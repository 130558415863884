import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { useDisable } from "../../../hooks/use-disable";

import { Typography, Grid } from "@mui/material";

import LabelInput from "../../UI/LabelInput";
import DropzoneUI from "../../UI/DropzoneUI";

import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledSelect from "../../Controller/ControlledSelect";
import ControlledCheckbox from "../../Controller/ControlledCheckbox";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";

import { ISelectOption } from "../../../types/global";
import { ICompany } from "../../../types/Setting/Company";

import { companyTypeFormatter } from "../../../utils/Formatter/Global";

const CompanyDetail = () => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<ICompany>();
  const [disabled] = useDisable();
  const watchRegisterVat = useWatch({ control, name: "is_registered_vat" });

  const { t } = useTranslation();

  const corporateTypes: ISelectOption[] = [
    {
      label: t("setting.company.corporate_type.company"),
      value: "company",
    },
    {
      label: t("setting.company.corporate_type.public_limited"),
      value: "public_limited",
    },
    {
      label: t("setting.company.corporate_type.limited_partnership"),
      value: "limited_partnership",
    },
    {
      label: t(
        "setting.company.corporate_type.registered_ordinary_partnership"
      ),
      value: "registered_ordinary_partnership",
    },
    {
      label: t("setting.company.corporate_type.association"),
      value: "association",
    },
    { label: t("setting.company.corporate_type.other"), value: "other" },
  ];

  return (
    <CustomizedBox>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Typography fontWeight={600} mb={2}>
            {t("sentence.detail")}
          </Typography>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12}>
              <ControlledTextField
                name="unique_id"
                control={control}
                label={`รหัสกิจการ`}
                error={Boolean(errors.unique_id)}
                helperText={errors.unique_id?.message?.toString()}
                viewMode={disabled}
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledTextField
                name="name"
                control={control}
                label={t("setting.company.name")}
                error={Boolean(errors.name)}
                helperText={errors.name?.message?.toString()}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {disabled ? (
                <LabelInput
                  label={t("setting.company.corporate_type.index")}
                  value={companyTypeFormatter(getValues("type"))}
                />
              ) : (
                <ControlledSelect
                  name="type"
                  control={control}
                  label={t("setting.company.corporate_type.index")}
                  error={Boolean(errors.type)}
                  helperText={errors.type?.message?.toString()}
                  options={corporateTypes}
                  required
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledTextField
                name="identity_no"
                control={control}
                label={t("setting.company.identity_no")}
                error={Boolean(errors.identity_no)}
                helperText={errors.identity_no?.message}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledDatePicker
                name="register_date"
                control={control}
                label={t("setting.company.register_date")}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ControlledCheckbox
                control={control}
                name="is_registered_vat"
                label={t("setting.company.is_registered_vat")}
                disabled={disabled}
              />
            </Grid>
            {watchRegisterVat && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledDatePicker
                  name="vat_registration_date"
                  control={control}
                  label={t("setting.company.vat_registration_date")}
                  viewMode={disabled}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <DropzoneUI
            control={control}
            name={"img_url"}
            setValue={setValue}
            acceptedFileType="image"
            maxSize={1}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default CompanyDetail;
