import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStateContext } from "contexts/auth-context";
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext, useWatch } from "react-hook-form";

import { useDisable } from "../../../../hooks/use-disable";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import {
  usePurchaseReturnOption,
  usePurchaseReturnCreate,
} from "../../../../hooks/Purchase/PurchaseReturn/use-purchase-return-option";

import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import EmployeeListForm from "../../EmployeeList";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";

import { IPurchaseReturn } from "types/Purchase/purchaseReturn";
import {
  PurchaseModelType,
  PurchaseUniqueIdGenerateQuery,
  usePurchaseUniqueIdGenerateQuery,
} from "../../../../generated/purchase";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { createGoodsIssueFromPurchaseReturn } from "utils/Formatter/Purchase/PurchaseReturn";
import CancelConfirmation from "components/UI/CancelConfirmation";

type Props = {
  editClickHandler: () => void;
  cancelHandler?: () => Promise<void>;
};

const PurchaseReturnHeader = ({ cancelHandler, editClickHandler }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [disabled] = useDisable();

  const {
    state: { authUser },
  } = useStateContext();

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IPurchaseReturn>();

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const reference_document_list = useWatch({
    control,
    name: "reference_document_list",
  });

  const item_list = useWatch({
    control,
    name: "item_list",
  });

  const graphQLClient = createGraphQLClientWithMiddleware("purchase");

  const { refetch: refetchUniqueId } =
    usePurchaseUniqueIdGenerateQuery<PurchaseUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: PurchaseModelType.PurchaseReturn,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const isMaxQty = item_list?.every((item) => (item.rs_qty || 0) >= item.qty);

  const { selectModifyOptions, showOptions } = usePurchaseReturnOption(
    status,
    disabled
  );

  const { selectCreateOptions, showCreateOptions } = usePurchaseReturnCreate(
    status,
    isMaxQty
  );

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(cancelHandler);

  return (
    <Box maxWidth={1040} mt={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">{t("purchase.return.index")}</Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        {id && (
          <Grid item sm={12} md={6}>
            <Grid container spacing={1.5} justifyContent="end">
              {showOptions && (
                <Grid item sm={5.5} md={4}>
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"ตัวเลือก"}
                    options={selectModifyOptions}
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      switch (value.innerText) {
                        case "แก้ไข":
                          editClickHandler();
                          break;
                        case "ยกเลิก":
                          openCancelConfirmation();
                          break;
                        default:
                          break;
                      }
                    }}
                    disabled={!id}
                  />
                </Grid>
              )}
              {showCreateOptions && (
                <Grid item sm={5.5} md={4}>
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"สร้าง"}
                    options={selectCreateOptions}
                    variant="contained"
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      switch (value.innerText) {
                        case `${t("inventory.goods_issue.index")}`:
                          const rs = getValues();
                          navigate("/inventory/goods-issue/add", {
                            state: createGoodsIssueFromPurchaseReturn(
                              rs,
                              authUser
                            ),
                          });
                          break;

                        default:
                      }
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="unique_id"
                control={control}
                placeholder={t("purchase.unique_id")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled={Boolean(id)}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={1}>
              {!Boolean(id) && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton onClick={generateUniqueId} color="primary">
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.reference_unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Box>
                {reference_document_list && reference_document_list?.length > 0
                  ? reference_document_list.map((reference) => (
                      <CustomizedChips
                        key={`${reference.document_id}-${reference.document_unique_id}-${reference.document_type}`}
                        onClick={() => {
                          window.open(
                            `/purchase/${
                              reference.document_type.split("_")[1]
                            }/${encodeURIComponent(reference.document_id)}`,
                            "_blank"
                          );
                        }}
                        value={reference.document_unique_id}
                        isRounded
                      />
                    ))
                  : "-"}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.reference_no")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="reference_no"
                control={control}
                error={Boolean(errors?.reference_no)}
                helperText={
                  errors?.reference_no &&
                  errors?.reference_no.message?.toString()
                }
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <Box>
                <EmployeeListForm />
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={isSmall ? 2 : 0}>
          <Grid container spacing={1.5} justifyContent="flex-end">
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                label={t("date.created_date")}
                disabled={true}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                label={t("date.issue_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.5} justifyContent="flex-end" mt={0}>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="delivery_date"
                control={control}
                label={t("date.delivery_date")}
                disabled={disabled}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledNumberTextField
                name="credit_day"
                control={control}
                label={t("purchase.credit_day")}
                disabled={disabled}
                viewMode={disabled}
                decimalScale={0}
                textAlign="left"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CancelConfirmation
        uniqueId={getValues("unique_id") || ""}
        confirmation={cancelConfirmation}
        closeConfirmationHandler={closeCancelConfirmation}
        submitConfirmationHandler={submitCancelConfirmation}
      />
    </Box>
  );
};

export default PurchaseReturnHeader;
