import { forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GraphQLClient } from "graphql-request";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideDatasource,
} from "ag-grid-community";

import ButtonLayout from "components/UI/ButtonLayout";
import CustomizedButton from "components/Custom/CustomizedButton";

import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import AgGrid from "components/UI/AgGrid";
import ModalUI from "./ModalUI";
import { useLocationColumnDefs } from "components/Table/Logistic/Location/columnDefs";
import { LOCATION_VIEW } from "services/AgGrid/LocationAgGrid";

interface Props {
  itemModal: boolean;
  closeItemModalHandler: () => void;
  addItemsHandler: () => void;
}

const WorkListModal = forwardRef<any, Props>(
  ({ itemModal, closeItemModalHandler, addItemsHandler }, ref) => {
    const { t } = useTranslation();

    const columnDefs = useLocationColumnDefs(true);

    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("crm");

    const datasource: IServerSideDatasource = {
      async getRows(params) {
        const { request } = params;
        const { startRow, endRow, filterModel, sortModel } = request;
        const { ...otherFilter } = filterModel;
        const formatFilter = {
          ...otherFilter,
        };
        try {
          const { LocationViewAggrid } =
            await graphQLClientWithHeaderItem.request(LOCATION_VIEW, {
              aggridInput: {
                startRow,
                endRow,
                filterModel: formatFilter,
                sortModel,
              },
            });
          params.success({
            rowData: LocationViewAggrid.results as any[],
            rowCount: LocationViewAggrid.count as number,
          });
        } catch (err) {
          params.fail();
        }
      },
    };

    const onGridReady = (params: GridReadyEvent) => {
      // params.api.setFilterModel({
      //   type: {
      //     type: "set",
      //     values: [isService ? ItemType.Service : ItemType.Normal],
      //   },
      //   is_active: {
      //     type: "set",
      //     values: ["1"],
      //   },
      // });
      params.api.setServerSideDatasource(datasource);
    };

    const getRowId = useCallback((params: GetRowIdParams) => {
      return params.data.unique_id;
    }, []);

    return (
      <ModalUI
        open={itemModal}
        handleClose={closeItemModalHandler}
        title={t("logistic.location.index")}
        maxWidth="lg"
        action={
          <ButtonLayout>
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              size="medium"
              onClick={closeItemModalHandler}
            />
            <CustomizedButton
              title={t("button.confirm")}
              onClick={addItemsHandler}
              variant="contained"
              size="medium"
            />
          </ButtonLayout>
        }
      >
        <AgGrid
          ref={ref}
          columnDefs={columnDefs}
          height={665}
          onGridReady={onGridReady}
          rowSelection="multiple"
          getRowId={getRowId}
          rowMultiSelectWithClick
        />
      </ModalUI>
    );
  }
);

export default WorkListModal;
