import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import { useDisable } from "../../../hooks/use-disable";

import { Grid } from "@mui/material";

import LabelInput from "../../UI/LabelInput";
import ControlledSelect from "../../Controller/ControlledSelect";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledAddressBox from "../../Controller/ControlledAddressBox";

import { countryOptions } from "../../../utils/Global";
import { countryFormatter } from "../../../utils/Formatter/Global";

import { IAddress } from "../../../types/global";
import { ICompany } from "../../../types/Setting/Company";

type Props = {
  index: number;
  addressList: IAddress[] | null | undefined;
};

const AddressDetail = ({ index, addressList }: Props) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<ICompany>();

  const [disabled] = useDisable();
  const { t } = useTranslation();

  const [addressValue, setAddressValue] = useState<IAddress>({
    address_type: "",
    name: "",
    district: "",
    is_default: true,
    postal_code: "",
    province: "",
    sub_district: "",
    address_contact_name: "",
    address_contact_phone: "",
    address_contact_fax: "",
    country: "",
  });

  const defaultAddressValue = useWatch({
    control,
    name: `address_list.0`,
  });

  const isSameAsDefault = useWatch({
    control,
    name: `address_list.${index}.is_same_as_default_address`,
  });

  useEffect(() => {
    if (isSameAsDefault) {
      const address = getValues(`address_list.${index}`);
      const newAddressValue = {
        ...defaultAddressValue,
        address_contact_fax:
          address.address_contact_fax ??
          defaultAddressValue.address_contact_fax ??
          "",
        address_contact_name:
          address.address_contact_name ??
          defaultAddressValue.address_contact_name ??
          "",
        address_contact_phone:
          address.address_contact_phone ??
          defaultAddressValue.address_contact_phone ??
          "",
        address_type:
          index === 1 ? "ที่อยู่จัดส่งสินค้า" : address.address_type ?? "",
        is_default: false,
        is_same_as_default_address: true,
      };

      setValue(`address_list.${index}`, newAddressValue);
      setAddressValue(newAddressValue);
    }
  }, [defaultAddressValue, getValues, index, isSameAsDefault, setValue]);

  useEffect(() => {
    if (disabled) {
      setAddressValue(
        addressList
          ? addressList[index]
          : {
              address_type: "",
              name: "",
              district: "",
              is_default: true,
              postal_code: "",
              province: "",
              sub_district: "",
              address_contact_name: "",
              address_contact_phone: "",
              address_contact_fax: "",
              country: "",
            }
      );
    }
  }, [addressList, disabled, index]);

  return (
    <>
      {index !== 0 && (
        <>
          <Grid item xs={12}>
            <ControlledTextField
              name={`address_list.${index}.address_contact_name`}
              control={control}
              label={t("contact.address.contact_person_name")}
              error={Boolean(
                errors?.address_list?.[index]?.address_contact_name
              )}
              helperText={
                errors?.address_list?.[index]?.address_contact_name?.message
              }
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ControlledTextField
              name={`address_list.${index}.address_contact_phone`}
              control={control}
              label={t("contact.address.phone_number")}
              error={Boolean(
                errors?.address_list?.[index]?.address_contact_phone
              )}
              helperText={
                errors?.address_list?.[index]?.address_contact_phone?.message
              }
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ControlledTextField
              name={`address_list.${index}.address_contact_fax`}
              control={control}
              label={t("contact.address.fax")}
              error={Boolean(
                errors?.address_list?.[index]?.address_contact_fax
              )}
              helperText={
                errors?.address_list?.[index]?.address_contact_fax?.message
              }
              viewMode={disabled}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <ControlledTextField
          name={`address_list.${index}.name`}
          control={control}
          label={t("contact.address.index")}
          rows={4}
          multiline
          disabled={isSameAsDefault}
          viewMode={disabled}
          error={Boolean(errors?.address_list?.[index]?.name)}
          helperText={errors?.address_list?.[index]?.name?.message}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ControlledAddressBox
          name={`address_list.${index}`}
          type="sub_district"
          disabled={isSameAsDefault}
          getValues={getValues}
          setValue={setValue}
          addressValue={addressValue}
          setAddressValue={setAddressValue}
          viewMode={disabled}
          error={Boolean(errors?.address_list?.[index]?.sub_district)}
          helperText={errors?.address_list?.[index]?.sub_district?.message}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ControlledAddressBox
          name={`address_list.${index}`}
          type="district"
          disabled={isSameAsDefault}
          getValues={getValues}
          setValue={setValue}
          addressValue={addressValue}
          setAddressValue={setAddressValue}
          viewMode={disabled}
          error={Boolean(errors?.address_list?.[index]?.district)}
          helperText={errors?.address_list?.[index]?.district?.message}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ControlledAddressBox
          name={`address_list.${index}`}
          type="province"
          disabled={isSameAsDefault}
          getValues={getValues}
          setValue={setValue}
          addressValue={addressValue}
          setAddressValue={setAddressValue}
          viewMode={disabled}
          error={Boolean(errors?.address_list?.[index]?.province)}
          helperText={errors?.address_list?.[index]?.province?.message}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ControlledAddressBox
          name={`address_list.${index}`}
          type="postal_code"
          disabled={isSameAsDefault}
          getValues={getValues}
          setValue={setValue}
          addressValue={addressValue}
          setAddressValue={setAddressValue}
          viewMode={disabled}
          error={Boolean(errors?.address_list?.[index]?.postal_code)}
          helperText={errors?.address_list?.[index]?.postal_code?.message}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        {disabled ? (
          <LabelInput
            label={t("contact.address.country")}
            value={countryFormatter(getValues(`address_list.${index}.country`))}
          />
        ) : (
          <ControlledSelect
            name={`address_list.${index}.country`}
            control={control}
            label={t("contact.address.country")}
            error={Boolean(errors.address_list?.[index]?.country)}
            helperText={
              errors.address_list &&
              errors.address_list?.[index]?.country &&
              errors.address_list?.[index]?.country?.message
            }
            options={countryOptions}
            disabled={true}
          />
        )}
      </Grid>
    </>
  );
};

export default AddressDetail;
