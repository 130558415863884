import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import TireHistoryTable from "components/Table/Logistic/Tire/TireHistoryTable";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { TireFineUniqueQuery, useTireFineUniqueQuery } from "generated/sales";

const HistoryTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { data, isSuccess } = useTireFineUniqueQuery<TireFineUniqueQuery>(
    graphQLClient,
    {
      tireFineUniqueId: id ? parseInt(id) : 0,
    },
    {
      enabled: !!id,
      cacheTime: 0,
    }
  );

  return (
    <>
      <Typography variant="h5" mb={2}>
        {t("logistic.tire.history.index")}
      </Typography>
      {isSuccess && (
        <TireHistoryTable referenceSerial={data?.TireFineUnique?.serial} />
      )}
    </>
  );
};

export default HistoryTab;
