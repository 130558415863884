import { Box, DialogContentText, Stack, Typography } from "@mui/material";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomerInfo from "components/Form/Logistic/CustomerInfo";
import DailyWorksheet from "components/Form/Logistic/DeliveryTrip/DailyWorksheet";
import DateDistanceInfo from "components/Form/Logistic/DeliveryTrip/DateDistanceInfo";
import DeliveryInfo from "components/Form/Logistic/DeliveryTrip/DeliveryInfo";
import FuelInfo from "components/Form/Logistic/DeliveryTrip/FuelInfo";
import DeliveryTripHeader from "components/Form/Logistic/DeliveryTrip/Header";
import DeliveryRemark from "components/Form/Logistic/DeliveryTrip/Remark";
import DeliveryTripVehicle from "components/Form/Logistic/DeliveryTrip/Vehicle";
import ExpensesList from "components/Form/Logistic/ExpensesList";
import IncomeList from "components/Form/Logistic/IncomeList";
import WorkList from "components/Form/Logistic/WorkList";
import GreenCheckIcon from "components/UI/GreenCheckIcon";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import ModalUI from "components/UI/Modal/ModalUI";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import dayjs from "dayjs";
import { ActivityLogDocumentType, ActivityType } from "generated/general";
import {
  DeliveryTripCreateInput,
  DeliveryTripUpdateInput,
  useDeliveryTripCreateMutation,
  useDeliveryTripFindUniqueQuery,
  useDeliveryTripUpdateMutation,
} from "generated/sales";
import {
  useVehicleCurrentKiloQuery,
  VehicleCurrentKiloQuery,
} from "generated/vehicle";
import { useActivityLog } from "hooks/use-activity-log";
import { useConfirmation } from "hooks/use-confirmation";
import { useDisable } from "hooks/use-disable";
import { useModal } from "hooks/use-modal";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import {
  deliveryTripCreatePayload,
  deliveryTripDataFormatter,
  deliveryTripUpdatePayload,
  workListQueryFormatter,
} from "utils/Formatter/Logistic/DeliveryTrip";
import { errorMessageFormatter } from "utils/Global";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LoadingUI from "components/UI/LoadingUI";
import AddressInfo from "components/Form/Logistic/CustomerInfo/AddressInfo";
import LoadingAnimation from "components/Animation/Loading";

const DocumentInfoTab = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    watch,
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext<IDeliveryTrip>();

  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { createActivityLog } = useActivityLog();

  const [, setDisabled] = useDisable();

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const graphqlClient = createGraphQLClientWithMiddleware("sales");

  const { data, refetch } = useDeliveryTripFindUniqueQuery(
    graphqlClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (state) {
      const {
        work_list,
        created_date,
        issue_date,
        plan_start_date,
        plan_end_date,
        copied_unique_id,
        copied_id,
        ...otherState
      } = state;
      const formatWorkList = work_list.map(
        ({ start_date, end_date, ...work }: any) => ({
          ...work,
          start_date: start_date ? dayjs(start_date) : undefined,
          end_date: end_date ? dayjs(end_date) : undefined,
        })
      );
      const formatState = {
        ...otherState,
        created_date: dayjs(created_date),
        issue_date: dayjs(issue_date),
        plan_start_date: dayjs(plan_start_date),
        plan_end_date: dayjs(plan_end_date),
        work_list: formatWorkList,
      };
      reset(formatState);
    }
  }, [reset, state]);

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;

  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    if (status && status !== "draft") {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [setDisabled, status]);

  const { mutateAsync: create, isLoading: isCreating } =
    useDeliveryTripCreateMutation<Error>(graphqlClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useDeliveryTripUpdateMutation<Error>(graphqlClient);

  // const draftHandler = async (data: IDeliveryTrip) => {
  //   try {
  //     if (!id) {
  //       const formatPayload = deliveryTripCreatePayload(data, "draft");
  //       const { DeliveryTripCreate } = await create({
  //         createInput: formatPayload as DeliveryTripCreateInput,
  //       });
  //       await createActivityLog({
  //         activity_type: ActivityType.StatusChange,
  //         document_type: ActivityLogDocumentType.DeliveryTrip,
  //         reference_id: DeliveryTripCreate?.id || 0,
  //         activity_detail: {
  //           secondary_operation: ActivityType.Create,
  //           curr_status: "draft",
  //         },
  //       });
  //       navigate(`/logistic/delivery-trip/${DeliveryTripCreate?.id}`);
  //     } else {
  //       const formatPayload = await deliveryTripUpdatePayload(data, "draft");
  //       const { DeliveryTripUpdate } = await update({
  //         uniqueInput: {
  //           id: id ? parseInt(id) : undefined,
  //         },
  //         updateInput: formatPayload as DeliveryTripUpdateInput,
  //       });
  //       if (isDirty) {
  //         await createActivityLog({
  //           activity_type: ActivityType.Edit,
  //           document_type: ActivityLogDocumentType.DeliveryTrip,
  //           reference_id: DeliveryTripUpdate?.id || 0,
  //           activity_detail: {
  //             secondary_operation: ActivityType.Edit,
  //           },
  //         });
  //       }
  //     }
  //     enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
  //       variant: "success",
  //     });
  //     await refetch();
  //   } catch (err) {
  //     const formatError = errorMessageFormatter(err, "document");
  //     enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
  //       variant: "error",
  //     });
  //   }
  // };

  const waitDeliverHandler = async (data: IDeliveryTrip) => {
    try {
      if (!id) {
        const formatPayload = deliveryTripCreatePayload(data, "wait_deliver");
        const { DeliveryTripCreate } = await create({
          createInput: formatPayload as DeliveryTripCreateInput,
        });
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.DeliveryTrip,
            reference_id: DeliveryTripCreate?.id || 0,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: DeliveryTripCreate?.id,
                unique_id: DeliveryTripCreate?.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.DeliveryTrip,
          reference_id: DeliveryTripCreate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_deliver",
          },
        });
        navigate(`/logistic/delivery-trip/${DeliveryTripCreate?.id}`);
      } else {
        const formatPayload = await deliveryTripUpdatePayload(
          data,
          "wait_deliver"
        );
        const { DeliveryTripUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: formatPayload as DeliveryTripUpdateInput,
        });
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.DeliveryTrip,
          reference_id: DeliveryTripUpdate?.id || 0,
          activity_detail: {
            prev_status: data.aggrid_status,
            curr_status: "wait_deliver",
          },
        });
      }
      enqueueSnackbar(`${t("status.wait_deliver")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      const formatError = errorMessageFormatter(err, "document");
      enqueueSnackbar(formatError || `${t("status.wait_deliver")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const deliveringHandler = async (data: IDeliveryTrip) => {
    try {
      const formatPayload = await deliveryTripUpdatePayload(data, "delivering");
      const { DeliveryTripUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: formatPayload as DeliveryTripUpdateInput,
      });

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.DeliveryTrip,
        reference_id: DeliveryTripUpdate?.id || 0,
        activity_detail: {
          prev_status: data.aggrid_status,
          curr_status: "delivering",
        },
      });

      enqueueSnackbar(`${t("status.delivering")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      enqueueSnackbar(`${t("status.delivering")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const finishedHandler = async (data: IDeliveryTrip) => {
    try {
      const formatPayload = await deliveryTripUpdatePayload(data, "finished");
      const { DeliveryTripUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: formatPayload as DeliveryTripUpdateInput,
      });

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.DeliveryTrip,
        reference_id: DeliveryTripUpdate?.id || 0,
        activity_detail: {
          prev_status: data.aggrid_status,
          curr_status: "finished",
        },
      });

      enqueueSnackbar(`${t("status.finished")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      enqueueSnackbar(`${t("status.finished")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setIsEdit(false);
    setDisabled(true);

    const formatData = deliveryTripDataFormatter(
      data?.DeliveryTripFindUnique
    ) as IDeliveryTrip;

    reset(formatData);
  };

  const editHandler = async (data: IDeliveryTrip) => {
    try {
      const formatPayload = await deliveryTripUpdatePayload(
        data,
        data.main_status
      );

      const { DeliveryTripUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: formatPayload as DeliveryTripUpdateInput,
      });

      if (isDirty) {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.DeliveryTrip,
          reference_id: DeliveryTripUpdate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Edit,
          },
        });
      }

      enqueueSnackbar(`${t("sentence.edit")}สำเร็จ`, {
        variant: "success",
      });

      await refetch();
      setIsEdit(false);
      setDisabled(true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("sentence.edit")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      const data = getValues();
      const flag_status =
        data?.flag_status && data.flag_status.length > 0
          ? data.flag_status.includes("cancelled")
            ? [...data.flag_status]
            : [...data.flag_status, "cancelled"]
          : ["cancelled"];
      const formatPayload = await deliveryTripUpdatePayload(
        data,
        data.main_status
      );
      const { DeliveryTripUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: {
          ...formatPayload,
          flag_status,
        } as DeliveryTripUpdateInput,
      });

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.DeliveryTrip,
        reference_id: DeliveryTripUpdate?.id || 0,
        activity_detail: {
          secondary_operation: ActivityType.Edit,
          prev_status: data.aggrid_status,
          curr_status: "cancelled",
        },
      });

      enqueueSnackbar(
        `${t("button.cancel")}${t("logistic.delivery_trip.index")}สำเร็จ`,
        {
          variant: "success",
        }
      );

      await refetch();
    } catch (err) {
      enqueueSnackbar(
        `${t("button.cancel")}${t("logistic.delivery_trip.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const { refetch: refetchCurrentKilo } =
    useVehicleCurrentKiloQuery<VehicleCurrentKiloQuery>(
      graphqlClient,
      {
        vehicleFindUniqueId: getValues("vehicle_id") || 0,
      },
      {
        enabled: false,
      }
    );

  const checkIsLowerCurrentKilo = async () => {
    const { data } = await refetchCurrentKilo();
    const startKilo = getValues("start_kilometer") || 0;
    if (startKilo < data?.VehicleFindUnique?.current_kilometer) {
      return true;
    }
    return false;
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(async () => {
    if (status === "wait_deliver") {
      handleSubmit(deliveringHandler)();
    } else {
      const isLower = await checkIsLowerCurrentKilo();
      if (isLower) {
        openModalHandler();
      } else {
        handleSubmit(finishedHandler)();
      }
    }
  });

  const updateLocationData = async () => {
    const currentWorkList = getValues("work_list");
    const vehicleType = getValues("vehicle.type");
    const isFrozen = getValues("is_frozen");

    setIsFetching(true);
    const newWorkList = await workListQueryFormatter(
      currentWorkList,
      vehicleType,
      isFrozen
    );
    setTimeout(() => {
      setIsFetching(false);
    }, 2000);

    return newWorkList;
  };

  const renderButton = (status: string | undefined) => {
    switch (status) {
      // case "draft":
      //   return (
      //     <>
      //       <CustomizedButton
      //         variant="outlined"
      //         title={t("button.save_draft")}
      //         disabled={isMutating}
      //         onClick={handleSubmit(draftHandler)}
      //       />
      //       <CustomizedButton
      //         variant="contained"
      //         title={t("status.wait_deliver")}
      //         disabled={isMutating}
      //         onClick={handleSubmit(waitDeliverHandler)}
      //       />
      //     </>
      //   );
      case "wait_deliver":
        if (isEdit) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={cancelEditHandler}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(editHandler)}
                disabled={isUpdating}
              />
            </>
          );
        } else {
          return (
            <>
              <CustomizedButton
                variant="contained"
                title={t("status.delivering")}
                onClick={openConfirmationHandler}
              />
            </>
          );
        }
      case "delivering":
        if (isEdit) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={cancelEditHandler}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(editHandler)}
                disabled={isUpdating}
              />
            </>
          );
        } else {
          return (
            <>
              <CustomizedButton
                variant="contained"
                title={t("status.finished")}
                onClick={openConfirmationHandler}
              />
            </>
          );
        }
      case "finished":
        if (isEdit) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={cancelEditHandler}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(editHandler)}
                disabled={isUpdating}
              />
            </>
          );
        } else {
          return;
        }
      case "cancelled":
        return;
      default:
        return (
          <>
            {/* <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isMutating}
              onClick={handleSubmit(draftHandler)}
            /> */}
            <CustomizedButton
              variant="contained"
              title={t("status.wait_deliver")}
              disabled={isMutating}
              onClick={handleSubmit(waitDeliverHandler)}
            />
          </>
        );
    }
  };

  const disabledStatus = ["delivering", "finished"].includes(status);

  const isMutating = isCreating || isUpdating;

  if (isFetching) {
    return <LoadingAnimation />;
  }

  if (id && isMutating) {
    return <LoadingUI />;
  }

  return (
    <>
      <DeliveryTripHeader
        editClickHandler={editClickHandler}
        cancelHandler={cancelHandler}
        isEdit={isEdit}
      />
      <DeliveryTripVehicle />
      <CustomerInfo forceDisabled={disabledStatus} />
      <AddressInfo forceDisabled={disabledStatus} />
      <DeliveryInfo />
      <DateDistanceInfo />
      <WorkList updateLocationData={updateLocationData} />
      <IncomeList />
      <FuelInfo />
      {["delivering", "finished", "cancelled"].includes(status) && (
        <>
          <ExpensesList />
          <DailyWorksheet />
        </>
      )}
      <DeliveryRemark />
      <BottomNavbar>
        <Stack direction="row" spacing={1} alignItems="center">
          {renderButton(status)}
        </Stack>
      </BottomNavbar>
      <ConfirmationModal
        title={"ยืนยันการปรับสถานะ"}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
        noDivider
        message={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Box mb={1}>
              <GreenCheckIcon size={64} />
            </Box>
            <Box>
              {status === "wait_deliver" ? (
                <>
                  <Typography>กรุณายืนยืนการปรับสถานะใบงานจัดส่ง</Typography>
                  <Typography>{getValues("unique_id")}</Typography>
                  <Box display={"flex"} gap={0.5} justifyContent={"center"}>
                    <Typography>จาก</Typography>
                    <Typography fontWeight={600}>รอวิ่งงาน</Typography>
                    <Typography>เป็น</Typography>
                    <Typography fontWeight={600}>กำลังขนส่ง</Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box display={"flex"} gap={0.5} justifyContent={"center"}>
                    <Typography>เมื่อเปลี่ยนเป็นสถานะ</Typography>
                    <Typography fontWeight={600}>เสร็จสิ้น</Typography>
                    <Typography>จะไม่สามารถแก้ไขข้อมูลใดๆ ได้</Typography>
                  </Box>
                  <Box display={"flex"} gap={0.5} justifyContent={"center"}>
                    <Typography>ยกเว้น</Typography>
                    <Typography fontWeight={600}>รายการรายรับ</Typography>
                    <Typography>ที่สามารถแก้ไขได้</Typography>
                  </Box>
                  <Typography>
                    จนกว่าจะนำใบงานจัดส่งนี้ไปเปิดใบวางบิล
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        }
      />
      <ModalUI
        title={"กรุณาแก้ไขข้อมูล"}
        maxWidth="xs"
        fullWidth
        open={modal}
        handleClose={closeModalHandler}
        centerAction
        action={
          <CustomizedButton
            title="ปิด"
            variant="outlined"
            onClick={closeModalHandler}
          />
        }
      >
        <DialogContentText>
          <Box textAlign="center">
            <ErrorOutlineIcon
              sx={{
                fontSize: "4rem",
                my: 1,
                color: "gray.light",
              }}
            />
            <Box display={"flex"} gap={0.5} justifyContent={"center"}>
              <Typography fontWeight={600}>เลขกิโลเมตรเริ่มต้น</Typography>
              <Typography>ต้องไม่น้อยกว่า</Typography>
              <Typography fontWeight={600}>เลขกิโลเมตรปัจจุบัน</Typography>
            </Box>
            <Typography>กรุณาอัปเดตข้อมูลเลขกิโลเมตรเริ่มต้น</Typography>
          </Box>
        </DialogContentText>
      </ModalUI>
    </>
  );
};

export default DocumentInfoTab;
