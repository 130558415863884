import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";
import { IInventorySetting } from "../../../types/global";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  label: string;
  open: boolean;
  closeModalHandler: () => void;
  onAddHandler: (data: IInventorySetting) => void;
  onUpdateHandler: (data: IInventorySetting) => void;
  data?: any;
  isLoading?: boolean;
  isSuccess?: boolean;
};

const inventorySettingSchema: IInventorySetting = {
  name: "",
};

const inventorySettingValidate = Yup.object().shape({
  name: Yup.string()
    .required("กรุณาระบุ")
    .max(50, "กรอกได้ไม่เกิน 50 ตัวอักษร"),
});

const InventorySettingModal = ({
  label,
  open,
  closeModalHandler,
  onAddHandler,
  onUpdateHandler,
  data,
  isLoading,
  isSuccess,
}: Props) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const id = searchParams.get("id");
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    defaultValues: inventorySettingSchema,
    resolver: yupResolver(inventorySettingValidate),
  });

  const onClose = () => {
    reset(inventorySettingSchema);
    closeModalHandler();
  };

  useEffect(() => {
    if (isSuccess) {
      reset(data);
    }
  }, [data, isSuccess, reset]);

  return (
    <ModalUI
      isLoading={Boolean(id && isLoading)}
      title={`${id ? t("sentence.edit") : t("button.add")}${label}`}
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          {id ? (
            <CustomizedButton
              title={t("button.confirm")}
              variant="contained"
              onClick={() => {
                handleSubmit(onUpdateHandler)();
                if (isValid) {
                  onClose();
                }
              }}
            />
          ) : (
            <CustomizedButton
              title={t("button.confirm")}
              variant="contained"
              onClick={() => {
                handleSubmit(onAddHandler)();
                if (isValid) {
                  onClose();
                }
              }}
            />
          )}
        </Box>
      }
    >
      <Grid container spacing={1.5} mt={0}>
        <Grid item xs={12}>
          <ControlledTextField
            label={`ชื่อ${label}`}
            control={control}
            name="name"
            error={Boolean(errors?.name)}
            helperText={errors?.name && errors.name.message?.toString()}
            required
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default InventorySettingModal;
