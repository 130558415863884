import { Box, Grid, IconButton, Typography } from "@mui/material";
import ControlledSSCreatable from "components/Controller/ControlledSSCreatable";
import ControlledTextField from "components/Controller/ControlledTextField";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { useDisable } from "hooks/use-disable";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMaintenance } from "types/Logistic/maintenance";
import CloseIcon from "@mui/icons-material/Close";
import ButtonLayout from "components/UI/ButtonLayout";
import CustomizedButton from "components/Custom/CustomizedButton";
import { IOptionsCreatable } from "types/global";

const MaintenanceTask = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IMaintenance>();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const { fields, remove, append } = useFieldArray({
    control,
    name: "ma_list",
  });

  const mainStatus = useWatch({ control, name: "main_status" });
  const aggrid_status = useWatch({ control, name: "aggrid_status" });
  const flagStatus = useWatch({ control, name: "flag_status" });
  const maTasks = useWatch({ control, name: "ma_list" });

  const cancelled = flagStatus.includes("cancelled");

  const status = !cancelled ? mainStatus : aggrid_status;

  const disabledStatus = ["finished"].includes(status);

  const currentTasks =
    (maTasks
      ?.map((item) => ({
        label: item.name,
        value: item.name,
      }))
      .filter((item) => item.label) as IOptionsCreatable[]) ?? [];

  return (
    <>
      <CustomizedBox margin={0}>
        <Typography fontWeight={600} mb={1}>
          {t("logistic.maintenance.ma_list.index")}
        </Typography>
        {fields.map((item, index) => (
          <Grid
            container
            spacing={1.5}
            mb={1}
            key={`maintenance-task-${item.id}`}
          >
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                fontWeight={400}
                sx={{
                  color: (theme: any) => theme.palette.gray.font,
                }}
              >
                {index + 1}
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
              sm={4}
              md={4}
              sx={{
                display: disabled ? "flex" : undefined,
                alignItems: disabled ? "center" : undefined,
              }}
            >
              <ControlledSSCreatable
                control={control}
                dataName="name"
                name={`ma_list.${index}.name`}
                keyId={item.id}
                defaultOptions={currentTasks}
                setValue={setValue}
                documentType="maintenance"
                label={disabled ? "" : t("logistic.maintenance.ma_list.index")}
                error={Boolean(errors.ma_list)}
                helperText={errors.ma_list?.message}
                disabled={disabled || disabledStatus}
                viewMode={disabled}
                disableNewOptions
              />
            </Grid>
            <Grid
              item
              xs={10}
              sm={6}
              md={6}
              sx={{
                display: disabled ? "flex" : undefined,
                alignItems: disabled ? "center" : undefined,
              }}
            >
              <ControlledTextField
                control={control}
                name={`ma_list.${index}.description`}
                label={
                  disabled ? "" : t("logistic.maintenance.ma_list.description")
                }
                error={Boolean(errors.ma_list)}
                helperText={errors.ma_list?.message}
                disabled={disabled || disabledStatus}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={2} sm={1} md={1}>
              {!disabled && !disabledStatus && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
        {!disabled && fields.length < 100 && (
          <ButtonLayout sx={{ marginTop: 2 }}>
            <CustomizedButton
              title={t("button.add_record")}
              variant="text"
              onClick={() => append({ name: "", description: "" })}
              startAddIcon
            />
          </ButtonLayout>
        )}
        {disabled && fields.length === 0 && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Typography>ไม่มีข้อมูล</Typography>
            </Box>
          </Grid>
        )}
      </CustomizedBox>
    </>
  );
};

export default MaintenanceTask;
