import * as Yup from "yup";
import dayjs from "dayjs";
import { IPurchaseOrder } from "../../../../types/Purchase/purchaseOrder";
import {
  AdditionalDiscountType,
  PriceVatType,
} from "../../../../generated/purchase";
import { itemListSchema } from "../ItemListSchema";
import { stringMaxInput } from "utils/Validate";
// import { itemListPRSchema } from "../../Sales/itemListSchema";

export const purchaseOrderSchema: IPurchaseOrder = {
  unique_id: "",
  main_status: "",
  sub_status: "",
  aggrid_status: "",
  flag_status: [],
  issue_date: dayjs(),
  expected_date: dayjs(),
  due_date: dayjs(),
  within_date: dayjs(),
  destination_warehouse_id: undefined,
  last_updated_by: null,
  created_date: dayjs(),
  created_by: null,
  reference_no: "",
  related_user_list: [],
  reference_document_list: [],
  remark: "",
  tag_list: [],
  item_list: [],
  shipping_cost: 0,
  additional_discount: 0,
  additional_discount_type: AdditionalDiscountType.Baht,
  price_vat_type: PriceVatType.ExcludedVat,
  post_discount_amount: 0,
  total_amount: 0,
  vat_0_percent_amount: 0,
  vat_7_percent_amount: 0,
  vat_amount: 0,
  vat_exempted_amount: 0,
  withholding_tax_amount: 0,
};

export const purchaseOrderValidation = Yup.object().shape({
  unique_id: stringMaxInput(15, undefined, true, "กรุณาระบุเลขที่เอกสาร"),
  created_date: Yup.date().required("กรุณาระบุวันที่สร้าง"),
  issue_date: Yup.date().required("กรุณาระบุวันที่ออกเอกสาร"),
  // title_name: stringMaxInput(),
  supplier_details: Yup.object().shape({
    unique_id_name: stringMaxInput(200, undefined, true),
    email: stringMaxInput(100, undefined, false),
    fax: stringMaxInput(20, undefined, false),
    phone: stringMaxInput(20, undefined, false),
  }),
  price_vat_type: Yup.string().required("กรุณาระบุประเภทราคา"),
  item_list: Yup.array().of(itemListSchema).min(1, "กรุณาเพิ่มสินค้า"),
  additional_discount: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
});
