import { useEffect, useMemo, useState } from "react";
import { IHeaderTable } from "types/global";

export const useDeliveryTripListPdfHeader = () => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);
  const columns = useMemo<IHeaderTable[]>(
    () => [
      {
        thaiLabel: "",
        width: 12,
      },
      {
        thaiLabel: "ใบงานจัดส่ง",
        width: 50,
        align: "left",
      },
      {
        thaiLabel: "วันที่",
        width: 40,
        align: "left",
      },
      {
        thaiLabel: "ทะเบียนรถ",
        width: 60,
        align: "left",
      },
      {
        thaiLabel: "รถ",
        width: 40,
        align: "left",
      },
      {
        thaiLabel: "สถานที่ต้นทาง",
        width: 70,
        align: "left",
      },
      {
        thaiLabel: "สถานที่ปลายทาง",
        width: 70,
        align: "left",
      },
      {
        thaiLabel: "มูลค่าสุทธิ",
        width: 65,
        align: "right",
      },
    ],
    []
  );
  useEffect(() => {
    setHeaders(columns);
  }, [columns]);

  return headers;
};
