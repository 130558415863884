import { GraphQLClient } from "graphql-request";
import { useEffect, useState } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { IAddress, IBillingAddress, IDeliveryAddress } from "../types/global";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import {
  ContactFindUniqueQuery,
  useContactFindUniqueQuery,
} from "../generated/crm";
import { IContact } from "../types/Contact/contact";
import { ICustomer } from "../types/Sales";

export const useContactDescription = (
  module: "sales" | "purchase",
  documentType?: string
) => {
  const { control, reset } = useFormContext();
  const contactUniqueId = useWatch({
    control,
    name: module === "sales" ? "customer_unique_id" : "supplier_unique_id",
  });

  const contact = useWatch({
    control,
    name: module === "sales" ? "customer_details" : "supplier_details",
  });

  const [openContactTable, setOpenContactTable] = useState<boolean>(false);

  const [contactIds, setContactIds] = useState<string[]>([]);
  const [contactIdsSnapshot, setContactIdsSnapshot] = useState<string[]>([]);

  const [openBillingAddress, setOpenBillingAddress] = useState<boolean>(false);
  const [billingAddressSnapshot, setBillingAddressSnapshot] =
    useState<IBillingAddress | null>({
      name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    });

  const [openDeliveryAddress, setOpenDeliveryAddress] =
    useState<boolean>(false);
  const [deliveryAddressSnapshot, setDeliveryAddressSnapshot] =
    useState<IDeliveryAddress | null>({
      name: "",
      address_contact_name: "",
      address_contact_phone: "",
      address_contact_fax: "",
      address_type: "",
      country: "",
      district: "",
      is_same_as_default_address: false,
      postal_code: "",
      province: "",
      sub_district: "",
    });

  const [allAddressList, setAllAddressList] = useState<
    IAddress[] | null | undefined
  >([]);

  const [selectedContactId, setSelectedContactId] = useState<number>();

  // const graphQLClientWithHeaderCompany: GraphQLClient =
  //   createGraphQLClientWithMiddleware("company-user");

  const graphQLClientWithHeaderContact: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  // const { refetch: companyRefetch, isLoading: companyIsLoading } =
  //   useCompanyQuery<CompanyQuery>(
  //     graphQLClientWithHeaderCompany,
  //     {
  //       uniqueInput: {
  //         unique_id: currentTenantId,
  //       },
  //     },
  //     {
  //       enabled: false,
  //     }
  //   );

  const { refetch: customerRefetch } =
    useContactFindUniqueQuery<ContactFindUniqueQuery>(
      graphQLClientWithHeaderContact,
      {
        uniqueInput: {
          unique_id: contactUniqueId,
        },
      },
      {
        enabled: false,
      }
    );

  const openContactTableHandler = async () => {
    setOpenContactTable(true);
  };

  const closeContactTableHandler = () => {
    setContactIds(contactIdsSnapshot);
    setOpenContactTable(false);
  };

  const openBillingAddressHandler = () => {
    setOpenBillingAddress(true);
  };

  const closeBillingAddressHandler = () => {
    setOpenBillingAddress(false);
  };

  const openDeliveryAddressHandler = async () => {
    const { data } = await customerRefetch();
    const contactData = data?.ContactFindUnique as IContact;
    const { address_list } = contactData;
    setAllAddressList(address_list);
    setOpenDeliveryAddress(true);
  };

  const closeDeliveryAddressHandler = () => {
    setOpenDeliveryAddress(false);
  };

  const finishContactsSelect = (data: IContact) => {
    if (data) {
      const {
        id,
        contact_channel_list,
        name,
        last_name,
        unique_id,
        address_list,
        taxpayer_id,
        billing_note_duration,
      } = data;

      setSelectedContactId(id);

      const foundEmail =
        contact_channel_list?.find(
          (channel) => channel.contact_channel_type === "email"
        )?.contact_channel_info || "";

      const foundPhone =
        contact_channel_list?.find(
          (channel) => channel.contact_channel_type === "phone"
        )?.contact_channel_info || "";

      const foundFax =
        contact_channel_list?.find(
          (channel) => channel.contact_channel_type === "fax"
        )?.contact_channel_info || "";

      const foundBillingAddress = address_list?.find(
        (address) => address.address_type === "ที่อยู่จดทะเบียน"
      );

      const formatBillingAddress = foundBillingAddress
        ? {
            name: foundBillingAddress.name,
            sub_district: foundBillingAddress.sub_district,
            district: foundBillingAddress.district,
            province: foundBillingAddress.province,
            postal_code: foundBillingAddress.postal_code,
            country: foundBillingAddress.country,
          }
        : {
            name: "",
            sub_district: "",
            district: "",
            province: "",
            postal_code: "",
            country: "",
          };

      const foundDeliveryAddress = address_list?.find(
        (address) => address.address_type === "ที่อยู่จัดส่ง"
      );

      const formatDeliveryAddress = foundDeliveryAddress
        ? {
            address_type: foundDeliveryAddress.address_type,
            is_same_as_default_address:
              foundDeliveryAddress.is_same_as_default_address,
            address_contact_name: foundDeliveryAddress.address_contact_name,
            address_contact_phone: foundDeliveryAddress.address_contact_phone,
            address_contact_fax: foundDeliveryAddress.address_contact_fax,
            name: foundDeliveryAddress.name,
            sub_district: foundDeliveryAddress.sub_district,
            district: foundDeliveryAddress.district,
            province: foundDeliveryAddress.province,
            postal_code: foundDeliveryAddress.postal_code,
            country: foundDeliveryAddress.country,
          }
        : {
            address_type: "",
            is_same_as_default_address: false,
            address_contact_name: "",
            address_contact_phone: "",
            address_contact_tax: "",
            name: "",
            sub_district: "",
            district: "",
            province: "",
            postal_code: "",
            country: "",
          };

      const customer_details: ICustomer = {
        id: id,
        unique_id_name: `${unique_id} - ${name}${
          last_name ? " " + last_name : ""
        }`,
        name: `${name}${last_name ? " " + last_name : ""}`,
        identity_no: taxpayer_id,
        email: foundEmail,
        phone: foundPhone,
        fax: foundFax,
        billing_address: formatBillingAddress,
        delivery_address:
          formatDeliveryAddress as ICustomer["delivery_address"],
        address_list: address_list,
      };

      setAllAddressList(address_list);

      const customer_unique_id = unique_id;

      if (module === "sales") {
        // const formatSalesList = sales_list?.map(
        //   ({ id, unique_id, nick_name, title_name, ...otherSales }) => ({
        //     ...otherSales,
        //     user_unique_id: unique_id,
        //   })
        // );

        if (documentType === "customer_deposit") {
          reset((prev) => ({
            ...prev,
            customer_details,
            customer_unique_id,
          }));
        } else {
          reset((prev) => ({
            ...prev,
            credit_day: billing_note_duration,
            customer_details,
            customer_unique_id,
          }));
        }
      } else {
        reset((prev) => ({
          ...prev,
          credit_day: billing_note_duration,
          supplier_details: customer_details,
          supplier_unique_id: customer_unique_id,
        }));
      }
      setBillingAddressSnapshot(formatBillingAddress);
    } else {
      if (module === "sales") {
        reset((prev) => ({
          ...prev,
          customer_details: {
            id: undefined,
            unique_id_name: "",
            name: "",
            phone: "",
            email: "",
            fax: "",
            identity_no: "",
            billing_address: {
              name: "",
              sub_district: "",
              district: "",
              province: "",
              postal_code: "",
              country: "",
            },
            delivery_address: {
              type: "",
              is_same_as_default_address: false,
              contact_name: "",
              contact_phone: "",
              name: "",
              sub_district: "",
              district: "",
              province: "",
              postal_code: "",
              country: "",
            },
          },
          customer_unique_id: null,
        }));
      }

      setSelectedContactId(undefined);
      setAllAddressList([]);
      setBillingAddressSnapshot({
        name: "",
        sub_district: "",
        district: "",
        province: "",
        postal_code: "",
        country: "",
      });
    }
    closeContactTableHandler();
  };

  const openContactDetail = () => {
    window.open(`/contact/all/${selectedContactId}?tab=address`, "_blank");
  };

  useEffect(() => {
    if (contactUniqueId && contact) {
      setContactIds([contactUniqueId]);
      setContactIdsSnapshot([contactUniqueId]);
      setSelectedContactId(contact.id);
      // setBillingAddressSnapshot(contact.billing_address);
      // setDeliveryAddressSnapshot(contact.delivery_address);
    }
  }, [contact, contactUniqueId]);

  return {
    contact,
    contactUniqueId,
    openContactTable,
    closeContactTableHandler,
    contactIds,
    setContactIds,
    contactIdsSnapshot,
    setContactIdsSnapshot,
    openBillingAddress,
    billingAddressSnapshot,
    setBillingAddressSnapshot,
    openDeliveryAddress,
    deliveryAddressSnapshot,
    setDeliveryAddressSnapshot,
    allAddressList,
    openContactTableHandler,
    openBillingAddressHandler,
    closeBillingAddressHandler,
    openDeliveryAddressHandler,
    closeDeliveryAddressHandler,
    finishContactsSelect,
    selectedContactId,
    openContactDetail,
  };
};
