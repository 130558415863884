import {
  Autocomplete,
  Box,
  ListItemText,
  TextField,
  createFilterOptions,
} from "@mui/material";
import allAddress from "../../data/address.json";
import { useTranslation } from "react-i18next";
import { HTMLAttributes, forwardRef, useState } from "react";

interface Props {
  type: string;
  value: any;
  onChange: any;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onInputChange?: (value: string) => void;
  required?: boolean;
}

interface AddressRenderProps {
  props: HTMLAttributes<HTMLLIElement>;
  option: any;
  type: string;
}

const allAddressType = allAddress as any;

const AddressRenderOption = ({ props, option, type }: AddressRenderProps) => {
  return (
    <li {...props} key={props.id} value={option[type]}>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box display="flex">
          <ListItemText
            primary={option.sub_district}
            primaryTypographyProps={{
              style: {
                fontWeight: type === "sub_district" ? 600 : 400,
              },
            }}
          />
          <ListItemText
            primary={option.district}
            primaryTypographyProps={{
              style: {
                fontWeight: type === "district" ? 600 : 400,
                textAlign: "right",
              },
            }}
          />
        </Box>
        <Box display="flex">
          <ListItemText
            primary={option.province}
            primaryTypographyProps={{
              style: {
                fontWeight: type === "province" ? 600 : 400,
              },
            }}
          />
          <ListItemText
            primary={option.postal_code}
            primaryTypographyProps={{
              style: {
                fontWeight: type === "postal_code" ? 600 : 400,
                textAlign: "right",
              },
            }}
          />
        </Box>
      </Box>
    </li>
  );
};
const CustomizedAddressBox = forwardRef<HTMLInputElement, Props>(
  (
    {
      type,
      value,
      onChange,
      disabled,
      error,
      helperText,
      onInputChange,
      required,
    },
    ref
  ) => {
    const filterOptions = createFilterOptions({
      matchFrom: "any",
      limit: 100,
      stringify: (option: any) => {
        const optionString = `${option.province} ${option.sub_district} ${option.district} ${option.zone} ${option.postal_code}`;
        return optionString;
      },
    });

    const [open, setOpen] = useState<boolean>(false);

    const { t } = useTranslation();

    return (
      <Autocomplete
        ref={ref}
        disabled={disabled}
        value={value}
        onChange={onChange}
        filterOptions={filterOptions}
        freeSolo
        disableClearable
        getOptionLabel={(option: any) => option[type] || ""}
        options={allAddressType}
        onInputChange={(event, value) => {
          if (event) setOpen(true);
          if (onInputChange) onInputChange(value);
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        renderOption={(props, option) => (
          <AddressRenderOption
            key={props.id}
            props={props}
            option={option}
            type={type}
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={t(`address.${type}`)}
            InputProps={{
              ...params.InputProps,
            }}
            error={
              // error ? error : (value && value[type]?.length) > 200
              error
            }
            helperText={
              // helperText
              //   ? helperText
              //   : value &&
              //     value[type]?.length > 200 &&
              //     "กรอกได้ไม่เกิน 200 ตัวอักษร"
              helperText
            }
            required={required}
          />
        )}
      />
    );
  }
);

export default CustomizedAddressBox;
