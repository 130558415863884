import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FormProvider,
  useForm,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import CustomizedButton from "components/Custom/CustomizedButton";
import {
  goodsReceiveSchema,
  goodsReceiveValidation,
} from "components/Form/Inventory/GoodsReceive/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { CircularProgress, Box, Stack, Typography } from "@mui/material";
import { ActivityLogDocumentType, ActivityType } from "generated/general";
import {
  useGoodsReceiveCreateMutation,
  useGoodsReceiveUpdateMutation,
  useGoodsReceiveFindUniqueQuery,
  useInventoryDocumentCancelMutation,
  GoodsReceiveFindUniqueQuery,
  GoodsReceiveCreateInput,
  GoodsReceiveUpdateInput,
  InventoryControlDocumentType,
} from "generated/wms";
import { useActivityLog } from "hooks/use-activity-log";
import { useDisable } from "hooks/use-disable";
import { IGoodsReceive } from "types/Inventory/goodsReceive";
import GoodsReceiveHeaderBreadcrumbs from "components/Form/Inventory/InventoryControl/HeaderBreadcrumbs";
import GoodsReceiveHeader from "components/Form/Inventory/GoodsReceive/Header";
import GoodsReceiveInfo from "components/Form/Inventory/GoodsReceive/Info";
import GoodsReceiveItemList from "components/Form/Inventory/GoodsReceive/ItemList";
import {
  goodsReceiveCreateFormatter,
  goodsReceiveQueryFormatter,
  goodsReceiveUpdateFormatter,
} from "utils/Formatter/Inventory/GoodsReceive";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import { useModal } from "hooks/use-modal";
import { errorMessageFormatter } from "utils/Global";

const GoodsReceiveContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [, setDisabled] = useDisable();
  const { createActivityLog } = useActivityLog();

  const methods = useForm<IGoodsReceive>({
    defaultValues: goodsReceiveSchema,
    resolver: yupResolver<any>(goodsReceiveValidation),
  });

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { dirtyFields },
  } = methods;

  const {
    fields,
    remove,
    update: updateFieldArray,
    replace,
  } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  const status = useWatch({ control, name: "aggrid_status" });

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const { data, isLoading, isSuccess, refetch } =
    useGoodsReceiveFindUniqueQuery<GoodsReceiveFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      { enabled: !!id }
    );

  const { mutateAsync: create, isLoading: isCreating } =
    useGoodsReceiveCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useGoodsReceiveUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: cancel, isLoading: isCancelling } =
    useInventoryDocumentCancelMutation<Error>(graphQLClient);

  const formatGR = useCallback(
    (goodsReceiveType: any) => {
      const formattedGoodsReceive =
        goodsReceiveQueryFormatter(goodsReceiveType);
      reset(formattedGoodsReceive);
    },
    [reset]
  );

  useEffect(() => {
    if (state) {
      reset({
        ...state,
        created_date: dayjs(),
        posted_date: dayjs(),
      });
    }
  }, [reset, state]);

  const getGoodsReceive = useCallback(async () => {
    if (isSuccess) {
      const { GoodsReceiveFindUnique } = data;
      const goodsReceiveType = GoodsReceiveFindUnique as IGoodsReceive;
      formatGR(goodsReceiveType);
    }
  }, [data, formatGR, isSuccess]);

  useEffect(() => {
    getGoodsReceive();
  }, [getGoodsReceive]);

  useEffect(() => {
    if (id && status && (status === "finished" || status === "cancelled")) {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled, status]);

  const draftHandler = async (data: IGoodsReceive) => {
    if (!validateQty()) return;

    try {
      const formatData = id
        ? goodsReceiveUpdateFormatter(data, "draft")
        : goodsReceiveCreateFormatter(data, "draft");

      const result: any = id
        ? await update({
            uniqueInput: { id: id ? parseInt(id) : undefined },
            updateInput: formatData as GoodsReceiveUpdateInput,
          })
        : await create({ createInput: formatData as GoodsReceiveCreateInput });

      const isDirty = Object.keys(dirtyFields)?.length > 0;

      if (!id) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsReceive,
          reference_id: result.GoodsReceiveCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });
      } else {
        if (isDirty) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.GoodsReceive,
            reference_id: result.GoodsReceiveUpdate?.id,
            activity_detail: {},
          });
        }
      }

      if (!id) {
        navigate(`/inventory/goods-receive/${result.GoodsReceiveCreate.id}`);
        enqueueSnackbar(
          `${t("button.create")}ใบ${t("inventory.goods_receive.index")}สำเร็จ`,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(
          `${t("button.edit")}ใบ${t("inventory.goods_receive.index")}สำเร็จ`,
          {
            variant: "success",
          }
        );
        await refetch();
      }
    } catch (e: any) {
      if (e.message?.includes("unique id has already existed")) {
        enqueueSnackbar("เลขที่เอกสารนี้มีอยู่ในระบบแล้ว ", {
          variant: "error",
        });
        return;
      }
      if (!id) {
        enqueueSnackbar(
          `${t("button.create")}ใบ${t(
            "inventory.goods_receive.index"
          )}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(
          `${t("button.edit")}ใบ${t("inventory.goods_receive.index")}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      }
    }
  };

  const saveHandler = async (data: IGoodsReceive) => {
    try {
      const payload = goodsReceiveUpdateFormatter(
        data,
        data.main_status || ""
      ) as GoodsReceiveUpdateInput;
      const { GoodsReceiveUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: payload,
      });
      const isDirty = Object.keys(dirtyFields)?.length > 0;

      if (isDirty) {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.GoodsReceive,
          reference_id: GoodsReceiveUpdate?.id,
          activity_detail: {},
        });
      }
      await refetch();
      enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`${t("button.save_draft")}ไม่สำเร็จ`, {
        variant: "success",
      });
    }
  };

  const waitReceiveHandler = async (data: IGoodsReceive) => {
    if (!validateQty()) return;

    try {
      const formatData = id
        ? goodsReceiveUpdateFormatter(data, "wait_receive")
        : goodsReceiveCreateFormatter(data, "wait_receive");

      const result: any = id
        ? await update({
            uniqueInput: { id: id ? parseInt(id) : undefined },
            updateInput: formatData as GoodsReceiveUpdateInput,
          })
        : await create({ createInput: formatData as GoodsReceiveCreateInput });

      if (!id) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsReceive,
          reference_id: result.GoodsReceiveCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_receive",
          },
        });
      } else {
        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.GoodsReceive,
          reference_id: result.GoodsReceiveUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.main_status,
            curr_status: "wait_receive",
            updated_fields:
              formattedDirtyFields?.length > 0
                ? formattedDirtyFields
                : undefined,
          },
        });
      }

      if (!id) {
        navigate(
          `/inventory/goods-receive/${
            result.GoodsReceiveCreate.id ?? result.GoodsReceiveUpdate.id
          }`
        );
      }
      await refetch();

      if (!id) {
        enqueueSnackbar(
          `${t("button.create")}ใบ${t("inventory.goods_receive.index")}สำเร็จ`,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(`${t("sentence.continue")}สำเร็จ`, {
          variant: "success",
        });
      }
    } catch (err: any) {
      if (err.message?.includes("unique id has already existed")) {
        enqueueSnackbar("เลขที่เอกสารนี้มีอยู่ในระบบแล้ว ", {
          variant: "error",
        });
        return;
      }
      if (!id) {
        enqueueSnackbar(
          `${t("button.create")}ใบ${t(
            "inventory.goods_receive.index"
          )}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(`${t("sentence.continue")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const receiveHandler = async (data: IGoodsReceive) => {
    try {
      const formatData = goodsReceiveUpdateFormatter(
        data,
        "finished"
      ) as GoodsReceiveUpdateInput;
      const { GoodsReceiveUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: formatData,
      });

      const formattedDirtyFields = Object.keys(dirtyFields);
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.GoodsReceive,
        reference_id: GoodsReceiveUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.main_status,
          curr_status: "finished",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });

      if (!id) navigate(`/inventory/goods-receive/${GoodsReceiveUpdate.id}`);
      else {
        const goodsReceiveType = GoodsReceiveUpdate as IGoodsReceive;
        formatGR(goodsReceiveType);
      }

      enqueueSnackbar(`${t("inventory.goods_receive.index")}สำเร็จ`, {
        variant: "success",
      });
    } catch (err) {
      const message = errorMessageFormatter(err);
      if (message?.split(",").length > 0) {
        message.split(",").forEach((unique_id: string) =>
          enqueueSnackbar(`${unique_id} เกินจำนวนสั่งซื้อ`, {
            variant: "error",
          })
        );
      } else
        enqueueSnackbar(`${t("inventory.goods_receive.index")}ไม่สำเร็จ`, {
          variant: "error",
        });
    }
  };

  const cancelHandler = async () => {
    try {
      await cancel({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        documentType: InventoryControlDocumentType.GoodReceive,
      });
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.GoodsReceive,
        reference_id: parseInt(id!),
        activity_detail: {
          prev_status: status,
          curr_status: "cancelled",
        },
      });
      await refetch();
      enqueueSnackbar(
        `${t("status.cancelled")}${t("inventory.goods_receive.index")}สำเร็จ`,
        {
          variant: "success",
        }
      );
    } catch (err) {
      enqueueSnackbar(
        `${t("status.cancelled")}${t(
          "inventory.goods_receive.index"
        )}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const validateQty = () => {
    const checkBoolean: { item_unique_id: string; status: boolean }[] = [];
    const goodsReceive = getValues();
    const type = getValues("type");
    const formatData = goodsReceiveCreateFormatter(goodsReceive);

    if (type === "other") return true;

    formatData.trace_entry_list.forEach((list) => {
      if (list.qty > (list.document_item_qty || 0) - (list.posted_qty || 0))
        return checkBoolean.push({
          item_unique_id: list.item_unique_id,
          status: false,
        });
    });

    if (!checkBoolean.every((boolean) => boolean.status)) {
      checkBoolean.forEach((list) =>
        enqueueSnackbar(`${list.item_unique_id} เกินจำนวนใบสั่งซื้อ`, {
          variant: "error",
        })
      );
    }

    return checkBoolean.every((boolean) => boolean.status);
  };

  const errorHandler = (error: any) => {
    let emptyTraceError = false;
    let emptySnError = false;
    let noQtyError = false;

    if (error?.type?.message === "กรุณาระบุประเภท") {
      enqueueSnackbar("กรุณาระบุประเภท", { variant: "error" });
    }
    if (error?.source_warehouse_id?.message === "กรุณาเลือกคลัง") {
      enqueueSnackbar("กรุณาเลือกคลัง", { variant: "error" });
    }
    if (
      error?.trace_entry_list?.message === "กรุณาเพิ่มสินค้า" ||
      error?.trace_entry_list?.root?.message === "กรุณาเพิ่มสินค้า"
    ) {
      emptyTraceError = true;
    }
    if (error?.trace_entry_list && Array.isArray(error.trace_entry_list)) {
      error.trace_entry_list.forEach((trace: any) => {
        if (trace.qty?.message === "กรุณาระบุจำนวนที่นำเข้า") {
          noQtyError = true;
        }
        if (trace.serial_list?.message === "กรุณาเพิ่ม SN ในรายการสินค้า") {
          emptySnError = true;
        }
        if (Array.isArray(trace.serial_list)) {
          trace.serial_list.forEach((serial: any) => {
            if (serial.qty?.message === "กรุณาระบุจำนวนที่นำเข้า") {
              noQtyError = true;
            }
          });
        }
      });
    }

    if (emptyTraceError) {
      enqueueSnackbar("กรุณาเพิ่มสินค้า", { variant: "error" });
    }
    if (emptySnError)
      enqueueSnackbar("กรุณาเพิ่ม SN ในรายการสินค้า", { variant: "error" });
    if (noQtyError)
      enqueueSnackbar("กรุณาระบุจำนวนมากกว่า 0", { variant: "error" });
  };

  const {
    modal: receiveConfirmation,
    openModalHandler: openReceiveConfirmationHandler,
    closeModalHandler: closeReceiveConfirmationHandler,
    submitModalHandler: submitReceiveConfirmationHandler,
  } = useModal(handleSubmit(receiveHandler, errorHandler));

  if (id && (isLoading || isCreating || isUpdating || isCancelling)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <GoodsReceiveHeaderBreadcrumbs
          type={InventoryControlDocumentType.GoodReceive}
        />
        <GoodsReceiveHeader cancelHandler={cancelHandler} />
        <GoodsReceiveInfo replace={replace} />
        <GoodsReceiveItemList
          fields={fields}
          remove={remove}
          update={updateFieldArray}
          replace={replace}
        />
        <BottomNavbar>
          {(!status || status === "draft") && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                sx={{ width: "100px" }}
                variant="outlined"
                title={t("button.save_draft")}
                onClick={handleSubmit(draftHandler, errorHandler)}
              />
              <CustomizedButton
                sx={{ width: "100px" }}
                variant="contained"
                title={t("button.next")}
                onClick={handleSubmit(waitReceiveHandler, errorHandler)}
              />
            </Stack>
          )}
          {status === "wait_receive" && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                sx={{ width: "100px" }}
                variant="outlined"
                title={t("button.save_draft")}
                onClick={handleSubmit(saveHandler, errorHandler)}
              />
              <CustomizedButton
                sx={{ width: "100px" }}
                variant="contained"
                title={t("inventory.goods_receive.index")}
                onClick={() => {
                  let foundUnscanned = false;
                  for (const trace of fields) {
                    if (foundUnscanned) {
                      break;
                    }
                    if (trace.status === "is_active") {
                      foundUnscanned = true;
                      break;
                    }
                    if (trace.serial_list) {
                      for (const serial of trace.serial_list) {
                        if (serial.status === "is_active") {
                          foundUnscanned = true;
                          break;
                        }
                      }
                    }
                  }
                  if (foundUnscanned) {
                    openReceiveConfirmationHandler();
                  } else {
                    handleSubmit(receiveHandler, errorHandler)();
                  }
                }}
              />
            </Stack>
          )}
        </BottomNavbar>
      </FormProvider>
      <ConfirmationModal
        title={"ยืนยันการนำเข้าสินค้า"}
        message={
          <Box my={2}>
            <Typography textAlign="center">
              <span>{"ขณะนี้มีสินค้า"}</span>
              <span style={{ fontWeight: "bold" }}>{` "รอสแกน" `}</span>
              <span>{"อยู่"}</span>
            </Typography>
            <Typography textAlign="center">
              <span>{"หากนำเข้าแล้วจะเปลี่ยนสถานะสินค้าเป็น"}</span>
              <span style={{ fontWeight: "bold" }}>{` "ยกเลิก"`}</span>
            </Typography>
            <Typography textAlign="center">
              {"และไม่สามารถแก้ไขเอกสารได้"}
            </Typography>
          </Box>
        }
        open={receiveConfirmation}
        handleClose={closeReceiveConfirmationHandler}
        action={submitReceiveConfirmationHandler}
        noDivider
      />
    </>
  );
};

export default GoodsReceiveContainer;
