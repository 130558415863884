import { gql } from "graphql-request";

export const ITEM_SKU_QTY_BY_WAREHOUSE_VIEW = gql`
  query ItemSkuQtyByWarehousesViewAggrid($aggridInput: AnyAggridInput!) {
    ItemSkuQtyByWarehousesViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        item_id
        item_unique_id
        warehouse_id
        warehouse_unique_id
        warehouse_name
        stock_qty
        available_qty
        purchase_ordered_qty
        sale_committed_qty
      }
    }
  }
`;
