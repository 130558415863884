import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { useDisable } from "../../../hooks/use-disable";

import { Alert, Box, FormControlLabel, Grid, Typography } from "@mui/material";

import DropzoneUI from "../../UI/DropzoneUI";
import PasswordDetail from "./PasswordDetail";
import ChangePasswordDetail from "./ChangePasswordDetail";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedSwitch from "../../Custom/CustomizedSwitch";
import ControlledSelect from "../../Controller/ControlledSelect";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import ControlledServerSideCreatable from "../../Controller/ControlledSSCreatable";

import { departmentType, positionType } from "../../../utils/userInfo";

import { IUser } from "../../../types/Auth/user";
import { CreatableFieldDocumentType } from "../../../generated/general";

const UserAccountForm = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [disabled] = useDisable();

  const setting = pathname.split("/")[2] === "setting";

  const [editPassword, setEditPassword] = useState(false);

  useEffect(() => {
    if (disabled) setEditPassword(false);
  }, [disabled]);

  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<IUser>();

  const status = watch("status");

  const prefixes = [
    {
      id: 1,
      label: t("contact.contact_person.prefix.mr"),
      value: t("contact.contact_person.prefix.mr"),
    },
    {
      id: 2,
      label: t("contact.contact_person.prefix.mrs"),
      value: t("contact.contact_person.prefix.mrs"),
    },
    {
      id: 3,
      label: t("contact.contact_person.prefix.ms"),
      value: t("contact.contact_person.prefix.ms"),
    },

    {
      id: 4,
      label: t("contact.contact_person.prefix.sir"),
      value: t("contact.contact_person.prefix.sir"),
    },
    {
      id: 5,
      label: t("contact.contact_person.prefix.none"),
      value: t("contact.contact_person.prefix.none"),
    },
  ];

  const licenseTypeOptions = [
    { label: "ท.2", value: "ท.2" },
    { label: "ท.3", value: "ท.3" },
    { label: "ท.4", value: "ท.4" },
  ];

  const radioList = [
    { value: 1, label: "ใช้งาน" },
    { value: 0, label: "หยุดใช้งาน" },
  ];

  const image = getValues("img_url");

  const position = useWatch({ control, name: "position" });

  const expiredDate = useWatch({
    control,
    name: "delivery_position_details.license_expire_date",
  });

  const currentDate = dayjs();

  const isNearlyExpire =
    currentDate?.isBefore(expiredDate) &&
    currentDate.diff(expiredDate, "d") < 1 &&
    currentDate.diff(expiredDate, "d") > -13;

  const isExpired = currentDate.isAfter(expiredDate);

  useEffect(() => {
    if (position === "พนักงานขนส่ง") setValue("is_delivery_position", 1);
    else setValue("is_delivery_position", 0);
  }, [position, setValue]);

  return (
    <>
      <CustomizedBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
              {t("user.title.information")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <ControlledSelect
                  control={control}
                  name="title_name"
                  error={Boolean(errors.title_name)}
                  helperText={errors.title_name?.message}
                  label={t("user.account.title_name")}
                  options={prefixes}
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <ControlledTextField
                  control={control}
                  name="nick_name"
                  error={Boolean(errors.nick_name)}
                  helperText={errors.nick_name?.message}
                  label={t("user.account.nick_name")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <ControlledTextField
                  control={control}
                  name="first_name"
                  error={Boolean(errors.first_name)}
                  helperText={errors.first_name?.message}
                  label={t("user.account.first_name")}
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <ControlledTextField
                  control={control}
                  name="last_name"
                  error={Boolean(errors.last_name)}
                  helperText={errors.last_name?.message}
                  label={t("user.account.last_name")}
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <ControlledTextField
                  control={control}
                  name="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  label={t("user.account.email")}
                  disabled={Boolean(id)}
                  required
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <ControlledTextField
                  control={control}
                  name="phone"
                  error={Boolean(errors.phone)}
                  helperText={errors.phone?.message}
                  label={t("user.account.phone")}
                  disabled={disabled}
                  required
                />
              </Grid>
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("user.title.organic")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledServerSideCreatable
                  label={t("user.account.department")}
                  control={control}
                  name="department"
                  documentType={CreatableFieldDocumentType.Employee}
                  defaultOptions={departmentType}
                  error={Boolean(errors.department)}
                  helperText={errors.department?.message}
                  setValue={setValue}
                  disabled={disabled}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledServerSideCreatable
                  label={t("user.account.position")}
                  control={control}
                  name="position"
                  documentType={CreatableFieldDocumentType.Employee}
                  defaultOptions={positionType}
                  error={Boolean(errors.position)}
                  helperText={errors.position?.message}
                  setValue={setValue}
                  disabled={disabled}
                  required
                />
              </Grid>
            </Grid>
            {position === "พนักงานขนส่ง" && (
              <Box padding={"0 1rem"}>
                <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
                  {t("user.account.delivery_position_details.index")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControlledDatePicker
                      control={control}
                      name="delivery_position_details.dob"
                      label={t("user.account.delivery_position_details.dob")}
                      disabled={disabled}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControlledTextField
                      control={control}
                      name="delivery_position_details.identity_no"
                      error={Boolean(
                        errors.delivery_position_details?.identity_no
                      )}
                      helperText={
                        errors.delivery_position_details?.identity_no?.message
                      }
                      label={t(
                        "user.account.delivery_position_details.identity_no"
                      )}
                      disabled={disabled}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControlledSelect
                      control={control}
                      name="delivery_position_details.license_type"
                      error={Boolean(
                        errors.delivery_position_details?.license_type
                      )}
                      helperText={
                        errors.delivery_position_details?.license_type?.message
                      }
                      label={t(
                        "user.account.delivery_position_details.license_type"
                      )}
                      options={licenseTypeOptions}
                      disabled={disabled}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControlledTextField
                      control={control}
                      name="delivery_position_details.license_no"
                      error={Boolean(
                        errors.delivery_position_details?.license_no
                      )}
                      helperText={
                        errors.delivery_position_details?.license_no?.message
                      }
                      label={t(
                        "user.account.delivery_position_details.license_no"
                      )}
                      disabled={disabled}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControlledDatePicker
                      control={control}
                      name="delivery_position_details.license_expire_date"
                      label={t(
                        "user.account.delivery_position_details.license_expire_date"
                      )}
                      disabled={disabled}
                      required
                    />
                  </Grid>
                  {(isNearlyExpire || isExpired) && (
                    <Grid item xs={12} sm={12} md={4}>
                      <Alert
                        sx={{
                          padding: "0 16px",
                          color: "#333333",
                          backgroundColor: "error.light",
                        }}
                        severity="error"
                      >
                        {t(
                          `user.account.delivery_position_details.sentence.${
                            isNearlyExpire ? "nearly_" : ""
                          }expired_license`
                        )}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
            {id && (
              <FormControlLabel
                sx={{ mt: 2 }}
                control={
                  <CustomizedSwitch
                    sx={{ ml: 2, mr: 1 }}
                    color="primary"
                    checked={editPassword}
                    onChange={(event) => setEditPassword(event.target.checked)}
                    disabled={disabled}
                  />
                }
                label={t("user.title.change_password")}
              />
            )}
            {(!id || (id && !setting && editPassword)) && <PasswordDetail />}
            {id && setting && editPassword && <ChangePasswordDetail />}
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              สถานะ
            </Typography>
            <Grid container spacing={2} ml={1}>
              <Grid xs={12}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <CustomizedRadioGroup
                      {...field}
                      radioList={radioList}
                      disabled={disabled}
                      row
                    />
                  )}
                />
              </Grid>
              {["0", 0].includes(status) && (
                <Grid xs={12} md={6} mt={1}>
                  <ControlledTextField
                    control={control}
                    name="remark_status"
                    error={Boolean(errors.remark_status)}
                    helperText={errors.remark_status?.message}
                    label={t("user.account.remark")}
                    disabled={disabled}
                    required
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={1.5} xl={1.5} />
          {((image && image.length > 0) || !disabled) && (
            <Grid item xs={12} sm={12} md={12} lg={2.5} xl={2.5}>
              <DropzoneUI
                control={control}
                name={"img_url"}
                setValue={setValue}
                acceptedFileType="image"
                maxSize={1}
                disabled={disabled}
              />
            </Grid>
          )}
        </Grid>
      </CustomizedBox>
    </>
  );
};

export default UserAccountForm;
