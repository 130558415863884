import { useCallback, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "components/UI/AgGrid";
import {
  ColDef,
  GridReadyEvent,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { useBillingNoteColumnDefs } from "./columnDefs";
import { useNavigate } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { dateFilterModel } from "utils/AgGridFilter";
import { BILLING_NOTE_VIEW } from "services/AgGrid/SalesAggrid";

interface Props {
  isFilter?: string | null;
  referenceUniqueId?: string | null;
}

const BillingNoteTable = ({ isFilter, referenceUniqueId }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const columnDef: ColDef[] | undefined = useBillingNoteColumnDefs();

  const graphQLClientWithHeader: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        issue_date,
        due_date,
        related_user_object_list,
        created_by_object,
        ...otherFilterModel
      } = filterModel;
      const formatFilter = {
        ...otherFilterModel,
        reference_delivery_trip_unique_id_list: referenceUniqueId &&
          referenceUniqueId?.trim() !== "" && {
            type: "hasSome",
            filterType: "array",
            values: [referenceUniqueId],
          },
        issue_date: dateFilterModel(issue_date),
        due_date: dateFilterModel(due_date),
        created_by: created_by_object,
        related_user_list: related_user_object_list
          ? {
              filterType: "array",
              type: "hasSome",
              values: related_user_object_list.values,
            }
          : undefined,
      };
      try {
        const { BillingNoteViewsAggrid } =
          await graphQLClientWithHeader.request(BILLING_NOTE_VIEW, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: BillingNoteViewsAggrid.results as any[],
          rowCount: BillingNoteViewsAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instance = params.api.getFilterInstance("aggrid_status");
      switch (isFilter) {
        case "draft":
          instance?.setModel({ values: ["draft"] });
          break;
        case "wait_payment":
          instance?.setModel({ values: ["wait_payment"] });
          break;
        case "fully_payment":
          instance?.setModel({ values: ["fully_payment"] });
          break;
        case "cancelled":
          instance?.setModel({ values: ["cancelled"] });
          break;
        default:
          instance?.setModel({
            values: ["draft", "wait_payment", "fully_payment"],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/sales/billing-note/${encodeURIComponent(params.data.id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onRowDoubleClicked={onRowDoubleClicked}
      onGridReady={onGridReady}
      path={"/sales/billing-note"}
    />
  );
};

export default BillingNoteTable;
