import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import dayjs from "dayjs";
import {
  DashboardPurchaseInput,
  DashboardPurchaseQuery,
  DateRange,
  useDashboardPurchaseQuery,
} from "generated/general";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IDashboardDateFilter } from "types/global";
import DashboardDateFilter from "../DateFilter";
import PurchaseOrderChart from "./PurchaseOrderChart";
import PurchaseReturnChart from "./PurchaseReturnChart";
import { InputMaybe } from "generated/purchase";

const PurchaseDashboard = () => {
  const { t } = useTranslation();
  const graphQLClient = createGraphQLClientWithMiddleware("general");
  const [input, setInput] = useState<InputMaybe<DashboardPurchaseInput>>({
    date_range: DateRange.Last_7Days,
    start_date: dayjs().startOf("day").subtract(6, "day"),
    end_date: dayjs().endOf("day"),
  });
  const { data, refetch, isFetching } =
    useDashboardPurchaseQuery<DashboardPurchaseQuery>(
      graphQLClient,
      {
        input,
      },
      {
        enabled: !!input,
      }
    );

  const filterHandler = async (data: IDashboardDateFilter) => {
    await setInput(data);
    await refetch();
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <CustomizedBox
      bgcolor={"#F9F9F9"}
      border={`1px solid white`}
      maxWidth={1650}
      margin={0}
      minHeight={316}
      height={"100%"}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h5">{t("purchase.index")}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <DashboardDateFilter
            filterHandler={filterHandler}
            isLoading={isFetching}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={6}>
            {isFetching ? (
              <Skeleton variant="rectangular" height={212} />
            ) : (
              <Box sx={{ minHeight: 212, height: "100%" }}>
                <PurchaseOrderChart
                  data={data?.DashboardPurchase?.purchase_order}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {isFetching ? (
              <Skeleton variant="rectangular" height={212} />
            ) : (
              <Box sx={{ minHeight: 212, height: "100%" }}>
                <PurchaseReturnChart
                  data={data?.DashboardPurchase?.purchase_return}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </CustomizedBox>
  );
};

export default PurchaseDashboard;
