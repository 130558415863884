import { styled } from "@mui/system";
import { Box, BoxProps, Typography, Grid } from "@mui/material";
import { AdditionalDiscountType } from "generated/purchase";
import { numberFormatter } from "utils/Formatter/Global";

const CustomizedSummary = styled(Box)<BoxProps>(({ bgcolor, paddingY }) => ({
  "&": {
    backgroundColor: bgcolor,
    display: "grid",
    justifyContent: "flex-end",
    textAlign: "right",
    gridGap: "10px",
    gap: "10px",
    gridTemplateColumns:
      "minmax(min-content,max-content) minmax(7rem,max-content) 24px",
    paddingY: paddingY,
    paddingRight: "24px",
  },
}));

interface Props {
  data: any;
  documentType?: string;
}

const PDFFooter = ({ data, documentType }: Props) => {
  const fontSize = 10.66;

  console.log(documentType, "doc type");

  return (
    <>
      <Grid container spacing={1.5} sx={{ breakInside: "avoid" }}>
        <Grid item xs={6}>
          <Typography fontSize={fontSize} fontWeight={600} lineHeight={2}>
            หมายเหตุ
          </Typography>
          <Typography
            fontSize={fontSize}
            sx={{
              whiteSpace: "pre-line",
              wordBreak: "break-word",
              lineHeight: "16px",
            }}
          >
            {data.remark || "-"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {data.additional_discount > 0 && (
            <CustomizedSummary>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                ส่วนลดเพิ่มเติม
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(data.additional_discount)}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {data.additional_discount_type ===
                AdditionalDiscountType.Percent
                  ? "%"
                  : "บาท"}
              </Typography>
            </CustomizedSummary>
          )}
          {data.additional_discount_type === AdditionalDiscountType.Percent && (
            <CustomizedSummary>
              <Box />
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(
                  data.pre_vat_amount * (data.additional_discount / 100)
                )}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          )}
          {documentType !== "quotation" && documentType !== "billing_note" && (
            <CustomizedSummary>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                มูลค่าหลังหักส่วนลด
              </Typography>

              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(data.post_discount_amount)}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          )}
          {data.vat_exempted_amount > 0 && documentType !== "billing_note" && (
            <CustomizedSummary>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                มูลค่ารายการยกเว้นภาษี
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(data.vat_exempted_amount)}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          )}
          {data.vat_0_percent_amount > 0 && documentType !== "billing_note" && (
            <CustomizedSummary>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                มูลค่ารายการภาษี 0%
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(data.vat_0_percent_amount)}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          )}
          {data.vat_7_percent_amount > 0 && documentType !== "billing_note" && (
            <CustomizedSummary>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                มูลค่ารายการภาษี 7%
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(data.vat_7_percent_amount)}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          )}
          {documentType !== "billing_note" && (
            <CustomizedSummary>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                ภาษีมูลค่าเพิ่มรวม
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                {numberFormatter(data.vat_amount)}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                lineHeight={2}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          )}

          <CustomizedSummary
            bgcolor={"#ECF6FD"}
            paddingY={0.5}
            borderRadius="4px"
          >
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              fontWeight={600}
            >
              มูลค่ารวมสุทธิ
            </Typography>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              fontWeight={600}
            >
              {numberFormatter(data.net_amount)}
            </Typography>
            <Typography
              fontSize={fontSize}
              color={"primary.main"}
              fontWeight={600}
            >
              บาท
            </Typography>
          </CustomizedSummary>
          {data.withholding_tax_amount > 0 &&
            documentType !== "billing_note" && (
              <CustomizedSummary>
                <Typography
                  fontSize={fontSize}
                  color={"primary.main"}
                  lineHeight={2}
                >
                  ภาษีหัก ณ ที่จ่าย
                </Typography>
                <Typography
                  fontSize={fontSize}
                  color={"primary.main"}
                  lineHeight={2}
                >
                  {numberFormatter(data.withholding_tax_amount)}
                </Typography>
                <Typography
                  fontSize={fontSize}
                  color={"primary.main"}
                  lineHeight={2}
                >
                  บาท
                </Typography>
              </CustomizedSummary>
            )}
          {documentType !== "billing_note" && (
            <CustomizedSummary>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                fontWeight={600}
                lineHeight={2}
              >
                จำนวนเงินชำระทั้งสิ้น
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                fontWeight={600}
                lineHeight={2}
              >
                {numberFormatter(data.total_amount)}
              </Typography>
              <Typography
                fontSize={fontSize}
                color={"primary.main"}
                fontWeight={600}
                lineHeight={2}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PDFFooter;
