import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";

import { IBreadcrumbsAndMenu } from "../../types/global";

const Logistic = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.vehicle.index"),
      to: "/logistic/vehicle",
    },
    {
      name: t("logistic.tire.index"),
      to: "/logistic/tire",
    },
    {
      name: t("logistic.delivery_trip.index"),
      to: "/logistic/delivery-trip",
    },
    {
      name: t(`logistic.location.index`),
      to: "/logistic/location",
    },
    {
      name: t("logistic.report.index"),
      to: "/logistic/report",
    },
    {
      name: t("logistic.gantt_chart.index"),
      to: "/logistic/gantt-chart",
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Logistic;
