import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ModalUI from "../ModalUI";
import { Divider } from "@mui/material";
import dayjs from "dayjs";

interface ModalComponentProps {
  open: boolean;
  handleClose: () => void;
  data: any;
}

const DeliveryTaskInfoModal: React.FC<ModalComponentProps> = ({
  open,
  handleClose,
  data,
}) => {
  const getStatusName = (status: string) => {
    switch (status) {
      case "in_progress":
        return "ดำเนินการ";
      case "available":
        return "ว่าง";
      case "in_active":
        return "หยุดใช้งาน";
      case "maintenance":
        return "ระหว่างซ่อม";
      case "incident":
        return "อุบัติเหตุ";
      default:
        return status;
    }
  };

  const formatDateNoTime = (date: string) => {
    const result = dayjs(date).format("DD/MM/YYYY");
    return result;
  };

  const renderStatus = () => {
    let bgColor = "";
    let textColor = "";

    if (data?.status === "in_progress") {
      bgColor = "#E8F3FD";
      textColor = "#2167D3";
    } else if (data?.status === "maintenance") {
      bgColor = "#FFF4E5";
      textColor = "#FB8C00";
    } else if (data?.status === "incident") {
      bgColor = "#FCE8E8";
      textColor = "#E41B1B";
    } else if (data?.status === "in_active") {
      bgColor = "#BDBDBD";
      textColor = "#FFFFFF";
    } else if (data?.status === "available") {
      bgColor = "#E6F9F0";
      textColor = "#22915A";
    }

    return (
      <Typography
        sx={{
          my: "auto",
          px: 2,
          py: 0.4,
          width: "100px",
          textAlign: "center",
          bgcolor: bgColor,
          color: textColor,
          borderRadius: "4px",
        }}
      >
        {getStatusName(data?.status)}
      </Typography>
    );
  };

  const isMAIncident = (data: any) => {
    return data.task.type === "MA" && data.task.info.status === "incident";
  };

  return (
    <>
      <ModalUI
        open={open}
        handleClose={handleClose}
        startIcon={
          <Box display="flex" gap={"16px"}>
            <Typography fontSize="24px" fontWeight={600}>
              ข้อมูลรถ
            </Typography>
            {renderStatus()}
          </Box>
        }
        closeIconSize={16}
      >
        <Divider />
        {data && (
          <Box sx={{ fontSize: "14px" }}>
            {data.status !== "in_active" ? (
              <>
                <Box display="flex" sx={{ mt: 1 }}>
                  <Typography fontWeight={600} flex={0.5}>
                    {data.task.type === "DT"
                      ? "เลขที่เอกสาร (ใบงานจัดส่ง)"
                      : "เลขที่เอกสาร (ใบแจ้งซ่อม)"}
                  </Typography>
                  <Typography flex={1}>
                    {data.task.info.document_id
                      ? data.task.info.document_id
                      : "-"}
                  </Typography>
                </Box>
                {data.task.type === "DT" && (
                  <Box display="flex" sx={{ mt: 1 }}>
                    <Typography fontWeight={600} flex={0.5}>
                      Job No.
                    </Typography>
                    <Typography flex={1}>
                      {data.task.info.job_no ? data.task.info.job_no : "-"}
                    </Typography>
                  </Box>
                )}
                {isMAIncident(data) && (
                  <>
                    <Box display="flex" sx={{ mt: 1 }}>
                      <Typography fontWeight={600} flex={0.5}>
                        เลขที่เอกสาร (ใบงานจัดส่ง)
                      </Typography>
                      <Typography flex={1}>
                        {data.task.info.reference_document_no
                          ? data.task.info.reference_document_no
                          : "-"}
                      </Typography>
                    </Box>
                  </>
                )}
                <Box display="flex" sx={{ mt: 1 }}>
                  <Typography fontWeight={600} flex={0.5}>
                    รหัสรถ
                  </Typography>
                  <Typography flex={1}>
                    {data.task.info.car_id ? data.task.info.car_id : "-"}
                  </Typography>
                </Box>

                {data.task.type === "DT" && (
                  <>
                    <Box display="flex" sx={{ mt: 1 }}>
                      <Typography fontWeight={600} flex={0.5}>
                        หมายเลขทะเบียนรถ
                      </Typography>
                      <Typography flex={1}>
                        {data.task.info.car_license
                          ? data.task.info.car_license
                          : "-"}
                      </Typography>
                    </Box>
                    <Box display="flex" sx={{ mt: 1 }}>
                      <Typography fontWeight={600} flex={0.5}>
                        รหัสรถ (หางลาก)
                      </Typography>
                      <Typography flex={1}>
                        {data.task?.info.trailer_unique_id
                          ? data.task?.info.trailer_unique_id
                          : "-"}
                      </Typography>
                    </Box>
                    <Box display="flex" sx={{ mt: 1 }}>
                      <Typography fontWeight={600} flex={0.5}>
                        หมายเลขทะเบียนหางลาก
                      </Typography>
                      <Typography flex={1}>
                        {data.task?.info.trailer_license_id
                          ? data.task?.info.trailer_license_id
                          : "-"}
                      </Typography>
                    </Box>
                    <Box display="flex" sx={{ mt: 1 }}>
                      <Typography fontWeight={600} flex={0.5}>
                        พนักงานขนส่ง
                      </Typography>
                      <Typography flex={1}>
                        {data.task.info.deliver_1
                          ? data.task.info.deliver_1
                          : "-"}
                      </Typography>
                    </Box>
                    <Box display="flex" sx={{ mt: 1 }}>
                      <Typography fontWeight={600} flex={0.5}>
                        พนักงานขนส่ง 2
                      </Typography>
                      <Typography flex={1}>
                        {data.task.info.deliver_2
                          ? data.task.info.deliver_2
                          : "-"}
                      </Typography>
                    </Box>
                  </>
                )}
                {data.task.type === "MA" && (
                  <>
                    <Box display="flex" sx={{ mt: 1 }}>
                      <Typography fontWeight={600} flex={0.5}>
                        หมายเลขทะเบียนรถ
                      </Typography>
                      <Typography flex={1}>
                        {data.task.info.car_license
                          ? data.task.info.car_license
                          : "-"}
                      </Typography>
                    </Box>
                    <Box display="flex" sx={{ mt: 1 }}>
                      <Typography fontWeight={600} flex={0.5}>
                        รหัสรถ (หางลาก)
                      </Typography>
                      <Typography flex={1}>
                        {data.task?.info.trailer_unique_id
                          ? data.task?.info.trailer_unique_id
                          : "-"}
                      </Typography>
                    </Box>
                    <Box display="flex" sx={{ mt: 1 }}>
                      <Typography fontWeight={600} flex={0.5}>
                        หมายเลขทะเบียนหางลาก
                      </Typography>
                      <Typography flex={1}>
                        {data.task?.info.trailer_license_id
                          ? data.task?.info.trailer_license_id
                          : "-"}
                      </Typography>
                    </Box>
                  </>
                )}

                <Box display="flex" sx={{ mt: 1 }}>
                  <Typography fontWeight={600} flex={0.5}>
                    {data.status === "in_progress"
                      ? "วันที่เวลาเริ่มต้น (แผน)"
                      : data.task.info.status === "incident"
                      ? "วันที่เริ่มซ่อม"
                      : data.task.info.status === "maintenance"
                      ? "วันที่เริ่มซ่อม"
                      : "วันที่เริ่มต้น"}
                  </Typography>
                  <Typography flex={1}>
                    {data.task.type === "MA" && data.task.info.start_date
                      ? formatDateNoTime(data.start_date)
                      : data.task.info.start_date
                      ? data.task.info.start_date
                      : "-"}
                  </Typography>
                </Box>
                <Box display="flex" sx={{ mt: 1 }}>
                  <Typography fontWeight={600} flex={0.5}>
                    {data.task.info.status === "in_progress"
                      ? "วันที่เวลาสิ้นสุด (แผน)"
                      : data.task.info.status === "incident"
                      ? "วันที่เสร็จสิ้น"
                      : data.task.info.status === "maintenance"
                      ? "วันที่เสร็จสิ้น"
                      : "วันที่เวลาสิ้นสุด (แผน)"}
                  </Typography>
                  <Typography flex={1}>
                    {data.task.type === "MA" && data.end_date
                      ? formatDateNoTime(data.end_date)
                      : data.task.info.end_date
                      ? data.task.info.end_date
                      : "-"}
                  </Typography>
                </Box>
                {data.status === "in_progress" && (
                  <Box display="flex" sx={{ mt: 1 }}>
                    <Typography fontWeight={600} flex={0.5}>
                      สถานที่ (สถานที่แรก-สุดท้าย)
                    </Typography>
                    <Typography flex={1}>
                      {data.task.info.stop ? data.task.info.stop : "-"}
                    </Typography>
                  </Box>
                )}
                {data.task.type === "MA" && (
                  <Box display="flex" sx={{ mt: 1 }}>
                    <Typography fontWeight={600} flex={0.5}>
                      {data.task.info.status === "maintenance"
                        ? "รายละเอียดข้อมูลใบแจ้งซ่อม"
                        : "รายละเอียดอุบัติเหตุ"}
                    </Typography>
                    <Typography flex={1}>
                      {data.task.info.remark ? data.task.info.remark : "-"}
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Box display="flex" sx={{ mt: 1 }}>
                  <Typography fontWeight={600} flex={0.5}>
                    รหัสรถ
                  </Typography>
                  <Typography flex={1}>
                    {data.car_id ? data.car_id : "-"}
                  </Typography>
                </Box>
                <Box display="flex" sx={{ mt: 1 }}>
                  <Typography fontWeight={600} flex={0.5}>
                    หมายเลขทะเบียนรถ
                  </Typography>
                  <Typography flex={1}>
                    {data.car.license ? data.car.license : "-"}
                  </Typography>
                </Box>
                <Box display="flex" sx={{ mt: 1 }}>
                  <Typography fontWeight={600} flex={0.5}>
                    รหัสรถ (หางลาก)
                  </Typography>
                  <Typography flex={1}>
                    {data.trailer?.unique_id ? data.trailer?.unique_id : "-"}
                  </Typography>
                </Box>
                <Box display="flex" sx={{ mt: 1 }}>
                  <Typography fontWeight={600} flex={0.5}>
                    หมายเลขทะเบียนหางลาก
                  </Typography>
                  <Typography flex={1}>
                    {data.trailer?.register_id ? data.trailer.register_id : "-"}
                  </Typography>
                </Box>
                <Box display="flex" sx={{ mt: 1 }}>
                  <Typography fontWeight={600} flex={0.5}>
                    วันที่หยุดใช้งาน
                  </Typography>
                  <Typography flex={1}>
                    {data.stop_date ? data.stop_date : "-"}
                  </Typography>
                </Box>
                <Box display="flex" sx={{ mt: 1 }}>
                  <Typography fontWeight={600} flex={0.5}>
                    หมายเหตุ
                  </Typography>
                  <Typography flex={1}>
                    {data.remark ? data.remark : "-"}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        )}
      </ModalUI>
    </>
  );
};

export default DeliveryTaskInfoModal;
