import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "components/Controller/ControlledTextField";

import BillingAddressModal from "../../../UI/Modal/BillingAddressModal";
import DeliveryAddressModal from "../../../UI/Modal/DeliveryAddressModal";

import { useModal } from "../../../../hooks/use-modal";
import { useDisable } from "../../../../hooks/use-disable";
import { countryFormatter } from "../../../../utils/Formatter/Global";

type Props = {
  forceDisabled?: boolean;
  documentType?: string;
};

const AddressInfo = ({ forceDisabled, documentType }: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const { control, getValues, watch } = useFormContext();

  const supplierUniqueId = watch("supplier_unique_id");

  const {
    modal: openBillingAddress,
    openModalHandler: openBillingAddressHandler,
    closeModalHandler: closeBillingAddress,
  } = useModal();

  const {
    modal: openDeliveryAddress,
    openModalHandler: openDeliveryAddressHandler,
    closeModalHandler: closeDeliveryAddress,
  } = useModal();

  const renderAddressString = (values: string) => {
    const addressValues = getValues(`supplier_details[${values}]`);
    if (addressValues) {
      const { name, sub_district, district, province, postal_code, country } =
        addressValues;

      const orderedValues = {
        name,
        sub_district,
        district,
        province,
        postal_code,
        country,
      };
      let result = "";

      if (addressValues && orderedValues) {
        if (Array.isArray(addressValues)) {
          addressValues.forEach((item) => {
            if (item?.length > 0) {
              result = result + item + ", ";
            }
          });
        } else {
          const keys = Object.keys(orderedValues);
          keys.forEach((key) => {
            let newValue = "";
            if (key === "country") {
              newValue =
                countryFormatter(
                  orderedValues[key as keyof typeof orderedValues]
                ) || "";
            } else {
              newValue = orderedValues[key as keyof typeof orderedValues];
            }

            if (newValue && newValue.length > 0) {
              result = result + newValue + ", ";
            }
          });
        }
        if (result?.trim().length === 0) {
          return "-";
        }
      }
      // remove whitespace and last comma
      return result?.trim().slice(0, -1);
      // remove whitespace and last comma
    } else return "-";
  };

  const renderAddressContact = () => {
    const addressValues = getValues("supplier_details.delivery_address");

    if (addressValues) {
      const { contact_name, contact_phone } = addressValues;

      if (contact_name && contact_phone) {
        const result = `${contact_name}, ${contact_phone}`;
        return result;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  return (
    <Fragment>
      <CustomizedBox margin={0}>
        <Box display="flex" gap={1} alignItems="center" mb={2}>
          <Typography fontWeight={600}>
            {t("address.billing_address")}
          </Typography>
          {!disabled && !forceDisabled && supplierUniqueId ? (
            <CustomizedTooltip title={`แก้ไข${t("address.billing_address")}`}>
              <IconButton onClick={openBillingAddressHandler} color="primary">
                <EditIcon />
              </IconButton>
            </CustomizedTooltip>
          ) : null}
        </Box>
        <Typography my={2}>{renderAddressString("billing_address")}</Typography>
        <Box display="flex" gap={1} alignItems="center" mb={2}>
          <Typography fontWeight={600}>
            {documentType === "purchase_return"
              ? t("address.delivery")
              : t("address.delivery_address")}
          </Typography>
          {!disabled && !forceDisabled && supplierUniqueId ? (
            <CustomizedTooltip title={`แก้ไข${t("address.delivery_address")}`}>
              <IconButton onClick={openDeliveryAddressHandler} color="primary">
                <EditIcon />
              </IconButton>
            </CustomizedTooltip>
          ) : null}
        </Box>
        <Typography mt={2}>{renderAddressContact()}</Typography>
        <Grid container mb={2}>
          <Grid item xs={4}>
            <ControlledTextField
              control={control}
              label={t("contact.address.contact_person_name")}
              name="supplier_details.delivery_address.address_contact_name"
              viewMode={true}
            />
          </Grid>
          <Grid item xs={4}>
            <ControlledTextField
              control={control}
              label={t("contact.address.phone_number")}
              name="supplier_details.delivery_address.address_contact_phone"
              viewMode={true}
            />
          </Grid>
        </Grid>
        <Typography>{renderAddressString("delivery_address")}</Typography>
      </CustomizedBox>
      <BillingAddressModal
        name="supplier_details"
        open={openBillingAddress}
        handleClose={closeBillingAddress}
        viewMode={false}
      />
      <DeliveryAddressModal
        name="supplier_details"
        open={openDeliveryAddress}
        handleClose={closeDeliveryAddress}
        viewMode={false}
      />
    </Fragment>
  );
};

export default AddressInfo;
