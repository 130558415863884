import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "components/Custom/CustomizedMenuList";
import { useTranslation } from "react-i18next"
import { IBreadcrumbsAndMenu } from "types/global";

const DeliveryReport = () => {
    const { t } = useTranslation();
    const breadcrumbs: IBreadcrumbsAndMenu[] = [
        {
            name: t("logistic.index"),
            to: "/logistic"
        },
        {
            name: t("report")
        },
    ];

    const menuList: IBreadcrumbsAndMenu[] = [
        {
            name: t("logistic.report.delivery_trip"),
            to: "/logistic/report/delivery_trip",
        },
        {
            name: t("logistic.report.delivery_trip_work"),
            to: "/logistic/report/delivery_trip_work",
        },
        {
            name: t("logistic.report.delivery_trip_income"),
            to: "/logistic/report/delivery_trip_income",
        },
        {
            name: t("logistic.report.delivery_trip_expenses"),
            to: "/logistic/report/delivery_trip_expenses",
        },
        {
            name: t("reports.delivery_trip_income_by_item"),
            to: "delivery_trip_income_by_item"
        },
        {
            name: t("reports.delivery_trip_income_by_customer"),
            to: "delivery_trip_income_by_customer"
        },
    ];

    return (
        <>
            <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
            <CustomizedMenuList menuList={menuList} />
        </>
    );
};

export default DeliveryReport;