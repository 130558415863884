import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { usePurchaseReport } from "hooks/Purchase/PurchaseReport/use-purchase-report";
import { PurchaseExportType } from "generated/purchase";
import { PurchaseBySupplierTable } from "components/Table/Purchase/Report/PurchaseBySupplierTable";

const PurchaseBySupplier = () => {
  const { t } = useTranslation();

  const gridRef = useRef<AgGridReact>(null);

  const { onBtnExport, isLoading } = usePurchaseReport(
    gridRef,
    PurchaseExportType.PurchaseOrderBySupplier
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("report"),
      to: "/purchase/report",
    },
    {
      name: t("purchase.report.purchase_by_supplier"),
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth={true}>
        <Typography variant="h5">
          {t("purchase.report.purchase_by_supplier")}
        </Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
          disabled={isLoading}
        />
      </HeaderLayout>
      <PurchaseBySupplierTable gridRef={gridRef} />
    </>
  );
};

export default PurchaseBySupplier;
