import { gql } from "graphql-request";

export const DELIVERY_TRIP_VIEW = gql`
  query DeliveryTripViewAggrid($aggridInput: AnyAggridInput) {
    DeliveryTripViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        main_status
        flag_status
        aggrid_status
        vehicle_id
        vehicle_no
        vehicle_license_plate
        vehicle_type
        trailer_license_plate
        customer_unique_id
        customer_name
        driver
        driver_object_list
        income_total_amount
        expenses_total_amount
        plan_start_date
        plan_end_date
      }
    }
  }
`;

export const DELIVERY_TRIP_FOR_BILLING_NOTE_VIEW = gql`
  query DeliveryTripForBillingNoteViewAggrid($aggridInput: AnyAggridInput) {
    DeliveryTripForBillingNoteViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        issue_date
        customer_unique_id
        customer_name
        vehicle_id
        vehicle_no
        vehicle_license_plate
        vehicle_type
        trailer_license_plate
        income_net_amount
        expenses_net_amount
        main_status
        flag_status
        aggrid_status
        work_list
        income_post_discount_amount
        income_vat_exempted_amount
        income_vat_0_percent_amount
        income_vat_7_percent_amount
        income_vat_amount
        income_net_amount
        income_withholding_tax_amount
        income_total_amount
        billing_total_amount
        exceed_total_amount
        remark
        vehicle
        customer_details
        has_billing_note
        reference_document_list
        billing_note_unique_id_list
      }
    }
  }
`;

export const DELIVERY_TRIP_FIND_ID = gql`
  query DeliveryTripFindUnique($uniqueInput: DeliveryUniqueInput!) {
    DeliveryTripFindUnique(uniqueInput: $uniqueInput) {
      id
      unique_id
    }
  }
`;
