import { useWatch } from "react-hook-form";
import { IDefaultForm } from "types/global";
import { IDeliveryTripList } from "types/Sales/billing-note";
import { useEffect, useState } from "react";
import { formatPriceTwoDecimal } from "utils/Global";

export const useBillingNoteSummary = (
  control: IDefaultForm["control"],
  setValue: IDefaultForm["setValue"],
  name: "item_list"
) => {
  const itemList: IDeliveryTripList[] = useWatch({
    control,
    name,
  });

  const priceVatType: string = useWatch({
    control,
    name: "income_post_discount_amount",
  });

  const [summary, setSummary] = useState({
    income_post_discount_amount: 0,
    income_vat_exempted_amount: 0,
    income_vat_0_percent_amount: 0,
    income_vat_7_percent_amount: 0,
    income_vat_amount: 0,
    income_net_amount: 0,
  });

  useEffect(() => {
    setSummary(
      ({
        income_net_amount,
        income_post_discount_amount = 0,
        income_vat_0_percent_amount,
        income_vat_7_percent_amount,
        income_vat_amount,
        income_vat_exempted_amount,
      }) => {
        const billing_note_ratios = itemList.map
          ? itemList.map((item) => {
              return (
                formatPriceTwoDecimal(
                  (item.income_post_discount_amount /
                    (item.income_net_amount ?? 0)) *
                    100
                ) ?? 0
              );
            })
          : [];

        income_post_discount_amount =
          itemList.reduce((acc, item) => {
            return acc + (item?.income_post_discount_amount ?? 0);
          }, 0) || 0;

        income_vat_exempted_amount =
          itemList.reduce((acc, item, index) => {
            return (
              acc +
              (item?.income_vat_exempted_amount ?? 0) *
                (billing_note_ratios[index] / 100)
            );
          }, 0) || 0;

        income_vat_0_percent_amount =
          itemList.reduce((acc, item, index) => {
            return (
              acc +
              (item?.income_vat_0_percent_amount ?? 0) *
                (billing_note_ratios[index] / 100)
            );
          }, 0) || 0;

        income_vat_7_percent_amount =
          itemList.reduce((acc, item, index) => {
            return (
              acc +
              (item?.income_vat_7_percent_amount ?? 0) *
                (billing_note_ratios[index] / 100)
            );
          }, 0) || 0;

        income_vat_amount =
          itemList.reduce((acc, item, index) => {
            return (
              acc +
              formatPriceTwoDecimal(
                (billing_note_ratios[index] / 100) *
                  (item.income_vat_amount ?? 0)
              )
            );
          }, 0) || 0;

        income_net_amount =
          itemList.reduce((acc, item) => {
            return acc + (item?.income_post_discount_amount ?? 0);
          }, 0) || 0;

        return {
          income_net_amount,
          income_post_discount_amount,
          income_vat_0_percent_amount,
          income_vat_7_percent_amount,
          income_vat_amount,
          income_vat_exempted_amount,
        };
      }
    );
  }, [itemList, priceVatType]);

  useEffect(() => {
    setValue("post_discount_amount", summary.income_post_discount_amount);
    setValue("post_discount_amount", summary.income_post_discount_amount);
    setValue("vat_exempted_amount", summary.income_vat_exempted_amount);
    setValue("vat_0_percent_amount", summary.income_vat_0_percent_amount);
    setValue("vat_7_percent_amount", summary.income_vat_7_percent_amount);
    setValue("vat_amount", summary.income_vat_amount);
    setValue("net_amount", summary.income_net_amount);
  }, [
    setValue,
    summary.income_net_amount,
    summary.income_post_discount_amount,
    summary.income_vat_0_percent_amount,
    summary.income_vat_7_percent_amount,
    summary.income_vat_amount,
    summary.income_vat_exempted_amount,
  ]);
  return summary;
};
