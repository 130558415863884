import { gql } from "graphql-request";

export const GOODS_ADJUST_VIEW = gql`
  query GoodAdjustsViewAggrid($aggridInput: AnyAggridInput!) {
    GoodAdjustsViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        posted_date
        created_date
        source_warehouse_name
        created_by
        remark
        aggrid_status
      }
    }
  }
`;
