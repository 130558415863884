import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import LoadingUI from "components/UI/LoadingUI";
import BillingNoteTable from "components/Table/Sales/BillingNote";
import {
  DeliveryTripFindUniqueQuery,
  useDeliveryTripFindUniqueQuery,
} from "generated/sales";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { useParams } from "react-router-dom";

type Props = {
  isLoading?: boolean;
};

const BillingNoteTab = ({ isLoading }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { data } = useDeliveryTripFindUniqueQuery<DeliveryTripFindUniqueQuery>(
    graphQLClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
      cacheTime: 20,
    }
  );

  if (isLoading) return <LoadingUI />;

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">{t("sales.billing_note.index")}</Typography>
      </Box>
      <BillingNoteTable
        referenceUniqueId={data?.DeliveryTripFindUnique?.unique_id}
      />
    </>
  );
};

export default BillingNoteTab;
