import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import LoadingUI from "../../../components/UI/LoadingUI";
import PurchaseReturnTable from "components/Table/Purchase/Return";

type Props = {
  referenceUniqueId?: string;
  isLoading?: boolean;
};

const PurchaseReturnTab = ({ referenceUniqueId, isLoading }: Props) => {
  const { t } = useTranslation();

  if (isLoading) return <LoadingUI />;

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">{t("purchase.return.index")}</Typography>
      </Box>
      <PurchaseReturnTable referenceUniqueId={referenceUniqueId} />
    </>
  );
};

export default PurchaseReturnTab;
