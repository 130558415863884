import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "types/global";

export const useQuotationOption = (
  status?: string | null,
  disabled?: boolean
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: !disabled || false,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
      {
        value: "ไม่ตอบรับใบเสนอราคา",
        disabled: false,
      },
    ],
    [disabled]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
      case "not_accepted":
        setSelectedModifyOptions([defaultOptions[1], defaultOptions[2]]); // คัดลอก, ยกเลิก
        break;
      case "cancelled":
      case "accepted":
      case "finished":
        setSelectedModifyOptions([defaultOptions[1]]); // คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions);
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};
