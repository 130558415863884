import { useDisable } from "hooks/use-disable";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import ControlledSelect from "components/Controller/ControlledSelect";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledRadioGroup from "components/Controller/ControlledRadioGroup";
import { IItem } from "types/Inventory/item";
import ItemConversion from "./ItemConversion";
import { useUnitOptions } from "hooks/Inventory/use-inventory-setting-option";
import LabelInput from "components/UI/LabelInput";

const ItemWarehouse = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<IItem>();

  const { renderOption, options } = useUnitOptions();

  const watchType = watch("type");
  const watchTracability = watch("tracability");

  return (
    <>
      {watchType === "normal" && (
        <>
          <Box display="flex" alignItems="center">
            <Typography fontWeight={600} my={2}>
              {t("inventory.item.warehouse.tracability.index")}
            </Typography>
            <CustomizedTooltip
              title={
                <Box>
                  <Typography fontSize={14} fontWeight={600}>
                    {t("inventory.item.warehouse.tracability.normal")}
                  </Typography>
                  <Typography fontSize={14}>
                    {t("inventory.sentence.tracability_normal")}
                  </Typography>
                  <Typography fontSize={14} fontWeight={600}>
                    {t("inventory.item.warehouse.tracability.serial")}
                  </Typography>
                  <Typography fontSize={14}>
                    {t("inventory.sentence.tracability_serial")}
                  </Typography>
                  <Typography fontSize={14}>
                    {t("inventory.sentence.tracability_remark")}
                  </Typography>
                </Box>
              }
              isNoMaxWidth
              placement="right"
            >
              <ErrorOutlineIcon
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  marginLeft: 1,
                }}
              />
            </CustomizedTooltip>
          </Box>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledRadioGroup
                control={control}
                name="tracability"
                disabled={disabled}
                row
                radioLists={[
                  {
                    label: t("inventory.item.warehouse.tracability.serial"),
                    value: "serial",
                  },
                  {
                    label: t("inventory.item.warehouse.tracability.normal"),
                    value: "normal",
                  },
                ]}
                onChangeCallback={(
                  e: React.ChangeEvent<HTMLInputElement>,
                  value: any
                ) => {
                  if (value === "normal") {
                    setValue("barcode", getValues("unique_id"));
                  }
                }}
              />
            </Grid>
            {watchTracability === "normal" && (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextField
                  control={control}
                  name="barcode"
                  label={t("inventory.item.barcode")}
                  error={Boolean(errors.barcode)}
                  helperText={errors.barcode?.message}
                  viewMode={disabled}
                  required
                  viewModeNoLabel
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Typography fontWeight={600} my={2}>
        {t("inventory.item.warehouse.uom.index")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {!disabled && (
            <ControlledSelect
              name="stock_uom_id"
              control={control}
              label={t("inventory.item.warehouse.uom.stock_uom")}
              error={Boolean(errors.stock_uom_id)}
              helperText={errors.stock_uom_id?.message?.toString()}
              options={options}
              onChange={(e: any) => {
                setValue(
                  `stock_uom_name`,
                  renderOption(e.target.value)?.toString()
                );
              }}
              required
            />
          )}
          {disabled && (
            <LabelInput
              label={t("inventory.item.warehouse.uom.stock_uom")}
              value={renderOption(getValues("stock_uom_id"))}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {!disabled && (
            <ControlledSelect
              name="sales_uom_id"
              control={control}
              label={t("inventory.item.warehouse.uom.sales_uom")}
              error={Boolean(errors.sales_uom_id)}
              helperText={errors.sales_uom_id?.message?.toString()}
              options={options}
            />
          )}
          {disabled && (
            <LabelInput
              label={t("inventory.item.warehouse.uom.sales_uom")}
              value={renderOption(getValues("sales_uom_id"))}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {!disabled && (
            <ControlledSelect
              name="purchase_uom_id"
              control={control}
              label={t("inventory.item.warehouse.uom.purchase_uom")}
              error={Boolean(errors.purchase_uom_id)}
              helperText={errors.purchase_uom_id?.message?.toString()}
              options={options}
            />
          )}
          {disabled && (
            <LabelInput
              label={t("inventory.item.warehouse.uom.purchase_uom")}
              value={renderOption(getValues("purchase_uom_id"))}
            />
          )}
        </Grid>
      </Grid>
      <Typography fontWeight={600} my={2}>
        {t("inventory.item.warehouse.uom.conversion.index")}
      </Typography>
      <ItemConversion />
    </>
  );
};

export default ItemWarehouse;
