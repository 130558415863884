import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { useDisable } from "hooks/use-disable";

import { Box, Grid, Typography } from "@mui/material";

import { CustomizedBox } from "components/Custom/CustomizedBox";
import ControlledSSCreatable from "components/Controller/ControlledSSCreatable";

const ReasonReturn = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <CustomizedBox margin="2rem 0 0 0">
      <Box mb={2}>
        <Typography fontWeight={600}>
          {t("purchase.return.reason_to_return_doc")}
        </Typography>
      </Box>
      <Grid container>
        <Grid item sm={12} md={8}>
          <ControlledSSCreatable
            control={control}
            name="reason_to_return"
            setValue={setValue}
            defaultOptions={[]}
            documentType="purchase_return"
            placeholder={t("purchase.return.reason_to_return_doc")}
            error={Boolean(errors.reason_to_return)}
            helperText={errors?.reason_to_return?.message?.toString()}
            viewMode={disabled}
            required
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ReasonReturn;
