import { gql } from "graphql-request";

export const PURCHASE_BY_PRODUCT_EXPORT_VIEW = gql`
  query PurchaseByProductExportViewAggrid($aggridInput: AnyAggridInput) {
    PurchaseByProductExportViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        item_unique_id
        item_name
        qty
        uom_name
        net_amount
        additional_discount
        pre_vat_amount
        vat_amount
        pre_discount_amount
        withholding_tax_amount
        total_amount
      }
    }
  }
`;
