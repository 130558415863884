import { ReactNode } from "react";

import { Box, DialogContentText, Divider } from "@mui/material";

import ModalUI from "./ModalUI";
import CustomizedButton from "../../Custom/CustomizedButton";

interface Props {
  open: boolean;
  title: string;
  titleSize?: number | string;
  message?: ReactNode | string;
  noDivider?: boolean;
  handleClose: () => void;
  action: () => void;
}

const ConfirmationModal = ({
  open,
  title,
  titleSize,
  message,
  noDivider,
  handleClose,
  action,
}: Props) => {
  return (
    <ModalUI
      title={title}
      titleSize={titleSize}
      open={open}
      handleClose={handleClose}
      maxWidth="xs"
      fullWidth
      action={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={handleClose}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={action}
          />
        </Box>
      }
    >
      {message && (
        <>
          {!noDivider && <Divider />}
          <DialogContentText>{message}</DialogContentText>
          {!noDivider && <Divider />}
        </>
      )}
    </ModalUI>
  );
};

export default ConfirmationModal;
