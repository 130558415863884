import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/Date";
import { dateFilterParams } from "utils/AgGridFilter";
import { numberFormatter, statusValueFormatter } from "utils/Formatter/Global";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import CustomizedAvatar from "components/Custom/CustomizedAvatar";
// import { formatNumber } from "utils/Global";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { USERS_AGGRID } from "services/AgGrid/UserAgGrid";
import { IUser } from "types/Auth/user";

type Props = {
  sortByDate?: boolean;
};

export const useMaintenanceColumnDefs = ({ sortByDate }: Props) => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "id",
        headerName: "",
        sort: sortByDate ? undefined : "desc",
        hide: true,
        filter: "agNumberColumnFilter",
        suppressColumnsToolPanel: true,
        minWidth: 200,
        flex: 1,
      },
      {
        field: "unique_id",
        headerName: t("logistic.maintenance.unique_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "issue_date",
        headerName: t("date.issue_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "vehicle_no",
        headerName: t("logistic.maintenance.vehicle.vehicle_no"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "vehicle_license_plate",
        headerName: t("logistic.maintenance.vehicle.license_plate"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "trailer_no",
        headerName: t("logistic.maintenance.trailer.vehicle_no"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
        hide: true,
      },
      {
        field: "trailer_license_plate",
        headerName: t("logistic.maintenance.trailer.license_plate"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
        hide: true,
      },
      {
        field: "type",
        headerName: t("logistic.maintenance.type"),
        filter: "agSetColumnFilter",
        minWidth: 200,
        flex: 1,
        filterParams: {
          values: ["CLAIM", "NORMAL"],
          valueFormatter: (params: ValueFormatterParams) => {
            if (params?.value === "CLAIM") {
              return "เคลม";
            } else if (params?.value === "NORMAL") {
              return "ปกติ";
            }
            return "-";
          },
        },
        valueFormatter: (params: ValueFormatterParams) => {
          if (params?.value === "CLAIM") {
            return "เคลม";
          } else if (params?.value === "NORMAL") {
            return "ปกติ";
          }
          return "-";
        },
      },
      {
        field: "garage_type",
        headerName: t("logistic.maintenance.ma_details.garage_type"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["อู่ภายใน", "อู่ภายนอก/ศูนย์บริการ"],
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "garage_name",
        headerName: t("logistic.maintenance.ma_details.garage_name"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "net_amount",
        headerName: t("sales.net_amount"),
        filter: "agNumberColumnFilter",
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        headerClass: "ag-end-header",
        valueFormatter: (params: ValueFormatterParams<any, any>) => {
          if (params.value) {
            return numberFormatter(params.value);
          } else return "";
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "is_insurance_fully_paid",
        headerName: t("logistic.maintenance.insurance_claim"),
        minWidth: 200,
        flex: 1,
        cellRenderer: (params: ICellRendererParams<any, string>) => {
          if (params.value === null) {
            return "-";
          }

          return (
            <CustomizedStatus status={params.value ? "success" : "failed_insurace_paid"} />
          );
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
        },
        filterParams: {
          values: ["success", "failed"],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
      },
      {
        field: "reference_document_unique_id_list",
        headerName: t("reports.reference_document_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "claim_no",
        headerName: t("logistic.maintenance.claim_no"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
        hide: true,
      },
      {
        field: "accident_date",
        headerName: t("logistic.maintenance.accident_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
        minWidth: 200,
        flex: 1,
        hide: true,
      },
      {
        field: "ma_start_date",
        headerName: t("logistic.maintenance.ma_start_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "ma_end_date",
        headerName: t("logistic.maintenance.ma_end_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "created_date",
        headerName: t("date.created_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        sort: sortByDate ? "desc" : undefined,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
        minWidth: 200,
        flex: 1,
        hide: true,
      },
      {
        field: "last_updated_date",
        headerName: t("date.last_updated_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
        minWidth: 200,
        flex: 1,
        hide: true,
      },
      {
        field: "driver_object_list",
        headerName: t("logistic.vehicle.driver"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            try {
              const graphQLClient: GraphQLClient =
                createGraphQLClientWithMiddleware("general");
              const { UsersFindManyAggrid } = await graphQLClient.request(
                USERS_AGGRID,
                {
                  aggridInput: {
                    startRow: 0,
                    endRow: 9999,
                    filterModel: {
                      position: {
                        filterType: "text",
                        type: "equals",
                        filter: "พนักงานขนส่ง",
                      },
                      status: {
                        values: [1],
                        filterType: "set",
                      },
                    },
                    sortModel: [],
                  },
                }
              );
              const userName = UsersFindManyAggrid.results.map(
                (user: IUser) => user.first_name + " " + user.last_name
              );
              params.success(userName);
            } catch (err) {
              params.fail();
            }
          },
        },
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value && params.value.length > 0) {
            return <CustomizedAvatar avatars={params.value} />;
          }
        },
        minWidth: 200,
      },
      {
        field: "created_by_object",
        headerName: t("logistic.vehicle.created_by"),
        filter: "agSetColumnFilter",
        hide: true,
        filterParams: {
          values: async (params: any) => {
            try {
              const graphQLClient: GraphQLClient =
                createGraphQLClientWithMiddleware("general");
              const { UsersFindManyAggrid } = await graphQLClient.request(
                USERS_AGGRID,
                {
                  aggridInput: {
                    startRow: 0,
                    endRow: 9999,
                    sortModel: [],
                  },
                }
              );
              const userName = UsersFindManyAggrid.results.map(
                (user: IUser) => user.first_name + " " + user.last_name
              );
              params.success(userName);
            } catch (err) {
              params.fail();
            }
          },
        },
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value) {
            return <CustomizedAvatar avatars={[params.value]} />;
          }
        },
        minWidth: 150,
      },
      {
        field: "aggrid_status",
        headerName: t("status.index"),
        minWidth: 200,
        flex: 1,
        cellRenderer: (params: ICellRendererParams<any, string>) => {
          return <CustomizedStatus status={params.value} />;
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
        },
        filterParams: {
          values: [
            "draft",
            "wait_maintenance",
            "maintenancing",
            "finished",
            "cancelled",
          ],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
      },
    ]);
  }, [t, sortByDate]);
  return columnDefs;
};
