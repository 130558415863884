import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { IBreadcrumbsAndMenu, ITab } from "types/global";

import HeaderLayout from "components/UI/HeaderLayout";
import CustomizedTab from "components/Custom/CustomizedTab";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import GoodsAdjustTable from "components/Table/Inventory/GoodsAdjust";
import CustomizedButton from "components/Custom/CustomizedButton";

const GoodsAdjust = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const statusFilter = searchParams.get("filter");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.goods_adjust.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (statusFilter ? `?filter=${statusFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5">
          {t("inventory.goods_adjust.index")}
        </Typography>
        <CustomizedButton
          startAddIcon
          title={`${t("button.create")}${t("inventory.goods_adjust.index")}`}
          variant="contained"
          onClick={() => navigate(`/inventory/goods-adjust/add`)}
        />
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <GoodsAdjustTable status={statusFilter} />
    </>
  );
};

export default GoodsAdjust;
