import { useState } from "react";

export const useModal = (
  action?: (params?: any) => void,
  removeSearchParamsHandler?: () => void
) => {
  const [modal, setModal] = useState<boolean>(false);

  const openModalHandler = () => {
    setModal(true);
  };

  const closeModalHandler = () => {
    setModal(false);
    if (removeSearchParamsHandler) {
      removeSearchParamsHandler();
    }
  };

  const submitModalHandler = () => {
    if (action) {
      action();
    }
    closeModalHandler();
  };

  return {
    modal,
    openModalHandler,
    closeModalHandler,
    submitModalHandler,
  };
};
