import { useCallback } from "react";
import { ItemIdByBarcodeQuery, useItemIdByBarcodeQuery } from "generated/wms";
import { InputAdornment, IconButton } from "@mui/material";
import ControlledTextField from "components/Controller/ControlledTextField";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IBarcodeForm } from "types/Inventory/item";
import SearchIcon from "@mui/icons-material/Search";

type Props = {
  includeTab?: boolean;
};

const BarcodeScanNavigate = ({ includeTab }: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control: barcodeControl,
    reset: resetBarcode,
    watch: watchBarcode,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
    },
  });

  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const { refetch: refetchItemId } =
    useItemIdByBarcodeQuery<ItemIdByBarcodeQuery>(
      graphQLClientWms,
      {
        barcode: watchBarcode("barcode")?.trim(),
      },
      {
        enabled: false,
      }
    );

  const onBarcodeSubmitHandler = useCallback(async () => {
    const { data } = await refetchItemId();
    if (data?.ItemIdByBarcode) {
      const { id, type } = data.ItemIdByBarcode || {};
      const formatType = type === "service" ? "service" : "item";
      navigate(
        `/inventory/${formatType}/${encodeURIComponent(id || "")}${
          includeTab ? "?tab=item&subtab=general" : "?subtab=general"
        }`
      );
    } else {
      enqueueSnackbar("ไม่พบ Barcode หรือ รหัสสินค้า นี้ในระบบ", {
        variant: "error",
      });
    }
    resetBarcode();
  }, [refetchItemId, resetBarcode, navigate, includeTab, enqueueSnackbar]);

  return (
    <ControlledTextField
      label="Scan Barcode"
      control={barcodeControl}
      name="barcode"
      onKeyDown={(e) => {
        if (e.key === "Enter" && e.shiftKey === false) {
          onBarcodeSubmitHandler();
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onBarcodeSubmitHandler} size="small">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default BarcodeScanNavigate;
