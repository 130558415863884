import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import CustomizedAddressBox from "../Custom/CustomizedAddressBox";
import { Dispatch, SetStateAction } from "react";
import LabelInput from "../UI/LabelInput";
import { useTranslation } from "react-i18next";

interface Props {
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  name?: string;
  type: string;
  disabled?: boolean;
  addressValue: any;
  setAddressValue: Dispatch<SetStateAction<any>>;
  viewMode: boolean;
  error?: boolean;
  helperText?: string;
  clearErrorsHandler?: () => void;
  required?: boolean;
  maxLength?: number;
}

const ControlledAddressBox = ({
  name,
  type,
  disabled,
  getValues,
  setValue,
  addressValue,
  setAddressValue,
  viewMode,
  error,
  helperText,
  clearErrorsHandler,
  maxLength,
  required,
}: Props) => {
  const { t } = useTranslation();

  return viewMode ? (
    <LabelInput
      label={t(`address.${type}`)}
      value={getValues(`${name}.${type}`)}
    />
  ) : (
    <CustomizedAddressBox
      value={addressValue}
      type={type}
      disabled={disabled}
      onChange={(_: any, data: any) => {
        const { sub_district, district, province, postal_code } = data;
        setAddressValue({
          ...addressValue,
          sub_district,
          district,
          province,
          postal_code,
        });
        if (name) {
          setValue(`${name}.sub_district`, sub_district);
          setValue(`${name}.district`, district);
          setValue(`${name}.province`, province);
          setValue(`${name}.postal_code`, postal_code);
          setValue(`${name}.country`, "ไทย");
        } else {
          setValue(`sub_district`, sub_district);
          setValue(`district`, district);
          setValue(`province`, province);
          setValue(`postal_code`, postal_code);
          setValue(`country`, "ไทย");
        }

        if (clearErrorsHandler) {
          clearErrorsHandler();
        }
      }}
      onInputChange={(value: string) => {
        setAddressValue((prev: any) => {
          prev[type] = value;
          return prev;
        });
        if (name) {
          setValue(`${name}.${type}`, value, { shouldValidate: true });
        } else {
          setValue(`${type}`, value, { shouldValidate: true });
        }
      }}
      error={error}
      helperText={helperText}
      required={required}
    />
  );
};

export default ControlledAddressBox;
