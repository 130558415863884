import React from "react";
import { Box, Typography } from "@mui/material";
import NoSimOutlinedIcon from "@mui/icons-material/NoSimOutlined";

import ConfirmationModal from "./Modal/ConfirmationModal";

type Props = {
  uniqueId?: string;
  confirmation: boolean;
  closeConfirmationHandler: () => void;
  submitConfirmationHandler: () => void;
};

const CancelConfirmation = ({
  uniqueId,
  confirmation,
  closeConfirmationHandler,
  submitConfirmationHandler,
}: Props) => {
  return (
    <ConfirmationModal
      title="ยืนยันการยกเลิกเอกสาร"
      message={
        <Box textAlign="center" my={1} mb={2}>
          <NoSimOutlinedIcon
            sx={{
              fontSize: "4rem",
              marginTop: 1,
              color: "gray.light",
            }}
          />
          <Typography>{"ยืนยันการยกเลิกเอกสาร"}</Typography>
          <Typography fontWeight="bold">{uniqueId}</Typography>
          <Typography>{"หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้"}</Typography>
        </Box>
      }
      open={confirmation}
      handleClose={closeConfirmationHandler}
      action={submitConfirmationHandler}
      noDivider
    />
  );
};

export default CancelConfirmation;
