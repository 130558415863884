import { gql } from "graphql-request";

export const VEHICLE_VIEW = gql`
  query VehicleFindViewAggrid($aggridInput: AnyAggridInput) {
    VehicleFindViewAggrid(aggridInput: $aggridInput) {
      results {
        id
        unique_id
        register_id
        type
        driver
        driver_object_list {
          user_id
          first_name
          last_name
          email
          img_url
        }
        current_kilometer
        due_distance_kilometer
        brand
        model
        detail
        act_id
        act_end_date
        status
        status_note
        created_date
        last_updated_date
        created_by
        created_by_object
        last_updated_by
        trailer_id
        trailer_unique_id
        trailer_register_id
        insurance_vehicle_start_date
        insurance_vehicle_end_date
        insurance_vehicle_tier
        insurance_vehicle_policy_id
        insurance_vehicle_protection_value
        insurance_product_start_date
        insurance_product_end_date
        insurance_product_tier
        insurance_product_policy_id
        insurance_product_protection_value
        insurance_vehicle_status
        insurance_product_status
        act_status
        maintenance_status
        has_approaching
        has_overdue
      }
      count
    }
  }
`;

export const TIRE_VIEW = gql`
  query TireFindViewAggrid($aggridInput: AnyAggridInput) {
    TireFindViewAggrid(aggridInput: $aggridInput) {
      results {
        id
        serial
        model
        band
        current_distance
        due_distance
        price_per_unit
        status
        remark
        created_date
        created_by
        last_updated_date
        last_updated_by
        maintenance_status
      }
      count
    }
  }
`;

export const TIRE_HISTORY_VIEW = gql`
  query TireUsageHistoryFindViewAggrid($aggridInput: AnyAggridInput) {
    TireUsageHistoryFindViewAggrid(aggridInput: $aggridInput) {
      results {
        id
        tire_series
        action_type
        previous_position
        current_position
        action_date
        action_distance
        tire_distance
        due_distance
        usage_distance
        price_per_unit
        band
        model
        vehicle_unique_id
        vehicle_license
        vehicle_type
        remark
        tire_status
        tire_notification_status
        tire_id
        created_date
        last_updated_date
      }
      count
    }
  }
`;
