import { useWarehousesFindAllQuery } from "generated/general";
import {
  usePurchaseOrderUniqueIdQueryQuery,
  usePurchaseReturnUniqueIdQueryQuery,
} from "generated/purchase";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { ISelectOption } from "types/global";

const useInventoryControl = (warehouse_id?: number, type?: string) => {
  const graphQLClientGeneral = createGraphQLClientWithMiddleware("general");
  const graphQLClientPurchase = createGraphQLClientWithMiddleware("purchase");

  const { data: warehouses, isLoading: isWarehousesLoading } =
    useWarehousesFindAllQuery(graphQLClientGeneral);

  const warehousesOptions: ISelectOption[] =
    warehouses?.WarehousesFindAll?.map((warehouse) => ({
      id: warehouse.id,
      label: warehouse.name,
      value: warehouse.id,
    })) || [];

  const { data: purchaseOrder, isLoading: isPurchaseOrderLoading } =
    usePurchaseOrderUniqueIdQueryQuery(
      graphQLClientPurchase,
      {
        aggridInput: {
          startRow: 0,
          endRow: 9999,
          filterModel: {
            status: {
              filterType: "set",
              values: ["fully_ordered", "partially_imported"],
            },
          },
          sortModel: [
            {
              sort: "desc",
              colId: "created_date",
            },
          ],
        },
      },
      {
        enabled: type === "purchase",
      }
    );

  const { data: purchaseReturn, isLoading: isPurchaseReturnLoading } =
    usePurchaseReturnUniqueIdQueryQuery(
      graphQLClientPurchase,
      {
        aggridInput: {
          startRow: 0,
          endRow: 9999,
          filterModel: {
            status: {
              filterType: "set",
              values: ["finished"],
            },
          },
          sortModel: [
            {
              sort: "desc",
              colId: "created_date",
            },
          ],
        },
      },
      {
        enabled: type === "return",
      }
    );

  const renderWarehousesOptions = (id: number) =>
    warehouses?.WarehousesFindAll?.find((warehouse) => warehouse.id === id)
      ?.name;

  const foundWarehouse = warehouses?.WarehousesFindAll?.find(
    (warehouse) => warehouse?.id === warehouse_id
  );

  let allBinLocation: any[] | undefined | null = [];

  foundWarehouse?.warehouse_level_1_list?.forEach((level1) => {
    level1.warehouse_level_2_list?.forEach((level2) => {
      level2?.warehouse_level_3_list?.forEach((level3) => {
        if (level3?.bin_location_list) {
          allBinLocation?.push(...level3.bin_location_list);
        }
      });
    });
  });

  const binLocationOptions: ISelectOption[] = allBinLocation.map((bin) => ({
    label: bin.name,
    value: bin.id,
  }));

  const purchaseOrderOptions: ISelectOption[] =
    purchaseOrder?.PurchaseOrderViewsAggrid?.results
      .filter((po) =>
        po.item_list?.every(
          (item) => item.po_qty + item.wait_receive_qty < item.qty
        )
      )
      .map((po) => ({
        id: po.id || 0,
        label: po.unique_id,
        value: po.unique_id,
      })) || [];

  const purchaseReturnOptions: ISelectOption[] =
    purchaseReturn?.PurchaseReturnViewsAggrid?.results
      .filter((rs) => rs.item_list?.every((item) => item.rs_qty < item.qty))
      .map((rs) => ({
        id: rs.id || 0,
        label: rs.unique_id,
        value: rs.unique_id,
      })) || [];

  return {
    warehousesOptions,
    warehouses: warehouses?.WarehousesFindAll,
    renderWarehousesOptions,
    allBinLocation,
    binLocationOptions,
    purchaseOrderOptions,
    isPurchaseOrderLoading,
    purchaseReturnOptions,
    isPurchaseReturnLoading,
    isOptionsLoading: isWarehousesLoading ? true : false,
  };
};

export default useInventoryControl;
