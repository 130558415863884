import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { ThemeProvider } from '@emotion/react';
import { createTheme, Typography } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Kanit, Arial, sans-serif',
  },
});

const LoadingAnimation = () => {
  return (
    <ThemeProvider theme={theme}>
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000 
      }}>
        <Player
          autoplay
          loop
          src="https://lottie.host/fcd86042-7b9a-4e1c-966c-8b9da110f02a/AtO9mu7904.json" 
          // src="https://lottie.host/1131788f-3ac1-4ffe-96a7-a48d5c63e65a/46gILskopU.json"
          // style={{ height: '500px', width: '500px' }}
        >
          {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
        
        <Typography variant="h6"
          style={{
            fontFamily: 'Kanit',
            fontSize: '24px',
            fontWeight: "bold",
            lineHeight: '24px',
            textAlign: 'center',
            color: '#333333',
          }}
        >
          {"ระบบกำลังดำเนินการ"}
        </Typography>
        <Typography variant="body1"
          style={{
            fontFamily: 'Kanit',
            fontSize: '14px',
            lineHeight: '24px',
            textAlign: 'center',
            color: '#333333',
            marginTop: '10px'
          }}
        >
          {"กรุณารอสักครู่ โปรดอย่าปิดหน้าต่างนี้"}
        </Typography>
        </Player>
      </div>
    </ThemeProvider>
  );
};

export default LoadingAnimation;