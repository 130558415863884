import { InputAdornment, Typography } from "@mui/material";
import { IOptionsCreatable } from "../../../../types/global";
import {
  Controller,
  FieldArrayWithId,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { numberFormatter } from "../../../../utils/Formatter/Global";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import { useDisable } from "hooks/use-disable";
import { useEffect } from "react";

interface Props {
  field: FieldArrayWithId<IDeliveryTrip, "income_list", "id">;
  index: number;
  disabledStatus: boolean;
}

const withholdingTaxTypeOption: IOptionsCreatable[] = [
  { id: 1, value: "ยังไม่ระบุ", label: "ยังไม่ระบุ" },
  { id: 2, value: "ไม่มี", label: "ไม่มี" },
  { id: 3, value: "0.75", label: "0.75" },
  { id: 4, value: "1", label: "1" },
  { id: 5, value: "1.5", label: "1.5" },
  { id: 6, value: "2", label: "2" },
  { id: 7, value: "3", label: "3" },
  { id: 8, value: "5", label: "5" },
  { id: 9, value: "10", label: "10" },
  { id: 10, value: "15", label: "15" },
];

const WithholdingTaxCell = ({ field, index, disabledStatus }: Props) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IDeliveryTrip>();

  const [disabled] = useDisable();

  const item_pre_vat_amount = useWatch({
    control,
    name: `income_list.${index}.pre_vat_amount`,
  });

  const withholding_tax_type = useWatch({
    control,
    name: `income_list.${index}.withholding_tax_type`,
  });

  const withholding_tax_value = useWatch({
    control,
    name: `income_list.${index}.withholding_tax_value`,
  });

  useEffect(() => {
    setValue(
      `income_list.${index}.withholding_tax_value`,
      (item_pre_vat_amount *
        (withholding_tax_type === "ยังไม่ระบุ" ||
        withholding_tax_type === "ไม่มี" ||
        withholding_tax_type === "" ||
        withholding_tax_type === undefined
          ? 0
          : parseFloat(withholding_tax_type))) /
        100
    );
  }, [index, item_pre_vat_amount, setValue, withholding_tax_type]);
  return (
    <>
      {disabled || disabledStatus ? (
        <>
          <Typography>
            {field.withholding_tax_type}
            {field.withholding_tax_type !== "ไม่มี" &&
            field.withholding_tax_type !== "ยังไม่ระบุ"
              ? " %"
              : ""}
          </Typography>
          {field.withholding_tax_type !== "ไม่มี" &&
            field.withholding_tax_type !== "ยังไม่ระบุ" && (
              <Typography variant="caption">
                {numberFormatter(withholding_tax_value)} บาท
              </Typography>
            )}
        </>
      ) : (
        <Controller
          control={control}
          name={`income_list.${index}.withholding_tax_type`}
          render={({ field }) => (
            <CustomizedComboBox
              {...field}
              freeSolo
              disableClearable
              sx={{ mt: 3 }}
              options={withholdingTaxTypeOption}
              onChange={(_, newValue) => {
                if (newValue) {
                  field.onChange(newValue.value || "");
                }
              }}
              onInputChange={(_, newValue) => {
                if (newValue) {
                  field.onChange(newValue);
                }
              }}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              error={Boolean(
                errors?.income_list &&
                  errors?.income_list[index] &&
                  errors?.income_list[index]?.withholding_tax_type
              )}
              helperText={numberFormatter(withholding_tax_value) + " บาท"}
              required
            />
          )}
        />
      )}
    </>
  );
};

export default WithholdingTaxCell;
