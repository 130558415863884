import { stringMaxInput } from "utils/Validate";
import * as Yup from "yup";

export const tireSchema = {
  serial: "",
  model: "",
  band: "",
  current_distance: undefined,
  due_distance: undefined,
  is_active: true,
  remark: "",
};

export const tireValidation = Yup.object().shape({
  serial: stringMaxInput(200, undefined, true, "กรุณาระบุ Serial ยาง"),
  current_distance: Yup.number()
    .required("กรุณาระบุเลขกิโลเมตรยาง")
    .typeError("กรุณาระบุเลขกิโลเมตรยาง")
    .max(999999, "เลขกิโลเมตรยางไม่สามารถเกิน 6 หลักได้"),
  due_distance: Yup.number()
    .required("กรุณาระบุระยะครบกำหนด")
    .typeError("กรุณาระบุระยะครบกำหนด"),
  brand: stringMaxInput(200, undefined, false, undefined),
  model: stringMaxInput(200, undefined, false, undefined),
  remark: Yup.string().when("is_active", {
    is: false,
    then: (schema) =>
      schema
        .required("กรุณาระบุหมายเหตุสถานะ")
        .max(200, `กรอกได้ไม่เกิน 200 ตัวอักษร`),
    otherwise: (schema) => schema.notRequired(),
  }),
});
