import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useWorkListHeader } from "hooks/Logistic/use-work-list-header";
import { useDisable } from "hooks/use-disable";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { FieldArrayWithId, FieldError, useFormContext } from "react-hook-form";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import MenuIcon from "@mui/icons-material/Menu";
import ControlledSelect from "components/Controller/ControlledSelect";
import { ISelectOption } from "types/global";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledDateTimePicker from "components/Controller/ControlledDateTimePicker";
import { formatDateTime } from "utils/Date";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import { numberFormatter } from "utils/Formatter/Global";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  fields: FieldArrayWithId<IDeliveryTrip, "work_list", "id">[];
  dragEndHandler: (result: DropResult) => void;
  openDeleteItemConfirmationHandler: (index: number) => void;
  onTypeChange: () => void;
};

const workType: ISelectOption[] = [
  {
    id: 1,
    label: "รับ",
    value: "receive",
  },
  {
    id: 2,
    label: "ส่ง",
    value: "deliver",
  },
];

const WorkListTable = ({
  fields,
  dragEndHandler,
  openDeleteItemConfirmationHandler,
  onTypeChange,
}: Props) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<IDeliveryTrip>();
  const [disabled] = useDisable();
  const headers = useWorkListHeader();

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;

  const isFinished = status === "finished";

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, overflow: "scroll" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                align={header.align}
                key={index}
                sx={{
                  px: 1,
                  py: 1.5,
                  backgroundColor: "secondary.main",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    width: header.width,
                    justifyContent: header.align,
                  }}
                >
                  <Typography fontWeight={600}>{header.thaiLabel}</Typography>
                  {header.required && (
                    <Typography
                      fontSize={14}
                      color={"error.main"}
                      fontWeight={600}
                    >
                      *
                    </Typography>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={dragEndHandler}>
          <Droppable droppableId="droppable" isDropDisabled={disabled}>
            {(provided) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((field, index) => (
                  <Draggable
                    key={field.unique_id}
                    draggableId={"work-" + field.unique_id}
                    index={index}
                    isDragDisabled={disabled}
                  >
                    {(provided) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          ...provided.draggableProps.style,
                          breakInside: "avoid",
                        }}
                      >
                        {isFinished ? (
                          <>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  alignItems: "center",
                                  width: headers[0]?.width,
                                }}
                              >
                                {!disabled && (
                                  <MenuIcon
                                    fontSize="small"
                                    sx={{ color: "#BDBDBD" }}
                                  />
                                )}
                                <Typography>
                                  {field.location_unique_id}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[1]?.align}>
                              <Box width={headers[1]?.width}>
                                <Typography>{field.location_name}</Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[2]?.align}>
                              <Box
                                width={headers[2]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]?.type
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledSelect
                                    control={control}
                                    name={`work_list.${index}.type`}
                                    options={workType}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors?.work_list[index]?.type
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      (
                                        errors?.work_list[index]
                                          ?.type as FieldError
                                      )?.message
                                    }
                                    disabled={isFinished}
                                    onChange={onTypeChange}
                                  />
                                ) : (
                                  <Typography>
                                    {
                                      workType.find(
                                        (work) => work.value === field.type
                                      )?.label
                                    }
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[3]?.align}>
                              <Box width={headers[3]?.width}>
                                <Typography>
                                  {field.distance.toLocaleString()}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[4]?.align}>
                              <Box width={headers[4]?.width}>
                                <Typography>
                                  {field.shipping_cost
                                    ? numberFormatter(field.shipping_cost)
                                    : "-"}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[5]?.align}>
                              <Box width={headers[5]?.width}>
                                <Typography>
                                  {field.secondary_driver_cost
                                    ? numberFormatter(
                                        field.secondary_driver_cost
                                      )
                                    : "-"}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[6]?.align}>
                              <Box width={headers[6]?.width}>
                                <Typography>
                                  {field.drop_cost
                                    ? numberFormatter(field.drop_cost)
                                    : "-"}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[7]?.align}>
                              <Box width={headers[7]?.width}>
                                <Typography>
                                  {field.max_shipping_cost
                                    ? numberFormatter(field.max_shipping_cost)
                                    : "-"}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[8]?.align}>
                              <Box
                                width={headers[8]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]?.coordinator_name
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledTextField
                                    control={control}
                                    name={`work_list.${index}.coordinator_name`}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors.work_list[index]
                                          ?.coordinator_name
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      errors.work_list[index]?.coordinator_name
                                        ?.message
                                    }
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.coordinator_name}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[9]?.align}>
                              <Box
                                width={headers[9]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]
                                        ?.coordinator_phone
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledTextField
                                    control={control}
                                    name={`work_list.${index}.coordinator_phone`}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors.work_list[index]
                                          ?.coordinator_phone
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      errors.work_list[index]?.coordinator_phone
                                        ?.message
                                    }
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.coordinator_phone}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[10]?.align}>
                              <Box
                                width={headers[10]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]?.special_request
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledTextField
                                    control={control}
                                    name={`work_list.${index}.special_request`}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors.work_list[index]?.special_request
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      errors.work_list[index]?.special_request
                                        ?.message
                                    }
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.special_request || "-"}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[11]?.align}>
                              <Box
                                width={headers[11]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]?.customer_name
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledTextField
                                    control={control}
                                    name={`work_list.${index}.customer_name`}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors.work_list[index]?.customer_name
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      errors.work_list[index]?.customer_name
                                        ?.message
                                    }
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.customer_name || "-"}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[12]?.align}>
                              <Box
                                width={headers[12]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]?.reference_code
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledTextField
                                    control={control}
                                    name={`work_list.${index}.reference_code`}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors.work_list[index]?.reference_code
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      errors.work_list[index]?.reference_code
                                        ?.message
                                    }
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.reference_code || "-"}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[13]?.align}>
                              <Box
                                width={headers[13]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]?.seal_no
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledTextField
                                    control={control}
                                    name={`work_list.${index}.seal_no`}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors.work_list[index]?.seal_no
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      errors.work_list[index]?.seal_no?.message
                                    }
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.seal_no || "-"}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            {/* <TableCell>
                          <Box width={headers[10]?.width}>
                            {!disabled ? (
                              <ControlledDateTimePicker
                                control={control}
                                name={`work_list.${index}.start_date`}
                              />
                            ) : (
                              <Typography>
                                {field.start_date
                                  ? formatDateTime(field.start_date.toDate())
                                  : "-"}
                              </Typography>
                            )}
                          </Box>
                        </TableCell> */}
                            <TableCell align={headers[14]?.align}>
                              <Box width={headers[14]?.width}>
                                {!disabled ? (
                                  <ControlledDateTimePicker
                                    control={control}
                                    name={`work_list.${index}.end_date`}
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.end_date
                                      ? formatDateTime(field.end_date.toDate())
                                      : "-"}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[15]?.align}>
                              <Box width={headers[15]?.width}>
                                {!disabled ? (
                                  <ControlledNumberTextField
                                    control={control}
                                    name={`work_list.${index}.arrived_kilometer`}
                                    error={Boolean(
                                      errors.work_list &&
                                        errors.work_list[index]
                                          ?.arrived_kilometer
                                    )}
                                    disabled={isFinished}
                                    decimalScale={0}
                                  />
                                ) : (
                                  <Typography>
                                    {field.arrived_kilometer
                                      ? field.arrived_kilometer.toLocaleString()
                                      : "-"}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            {!disabled && !isFinished && (
                              <TableCell align="center">
                                <Box width={headers[16]?.width}>
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                    onClick={() =>
                                      openDeleteItemConfirmationHandler(index)
                                    }
                                  >
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            )}
                          </>
                        ) : (
                          <>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  alignItems: "center",
                                  width: headers[0]?.width,
                                }}
                              >
                                {!disabled && (
                                  <MenuIcon
                                    fontSize="small"
                                    sx={{ color: "#BDBDBD" }}
                                  />
                                )}
                                <Typography>
                                  {field.location_unique_id}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[1]?.align}>
                              <Box width={headers[1]?.width}>
                                <Typography>{field.location_name}</Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[2]?.align}>
                              <Box
                                width={headers[2]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]?.type
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledSelect
                                    control={control}
                                    name={`work_list.${index}.type`}
                                    options={workType}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors?.work_list[index]?.type
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      (
                                        errors?.work_list[index]
                                          ?.type as FieldError
                                      )?.message
                                    }
                                    disabled={isFinished}
                                    onChange={onTypeChange}
                                  />
                                ) : (
                                  <Typography>
                                    {
                                      workType.find(
                                        (work) => work.value === field.type
                                      )?.label
                                    }
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[3]?.align}>
                              <Box width={headers[3]?.width}>
                                <Typography>
                                  {field.distance.toLocaleString()}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[4]?.align}>
                              <Box
                                width={headers[4]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]?.coordinator_name
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledTextField
                                    control={control}
                                    name={`work_list.${index}.coordinator_name`}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors.work_list[index]
                                          ?.coordinator_name
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      errors.work_list[index]?.coordinator_name
                                        ?.message
                                    }
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.coordinator_name}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[5]?.align}>
                              <Box
                                width={headers[5]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]
                                        ?.coordinator_phone
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledTextField
                                    control={control}
                                    name={`work_list.${index}.coordinator_phone`}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors.work_list[index]
                                          ?.coordinator_phone
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      errors.work_list[index]?.coordinator_phone
                                        ?.message
                                    }
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.coordinator_phone}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[6]?.align}>
                              <Box
                                width={headers[6]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]?.special_request
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledTextField
                                    control={control}
                                    name={`work_list.${index}.special_request`}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors.work_list[index]?.special_request
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      errors.work_list[index]?.special_request
                                        ?.message
                                    }
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.special_request || "-"}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[7]?.align}>
                              <Box
                                width={headers[7]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]?.customer_name
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledTextField
                                    control={control}
                                    name={`work_list.${index}.customer_name`}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors.work_list[index]?.customer_name
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      errors.work_list[index]?.customer_name
                                        ?.message
                                    }
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.customer_name || "-"}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[8]?.align}>
                              <Box
                                width={headers[8]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]?.reference_code
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledTextField
                                    control={control}
                                    name={`work_list.${index}.reference_code`}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors.work_list[index]?.reference_code
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      errors.work_list[index]?.reference_code
                                        ?.message
                                    }
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.reference_code || "-"}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[9]?.align}>
                              <Box
                                width={headers[9]?.width}
                                mt={
                                  Boolean(
                                    errors?.work_list &&
                                      errors?.work_list[index]?.seal_no
                                  )
                                    ? 3
                                    : 0
                                }
                              >
                                {!disabled ? (
                                  <ControlledTextField
                                    control={control}
                                    name={`work_list.${index}.seal_no`}
                                    error={Boolean(
                                      errors?.work_list &&
                                        errors.work_list[index]?.seal_no
                                    )}
                                    helperText={
                                      errors?.work_list &&
                                      errors.work_list[index]?.seal_no?.message
                                    }
                                    disabled={isFinished}
                                  />
                                ) : (
                                  <Typography>
                                    {field.seal_no || "-"}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[10]?.align}>
                              <Box width={headers[10]?.width}>
                                {!disabled ? (
                                  <ControlledDateTimePicker
                                    control={control}
                                    name={`work_list.${index}.end_date`}
                                    disabled={
                                      status !== "delivering" || isFinished
                                    }
                                  />
                                ) : (
                                  <Typography>
                                    {field.end_date
                                      ? formatDateTime(field.end_date.toDate())
                                      : "-"}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[11]?.align}>
                              <Box width={headers[11]?.width}>
                                {!disabled ? (
                                  <ControlledNumberTextField
                                    control={control}
                                    name={`work_list.${index}.arrived_kilometer`}
                                    error={Boolean(
                                      errors.work_list &&
                                        errors.work_list[index]
                                          ?.arrived_kilometer
                                    )}
                                    disabled={
                                      status !== "delivering" || isFinished
                                    }
                                    decimalScale={0}
                                  />
                                ) : (
                                  <Typography>
                                    {field.arrived_kilometer
                                      ? field.arrived_kilometer.toLocaleString()
                                      : "-"}
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align={headers[12]?.align}>
                              <Box width={headers[12]?.width}>
                                <Typography>
                                  {field.shipping_cost
                                    ? numberFormatter(field.shipping_cost)
                                    : "-"}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[13]?.align}>
                              <Box width={headers[13]?.width}>
                                <Typography>
                                  {field.secondary_driver_cost
                                    ? numberFormatter(
                                        field.secondary_driver_cost
                                      )
                                    : "-"}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[14]?.align}>
                              <Box width={headers[14]?.width}>
                                <Typography>
                                  {field.drop_cost
                                    ? numberFormatter(field.drop_cost)
                                    : "-"}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align={headers[15]?.align}>
                              <Box width={headers[15]?.width}>
                                <Typography>
                                  {field.max_shipping_cost
                                    ? numberFormatter(field.max_shipping_cost)
                                    : "-"}
                                </Typography>
                              </Box>
                            </TableCell>
                            {!disabled && !isFinished && (
                              <TableCell align="center">
                                <Box width={headers[16]?.width}>
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                    onClick={() =>
                                      openDeleteItemConfirmationHandler(index)
                                    }
                                  >
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            )}
                          </>
                        )}
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};

export default WorkListTable;
