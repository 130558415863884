import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { IQuotation } from "types/Sales/quotation";
import {
  QuotationCreateInput,
  QuotationFindUniqueQuery,
  QuotationUpdateInput,
  QuotationCancelMutation,
  useQuotationCreateMutation,
  useQuotationFindUniqueQuery,
  useQuotationUpdateMutation,
  useQuotationCancelMutation,
  ActivityType,
  ActivityLogDocumentType,
  SalesModelType,
} from "generated/sales";

import { useActivityLog } from "hooks/use-activity-log";
import { useDisable } from "hooks/use-disable";

import { useModal } from "hooks/use-modal";

import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

import {
  quotationQueryFormatter,
  quotationCreatePayloadFormatter,
  quotationUpdatePayloadFormatter,
} from "utils/Formatter/Sales/Quotation";

import { Stack } from "@mui/material";
import CustomizedButton from "components/Custom/CustomizedButton";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import LoadingUI from "components/UI/LoadingUI";
import SalesFooter from "components/Form/Sales/Footer";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import QuotationHeader from "components/Form/Sales/Quotation/Header";
import CustomerInfo from "components/Form/Sales/CustomerInfo";
import AddressInfo from "components/Form/Sales/CustomerInfo/AddressInfo";
import DeliveryInfo from "components/Form/Sales/Quotation/DeliveryInfo";
import PlanDateInfo from "components/Form/Sales/Quotation/PlanDateInfo";
import SalesItemList from "components/Table/Sales/ItemList";
import AcceptModal from "components/Form/Sales/Accept/AcceptModal";
import SalesAcceptForm from "components/Form/Sales/Accept";

import { errorMessageFormatter } from "utils/Global";

const QuotationDocumentInfoTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useDisable();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  const { createActivityLog } = useActivityLog();

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const {
    reset,
    watch,
    handleSubmit,
    trigger,
    getValues,
    setError,
    formState: { isDirty, dirtyFields, isValid },
  } = useFormContext<IQuotation>();

  const status = watch("aggrid_status");

  const { data, isLoading, isSuccess, refetch } =
    useQuotationFindUniqueQuery<QuotationFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 10,
      }
    );

  useEffect(() => {
    setIsLoadingData(true);
    if (isSuccess) {
      const { QuotationFindUnique } = data;

      const getQuotationData = async () => {
        const formatted = quotationQueryFormatter(QuotationFindUnique);
        reset(formatted);
        setIsLoadingData(false);
      };
      getQuotationData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (id && status !== "draft") {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled, status]);

  useEffect(() => {
    if (state) {
      const { copied_unique_id, copied_id, ...otherState } = state;
      reset({
        ...otherState,
        created_date: dayjs(),
        due_date: dayjs(),
        issue_date: dayjs(),
        plan_start_date: dayjs(),
        plan_end_date: dayjs(),
      });
    }
  }, [reset, state]);

  const { mutateAsync: create, isLoading: isCreating } =
    useQuotationCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useQuotationUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: cancel, isLoading: isCancelling } =
    useQuotationCancelMutation<QuotationCancelMutation>(graphQLClient);

  const draftHandler = async (data: IQuotation) => {
    if (!id) {
      try {
        const formatData = quotationCreatePayloadFormatter(data, "draft");
        const { QuotationCreate } = await create({
          createInput: formatData as QuotationCreateInput,
        });
        enqueueSnackbar(t("sales.quotation.create.success"), {
          variant: "success",
        });
        navigate(`/sales/quotation/${QuotationCreate?.id}`);

        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.Quotation,
            reference_id: QuotationCreate?.id || 0,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: QuotationCreate?.id,
                unique_id: QuotationCreate?.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.Quotation,
          reference_id: QuotationCreate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(formatError || t("sales.quotation.create.fail"), {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = await quotationUpdatePayloadFormatter(data, "draft");
        const { QuotationUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: formatData as QuotationUpdateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });

        await refetch();

        if (isDirty && Object.keys(dirtyFields).length > 0) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.Quotation,
            reference_id: QuotationUpdate?.id || 0,
            activity_detail: {
              secondary_operation: ActivityType.Edit,
            },
          });
        }
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const waitAcceptHandler = async (data: IQuotation) => {
    if (!id) {
      try {
        const formatData = quotationCreatePayloadFormatter(data, "wait_accept");

        const { QuotationCreate } = await create({
          createInput: formatData as QuotationCreateInput,
        });
        enqueueSnackbar(t("sales.quotation.create.success"), {
          variant: "success",
        });
        navigate(`/sales/quotation/${QuotationCreate?.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.Quotation,
            reference_id: QuotationCreate?.id || 0,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: QuotationCreate?.id,
                unique_id: QuotationCreate?.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.Quotation,
          reference_id: QuotationCreate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_accept",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(formatError || t("sales.quotation.create.fail"), {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = await quotationUpdatePayloadFormatter(
          data,
          "wait_accept"
        );
        const { QuotationUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: formatData as QuotationUpdateInput,
        });
        enqueueSnackbar(t("sales.quotation.status_change.success"), {
          variant: "success",
        });

        await refetch();

        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.Quotation,
          reference_id: QuotationUpdate?.id || 0,
          activity_detail: {
            prev_status: data.aggrid_status,
            curr_status: "wait_accept",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");

        enqueueSnackbar(
          formatError || t("sales.quotation.status_change.fail"),
          {
            variant: "error",
          }
        );
      }
    }
  };

  const validateAcceptHandler = () => {
    const accepted_date = getValues("accepted_date");
    if (!accepted_date) {
      setError("accepted_date", { message: "กรุณาระบุ" });
      enqueueSnackbar("กรุณาระบุข้อมูลที่จำเป็น", {
        variant: "error",
      });
      return;
    }
    submitAcceptModalHandler();
  };

  const acceptHandler = async () => {
    try {
      trigger();
      if (isValid) {
        const data = getValues();

        const formatData = await quotationUpdatePayloadFormatter(
          data,
          "accepted"
        );
        const { QuotationUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: formatData as QuotationUpdateInput,
        });
        enqueueSnackbar(`${t("sales.quotation.accept.success")}`, {
          variant: "success",
        });

        await refetch();

        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.Quotation,
          reference_id: QuotationUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.aggrid_status,
            curr_status: "accepted",
            updated_fields:
              formattedDirtyFields?.length > 0
                ? formattedDirtyFields
                : undefined,
          },
        });
      }
    } catch (err) {
      enqueueSnackbar(`${t("sales.quotation.accept.fail")}`, {
        variant: "error",
      });
    }
  };

  const notAcceptHandler = async () => {
    try {
      trigger();
      if (isValid) {
        const data = getValues();

        const formatData = await quotationUpdatePayloadFormatter(
          data,
          "not_accepted"
        );
        const { QuotationUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: formatData as QuotationUpdateInput,
        });
        enqueueSnackbar(`${t("sales.quotation.not_accept.success")}`, {
          variant: "success",
        });

        await refetch();

        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.Quotation,
          reference_id: QuotationUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.aggrid_status,
            curr_status: "not_accepted",
            updated_fields:
              formattedDirtyFields?.length > 0
                ? formattedDirtyFields
                : undefined,
          },
        });
      }
    } catch (err) {
      enqueueSnackbar(`${t("sales.quotation.not_accept.fail")}`, {
        variant: "error",
      });
    }
  };

  const editHandler = async (data: IQuotation, status?: string) => {
    try {
      const formatData = await quotationUpdatePayloadFormatter(
        data,
        status || data?.main_status || ""
      );
      const { QuotationUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: formatData as QuotationUpdateInput,
      });

      enqueueSnackbar(`${t("sentence.edit")}สำเร็จ`, {
        variant: "success",
      });

      setDisabled(true);

      await refetch();

      if (status && data.aggrid_status !== status) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.Quotation,
          reference_id: QuotationUpdate?.id || 0,
          activity_detail: {
            prev_status: data.aggrid_status,
            curr_status: status,
          },
        });
      } else {
        if (isDirty && Object.keys(dirtyFields).length > 0) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.Quotation,
            reference_id: QuotationUpdate?.id || 0,
            activity_detail: {
              secondary_operation: ActivityType.Edit,
            },
          });
        }
      }
    } catch (err) {
      enqueueSnackbar(`${t("sentence.edit")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      await cancel({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.Quotation,
        reference_id: parseInt(id!),
        activity_detail: {
          prev_status: status,
          curr_status: "cancelled",
        },
      });
      await refetch();
      enqueueSnackbar(t("sales.quotation.cancel.success"), {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(t("sales.quotation.cancel.fail"), {
        variant: "error",
      });
    }
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    reset();
  };

  const editClickHandler = () => {
    setDisabled(false);
  };

  const {
    modal: acceptModal,
    openModalHandler: openAcceptModalHandler,
    closeModalHandler: closeAcceptModalHandler,
    submitModalHandler: submitAcceptModalHandler,
  } = useModal(acceptHandler);

  const renderButton = (status: string | undefined) => {
    switch (status) {
      case "draft":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating || isCancelling}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              variant="contained"
              title={t("status.wait_accept")}
              disabled={isCreating || isUpdating || isCancelling}
              onClick={handleSubmit(waitAcceptHandler)}
            />
          </>
        );
      case "wait_accept":
        if (!disabled)
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isUpdating || isCancelling}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={() =>
                  handleSubmit((data) => editHandler(data, status))()
                }
                disabled={isUpdating || isCancelling}
              />
            </>
          );
        return (
          <CustomizedButton
            title={t("sales.quotation.accept.index")}
            variant="contained"
            onClick={openAcceptModalHandler}
            disabled={isUpdating || isCancelling}
          />
        );
      case "accepted":
      case "not_accepted":
      case "finished":
        return <></>;
      case "cancelled":
        break;
      default:
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating || isCancelling}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              variant="contained"
              title={t("status.wait_accept")}
              disabled={isCreating || isUpdating || isCancelling}
              onClick={handleSubmit(waitAcceptHandler)}
            />
          </>
        );
    }
  };

  if (
    id &&
    (isLoadingData || isLoading || isCreating || isUpdating || isCancelling)
  ) {
    return <LoadingUI />;
  }
  return (
    <>
      <QuotationHeader
        editClickHandler={editClickHandler}
        cancelHandler={cancelHandler}
        notAcceptHandler={notAcceptHandler}
      />
      <CustomerInfo />
      <AddressInfo />
      <DeliveryInfo />
      <PlanDateInfo />
      <SalesItemList documentType={SalesModelType.Quotation} />
      <SalesFooter documentType={SalesModelType.Quotation} />
      {(status === "accepted" || status === "finished") && (
        <CustomizedBox margin={0}>
          <SalesAcceptForm documentType={SalesModelType.Quotation} />
        </CustomizedBox>
      )}
      <AcceptModal
        open={acceptModal}
        handleClose={closeAcceptModalHandler}
        onSubmit={validateAcceptHandler}
        documentType={SalesModelType.Quotation}
      />
      <BottomNavbar>
        <Stack direction="row" spacing={1} alignItems="center">
          {renderButton(status)}
        </Stack>
      </BottomNavbar>
    </>
  );
};

export default QuotationDocumentInfoTab;
