import { Controller, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box } from "@mui/material";
import CustomizedCreatable from "../../Custom/CustomizedCreatable";
import CustomizedChips from "../../Custom/CustomizedChips";
import { IContact } from "../../../types/Contact/contact";
import { useDisable } from "../../../hooks/use-disable";
import ControlledNumberTextField from "../../Controller/ControlledNumberTextField";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ContactSpecificTab = () => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<IContact>();

  const [disabled] = useDisable();

  const { t } = useTranslation();

  const fields = watch("required_purchase_item_type");

  const deleteProductHandler = (index: number) => {
    const newFields = fields.filter((_, fieldIndex) => fieldIndex !== index);
    setValue("required_purchase_item_type", newFields);
  };

  const itemValidation = Yup.object({
    purchase_item: Yup.string().max(50, "กรอกได้ไม่เกิน 50 ตัวอักษร"),
  });
  const {
    control: itemControl,
    formState: { errors: itemErrors },
    resetField,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      purchase_item: "",
    },
    resolver: yupResolver(itemValidation),
  });

  const renderProductChips = () => {
    return fields.map((item, index) => (
      <Box sx={{ display: "inline-block", mr: 1 }} key={`${item}-${index}`}>
        <CustomizedChips
          variant="outlined"
          color="primary"
          label={item}
          onDelete={!disabled ? () => deleteProductHandler(index) : undefined}
          isRounded
        />
      </Box>
    ));
  };

  return (
    <>
      <Typography fontWeight="bold" sx={{ my: 2 }}>
        {t("contact.specific_info.required_purchase_item_type")}
      </Typography>
      <Grid container spacing={1.5} alignItems={"center"}>
        {!disabled && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              control={itemControl}
              name="purchase_item"
              render={({ field }) => (
                <CustomizedCreatable
                  {...field}
                  options={[]}
                  label={t("contact.specific_info.required_purchase_item_type")}
                  onChange={(_: any, newValue: any) => {
                    let newFields = fields;
                    if (typeof newValue === "string") {
                      return;
                    } else if (
                      newValue &&
                      newValue.inputValue &&
                      !itemErrors.purchase_item
                    ) {
                      if (!fields.includes(newValue.inputValue)) {
                        newFields.push(newValue.inputValue);
                      }
                    } else {
                      if (newValue && !itemErrors.purchase_item)
                        newFields.push(newValue.value);
                    }
                    setValue("required_purchase_item_type", newFields);
                    resetField("purchase_item");
                  }}
                  onInputChange={(_, newValue) => {
                    field.onChange(newValue);
                  }}
                  readOnly={disabled}
                  disabled={disabled}
                  error={Boolean(itemErrors.purchase_item)}
                  helperText={itemErrors.purchase_item?.message}
                />
              )}
            />
          </Grid>
        )}
        {!disabled && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {renderProductChips()}
          </Grid>
        )}
        {disabled && fields.length > 0 && (
          <Grid item xs={12}>
            {renderProductChips()}
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledNumberTextField
            control={control}
            label={t("contact.specific_info.purchase_amount_per_month")}
            name="purchase_amount_per_month"
            error={Boolean(errors.purchase_amount_per_month)}
            helperText={errors?.purchase_amount_per_month?.toString()}
            viewMode={disabled}
            endAdornmentMessage="บาท"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ContactSpecificTab;
