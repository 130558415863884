import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import {
  Grid,
  Box,
  IconButton,
  Typography,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import { useModal } from "../../../../hooks/use-modal";
import BillingAddressModal from "../../../UI/Modal/BillingAddressModal";
import { useDisable } from "../../../../hooks/use-disable";
import { countryFormatter } from "../../../../utils/Formatter/Global";
import ControlledTextField from "components/Controller/ControlledTextField";
import CheckedCustomerModal from "components/UI/Modal/CheckedModal/CheckedCustomerModal";
import { useContactDescription } from "hooks/use-contact-description";
import ControlledSelect from "components/Controller/ControlledSelect";
import { IContact } from "types/Contact/contact";
import { IMaintenance } from "types/Logistic/maintenance";
import { useEffect, useState } from "react";
import { MaintenanceRepairType } from "generated/sales";
import CustomizedCheckbox from "components/Custom/CustomizedCheckbox";
import { ISelectOption } from "types/global";

type Props = {
  forceDisabled?: boolean;
};

const garageTypeOption: ISelectOption[] = [
  {
    id: 1,
    label: "อู่ภายใน",
    value: "อู่ภายใน",
  },
  {
    id: 2,
    label: "อู่ภายนอก/ศูนย์บริการ",
    value: "อู่ภายนอก/ศูนย์บริการ",
  },
];

const MaintenanceInfo = ({ forceDisabled }: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<IMaintenance>();

  const { getValues: getMaValues } = useFormContext();

  const currentMaType = useWatch({ control, name: "ma_type" });

  const [maType, setMaType] = useState([
    {
      label: t("logistic.maintenance.ma_details.ma_type.engine"),
      selected: currentMaType.includes(MaintenanceRepairType.Engine),
      value: MaintenanceRepairType.Engine,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.clutch"),
      selected: currentMaType.includes(MaintenanceRepairType.Clutch),
      value: MaintenanceRepairType.Clutch,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.gear_rear"),
      selected: currentMaType.includes(MaintenanceRepairType.GearRear),
      value: MaintenanceRepairType.GearRear,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.brake"),
      selected: currentMaType.includes(MaintenanceRepairType.Brake),
      value: MaintenanceRepairType.Brake,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.electrical_AC"),
      selected: currentMaType.includes(MaintenanceRepairType.ElectricalAc),
      value: MaintenanceRepairType.ElectricalAc,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.suspension"),
      selected: currentMaType.includes(MaintenanceRepairType.Suspension),
      value: MaintenanceRepairType.Suspension,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.tire_wheel"),
      selected: currentMaType.includes(MaintenanceRepairType.TireWheel),
      value: MaintenanceRepairType.TireWheel,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.AIRuu"),
      selected: currentMaType.includes(MaintenanceRepairType.Airuu),
      value: MaintenanceRepairType.Airuu,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.tail_lift"),
      selected: currentMaType.includes(MaintenanceRepairType.TailLift),
      value: MaintenanceRepairType.TailLift,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.battery"),
      selected: currentMaType.includes(MaintenanceRepairType.Battery),
      value: MaintenanceRepairType.Battery,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.air_generator_40"),
      selected: currentMaType.includes(MaintenanceRepairType.AirGenerator_40),
      value: MaintenanceRepairType.AirGenerator_40,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.engine_oil"),
      selected: currentMaType.includes(MaintenanceRepairType.EngineOil),
      value: MaintenanceRepairType.EngineOil,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.gear_oil"),
      selected: currentMaType.includes(MaintenanceRepairType.GearOil),
      value: MaintenanceRepairType.GearOil,
    },
    {
      label: t("logistic.maintenance.ma_details.ma_type.others"),
      selected: currentMaType.includes(MaintenanceRepairType.Others),
      value: MaintenanceRepairType.Others,
    },
  ]);

  useEffect(() => {
    setMaType([
      {
        label: t("logistic.maintenance.ma_details.ma_type.engine"),
        selected: currentMaType.includes(MaintenanceRepairType.Engine),
        value: MaintenanceRepairType.Engine,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.clutch"),
        selected: currentMaType.includes(MaintenanceRepairType.Clutch),
        value: MaintenanceRepairType.Clutch,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.gear_rear"),
        selected: currentMaType.includes(MaintenanceRepairType.GearRear),
        value: MaintenanceRepairType.GearRear,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.brake"),
        selected: currentMaType.includes(MaintenanceRepairType.Brake),
        value: MaintenanceRepairType.Brake,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.electrical_AC"),
        selected: currentMaType.includes(MaintenanceRepairType.ElectricalAc),
        value: MaintenanceRepairType.ElectricalAc,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.suspension"),
        selected: currentMaType.includes(MaintenanceRepairType.Suspension),
        value: MaintenanceRepairType.Suspension,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.tire_wheel"),
        selected: currentMaType.includes(MaintenanceRepairType.TireWheel),
        value: MaintenanceRepairType.TireWheel,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.AIRuu"),
        selected: currentMaType.includes(MaintenanceRepairType.Airuu),
        value: MaintenanceRepairType.Airuu,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.tail_lift"),
        selected: currentMaType.includes(MaintenanceRepairType.TailLift),
        value: MaintenanceRepairType.TailLift,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.battery"),
        selected: currentMaType.includes(MaintenanceRepairType.Battery),
        value: MaintenanceRepairType.Battery,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.air_generator_40"),
        selected: currentMaType.includes(MaintenanceRepairType.AirGenerator_40),
        value: MaintenanceRepairType.AirGenerator_40,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.engine_oil"),
        selected: currentMaType.includes(MaintenanceRepairType.EngineOil),
        value: MaintenanceRepairType.EngineOil,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.gear_oil"),
        selected: currentMaType.includes(MaintenanceRepairType.GearOil),
        value: MaintenanceRepairType.GearOil,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.others"),
        selected: currentMaType.includes(MaintenanceRepairType.Others),
        value: MaintenanceRepairType.Others,
      },
    ]);
  }, [t, currentMaType]);

  const onMaTypeSelected = (index: number) => {
    let newMaTypeState = maType;
    newMaTypeState[index].selected = !newMaTypeState[index].selected;
    setMaType(newMaTypeState);
    const newMaType = newMaTypeState
      .filter((type) => type.selected)
      .map((type) => type.value);
    setValue("ma_type", newMaType);
  };

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");
  const cancelled = flagStatus.includes("cancelled");

  const status = !cancelled ? mainStatus : aggrid_status;
  const disabledStatus = ["finished"].includes(status);

  const {
    modal: openBillingAddress,
    openModalHandler: openBillingAddressHandler,
    closeModalHandler: closeBillingAddress,
  } = useModal();

  const {
    openContactTable,
    closeContactTableHandler,
    contactIds,
    setContactIds,
    contactIdsSnapshot,
    setContactIdsSnapshot,
    openContactTableHandler,
  } = useContactDescription("sales");

  const finishContactsSelect = (data: IContact) => {
    setValue(
      "ma_details.email",
      data?.contact_channel_list?.find(
        (channel) => channel.contact_channel_type === "email"
      )?.contact_channel_info || ""
    );
    setValue("ma_details.garage_name", data.name + " " + data.last_name);
    setValue("ma_details.taxpayer_id", data.taxpayer_id);
    setValue("ma_details.billing_address", {
      ...data.address_list[0],
      country: data.address_list[0].country ?? "",
    });
  };

  const renderAddressString = (values: string) => {
    const addressValues = getMaValues(`ma_details[${values}]`);
    if (addressValues) {
      const { name, sub_district, district, province, postal_code, country } =
        addressValues;

      const orderedValues = {
        name,
        sub_district,
        district,
        province,
        postal_code,
        country,
      };
      let result = "";

      if (addressValues && orderedValues) {
        if (Array.isArray(addressValues)) {
          addressValues.forEach((item) => {
            if (item?.length > 0) {
              result = result + item + ", ";
            }
          });
        } else {
          const keys = Object.keys(orderedValues);
          keys.forEach((key) => {
            let newValue = "";
            if (key === "country") {
              newValue =
                countryFormatter(
                  orderedValues[key as keyof typeof orderedValues]
                ) || "";
            } else {
              newValue = orderedValues[key as keyof typeof orderedValues];
            }

            if (newValue && newValue.length > 0) {
              result = result + newValue + ", ";
            }
          });
        }
        if (result?.trim().length === 0) {
          return "-";
        }
      }
      // remove whitespace and last comma
      return result?.trim().slice(0, -1);
      // remove whitespace and last comma
    } else return "-";
  };

  return (
    <>
      <CustomizedBox>
        <Box display="flex" gap={1} alignItems="center" mb={1}>
          <Typography fontWeight={600}>
            {t("logistic.maintenance.ma_details.index")}
          </Typography>
        </Box>
        <Grid container spacing={1.5} mb={1}>
          <Grid item xs={12} sm={12} md={4}>
            <ControlledSelect
              control={control}
              name="ma_details.garage_type"
              label={t("logistic.maintenance.ma_details.garage_type")}
              options={garageTypeOption}
              error={Boolean(errors.ma_details?.garage_type)}
              helperText={errors.ma_details?.garage_type?.message}
              disabled={disabled || disabledStatus}
              viewMode={disabled}
              endAdorment={
                <Typography color="red" sx={{ marginRight: "20px" }}>
                  *
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <ControlledTextField
              control={control}
              name="ma_details.garage_name"
              label={t("logistic.maintenance.ma_details.garage_name")}
              error={Boolean(errors.ma_details?.garage_name)}
              helperText={errors.ma_details?.garage_name?.message}
              disabled={disabled || disabledStatus}
              viewMode={disabled}
              required
              InputProps={{
                endAdornment: !disabled && !disabledStatus && (
                  <InputAdornment position="end">
                    <CustomizedTooltip
                      title={`เลือก${t(
                        "logistic.maintenance.ma_details.garage_name"
                      )}`}
                    >
                      <IconButton
                        onClick={openContactTableHandler}
                        color="primary"
                        size="small"
                      >
                        <EditIcon
                          sx={{
                            fontSize: 16,
                          }}
                        />
                      </IconButton>
                    </CustomizedTooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Box display="flex" gap={1} alignItems="center" mb={1}>
          <Typography fontWeight={600}>
            {t("logistic.maintenance.ma_details.billing_address")}
          </Typography>
          {!disabled && !forceDisabled ? (
            <CustomizedTooltip
              title={`แก้ไข${t(
                "logistic.maintenance.ma_details.billing_address"
              )}`}
            >
              <IconButton
                onClick={openBillingAddressHandler}
                color="primary"
                size="small"
              >
                <EditIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </CustomizedTooltip>
          ) : null}
        </Box>
        <Typography mb={1}>{renderAddressString("billing_address")}</Typography>
        <Typography fontWeight={600}>
          {t("logistic.maintenance.ma_details.ma_type.index")}
        </Typography>
        <Grid container spacing={1.5} mb={1}>
          {maType.map((type, index) => (
            <Grid item xs={6} sm={6} md={3} key={`ma-type-${type.label}`}>
              <CustomizedCheckbox
                label={type.label}
                value={type.selected}
                onChange={() => onMaTypeSelected(index)}
                isDisabled={disabled}
              />
            </Grid>
          ))}
        </Grid>
      </CustomizedBox>
      <BillingAddressModal
        name="ma_details"
        open={openBillingAddress}
        handleClose={closeBillingAddress}
        viewMode={false}
      />
      <CheckedCustomerModal
        showSelectCustomer={openContactTable}
        closeCustomerTable={closeContactTableHandler}
        finishCustomersSelect={finishContactsSelect}
        customerIds={contactIds}
        setCustomerIds={setContactIds}
        customerIdsSnapshot={contactIdsSnapshot}
        setCustomerIdsSnapshot={setContactIdsSnapshot}
        rowSelection="single"
        onlySupplier={true}
      />
    </>
  );
};

export default MaintenanceInfo;
