import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../types/global";
import { Fragment } from "react";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";

const ContactLanding = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.contact_type.all"),
      to: "all",
    },
    {
      name: t("contact.contact_type.customer"),
      to: "customer",
    },
    {
      name: t("contact.contact_type.supplier"),
      to: "supplier",
    },
  ];
  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default ContactLanding;
