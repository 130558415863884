import { RefObject, useEffect, useState } from "react";
import { useStateContext } from "../../contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import {
  GenerateReportNoQueueQuery,
  InventoryReportType,
  ReportGenerateInput,
  useGenerateReportNoQueueQuery,
} from "generated/wms";
import { AgGridReact } from "ag-grid-react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { dateFilterModel } from "utils/AgGridFilter";

export const useInventoryReport = (
  gridRef: RefObject<AgGridReact<any>>,
  reportType: InventoryReportType
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    state: { authUser },
  } = useStateContext();

  const graphqlClient = createGraphQLClientWithMiddleware("wms");

  const [exportInput, setExportInput] = useState<ReportGenerateInput | null>(
    null
  );

  const {
    refetch: refetchData,
    isLoading: isExporting,
    isFetching,
  } = useGenerateReportNoQueueQuery<GenerateReportNoQueueQuery>(
    graphqlClient,
    {
      input: exportInput as ReportGenerateInput,
    },
    {
      enabled: false,
      retry: false,
    }
  );

  const onBtnExport = () => {
    try {
      const filterModel = gridRef?.current?.api.getFilterModel();
      const formattedFilterModel = {
        ...formatDateFilterFields(filterModel),
        is_active: filterModel &&
          filterModel.is_active && {
            ...filterModel.is_active,
            values: filterModel.is_active.values.map((v: string) =>
              parseInt(v)
            ),
          },
      };
      const sortModel = gridRef?.current?.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));
      setExportInput({
        report_type: reportType,
        priority: 1,
        user_unique_id: authUser?.unique_id || "",
        params: {
          aggridInput: {
            filterModel: formattedFilterModel,
            sortModel,
          },
        },
      });
    } catch (err) {
      enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
      setExportInput(null);
    }
  };

  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        const { data: exportData } = await refetchData();
        if (exportData) {
          window.open(exportData?.GenerateReportNoQueue || "", "_blank");
          enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
            variant: "error",
          });
          setExportInput(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
        setExportInput(null);
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [enqueueSnackbar, exportInput, refetchData, t]);

  return {
    onBtnExport,
    isExporting,
    isFetching,
  };
};

const formatDateFilterFields = (filterModel: any) => {
  const dateFields = Object.keys(filterModel).filter((key) =>
    key.includes("_date")
  );

  return dateFields.reduce((acc, key) => {
    const dateFilter = filterModel[key];
    return {
      ...acc,
      [key]: dateFilterModel(dateFilter),
    };
  }, filterModel);
};
