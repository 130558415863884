import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { useDisable } from "hooks/use-disable";
import ControlledTextField from "components/Controller/ControlledTextField";
import { Typography } from "@mui/material";
import { useState } from "react";

const Remark = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const maxRows = 10;
  const maxChars = 200;

  const [disabled] = useDisable();

  const { t } = useTranslation();

  const remarkValue = useWatch({
    control,
    name: "remark",
  });

  const focusHandler = () => {
    setIsFocused(true);
  };

  const blurHandler = () => {
    setIsFocused(false);
  };

  const mainStatus = useWatch({
    control,
    name: "main_status",
  });
  const aggrid_status = useWatch({
    control,
    name: "aggrid_status",
  });
  const flagStatus = useWatch({
    control,
    name: "flag_status",
  });

  const cancelled = flagStatus.includes("cancelled");

  const status = !cancelled ? mainStatus : aggrid_status;

  const disabledStatus = ["finished"].includes(status);

  return (
    <>
      <Typography fontWeight={600} mb={1}>
        {t("sentence.remark")}
      </Typography>
      <ControlledTextField
        name="remark"
        control={control}
        error={Boolean(errors.remark)}
        disabled={disabled || disabledStatus}
        sx={{ my: 1.5 }}
        multiline
        minRows={5}
        maxRows={maxRows}
        inputProps={{
          maxLength: maxChars,
        }}
        viewModeNoHelperText
        helperText={isFocused ? `${remarkValue?.length}/200` : undefined}
        viewMode={disabled || disabledStatus}
        placeholder={t("sentence.remark")}
        onFocus={focusHandler}
        onBlur={blurHandler}
        FormHelperTextProps={{
          style: {
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 0,
          },
        }}
      />
    </>
  );
};

export default Remark;
