import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDisable } from "hooks/use-disable";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { FieldArrayWithId, useFormContext } from "react-hook-form";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import MenuIcon from "@mui/icons-material/Menu";
import ItemCell from "./ItemCell";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import { formatNumber } from "utils/Global";
import CloseIcon from "@mui/icons-material/Close";
import PreVatAmountCell from "./PreVatAmountCell";
import WithholdingTaxCell from "./WithholdingTaxCell";
import UomCell from "./UomCell";
import { useExpensesListHeader } from "hooks/Logistic/use-expenses-list-header";

type Props = {
  fields: FieldArrayWithId<IDeliveryTrip, "expenses_list", "id">[];
  dragEndHandler: (result: DropResult) => void;
  openDeleteItemConfirmationHandler: (index: number) => void;
};

const ExpensesListTable = ({
  fields,
  dragEndHandler,
  openDeleteItemConfirmationHandler,
}: Props) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<IDeliveryTrip>();
  const [disabled] = useDisable();
  const headers = useExpensesListHeader();

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;

  const isFinished = status === "finished";

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, overflow: "scroll" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                align={header.align}
                key={index}
                sx={{
                  px: 1,
                  py: 1.5,
                  backgroundColor: "secondary.main",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    width: header.width,
                    justifyContent: header.align,
                  }}
                >
                  <Typography fontWeight={600}>{header.thaiLabel}</Typography>
                  {header.required && (
                    <Typography
                      fontSize={14}
                      color={"error.main"}
                      fontWeight={600}
                    >
                      *
                    </Typography>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={dragEndHandler}>
          <Droppable droppableId="droppable" isDropDisabled={disabled}>
            {(provided) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((field, index) => (
                  <Draggable
                    key={field.unique_id}
                    draggableId={"expenses-" + field.unique_id}
                    index={index}
                    isDragDisabled={disabled}
                  >
                    {(provided) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          ...provided.draggableProps.style,
                          breakInside: "avoid",
                        }}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              justifyContent: "center",
                              alignItems: "center",
                              width: headers[0]?.width,
                            }}
                          >
                            {!disabled && (
                              <MenuIcon
                                fontSize="small"
                                sx={{ color: "#BDBDBD" }}
                              />
                            )}
                            <Typography>{index + 1}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align={headers[1]?.align}>
                          <Box width={headers[1]?.width}>
                            <ItemCell
                              field={field}
                              index={index}
                              disabledStatus={isFinished}
                            />
                          </Box>
                        </TableCell>
                        <TableCell align={headers[2]?.align}>
                          <Box
                            width={headers[2]?.width}
                            mt={
                              Boolean(
                                errors?.expenses_list &&
                                  errors.expenses_list[index]?.qty
                              )
                                ? 3
                                : 0
                            }
                          >
                            {!disabled ? (
                              <ControlledNumberTextField
                                fullWidth
                                control={control}
                                name={`expenses_list.${index}.qty`}
                                error={Boolean(
                                  errors?.expenses_list &&
                                    errors.expenses_list[index]?.qty
                                )}
                                helperText={
                                  errors?.expenses_list &&
                                  errors.expenses_list[index]?.qty?.message
                                }
                                textAlign="left"
                                disabled={isFinished}
                              />
                            ) : (
                              <Typography>{formatNumber(field.qty)}</Typography>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell align={headers[3]?.align}>
                          <Box width={headers[3]?.width}>
                            <UomCell
                              field={field}
                              index={index}
                              disabledStatus={isFinished}
                            />
                          </Box>
                        </TableCell>
                        <TableCell align={headers[4]?.align}>
                          <Box
                            width={headers[4]?.width}
                            mt={
                              Boolean(
                                errors?.expenses_list &&
                                  errors.expenses_list[index]?.price_per_unit
                              )
                                ? 3
                                : 0
                            }
                          >
                            {!disabled ? (
                              <ControlledNumberTextField
                                fullWidth
                                control={control}
                                name={`expenses_list.${index}.price_per_unit`}
                                error={Boolean(
                                  errors?.expenses_list &&
                                    errors.expenses_list[index]?.price_per_unit
                                )}
                                helperText={
                                  errors?.expenses_list &&
                                  errors.expenses_list[index]?.price_per_unit
                                    ?.message
                                }
                                disabled={isFinished}
                              />
                            ) : (
                              <Typography>
                                {formatNumber(field.price_per_unit)}
                              </Typography>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell align={headers[5]?.align}>
                          <Box width={headers[5]?.width}>
                            <PreVatAmountCell field={field} index={index} />
                          </Box>
                        </TableCell>
                        <TableCell align={headers[6]?.align}>
                          <Box width={headers[6]?.width}>
                            <WithholdingTaxCell field={field} index={index} />
                          </Box>
                        </TableCell>
                        {!disabled && !isFinished && (
                          <TableCell>
                            <Box width={headers[7]?.width}>
                              {![160].includes(field.item_id) && (
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                  onClick={() =>
                                    openDeleteItemConfirmationHandler(index)
                                  }
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};

export default ExpensesListTable;
