import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IModalUI } from "../../../types/global";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    paddingTop: "8px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
    paddingTop: "0",
  },
}));

const ModalUI = ({
  open,
  handleClose,
  fullWidth,
  maxWidth,
  title,
  titleSize,
  titleSx,
  children,
  width,
  isLoading,
  isRed,
  startIcon,
  action,
  TransitionProps,
  centerAction,
  sx,
  closeIconSize,
}: IModalUI) => {
  function CustomCloseIcon({ size = 24 }) {
    return <CloseIcon sx={{ fontSize: size }} />;
  }
  return (
    <StyledDialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: width ?? "auto",
          },
        },
        ...(sx || {}),
      }}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionProps={TransitionProps}
    >
      {isLoading ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CustomCloseIcon size={closeIconSize} />
            </IconButton>
          ) : null}

          {startIcon ? (
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                m: 0,
                px: 2,
                py: 1,
              }}
            >
              {startIcon}
              <DialogTitle
                id="alert-dialog-title"
                sx={{
                  padding: 0,
                  fontSize: titleSize ? titleSize : "18px",
                }}
              >
                {title}
              </DialogTitle>
            </Box>
          ) : (
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                m: 0,
                p: 2,
                fontSize: titleSize ? titleSize : "18px",
              }}
            >
              {title}
            </DialogTitle>
          )}

          <DialogContent>{children}</DialogContent>
          {action && (
            <DialogActions
              sx={{ justifyContent: centerAction ? "center" : "flex-end" }}
            >
              {action}
            </DialogActions>
          )}
        </>
      )}
    </StyledDialog>
  );
};

export default ModalUI;
