import { Box, Typography } from "@mui/material";
import { IPdfInfo } from "types/global";

const PDFRightInfo = ({ headers, info }: IPdfInfo) => {
  return (
    <Box px={1} py={0.5}>
      {headers.map((name, index) => (
        <Box sx={{ display: "flex", gap: 0.5 }} key={index}>
          <Typography
            sx={{
              fontSize: 10.66,
              fontWeight: 600,
              color: "#333333",
              flex: 2,
            }}
            lineHeight={2}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: 10.66,
              flex: 3,
              width: "100%",
              wordBreak: "break-word",
            }}
            lineHeight={2}
          >
            {info[index]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default PDFRightInfo;
