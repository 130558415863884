import { useSnackbar } from "notistack";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import {
  Controller,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import {
  TagCreateInput,
  TagsFindManyByDocumentTypeQuery,
  useTagCreateMutation,
  useTagDeleteMutation,
  useTagsFindManyByDocumentTypeQuery,
} from "../../generated/general";
import CustomizedCreatable from "../Custom/CustomizedCreatable";
import CustomizedChips from "../Custom/CustomizedChips";
import CloseIcon from "@mui/icons-material/Close";
import { useDisable } from "../../hooks/use-disable";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

type Props = {
  documentType: any;
  name: string;
  label?: string;
  onClickHandler?: (data: string) => void;
  fullWidth?: boolean;
};

interface Tag {
  id?: number;
  name: string;
  document_type: any;
}

// interface ModalPayload {
//   value: boolean;
//   document_id_list: string[];
//   optionToDelete: string;
//   uniqueInput: { id: number };
// }

type FieldArrayType = {
  tag_list: Tag[];
};

const TagList = ({
  documentType,
  name,
  label,
  onClickHandler,
  fullWidth,
}: Props) => {
  const { control, getValues, setValue } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const [tagList, setTagList] = useState<Tag[]>([]);
  const [disabled] = useDisable();
  //   const [modalDelete, setModalDelete] = useState<ModalPayload>({
  //     value: false,
  //     document_id_list: [],
  //     optionToDelete: "",
  //     uniqueInput: { id: 0 },
  //   });

  const { fields, append, remove } = useFieldArray<
    FieldArrayType,
    "tag_list",
    "id"
  >({
    // control props comes from useForm (optional: if you are using FormContext)
    name: "tag_list", // unique name for your Field Array
  });

  const tagValidation = Yup.object({
    tag: Yup.string().max(50, "กรอกได้ไม่เกิน 50 ตัวอักษร"),
  });

  const {
    control: tagControl,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      tag: "",
    },
    resolver: yupResolver(tagValidation),
  });

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: tagListResult, isSuccess } =
    useTagsFindManyByDocumentTypeQuery<TagsFindManyByDocumentTypeQuery>(
      graphQLClient,
      {
        documentType,
      }
    );

  const { mutateAsync: createTag } = useTagCreateMutation<Error>(
    graphQLClient,
    {
      onSuccess(data) {
        const { TagCreate } = data;
        setTagList((prev) => [...prev, TagCreate]);
      },
    }
  );

  //   const { mutate: verifyTag } = useContactTagDeleteVerifyMutation<Error>(
  //     graphQLClient,
  //     {
  //       onSuccess(data, variables) {
  //         if (
  //           data.contactTagDeleteVerify &&
  //           data.contactTagDeleteVerify.length === 0
  //         ) {
  //           deleteOptionHandler(variables.uniqueInput ?? { id: 0 });
  //         } else
  //           setModalDelete((prev) => ({
  //             ...prev,
  //             value: true,
  //             document_id_list: data.contactTagDeleteVerify || [],
  //             uniqueInput: variables.uniqueInput ?? { id: 0 },
  //           }));
  //       },
  //       onError() {
  //         enqueueSnackbar("ไม่มีกลุ่มนี้อยู่ในระบบ", {
  //           variant: "error",
  //         });
  //       },
  //     }
  //   );

  const { mutate: daleteTag } = useTagDeleteMutation<Error>(graphQLClient, {
    onSuccess(data) {
      setTagList((prevOptions) =>
        prevOptions.filter((option) => option.id !== data.TagDelete?.id)
      );
    },
    onError() {
      enqueueSnackbar("ลบการจัดกลุ่มไม่สำเร็จ", {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const { TagsFindManyByDocumentType } = tagListResult;
      setTagList(TagsFindManyByDocumentType);
    }
  }, [isSuccess, tagListResult]);

  //   const getAllTag = useCallback(() => {
  //     if (isSuccess) {
  //       const { contactTagsFindByEntity } = tagListResult;
  //       const tagListType = contactTagsFindByEntity as TagList[];
  //       setTagList(tagListType);
  //     }
  //   }, [isSuccess, tagListResult]);

  const createOptionHandler = async (createInput: TagCreateInput) => {
    const { TagCreate } = await createTag({ data: createInput });
    return TagCreate;
  };

  //   const deleteVerify = async (id: number, name: string) => {
  //     const findId = tagList.find((tag) => tag.id === id);
  //     if (findId) {
  //       setModalDelete((prev) => ({
  //         ...prev,
  //         optionToDelete: name,
  //       }));
  //       //   verifyTag({ uniqueInput });
  //     }
  //   };

  const deleteOptionHandler = async (id: number) => {
    daleteTag({
      uniqueInput: {
        id,
      },
    });
    setTagList((prevOptions) =>
      prevOptions.filter((option) => option.id !== id)
    );
    const value: Tag[] = getValues(name);
    const filterValue = value.filter((option) => option.id !== id);
    setValue(name, filterValue);
  };

  //   const closeModalHandler = () => {
  //     setModalDelete((prev) => ({
  //       ...prev,
  //       value: false,
  //     }));
  //     setTimeout(() => {
  //       setModalDelete((prev) => ({
  //         ...prev,
  //         document_id_list: [],
  //         optionToDelete: "",
  //         uniqueInput: { id: 0 },
  //       }));
  //     }, 700);
  //   };

  //   const confirmationDeleteAction = () => {
  //     const { uniqueInput } = modalDelete;
  //     deleteOptionHandler(uniqueInput);
  //     closeModalHandler();
  //   };

  return (
    <>
      {!disabled && (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={fullWidth ? 12 : 4}
            lg={fullWidth ? 12 : 4}
            xl={fullWidth ? 12 : 4}
          >
            <Controller
              name="tag"
              control={tagControl}
              render={({ field }) => (
                <CustomizedCreatable
                  {...field}
                  options={
                    tagList?.map((tag) => ({
                      id: tag.id,
                      value: tag.name,
                      label: tag.name,
                    })) || []
                  }
                  // inputValue=""
                  // value=""
                  error={Boolean(errors.tag)}
                  helperText={errors.tag?.message}
                  placeholder={label}
                  onChange={async (_, newValue) => {
                    if (newValue && newValue.inputValue) {
                      field.onChange(newValue.inputValue);
                      const createInput: TagCreateInput = {
                        name: newValue.inputValue || "",
                        document_type: documentType,
                      };
                      const data = await createOptionHandler(createInput);
                      append({
                        id: data.id,
                        name: data.name,
                        document_type: documentType,
                      });

                      // Create a new value from the user input
                    } else {
                      if (newValue) {
                        field.onChange(newValue.value);
                        const existingValue: Tag[] = getValues(name);
                        const isExist = existingValue.find(
                          (value) => value.name === newValue.value
                        );
                        if (!isExist) {
                          append({
                            id: newValue.id,
                            name: newValue.value,
                            document_type: documentType,
                          });
                        }
                      }
                    }
                  }}
                  onInputChange={(_, newInputValue: any) => {
                    field.onChange(newInputValue);
                  }}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  renderOption={(props: any, option: any) => {
                    return (
                      <li
                        {...props}
                        value={option.name}
                        key={option.id || option}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            wordBreak: "break-word",
                          }}
                        >
                          {option.label ?? option}
                        </Typography>
                        {!option?.label?.includes("เพิ่ม ") && (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              deleteOptionHandler(option.id);
                              e.stopPropagation();
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                      </li>
                    );
                  }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={fullWidth ? 12 : 8}
        lg={fullWidth ? 12 : 8}
        xl={fullWidth ? 12 : 8}
        alignSelf={"center"}
      >
        <Box display="flex" gap={0.5}>
          {fields && fields.length > 0 ? (
            fields.map((data, index) => (
              <Controller
                key={data.id}
                name={`${name}.${index}.name`}
                control={control}
                render={({ field }) => (
                  <CustomizedChips
                    {...field}
                    variant="outlined"
                    color="primary"
                    label={field.value}
                    handleDelete={!disabled ? () => remove(index) : null}
                    onClick={
                      onClickHandler
                        ? () => onClickHandler(data.name)
                        : undefined
                    }
                    isRounded
                  />
                )}
              />
            ))
          ) : disabled ? (
            <Typography>-</Typography>
          ) : null}
        </Box>
      </Grid>
      {/* <DeleteTagListConfirmation
        payload={modalDelete}
        openDeleteConfirmation={modalDelete.value}
        comfirmationDeleteAction={comfirmationDeleteAction}
        closeModalHandler={closeModalHandler}
        entityType={entity}
      /> */}
    </>
  );
};

export default TagList;
