import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "types/global";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "components/Custom/CustomizedMenuList";

const QuotationReports = () => {
    const { t } = useTranslation();

    const breadcrumbs: IBreadcrumbsAndMenu[] = [
        {
            name: t("sales.index"),
            to: "/sales",
        },
        {
            name: t("report"),
        },
    ];

    const menuList: IBreadcrumbsAndMenu[] = [
        {
            name: t("sales.quotation.index"),
            to: "quotation",
        },
    ];

    return (
        <>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        <CustomizedMenuList menuList={menuList} />
        </>
    );
};

export default QuotationReports;