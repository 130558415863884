import { gql } from "graphql-request";

export const MAINTENANCE_VIEW = gql`
  query MaintenanceViewAggrid($aggridInput: AnyAggridInput) {
    MaintenanceViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        main_status
        flag_status
        aggrid_status
        vehicle_id
        vehicle_no
        vehicle_license_plate
        trailer_no
        trailer_license_plate
        type
        issue_date
        garage_type
        garage_name
        accident_date
        net_amount
        is_insurance_fully_paid
        created_by
        created_by_object
        driver_list
        driver_object_list
        created_date
        last_updated_date
        ma_start_date
        ma_end_date
        reference_document_unique_id_list
        claim_no
      }
    }
  }
`;

export const DELIVERY_TRIP_FOR_BILLING_NOTE_VIEW = gql`
  query DeliveryTripForBillingNoteViewAggrid($aggridInput: AnyAggridInput) {
    DeliveryTripForBillingNoteViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        issue_date
        customer_unique_id
        customer_name
        vehicle_id
        vehicle_no
        vehicle_license_plate
        vehicle_type
        trailer_license_plate
        income_net_amount
        expenses_net_amount
        main_status
        flag_status
        aggrid_status
        work_list
        income_post_discount_amount
        income_vat_exempted_amount
        income_vat_0_percent_amount
        income_vat_7_percent_amount
        income_vat_amount
        income_net_amount
        income_withholding_tax_amount
        income_total_amount
        remark
        vehicle
        customer_details
        has_billing_note
        reference_document_list
        billing_note_unique_id_list
      }
    }
  }
`;
