import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import ControlledSelect from "components/Controller/ControlledSelect";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import { IItem } from "types/Inventory/item";
import {
  useUnitOptions,
  useCategory1Options,
  useCategory2Options,
  useCategory3Options,
} from "hooks/Inventory/use-inventory-setting-option";
import { useDisable } from "hooks/use-disable";
import LabelInput from "components/UI/LabelInput";

const ItemGeneral = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const { renderOption: renderUnitOption, options: unitOptions } =
    useUnitOptions();
  const { renderOption: renderCate1Option, options: cate1Options } =
    useCategory1Options();
  const { renderOption: renderCate2Option, options: cate2Options } =
    useCategory2Options();
  const { renderOption: renderCate3Option, options: cate3Options } =
    useCategory3Options();

  const {
    control,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<IItem>();

  const type = watch("type");

  return (
    <>
      <Typography fontWeight={600} my={2}>
        {t("inventory.item.category")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {!disabled && (
            <ControlledSelect
              name="cate_1_id"
              control={control}
              label={t("inventory.item.general.cate_1")}
              error={Boolean(errors.cate_1_id)}
              helperText={errors.cate_1_id?.message?.toString()}
              options={cate1Options}
              required
            />
          )}
          {disabled && (
            <LabelInput
              label={t("inventory.item.general.cate_1")}
              value={renderCate1Option(getValues("cate_1_id"))}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {!disabled && (
            <ControlledSelect
              name="cate_2_id"
              control={control}
              label={t("inventory.item.general.cate_2")}
              error={Boolean(errors.cate_2_id)}
              helperText={errors.cate_2_id?.message?.toString()}
              options={cate2Options}
            />
          )}
          {disabled && (
            <LabelInput
              label={t("inventory.item.general.cate_2")}
              value={renderCate2Option(getValues("cate_2_id"))}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {!disabled && (
            <ControlledSelect
              name="cate_3_id"
              control={control}
              label={t("inventory.item.general.cate_3")}
              error={Boolean(errors.cate_3_id)}
              helperText={errors.cate_3_id?.message?.toString()}
              options={cate3Options}
            />
          )}
          {disabled && (
            <LabelInput
              label={t("inventory.item.general.cate_3")}
              value={renderCate3Option(getValues("cate_3_id"))}
            />
          )}
        </Grid>
      </Grid>
      {type === "normal" && (
        <>
          <Typography fontWeight={600} my={2}>
            {t("inventory.item.warehouse.uom.index")}
          </Typography>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledNumberTextField
                name="width"
                label={t("inventory.item.general.width")}
                control={control}
                error={Boolean(errors.width)}
                helperText={errors.width?.message?.toString()}
                fullWidth
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {!disabled && (
                <ControlledSelect
                  name="width_uom_id"
                  control={control}
                  label={t("inventory.item.warehouse.uom.index")}
                  error={Boolean(errors.width_uom_id)}
                  helperText={errors.width_uom_id?.message?.toString()}
                  options={unitOptions}
                />
              )}
              {disabled && (
                <LabelInput
                  label={t("inventory.item.warehouse.uom.index")}
                  value={renderUnitOption(getValues("width_uom_id"))}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1.5} mt={0.5}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledNumberTextField
                name="length"
                label={t("inventory.item.general.length")}
                control={control}
                error={Boolean(errors.length)}
                helperText={errors.length?.message?.toString()}
                fullWidth
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {!disabled && (
                <ControlledSelect
                  name="length_uom_id"
                  control={control}
                  label={t("inventory.item.warehouse.uom.index")}
                  error={Boolean(errors.length_uom_id)}
                  helperText={errors.length_uom_id?.message?.toString()}
                  options={unitOptions}
                />
              )}
              {disabled && (
                <LabelInput
                  label={t("inventory.item.warehouse.uom.index")}
                  value={renderUnitOption(getValues("length_uom_id"))}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1.5} mt={0.5}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledNumberTextField
                name="height"
                label={t("inventory.item.general.height")}
                control={control}
                error={Boolean(errors.height)}
                helperText={errors.height?.message?.toString()}
                fullWidth
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {!disabled && (
                <ControlledSelect
                  name="height_uom_id"
                  control={control}
                  label={t("inventory.item.warehouse.uom.index")}
                  error={Boolean(errors.height_uom_id)}
                  helperText={errors.height_uom_id?.message?.toString()}
                  options={unitOptions}
                />
              )}
              {disabled && (
                <LabelInput
                  label={t("inventory.item.warehouse.uom.index")}
                  value={renderUnitOption(getValues("height_uom_id"))}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1.5} mt={0.5}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledNumberTextField
                name="thick"
                label={t("inventory.item.general.thick")}
                control={control}
                error={Boolean(errors.thick)}
                helperText={errors.thick?.message?.toString()}
                fullWidth
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {!disabled && (
                <ControlledSelect
                  name="thickness_uom_id"
                  control={control}
                  label={t("inventory.item.warehouse.uom.index")}
                  error={Boolean(errors.thickness_uom_id)}
                  helperText={errors.thickness_uom_id?.message?.toString()}
                  options={unitOptions}
                />
              )}
              {disabled && (
                <LabelInput
                  label={t("inventory.item.warehouse.uom.index")}
                  value={renderUnitOption(getValues("thickness_uom_id"))}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ItemGeneral;
