import { Stack } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "./ModalUI";
import ImporterErrorTable from "../../Table/Logistic/Location/ImporterErrorTable";
// import { useSnackbar } from "notistack";
// import { exportAsExcel } from "utils/Exporter";
import { IImporterError } from "types/global";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  data: IImporterError[];
};

const LocationImporterErrorModal = ({ open, closeModalHandler, data }: Props) => {
  // const { enqueueSnackbar } = useSnackbar();

  // const exportErrorHandler = (data: IImporterError[]) => {
  //   const formatDownloadData = data.map((error) => ({
  //     สาเหตุ: error.case,
  //     // รหัสสถานที่: error.unique_id,
  //     ข้อมูล: error.name,
  //     รายละเอียด: error.value,
  //   }));
  //   exportAsExcel(formatDownloadData, "รูปแบบข้อมูลไม่ถูกต้อง");
  //   enqueueSnackbar("ดาวน์โหลดตารางสำเร็จ", {
  //     variant: "success",
  //   });
  // };

  return (
    <ModalUI
      title={"รูปแบบข้อมูลไม่ถูกต้อง"}
      open={open}
      handleClose={closeModalHandler}
      maxWidth="md"
      action={
        <Stack direction={"row"} gap={1}>
          {/* <CustomizedButton
            title={"ดาวน์โหลดตาราง"}
            variant="outlined"
            onClick={() => exportErrorHandler(data)}
          /> */}
          <CustomizedButton
            title={"ปิด"}
            variant="contained"
            onClick={closeModalHandler}
          />
        </Stack>
      }
    >
      <ImporterErrorTable data={data} />

    </ModalUI>
  );
};

export default LocationImporterErrorModal;
