const GanttStyles = () => (
  <style>
    {`
      div {
        font-family: 'Kanit';
      }
      .gantt_tree_icon {
        display: none;
      }
      .today {
        background-color: red;
        width: 2px;
      }
      .gantt_grid_scale {
        background-color: #ECF6FD;
      }
      .gantt_task_scale{
        background-color: #ECF6FD;
      }
      .gantt_grid_scale .gantt_grid_head_cell {
        color: black;
        font-weight: 600;
      }
      .gantt_task .gantt_task_scale .gantt_scale_cell{
        color: black;
        font-weight: 600;
        border-right: none;
      }
      .gantt_grid_data .gantt_cell:nth-child(1) {
        border-right: 1px solid #ccc;
      }
      .gantt_grid_scale .gantt_grid_head_cell:nth-child(1) {
        border-right: 1px solid #ccc;
      }
      .gantt_grid_data .gantt_cell, .gantt_grid_scale .gantt_grid_head_cell {
        width: 150px;  /* Ensure the width matches the column width set in JavaScript */
      }
      .gantt_task_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px;
        box-sizing: border-box;
        line-height: 40px;
      }
      .gantt_bar_task{
        color: white;
        font-size: 20px;
        border: none;
        border-color: transparent;
        border-radius: 4px;
        height: 32px !important; /* Set the task bar height */
        margin-top: 6px;
      }
      .gantt_bar_task.task-status-operating {
        background-color: #184d9e;
      }
      .gantt_bar_task.task-status-accident {
        background-color: #184d9e;
      }
      .gantt_bar_task.task-status-repair {
        background-color: #c46e00;
      }
      .gantt_bar_task.task-status-vacant {
        visibility: hidden;
      }
      .gantt_bar_task.task-status-out-of-service {
        background-color: #bdbdbd;
      }
      .gantt_bar_task.task-delivery-trip {
        background-color: #2167D3;
      }
      .gantt_bar_task.task-ma {
        background-color: #FB8C00;
      }
      .gantt_task_row.row-accident {
        background-color: #FCE8E8 !important;
      }
      .gantt_task_row.row-accident:hover {
        background-color: #FCE8E8 !important;
      }
      .gantt_task_row.row-operating {
        background-color: #ECF6FD !important;
      }
      .gantt_task_row.row-maintenance {
        background-color: #FFFFFF !important;
      }
      .car-name {
        cursor: pointer;
      }
      .car-status {
        border-radius: 4px;
        text-align: center;
        height: 35px;
        line-height: 35px; /* Ensure text is centered vertically */
        margin-top: 6px !important; 
      }
      .status-vacant {
        background-color: #E6F9F0;
        color: #22915A;
      }
      .status-operating {
        background-color: #E8F3FD;
        color: #2167D3;
      }
      .status-repair {
        background-color: #FFF4E5;
        color: #FB8C00;
      }
      .status-incident {
        background-color: #FCE8E8;
        color: #E41B1B;
      }
      .status-stop {
        background-color: #BDBDBD;
        color: #FFFFFF;
      }
      .gantt_grid_data .gantt_row:nth-child(odd):hover,
      .gantt_grid_data .gantt_row:nth-child(even):hover {
        background-color: #F9F9F9 !important;
      }
      .gantt_row.gantt_selected, .gantt_grid_data .gantt_row.odd.gantt_selected {
        background-color: #FFFFFF !important;
      }
      .gantt_task_row.gantt_selected .gantt_task_cell {
        border-right: 1px solid #ebebeb;
      }
    `}
  </style>
);

export default GanttStyles;
