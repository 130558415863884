import { Traceability, TraceEntryType, ItemSkuQtysQuery } from "generated/wms";
import { ITraceEntry } from "types/Inventory";
import { IGoodsIssue } from "types/Inventory/goodsIssue";
import { IUser } from "types/Auth/user";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

export const goodsIssueCreateFormatter = (
  data: IGoodsIssue,
  status?: string
) => {
  const {
    created_date,
    trace_entry_list,
    aggrid_status,
    source_warehouse_id,
    ...otherData
  } = data;

  const new_trace_entry_list: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            id,
            uom_id,
            reference_status,
            source_bin_location,
            all_bin_locations,
            // posted_date,
            ...serial
          }) => ({
            ...serial,
            reference_unique_id: data.unique_id,
            reference_line_item: trace.reference_line_item,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            barcode: serial.barcode,
            uom_id: typeof uom_id === "string" ? parseInt(uom_id) : uom_id,
            scanned_by: data.created_by ?? {},
            destination_scanned_by: {},
          })
        );
        new_trace_entry_list.push(...formatSerialList);
      } else {
        const {
          id,
          serial_list,
          uom_id,
          reference_status,
          source_bin_location,
          all_bin_locations,
          // posted_date,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          reference_unique_id: data.unique_id,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
          uom_id: typeof uom_id === "string" ? parseInt(uom_id) : uom_id,
          scanned_by: data.created_by ?? {},
          destination_scanned_by: {},
        };
        new_trace_entry_list.push(formatTrace);
      }
    } else {
      new_trace_entry_list.push(trace);
    }
  });

  return {
    ...otherData,
    trace_entry_list: new_trace_entry_list,
    main_status: status,
    source_warehouse_id:
      typeof source_warehouse_id === "string"
        ? parseInt(source_warehouse_id)
        : source_warehouse_id,
  };
};

export const goodsIssueTypeValueFormatter = (type: string) => {
  switch (type) {
    case "work_order":
      return "แจ้งซ่อม";
    case "return":
      return "ส่งคืน";
    case "other":
      return "อื่นๆ";
    default:
      return "";
  }
};

const groupBy = (xs: any[], key: string, altKey: string) => {
  return xs.reduce((rv, x) => {
    if (x.traceability === "normal") {
      const groupKey = x[altKey];
      rv[groupKey] = rv[groupKey] || [];
      rv[groupKey].push(x);
    } else {
      const groupKey = x[key];
      rv[groupKey] = rv[groupKey] || [];
      rv[groupKey].push(x);
    }
    return rv;
  }, {});
};

export const goodsIssueUpdateFormatter = (
  data: IGoodsIssue,
  status: string
) => {
  const {
    id,
    source_warehouse,
    trace_entry_list,
    created_date,
    created_by,
    last_updated_date,
    unique_id,
    aggrid_status,
    posted_date,
    source_warehouse_id,
    ...otherData
  } = data;
  const new_trace_entry_list: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            id,
            uom_id,
            created_date,
            source_bin_location,
            destination_bin_location,
            scanned_by,
            destination_scanned_by,
            reference_status,
            all_bin_locations,
            ...serial
          }) => ({
            ...serial,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            posted_qty: trace.posted_qty,
            barcode: serial.barcode,
            uom_id: typeof uom_id === "string" ? parseInt(uom_id) : uom_id,
            scanned_by: scanned_by ? scanned_by : {},
            destination_scanned_by: destination_scanned_by
              ? destination_scanned_by
              : {},
          })
        );
        new_trace_entry_list.push(...formatSerialList);
      } else {
        const {
          id,
          serial_list,
          uom_id,
          source_bin_location,
          destination_bin_location,
          created_date,
          scanned_by,
          destination_scanned_by,
          reference_status,
          all_bin_locations,
          // posted_date,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          uom_id: typeof uom_id === "string" ? parseInt(uom_id) : uom_id,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
          scanned_by: scanned_by ? scanned_by : {},
          destination_scanned_by: destination_scanned_by
            ? destination_scanned_by
            : {},
        };
        new_trace_entry_list.push(formatTrace);
      }
    } else {
      new_trace_entry_list.push(trace);
    }
  });

  return {
    ...otherData,
    trace_entry_list: new_trace_entry_list,
    main_status: status,
    source_warehouse_id:
      typeof source_warehouse_id === "string"
        ? parseInt(source_warehouse_id)
        : source_warehouse_id,
  };
};

export const goodsIssueQueryFormatter = (
  data: IGoodsIssue,
  allSkuQtys?: ItemSkuQtysQuery
) => {
  const { trace_entry_list, ...otherData } = data;
  const groupedTrace = groupBy(trace_entry_list, "item_unique_id", "unique_id");

  const allTraceEntries: ITraceEntry[] = [];

  for (const entry of Object.entries(groupedTrace)) {
    const key = entry[0] as string;
    const value = entry[1] as ITraceEntry[];

    const foundAllSku =
      allSkuQtys && allSkuQtys.ItemSkuQtys && allSkuQtys.ItemSkuQtys.length > 0
        ? allSkuQtys.ItemSkuQtys.filter(
            (sku) => sku?.barcode === value[0].barcode
          )
        : [];

    const allBinLocation = foundAllSku.map((itemSku) => ({
      id: itemSku?.bin_location_id,
      name: itemSku?.bin_name,
      stock_qty: itemSku?.stock_qty,
      warehouse_id: itemSku?.warehouse_id,
    }));

    const filterBinLocations = allBinLocation.filter(
      (bin) => bin.warehouse_id === otherData.source_warehouse_id
    );

    const foundBinLocation = filterBinLocations.find(
      (bin) => bin.id === value[0].source_bin_location_id
    );

    let formatSerial = [];

    formatSerial = value.map((serial) => ({
      ...serial,
      scanned_by: serial.scanned_by ?? undefined,
      all_bin_locations: filterBinLocations,
      stock_qty: foundBinLocation?.stock_qty,
    }));

    const formatTrace = {
      item_unique_id: key,
      po_reference_unique_id: value[0].po_reference_unique_id,
      item_name: value[0].item_name,
      seller_sku: value[0].seller_sku,
      item_img_url: value[0].item_img_url,
      traceability: value[0].traceability,
      type: value[0].type,
      unique_id: value[0].unique_id,
      qty: value.reduce((prev, curr) => prev + curr.qty, 0),
      posted_qty: value[0].posted_qty,
      document_item_qty: value[0].document_item_qty,
      scanned_date:
        value[0].traceability === Traceability.Normal
          ? value[0].scanned_date
          : undefined,
      created_date: value[0].created_date,
      uom_id: value[0].uom_id,
      uom: value[0].uom,
      source_bin_location:
        value[0].traceability === Traceability.Normal
          ? value[0].source_bin_location
          : undefined,
      source_bin_location_id:
        value[0].traceability === Traceability.Normal
          ? value[0].source_bin_location_id
          : undefined,
      barcode:
        value[0].traceability === Traceability.Normal
          ? value[0].barcode
          : undefined,
      serial_list: value.some(
        (serial) => serial.traceability === Traceability.Normal
      )
        ? undefined
        : formatSerial,
      status:
        value[0].traceability === Traceability.Normal
          ? value[0].status
          : undefined,
      scanned_by:
        value[0].traceability === Traceability.Normal
          ? value[0].scanned_by ?? undefined
          : undefined,
      remark: value[0].remark ?? undefined,
      reference_unique_id: value[0].reference_unique_id,
      reference_line_item: value[0].reference_line_item,
      lot_date: value[0].lot_date ? dayjs(value[0].lot_date) : null,
      all_bin_locations: filterBinLocations,
      stock_qty:
        value[0].traceability === Traceability.Normal
          ? foundBinLocation?.stock_qty
          : formatSerial?.reduce<number>(
              (prev, curr) => prev + (curr.stock_qty ?? 0),
              0
            ),
    } as ITraceEntry;
    allTraceEntries.push(formatTrace);
  }

  return {
    ...otherData,
    trace_entry_list: allTraceEntries,
    created_date: dayjs(otherData.created_date),
    posted_date: otherData.posted_date ? dayjs(otherData.posted_date) : null,
  };
};
export const goodsIssueTraceEntryFormatter = (
  trace: ITraceEntry,
  binLocations: any[],
  user: IUser | null
) => {
  const {
    type,
    // posted_date,
    created_date,
    scanned_by,
    unique_id,
    ...otherPayload
  } = trace;

  if (user) {
    const formatTrace: ITraceEntry = {
      ...otherPayload,
      qty: 0,
      source_bin_location: binLocations[0],
      source_bin_location_id: binLocations[0].id,
      all_bin_locations: binLocations,
      unique_id: uuidv4(),
      type: TraceEntryType.GoodIssue,
      scanned_by: {
        user_unique_id: user.unique_id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        img_url: user.img_url,
      },
      // posted_date: dayjs().toDate(),
      scanned_date: dayjs().toDate(),
      stock_qty: binLocations[0].stock_qty,
      status:
        trace.traceability === Traceability.Normal
          ? "is_scanned"
          : trace.status,
    };
    return formatTrace;
  }
  return;
};
