import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import ControlledTextField from "../../Controller/ControlledTextField";
import { IContact } from "../../../types/Contact/contact";
import { useTranslation } from "react-i18next";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useDisable } from "../../../hooks/use-disable";
import DropzoneUI from "../../UI/DropzoneUI";
import ControlledCheckbox from "../../Controller/ControlledCheckbox";
import LabelInput from "../../UI/LabelInput";
import {
  businessTypeFormatter,
  companyTypeFormatter,
} from "../../../utils/Formatter/Global";
import ControlledSelect from "../../Controller/ControlledSelect";
import { ISelectOption } from "../../../types/global";
import ControlledNumberTextField from "../../Controller/ControlledNumberTextField";
import ContactChannel from "./ContactChannel";
import ControlledRadioGroup from "../../Controller/ControlledRadioGroup";
import {
  ContactModelType,
  CrmUniqueIdGenerateQuery,
  useCrmUniqueIdGenerateQuery,
} from "../../../generated/crm";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import TagList from "../../UI/TagList";
import { sourceFormatter } from "../../../utils/Formatter/Contact";

const ContactInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<IContact>();
  const [disabled] = useDisable();

  const watchBusinessType = watch("business_type");
  const watchStatus = watch("status");
  const graphqlClientCrm = createGraphQLClientWithMiddleware("crm");

  const { refetch: refetchUniqueId } =
    useCrmUniqueIdGenerateQuery<CrmUniqueIdGenerateQuery>(
      graphqlClientCrm,
      {
        modelType: ContactModelType.Contact,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate || "");
  }, [refetchUniqueId, setValue]);

  const busnessTypeOptions: ISelectOption[] = [
    {
      label: t("contact.info.corporate.index"),
      value: "corporate",
    },
    {
      label: t("contact.info.individual.index"),
      value: "individual",
    },
  ];

  const corporateTypes: ISelectOption[] = useMemo(
    () => [
      {
        id: 1,
        label: t("contact.info.corporate.company"),
        value: "company",
      },
      {
        id: 2,
        label: t("contact.info.corporate.public_limited"),
        value: "public_limited",
      },
      {
        id: 3,
        label: t("contact.info.corporate.limited_partnership"),
        value: "limited_partnership",
      },
      {
        id: 4,
        label: t("contact.info.corporate.juristic_partnership"),
        value: "registered_ordinary_partnership",
      },
      {
        id: 5,
        label: t("contact.info.corporate.association"),
        value: "association",
      },
      { id: 6, label: t("contact.info.corporate.other"), value: "other" },
    ],
    [t]
  );

  const individualTypes: ISelectOption[] = useMemo(
    () => [
      {
        id: 1,
        label: t("contact.info.individual.index"),
        value: "individual",
      },
      {
        id: 2,
        label: t("contact.info.individual.ordinary_partnership"),
        value: "ordinary_partnership",
      },
      {
        id: 3,
        label: t("contact.info.individual.store"),
        value: "store",
      },
      {
        id: 4,
        label: t("contact.info.individual.entities"),
        value: "entities",
      },
    ],
    [t]
  );

  const sourceOptions: ISelectOption[] = useMemo(
    () => [
      {
        label: t("contact.sales_person.index"),
        value: "sales_person",
      },
      {
        label: "Facebook",
        value: "facebook",
      },
      {
        label: "Instagram",
        value: "instagram",
      },
      {
        label: "Website",
        value: "website",
      },
      {
        label: "Email",
        value: "email",
      },
      {
        label: "Line",
        value: "line",
      },
      {
        label: "เบอร์โทรศัพท์",
        value: "phone",
      },
      {
        label: "Market Place",
        value: "market_place",
      },
      {
        label: "อื่นๆ",
        value: "other",
      },
    ],
    [t]
  );

  const prefixes = [
    {
      id: 1,
      label: t("contact.contact_person.prefix.mr"),
      value: t("contact.contact_person.prefix.mr"),
    },
    {
      id: 2,
      label: t("contact.contact_person.prefix.mrs"),
      value: t("contact.contact_person.prefix.mrs"),
    },
    {
      id: 3,
      label: t("contact.contact_person.prefix.ms"),
      value: t("contact.contact_person.prefix.ms"),
    },

    {
      id: 4,
      label: t("contact.contact_person.prefix.sir"),
      value: t("contact.contact_person.prefix.sir"),
    },
    {
      id: 5,
      label: t("contact.contact_person.prefix.none"),
      value: t("contact.contact_person.prefix.none"),
    },
  ];

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const hasContactError =
    Object.keys(errors)?.filter((errorType) =>
      ["is_customer", "is_supplier"].includes(errorType)
    )?.length > 1;

  return (
    <CustomizedBox>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={8}>
          <Grid container spacing={1.5}>
            <Grid item xs={11} sm={11} md={6}>
              <ControlledTextField
                control={control}
                name="unique_id"
                label={t("contact.unique_id")}
                error={Boolean(errors.unique_id)}
                helperText={errors.unique_id?.message}
                viewMode={disabled}
                disabled={id ? true : false}
                required
              />
            </Grid>
            <Grid item xs={1} sm={1} md={6}>
              {!disabled && !id && (
                <CustomizedTooltip
                  title={`เรียก${t("contact.unique_id")}`}
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateUniqueId}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box display={"flex"} gap={0.5}>
                <Typography fontWeight={600}>
                  {t("contact.contact_type.index")}
                </Typography>
                {!disabled && (
                  <Typography
                    fontSize={14}
                    color={"error.main"}
                    fontWeight={600}
                  >
                    *
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ControlledCheckbox
                control={control}
                name="is_customer"
                label={t("contact.contact_type.customer")}
                disabled={disabled}
                error={hasContactError}
              />
              <ControlledCheckbox
                control={control}
                name="is_supplier"
                label={t("contact.contact_type.supplier")}
                disabled={disabled}
                error={hasContactError}
              />
              {hasContactError && (
                <Box sx={{ ml: 1 }}>
                  <Typography sx={{ color: "error.main" }} variant="caption">
                    กรุณาเลือก
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <DropzoneUI
            control={control}
            name={"img_url"}
            setValue={setValue}
            acceptedFileType="image"
            maxSize={1}
            disabled={disabled}
            width={100}
            height={100}
          />
        </Grid>
      </Grid>
      <Typography fontWeight={600} mt={1} mb={2}>
        {t("contact.info.index")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("contact.info.business_type")}
              value={businessTypeFormatter(getValues("business_type"))}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="business_type"
              label={t("contact.info.business_type")}
              options={busnessTypeOptions}
              error={Boolean(errors.business_type)}
              helperText={errors.business_type?.message?.toString()}
              onChange={() => {
                setValue("business_type_detail", "");
                setValue("title_name", "");
                setValue("name", "");
                setValue("last_name", "");
                setValue("nick_name", "");
                setValue("taxpayer_id", "");
                setValue("registered_capital", 0);
                setValue("source", "");
              }}
              required
            />
          )}
        </Grid>
        {watchBusinessType === "corporate" ? (
          <>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {disabled ? (
                <LabelInput
                  label={t(`contact.info.${watchBusinessType}.index`)}
                  value={companyTypeFormatter(
                    getValues("business_type_detail")
                  )}
                />
              ) : (
                <ControlledSelect
                  name="business_type_detail"
                  control={control}
                  label={t(`contact.info.${watchBusinessType}.index`)}
                  error={Boolean(errors.business_type_detail)}
                  helperText={errors.business_type_detail?.message?.toString()}
                  options={corporateTypes}
                  required
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                control={control}
                name="name"
                label={t("contact.info.brand_name")}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                control={control}
                name="taxpayer_id"
                label={t("contact.info.taxpayer_id")}
                error={Boolean(errors.taxpayer_id)}
                helperText={errors.taxpayer_id?.message}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledNumberTextField
                fullWidth
                textAlign="right"
                control={control}
                name="registered_capital"
                label={t("contact.info.registered_capital")}
                error={Boolean(errors.registered_capital)}
                helperText={errors.registered_capital?.message}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {disabled ? (
                <LabelInput
                  label={t(`contact.info.source`)}
                  value={sourceFormatter(getValues("source"))}
                />
              ) : (
                <ControlledSelect
                  name="source"
                  control={control}
                  label={t(`contact.info.source`)}
                  error={Boolean(errors.source)}
                  helperText={errors.source?.message?.toString()}
                  options={sourceOptions}
                />
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {disabled ? (
                <LabelInput
                  label={t(`contact.info.${watchBusinessType}.index`)}
                  value={companyTypeFormatter(
                    getValues("business_type_detail")
                  )}
                />
              ) : (
                <ControlledSelect
                  name="business_type_detail"
                  control={control}
                  label={t(`contact.info.${watchBusinessType}.index`)}
                  error={Boolean(errors.business_type_detail)}
                  helperText={errors.business_type_detail?.message?.toString()}
                  options={individualTypes}
                  required
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                control={control}
                name="taxpayer_id"
                label={t("contact.info.taxpayer_id")}
                error={Boolean(errors.taxpayer_id)}
                helperText={errors.taxpayer_id?.message}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {disabled ? (
                <LabelInput
                  label={t(`contact.info.title_name`)}
                  value={getValues("title_name")}
                />
              ) : (
                <ControlledSelect
                  name="title_name"
                  control={control}
                  label={t(`contact.info.title_name`)}
                  error={Boolean(errors.title_name)}
                  helperText={errors.title_name?.message?.toString()}
                  options={prefixes}
                  required
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                control={control}
                name="name"
                label={t("contact.info.first_name")}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                control={control}
                name="last_name"
                label={t("contact.info.last_name")}
                error={Boolean(errors.last_name)}
                helperText={errors.last_name?.message}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {disabled ? (
                <LabelInput
                  label={t(`contact.info.source`)}
                  value={sourceFormatter(getValues("source"))}
                />
              ) : (
                <ControlledSelect
                  name="source"
                  control={control}
                  label={t(`contact.info.source`)}
                  error={Boolean(errors.source)}
                  helperText={errors.source?.message?.toString()}
                  options={sourceOptions}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
      <ContactChannel />
      <Typography mt={2} fontWeight={600}>
        {t("status.index")}
      </Typography>
      <ControlledRadioGroup
        control={control}
        name="status"
        disabled={disabled}
        row
        radioLists={[
          {
            label: "ใช้งาน",
            value: 1,
          },
          {
            label: "หยุดใช้งาน",
            value: 0,
          },
        ]}
      />
      {(typeof watchStatus === "string"
        ? parseInt(watchStatus)
        : watchStatus) === 0 && (
        <Grid container spacing={1.5} mt={0}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <ControlledTextField
              label={t("contact.remark_status")}
              control={control}
              name="remark_status"
              error={Boolean(errors.remark_status)}
              helperText={errors.remark_status?.message}
              required
              viewMode={disabled}
            />
          </Grid>
        </Grid>
      )}
      <Typography fontWeight={600} mt={1} mb={2}>
        {t("contact.tag_list")}
      </Typography>
      <Grid container spacing={1.5}>
        <TagList
          name="tag_list"
          label={t("contact.tag_list")}
          documentType={"contact"}
        />
      </Grid>
    </CustomizedBox>
  );
};

export default ContactInfo;
