import { Box, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import { numberFormatter } from "utils/Formatter/Global";

const PostDiscountAmount = () => {
  const { control } = useFormContext<IDeliveryTrip>();
  const expensesPostDiscountAmount = useWatch({
    control,
    name: "expenses_post_discount_amount",
  });

  return (
    <Box
      sx={{
        my: 1,
        display: "flex",
        alignItems: "center",
        gap: 3,
        height: "2rem",
      }}
      bgcolor={"secondary.main"}
      maxWidth={461}
      marginLeft="auto"
      justifyItems="flex-end"
      borderRadius={1}
    >
      <>
        <Typography
          color={"primary.main"}
          textAlign={"right"}
          flex={2}
          minWidth={50}
        >
          รวม
        </Typography>
        <Typography
          color={"primary.main"}
          variant="subtitle1"
          sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
        >
          {numberFormatter(expensesPostDiscountAmount)}
        </Typography>
      </>
      <Typography color={"primary.main"} sx={{ ml: 2.5, mr: 4 }}>
        บาท
      </Typography>
    </Box>
  );
};

export default PostDiscountAmount;
