import { Box, Grid, Typography } from "@mui/material";
import { formatDateTime } from "utils/Date";

type Props = {
  data?: any;
};

export const DailyWorkSheetVehicleInfo = ({ data }: Props) => {
  const fontSize = 10.66;
  return (
    <>
      <Grid container alignItems={"center"} marginY={1.8}>
        <Grid item xs={4.4}>
          <Box
            sx={{
              width: "100%",
              height: "141.75px",
              border: "0.5px solid #BDBDBD",
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              padding: "10px 8px",
            }}
          >
            <Grid container spacing={0.5}>
              <Grid item xs={5}>
                <Typography fontSize={fontSize} fontWeight={600}>
                  รหัสรถ
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography fontSize={fontSize} marginLeft={1}>
                  {data.vehicle.vehicle_no ? data.vehicle.vehicle_no : "-"}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography fontSize={fontSize} fontWeight={600}>
                  ทะเบียนรถ
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography fontSize={fontSize} marginLeft={1}>
                  {data.vehicle.license_plate
                    ? data.vehicle.license_plate
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography fontSize={fontSize} fontWeight={600}>
                  ทะเบียนหาง
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography fontSize={fontSize} marginLeft={1}>
                  {data.trailer_license_plate
                    ? data.trailer_license_plate
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography fontSize={fontSize} fontWeight={600}>
                  พนักงานขนส่ง
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography fontSize={fontSize} marginLeft={1}>
                  {data.primary_driver.name ? data.primary_driver.name : "-"}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography fontSize={fontSize} fontWeight={600}>
                  พนักงานขนส่ง 2
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography fontSize={fontSize} marginLeft={1}>
                  {data.secondary_driver.name
                    ? data.secondary_driver.name
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography fontSize={fontSize} fontWeight={600}>
                  อุณหภูมิ
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography fontSize={fontSize} marginLeft={1}>
                  {data.expected_temperature ? data.expected_temperature : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={3.8} height={"100%"}>
          <Box
            sx={{
              width: "100%",
              height: "141.75px",
              borderTop: "0.5px solid #BDBDBD",
              borderBottom: "0.5px solid #BDBDBD",
              padding: "10px 8px",
            }}
          >
            <Grid container spacing={1.6}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={7}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      วันที่เวลาเริ่มต้น (แผน)
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={fontSize}>
                      {data.plan_start_date
                        ? formatDateTime(data.plan_start_date)
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={7}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      วันที่เวลาเริ่มต้น (จริง)
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #BDBDBD",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={7}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      ชั่วโมงทำอุณหภูมิเริ่มต้น
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #BDBDBD",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={7}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      เลขกิโลเริ่มต้น (OUT)
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={fontSize}>
                      {data.start_kilometer ? data.start_kilometer : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={3.8} sx={{ height: "100%" }}>
          <Box
            sx={{
              width: "100%",
              height: "141.75px",
              border: "0.5px solid #BDBDBD",
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              padding: "10px 8px",
            }}
          >
            <Grid container spacing={1.6}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={7}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      วันที่เวลาสิ้นสุด (แผน)
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={fontSize}>
                      {data.plan_end_date
                        ? formatDateTime(data.plan_end_date)
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={7}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      วันที่เวลาสิ้นสุด (จริง)
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #BDBDBD",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={7}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      ชั่วโมงทำอุณหภูมิสิ้นสุด
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #BDBDBD",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={7}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      เลขกิโลสิ้นสุด (IN)
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #BDBDBD",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
