import { Box, CircularProgress, Typography } from "@mui/material";
import CustomizedButton from "components/Custom/CustomizedButton";
import PurchaseOrderListPdfTable from "components/Table/Purchase/Order/PDF";
import PDFLayout from "components/UI/PDF/Layout";
import {
  PurchaseOrderFindUniqueQuery,
  usePurchaseOrderFindUniqueQuery,
} from "generated/purchase";
import { usePDFMultiplePage } from "hooks/use-pdf-multiple-page";
import { ReactInstance, useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IPdfInfo } from "types/global";
import { IPurchaseItemList } from "types/Purchase";
import { formatDate } from "utils/Date";
import { renderAddressString } from "utils/Formatter/Global";

const PurchaseOrderPDF = () => {
  const { id } = useParams();
  const componentRef = useRef<ReactInstance | null>(null);

  const graphQLClient = createGraphQLClientWithMiddleware("purchase");
  const { data, isLoading } =
    usePurchaseOrderFindUniqueQuery<PurchaseOrderFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      }
    );

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.PurchaseOrderFindUnique, //data
    "purchase_order", // document type
    139.66, // name and description row width
    640, // max height without footer
    495, // max height with footer
    224.875, // left header minimum height
    247.797, // right header minimum height
    37.313, // left footer minimum height (you don't need to specify this if the document doesn't have remark and tags)
    87.963 // right footer minimum height (you don't need to specify this if the document doesn't have summary)
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const highlightSection: IPdfInfo = {
    headers: [
      "เลขที่เอกสาร",
      "วันที่ออกเอกสาร",
      "ต้องการภายในวันที่",
      "วันประมาณการณ์สินค้า",
      "วันครบกำหนดชำระ",
      "เครดิต",
    ],
    info: [
      data?.PurchaseOrderFindUnique.unique_id || "-",
      data?.PurchaseOrderFindUnique.issue_date
        ? formatDate(data?.PurchaseOrderFindUnique.issue_date)
        : "-",
      data?.PurchaseOrderFindUnique.within_date
        ? formatDate(data?.PurchaseOrderFindUnique.expected_date)
        : "-",
      data?.PurchaseOrderFindUnique.expected_date
        ? formatDate(data?.PurchaseOrderFindUnique.expected_date)
        : "-",
      data?.PurchaseOrderFindUnique.due_date
        ? formatDate(data?.PurchaseOrderFindUnique.due_date)
        : "-",
      data?.PurchaseOrderFindUnique.credit_day
        ? data.PurchaseOrderFindUnique.credit_day + " วัน"
        : "-",
    ],
  };

  const leftSection: IPdfInfo = {
    headers: [
      "ซัพพลายเออร์",
      "ที่อยู่",
      "เลขประจำตัวผู้เสียภาษี",
      "เบอร์โทรศัพท์@Fax",
      "Email",
      "ที่อยู่จัดส่งผู้รับ",
    ],
    info: [
      `${data?.PurchaseOrderFindUnique.supplier_unique_id} - ${data?.PurchaseOrderFindUnique.supplier_details.name}`,
      renderAddressString(
        data?.PurchaseOrderFindUnique.supplier_details.billing_address
      ) || "-",
      data?.PurchaseOrderFindUnique.supplier_details.identity_no || "-",
      data?.PurchaseOrderFindUnique.supplier_details.phone +
        "@" +
        data?.PurchaseOrderFindUnique.supplier_details.fax || "-",
      data?.PurchaseOrderFindUnique.supplier_details.email || "-",
      renderAddressString(
        data?.PurchaseOrderFindUnique.supplier_details.delivery_address
      ),
    ],
  };

  const rightSection: IPdfInfo = {
    headers: ["คลังปลายทาง", "อ้างอิงถึง", "หมายเลขอ้างอิง"],
    info: [
      data?.PurchaseOrderFindUnique.destination_warehouse_id
        ? `${data?.PurchaseOrderFindUnique.destination_warehouse?.unique_id} - ${data?.PurchaseOrderFindUnique.destination_warehouse?.name}`
        : "-",
      "-",
      data?.PurchaseOrderFindUnique.reference_no || "-",
    ],
  };

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mx: "auto" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">ใบสั่งซื้อ</Typography>
        <CustomizedButton
          title="พิมพ์เอกสาร"
          variant="outlined"
          onClick={handlePrint}
        />
      </Box>
      <Box ref={componentRef} sx={{ m: "auto" }}>
        {paginatedItems.map((item, index) => (
          <PDFLayout
            highlightSection={highlightSection}
            leftSection={leftSection}
            rightSection={rightSection}
            name={"ใบสั่งซื้อ"}
            data={data?.PurchaseOrderFindUnique}
            summary={index === paginatedItems.length - 1}
            multiplePage={paginatedItems.length > 1}
            page={index + 1}
            allPages={paginatedItems.length}
            documentType="purchase_order"
          >
            <PurchaseOrderListPdfTable
              itemList={(item as IPurchaseItemList[]) || []}
              startNumber={startNumbers[index]}
              lastTable={
                paginatedItems[paginatedItems.length - 1].length === 0
                  ? index === paginatedItems.length - 2
                  : index === paginatedItems.length - 1
              }
              totalAmount={
                data?.PurchaseOrderFindUnique.total_amount
                  ? data?.PurchaseOrderFindUnique.total_amount
                  : 0
              }
            />
          </PDFLayout>
        ))}
      </Box>
    </Box>
  );
};

export default PurchaseOrderPDF;
