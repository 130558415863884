import { GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "components/UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useRef } from "react";
import { LOCATION_VIEW } from "services/AgGrid/LocationAgGrid";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { useLocationColumnDefs } from "./columnDefs";

interface Props {
  setSelectionRow: (row: any) => void;
  isFilter: string | null;
  refreshTrigger: any;
}

const LocationTable = ({
  setSelectionRow,
  isFilter,
  refreshTrigger,
}: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const columnDef = useLocationColumnDefs();

  const graphQLClientWithHeader: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const createDatasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { status, ...otherFilterModel } = filterModel;
      const formatFilter = {
        status: status && {
          ...status,
          values: status.values.map((v: string) => parseInt(v)),
        },
        ...otherFilterModel,
      };

      const sort =
        sortModel.length > 0
          ? sortModel
          : {
              colId: "created_date",
              sort: "desc",
            };

      try {
        const { LocationViewAggrid } =
          await graphQLClientWithHeader.request(LOCATION_VIEW, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel: sort,
            },
          });

          const responseData = LocationViewAggrid.results.map((result: any) => {
            const formatNumber = (value: number | null | undefined) => {
              if (value === null || value === undefined) return "-";
              return value >= 1000
                ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                : value.toFixed(2);
            };
          
            return {
              ...result,
              status: result.status === 1 ? "active" : "inactive",
              secondary_driver_cost: formatNumber(result.secondary_driver_cost),
              drop_cost: formatNumber(result.drop_cost),
              contact_phone: result.contact_phone ? result.contact_phone : "-"
            };
          });          

        params.success({
          rowData: responseData as any[],
          rowCount: LocationViewAggrid.count as number,
        });
      } catch (err) {
        console.error("Failed to fetch locations:", err);
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instanceStatus = params.api.getFilterInstance("status");
      switch (isFilter) {
        case "in_active":
          instanceStatus?.setModel({ values: ["0"] });
          break;
        case "active":
          instanceStatus?.setModel({ values: ["1"] });
          break;
        default:
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(createDatasource);
    onFilterChanged(params);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged, refreshTrigger]);

  const handleFinishSelecting = (event: any) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectionRow(selectedRows[0]);
  };
  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onGridReady={onGridReady}
      rowSelection="single"
      suppressRowClickSelection={true}
      onRowSelected={handleFinishSelecting}
    />
  );
};

export default LocationTable;
