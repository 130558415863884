import {
  IBankAccountDetail,
  IContact,
  IContactPerson,
} from "../../../types/Contact/contact";
import * as Yup from "yup";
import { IContactChannel } from "../../../types/global";

export const contactChannelSchema: IContactChannel = {
  contact_channel_type: "",
  contact_channel_info: "",
};

export const contactSchema: IContact = {
  unique_id: "",
  is_customer: false,
  is_supplier: false,
  img_url: [],
  business_type: "corporate",
  business_type_detail: "",
  title_name: "",
  name: "",
  last_name: "",
  nick_name: "",
  taxpayer_id: "",
  registered_capital: undefined,
  source: "",
  contact_channel_list: [contactChannelSchema],
  status: 1,
  remark_status: "",
  tag_list: [],
  address_list: [
    {
      address_type: "ที่อยู่จดทะเบียน",
      name: "",
      district: "",
      is_default: true,
      postal_code: "",
      province: "",
      sub_district: "",
      address_contact_name: "",
      address_contact_phone: "",
      address_contact_fax: "",
      country: "ไทย",
    },
  ],
  contact_person_list: [],
  is_cheque: false,
  is_transfer: false,
  is_cash: false,
  payment_duration: undefined,
  payment_contact_person: "",
  payment_contact_phone: "",
  billing_note_duration: undefined,
  billing_note_contact_person: "",
  billing_note_contact_phone: "",
  bank_account_detail_list: [],
  credit_request: undefined,
  credit_request_duration: undefined,
  account_creditor: "",
  account_debtor: "",
  required_purchase_item_type: [],
  purchase_amount_per_month: undefined,
  attachment_list: [],
  sales_person_list: [],
  remark: "",
};

export const contactPersonSchema: IContactPerson = {
  position: "",
  title_name: "",
  first_name: "",
  last_name: "",
  nick_name: "",
  img_url: [],
  contact_channel_list: [contactChannelSchema],
  remark: "",
};

export const bankAccountSchema: IBankAccountDetail = {
  account_number: "",
  branch: "",
  description: "",
  name: "",
  provider: "",
  type: "",
};

const contactChannelListValidation = Yup.object().shape({
  contact_channel_type: Yup.string().nullable(),
  contact_channel_info: Yup.string()
    .max(100, "กรอกได้ไม่เกิน 100 ตัวอักษร")
    .nullable(),
});

const addressValidation = Yup.object().shape({
  name: Yup.string().max(300, "กรอกได้ไม่เกิน 300 ตัวอักษร"),
  postal_code: Yup.string().max(10, "กรอกได้ไม่เกิน 10 ตัวอักษร"),
  address_contact_name: Yup.string().max(100, "กรอกได้ไม่เกิน 100 ตัวอักษร"),
  address_contact_phone: Yup.string().max(20, "กรอกได้ไม่เกิน 20 ตัวอักษร"),
});

export const contactPersonValidation = Yup.object().shape({
  title_name: Yup.string().required("กรุณาระบุ"),
  first_name: Yup.string()
    .required("กรุณาระบุ")
    .max(50, "กรอกได้ไม่เกิน 50 ตัวอักษร"),
  last_name: Yup.string()
    .required("กรุณาระบุ")
    .max(50, "กรอกได้ไม่เกิน 50 ตัวอักษร"),
  nick_name: Yup.string().max(50, "กรอกได้ไม่เกิน 50 ตัวอักษร"),
  contact_channel_list: Yup.array().of(contactChannelListValidation),
});

export const bankAccountValidation = Yup.object().shape({
  provider: Yup.string().required("กรุณาระบุ"),
  name: Yup.string()
    .required("กรุณาระบุ")
    .max(100, "กรอกได้ไม่เกิน 100 ตัวอักษร"),
  type: Yup.string().required("กรุณาระบุ"),
  account_number: Yup.string()
    .required("กรุณาระบุ")
    .max(20, "กรอกได้ไม่เกิน 20 ตัวอักษร"),
});

export const contactValidation = Yup.object().shape(
  {
    unique_id: Yup.string().required("กรุณาระบุ"),
    is_customer: Yup.boolean().when("is_supplier", {
      is: true,
      then: (schema) => schema,
      otherwise: (schema) =>
        schema.required("กรุณาเลือก").oneOf([true], "กรุณาเลือก"),
    }),
    is_supplier: Yup.boolean().when("is_customer", {
      is: true,
      then: (schema) => schema,
      otherwise: (schema) =>
        schema.required("กรุณาเลือก").oneOf([true], "กรุณาเลือก"),
    }),
    business_type_detail: Yup.string().required("กรุณาระบุ"),
    title_name: Yup.string().when("business_type", {
      is: "individual",
      then: (schema) => schema.required("กรุณาระบุ"),
    }),
    name: Yup.string().when("business_type", {
      is: "corporate",
      then: (schema) =>
        schema.required("กรุณาระบุ").max(100, "กรอกได้ไม่เกิน 100 ตัวอักษร"),
      otherwise: (schema) =>
        schema.required("กรุณาระบุ").max(50, "กรอกได้ไม่เกิน 50 ตัวอักษร"),
    }),
    last_name: Yup.string().when("business_type", {
      is: "individual",
      then: (schema) =>
        schema.required("กรุณาระบุ").max(50, "กรอกได้ไม่เกิน 50 ตัวอักษร"),
    }),
    taxpayer_id: Yup.string().max(15, "กรอกได้ไม่เกิน 15 ตัวอักษร"),
    registered_capital: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .max(999999999999999, "กรอกได้ไม่เกิน 15 หลัก"),
    remark_status: Yup.string()
      .when("status", {
        is: (value: any) => value === 1 || value === "1",
        then: (schema) => schema,
        otherwise: (schema) => schema.required("กรุณาระบุ"),
      })
      .max(100, "กรอกได้ไม่เกิน 100 ตัวอักษร"),
    contact_channel_list: Yup.array().of(contactChannelListValidation),
    address_list: Yup.array().of(addressValidation),
    contact_person_list: Yup.array().of(contactPersonValidation),
    payment_duration: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .max(9999, "กรอกได้ไม่เกิน 4 หลัก"),
    payment_contact_person: Yup.string().max(
      100,
      "กรอกได้ไม่เกิน 100 ตัวอักษร"
    ),
    payment_contact_phone: Yup.string().max(20, "กรอกได้ไม่เกิน 20 ตัวอักษร"),
    billing_note_duration: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .max(9999, "กรอกได้ไม่เกิน 4 หลัก"),
    billing_note_contact_person: Yup.string().max(
      100,
      "กรอกได้ไม่เกิน 100 ตัวอักษร"
    ),
    billing_note_contact_phone: Yup.string().max(
      20,
      "กรอกได้ไม่เกิน 20 ตัวอักษร"
    ),
    credit_request: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .max(9999999999, "กรอกได้ไม่เกิน 10 หลัก"),
    credit_request_duration: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .max(9999, "กรอกได้ไม่เกิน 4 หลัก"),
    account_debtor: Yup.string().max(50, "กรอกได้ไม่เกิน 50 ตัวอักษร"),
    account_creditor: Yup.string().max(50, "กรอกได้ไม่เกิน 50 ตัวอักษร"),
    purchase_amount_per_month: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .max(9999999999, "กรอกได้ไม่เกิน 10 หลัก"),
    remark: Yup.string().max(1000, "กรอกได้ไม่เกิน 1000 ตัวอักษร"),
  },
  [["is_customer", "is_supplier"]]
);
