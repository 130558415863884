import { useVehicleColumnDefs } from "components/Table/Logistic/Vehicle/columnDefs";
import { GraphQLClient } from "graphql-request";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import CheckboxAggridModal from "../CheckboxAggridModal";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { VEHICLE_VIEW } from "services/AgGrid/VehicleAgGrid";

type CheckedVehicleModalProps = {
  showSelectVehicle: boolean;
  closeVehicleTable: () => void;
  finishVehiclesSelect: (data: any) => void;
  vehicleIds: string[];
  setVehicleIds: Dispatch<SetStateAction<string[]>>;
  vehicleIdsSnapshot: string[];
  setVehicleIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowSelection?: "single" | "multiple";
  disableTrailer?: boolean;
};

const CheckedVehicleModal = ({
  showSelectVehicle,
  closeVehicleTable,
  finishVehiclesSelect,
  vehicleIds,
  setVehicleIds,
  vehicleIdsSnapshot,
  setVehicleIdsSnapshot,
  rowSelection,
  disableTrailer,
}: CheckedVehicleModalProps) => {
  const { t } = useTranslation();
  const gridRef = useRef();

  const columnDefs = useVehicleColumnDefs(true, false);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        driver_object_list,
        created_by_object,
        created_date,
        type,
        ...otherFilter
      } = filterModel;

      let typeFilter;

      if (disableTrailer) {
        if (type) {
          typeFilter = {
            filterType: "set",
            values: type.values.filter(
              (vehicleType: string) => vehicleType !== "trailer"
            ),
          };
        } else {
          typeFilter = {
            filterType: "set",
            values: ["four_wheel", "six_wheel", "ten_wheel", "head"],
          };
        }
      } else {
        typeFilter = type;
      }

      const formatFilter = {
        ...otherFilter,
        driver: driver_object_list && {
          filterType: "array",
          type: "hasSome",
          values: [driver_object_list.filter],
        },
        created_by: created_by_object,
        type: typeFilter,
      };

      try {
        const { VehicleFindViewAggrid } = await graphQLClient.request(
          VEHICLE_VIEW,
          {
            aggridInput: {
              startRow: startRow,
              endRow: endRow,
              filterModel: formatFilter,
              sortModel: sortModel,
            },
          }
        );

        params.success({
          rowData: VehicleFindViewAggrid.results as any[],
          rowCount: VehicleFindViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    params.api.setFilterModel({
      status: {
        type: "set",
        values: ["active"],
      },
    });
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxAggridModal
      modalTitle={t("logistic.vehicle.index")}
      gridRef={gridRef}
      height={450}
      columnDefs={columnDefs}
      rowSelection={rowSelection}
      onFinishEditing={finishVehiclesSelect}
      modalIsOpen={showSelectVehicle}
      getRowId={getRowId}
      closeModal={closeVehicleTable}
      selectedIds={vehicleIds}
      setSelectedIds={setVehicleIds}
      idsSnapshot={vehicleIdsSnapshot}
      setIdsSnapshot={setVehicleIdsSnapshot}
      onGridReady={onGridReady}
    />
  );
};

export default CheckedVehicleModal;
