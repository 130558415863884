import { IDateFilterParams } from "ag-grid-community";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const dateFilterParams: IDateFilterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    if (cellValue == null) {
      return 0;
    }
    const dateWithoutTime = cellValue.split(",")[0];
    var dateParts = dateWithoutTime.split("/");
    var year = Number(dateParts[2].substring(0, 4));
    var month = Number(dateParts[1]) - 1;
    var day = Number(dateParts[0]);
    var cellDate = new Date(year, month, day);
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  },
};

export const dateFilterModel = (filterObj: any) => {
  if (!filterObj) {
    return undefined;
  }
  const startOfDay = dayjs.utc(filterObj.dateFrom).subtract(7, "hour");

  if (filterObj.type === "equals" || filterObj.dateFrom === filterObj.dateTo) {
          const endOfDay = startOfDay.add(1, "day").subtract(1, "millisecond");

    return {
      filterType: "date",
      type: "inRange",
      dateFrom: startOfDay ? startOfDay.toISOString() : undefined,
      dateTo: endOfDay ? endOfDay.toISOString() : undefined,
    };
  }

  return {
    filterType: "date",
    type: filterObj.type,
    dateFrom: startOfDay ? startOfDay.toISOString() : undefined,
    dateTo: filterObj.dateTo
      ? dayjs.utc(filterObj.dateTo).add(1,'day').subtract(7, "hour").toISOString()
      : undefined,
  };
};
