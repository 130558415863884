import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BinLocationsFindAllQuery,
  InventorySettingsByTypeQuery,
  InventorySettingType,
  useBinLocationsFindAllQuery,
  useInventorySettingsByTypeQuery,
  useWarehousesFindAllQuery,
  WarehousesFindAllQuery,
} from "generated/general";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { dateFilterParams } from "utils/AgGridFilter";
import { formatDate } from "utils/Date";

export const useCurrentStockBySNReportColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } = useWarehousesFindAllQuery<WarehousesFindAllQuery>(
    graphQLClient,
    {},
    {
      enabled: false,
    }
  );

  const { refetch: refecthBinLocation } =
    useBinLocationsFindAllQuery<BinLocationsFindAllQuery>(
      graphQLClient,
      {},
      {
        enabled: false,
      }
    );

  const { refetch: refetchCategory } =
    useInventorySettingsByTypeQuery<InventorySettingsByTypeQuery>(
      graphQLClient,
      {
        type: InventorySettingType.Cate_1,
      },
      {
        enabled: false,
      }
    );

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: t("inventory.item.unique_id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "seller_sku",
        headerName: t("inventory.item.sku"),
        filter: "agTextColumnFilter",
        hide: true,
        valueFormatter: (params) => (params.value ? params.value : "-"),
      },
      {
        field: "name",
        headerName: t("inventory.item.name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "cate_1",
        headerName: t("inventory.item.category_one"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: categoriesResult } = await refetchCategory();
            const values = categoriesResult?.InventorySettingsByType;
            const formatValues = values?.map((category) => category.name);
            params.success(formatValues);
          },
        },
      },
      {
        field: "serial_no",
        headerName: t("inventory.item.serial_no"),
        filter: "agTextColumnFilter",
      },
      {
        field: "barcode",
        headerName: t("inventory.item.barcode"),
        filter: "agTextColumnFilter",
      },
      {
        field: "warehouse_unique_id",
        headerName: t("inventory.item.warehouse.warehouse_id"),
        filter: "agSetColumnFilter",
        hide: true,
        filterParams: {
          values: async (params: any) => {
            const { data: warehousesResult } = await refetch();
            const values = warehousesResult?.WarehousesFindAll;
            const formatValues = values?.map(
              (warehouse) => warehouse.unique_id
            );
            params.success(formatValues);
          },
        },
      },
      {
        field: "warehouse_name",
        headerName: t("inventory.item.warehouse.index"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: warehousesResult } = await refetch();
            const values = warehousesResult?.WarehousesFindAll;
            const formatValues = values?.map((warehouse) => warehouse.name);
            params.success(formatValues);
          },
        },
      },
      {
        field: "bin_name",
        headerName: t("inventory.itemCurrentStockSerialNumber.binLocation"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: binLocationResult } = await refecthBinLocation();
            const values = binLocationResult?.BinLocationsFindAll;
            const formatValues = values?.map((binlocation) => binlocation.name);
            params.success(formatValues);
          },
        },
      },
      {
        field: "stock_qty",
        headerName: t("inventory.item.stock_qty"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params) =>
          params.value ? params.value.toLocaleString() : 0,
        cellStyle: { display: "flex", justifyContent: "flex-end" },
      },
      {
        field: "lot_date",
        headerName: "วันที่ Lot",
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
    ]);
  }, [t, refetch, refecthBinLocation, refetchCategory]);
  return columnDefs;
};
