import "./App.css";
import "./i18n/i18n";
import { Navigate, Route, Routes } from "react-router-dom";
import { Cookies } from "react-cookie";

import DashboardLayout from "./components/UI/DashboardLayout/Dashboard";

// import Landing from "./pages/Landing";
import Setting from "./pages/Setting";
import CompanySetting from "./pages/Setting/Company";

import User from "./pages/User";
import UserAccount from "./pages/User/Account";
import UserAccountContainer from "./pages/User/Account/Container";

import Login from "./pages/Login";
import RequireUser from "./pages/RequireUser";
import Contact from "./pages/Contact";
import ContactContainer from "./pages/Contact/Container";
import ContactLanding from "./pages/Contact/Landing";
import InventorySetting from "./pages/Setting/Inventory";
import CategorySetting from "./pages/Setting/Inventory/Category";
import UomSetting from "./pages/Setting/Inventory/Uom";
import LocationSetting from "./pages/Setting/Inventory/Location";
import Inventory from "./pages/Inventory";
import Item from "./pages/Inventory/Item";
import ItemContainer from "./pages/Inventory/Item/Container";
import GoodsReceive from "./pages/Inventory/GoodsReceive";
import GoodsReceiveContainer from "./pages/Inventory/GoodsReceive/Container";
import GoodsIssue from "./pages/Inventory/GoodsIssue";
import GoodsIssueContainer from "./pages/Inventory/GoodsIssue/Container";
import GoodsAdjust from "pages/Inventory/GoodsAdjust";
import GoodsAdjustContainer from "pages/Inventory/GoodsAdjust/Container";
import ContactImporter from "pages/Contact/Importer/Contact";
import Sales from "./pages/Sales";
import Quotation from "pages/Sales/Quotation";
import QuotationContainer from "pages/Sales/Quotation/Container";
import QuotationPDF from "pages/Sales/Quotation/PDF";
import BillingNote from "pages/Sales/BillingNote";
import BillingNoteContainer from "pages/Sales/BillingNote/Container";
import BillingNotePDF from "pages/Sales/BillingNote/PDF";
import Purchase from "./pages/Purchase";
import PurchaseOrder from "./pages/Purchase/Order";
import PurchaseOrderContainer from "./pages/Purchase/Order/Container";
import PurchaseReturn from "./pages/Purchase/Return";
import PurchaseReturnContainer from "./pages/Purchase/Return/Container";
import PurchaseReport from "pages/Purchase/Report";
import PurchaseByDueDate from "pages/Purchase/Report/PurchaseByDueDate";
import PurchaseByProduct from "pages/Purchase/Report/PuchaseByProduct";
import PurchaseBySupplier from "pages/Purchase/Report/PurchaseBySupplier";
import GoodsTransfer from "pages/Inventory/GoodsTransfer";
import GoodsTransferContainer from "pages/Inventory/GoodsTransfer/Container";
import InventoryReport from "pages/Inventory/Report";
import CurrentStockBySN from "pages/Inventory/Report/CurrentStockBySN";
import NoEntry from "pages/Inventory/Report/NoEntry";
import Stock from "pages/Inventory/Report/Stock";
import TraceEntry from "pages/Inventory/Report/TraceEntry";
import Logistic from "pages/Logistic";
import Vehicle from "pages/Logistic/Vehicle";
import VehicleContainer from "pages/Logistic/Vehicle/Container";
import DeliveryTrip from "pages/Logistic/DeliveryTrip";
import DeliveryTripContainer from "pages/Logistic/DeliveryTrip/Container";
import Location from "pages/Logistic/Location";
import ItemImporter from "pages/Inventory/Importer/Item";
import PurchaseOrderPDF from "pages/Purchase/Order/PDF";
import LocationImporter from "pages/Logistic/Importer/Location";
import { DailyWorksheetPDF } from "pages/Logistic/DeliveryTrip/PDF";
import DeliveryTripReport from "pages/Logistic/Report/DeliveryTripReport";
import DeliveryReport from "pages/Logistic/Report";
import DeliveryTripWorkReport from "pages/Logistic/Report/DeliveryTripWorkReport";
import DeliveryTripIncomeReport from "pages/Logistic/Report/DeliveryTripIncomeReport";
import DeliveryTripExpensesReport from "pages/Logistic/Report/DeliveryTripExpensesReport";
import QuotationReports from "pages/Sales/Report";
import QuotationReport from "pages/Sales/Report/Quotation";
import GanttChartPage from "pages/Logistic/GanttChart";
import Maintenance from "pages/Logistic/Maintenance";
import MaintenanceContainer from "pages/Logistic/Maintenance/Container";
import MaintenancePDF from "pages/Logistic/Maintenance/PDF";
import BillingNoteByItemReport from "pages/Logistic/Report/BillingNoteByItem";
import BillingNoteByCustomerReport from "pages/Logistic/Report/BillingNoteByCustomer";
import Dashboard from "pages/Dashboard";
import Tire from "pages/Logistic/Tire";
import TireContainer from "pages/Logistic/Tire/Container";

const cookies = new Cookies();

function App() {
  const isLoggedIn = cookies.get("logged_in");

  return (
    <Routes>
      <Route
        path="/"
        element={
          isLoggedIn ? (
            <Navigate replace to="/landing" />
          ) : (
            <Navigate replace to="/login" />
          )
        }
      />
      <Route path="/login" element={<Login />} />
      <Route element={<RequireUser />}>
        <Route element={<DashboardLayout />}>
          <Route path="/landing" element={<Dashboard />} />
          <Route path="/contact">
            <Route index element={<ContactLanding />} />
            <Route path="all" element={<Contact type="all" />} />
            <Route path="customer" element={<Contact type="customer" />} />
            <Route path="supplier" element={<Contact type="supplier" />} />
            <Route path="all/add" element={<ContactContainer />} />
            <Route path="all/:id" element={<ContactContainer />} />
            <Route path="import" element={<ContactImporter />} />
          </Route>
          <Route path="/inventory">
            <Route index element={<Inventory />} />
            <Route path="item">
              <Route index element={<Item />} />
              <Route path="import" element={<ItemImporter />} />
              <Route path="add" element={<ItemContainer type={"item"} />} />
              <Route path=":id" element={<ItemContainer type={"item"} />} />
            </Route>
            <Route path="report">
              <Route index element={<InventoryReport />} />
              <Route path="stock" element={<Stock />} />
              <Route path="no-entry" element={<NoEntry />} />
              <Route path="trace-entry" element={<TraceEntry />} />
              <Route
                path="current-stock-by-sn"
                element={<CurrentStockBySN />}
              />
            </Route>
            <Route path="goods-receive">
              <Route index element={<GoodsReceive />} />
              <Route path="add" element={<GoodsReceiveContainer />} />
              <Route path=":id" element={<GoodsReceiveContainer />} />
            </Route>
            <Route path="goods-issue">
              <Route index element={<GoodsIssue />} />
              <Route path="add" element={<GoodsIssueContainer />} />
              <Route path=":id" element={<GoodsIssueContainer />} />
            </Route>
            <Route path="goods-transfer">
              <Route index element={<GoodsTransfer />} />
              <Route path="add" element={<GoodsTransferContainer />} />
              <Route path=":id" element={<GoodsTransferContainer />} />
            </Route>
            <Route path="goods-adjust">
              <Route index element={<GoodsAdjust />} />
              <Route path="add" element={<GoodsAdjustContainer />} />
              <Route path=":id" element={<GoodsAdjustContainer />} />
            </Route>
            <Route path="service">
              <Route index element={<Item />} />
              <Route path="add" element={<ItemContainer type={"service"} />} />
              <Route path=":id" element={<ItemContainer type={"service"} />} />
            </Route>
          </Route>
          <Route path="/user">
            <Route index element={<User />} />
            <Route path="account">
              <Route index element={<UserAccount />} />
              <Route path="add" element={<UserAccountContainer />} />
              <Route path=":id" element={<UserAccountContainer />} />
            </Route>
            <Route path="setting">
              <Route path=":id" element={<UserAccountContainer />} />
            </Route>
          </Route>
          <Route path="/sales">
            <Route index element={<Sales />} />
            <Route path="quotation">
              <Route index element={<Quotation />} />
              <Route path="add" element={<QuotationContainer />} />
              <Route path=":id">
                <Route index element={<QuotationContainer />} />
                <Route path="pdf" element={<QuotationPDF />} />
              </Route>
            </Route>
            <Route path="billing-note">
              <Route index element={<BillingNote />} />
              <Route path="add" element={<BillingNoteContainer />} />
              <Route path=":id">
                <Route index element={<BillingNoteContainer />} />
                <Route path="pdf" element={<BillingNotePDF />} />
              </Route>
            </Route>
            <Route path="report">
              <Route index element={<QuotationReports />} />
              <Route path="quotation" element={<QuotationReport />} />
            </Route>
          </Route>
          <Route path="/logistic">
            <Route index element={<Logistic />} />
            <Route path="gantt-chart">
              <Route index element={<GanttChartPage />} />
            </Route>
            <Route path="vehicle">
              <Route index element={<Vehicle />} />
              <Route path="add" element={<VehicleContainer />} />
              <Route path=":id" element={<VehicleContainer />} />
            </Route>
            <Route path="tire">
              <Route index element={<Tire />} />
              <Route path="add" element={<TireContainer />} />
              <Route path=":id" element={<TireContainer />} />
            </Route>
            <Route path="delivery-trip">
              <Route index element={<DeliveryTrip />} />
              <Route path="add" element={<DeliveryTripContainer />} />
              <Route path=":id">
                <Route index element={<DeliveryTripContainer />} />
                <Route path="pdf" element={<DailyWorksheetPDF />} />
              </Route>
            </Route>
            <Route path="maintenance">
              <Route index element={<Maintenance />} />
              <Route path="add" element={<MaintenanceContainer />} />
              <Route path=":id">
                <Route index element={<MaintenanceContainer />} />
                <Route path="pdf" element={<MaintenancePDF />} />
              </Route>
            </Route>
            <Route path="location">
              <Route index element={<Location />} />
              <Route path="import" element={<LocationImporter />} />
            </Route>
            <Route path="report">
              <Route index element={<DeliveryReport />} />
              <Route path="delivery_trip" element={<DeliveryTripReport />} />
              <Route
                path="delivery_trip_work"
                element={<DeliveryTripWorkReport />}
              />
              <Route
                path="delivery_trip_income"
                element={<DeliveryTripIncomeReport />}
              />
              <Route
                path="delivery_trip_expenses"
                element={<DeliveryTripExpensesReport />}
              />
              <Route
                path="delivery_trip_income_by_item"
                element={<BillingNoteByItemReport />}
              />
              <Route
                path="delivery_trip_income_by_customer"
                element={<BillingNoteByCustomerReport />}
              />
            </Route>
          </Route>
          <Route path="/purchase">
            <Route index element={<Purchase />} />
            <Route path="order">
              <Route index element={<PurchaseOrder />} />
              <Route path="add" element={<PurchaseOrderContainer />} />
              <Route path=":id">
                <Route index element={<PurchaseOrderContainer />} />
                <Route path="pdf" element={<PurchaseOrderPDF />} />
              </Route>
            </Route>
            <Route path="return">
              <Route index element={<PurchaseReturn />} />
              <Route path="add" element={<PurchaseReturnContainer />} />
              <Route path=":id" element={<PurchaseReturnContainer />} />
            </Route>
            <Route path="report">
              <Route index element={<PurchaseReport />} />
              <Route
                path="purchase-by-due-date"
                element={<PurchaseByDueDate />}
              />
              <Route
                path="purchase-by-product"
                element={<PurchaseByProduct />}
              />
              <Route
                path="purchase-by-supplier"
                element={<PurchaseBySupplier />}
              />
            </Route>
          </Route>
          <Route path="/setting">
            <Route index element={<Setting />} />
            <Route path="company">
              <Route index element={<CompanySetting />} />
            </Route>
            <Route path="inventory">
              <Route index element={<InventorySetting />} />
              <Route path="location" element={<LocationSetting />} />
              <Route
                path="category-one"
                element={<CategorySetting type="cate_1" />}
              />
              <Route
                path="category-two"
                element={<CategorySetting type="cate_2" />}
              />
              <Route
                path="category-three"
                element={<CategorySetting type="cate_3" />}
              />
              <Route path="uom" element={<UomSetting />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
