import { gql } from "graphql-request";

export const QUOTATION_REPORT = gql`
  query QuotationReportViewsAggrid($aggridInput: AnyAggridInput) {
    QuotationReportViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        unique_id
        status
        related_user_list
        customer_unique_id
        customer_name
        customer_identity_no
        customer_phone
        customer_email
        billing_address
        billing_sub_district
        billing_district
        billing_province
        billing_postal_code
        billing_country
        plan_start_date
        plan_end_date
        vehicle_type
        container_type
        item_type
        packing_location
        is_frozen
        item_value
        remark
        temperature
        item_unique_id
        item_name
        item_sku_desc
        qty
        uom_name
        price_per_unit
        discount
        vat_percentage
        post_discount_amount
        item_withholding_tax_value
        vat_exempted_amount
        vat_0_percent_amount
        vat_7_percent_amount
        vat_amount
        net_amount
        withholding_tax_value
        total_amount
        issue_date
        created_date
      }
    }
  }
`;
