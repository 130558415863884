import { useCallback, useEffect, useRef } from "react";
import { useItemCurrentStockByBinLocationColumnDefs } from "./byBinLocationColumnDefs";
import { GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import AgGrid from "components/UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { ITEM_SKU_QTY_BY_BIN_LOCATION_VIEW } from "services/AgGrid/ItemSkuQtyByBinLocationAggrid";

type Props = {
  itemUniqueId: string | null;
};

const ItemCurrentStockByBinLocationTable = ({ itemUniqueId }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);

  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const columnDefs = useItemCurrentStockByBinLocationColumnDefs();
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const formatFilter = {
        ...filterModel,
        item_unique_id: itemUniqueId && {
          type: "equals",
          filterType: "text",
          filter: itemUniqueId,
        },
      };
      try {
        const { ItemSkuQtyByBinLocationsViewAggrid } =
          await graphQLClientWms.request(ITEM_SKU_QTY_BY_BIN_LOCATION_VIEW, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel: sortModel,
            },
          });
        params.success({
          rowData: ItemSkuQtyByBinLocationsViewAggrid.results as any[],
          rowCount: ItemSkuQtyByBinLocationsViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const itemUniqueIdInstance =
        params.api.getFilterInstance("item_unique_id");
      itemUniqueIdInstance?.setModel({ values: itemUniqueId });

      params.api.onFilterChanged();
    },
    [itemUniqueId]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={608}
      onGridReady={onGridReady}
      disableFloatingFilter
    />
  );
};

export default ItemCurrentStockByBinLocationTable;
