import { Dispatch, SetStateAction, useEffect } from "react";
import { IBankAccountDetail, IContact } from "../../../types/Contact/contact";
import { FieldArrayWithId, useForm } from "react-hook-form";
import {
  bankAccountSchema,
  bankAccountValidation,
} from "../../Form/Contact/schema";
import { useDisable } from "../../../hooks/use-disable";
import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Grid } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import LabelInput from "../LabelInput";
import ControlledSelect from "../../Controller/ControlledSelect";
import ControlledTextField from "../../Controller/ControlledTextField";
import { ISelectOption } from "../../../types/global";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  isEdit?: boolean;
  isCreate?: boolean;
  setIsEdit?: Dispatch<SetStateAction<boolean>>;
  onAddHandler?: (data: IBankAccountDetail) => void;
  onUpdateHandler?: (data: IBankAccountDetail) => void;
  data?: FieldArrayWithId<IContact, "bank_account_detail_list", "id">;
};

const bankProviderOptions: ISelectOption[] = [
  {
    label: "ธ.กรุงเทพ",
    value: "ธ.กรุงเทพ",
  },
  {
    label: "ธ.กรุงไทย",
    value: "ธ.กรุงไทย",
  },
  {
    label: "ธ.กรุงศรีอยุธยา",
    value: "ธ.กรุงศรีอยุธยา",
  },
  {
    label: "ธ.กสิกรไทย",
    value: "ธ.กสิกรไทย",
  },
  {
    label: "ธ.ทหารไทยธนชาต",
    value: "ธ.ทหารไทยธนชาต",
  },
  {
    label: "ธ.ออมสิน",
    value: "ธ.ออมสิน",
  },
  {
    label: "ธ.ซีไอเอ็มบี ไทย",
    value: "ธ.ซีไอเอ็มบี ไทย",
  },
  {
    label: "ธ.ไทยพาณิชย์",
    value: "ธ.ไทยพาณิชย์",
  },
  {
    label: "ธ.สแตนดาร์ดชาร์เตอร์ด",
    value: "ธ.สแตนดาร์ดชาร์เตอร์ด",
  },
  {
    label: "ธ.ยูโอบี",
    value: "ธ.ยูโอบี",
  },
  {
    label: "ธ.ทิสโก้",
    value: "ธ.ทิสโก้",
  },
  {
    label: "ธ.เกียรตินาคินภัทร",
    value: "ธ.เกียรตินาคินภัทร",
  },
  {
    label: "ธ.แลนด์ แอนด์ เฮ้าส์",
    value: "ธ.แลนด์ แอนด์ เฮ้าส์",
  },
  {
    label: "ธ.ไอซีบีซี (ไทย)",
    value: "ธ.ไอซีบีซี (ไทย)",
  },
  {
    label: "ธ.อิสลามแห่งประเทศไทย",
    value: "ธ.อิสลามแห่งประเทศไทย",
  },
  {
    label: "ธ.อื่นๆ",
    value: "ธ.อื่นๆ",
  },
];

const bankTypeOptions: ISelectOption[] = [
  {
    label: "บัญชีออมทรัพย์",
    value: "บัญชีออมทรัพย์",
  },
  {
    label: "บัญชีฝากประจำ",
    value: "บัญชีฝากประจำ",
  },
  {
    label: "บัญชีกระแสรายวัน",
    value: "บัญชีกระแสรายวัน",
  },
];

const BankModal = ({
  isCreate,
  data,
  isEdit,
  setIsEdit,
  closeModalHandler,
  open,
  onAddHandler,
  onUpdateHandler,
}: Props) => {
  const {
    control,
    getValues,
    handleSubmit,
    formState: { isValid, errors },
    reset,
  } = useForm<IBankAccountDetail>({
    defaultValues: bankAccountSchema,
    resolver: yupResolver<any>(bankAccountValidation),
    mode: "onChange",
  });

  const [disabled] = useDisable();

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      if (isCreate) {
        reset(bankAccountSchema);
      } else {
        if (data) {
          if (data.id) {
            const { id, ...other } = data;
            reset(other);
          } else {
            reset(data);
          }
        }
      }
    }
  }, [data, isCreate, open, reset]);

  const onClose = () => {
    if (setIsEdit) {
      setIsEdit(false);
    }

    closeModalHandler();
  };
  return (
    <ModalUI
      title={t("contact.financial.bank.index")}
      open={open}
      handleClose={onClose}
      maxWidth="md"
      action={
        <>
          {!disabled ? (
            <Box sx={{ display: "flex", gap: 1 }}>
              {isCreate ? (
                <>
                  <CustomizedButton
                    title={t("button.cancel")}
                    variant="outlined"
                    onClick={onClose}
                  />
                  <CustomizedButton
                    title={t("button.confirm")}
                    variant="contained"
                    onClick={() => {
                      if (onAddHandler) {
                        handleSubmit(onAddHandler)();
                        if (isValid) {
                          onClose();
                        }
                      }
                    }}
                  />
                </>
              ) : isEdit ? (
                <>
                  <CustomizedButton
                    title={t("button.cancel")}
                    variant="outlined"
                    onClick={() => {
                      if (setIsEdit) {
                        setIsEdit(false);
                      }
                      reset();
                    }}
                  />
                  <CustomizedButton
                    title={t("button.confirm")}
                    variant="contained"
                    onClick={() => {
                      if (onUpdateHandler) {
                        handleSubmit(onUpdateHandler)();
                        if (isValid) {
                          onClose();
                        }
                      }
                    }}
                  />
                </>
              ) : undefined}
            </Box>
          ) : undefined}
        </>
      }
    >
      {/* <Divider sx={{ mb: 1 }} /> */}
      <Grid container spacing={1.5} mt={0}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          {!isCreate && !isEdit ? (
            <LabelInput
              label={t("contact.financial.bank.provider")}
              value={getValues("provider")}
            />
          ) : (
            <ControlledSelect
              control={control}
              label={t("contact.financial.bank.provider")}
              name="provider"
              options={bankProviderOptions}
              error={Boolean(errors.provider)}
              helperText={errors.provider?.message}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          {!isCreate && !isEdit ? (
            <LabelInput
              label={t("contact.financial.bank.type")}
              value={getValues("type")}
            />
          ) : (
            <ControlledSelect
              control={control}
              label={t("contact.financial.bank.type")}
              name="type"
              options={bankTypeOptions}
              error={Boolean(errors.type)}
              helperText={errors.type?.message}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            control={control}
            label={t("contact.financial.bank.account_number")}
            name="account_number"
            error={Boolean(errors.account_number)}
            helperText={errors.account_number?.message}
            viewMode={!isCreate && !isEdit}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            control={control}
            label={t("contact.financial.bank.name")}
            name="name"
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            viewMode={!isCreate && !isEdit}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            control={control}
            label={t("contact.financial.bank.branch")}
            name="branch"
            error={Boolean(errors.branch)}
            helperText={errors.branch?.message}
            viewMode={!isCreate && !isEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            label={t("contact.financial.bank.description")}
            name="description"
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
            multiline
            viewMode={!isCreate && !isEdit}
          />
        </Grid>
      </Grid>
      {/* <Divider
        sx={{
          mt: 2,
        }}
      /> */}
    </ModalUI>
  );
};

export default BankModal;
