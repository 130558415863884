import { FormControlLabel, Grid, Radio, Typography } from "@mui/material";
// import ControlledSelect from "../../Controller/ControlledSelect";
import CustomizedButton from "../../Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
// import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import { useFormContext } from "react-hook-form";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Fragment } from "react";
import ImporterDropzoneUI from "components/UI/ImporterDropzoneUI";
// import {
//   customerTypeOptions,
//   vendorTypeOptions,
// } from "../../../utils/Formatter/Contact";

const ContactImportUploader = () => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();

  const type = watch("type");
  const importType = watch("import_type");

  const downloadTemplateHandler = () => {
    const filePath = `/static/importer/contact/template_ผู้ติดต่อ.xlsx`;
    window.open(filePath, "_blank");
  };

  const createButtonDisabled = [
    "customer_attribute",
    "vendor_attribute",
  ].includes(importType);
  const editButtonDisabled = ![
    "customer",
    "vendor",
    "customer_attribute",
    "vendor_attribute",
  ].includes(importType);

  return (
    // <Fragment>
    //   <Grid container spacing={2}>
    //     <Grid item xs={12} md={4}>
    //       {/* <ControlledSelect
    //         control={control}
    //         name="import_type"
    //         options={
    //           contactType === "customer"
    //             // ? customerTypeOptions
    //             // : vendorTypeOptions
    //         }
    //         onChange={(e: any) => {
    //           const value = e.target.value;
    //           if (
    //             value === "customer_attribute" ||
    //             value === "vendor_attribute"
    //           ) {
    //             setValue("type", "update");
    //           } else {
    //             setValue("type", "create");
    //           }
    //         }}
    //       /> */}
    //     </Grid>
    //     <Grid item xs={12} md={4}>
    //       <CustomizedButton
    //         title={t("inventory.items.import.download_template")}
    //         variant="outlined"
    //         fullWidth
    //         size="medium"
    //         onClick={downloadTemplateHandler}
    //       />
    //     </Grid>
    //     <Grid item xs={12} md={4} />
    //     {/* <Grid item xs={12} md={4}>
    //       <CustomizedTooltip title="">
    //         <InfoOutlinedIcon />
    //       </CustomizedTooltip>
    //     </Grid> */}
    //     <Grid item xs={12} md={4}>
    //       <Radio
    //         onClick={() => {
    //           setValue("type", "create");
    //         }}
    //         // label={t("button.create")}
    //         checked={type === "create"}
    //         disabled={createButtonDisabled}
    //         // small
    //       />
    //     </Grid>
    //     <Grid item xs={12} md={4}>
    //       <Radio
    //         onClick={() => {
    //           setValue("type", "update");
    //         }}
    //         // label={t("button.edit")}
    //         checked={type === "update"}
    //         disabled={editButtonDisabled}
    //         // small
    //       />
    //     </Grid>
    //     <Grid item xs={12} md={4}></Grid>
    //     <Grid item xs={12}>
    //       <ImporterDropzoneUI hasLabelHeader />
    //     </Grid>
    //   </Grid>
    // </Fragment>
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} container alignItems="center" spacing={2}>
          <Grid item>
            {/* <FormLabel component="legend">{t("เลือกประเภทการนำเข้า")}</FormLabel> */}
            <Typography variant="h6">
              {"เลือกประเภทการนำเข้า"}
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Radio
                  onClick={() => {
                    setValue("type", "create");
                  }}
                  checked={type === "create"}
                  disabled={createButtonDisabled}
                />
              }
              label={t("สร้าง")}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Radio
                  onClick={() => {
                    setValue("type", "update");
                  }}
                  checked={type === "update"}
                  disabled={editButtonDisabled}
                />
              }
              label={t("แก้ไข")}
            />
          </Grid>
          <Grid item xs />
          <Grid item>
            <CustomizedButton
              title={t("ดาวน์โหลด Template")}
              variant="outlined"
              size="medium"
              onClick={downloadTemplateHandler}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ImporterDropzoneUI hasLabelHeader />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ContactImportUploader;
