import { Box, Grid } from "@mui/material";
import DailyWorkSheetCompanyInfo from "./Header/CompanyInfo";
import { DailyWorkSheetHighlightHeader } from "./Header/HighlightHeader";
import { DailyWorkSheetVehicleInfo } from "./Header/VehicleInfo";
import { ReactNode } from "react";
import { DailyWorkSheetFooterInfo } from "./Footer/FooterInfo";
import { DailyWorkSheetDriverSigner } from "./Footer/DriverSigner";

type Props = {
  data: any;
  index: number;
  isLast: boolean;
  isMultiplePage: boolean;
  totalPages: number;
  children?: ReactNode;
};

export const DailyWorkSheetLayout = ({
  data,
  index,
  isLast,
  totalPages,
  isMultiplePage,
  children,
}: Props) => {
  return (
    <>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          border: "1px solid #eee",
          borderRadius: "5px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "28px",
          mb: 5,
          "@media print": {
            width: "auto",
            height: "297mm",
            boxSizing: "border-box",
          },
        }}
      >
        <Box>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={5.6} height={82}>
              <DailyWorkSheetCompanyInfo branch_unique_id="1" />
            </Grid>
            <Grid item xs={5.6}>
              <DailyWorkSheetHighlightHeader data={data} />
            </Grid>
          </Grid>
          <DailyWorkSheetVehicleInfo data={data} />
          <Box id="print-content">{children}</Box>
          {isLast && <DailyWorkSheetFooterInfo />}
        </Box>
        {isLast && (
          <DailyWorkSheetDriverSigner isMultiplePages={isMultiplePage} />
        )}
        {isMultiplePage && (
          <Box
            sx={{
              fontSize: "10px",
              color: "#737373",
              position: "absolute",
              bottom: 10,
              right: 27,
            }}
          >
            หน้า {index + 1}/{totalPages}
          </Box>
        )}
      </Box>
    </>
  );
};
