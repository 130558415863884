import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";

export const useDeliveryTripOption = (
  status?: string | null,
  isEditing?: boolean
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: isEditing,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    [isEditing]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions([defaultOptions[1], defaultOptions[2]]); // ยกเลิก, คัดลอก
        break;
      case "cancelled":
        setSelectedModifyOptions([defaultOptions[1]]); // คัดลอก
        break;
      case "finished":
        setSelectedModifyOptions([defaultOptions[0], defaultOptions[1]]); // คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions);
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};
