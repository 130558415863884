import { gql } from "graphql-request";

export const ITEM_SKU_QTY_BY_BARCODE_VIEW = gql`
  query ItemSkuQtyByBarcodesViewAggrid($aggridInput: AnyAggridInput!) {
    ItemSkuQtyByBarcodesViewAggrid(aggridInput: $aggridInput) {
      results {
        item_id
        item_unique_id
        warehouse_id
        warehouse_unique_id
        warehouse_name
        serial_no
        bin_location_id
        bin_name
        barcode
        lot_date
        stock_qty
        available_qty
        purchase_ordered_qty
        sale_committed_qty
      }
      count
    }
  }
`;
