import { Grid, Typography } from "@mui/material";
import { ChangeEventHandler, FC, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { FileRejection } from "react-dropzone";
import { useSnackbar } from "notistack";
import { FileError } from "react-dropzone";
import { Box } from "@mui/system";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { IMenuOption } from "types/global";
import CustomizedIconMenuOptions from "components/Custom/CustomizedIconMenuOptions";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  borderWidth: 2,
  borderRadius: 14,
  borderColor: "#BDBDBD",
  borderStyle: "dashed",
  backgroundColor: "#F2F2F2",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
} as const;

const activeStyle = {
  borderColor: "#2196f3",
} as const;

const acceptStyle = {
  borderColor: "#00e676",
} as const;

const rejectStyle = {
  borderColor: "#ff1744",
} as const;

const options = (acceptedFileType?: string): IMenuOption[] => [
  {
    value: "ลบ",
  },
  {
    value:
      acceptedFileType === "image" || acceptedFileType === "strict-image"
        ? "เลือกเป็นภาพหลัก"
        : "เลือกเป็นไฟล์หลัก",
  },
];

const optionWithNoMainIndex = (): IMenuOption[] => [
  {
    value: "ลบ",
  },
];

const ItemImage: FC<{
  control: Control<any>;
  name: string;
  setValue: UseFormSetValue<any>;
  multiple?: boolean;
  acceptedFileType?: string;
  maxSize?: number;
  maxFileSize?: number;
  disabled?: boolean;
  isNotRevoke?: boolean;
  title?: string;
  disableMainIndex?: boolean;
}> = ({
  control,
  name,
  setValue,
  acceptedFileType,
  maxSize,
  maxFileSize,
  disabled,
  isNotRevoke,
  title,
  disableMainIndex,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <Dropzone
          control={control}
          name={name}
          setValue={setValue}
          onChange={(e) => onChange(e.target.files?.[0] ?? null)}
          options={
            disableMainIndex
              ? optionWithNoMainIndex()
              : options(acceptedFileType)
          }
          acceptedFileType={acceptedFileType}
          maxSize={maxSize}
          disabled={disabled}
          maxFileSize={maxFileSize}
          isNotRevoke={isNotRevoke}
          title={title}
          disableMainIndex={disableMainIndex}
        />
      )}
    />
  );
};

const Dropzone: FC<{
  control: Control;
  name: string;
  setValue: UseFormSetValue<any>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  options?: IMenuOption[];
  maxSize?: number;
  maxFileSize?: number;
  acceptedFileType?: string;
  isNotRevoke?: boolean;
  title?: string;
  disableMainIndex?: boolean;
}> = ({
  control,
  name,
  setValue,
  onChange,
  disabled,
  options,
  acceptedFileType,
  maxSize = 10,
  maxFileSize,
  isNotRevoke,
  title,
  disableMainIndex,
}) => {
  const getAcceptFileType = (
    fileType: string | undefined
  ): {
    "image/*"?: any[];
    "image/jpg"?: any[];
    "image/jpeg"?: any[];
    "image/png"?: any[];
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"?: any[];
    "application/pdf"?: any[];
  } => {
    switch (fileType) {
      case "strict-image":
        return { "image/jpg": [], "image/jpeg": [], "image/png": [] };
      case "image":
        return {
          "image/*": [],
        };
      case "xlsx":
        return {
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
        };
      default:
        return {
          "image/*": [],
          "application/pdf": [],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
        };
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const mainImageIndex = useWatch({
    control,
    name: "main_image_index",
  });

  const files = useWatch({
    control,
    name,
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 10,
    accept: getAcceptFileType(acceptedFileType),
    maxSize: maxFileSize ? maxFileSize : 10485760,
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      fileRejections.forEach((file: any) => {
        file.errors.forEach((err: FileError) => {
          if (err.code === "file-too-large") {
            enqueueSnackbar(
              "ไม่สามารถอัปโหลดได้\nเนื่องจากไฟล์มีขนาดใหญ่เกิน 10MB",
              {
                variant: "error",
                style: { whiteSpace: "pre-line" },
              }
            );
          }
        });
      });

      const combinedLength =
        (files?.length ?? 0) + (acceptedFiles?.length ?? 0);

      if (combinedLength > 10) {
        enqueueSnackbar("ไม่สามารถอัปโหลดเกิน 10 ไฟล์", {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        });
        return;
      }

      const newFiles: File[] =
        (!!files?.length && [...files].concat(acceptedFiles)) || acceptedFiles;

      const url = newFiles.map((file: File) => {
        if (typeof file === "object")
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        else return file;
      });

      if (!disableMainIndex && files.length === 0 && newFiles.length > 0) {
        setValue("main_image_index", 0);
      }
      setValue(name, url);
    },
    disabled: files.length === (maxSize ?? 10) || disabled,
    multiple: true,
  });

  const style = useMemo(
    () => ({
      ...{
        ...baseStyle,
      },
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  const setMainImage = (index: number) => {
    setValue("main_image_index", index);
  };

  const renderGallary = () => {
    return (
      <Grid container spacing={1.5}>
        {disabled && files.length === 0 && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Typography>ไม่มีข้อมูล</Typography>
            </Box>
          </Grid>
        )}
        {files?.length > 0 &&
          files.map((file: any, index: number) => {
            return (
              <Grid
                item
                xs={6}
                sm={4}
                md={2.4}
                lg={2.4}
                xl={2.4}
                key={`image-dropzone-${index}`}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: 205,
                    justifyContent: "center",
                    position: "relative",
                    border: "1px solid #d6cfcf",
                    borderRadius: "8px",
                    padding: 1,
                  }}
                >
                  {!disabled && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 1,
                        right: 1,
                      }}
                    >
                      <CustomizedIconMenuOptions
                        icon={<MoreVertIcon />}
                        options={options ?? []}
                        onSelect={(e) => {
                          const value = e.target as HTMLElement;
                          switch (value.innerText) {
                            case "ลบ":
                              const newFiles = [...files];
                              newFiles.splice(newFiles.indexOf(file), 1);
                              setValue(name, newFiles);
                              break;
                            case "เลือกเป็นภาพหลัก":
                            case "เลือกเป็นไฟล์หลัก":
                              setMainImage(index);
                              break;
                            default:
                              break;
                          }
                        }}
                      />
                    </Box>
                  )}
                  <img
                    alt="item_image"
                    src={file?.preview || file}
                    className="image-no-hover"
                    onLoad={() => {
                      if (!isNotRevoke) {
                        URL.revokeObjectURL(file?.preview);
                      }
                    }}
                  />
                  {index === mainImageIndex && !disableMainIndex && (
                    <Box
                      sx={{
                        position: "absolute",
                        left: "0.5rem",
                        bottom: "0.5rem",
                        zIndex: 5,
                        backgroundColor: "#F2F2F2",
                        borderRadius: "0.5rem",
                        padding: "0 0.5rem",
                      }}
                    >
                      <Typography color="#2167D3">
                        {acceptedFileType === "image" ||
                        acceptedFileType === "strict-image"
                          ? "ภาพหลัก"
                          : "ไฟล์หลัก"}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            );
          })}
        {!disabled && files.length < (maxSize ?? 10) && (
          <Grid item xs={6} sm={4} md={3} lg={2.4} xl={2.4}>
            <Box
              display="flex"
              justifyContent="center"
              height={205}
              position="relative"
            >
              <div {...getRootProps({ style })}>
                <input {...getInputProps({ onChange })} />
                <ImageOutlinedIcon color="primary" />
                <Typography
                  color="primary"
                  sx={{ whiteSpace: "pre-line", textAlign: "center" }}
                >
                  {title ?? "อัปโหลดรูปภาพ"}
                </Typography>
                <Typography color="primary">
                  {files?.length}/{maxSize}
                </Typography>
              </div>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  return <>{renderGallary()}</>;
};

export default ItemImage;
