import { Box, CircularProgress, Typography } from "@mui/material";
import CustomizedButton from "components/Custom/CustomizedButton";
import PDFLayout from "components/UI/PDF/Layout";
import {
  MaintenanceFindUniqueQuery,
  useMaintenanceFindUniqueQuery,
} from "generated/sales";
import { usePDFMultiplePage } from "hooks/use-pdf-multiple-page";
import { ReactInstance, useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IPdfInfo } from "types/global";
import { ISalesItemList } from "types/Sales";
import { MaintenanceTask } from "generated/sales";
import { formatDate } from "utils/Date";
import { renderAddressString } from "utils/Formatter/Global";
import { useTranslation } from "react-i18next";
import MaintenanceTaskListPdfTable from "components/Table/Logistic/Maintenance/PDF/TaskList";
import MaintenanceItemListPdfTable from "components/Table/Logistic/Maintenance/PDF/ItemList";

const maTypes = [
  {
    label: "ปกติ",
    value: "NORMAL",
  },
  {
    label: "เคลม",
    value: "CLAIM",
  },
];

const QuotationPDF = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const componentRef = useRef<ReactInstance | null>(null);

  const graphQLClient = createGraphQLClientWithMiddleware("sales");
  const { data, isLoading } =
    useMaintenanceFindUniqueQuery<MaintenanceFindUniqueQuery>(graphQLClient, {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    });

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.MaintenanceFindUnique, //data
    "maintenance", // document type
    139.66, // name and description row width
    640, // max height without footer
    495, // max height with footer
    224.875, // left header minimum height
    247.797, // right header minimum height
    37.313, // left footer minimum height (you don't need to specify this if the document doesn't have remark and tags)
    87.963, // right footer minimum height (you don't need to specify this if the document doesn't have summary)
    121.98 // reserved height
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data?.MaintenanceFindUnique?.unique_id,
  });

  const highlightSection: IPdfInfo = {
    headers: [
      "เลขที่เอกสาร",
      "วันที่ออกเอกสาร",
      "วันที่เริ่มซ่อม",
      "วันที่เสร็จสิ้น",
    ],
    info: [
      data?.MaintenanceFindUnique?.unique_id || "-",
      data?.MaintenanceFindUnique?.issue_date
        ? formatDate(data?.MaintenanceFindUnique?.issue_date)
        : "-",
      data?.MaintenanceFindUnique?.ma_start_date
        ? formatDate(data?.MaintenanceFindUnique?.ma_start_date)
        : "-",
      data?.MaintenanceFindUnique?.ma_end_date
        ? formatDate(data?.MaintenanceFindUnique?.ma_end_date)
        : "-",
    ],
  };

  const leftSection: IPdfInfo = {
    headers: [
      "ชื่ออู่/ศูนย์ซ่อม",
      "ที่อยู่เปิดบิล",
      "เลขประจำตัวผู้เสียภาษี",
      "เบอร์โทรศัพท์@Fax",
      "Email",
      "ประเภทซ่อมบำรุง",
    ],
    info: [
      data?.MaintenanceFindUnique?.ma_details?.garage_name || "-",
      renderAddressString(
        data?.MaintenanceFindUnique?.ma_details?.billing_address
      ) || "-",
      data?.MaintenanceFindUnique?.ma_details.taxpayer_id || "-",
      (data?.MaintenanceFindUnique?.ma_details?.address_contact_phone || "-") +
        "@" +
        (data?.MaintenanceFindUnique?.ma_details?.address_contact_fax || "-"),
      data?.MaintenanceFindUnique?.ma_details?.email || "-",
      maTypes.find(
        (maType) => maType.value === data?.MaintenanceFindUnique?.type
      )?.label || "-",
    ],
  };

  const rightSection: IPdfInfo = {
    headers: ["อ้างอิงถึง", "หมายเลขอ้างอิง", "พนักงานขนส่ง", "รถ", "หางลาก"],
    info: [
      data?.MaintenanceFindUnique?.reference_document_list?.map(ref => ref.document_unique_id)?.join(", ") || "-",
      data?.MaintenanceFindUnique?.reference_no || "-",
      data?.MaintenanceFindUnique?.driver_list?.[0]
        ? `${data?.MaintenanceFindUnique?.driver_list?.[0]?.user_unique_id} - 
        ${data?.MaintenanceFindUnique?.driver_list?.[0]?.first_name} ${data?.MaintenanceFindUnique?.driver_list?.[0]?.last_name}`
        : "-",
      `${data?.MaintenanceFindUnique?.vehicle_details?.vehicle_no} - ${data?.MaintenanceFindUnique?.vehicle_details?.license_plate}`,
      data?.MaintenanceFindUnique?.trailer_details?.vehicle_no
        ? `${data?.MaintenanceFindUnique?.trailer_details?.vehicle_no} - ${data?.MaintenanceFindUnique?.trailer_details?.license_plate}`
        : "-",
    ],
  };

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mx: "auto" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">{t("logistic.maintenance.index")}</Typography>
        <CustomizedButton
          title="พิมพ์เอกสาร"
          variant="outlined"
          onClick={handlePrint}
        />
      </Box>
      <Box ref={componentRef} sx={{ m: "auto" }}>
        {paginatedItems.map((item, index) => (
          <PDFLayout
            highlightSection={highlightSection}
            leftSection={leftSection}
            rightSection={rightSection}
            name={t("logistic.maintenance.index")}
            data={data?.MaintenanceFindUnique}
            summary={index === paginatedItems.length - 1}
            multiplePage={paginatedItems.length > 1}
            page={index + 1}
            allPages={paginatedItems.length}
            documentType="maintenance"
          >
            <MaintenanceTaskListPdfTable
              itemList={(item as MaintenanceTask[]) || []}
              startNumber={startNumbers[index]}
              lastTable={
                paginatedItems[paginatedItems.length - 1].length === 0
                  ? index === paginatedItems.length - 2
                  : index === paginatedItems.length - 1
              }
            />
            <MaintenanceItemListPdfTable
              itemList={(item as ISalesItemList[]) || []}
              startNumber={startNumbers[index]}
              lastTable={
                paginatedItems[paginatedItems.length - 1].length === 0
                  ? index === paginatedItems.length - 2
                  : index === paginatedItems.length - 1
              }
              totalAmount={
                data?.MaintenanceFindUnique?.total_amount
                  ? data?.MaintenanceFindUnique?.total_amount
                  : 0
              }
            />
          </PDFLayout>
        ))}
      </Box>
    </Box>
  );
};

export default QuotationPDF;
