import { gql } from "graphql-request";

export const TRACE_ENTRY_VIEW = gql`
  query TraceEntriesViewAggrid($aggridInput: AnyAggridInput!) {
    TraceEntriesViewAggrid(aggridInput: $aggridInput) {
      results {
        id
        created_date
        scanned_date
        posted_date
        source_warehouse_id
        source_warehouse_unique_id
        source_warehouse_name
        source_bin_location_id
        source_bin_location_name
        destination_warehouse_id
        destination_warehouse_unique_id
        destination_warehouse_name
        destination_bin_location_id
        destination_bin_location_name
        item_unique_id
        seller_sku
        item_name
        lot_date
        serial_no
        barcode
        finished_qty
        qty
        uom
        type
        reference_unique_id
        scanned_by
        destination_scanned_by
        scanned_by_name
        destination_scanned_by_name
        scanned_by_img_url
        destination_scanned_by_img_url
      }
      count
    }
  }
`;
