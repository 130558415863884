import { useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Chip,
  useMediaQuery,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomizedLetterAvatar, {
  stringAvatar,
} from "../Custom/CustomizedLetterAvatar";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  "&.MuiChip-avatar": {
    color: "#FFFFFF",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }: any) => ({
  "&.Mui-disabled": {
    opacity: 1,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

const StyledBadge = styled(Badge)(({ theme }: any) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: "-1px",
      left: "-1px",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const UserBadge = ({
  id,
  first_name,
  last_name,
  img_url,
  department,
  onLogout,
}: any) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorPos, setAnchorPos] = useState(null);
  const showActions = Boolean(anchorPos);

  const navigate = useNavigate();
  const fullName = `${first_name} ${last_name}`;

  const openActionsHandler = (event: any) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const navigateToEditUser = () => {
    closeActionsHandler();
    navigate(`/user/setting/${id}?tab=inventory`);
  };

  const logoutHandler = () => {
    closeActionsHandler();
    onLogout();
  };

  return (
    <>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
        disableAutoFocusItem
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <StyledMenuItem onClick={navigateToEditUser}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {img_url && img_url.length > 0 ? (
              <Avatar src={img_url[0]} />
            ) : (
              <CustomizedLetterAvatar name={fullName} />
            )}
            <Box>
              <Typography fontWeight={600}>{fullName}</Typography>
              <Typography variant="body2">{department}</Typography>
            </Box>
          </Box>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={navigateToEditUser}>
          <ListItemIcon>
            <ManageAccountsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{t("user.setting.index")}</Typography>
          </ListItemText>
        </StyledMenuItem>
        <StyledMenuItem data-testid="user-menu-logout" onClick={logoutHandler}>
          <ListItemIcon>
            <FileUploadOutlinedIcon
              sx={{ transform: "rotate(90deg)" }}
              fontSize="small"
            />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{"ออกจากระบบ"}</Typography>
          </ListItemText>
        </StyledMenuItem>
      </Menu>
      {isSmallScreen ? (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
          onClick={openActionsHandler}
        >
          {img_url && img_url.length > 0 ? (
            <Avatar
              alt={fullName}
              src={img_url && img_url.length > 0 ? img_url[0] : ""}
            />
          ) : (
            <StyledAvatar {...stringAvatar(fullName)} />
          )}
        </StyledBadge>
      ) : (
        <Chip
          avatar={
            img_url && img_url.length > 0 ? (
              <Avatar alt={fullName} src={img_url[0]} />
            ) : (
              <StyledAvatar {...stringAvatar(fullName)} />
            )
          }
          label={fullName}
          variant="outlined"
          onClick={openActionsHandler}
          data-testid="user-menu"
        />
      )}
    </>
  );
};

export default UserBadge;
