import { useEffect, useState } from "react";
import { IHeaderTable } from "types/global";

export const useDeliveryTripListHeader = (
  disabled: boolean,
  status?: string
) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "",
        label: "No.",
        width: 50,
      },
      {
        thaiLabel: "เลขที่เอกสาร",
        label: "Delivery Trip Document ID",
        width: 150,
        align: "left",
      },
      {
        thaiLabel: "วันที่ออกเอกสาร",
        label: "Issue Date",
        width: 150,
        align: "left",
      },
      {
        thaiLabel: "ทะเบียนรถ",
        label: "License Plate",
        width: 100,
        align: "left",
      },
      {
        thaiLabel: "ประเภทรถ",
        label: "Vehicle Type",
        width: 100,
        align: "left",
      },
      {
        thaiLabel: "สถานที่ต้นทาง",
        label: "Source Location",
        width: 150,
        align: "left",
        required: true,
      },
      {
        thaiLabel: "สถานที่ปลายทาง",
        label: "Destination Location",
        width: 150,
        align: "left",
        required: true,
      },
      {
        thaiLabel: "มูลค่ารวมสุทธิ",
        label: "Net Amount",
        width: 150,
        align: "right",
        required: true,
      },
      {
        thaiLabel: "มูลค่าใบงานจัดส่ง",
        label: "Delivery Trip Net Amount",
        width: 150,
        align: "right",
      },
      {
        thaiLabel: "มูลค่าคงเหลือ",
        label: "Remaining Amount",
        width: 150,
        align: "right",
      },
    ];

    if (
      [null, undefined, "", "draft", "cancelled", "wait_payment"].includes(
        status
      )
    ) {
      if (!disabled)
        setHeaders([
          ...defaultHeaders,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      else setHeaders(defaultHeaders);
    } else {
      setHeaders(defaultHeaders);
    }
  }, [disabled, status]);

  return headers;
};
