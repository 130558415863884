import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useDisable } from "hooks/use-disable";

import { FormControlLabel, Grid, Typography } from "@mui/material";

import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedSwitch from "components/Custom/CustomizedSwitch";
import ControlledTextField from "components/Controller/ControlledTextField";

const TireStatus = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const status = watch("status");

  const is_active = watch("is_active");

  return (
    <CustomizedBox margin={"0 0 0 0"}>
      <Typography fontWeight={600}>{t("status.index")}</Typography>
      <Controller
        control={control}
        name="is_active"
        render={({ field }) => (
          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <CustomizedSwitch
                {...field}
                sx={{ ml: 2, mr: 1 }}
                checked={field.value}
                color="primary"
              />
            }
            disabled={disabled || status === "active"}
            label={t(`status.${is_active ? "active" : "in_active"}`)}
          />
        )}
      />
      {!is_active && (
        <Grid container mt={2}>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="remark"
              control={control}
              label={t("logistic.tire.remark")}
              error={Boolean(errors?.remark)}
              helperText={errors?.remark && errors?.remark.message?.toString()}
              viewMode={Boolean(id) && disabled}
              required
            />
          </Grid>
        </Grid>
      )}
    </CustomizedBox>
  );
};

export default TireStatus;
