import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  th: {
    translation: {
      report: "รายงาน",
      record: "รายการ",
      document_info: "ข้อมูลเอกสาร",
      button: {
        trigger: "trigger",
        back: "ย้อนกลับ",
        add: "เพิ่ม",
        delete: "ลบ",
        create: "สร้าง",
        edit: "แก้ไข",
        accept: "ตอบรับ",
        export: "นำออกข้อมูล",
        import: "นำเข้าข้อมูล",
        download: "ดาวน์โหลด Template",
        filter: "กรองข้อมูล",
        reset_filter: "ล้างตัวกรอง",
        save: "บันทึก",
        save_draft: "บันทึกร่าง",
        confirm: "ยืนยัน",
        cancel: "ยกเลิก",
        cancel_pending_scan: "ยกเลิกจำนวนที่ค้าง",
        choose: "เลือก",
        option: "ตัวเลือก",
        next: "ถัดไป",
        send: "ส่ง",
        not_approve: "ไม่อนุมัติ",
        approve: "อนุมัติ",
        in_transit: "ระหว่างทาง",
        transfer: "โอนย้าย",
        adjust: "ปรับปรุง",
        print_document: "พิมพ์เอกสาร",
        print: "พิมพ์",
        partially_ordered: "สั่งซื้อแล้วบางส่วน",
        fully_ordered: "สั่งซื้อแล้ว",
        select_item: "เลือกสินค้า",
        add_item: "เพิ่มสินค้า",
        add_record: "เพิ่มรายการ",
      },
      global: {
        all: "ทั้งหมด",
        billing_address: "ที่อยู่จดทะเบียน",
        picture: "รูปภาพ",
        price: "ราคา",
        special_price: "ราคาพิเศษ",
        phone_number: "เบอร์โทรศัพท์",
        email: "Email",
        fax: "Fax",
        currency: "สกุลเงิน",
        date_range: "กำหนดช่วงวันที่",
        tag_list: "การจัดกลุ่ม",
      },
      sentence: {
        choose_customer: "เลือกลูกค้า",
        choose_vendor: "เลือกผู้ขาย",
        choose_requestor: "เลือกผู้ขอ",
        deliver_location: "ที่อยู่จัดส่ง",
        detail: "รายละเอียด",
        unique_id: "เลขที่เอกสาร",
        attachment_list: "เอกสารที่เกี่ยวข้อง",
        type: "ประเภท",
        reference_unique_id: "เอกสารอ้างอิง",
        remark: "หมายเหตุ",
        remark_status: "หมายเหตุสถานะ",
        remark_document: "หมายเหตุเอกสาร",
        change_status: "เปลี่ยนสถานะ",
        created_by: "ผู้สร้าง",
        edit: "แก้ไข",
        copy_document_title: "ยืนยันหากต้องการคัดลอกเอกสาร",
        delete_document_title: "ยืนยันหากต้องการยกเลิกเอกสาร",
        delete_document_message: "หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้",
        continue: "ดำเนินการต่อ",
      },
      date: {
        range: "ช่วงเวลา",
        from: "ตั้งแต่",
        to: "จนถึง",
        start_date: "วันที่เริ่ม",
        end_date: "วันที่สิ้นสุด",
        created_date: "วันที่สร้าง",
        issue_date: "วันที่ออกเอกสาร",
        due_date: "วันครบกำหนด",
        to_date: "ใช้ได้ถึง",
        purchase_expected_date: "วันประมาณการณ์",
        purchase_within_date: "ต้องการภายในวันที่",
        purchase_due_date: "วันครบกำหนดชำระ",
        expect_date: "ต้องการภายในวันที่",
        delivery_date: "วันกำหนดส่งของ",
        received_date: "วันที่นำเข้า",
        returned_date: "วันที่รับคืน",
        issued_date: "วันที่นำออก",
        transfered_date: "วันที่โอนย้าย",
        adjusted_date: "วันที่ปรับปรุง",
        inactive_date: "วันที่หยุดใช้งาน",
        scanned_date: "วันที่สแกน",
        opened_date: "วันที่เปิด",
        lot_date: "Lot",
        manufactured_date: "วันที่ผลิต",
        expired_date: "วันที่หมดอายุ",
        last_updated_date: "วันที่อัปเดต",
        new_last_updated_date: "วันที่แก้ไขล่าสุด",
      },
      status: {
        index: "สถานะ",
        all: "ทั้งหมด",
        draft: "ร่าง",
        finished: "เสร็จสิ้น",
        cancelled: "ยกเลิก",
        wait_accept: "รอตอบรับ",
        wait_issue: "รอนำออก",
        accepted: "ตอบรับแล้ว",
        not_accepted: "ไม่ได้รับการตอบรับ",
        wait_approve: "รออนุมัติ",
        approved: "อนุมัติแล้ว",
        not_approved: "ไม่อนุมัติ",
        wait_payment: "รอชำระ",
        partially_payment: "ชำระแล้วบางส่วน",
        fully_payment: "ชำระแล้ว",
        expired: "เกินเวลา",
        completed: "สำเร็จ",
        not_completed: "ไม่สำเร็จ",
        active: "ใช้งาน",
        in_active: "หยุดใช้งาน",
        wait_ordered: "รอสั่งซื้อ",
        partially_ordered: "สั่งซื้อแล้วบางส่วน",
        fully_ordered: "สั่งซื้อแล้ว",
        partially_imported: "นำเข้าแล้วบางส่วน",
        fully_imported: "นำเข้าแล้ว",
        wait_returned: "รอส่งคืน",
        wait_deliver: "รอวิ่งงาน",
        delivering: "กำลังขนส่ง",
        wait_previous_jobs: "รองานอื่น",
        insufficient_ingredient: "รอส่วนประกอบ",
        ready: "พร้อมผลิต",
        manufacturing: "กำลังผลิต",
        is_main_bom: "ใช้เป็นสูตรหลัก",
        pending_manu: "รอผลิต",
        wait_transfer: "รอโอนย้าย",
        late: "เกินกำหนด",
        wait_receive: "รอนำเข้า",
        in_transit: "ระหว่างทาง",
        success: "สำเร็จ",
        failed: "ไม่สำเร็จ",
        is_active: "รอสแกน",
        is_scanned: "สแกนแล้ว",
        wait_maintenance: "รอซ่อม",
        maintenancing: "ระหว่างซ่อม",
      },
      warehouse: {
        index: "คลัง",
        unique_id: "รหัสคลัง",
        destination: "คลังปลายทาง",
        destination_unique_id: "รหัสคลังปลายทาง",
        source: "คลังปลายทาง",
        source_unique_id: "รหัสคลังปลายทาง",
        bin_location: {
          index: "สถานที่",
        },
      },
      price: {
        price_per_unit: "ราคา/หน่วย",
        discount: "ส่วนลด",
        vat_percentage: "ภาษี (%)",
        pre_vat_amount: "จำนวนเงิน",
        withholding_tax_type: "หัก ณ ที่จ่าย (%)",
        additional_discount: "ส่วนลดเพิ่มเติม",
        shipping_cost: "ค่าส่ง",
        post_discount_amount: "มูลค่าหลังหักส่วนลด",
        vat_exempted_amount: "มูลค่ารายการยกเว้นภาษี",
        vat_0_percent_amount: "มูลค่ารายการภาษี 0%",
        vat_7_percent_amount: "มูลค่ารายการภาษี 7%",
        vat_amount: "ภาษีมูลค่าเพิ่มรวม",
        net_amount: "มูลค่ารวมสุทธิ",
        withholding_tax_amount: "ภาษีหัก ณ ที่จ่าย",
        total_amount: "จำนวนเงินชำระทั้งสิ้น",
      },
      snack_bar: {
        purchase_order: {
          create: {
            success: "สร้างใบสั่งซื้อสำเร็จ",
            fail: "สร้างใบสั่งซื้อไม่สำเร็จ",
          },
          cancel: {
            success: "ยกเลิกใบสั่งซื้อสำเร็จ",
            fail: "ยกเลิกใบสั่งซื้อไม่สำเร็จ",
          },
          cancelPOQty: {
            success: "ยกเลิกจำนวนคงค้างสำเร็จ",
            fail: "ยกเลิกจำนวนคงค้างไม่สำเร็จ",
          },
        },
        purchase_return: {
          create: {
            success: "สร้างใบส่งคืนสำเร็จ",
            fail: "สร้างใบส่งคืนไม่สำเร็จ",
          },
          cancel: {
            success: "ยกเลิกใบส่งคืนสำเร็จ",
            fail: "ยกเลิกใบส่งคืนไม่สำเร็จ",
          },
          finished: {
            success: "เปลี่ยนสถานะสำเร็จ",
            fail: "เปลี่ยนสถานะเอกสารไม่สำเร็จ",
          },
          draft: {
            success: "บันทึกร่างสำเร็จ",
            fail: "บันทึกร่างไม่สำเร็จ",
          },
        },
        vehicle: {
          create: {
            success: "สร้างรถสำเร็จ",
            fail: "สร้างรถไม่สำเร็จ",
          },
          update: {
            success: "แก้ไขข้อมูลรถสำเร็จ",
            fail: "แก้ไขข้อมูลรถไม่สำเร็จ",
          },
          delete: {
            success: "ลบรถสำเร็จ",
            fail: "ไม่สามารถลบได้ เนื่องจากถูกใช้ในเอกสารที่กำลังดำเนินการ",
          },
          inactivate: {
            success: "หยุดใช้งานรถสำเร็จ",
            fail: "ไม่สามารถหยุดใช้งานได้ เนื่องจากถูกใช้ในเอกสารที่กำลังดำเนินการ",
          },
        },
        tire: {
          create: {
            success: "สร้างยางสำเร็จ",
            fail: "สร้างยางไม่สำเร็จ",
          },
          update: {
            success: "แก้ไขข้อมูลยางสำเร็จ",
            fail: "แก้ไขข้อมูลยางไม่สำเร็จ",
          },
          delete: {
            success: "ลบยางสำเร็จ",
            fail: "ลบยางไม่สำเร็จ",
            fail_document: "ไม่สามารถลบได้ เนื่องจากถูกอ้างอิงในเอกสาร",
          },
          inactivate: {
            success: "หยุดใช้งานยางสำเร็จ",
            fail: "หยุดใช้งานยางไม่สำเร็จ",
            fail_document:
              "ไม่สามารถหยุดใช้งานได้ เนื่องจากถูกใช้ในเอกสารที่กำลังดำเนินการ",
          },
        },
      },
      user: require("./user.json"),
      setting: require("./setting.json"),
      inventory: require("./inventory.json"),
      contact: require("./contact.json"),
      address: require("./address.json"),
      sales: require("./sales.json"),
      purchase: require("./purchase.json"),
      logistic: require("./logistic.json"),
      manufacture: require("./manufacture.json"),
      project: require("./project.json"),
      reports: require("./report.json"),
    },
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "th",
  fallbackLng: "th",
  resources,
  returnNull: false,
});
