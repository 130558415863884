import { useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { ITab } from "types/global";
import { Box, Typography } from "@mui/material";
import CustomizedTab from "components/Custom/CustomizedTab";
import ItemCurrentStockByWarehouseTable from "components/Table/Inventory/Item/ItemCurrentStock/ByWarehouse";
import ItemCurrentStockByBinLocationTable from "components/Table/Inventory/Item/ItemCurrentStock/ByBinLocation";
import ItemCurrentStockByBarcodeTable from "components/Table/Inventory/Item/ItemCurrentStock/ByBarcode";
import { CustomizedBox } from "components/Custom/CustomizedBox";

const CurrentStocktab = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const subtab = searchParams.get("subtab");
  const { pathname } = useLocation();

  const watchItemUniqueId = watch("unique_id");
  const watchTraceability = watch("tracability");

  const tabs: ITab[] = useMemo(
    () =>
      [
        {
          label: t("inventory.item.stock_qty_by_warehouse"),
          path: `${pathname}?tab=current-stock&subtab=by-warehouse`,
        },
        {
          label: t("inventory.item.stock_qty_by_location"),
          path: `${pathname}?tab=current-stock&subtab=by-location`,
        },
        watchTraceability !== "normal"
          ? {
              label: t("inventory.item.stock_qty_by_serial_no"),
              path: `${pathname}?tab=current-stock&subtab=by-serial-no`,
            }
          : undefined,
      ].filter(Boolean) as ITab[],
    [pathname, watchTraceability, t]
  );

  const renderSubTabTitles = () => {
    switch (subtab) {
      case "by-warehouse":
        return t("inventory.item.stock_qty_by_warehouse");
      case "by-location":
        return t("inventory.item.stock_qty_by_location");
      case "by-serial-no":
        return t("inventory.item.stock_qty_by_serial_no");
      default:
        return t("inventory.item.stock_qty_by_warehouse");
    }
  };

  const renderSubTabTables = () => {
    switch (subtab) {
      case "by-warehouse":
        return (
          <ItemCurrentStockByWarehouseTable itemUniqueId={watchItemUniqueId} />
        );
      case "by-location":
        return (
          <ItemCurrentStockByBinLocationTable
            itemUniqueId={watchItemUniqueId}
          />
        );
      case "by-serial-no":
        return (
          <ItemCurrentStockByBarcodeTable itemUniqueId={watchItemUniqueId} />
        );
      default:
        return (
          <ItemCurrentStockByWarehouseTable itemUniqueId={watchItemUniqueId} />
        );
    }
  };

  const currentTab =
    pathname + (tab ? `?tab=${tab}` : "") + (subtab ? `&subtab=${subtab}` : "");

  return (
    <>
      <Box mt={2}>
        <CustomizedTab
          tabs={tabs}
          currentTab={currentTab}
          table
          noBottomDivider
        />
      </Box>
      <CustomizedBox margin={"0"}>
        <Typography variant="h6" mb={2}>
          {renderSubTabTitles()}
        </Typography>
        {renderSubTabTables()}
      </CustomizedBox>
    </>
  );
};

export default CurrentStocktab;
