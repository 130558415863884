import { gql } from "graphql-request";

export const PURCHASE_ORDER_VIEW = gql`
  query PurchaseOrderViewsAggrid($aggridInput: AnyAggridInput) {
    PurchaseOrderViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        created_date
        last_updated_date
        issue_date
        expected_date
        within_date
        due_date
        supplier_unique_id
        supplier_name
        net_amount
        reference_no
        remark
        destination_warehouse_id
        destination_warehouse_unique_id
        destination_warehouse_name
        created_by
        created_by_object
        related_user_list
        related_user_object_list
        reference_document_list
        reference_document_object_list
        item_list
        status
      }
    }
  }
`;

export const PURCHASE_RETURN_VIEW = gql`
  query PurchaseReturnViewsAggrid($aggridInput: AnyAggridInput) {
    PurchaseReturnViewsAggrid(aggridInput: $aggridInput) {
      results {
        id
        unique_id
        created_date
        last_updated_date
        issue_date
        delivery_date
        supplier_unique_id
        supplier_name
        reason_to_return
        net_amount
        reference_no
        remark
        created_by
        created_by_object
        related_user_list
        related_user_object_list
        reference_document_list
        reference_document_object_list
        item_list
        status
      }
      count
    }
  }
`;
