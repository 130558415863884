import { gql } from "graphql-request";

export const ITEM_VIEW = gql`
  query ItemsViewAggrid($aggridInput: AnyAggridInput!) {
    ItemsViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        img_url_list
        name
        seller_sku
        description
        type
        barcode
        stock_qty
        available_qty
        tag_list
        cate_1
        cate_2
        cate_3
        is_active
        stock_uom_id
        stock_uom_name
        tracability
        hidden_barcode
        purchase_price
        min_purchase_request_qty
        purchase_vat_type
        sale_price
        sale_vat_type
        uom_conversion_list
        special_price
        sales_uom_id
        sales_uom_name
        purchase_uom_id
        purchase_uom_name
        is_purchasable
        is_saleable
      }
    }
  }
`;
