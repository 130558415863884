import { Box, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import { useDisable } from "hooks/use-disable";
import { FieldError, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import CheckedVehicleModal from "components/UI/Modal/CheckedModal/CheckedVehicleModal";
import CheckedTrailerModal from "components/UI/Modal/CheckedModal/CheckedTrailerModal";
import { useConfirmation } from "hooks/use-confirmation";
import VehicleConfirmation from "components/UI/Modal/VehicleConfirmation";
import { useEffect, useState } from "react";
import { IMaintenance } from "types/Logistic/maintenance";
import { useMaintenanceModal } from "hooks/Logistic/Maintenance/use-maintenance-modal";
import ControlledSSCreatable from "components/Controller/ControlledSSCreatable";
import ButtonLayout from "components/UI/ButtonLayout";
import CustomizedButton from "components/Custom/CustomizedButton";

const MaintenanceVehicle = () => {
  const {
    control,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useFormContext<IMaintenance>();

  const [vehicleData, setVehicleData] = useState<any>();

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const cancelled = flagStatus.includes("cancelled");

  const status = !cancelled ? mainStatus : aggrid_status;

  const [disabled] = useDisable();

  const { t } = useTranslation();

  const watchtrailerId = useWatch({
    control,
    name: "trailer_id",
  });

  const watchHasTrailer = useWatch({
    control,
    name: "has_trailer",
  });

  useEffect(() => {
    setValue(
      "has_trailer",
      watchtrailerId !== undefined && watchtrailerId !== null
    );
  }, [setValue, watchtrailerId]);

  const { vehicle, trailer } = useMaintenanceModal();

  const vehicleChangeHandler = async (vehicleData: any) => {
    if (vehicleData) {
      const { id, unique_id, register_id, brand, model, current_kilometer } =
        vehicleData;
      const vehicleObject = {
        vehicle_no: unique_id,
        license_plate: register_id,
        brand,
        model,
        current_kilometer,
      };
      reset((prev) => ({
        ...prev,
        vehicle_id: id,
        vehicle_details: vehicleObject,
      }));
    } else {
      reset((prev) => ({
        ...prev,
        vehicle_id: undefined,
        vehicle_details: {
          vehicle_no: "",
          license_plate: "",
          brand: "",
          model: "",
          current_kilometer: "",
        },
      }));
    }
  };

  const finishVehiclesSelect = async (vehicleData: any) => {
    await setVehicleData(vehicleData);
    await vehicleChangeHandler(vehicleData);
  };

  const finishTrailersSelect = async (data: any) => {
    if (data) {
      const { id, unique_id, register_id, brand, model, current_kilometer } =
        data;
      const trailerObject = {
        vehicle_no: unique_id,
        license_plate: register_id,
        brand,
        model,
        current_kilometer,
      };
      reset((prev) => ({
        ...prev,
        has_trailer: true,
        trailer_id: id,
        trailer_details: trailerObject,
      }));
    } else {
      reset((prev) => ({
        ...prev,
        has_trailer: false,
        trailer_id: undefined,
        trailer_details: undefined,
      }));
    }
  };

  const {
    confirmation: vehicleConfirmation,
    // openConfirmationHandler: openVehicleConfirmation,
    closeConfirmationHandler: closeVehicleConfirmation,
    submitConfirmationHandler: submitVehicleConfirmation,
  } = useConfirmation(async () => await vehicleChangeHandler(vehicleData));

  const disabledStatus = ["finished"].includes(status);

  return (
    <>
      <CustomizedBox>
        <Box display={"flex"} alignItems={"center"} mb={1} gap={1}>
          <Typography fontWeight={600}>
            {t("logistic.maintenance.vehicle.index")}
          </Typography>
          {!disabled && !disabledStatus && (
            <CustomizedTooltip title="เลือกรถ">
              <IconButton
                onClick={vehicle.openModalHandler}
                color="primary"
                size="small"
              >
                <EditIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Box>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              label={t("logistic.maintenance.vehicle.id")}
              control={control}
              name="vehicle_details.vehicle_no"
              error={Boolean(errors?.vehicle_details?.vehicle_no)}
              helperText={errors?.vehicle_details?.vehicle_no?.message}
              required
              disabled
              viewMode={disabled || disabledStatus}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              label={t("logistic.maintenance.vehicle.license_plate")}
              control={control}
              name="vehicle_details.license_plate"
              error={Boolean(errors?.vehicle_details?.license_plate)}
              helperText={errors?.vehicle_details?.license_plate?.message}
              required
              disabled
              viewMode={disabled || disabledStatus}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              label={t("logistic.maintenance.vehicle.brand")}
              control={control}
              name="vehicle_details.brand"
              error={Boolean(errors?.vehicle_details?.brand)}
              helperText={errors?.vehicle_details?.brand?.message}
              disabled
              viewMode={disabled || disabledStatus}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              control={control}
              name="vehicle_details.model"
              label={t("logistic.maintenance.vehicle.model")}
              error={Boolean(errors?.vehicle_details?.model)}
              helperText={errors?.vehicle_details?.model?.message}
              disabled
              viewMode={disabled || disabledStatus}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledNumberTextField
              fullWidth
              control={control}
              name="vehicle_details.current_kilometer"
              error={Boolean(errors?.vehicle_details?.current_kilometer)}
              helperText={errors?.vehicle_details?.current_kilometer?.message}
              label={t("logistic.maintenance.vehicle.current_kilometer")}
              viewMode={disabled || disabledStatus}
              decimalScale={0}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledSSCreatable
              control={control}
              name="vehicle_details.container_no"
              setValue={setValue}
              defaultOptions={[]}
              documentType="maintenance"
              label={t("logistic.maintenance.vehicle.container_no")}
              placeholder={t("logistic.maintenance.vehicle.container_no")}
              error={Boolean(errors.vehicle_details?.container_no)}
              helperText={errors.vehicle_details?.container_no?.message}
              viewMode={disabled || disabledStatus}
              // disabled={isFinished}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledSSCreatable
              label={t("logistic.maintenance.vehicle.engine_gen_no")}
              placeholder={t("logistic.maintenance.vehicle.engine_gen_no")}
              control={control}
              name="vehicle_details.engine_no"
              setValue={setValue}
              defaultOptions={[]}
              documentType="maintenance"
              error={Boolean(errors.vehicle_details?.engine_gen_no)}
              helperText={errors.vehicle_details?.engine_gen_no?.message}
              viewMode={disabled || disabledStatus}
              // disabled
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      {watchHasTrailer && (
        <CustomizedBox>
          <Box display={"flex"} alignItems={"center"} mb={1} gap={1}>
            <Box display={"flex"} alignItems={"center"} gap={1} flexGrow={1}>
              <Typography fontWeight={600}>
                {t("logistic.maintenance.trailer.index")}
              </Typography>
              {!disabled && !disabledStatus && (
                <CustomizedTooltip title="เลือกรถ">
                  <IconButton
                    onClick={trailer.openModalHandler}
                    color="primary"
                    size="small"
                  >
                    <EditIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Box>
            {!disabled && !disabledStatus && (
              <CustomizedTooltip title="ลบหางลาก">
                <CloseIcon
                  fontSize="medium"
                  onClick={() => finishTrailersSelect(undefined)}
                />
              </CustomizedTooltip>
            )}
          </Box>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledTextField
                label={t("logistic.maintenance.trailer.vehicle_no")}
                control={control}
                name="trailer_details.vehicle_no"
                error={Boolean(errors?.trailer_details?.vehicle_no)}
                helperText={
                  (errors?.trailer_details?.vehicle_no as FieldError)?.message
                }
                required
                disabled
                viewMode={disabled || disabledStatus}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledTextField
                label={t("logistic.delivery_trip.trailer.license_plate")}
                control={control}
                name="trailer_details.license_plate"
                error={Boolean(errors?.trailer_details?.license_plate)}
                helperText={errors?.trailer_details?.license_plate?.message}
                required
                disabled
                viewMode={disabled || disabledStatus}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledTextField
                label={t("logistic.maintenance.trailer.brand")}
                control={control}
                name="trailer_details.brand"
                error={Boolean(errors?.trailer_details?.brand)}
                helperText={errors?.trailer_details?.brand?.message}
                disabled
                viewMode={disabled || disabledStatus}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledTextField
                control={control}
                name="trailer_details.model"
                label={t("logistic.maintenance.trailer.model")}
                error={Boolean(errors?.trailer_details?.model)}
                helperText={errors?.trailer_details?.model?.message}
                disabled
                viewMode={disabled || disabledStatus}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledNumberTextField
                fullWidth
                control={control}
                name="trailer_details.current_kilometer"
                error={Boolean(errors?.trailer_details?.current_kilometer)}
                helperText={errors?.trailer_details?.current_kilometer?.message}
                label={t("logistic.maintenance.trailer.current_kilometer")}
                viewMode={disabled || disabledStatus}
                decimalScale={0}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledSSCreatable
                control={control}
                name="trailer_details.container_no"
                setValue={setValue}
                defaultOptions={[]}
                documentType="maintenance"
                label={t("logistic.maintenance.trailer.container_no")}
                placeholder={t("logistic.maintenance.trailer.container_no")}
                error={Boolean(errors.trailer_details?.container_no)}
                helperText={errors.trailer_details?.container_no?.message}
                viewMode={disabled || disabledStatus}
                // disabled={isFinished}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledSSCreatable
                label={t("logistic.maintenance.trailer.engine_gen_no")}
                placeholder={t("logistic.maintenance.trailer.engine_gen_no")}
                control={control}
                name="trailer_details.engine_no"
                setValue={setValue}
                defaultOptions={[]}
                documentType="maintenance"
                error={Boolean(errors.trailer_details?.engine_gen_no)}
                helperText={errors.trailer_details?.engine_gen_no?.message}
                viewMode={disabled || disabledStatus}
                // disabled
              />
            </Grid>
          </Grid>
        </CustomizedBox>
      )}
      {!watchHasTrailer && !disabled && (
        <ButtonLayout>
          <CustomizedButton
            title={"เพิ่มหางลาก"}
            variant="outlined"
            onClick={() => {
              setValue("has_trailer", true);
            }}
            startAddIcon
          />
        </ButtonLayout>
      )}
      <CheckedVehicleModal
        showSelectVehicle={vehicle.modal}
        vehicleIds={vehicle.ids}
        setVehicleIds={vehicle.setIdsSnapshot}
        vehicleIdsSnapshot={vehicle.idsSnapshot}
        setVehicleIdsSnapshot={vehicle.setIdsSnapshot}
        rowSelection="single"
        closeVehicleTable={vehicle.closeModalHandler}
        finishVehiclesSelect={finishVehiclesSelect}
        disableTrailer
      />
      <CheckedTrailerModal
        showSelectTrailer={trailer.modal}
        trailerId={trailer.ids}
        setTrailerId={trailer.setIdsSnapshot}
        trailerIdSnapshot={trailer.idsSnapshot}
        setTrailerIdSnapshot={trailer.setIdsSnapshot}
        closeTrailerTable={trailer.closeModalHandler}
        finishTrailerSelect={finishTrailersSelect}
        trailerOnly={true}
      />
      <VehicleConfirmation
        confirmation={vehicleConfirmation}
        closeConfirmationHandler={closeVehicleConfirmation}
        submitConfirmationHandler={submitVehicleConfirmation}
      />
    </>
  );
};

export default MaintenanceVehicle;
