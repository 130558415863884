import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

import { useModal } from "hooks/use-modal";
import { useDisable } from "hooks/use-disable";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { AgGridReact } from "ag-grid-react";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import ControlledSelect from "components/Controller/ControlledSelect";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import ButtonLayout from "components/UI/ButtonLayout";
import CustomizedButton from "components/Custom/CustomizedButton";
import Confirmation from "components/UI/Confirmation";

import PreVatAmountCell from "./PreVatAmountCell";
import ItemNameCell from "./ItemNameCell";
import WithholdingTaxCell from "./WithholdingTaxCell";
import SumPreVatAmount from "./SumPreVatAmount";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import { SalesModelType } from "generated/sales";
import { IItem } from "types/Inventory/item";
import { ISelectOption } from "types/global";

import {
  numberFormatter,
  priceNumberFormatter,
  priceVatTypeFormatter,
  vatTypeFormatter,
} from "utils/Formatter/Global";
import { IMaintenance, IMaintenanceItem } from "types/Logistic/maintenance";
import ControlledTextField from "components/Controller/ControlledTextField";
import { useMaintenanceItemListHeader } from "hooks/Sales/use-maintenance-item-list-header";
import LabelInput from "components/UI/LabelInput";
import { PriceVatType } from "generated/purchase";
import PurchaseItemTableModal from "components/UI/Modal/CheckedModal/PurchaseItemTableModal";

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0 %",
  },
  {
    label: "7 %",
    value: "7 %",
  },
];

const priceVatTypeOptions: ISelectOption[] = [
  {
    label: "ราคารวมภาษี",
    value: PriceVatType.IncludedVat,
  },
  {
    label: "ราคาแยกภาษี",
    value: PriceVatType.ExcludedVat,
  },
];

interface Props {
  documentType: SalesModelType;
}

const MaintenanceItemList = ({ documentType }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IMaintenance>();
  const [disabled] = useDisable();
  const status = useWatch({ control, name: "aggrid_status" });
  const isFinished = status === "finished";
  const headers = useMaintenanceItemListHeader(documentType, disabled, status);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  // const [itemIds, setItemIds] = useState<string[]>([]);
  // const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);

  const { fields, move, remove, append } = useFieldArray({
    control,
    name: "ma_item_list",
    keyName: "unique_id",
  });

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = () => {
    if (deletedIndex || deletedIndex === 0) {
      remove(deletedIndex);
      // setItemIds((prev) => prev.filter((_, index) => index !== deletedIndex));
      // setItemIdsSnapshot((prev) =>
      //   prev.filter((_, index) => index !== deletedIndex)
      // );
    }
    closeDeleteItemConfirmationHandler();
  };

  const addItemsHandler = () => {
    const selectedItems = gridRef?.current?.api.getSelectedRows();
    if (selectedItems) {
      selectedItems.forEach((item_details: IItem, index) => {
        let formatItems: IMaintenanceItem = {
          id: 0,
          reference_unique_id: getValues().unique_id ?? "",
          indexing: index,
          unique_id: uuidv4(),
          item_id: item_details.id || 0,
          item_unique_id: item_details.unique_id || "",
          item_name: item_details.name || "",
          item_sku_desc: item_details.description,
          qty: 1,
          uom_id: item_details.stock_uom_id ?? 0,
          uom_name: item_details.stock_uom_name ?? "",
          uom_conversion_list: item_details.uom_conversion_list,
          remark: "",
          stock_qty: 1,
          discount: 0,
          pre_discount_amount: 0,
          post_discount_amount: 0,
          price_per_unit: item_details.sale_price ?? 0,
          withholding_tax_type: "ยังไม่ระบุ",
          withholding_tax_value: 0,
          vat_percentage: item_details.sale_vat_type || "ไม่มี",
        };

        append(formatItems);
      });
    }
    closeItemModalHandler();
  };

  const uomOptions = (field: IMaintenanceItem) => {
    const options = (
      field.uom_conversion_list
        ? field.uom_conversion_list.length > 0
          ? [
              {
                label: field.uom_name || "",
                value: field.uom_id || "",
              },
              ...field.uom_conversion_list.map((uom) => ({
                label: uom.target_uom_name,
                value: uom.target_uom_id,
              })),
            ]
          : [
              {
                label: field.uom_name || "",
                value: field.uom_id || "",
              },
            ]
        : []
    ) as ISelectOption[];
    return options;
  };

  return (
    <CustomizedBox
      sx={{ breakAfter: "auto" }}
      margin="1rem 0 0 0"
      maxWidth={1650}
    >
      <Box display="flex" alignItems="center">
        <Typography fontWeight={600}>รายการค่าใช้จ่ายและอะไหล่</Typography>{" "}
        <Typography variant="body1" color="error" sx={{ ml: 0.5 }}>
          *
        </Typography>
      </Box>
      <Grid container mt={0} mb={2} spacing={2}>
        <Grid item sm={12} md={4}>
          {disabled ? (
            <LabelInput
              label={t("purchase.price_vat_type")}
              value={priceVatTypeFormatter(getValues("price_vat_type"))}
            />
          ) : (
            <ControlledSelect
              control={control}
              name="price_vat_type"
              label={t("sales.price_vat_type")}
              options={priceVatTypeOptions}
              error={Boolean(errors.price_vat_type)}
              helperText={errors?.price_vat_type?.message?.toString()}
              disabled={disabled}
              required
            />
          )}
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align="center"
                  key={index}
                  sx={{
                    px: 1,
                    py: 1.5,
                    backgroundColor: "secondary.main",
                  }}
                  width={header.width}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      width: header.width,
                      justifyContent: header.align || "center",
                    }}
                  >
                    <Typography fontSize={14} fontWeight={600}>
                      {header.thaiLabel}
                    </Typography>
                    {header.required && (
                      <Typography
                        fontSize={14}
                        color={"error.main"}
                        fontWeight={600}
                      >
                        *
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" isDropDisabled={disabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <Draggable
                      key={field.unique_id}
                      draggableId={"maintenance-" + field.unique_id}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...provided.draggableProps.style,
                            breakInside: "avoid",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[0]?.width || 0
                                : 0) + 16
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!disabled && (
                                <MenuIcon
                                  fontSize="small"
                                  sx={{ color: "#BDBDBD" }}
                                />
                              )}
                              <Typography fontSize={14}>{index + 1}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[1]?.width || 0
                                : 0) + 16
                            }
                          >
                            <ItemNameCell
                              control={control}
                              errors={errors}
                              field={field}
                              index={index}
                              disabled={disabled}
                              documentType={documentType}
                            />
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[2]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {numberFormatter(field.qty)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`ma_item_list.${index}.qty`}
                                error={Boolean(
                                  errors?.ma_item_list &&
                                    errors?.ma_item_list[index] &&
                                    errors?.ma_item_list[index]?.qty
                                )}
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                    style: { textAlign: "right" },
                                  },
                                }}
                                onChange={(value) => {
                                  const parseValue = value || 0;
                                  const currentUomId = getValues(
                                    `ma_item_list.${index}.uom_id`
                                  );
                                  if (currentUomId) {
                                    if (field.uom_conversion_list) {
                                      if (currentUomId === field.uom_id) {
                                        setValue(
                                          `ma_item_list.${index}.stock_qty`,
                                          parseValue
                                        );
                                      } else {
                                        if (
                                          field.uom_conversion_list &&
                                          field.uom_conversion_list.length > 0
                                        ) {
                                          const conversionUom =
                                            field.uom_conversion_list.find(
                                              (conversion) =>
                                                conversion.target_uom_id ===
                                                currentUomId
                                            );
                                          if (conversionUom) {
                                            const targetUomQty =
                                              conversionUom.base_uom_rate /
                                              conversionUom.target_uom_rate;
                                            setValue(
                                              `ma_item_list.${index}.stock_qty`,
                                              parseValue * targetUomQty
                                            );
                                          }
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[3]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {field.uom_name}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                name={`ma_item_list.${index}.uom_id`}
                                error={Boolean(
                                  errors?.ma_item_list &&
                                    errors?.ma_item_list[index] &&
                                    errors?.ma_item_list[index]?.uom_id
                                )}
                                options={uomOptions(field)}
                                onChange={(e: any) => {
                                  const uom_name = uomOptions(field).find(
                                    (uom) => uom.value === e.target.value
                                  )?.label;
                                  setValue(
                                    `ma_item_list.${index}.uom_name`,
                                    uom_name ?? ""
                                  );
                                  const currentQty = getValues(
                                    `ma_item_list.${index}.qty`
                                  );
                                  if (field.uom_conversion_list) {
                                    if (e.target.value === field.uom_id) {
                                      setValue(
                                        `ma_item_list.${index}.stock_qty`,
                                        currentQty
                                      );
                                    } else {
                                      if (
                                        field.uom_conversion_list &&
                                        field.uom_conversion_list.length > 0
                                      ) {
                                        const conversionUom =
                                          field.uom_conversion_list.find(
                                            (conversion) =>
                                              conversion.target_uom_id ===
                                              e.target.value
                                          );
                                        if (conversionUom) {
                                          const targetUomQty =
                                            conversionUom.base_uom_rate /
                                            conversionUom.target_uom_rate;
                                          setValue(
                                            `ma_item_list.${index}.stock_qty`,
                                            currentQty * targetUomQty
                                          );
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[4]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {priceNumberFormatter(field.price_per_unit)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`ma_item_list.${index}.price_per_unit`}
                                error={Boolean(
                                  errors?.ma_item_list &&
                                    errors?.ma_item_list[index] &&
                                    errors?.ma_item_list[index]?.price_per_unit
                                )}
                                decimalScale={4}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[5]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {priceNumberFormatter(field.discount)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                control={control}
                                name={`ma_item_list.${index}.discount`}
                                error={Boolean(
                                  errors?.ma_item_list &&
                                    errors?.ma_item_list[index] &&
                                    errors?.ma_item_list[index]?.discount
                                )}
                                decimalScale={4}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[6]?.width || 0
                                : 0) + 16
                            }
                          >
                            {disabled ? (
                              <Typography fontSize={14}>
                                {vatTypeFormatter(field.vat_percentage)}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                control={control}
                                name={`ma_item_list.${index}.vat_percentage`}
                                error={Boolean(
                                  errors?.ma_item_list &&
                                    errors?.ma_item_list[index] &&
                                    errors?.ma_item_list[index]?.vat_percentage
                                )}
                                options={vatOptions}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                            width={
                              (headers && headers.length > 0
                                ? headers[7]?.width || 0
                                : 0) + 16
                            }
                          >
                            <PreVatAmountCell
                              control={control}
                              index={index}
                              field={field}
                              disabled={disabled}
                              setValue={setValue}
                            />
                          </TableCell>
                          <TableCell
                            align={headers[8]?.align}
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                          >
                            <Box width={headers[8]?.width}>
                              <WithholdingTaxCell
                                field={field}
                                index={index}
                                disabledStatus={isFinished}
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            align={"right"}
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                          >
                            <Box width={headers[9]?.width}>
                              <ControlledTextField
                                control={control}
                                name={`ma_item_list.${index}.stock_qty`}
                                error={Boolean(
                                  errors?.ma_item_list &&
                                    errors?.ma_item_list[index] &&
                                    errors?.ma_item_list[index]?.stock_qty
                                )}
                                InputProps={{
                                  inputProps: {
                                    style: { textAlign: "center" },
                                  },
                                }}
                                helperText={field.uom_name}
                                uomHelperText={true}
                                viewMode={true}
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            align={"right"}
                            sx={{
                              px: 1,
                              py: 1,
                            }}
                          >
                            <Box width={headers[10]?.width}>
                              <ControlledTextField
                                control={control}
                                name={`ma_item_list.${index}.remark`}
                                error={Boolean(
                                  errors?.ma_item_list &&
                                    errors?.ma_item_list[index] &&
                                    errors?.ma_item_list[index]?.remark
                                )}
                                disabled={disabled}
                              />
                            </Box>
                          </TableCell>
                          {!disabled && (
                            <TableCell
                              align="center"
                              sx={{
                                px: 1,
                                py: 1,
                              }}
                              width={
                                (headers && headers.length > 0
                                  ? headers[11]?.width || 0
                                  : 0) + 16
                              }
                            >
                              {field.item_id !== 157 &&
                                field.item_id !== 158 && (
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                    onClick={() =>
                                      openDeleteItemConfirmationHandler(index)
                                    }
                                  >
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                )}
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {!disabled && (
        <ButtonLayout sx={{ marginTop: 2 }}>
          <CustomizedButton
            title={t("button.add_record")}
            variant="outlined"
            onClick={openItemModalHandler}
            startAddIcon
          />
        </ButtonLayout>
      )}
      <SumPreVatAmount control={control} />
      <Confirmation
        title="ยืนยันการลบสินค้า"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
      <PurchaseItemTableModal
        ref={gridRef}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
      />
    </CustomizedBox>
  );
};

export default MaintenanceItemList;
