import { ColDef, ICellRendererParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useWarehousesFindAllQuery,
  WarehousesFindAllQuery,
} from "generated/general";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import CustomizedStatus from "components/Custom/CustomizedStatus";

export const useStockReportColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } = useWarehousesFindAllQuery<WarehousesFindAllQuery>(
    graphQLClient,
    {},
    {
      enabled: false,
    }
  );

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: t("inventory.item.unique_id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "seller_sku",
        headerName: t("inventory.item.sku"),
        filter: "agTextColumnFilter",
        hide: true,
        valueFormatter: (params) => (params.value ? params.value : "-"),
      },
      {
        field: "name",
        headerName: t("inventory.item.name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "warehouse_unique_id",
        headerName: t("inventory.item.warehouse.warehouse_id"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: warehousesResult } = await refetch();
            const values = warehousesResult?.WarehousesFindAll;
            const formatValues = values?.map(
              (warehouse) => warehouse.unique_id
            );
            params.success(formatValues);
          },
        },
        hide: true,
      },
      {
        field: "warehouse_name",
        headerName: t("inventory.item.warehouse.index"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: warehousesResult } = await refetch();
            const values = warehousesResult?.WarehousesFindAll;
            const formatValues = values?.map((warehouse) => warehouse.name);
            params.success(formatValues);
          },
        },
      },
      {
        field: "available_qty",
        headerName: t("inventory.item.available_qty"),
        filter: "agNumberColumnFilter",
        cellStyle: { display: "flex", justifyContent: "flex-end" },
      },
      {
        field: "stock_qty",
        headerName: t("inventory.item.stock_qty"),
        filter: "agNumberColumnFilter",
        cellStyle: { display: "flex", justifyContent: "flex-end" },
      },
      {
        field: "min_purchase_request_qty",
        headerName: "จำนวนสั่งซื้อขั้นต่ำ",
        filter: "agNumberColumnFilter",
        valueFormatter: (params) =>
          params.value !== null && typeof params.value !== "undefined"
            ? params.value.toLocaleString()
            : "-",
        cellStyle: { display: "flex", justifyContent: "flex-end" },
      },
      {
        field: "min_stock_qty",
        headerName: t("inventory.item.stock.min_stock_qty"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params) =>
          params.value !== null && typeof params.value !== "undefined"
            ? params.value.toLocaleString()
            : "-",
        cellStyle: { display: "flex", justifyContent: "flex-end" },
      },
      {
        field: "max_stock_qty",
        headerName: t("inventory.item.stock.max_stock_qty"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params) =>
          params.value !== null && typeof params.value !== "undefined"
            ? params.value.toLocaleString()
            : "-",
        cellStyle: { display: "flex", justifyContent: "flex-end" },
      },
      {
        field: "reorder_point",
        headerName: "จำนวนแนะนำ",
        filter: "agNumberColumnFilter",
        valueFormatter: (params) =>
          params.value !== null && typeof params.value !== "undefined"
            ? params.value.toLocaleString()
            : 0,
        cellStyle: { display: "flex", justifyContent: "flex-end" },
      },
      {
        field: "status",
        headerName: t("inventory.item.status"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["ต้องสั่งซื้อ", "เกินสต็อก", "ปกติ"],
        },
        cellRenderer: (params: ICellRendererParams<any, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
        // cellStyle: (params) => {
        //   if (params.value === "ปกติ") {
        //     return { color: "#246527", fontWeight: 600 };
        //   } else if (params.value === "เกินสต็อก") {
        //     return { color: "#C3762E", fontWeight: 600 };
        //   } else {
        //     return { color: "#B54839", fontWeight: 600 };
        //   }
        // },
      },
    ]);
  }, [t, refetch]);
  return columnDefs;
};
