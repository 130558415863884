import { Box, Grid, Typography } from "@mui/material";

type Props = {
  data: any[];
};

export const DailyWorkSheetWorkListInfo = ({ data }: Props) => {
  const fontSize = 10.66;
  return (
    <>
      {data.map((item, index) => {
        const formattedSpecialRequest = item.special_request
          ? item.special_request.replace(/ /g, "<br />")
          : "";

        return (
          <Box
            sx={{
              width: "100%",
              border: "0.5px solid #BDBDBD",
              borderBottom:
                index !== data.length - 1 ? "0px" : "0.5px solid #BDBDBD",
              borderTopRightRadius: index === 0 ? 8 : 0,
              borderTopLeftRadius: index === 0 ? 8 : 0,
              borderBottomRightRadius: index === data.length - 1 ? 8 : 0,
              borderBottomLeftRadius: index === data.length - 1 ? 8 : 0,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              marginBottom: index === data.length - 1 ? "15px" : "0px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "26px",
                backgroundColor: "#ECF6FD",
                borderBottom: "0.5px solid #BDBDBD",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "4px 8px 4px 8px",
              }}
            >
              <Typography
                fontSize={fontSize}
                fontWeight={600}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box component="span">
                  {index === 0 ? `ต้นทาง` : `จุดที่ ${index}`}
                </Box>
                <Box component="span" sx={{ ml: 1 }}>{`(${
                  item.type === "receive" ? "รับ" : "ส่ง"
                })`}</Box>
                <Box component="span" sx={{ ml: 2 }}>
                  สถานที่
                </Box>
              </Typography>
              <Typography marginLeft={0.3} fontSize={fontSize} gap={1}>
                {item.location_unique_id} - {item.location_name}
              </Typography>
            </Box>
            <Grid container height={92}>
              <Grid
                item
                xs={4.4}
                sx={{
                  borderRight: "0.5px solid #BDBDBD",
                  padding: "8px",
                }}
              >
                <Grid container spacing={0.3}>
                  <Grid item xs={5}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      ชื่อลูกค้า
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography fontSize={fontSize}>
                      {item.customer_name ? item.customer_name : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      ชื่อผู้ประสานงาน
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography fontSize={fontSize}>
                      {item.coordinator_name ? item.coordinator_name : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      เบอร์โทรผู้ประสานงาน
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography fontSize={fontSize}>
                      {item.coordinator_phone ? item.coordinator_phone : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={3.8}
                sx={{ borderRight: "0.5px solid #BDBDBD", padding: "8px" }}
              >
                <Grid container spacing={0.6}>
                  <Grid item xs={5}>
                    <Typography
                      fontSize={fontSize}
                      fontWeight={600}
                      color={"#E41B1B"}
                    >
                      Special Request
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      fontSize={fontSize}
                      color={"#E41B1B"}
                      dangerouslySetInnerHTML={{
                        __html: formattedSpecialRequest
                          ? formattedSpecialRequest
                          : "-",
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      อุณหภูมิปิดตู้
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #BDBDBD",
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      หมายเลขซีล
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography fontSize={fontSize}>
                      {item.seal_no || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3.8} sx={{ padding: "8px" }}>
                <Grid container spacing={1.4}>
                  <Grid item xs={12}>
                    <Grid container spacing={0}>
                      <Grid item xs={6.7}>
                        <Typography fontSize={fontSize} fontWeight={600}>
                          วันที่เวลาขึ้นสินค้า (จริง)
                        </Typography>
                      </Grid>
                      <Grid item xs={5.3}>
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderBottom: "1px solid #BDBDBD",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={0}>
                      <Grid item xs={6.7}>
                        <Typography fontSize={fontSize} fontWeight={600}>
                          เลขกิโลเมตรถึงสาขา
                        </Typography>
                      </Grid>
                      <Grid item xs={5.3}>
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderBottom: "1px solid #BDBDBD",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={0}>
                      <Grid item xs={6.7}>
                        <Typography fontSize={fontSize} fontWeight={600}>
                          หมายเหตุ
                        </Typography>
                      </Grid>
                      <Grid item xs={5.3}>
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderBottom: "1px solid #BDBDBD",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                width: "100%",
                height: "26px",
                backgroundColor: "rgba(242, 242, 242, 0.5)",
                borderTop: "0.5px solid #BDBDBD",
                alignItems: "center",
                padding: "4px 8px 4px 8px",
              }}
            >
              <Typography fontSize={fontSize} fontWeight={600}>
                ลายเซ็นลูกค้า
              </Typography>
            </Box>
          </Box>
        );
      })}
    </>
  );
};
