import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useDisable } from "hooks/use-disable";
import { IVehicle } from "types/Logistic/vehicle";

import { Box, Typography } from "@mui/material";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedChips from "components/Custom/CustomizedChips";

const TireInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [disable] = useDisable();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<IVehicle>();

  return (
    <CustomizedBox>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={1}>
          <Typography fontWeight={600}>ยางหลัก</Typography>
          <CustomizedChips
            value="5/10"
            variant="filled"
            sx={{ backgroundColor: "#ECF6FD", color: "#333333" }}
          />
        </Box>
        <CustomizedButton variant="outlined" title="กำหนดตำแหน่งยาง" />
      </Box>
      <Box my={2}></Box>
      <CustomizedButton variant="outlined" title="เพิ่มยาง" startAddIcon />
      <Box display="flex" alignItems="center" gap={1} mt={2}>
        <Typography fontWeight={600}>ยางสำรอง</Typography>
        <CustomizedChips
          value="5/10"
          variant="filled"
          sx={{ backgroundColor: "#ECF6FD", color: "#333333" }}
        />
      </Box>
      <Box my={2}></Box>
      <CustomizedButton variant="outlined" title="เพิ่มยาง" startAddIcon />
    </CustomizedBox>
  );
};

export default TireInfo;
