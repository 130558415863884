import { useSnackbar } from "notistack";
import { useEffect } from "react";

export const useInventoryError = (errors: any) => {
  const { enqueueSnackbar } = useSnackbar();

  console.log(errors);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const {
        unique_id,
        source_warehouse_id,
        destination_warehouse_id,
        trace_entry_list,
        type,
        reference_unique_id,
      } = errors;
      if (unique_id) {
        enqueueSnackbar(unique_id.message, {
          variant: "error",
        });
      }
      if (source_warehouse_id) {
        enqueueSnackbar(source_warehouse_id.message, {
          variant: "error",
        });
      }
      if (destination_warehouse_id) {
        enqueueSnackbar(destination_warehouse_id.message, {
          variant: "error",
        });
      }
      if (type) {
        enqueueSnackbar(type.message, {
          variant: "error",
        });
      }
      if (reference_unique_id) {
        enqueueSnackbar(reference_unique_id.message, {
          variant: "error",
        });
      }
      if (trace_entry_list) {
        if (Array.isArray(trace_entry_list)) {
          trace_entry_list.forEach((trace) => {
            if (trace) {
              const { serial_list, qty } = trace;
              if (serial_list) {
                if (Array.isArray(serial_list)) {
                  serial_list.forEach((serial) => {
                    if (serial) {
                      const { qty } = serial;
                      if (qty) {
                        enqueueSnackbar(qty.message, {
                          variant: "error",
                        });
                      }
                    }
                  });
                } else {
                  enqueueSnackbar(serial_list.message, {
                    variant: "error",
                  });
                }
              }
              if (qty) {
                enqueueSnackbar(qty.message, {
                  variant: "error",
                });
              }
            }
          });
        } else {
          enqueueSnackbar(
            trace_entry_list?.message || trace_entry_list?.root.message,
            {
              variant: "error",
            }
          );
        }
      }
    }
  }, [enqueueSnackbar, errors]);
  return;
};
