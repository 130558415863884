import { Box, IconButton } from "@mui/material";
import { ICellRendererParams, ColDef } from "ag-grid-community";
import { useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AgGrid from "../../UI/AgGrid";

type Props = {
  onEditHandler: (id?: number) => void;
  onDeleteHandler: (id?: number) => void;
  data: any[];
};

const InventorySettingTable = ({
  onEditHandler,
  onDeleteHandler,
  data,
}: Props) => {
  const gridRef = useRef(null);

  const columnDefs: ColDef[] = [
    {
      field: "id",
      hide: true,
      sort: "desc",
      suppressFiltersToolPanel: true,
    },
    {
      field: "name",
      headerName: "ชื่อ",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "",
      filter: false,
      sortable: false,
      width: 100,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (params.data) {
          return (
            <Box>
              <IconButton
                onClick={() => onEditHandler(params.data?.id)}
                color="primary"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => onDeleteHandler(params.data?.id)}
                color="primary"
              >
                <DeleteForeverIcon />
              </IconButton>
            </Box>
          );
        }
      },
    },
  ];

  return (
    <AgGrid
      columnDefs={columnDefs}
      ref={gridRef}
      rowData={data || []}
      height={670}
      disabledSidebar
    />
  );
};

export default InventorySettingTable;
