import { Fragment } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";

import CustomizedAvatar from "components/Custom/CustomizedAvatar";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";

import { IDefaultForm } from "types/global";
import { IGoodsReceive } from "types/Inventory/goodsReceive";
import { formatDateTime } from "utils/Date";
import { checkIsNotDraftOrEmptyStatus } from "utils/Global";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import { useTranslation } from "react-i18next";

type Props = {
  disabled: IDefaultForm["disabled"];
  nestedIndex: number;
  status: string;
};

const SerialList = ({ nestedIndex, disabled, status }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IGoodsReceive>();

  const { fields, remove, update } = useFieldArray({
    control,
    name: `trace_entry_list.${nestedIndex}.serial_list` as `trace_entry_list`,
  });

  const rescanBarcodeHandler = (index: number) => {
    const { id, ...otherFields } = fields[index];

    update(index, {
      ...otherFields,
      status: "is_active",
      source_bin_location: undefined,
      source_bin_location_id: undefined,
      scanned_by: undefined,
      scanned_date: undefined,
    });
  };

  const notDraft = checkIsNotDraftOrEmptyStatus(status);
  const isFinished = ["finished", "cancelled"].includes(status);

  return (
    <>
      {fields.map((serial, index) => (
        <TableRow key={serial.id}>
          <TableCell></TableCell>
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
          >
            <Typography fontSize={14}>
              {formatDateTime(serial.scanned_date)}
            </Typography>
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          {!notDraft && (
            <>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </>
          )}
          <TableCell align="center">
            <ControlledNumberTextField
              fullWidth
              control={control}
              name={`trace_entry_list.${nestedIndex}.serial_list.${index}.qty`}
              error={Boolean(
                errors?.trace_entry_list?.[nestedIndex]?.serial_list?.[index]
                  ?.qty
              )}
              viewMode={notDraft}
              decimalScale={0}
            />
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.uom}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.serial_no}</Typography>
          </TableCell>
          {notDraft && (
            <Fragment>
              <TableCell align="center">
                <Typography fontSize={14}>
                  {serial.source_bin_location?.name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Box
                  display={"flex"}
                  justifyContent={isFinished ? "center" : "flex-start"}
                >
                  <CustomizedStatus status={serial.status} />
                  {!isFinished && serial.status === "is_scanned" && (
                    <CustomizedTooltip title="สแกนใหม่">
                      <IconButton onClick={() => rescanBarcodeHandler(index)}>
                        <ReplayOutlinedIcon fontSize="small" />
                      </IconButton>
                    </CustomizedTooltip>
                  )}
                </Box>
              </TableCell>
              <TableCell align="center">
                {serial.scanned_by &&
                  Object.keys(serial.scanned_by).length > 0 && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CustomizedAvatar
                        avatars={[
                          {
                            unique_id: serial.scanned_by.user_unique_id || "",
                            first_name: serial.scanned_by.first_name || "",
                            last_name: serial.scanned_by.last_name || "",
                            img_url: serial.scanned_by.img_url
                              ? serial.scanned_by.img_url[0]
                              : "",
                          },
                        ]}
                      />
                    </Box>
                  )}
              </TableCell>
              <TableCell>
                <Typography fontSize={14}>{serial.barcode}</Typography>
              </TableCell>
            </Fragment>
          )}
          {!notDraft && (
            <>
              <TableCell />
              <TableCell align="center">
                <Typography fontSize={14}>
                  <ControlledTextField
                    placeholder={t("sentence.remark")}
                    control={control}
                    name={`trace_entry_list.${nestedIndex}.serial_list.${index}.remark`}
                    error={Boolean(
                      errors?.trace_entry_list?.[nestedIndex]?.serial_list?.[
                        index
                      ]?.remark
                    )}
                    helperText={
                      errors?.trace_entry_list?.[nestedIndex]?.serial_list?.[
                        index
                      ]?.remark?.message
                    }
                    viewMode={isFinished}
                    viewModeNoLabel
                  />
                </Typography>
              </TableCell>
            </>
          )}
          <TableCell align="center">
            <Typography fontSize={14}>
              {serial.is_lot ? (
                <ControlledDatePicker
                  placeholder="LOT"
                  control={control}
                  name={`trace_entry_list.${nestedIndex}.serial_list.${index}.lot_date`}
                  viewMode={notDraft || isFinished}
                  viewModeNoLabel
                />
              ) : (
                "-"
              )}
            </Typography>
          </TableCell>
          {!notDraft && (
            <TableCell align="center">
              <IconButton onClick={() => remove(index)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default SerialList;
