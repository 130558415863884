import {
  ColDef,
  IServerSideGetRowsParams,
  GridReadyEvent,
} from "ag-grid-community";
import AgGrid from "../../../UI/AgGrid";
import { RefObject } from "react";
import { AgGridReact } from "ag-grid-react";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";

import { PURCHASE_BY_SUPPLIER_EXPORT_VIEW } from "services/Purchase/Report/PurchaseBySupplierExportViewAggrid";
import { numberFormatter } from "utils/Formatter/Global";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

export const PurchaseBySupplierTable = ({ gridRef }: Props) => {
  const columnDefs: ColDef[] = [
    {
      field: "supplier_unique_id",
      headerName: "รหัสซัพพลายเออร์",
      filter: "agTextColumnFilter",
    },
    {
      field: "supplier_name",
      headerName: "ชื่อซัพพลายเออร์",
      filter: "agTextColumnFilter",
    },
    {
      field: "total_document",
      headerName: "จำนวนใบสั่งซื้อ",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
      // headerClass: "ag-end-header",
    },
    {
      field: "net_amount",
      headerName: "มูลค่ารวมสุทธิ",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      // headerClass: "ag-end-header",
    },
    {
      field: "vat_amount",
      headerName: "ภาษีมูลค่าเพิ่มรวม",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      // headerClass: "ag-end-header",
    },
    {
      field: "total_amount",
      headerName: "จำนวนเงินชำระทั้งสิ้น",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      // headerClass: "ag-end-header",
    },
    {
      field: "pre_vat_amount",
      headerName: "มูลค่าก่อนหักส่วนลด",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      // headerClass: "ag-end-header",
    },
    {
      field: "additional_discount",
      headerName: "ส่วนลดเพิ่มเติม",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      // headerClass: "ag-end-header",
    },
    {
      field: "post_discount_amount",
      headerName: "มูลค่าก่อนภาษี",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      // headerClass: "ag-end-header",
    },
    {
      field: "withholding_tax_amount",
      headerName: "ภาษีหัก ณ ที่จ่าย",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      // headerClass: "ag-end-header",
    },
  ];

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      try {
        const { PurchaseBySupplierExportViewsAggrid } =
          await graphQLClient.request(PURCHASE_BY_SUPPLIER_EXPORT_VIEW, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: filterModel,
              sortModel,
            },
          });

        params.success({
          rowData: PurchaseBySupplierExportViewsAggrid.results,
          rowCount: PurchaseBySupplierExportViewsAggrid.count,
        });
      } catch (err) {
        console.log(err);
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      headerClass={["grid-header-end"]}
    />
  );
};
