import { useEffect, RefObject, useCallback } from "react";
import { useStockReportColumnDefs } from "./columnDefs";
import { GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import AgGrid from "components/UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { GET_STOCK_REPORT_VIEW } from "services/AgGrid/InventoryReportAgGrid";

interface Props {
  gridRef: RefObject<AgGridReact>;
  status: string | null;
}

const StockReportTable = ({ gridRef, status }: Props) => {
  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const columnDefs = useStockReportColumnDefs();
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
      };
      try {
        const { CurrentStockByWarehouseReportView } =
          await graphQLClientWms.request(GET_STOCK_REPORT_VIEW, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: CurrentStockByWarehouseReportView.results as any[],
          rowCount: CurrentStockByWarehouseReportView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const statusInstance = params.api.getFilterInstance("status");
      switch (status) {
        case "ordering":
          statusInstance.setModel({ values: ["ต้องสั่งซื้อ"] });
          break;
        case "over":
          statusInstance.setModel({ values: ["เกินสต็อก"] });
          break;
        default:
          statusInstance.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [status]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onGridReady={onGridReady}
    />
  );
};

export default StockReportTable;
