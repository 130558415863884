import { Grid } from "@mui/material";
import Remark from "./Remark";
import SalesSummary from "./Summary";
import BillingNoteSummary from "components/Form/Sales/BillingNote/Summary";
import { CustomizedBox } from "components/Custom/CustomizedBox";

interface Props {
  documentType: string;
}

const SalesFooter = ({ documentType }: Props) => {
  return (
    <CustomizedBox>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6.5} lg={6.5} xl={6.5}>
          <Remark />
        </Grid>
        <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
          {documentType === "billing_note" ? (
            <BillingNoteSummary documentType={documentType} />
          ) : (
            <SalesSummary documentType={documentType} />
          )}
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default SalesFooter;
