import dayjs from "dayjs";
import { IBillingNote } from "types/Sales/billing-note";
import { IUser } from "types/Auth/user";
// import { v4 as uuidv4 } from "uuid";
import { billingNoteSchema } from "components/Form/Sales/BillingNote/schema";
import { IAttachment } from "types/global";
import { uploadFileToS3 } from "utils/s3";

export const billingNoteQueryFormatter = (data: any) => {
  const {
    created_date,
    issue_date,
    due_date,
    customer_unique_id,
    customer_details,
    item_list,
    accepted_date,
    ...otherData
  } = data;

  const formattedCustomer = {
    id: customer_details?.customer_id,
    unique_id: customer_details?.customer_unique_id,
    unique_id_name: `${customer_unique_id} - ${customer_details.customer_name}`,
    name: customer_details?.customer_name,
    identity_no: customer_details?.identity_no,
    email: customer_details?.email,
    phone: customer_details?.phone,
    fax: customer_details?.fax,
    billing_address: customer_details?.billing_address,
  };

  const formattedItemList = item_list?.map((item: any) => {
    return {
      ...item,
      customer_details: {
        id: item.customer_details?.customer_id,
        name: item.customer_details?.customer_name,
        identity_no: item.customer_details?.identity_no,
        email: item.customer_details?.email,
        phone: item.customer_details?.phone,
        fax: item.customer_details?.fax,
      },
    };
  });

  const formatted = {
    ...otherData,
    created_date: created_date ? dayjs(created_date) : undefined,
    issue_date: issue_date ? dayjs(issue_date) : undefined,
    due_date: due_date ? dayjs(due_date) : undefined,
    accepted_date: accepted_date ? dayjs(accepted_date) : undefined,
    customer_unique_id,
    customer_details: formattedCustomer,
    item_list: formattedItemList,
  } as IBillingNote;

  return formatted;
};

export const billingNoteCreatePayloadFormatter = (
  data: IBillingNote,
  status: string
) => {
  const {
    customer_unique_id,
    customer_details,
    created_date,
    last_updated_date,
    last_updated_by,
    aggrid_status,
    item_list,
    item_unique_id_list,
    ...otherData
  } = data;

  const formattedCustomer = {
    customer_id: customer_details?.id,
    customer_unique_id: customer_unique_id,
    customer_name: customer_details?.name,
    identity_no: customer_details?.identity_no,
    email: customer_details?.email,
    phone: customer_details?.phone,
    fax: customer_details?.fax,
    billing_address: customer_details?.billing_address,
  };

  const formattedItemList = item_list?.map((item: any) => {
    return {
      ...item,
      income_withholding_tax_amount: item.income_withholding_tax_amount ?? 0,
      customer_details: {
        customer_id: item.customer_details?.id,
        customer_name: item.customer_details?.name,
        identity_no: item.customer_details?.identity_no,
        email: item.customer_details?.email,
        phone: item.customer_details?.phone,
        fax: item.customer_details?.fax,
      },
      id: undefined,
    };
  });

  const formatted = {
    ...otherData,
    customer_unique_id,
    customer_details: formattedCustomer,
    item_list: formattedItemList,
    main_status: status,
    sub_status: status,
  };

  return formatted;
};

export const billingNoteUpdatePayloadFormatter = async (
  data: IBillingNote,
  status: string
) => {
  const {
    id,
    customer_unique_id,
    customer_details,
    created_date,
    created_by,
    last_updated_date,
    aggrid_status,
    item_list,
    item_unique_id_list,
    attachment_list,
    ...otherData
  } = data;

  const formattedCustomer = {
    customer_id: customer_details?.id,
    customer_unique_id: customer_unique_id,
    customer_name: customer_details?.name,
    identity_no: customer_details?.identity_no,
    email: customer_details?.email,
    phone: customer_details?.phone,
    fax: customer_details?.fax,
    billing_address: customer_details?.billing_address,
  };

  const formattedItemList = item_list?.map((item: any) => {
    return {
      ...item,
      id: undefined,
      income_withholding_tax_amount: item.income_withholding_tax_amount || 0,
      customer_details: {
        customer_id: item.customer_details?.id,
        customer_name: item.customer_details?.name,
        identity_no: item.customer_details?.identity_no,
        email: item.customer_details?.email,
        phone: item.customer_details?.phone,
        fax: item.customer_details?.fax,
      },
    };
  });

  let uploadedAttachment: IAttachment[] = [];
  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "quotation",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        uploadedAttachment.push(formatAttachment);
      } else {
        uploadedAttachment.push(file);
      }
    }
  }

  const formatted = {
    ...otherData,
    customer_unique_id,
    customer_details: formattedCustomer,
    item_list: formattedItemList,
    main_status: status,
    sub_status: status,
    attachment_list: uploadedAttachment,
  };

  return formatted;
};

export const copyBillingNoteFormatter = async (
  data: IBillingNote,
  currentUser: IUser | null
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      due_date,
      accepted_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      ...otherData
    } = data;

    // const formatItemList =
    //   item_list && item_list.length > 0
    //     ? item_list.map(({ id, unique_id, ...otherItemList }) => ({
    //         ...otherItemList,
    //         unique_id: uuidv4(),
    //       }))
    //     : [];

    return {
      ...billingNoteSchema,
      ...otherData,
      created_by: {
        user_unique_id: currentUser?.unique_id,
        email: currentUser?.email,
        first_name: currentUser?.first_name,
        last_name: currentUser?.last_name,
        img_url: currentUser?.img_url,
      },
      copied_id: id,
      copied_unique_id: unique_id,
      item_list: [],
      // item_list: formatItemList,
      accepted_date: undefined,
      accepted_remark: "",
      attachment_list: [],
    };
  }
};
