import { IItem, IImageUrlObject } from "types/Inventory/item";
import {
  ItemCreateInput,
  ItemUpdateInput,
  ItemFindUniqueQuery,
  Traceability,
} from "generated/wms";
import { uploadFileToS3 } from "utils/s3";

export const itemCreateFormatter = async (
  data: IItem,
  id?: string | undefined
) => {
  const {
    unique_id,
    tag_list,
    uom_conversion_list,
    stock_uom_id,
    stock_uom_name,
    is_active,
    img_url_list,
    main_image_index,
    ...rest
  } = data;

  const formatUomConversionList = uom_conversion_list.map((uom) => {
    return {
      ...uom,
      base_uom_id: stock_uom_id,
      base_uom_name: stock_uom_name,
      target_uom_id: uom.target_uom_id || undefined,
      target_uom_name: uom.target_uom_name || undefined,
    };
  });

  const tag_id_list: number[] = [];

  const imageUrlList = img_url_list as any[];

  let uploded_img_list: string[] = [];
  if (imageUrlList && imageUrlList.length > 0) {
    if (imageUrlList && imageUrlList.length > 0) {
      for (const file of imageUrlList) {
        if (typeof file === "object") {
          const { Location } = await uploadFileToS3(
            file,
            "item",
            id?.toString() || ""
          );
          uploded_img_list.push(Location);
        } else {
          uploded_img_list.push(file);
        }
      }
    } else {
      uploded_img_list = imageUrlList;
    }
  }

  const formatImageUrlList = uploded_img_list.map((img, index) => {
    return {
      img_url: img,
      is_main_img: main_image_index === index,
    };
  }) as IImageUrlObject[];

  tag_list.forEach((tag) => {
    if (tag.id) {
      tag_id_list.push(tag.id);
    }
  });

  const activeStatus = Number.isInteger(is_active)
    ? is_active
    : parseInt(is_active as any);

  const formatData: ItemCreateInput = {
    ...rest,
    unique_id,
    stock_uom_id,
    uom_conversion_list: formatUomConversionList,
    tag_id_list,
    type: data.type as ItemCreateInput["type"],
    tracability: data.tracability as Traceability,
    barcode:
      data.tracability === Traceability.Serial ? unique_id : data.barcode,
    is_active: activeStatus,
    remark_status: activeStatus === 1 ? "" : data.remark_status,
    img_url_list: formatImageUrlList,
  };

  return formatData;
};

export const itemUpdateFormatter = async (data: IItem) => {
  const {
    id,
    unique_id,
    last_updated_date,
    created_date,
    tag_list,
    created_by,
    cate_1,
    cate_2,
    cate_3,
    width_uom,
    length_uom,
    thickness_uom,
    height_uom,
    stock_uom,
    sales_uom,
    purchase_uom,
    stock_qty,
    available_qty,
    purchase_ordered_qty,
    sale_committed_qty,
    uom_conversion_list,
    stock_uom_id,
    stock_uom_name,
    is_active,
    img_url_list,
    main_image_index,
    ...rest
  } = data;

  const formatUomConversionList = uom_conversion_list.map((uom) => {
    return {
      ...uom,
      base_uom_id: stock_uom_id,
      base_uom_name: stock_uom_name,
    };
  });

  const tag_id_list: number[] = [];

  tag_list.forEach((tag) => {
    if (tag.id) {
      tag_id_list.push(tag.id);
    }
  });

  const activeStatus = Number.isInteger(is_active)
    ? is_active
    : parseInt(is_active as any);

  const imageUrlList = img_url_list as any[];

  let uploded_img_list: string[] = [];
  if (imageUrlList && imageUrlList.length > 0) {
    if (imageUrlList && imageUrlList.length > 0) {
      for (const file of imageUrlList) {
        if (typeof file === "object") {
          const { Location } = await uploadFileToS3(
            file,
            "item",
            id?.toString() || ""
          );
          uploded_img_list.push(Location);
        } else {
          uploded_img_list.push(file);
        }
      }
    } else {
      uploded_img_list = imageUrlList;
    }
  }

  const formatImageUrlList = uploded_img_list.map((img, index) => {
    return {
      img_url: img,
      is_main_img: main_image_index === index,
    };
  }) as IImageUrlObject[];

  const formatData: ItemUpdateInput = {
    ...rest,
    unique_id,
    tag_id_list,
    type: data.type as ItemUpdateInput["type"],
    tracability: data.tracability as Traceability,
    uom_conversion_list: formatUomConversionList,
    stock_uom_id: stock_uom_id,
    barcode:
      data.tracability === Traceability.Serial ? unique_id : data.barcode,
    is_active: activeStatus,
    remark_status: activeStatus === 1 ? "" : data.remark_status,
    img_url_list: formatImageUrlList,
  };

  return formatData;
};

export const itemQueryFormatter = (
  data: ItemFindUniqueQuery["ItemFindUnique"]
) => {
  const formatImageUrlList = data?.img_url_list?.map(
    (img: any) => img.img_url as string
  );

  const mainImageIndex = data?.img_url_list?.findIndex(
    (img: any) => img.is_main_img
  );

  const formatData: IItem = {
    ...data,
    stock_uom_name: data?.stock_uom?.name,
    img_url_list: formatImageUrlList as any[],
    main_image_index: mainImageIndex,
  } as IItem;

  return formatData;
};
