import { Grid } from "@mui/material";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import { useFormContext } from "react-hook-form";
import { useDisable } from "hooks/use-disable";
import { useTranslation } from "react-i18next";

const ItemStock = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const type = watch("type");

  return (
    <>
      <Grid container spacing={1.5} mt={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledNumberTextField
            name="min_stock_qty"
            label={t("inventory.item.stock.min_stock_qty")}
            control={control}
            viewMode={disabled}
            error={Boolean(errors.min_stock_qty)}
            helperText={errors.min_stock_qty?.message?.toString()}
            fullWidth
            disabled={type === "service"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledNumberTextField
            name="max_stock_qty"
            label={t("inventory.item.stock.max_stock_qty")}
            control={control}
            viewMode={disabled}
            error={Boolean(errors.max_stock_qty)}
            helperText={errors.max_stock_qty?.message?.toString()}
            fullWidth
            disabled={type === "service"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ItemStock;
