import { gql } from "graphql-request";

export const ITEM_SKU_QTY_BY_BIN_LOCATION_VIEW = gql`
  query ItemSkuQtyByBinLocationsViewAggrid($aggridInput: AnyAggridInput!) {
    ItemSkuQtyByBinLocationsViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        item_id
        item_unique_id
        warehouse_id
        warehouse_unique_id
        warehouse_name
        bin_location_id
        bin_name
        stock_qty
        available_qty
        purchase_ordered_qty
        sale_committed_qty
      }
    }
  }
`;
