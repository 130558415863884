import { Box, Grid, Typography } from "@mui/material";

type Props = {
  isMultiplePages: boolean;
};

export const DailyWorkSheetDriverSigner = ({ isMultiplePages }: Props) => {
  const fontSize = 10.66;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row-reverse",
        ...(isMultiplePages && { marginBottom: "4px" }),
      }}
    >
      <Grid
        container
        height={55}
        width={200}
        sx={{ border: "0.5px solid #BDBDBD", borderRadius: 2 }}
        padding={"8px"}
      >
        <Grid item xs={5}>
          <Typography fontSize={fontSize} fontWeight={600}>
            พนักงานขนส่ง
          </Typography>
        </Grid>
        <Grid item xs={7} sx={{ pb: "10px" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderBottom: "1px solid #BDBDBD",
            }}
          />
        </Grid>
        <Grid item xs={5} />
        <Grid
          item
          xs={7}
          sx={{ pb: "1px", display: "flex", flexDirection: "row", gap: "2px" }}
        >
          <Box
            sx={{
              width: "32%",
              height: "100%",
              borderBottom: "1px solid #BDBDBD",
            }}
          />
          <Box
            sx={{
              width: "32%",
              height: "100%",
              borderBottom: "1px solid #BDBDBD",
            }}
          />
          <Box
            sx={{
              width: "32%",
              height: "100%",
              borderBottom: "1px solid #BDBDBD",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
