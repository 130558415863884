import { ColDef, ValueFormatterParams } from "ag-grid-community";
import AgGrid from "../../UI/AgGrid";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { IContact } from "types/Contact/contact";
import { IContactChannel } from "types/global";
import { Box } from "@mui/material";
import CustomizedChips from "components/Custom/CustomizedChips";
import { businessTypeFormatter, contactChannelFormatter } from "utils/Formatter/Global";
import { t } from "i18next";

type Props = {
  // type: "customer" | "vendor";
  data: any[];
};
const contactChannelAction = (type: string, info: string) => {
  switch (type) {
    case "phone":
    case "fax":
      window.open(`tel:${info}`, "_self");
      return;
    case "email":
      window.open("mailto:" + info, "_self");
      return;
    case "line":
      window.open(
        "https://line.me/R/ti/p/" + info,
        "_blank",
        "noopener,noreferrer"
      );
      return;
    case "facebook":
      window.open(
        "https://facebook.com/" + info,
        "_blank",
        "noopener,noreferrer"
      );
      return;
    case "website":
      window.open(`https://${info}`, "_blank", "noopener,noreferrer");
      return;
    case "instagram":
      window.open(
        "https://instagram.com/" + info,
        "_blank",
        "noopener,noreferrer"
      );
      return;
    default:
      return;
  }
};
// const ContactImporterTable = ({ type, data }: Props) => {
const ContactImporterTable = ({ data }: Props) => {

  const gridRef = useRef<AgGridReact>(null);
  const columnDefs: ColDef[] = [
    {
      field: "unique_id",
      // headerName: type === "customer" ? "รหัสลูกค้า" : "รหัสผู้ขาย",
      headerName: "รหัสผู้ติดต่อ",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
    },
    // {
    //   field: "business_type",
    //   headerName: "ประเภทกิจการ",
    //   filter: "agTextColumnFilter",
    //   flex: 1,
    //   minWidth: 200,
    //   valueFormatter: ({ data }: { data: IContact }) => {
    //     if (data.business_type === "นิติบุคคล") {
    //       return data.business_type;
    //     }
    //     return data.business_type
    //   },
    // },
    {
      field: "business_type",
      headerName: t("contact.info.business_type"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["individual", "corporate"],
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return businessTypeFormatter(params.value) || "-";
          else return "-";
        },
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return businessTypeFormatter(params.value) || "-";
        else return "-";
      },
      flex: 1,
      minWidth: 200,
    },
    {
      field: "contact_type",
      headerName: t("contact.contact_type.index"),
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
      valueFormatter: ({ data }: { data: IContact }) => {
        if (data.is_customer === true && data.is_supplier) {
          return `${data.is_customer === true ? "ลูกค้า" : undefined},${data.is_supplier === true ? "ซัพพลายเออร์" : undefined}`;
        }
        else if (data.is_customer === true) {
          return `${data.is_customer === true ? "ลูกค้า" : undefined}`;
        }
        return `${data.is_supplier === true ? "ซัพพลายเออร์" : undefined}`
      },
    },
    {
      field: "name",
      headerName: "ชื่อกิจการ/ชื่อ นามสกุล",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
      valueFormatter: ({ data }: { data: IContact }) => {
        if (data.business_type === "นิติบุคคล") {
          return data.name;
        }
        return `${data.name} ${data?.last_name || ""}`;
      },
    },
    {
      field: "contact_channel_list",
      headerName: t("contact.contact_channel.index"),
      filter: false,
      valueFormatter: ({ value }: { value: IContactChannel[] }) => {
        return value.map(channel => `${contactChannelFormatter(channel.contact_channel_type)}: ${channel.contact_channel_info}`).join(', ');
      },
      cellRenderer: ({ value }: { value: IContactChannel[] }) => {
        if (
          value.length === 1 &&
          // value[0].contact_channel_info.trim().length +
          //   value[0].contact_channel_type.trim().length ===
          //   0
          (!value[0].contact_channel_info || value[0].contact_channel_info.trim().length === 0) &&
          (!value[0].contact_channel_type || value[0].contact_channel_type.trim().length === 0)
        ) {
          return;
        } else {
          return value.map((channel, index) => (
            <Box
              sx={{ display: "inline-block", mr: 1 }}
              key={`${channel.contact_channel_info}-${index}`}
            >
              <CustomizedChips
                color="primary"
                variant="outlined"
                onClick={() =>
                  contactChannelAction(
                    channel.contact_channel_type,
                    channel.contact_channel_info
                  )
                }
                label={`${contactChannelFormatter(
                  channel.contact_channel_type
                )}: ${channel.contact_channel_info || "-"}`}
                isRounded
              />
            </Box>
          ));
        }
      },
      flex: 1,
      minWidth: 200,
    },
  ];
  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      rowData={data}
      disabledSidebar
    />
  );
};

export default ContactImporterTable;
