import { Navigate, Outlet, useLocation } from "react-router-dom";
import { REFRESH_ACCESS_TOKEN } from "../middleware/AuthMiddleware";
import { useStateContext } from "../contexts/auth-context";
import { IUser } from "../types/Auth/user";
import {
  ValidateTokenQuery,
  useValidateTokenQuery,
} from "../generated/general";
import { useCookies } from "react-cookie";
import jwtDecode from "jwt-decode";
import { IToken } from "../types/Auth/token";
import { Box, CircularProgress } from "@mui/material";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";

const RequireUser = () => {
  const [cookies, setCookie] = useCookies([
    "access_token",
    "refresh_token",
    "logged_in",
  ]);

  const location = useLocation();
  const { dispatch, logout } = useStateContext();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, refetch, isFetching, isLoading, isError } =
    useValidateTokenQuery<ValidateTokenQuery, Error>(
      graphQLClient,
      {},
      {
        retry: 1,
        // cacheTime: 0,
        // enabled: cookies.logged_in === "true",
        staleTime: 3600 * 1000,
        onSuccess: (data) => {
          dispatch({
            type: "SET_USER",
            payload: data.validateToken as IUser,
          });
        },
        onError(error: any) {
          if (cookies.refresh_token) {
            error.response.errors.forEach(async (err: any) => {
              if (err.message.includes("Unauthorized")) {
                try {
                  const { refreshToken } = await graphQLClient.request(
                    REFRESH_ACCESS_TOKEN
                  );
                  const { access_token } = refreshToken;
                  const { exp: accessMaxAge } = jwtDecode<IToken>(access_token);
                  setCookie("access_token", access_token, {
                    path: "/",
                    maxAge: accessMaxAge,
                  });
                  refetch();
                } catch (error) {
                  logout();
                }
              } else {
                logout();
              }
            });
          } else {
            logout();
          }
        },
      }
    );

  const user = data?.validateToken;

  const loading = isFetching || isLoading || isError;

  if (loading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return cookies.logged_in === "true" || user ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireUser;
