import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

const defaultHeaders: IHeaderTable[] = [
  {
    thaiLabel: "",
    width: 50,
  },
  {
    thaiLabel: "วันที่และเวลา(ต้นทาง)",
    width: 130,
    align: "left",
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัส-ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวน",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    thaiLabel: "Batch/SN",
    width: 200,
    align: "left",
  },
  {
    thaiLabel: "สถานที่ต้นทาง",
    width: 200,
    align: "left",
  },
  {
    thaiLabel: "ผู้สแกนต้นทาง",
    width: 100,
  },
  {
    thaiLabel: "Barcode",
    width: 300,
    align: "left",
  },
  {
    thaiLabel: "Lot",
    width: 100,
  },
];

const defaultScanHeader: IHeaderTable[] = [
  {
    thaiLabel: "",
    width: 50,
  },
  {
    thaiLabel: "วันที่และเวลา(ต้นทาง)",
    width: 130,
    align: "left",
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัส-ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวน",
    width: 100,
    align: "left",
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    thaiLabel: "Batch/SN",
    width: 200,
    align: "left",
  },
  {
    thaiLabel: "สถานที่ต้นทาง",
    width: 200,
    align: "left",
  },
  {
    thaiLabel: "สถานที่ปลายทาง",
    width: 200,
    align: "left",
  },
  {
    thaiLabel: "ผู้สแกนต้นทาง",
    width: 100,
  },
  {
    thaiLabel: "สถานะการสแกน",
    width: 100,
  },
  {
    thaiLabel: "",
    width: 30,
  },
  {
    thaiLabel: "ผู้สแกนปลายทาง",
    width: 100,
  },
  {
    thaiLabel: "วันที่และเวลา(ปลายทาง)",
    width: 140,
    align: "left",
  },
  {
    thaiLabel: "Barcode",
    width: 300,
    align: "left",
  },
  {
    thaiLabel: "Lot",
    width: 100,
  },
];

const headerPdf: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 20,
  },
  // {
  //   thaiLabel: "รูป",
  //   width: 50,
  // },
  {
    thaiLabel: "รหัส-ชื่อสินค้า",
    width: 110,
  },
  {
    thaiLabel: "เลขที่เอกสาร",
    width: 75,
  },
  {
    thaiLabel: "จำนวนนำเข้า - หน่วย",
    width: 105,
  },
  {
    thaiLabel: "Batch/SN",
    width: 120,
  },
  {
    thaiLabel: "Barcode",
    width: 200,
  },
  {
    thaiLabel: "สถานที่",
    width: 130,
  },
  {
    thaiLabel: "หมายเหตุ",
    width: 80,
  },
  {
    thaiLabel: "LOT",
    width: 70,
  },
];

export const useGoodsTransferItemList = (
  disabled: boolean,
  status?: string
) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    if (!status || status === "draft") {
      setHeaders([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 20,
        },
      ]);
    } else {
      setHeaders(defaultScanHeader);
    }
  }, [disabled, status]);

  return headers;
};

export const useGoodsTransferItemListPdf = () => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    setHeaders(headerPdf);
  }, []);

  return headers;
};
