import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import EditIcon from "@mui/icons-material/Edit";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../Controller/ControlledTextField";

import { useDisable } from "../../../../hooks/use-disable";
import { useContactDescription } from "../../../../hooks/use-contact-description";
import CheckedCustomerModal from "components/UI/Modal/CheckedModal/CheckedCustomerModal";

type Props = {
  forceDisabled?: boolean;
};

const CustomerInfo = ({ forceDisabled }: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    openContactTable,
    closeContactTableHandler,
    contactIds,
    setContactIds,
    contactIdsSnapshot,
    setContactIdsSnapshot,
    finishContactsSelect,
    openContactTableHandler,
    selectedContactId,
    openContactDetail,
  } = useContactDescription("sales");

  return (
    <Fragment>
      <CustomizedBox margin={0}>
        <Box display="flex" alignItems="center" mb={1} gap={1}>
          <Typography fontWeight={600}>
            {t("sales.customer_description")}
          </Typography>
          {!disabled && !forceDisabled && (
            <CustomizedTooltip title={t("sentence.choose_customer")}>
              <IconButton
                onClick={openContactTableHandler}
                color="primary"
                size="small"
              >
                <EditIcon
                  sx={{
                    fontSize: 16,
                  }}
                />
              </IconButton>
            </CustomizedTooltip>
          )}
          {disabled && selectedContactId && (
            <CustomizedTooltip title="กดเพื่อดูรายละเอียดลูกค้า">
              <IconButton onClick={openContactDetail} color="primary">
                <LaunchOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Box>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={4} display={"flex"}>
            <ControlledTextField
              control={control}
              name="customer_details.unique_id_name"
              label={"รหัส - ชื่อ"}
              disabled
              viewMode={disabled}
              error={Boolean(errors.customer_unique_id)}
              helperText={errors?.customer_unique_id?.message?.toString()}
              required
            />
            {!disabled && selectedContactId && (
              <CustomizedTooltip title="กดเพื่อดูรายละเอียดลูกค้า">
                <IconButton onClick={openContactDetail} color="primary">
                  <LaunchOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.identity_no"
              label={t("sales.identity_no")}
              viewMode={disabled}
              disabled
            />
          </Grid>
          <Box width="100%" />
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.phone"
              label={t("global.phone_number")}
              viewMode={disabled}
              disabled={forceDisabled}
              error={Boolean((errors as any).customer_details?.phone)}
              helperText={(
                errors as any
              )?.customer_details?.phone?.message?.toString()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.email"
              label={t("global.email")}
              viewMode={disabled}
              disabled={forceDisabled}
              error={Boolean((errors as any).customer_details?.["email"])}
              helperText={(
                errors as any
              )?.customer_details?.email?.message?.toString()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="customer_details.fax"
              label={t("global.fax")}
              viewMode={disabled}
              disabled={forceDisabled}
              error={Boolean((errors as any).customer_details?.fax)}
              helperText={(
                errors as any
              )?.customer_details?.fax?.message?.toString()}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <CheckedCustomerModal
        showSelectCustomer={openContactTable}
        closeCustomerTable={closeContactTableHandler}
        finishCustomersSelect={finishContactsSelect}
        customerIds={contactIds}
        setCustomerIds={setContactIds}
        customerIdsSnapshot={contactIdsSnapshot}
        setCustomerIdsSnapshot={setContactIdsSnapshot}
        rowSelection="single"
        onlySupplier={false}
      />
    </Fragment>
  );
};

export default CustomerInfo;
