import { useEffect, useState } from "react";
import { IHeaderTable } from "types/global";

export const useSalesItemListHeader = (
  documentType: string,
  disabled: boolean,
  status?: string
) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "รายการ",
        label: "No.",
        width: 75,
      },
      {
        thaiLabel: "รายการรายรับ",
        label: "Name",
        width: 320,
        align: "left",
        required: true,
      },
      {
        thaiLabel: "จำนวน",
        label: "Qty",
        width: 150,
        align: "right",
        required: true,
      },
      {
        thaiLabel: "หน่วย",
        label: "Unit",
        width: 100,
        align: "left",
        required: true,
      },
      {
        thaiLabel: "ราคา/หน่วย",
        label: "Price/Unit",
        width: 150,
        align: "right",
        required: true,
      },
      {
        thaiLabel: "ส่วนลด",
        label: "Discount",
        width: 150,
        align: "right",
      },

      {
        thaiLabel: "ภาษี (%)",
        label: "Vat",
        width: 100,
        align: "left",
        required: true,
      },
      {
        thaiLabel: "จำนวนเงิน",
        label: "Pre-vat Amount",
        width: 150,
        align: "right",
        required: true,
      },
      {
        thaiLabel: "ภาษีหัก ณ ที่จ่าย (%)",
        label: "Withholding Tax (%)",
        width: 150,
        align: "left",
      },
    ];

    if (
      [null, undefined, "", "draft", "wait_accept", "cancelled"].includes(
        status
      )
    ) {
      if (!disabled)
        setHeaders([
          ...defaultHeaders,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      else setHeaders(defaultHeaders);
    } else {
      setHeaders(defaultHeaders);
    }
  }, [disabled, documentType, status]);

  return headers;
};
