import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import CustomizedTab from "components/Custom/CustomizedTab";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import QuotationTable from "components/Table/Sales/Quotation";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import HeaderLayout from "components/UI/HeaderLayout";

const Quotation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.quotation.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_accept"),
      path: `${pathname}?filter=wait_accept`,
    },
    {
      label: t("status.accepted"),
      path: `${pathname}?filter=accepted`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.not_accepted"),
      path: `${pathname}?filter=not_accepted`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5" marginY={"auto"}>
          {t("sales.quotation.index")}
        </Typography>
        <CustomizedButton
          title={`${t("button.create")}${t("sales.quotation.index")}`}
          variant="contained"
          onClick={() => navigate(`${pathname}/add`)}
          startAddIcon
        />
      </HeaderLayout>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      </Box>
      <QuotationTable isFilter={isFilter} />
    </>
  );
};

export default Quotation;
