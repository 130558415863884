import { ColDef } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const useItemCurrentStockByWarehouseColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "warehouse_unique_id",
        headerName: t("warehouse.unique_id"),
        filter: false,
        width: 322,
      },
      {
        field: "warehouse_name",
        headerName: t("warehouse.index"),
        filter: false,
        width: 322,
      },
      {
        field: "stock_qty",
        headerName: t("inventory.item.stock_qty"),
        filter: false,
        width: 322,
      },
    ]);
  }, [t]);
  return columnDefs;
};
