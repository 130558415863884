import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";

import UserTable from "../../../components/Table/User";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
// import UserTable from "../../../components/Table/User";

const User = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.account.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("user.account.tab.index"),
      path: `${pathname}`,
    },
    {
      label: t("status.in_active"),
      path: `${pathname}?filter=in_active`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5">{t("user.account.index")}</Typography>
        <CustomizedButton
          startAddIcon
          title={t("user.account.add_new_account")}
          variant="contained"
          onClick={() => navigate(`${pathname}/add?subtab=inventory`)}
        />
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <UserTable />
    </>
  );
};

export default User;
