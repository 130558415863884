import dayjs from "dayjs";
import { UserCreateInput, UserUpdateInput } from "../../generated/general";
import { IUser } from "../../types/Auth/user";
import { uploadFileToS3 } from "../s3";

export const userCreateFormatter = async (
  data: IUser,
  id: string | undefined
) => {
  const {
    start_date,
    created_date,
    last_login_date,
    old_password,
    last_updated_date,
    new_password,
    confirm_new_password,
    delivery_position_details,
    is_delivery_position,
    ...otherData
  } = data;

  let img_url: string[] = [];
  if (data.img_url && data.img_url.length > 0) {
    const filteredFile = data.img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "user",
        id || ""
      );
      img_url.push(Location);
    } else {
      img_url = data.img_url;
    }
  }

  const formatData: UserCreateInput = {
    ...otherData,
    img_url,
    delivery_position_details: {
      ...delivery_position_details,
      dob: delivery_position_details?.dob
        ? dayjs(delivery_position_details?.dob)
        : undefined,
      license_expire_date: delivery_position_details?.license_expire_date
        ? dayjs(delivery_position_details?.license_expire_date)
        : undefined,
    },
    is_delivery_position: otherData.position === "พนักงานขนส่ง" ? 1 : 0,
    user_password: new_password ?? "",
  };

  return formatData;
};

export const userUpdateFormatter = async (data: IUser) => {
  const {
    id,
    start_date,
    last_login_date,
    created_date,
    last_updated_date,
    new_password,
    confirm_new_password,
    delivery_position_details,
    created_by,
    role_list,
    ...otherData
  } = data;

  let img_url: string[] = [];
  if (data.img_url && data.img_url.length > 0) {
    const filteredFile = data.img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "user",
        id?.toString() || ""
      );
      img_url.push(Location);
    } else {
      img_url = data.img_url;
    }
  }

  const formatData: UserUpdateInput = {
    ...otherData,
    img_url,
    delivery_position_details: {
      ...delivery_position_details,
      dob: delivery_position_details?.dob
        ? dayjs(delivery_position_details?.dob)
        : undefined,
      license_expire_date: delivery_position_details?.license_expire_date
        ? dayjs(delivery_position_details?.license_expire_date)
        : undefined,
    },
    new_password: new_password === "" ? undefined : new_password,
  };

  return formatData;
};

export const userQueryFormatter = (data: IUser): IUser => {
  const { start_date, inactive_date, delivery_position_details, ...otherData } =
    data;

  const formatData = {
    ...otherData,
    start_date: start_date ? dayjs(start_date) : null,
    inactive_date: inactive_date ? dayjs(inactive_date) : null,
    is_delivery_position: otherData.position === "พนักงานขนส่ง" ? 1 : 0,
    delivery_position_details: {
      ...delivery_position_details,
      dob: delivery_position_details?.dob
        ? dayjs(delivery_position_details?.dob)
        : undefined,
      license_expire_date: delivery_position_details?.license_expire_date
        ? dayjs(delivery_position_details?.license_expire_date)
        : undefined,
    },
  };
  return formatData;
};
