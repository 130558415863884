import { useFormContext } from "react-hook-form";
import TraceEntryTable from "components/Table/Inventory/TraceEntry";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { Typography } from "@mui/material";

const TransactionTab = () => {
  const { watch } = useFormContext();

  const itemUniqueId = watch("unique_id");

  return (
    <CustomizedBox margin={"2rem 0"}>
      <Typography variant="h6" mb={2}>
        {"รายการเคลื่อนไหวสินค้า"}
      </Typography>
      <TraceEntryTable itemUniqueId={itemUniqueId} />
    </CustomizedBox>
  );
};

export default TransactionTab;
