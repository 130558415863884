import { Box, Divider, Grid, Typography } from "@mui/material";
import { ReactNode, useEffect, useRef } from "react";
import { IPdfInfo } from "types/global";
import PDFCompanyInfo from "./CompanyInfo";
import PDFLeftInfo from "./LeftInfo";
import PDFHighlightInfo from "./HighlightInfo";
import PDFRightInfo from "./RightInfo";
import PDFFooter from "./Footer";
import PDFSigner from "./Signer";
import PDFTransitInfo from "./TransitInfo";
import MaintenanceTypeList from "components/Table/Logistic/Maintenance/PDF/TypeList";

type Props = {
  name: string;
  highlightSection: IPdfInfo;
  leftSection: IPdfInfo;
  rightSection: IPdfInfo;
  data: any;
  summary: boolean;
  multiplePage: boolean;
  page: number;
  allPages: number;
  children: ReactNode;
  documentType?: string;
  headerType?: string;
};

const PDFLayout = ({
  name,
  highlightSection,
  leftSection,
  rightSection,
  data,
  summary,
  multiplePage,
  page,
  allPages,
  children,
  documentType,
  headerType,
}: Props) => {
  const headerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.offsetHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${height}px`
      );
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          border: "1px solid #eee",
          borderRadius: "5px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "28px",
          mb: 5,
        }}
      >
        <Box id="print-header" ref={headerRef} height="80mm">
          <Grid container spacing={1.5}>
            <Grid item xs={7}>
              {headerType === "transfer" ? (
                <PDFTransitInfo branch_unique_id={"1"} />
              ) : (
                <PDFCompanyInfo branch_unique_id={"1"} />
              )}
              <Divider sx={{ my: 1, mr: 1 }} />
              <PDFLeftInfo
                headers={leftSection.headers}
                info={leftSection.info}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                fontSize={26.66}
                fontWeight={600}
                align="right"
                color={"primary.main"}
              >
                {name}
              </Typography>
              <PDFHighlightInfo
                headers={highlightSection.headers}
                info={highlightSection.info}
              />
              <PDFRightInfo
                headers={rightSection.headers}
                info={rightSection.info}
              />
            </Grid>
          </Grid>
          {documentType === "maintenance" && page === 1 && (
            <MaintenanceTypeList maTypeList={data?.ma_type || []} />
          )}
          <Box id="print-content">{children}</Box>
        </Box>
        <Box mt="auto">
          {summary ? <PDFFooter data={data} documentType={documentType} /> : ""}
          <Box mt="16px">
            <PDFSigner
              status={data.aggrid_status}
              documentType={documentType}
            />
          </Box>
        </Box>
        {multiplePage && (
          <Box
            sx={{
              fontSize: "10px",
              color: "#737373",
              position: "absolute",
              bottom: 10,
              right: 24,
            }}
          >
            หน้า {page}/{allPages}
          </Box>
        )}
      </Box>
    </>
  );
};

export default PDFLayout;
