import { useCallback, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "components/UI/AgGrid";
import {
  ColDef,
  GridReadyEvent,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { useQuotationColumnDefs } from "./columnDefs";
import { useNavigate } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { dateFilterModel } from "utils/AgGridFilter";
import { QUOTATION_VIEW } from "services/AgGrid/SalesAggrid";

interface Props {
  isFilter?: string | null;
}

const QuotationTable = ({ isFilter }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const columnDef: ColDef[] | undefined = useQuotationColumnDefs();

  const graphQLClientWithHeader: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        issue_date,
        due_date,
        related_user_object_list,
        ...otherFilterModel
      } = filterModel;
      const formatFilter = {
        ...otherFilterModel,
        issue_date: dateFilterModel(issue_date),
        due_date: dateFilterModel(due_date),
        related_user_list: related_user_object_list
          ? {
              filterType: "array",
              type: "hasSome",
              values: related_user_object_list.values,
            }
          : undefined,
      };
      try {
        const { QuotationViewsAggrid } = await graphQLClientWithHeader.request(
          QUOTATION_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: QuotationViewsAggrid.results as any[],
          rowCount: QuotationViewsAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instance = params.api.getFilterInstance("aggrid_status");
      switch (isFilter) {
        case "draft":
          instance?.setModel({ values: ["draft"] });
          break;
        case "wait_accept":
          instance?.setModel({ values: ["wait_accept"] });
          break;
        case "accepted":
          instance?.setModel({ values: ["accepted"] });
          break;
        case "finished":
          instance?.setModel({ values: ["finished"] });
          break;
        case "not_accepted":
          instance?.setModel({ values: ["not_accepted"] });
          break;
        case "cancelled":
          instance?.setModel({ values: ["cancelled"] });
          break;
        default:
          instance?.setModel({
            values: [
              "draft",
              "wait_accept",
              "accepted",
              "finished",
              "not_accepted",
            ],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/sales/quotation/${encodeURIComponent(params.data.id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onRowDoubleClicked={onRowDoubleClicked}
      onGridReady={onGridReady}
      path={"/sales/quotation"}
    />
  );
};

export default QuotationTable;
