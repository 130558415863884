import { Box, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedMenuOptionsMeatball from "components/Custom/CustomizedMenuOptionsMeatball";
import WorkListTable from "components/Table/Logistic/WorkList";
import WorkTotalAmount from "components/Table/Logistic/WorkList/WorkTotalAmount";
import DeleteConfirmation from "components/UI/Modal/DeleteConfirmation";
import WorkListModal from "components/UI/Modal/WorkListModal";
import { DeliveryReportType } from "generated/sales";
import { useDeliveryTripReport } from "hooks/Logistic/DeliveryTrip/use-delivery-trip-report";
import { useDisable } from "hooks/use-disable";
import { useModal } from "hooks/use-modal";
import { useRef, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMenuOption, IShippingCost } from "types/global";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import { workListQueryFormatter } from "utils/Formatter/Logistic/DeliveryTrip";
import { v4 as uuidv4 } from "uuid";

type Props = {
  updateLocationData: () => Promise<any>;
};

const WorkList = ({ updateLocationData }: Props) => {
  const { control, getValues, watch } = useFormContext<IDeliveryTrip>();
  const [disabled] = useDisable();
  const { fields, append, remove, move, replace } = useFieldArray({
    control,
    name: "work_list",
  });

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { modal, openModalHandler, closeModalHandler } = useModal();

  const gridRef = useRef<AgGridReact<any>>(null);

  const { t } = useTranslation();

  const dragEndHandler = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index !== result.source.index) {
      move(result.source.index, result.destination.index);
      const workList = getValues("work_list");
      const vehicleType = getValues("vehicle.type");
      const isFrozen = getValues("is_frozen");
      const newWorkList = await workListQueryFormatter(
        workList,
        vehicleType,
        isFrozen
      );
      replace(newWorkList);
    }
  };

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = async () => {
    setIsDeleting(true);
    if (deletedIndex || deletedIndex === 0) {
      remove(deletedIndex);
    }
    const workList = getValues("work_list");
    const vehicleType = getValues("vehicle.type");
    const isFrozen = getValues("is_frozen");

    const newWorkList = await workListQueryFormatter(
      workList,
      vehicleType,
      isFrozen
    );
    replace(newWorkList);
    setIsDeleting(false);
    closeDeleteItemConfirmationHandler();
  };

  const addLocationHandler = () => {
    const documentUniqueId = getValues("unique_id");
    const selectedLocations = gridRef?.current?.api.getSelectedRows();

    if (selectedLocations) {
      const vehicleType = getValues("vehicle.type");
      const isFrozen = getValues("is_frozen");
      const totalWorkList = getValues("work_list").length;

      // Helper function to get formatted shipping cost
      const formatShippingCost = (shippingCostList: IShippingCost[]) => {
        return (
          shippingCostList.find(
            (cost) => cost.type === vehicleType && cost.is_frozen === isFrozen
          )?.cost || 0
        );
      };

      const formattedLocations = selectedLocations.map((item, index) => {
        const shipping_cost = formatShippingCost(item.shipping_cost);

        return {
          unique_id: uuidv4(),
          location_unique_id: item.unique_id,
          location_name: item.name,
          type: totalWorkList === 0 && index === 0 ? "receive" : "deliver",
          distance: item.distance,
          coordinator_name: item.coordinator_name,
          coordinator_phone: item.coordinator_phone,
          special_request: item.special_request,
          customer_name: item.contact_full_name,
          reference_code: "",
          seal_no: "",
          start_date: undefined,
          end_date: undefined,
          arrived_kilometer: undefined,
          shipping_cost: shipping_cost,
          secondary_driver_cost: item.secondary_driver_cost,
          drop_cost: item.drop_cost,
          max_shipping_cost: 0,
          reference_unique_id: documentUniqueId,
        };
      });

      // Append formatted locations
      formattedLocations.forEach((location) => append(location));

      const newWorkList = getValues("work_list").map((work) => {
        if (work.type === "receive") {
          return {
            ...work,
            shipping_cost: undefined,
            drop_cost: undefined,
            max_shipping_cost: 0,
          };
        }
        return work;
      });

      let maxShippingCost = Math.max(
        ...newWorkList.map((work) => work.shipping_cost || 0)
      );

      // Find the first deliver index in the new work list
      const firstDeliverIndex = newWorkList.findIndex(
        (work) => work.type === "deliver"
      );

      // Set max shipping cost for the first deliver item
      if (newWorkList.length > 0) {
        newWorkList[0].max_shipping_cost = maxShippingCost;
      }

      // Clear drop_cost for the first deliver item if it exists
      if (firstDeliverIndex !== -1) {
        newWorkList[firstDeliverIndex].drop_cost = undefined;
      }

      replace(newWorkList);
    }

    closeModalHandler();
  };

  const vehicleId = watch("vehicle_id");

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;

  const isFinished = status === "finished";

  const updateLocationDataHandler = async () => {
    const newWorkList = await updateLocationData();
    replace(newWorkList);
  };

  const documentUniqueId = getValues("unique_id");

  const options: IMenuOption[] = [
    {
      value: "นำออกรายงาน",
    },
  ];

  const { onBtnExport } = useDeliveryTripReport(
    gridRef,
    DeliveryReportType.DeliveryTripWork,
    documentUniqueId
  );

  const onTypeChange = async () => {
    const workList = getValues("work_list");
    const vehicleType = getValues("vehicle.type");
    const isFrozen = getValues("is_frozen");

    const newWorkList = await workListQueryFormatter(
      workList,
      vehicleType,
      isFrozen
    );

    replace(newWorkList);
  };

  return (
    <>
      <CustomizedBox maxWidth={1650} margin={0}>
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={1}
        >
          <Box display="flex" gap={0.5}>
            <Typography fontWeight={600}>
              {t("logistic.work_list.index")}
            </Typography>
            <Typography color="error.main">*</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems={"center"}
            gap={1}
          >
            {!disabled && (
              <Box>
                <CustomizedButton
                  title="อัปเดตข้อมูล"
                  variant="outlined"
                  onClick={updateLocationDataHandler}
                />
              </Box>
            )}
            {disabled && (
              <Box>
                <CustomizedMenuOptionsMeatball
                  isIcon
                  options={options}
                  onSelect={(e) => {
                    const value = e.target as HTMLElement;
                    switch (value.innerText) {
                      case "นำออกรายงาน":
                        onBtnExport();
                        break;
                      default:
                    }
                  }}
                  sx={{
                    width: "44px",
                    minWidth: "44px",
                  }}
                  disableRipple={true}
                />
              </Box>
            )}
          </Box>
        </Box>
        <WorkListTable
          fields={fields}
          dragEndHandler={dragEndHandler}
          openDeleteItemConfirmationHandler={openDeleteItemConfirmationHandler}
          onTypeChange={onTypeChange}
        />
        <Box
          display={"flex"}
          mt={2}
          justifyContent={fields.length === 0 ? "center" : "flex-start"}
        >
          {!disabled && !isFinished && (
            <CustomizedButton
              startAddIcon
              variant="outlined"
              title={t("logistic.work_list.add")}
              onClick={openModalHandler}
              disabled={!vehicleId}
            />
          )}
        </Box>
        <WorkTotalAmount />
        <DeleteConfirmation
          title="ลบรายการสถานที่"
          message={
            <Box>
              <Typography>
                การลบสถานที่จะมีผลเปลี่ยนแปลงต่อรายการราคา
              </Typography>
            </Box>
          }
          open={openDeleteItemConfirmation}
          handleClose={closeDeleteItemConfirmationHandler}
          action={deleteItemConfirmationAction}
          isLoading={isDeleting}
          noDivider
        />
      </CustomizedBox>
      <WorkListModal
        ref={gridRef}
        itemModal={modal}
        addItemsHandler={addLocationHandler}
        closeItemModalHandler={closeModalHandler}
      />
    </>
  );
};

export default WorkList;
