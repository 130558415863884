import { forwardRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteProps,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import { IOptionsCreatable } from "../../types/global";

const filter = createFilterOptions();

type ComboBoxProps = AutocompleteProps<any, any, any, any, any>;
interface ExtendedProps {
  label?: string;
  sx?: ComboBoxProps["sx"];
  value?: ComboBoxProps["value"];
  inputValue?: ComboBoxProps["inputValue"];
  onInputChange?: ComboBoxProps["onInputChange"];
  onChange: ComboBoxProps["onChange"];
  onKeyDown?: ComboBoxProps["onKeyDown"];
  readOnly?: boolean;
  disabled?: boolean;
  name?: string;
  options: IOptionsCreatable[];
  helperText?: string;
  placeholder?: string;
  error?: boolean;
  required?: boolean;
  endAdornment?: any;
  renderOption?: any;
  testId?: string;
  onBlur?: ComboBoxProps["onBlur"];
}

const CustomizedCreatable = forwardRef<HTMLInputElement, ExtendedProps>(
  (
    {
      label,
      options,
      sx,
      name,
      value,
      inputValue,
      onBlur,
      onInputChange,
      onChange,
      onKeyDown,
      readOnly,
      error,
      helperText,
      required,
      placeholder,
      disabled,
      endAdornment,
      renderOption,
      testId,
    },
    ref
  ) => {
    return (
      <Autocomplete
        data-test-id={testId}
        fullWidth
        disabled={disabled}
        inputValue={inputValue}
        value={value}
        onInputChange={onInputChange}
        onChange={onChange}
        ref={ref}
        autoHighlight
        filterOptions={(options: any, params: any) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option: IOptionsCreatable) => inputValue === option.label
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              label: `เพิ่ม "${inputValue}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        blurOnSelect
        handleHomeEndKeys
        disableClearable={endAdornment ? true : false}
        options={options}
        getOptionLabel={(option: any) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.value || option.type;
        }}
        renderOption={
          renderOption
            ? renderOption
            : (props, option: any) => {
                return (
                  <li
                    {...props}
                    value={option.value || option}
                    key={option.label || option}
                  >
                    {option.label || option}
                  </li>
                );
              }
        }
        sx={sx}
        freeSolo
        onBlur={onBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {endAdornment && endAdornment}
                </>
              ),
            }}
            label={label}
            size="small"
            name={name}
            error={error}
            onKeyDown={onKeyDown}
            helperText={helperText}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            InputLabelProps={{
              ...params.InputLabelProps,
              children: label, // Explicitly cast title to InputLabelProps.children
            }}
          />
        )}
        readOnly={readOnly}
      />
    );
  }
);

export default CustomizedCreatable;
