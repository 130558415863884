import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { Box, Grid, Typography } from "@mui/material";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import ControlledSelect from "components/Controller/ControlledSelect";
import ControlledTextField from "components/Controller/ControlledTextField";
import CustomizedButton from "components/Custom/CustomizedButton";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import { MouseEventHandler } from "react";
import { Control } from "react-hook-form";

const carOptions = [
  {
    label: "4 ล้อ",
    value: "four_wheel",
  },
  {
    label: "6 ล้อ",
    value: "six_wheel",
  },
  {
    label: "10 ล้อ",
    value: "ten_wheel",
  },
  {
    label: "หัวลาก",
    value: "head",
  },
];

const statusOption = [
  {
    label: "ทั้งหมด",
    value: "all",
  },
  {
    label: "ว่าง",
    value: "available",
  },
  {
    label: "ดำเนินการ",
    value: "in_progress",
  },
  {
    label: "ระหว่างซ่อม",
    value: "maintenance",
  },
  {
    label: "อุบัติเหตุ",
    value: "incident",
  },
  {
    label: "หยุดใช้งาน",
    value: "in_active",
  },
];

type Props = {
  control: Control<any>;
  onFilterHandle: MouseEventHandler<HTMLButtonElement>;
  onClearFilterHandle: MouseEventHandler<HTMLButtonElement>;
  onRefreshData: any;
};

const GanttChartFilter = ({
  control,
  onFilterHandle,
  onClearFilterHandle,
  onRefreshData,
}: Props) => {
  return (
    <Box
      sx={{
        width: "80%",
        border: 1,
        borderColor: "#BDBDBD",
        borderRadius: "8px",
        p: 2,
        my: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Box display="flex" gap={0.5} alignItems={"center"}>
            <Typography fontWeight={600}>ตัวกรองการแสดงผล</Typography>
            <CustomizedTooltip title="update-button">
              <CustomizedButton
                variant="text"
                startIcon={<RestartAltOutlinedIcon />}
                title="อัปเดตข้อมูล"
                color="primary"
                onClick={onRefreshData}
              />
            </CustomizedTooltip>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" gap={1} justifyContent={"end"}>
            <CustomizedButton
              title="กรองข้อมูล"
              variant="contained"
              onClick={onFilterHandle}
              sx={{ width: "auto", minWidth: "100px" }}
            />
            <CustomizedButton
              title="ล้างตัวกรอง"
              variant="text"
              onClick={onClearFilterHandle}
              sx={{ width: "auto", minWidth: "100px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <ControlledDatePicker name="date" control={control} label="วันที่" />
        </Grid>
        <Grid item xs={3}>
          <ControlledSelect
            control={control}
            name="car_type"
            options={carOptions}
            label="ประเภทรถ"
          />
        </Grid>
        <Grid item xs={3}>
          <ControlledSelect
            control={control}
            name="status"
            options={statusOption}
            label="สถานะ"
          />
        </Grid>
        <Grid item xs={3}>
          <ControlledTextField
            control={control}
            label="รหัสรถ"
            name="license_plate"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GanttChartFilter;
