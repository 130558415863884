import { Typography, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";

import { IBreadcrumbsAndMenu, ITab, ICardList } from "types/global";

import HeaderLayout from "components/UI/HeaderLayout";
import CustomizedTab from "components/Custom/CustomizedTab";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import ItemTable from "components/Table/Inventory/Item";
import TotalBox from "components/UI/TotalBox";
import { useItemsCountQuery, ItemsCountQuery } from "generated/wms";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import CustomizedMenuOptions from "components/Custom/CustomizedMenuOptions";
import BarcodeScanNavigate from "components/UI/BarcodeScanNavigate";
import CustomizedButton from "components/Custom/CustomizedButton";

const Item = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware("wms");

  const { data: itemCountData } =
    useItemsCountQuery<ItemsCountQuery>(graphQLClient);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.item.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("inventory.item.normal"),
      path: `${pathname}?filter=normal`,
    },
    {
      label: t("inventory.item.service"),
      path: `${pathname}?filter=service`,
    },
    {
      label: t("status.in_active"),
      path: `${pathname}?filter=in_active`,
    },
  ];

  const CardList: ICardList[] = useMemo(
    () => [
      {
        title: t("inventory.item.all"),
        field: "all",
        borderColor: "#2167D3",
      },
      {
        title: t("inventory.item.normal"),
        field: "normal",
        borderColor: "#2167D3",
      },
      {
        title: t("inventory.item.service"),
        field: "service",
        borderColor: "#2167D3",
      },
    ],
    [t]
  );

  const renderCard = (list: ICardList[]) =>
    list.map((menu: ICardList, index) => {
      let count;
      switch (index) {
        case 0:
          count = itemCountData?.ItemsCount?.total;
          break;
        case 1:
          count = itemCountData?.ItemsCount?.normal;
          break;
        case 2:
          count = itemCountData?.ItemsCount?.service;
          break;
        default:
          count = 0;
      }
      return (
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4} key={menu.title}>
          <TotalBox
            title={menu.title}
            value={count}
            borderColor={menu.borderColor}
          />
        </Grid>
      );
    });

  const options = [
    {
      value: "สินค้าธรรมดา",
    },
    {
      value: "บริการ",
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <BarcodeScanNavigate />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {renderCard(CardList)}
      </Grid>
      <HeaderLayout noMaxWidth>
        <Typography variant="h5">{t("inventory.item.index")}</Typography>
        <Box display="flex" alignItems="center">
          <Box ml="auto">
            <CustomizedButton
              title={`นำเข้า${t("inventory.item.index")}`}
              variant="outlined"
              onClick={() => navigate(`/inventory/item/import`)}
              sx={{
                height: "33px",
                minHeight: "33px",
                mr: 2,
              }}
            />
            <CustomizedMenuOptions
              label={t("button.create") + t("inventory.item.index")}
              variant={"contained"}
              options={options}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case "สินค้าธรรมดา":
                    navigate(`${pathname}/add?tab=item&subtab=general`);
                    break;
                  case "บริการ":
                    navigate(`../service/add?tab=item&subtab=general`);
                    break;
                  default:
                    break;
                }
              }}
              startAddIcon
              endIcon
              sx={{
                height: "33px",
                minHeight: "33px",
              }}
            />
          </Box>
        </Box>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <ItemTable type={isFilter} />
    </>
  );
};

export default Item;
