import { IContact } from "../../types/Contact/contact";
import { uploadFileToS3 } from "../s3";
import { IAttachment, IContactChannel } from "../../types/global";
import {
  ContactCreateInput,
  ContactFindUniqueQuery,
  ContactUpdateUniput,
} from "../../generated/crm";
import { IUser } from "types/Auth/user";

export const contactCreateFormatter = async (
  data: IContact,
  id: string | undefined,
  user: IUser | null
) => {
  const {
    status,
    img_url,
    tag_list,
    attachment_list,
    contact_channel_list,
    contact_person_list,
    is_cheque,
    is_transfer,
    is_cash,
    ...otherData
  } = data;

  const payment_type_list = [];
  if (is_cheque) {
    payment_type_list.push("is_cheque");
  }
  if (is_transfer) {
    payment_type_list.push("is_transfer");
  }
  if (is_cash) {
    payment_type_list.push("is_cash");
  }

  let uploded_img: string[] = [];
  if (img_url && img_url.length > 0) {
    const filteredFile = img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "contact",
        id || ""
      );
      uploded_img.push(Location);
    } else {
      uploded_img = img_url;
    }
  }

  const transformedContactPersons = await Promise.all(
    contact_person_list?.map(async (person: any, index: number) => {
      let updatedImgUrl: string[] = [];
      if (person.img_url && person.img_url.length > 0) {
        const filteredFile = person.img_url.filter(
          (img: string | File) => img instanceof File
        );

        if (filteredFile && filteredFile.length > 0) {
          const { Location } = await uploadFileToS3(
            filteredFile[0],
            "contact",
            data?.unique_id + person.first_name + person.last_name + index || ""
          );
          updatedImgUrl.push(Location);
        } else {
          updatedImgUrl = data.img_url;
        }
        const formatContactChannelList: IContactChannel[] = [];
        person.contact_channel_list.forEach((channel: IContactChannel) => {
          if (
            (channel.contact_channel_info &&
              channel.contact_channel_info.trim().length > 0) ||
            (channel.contact_channel_type &&
              channel.contact_channel_type.trim().length > 0) ||
            index === 0
          ) {
            formatContactChannelList.push(channel);
          }
        });
        return {
          ...person,
          img_url: updatedImgUrl || [],
          contact_channel_list: formatContactChannelList,
        };
      } else {
        const formatContactChannelList: IContactChannel[] = [];
        person.contact_channel_list.forEach((channel: IContactChannel) => {
          if (
            (channel.contact_channel_info &&
              channel.contact_channel_info.trim().length > 0) ||
            (channel.contact_channel_type &&
              channel.contact_channel_type.trim().length > 0) ||
            index === 0
          ) {
            formatContactChannelList.push(channel);
          }
        });
        return { ...person, contact_channel_list: formatContactChannelList };
      }
    }) || []
  );

  let uploadedAttachment: IAttachment[] = [];
  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "contact",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        uploadedAttachment.push(formatAttachment);
      } else {
        uploadedAttachment.push(file);
      }
    }
  }

  const tag_id_list: number[] = [];

  tag_list.forEach((tag) => {
    if (tag.id) {
      tag_id_list.push(tag.id);
    }
  });

  let formatContactChannelList: IContactChannel[] = [];

  contact_channel_list.forEach((channel, index) => {
    if (
      channel.contact_channel_info.trim().length > 0 ||
      channel.contact_channel_type.trim().length > 0 ||
      index === 0
    ) {
      formatContactChannelList.push(channel);
    }
  });

  const formatData: ContactCreateInput = {
    ...otherData,
    status:
      (typeof status === "string" && status === "1") || status === 1 ? 1 : 0,
    img_url: uploded_img,
    tag_id_list,
    attachment_list: uploadedAttachment,
    contact_channel_list: formatContactChannelList,
    contact_person_list: transformedContactPersons,
    sales_person_list:
      otherData.sales_person_list as ContactCreateInput["sales_person_list"],
    created_by: {
      email: user?.email,
      first_name: user?.first_name,
      img_url: user?.img_url,
      last_name: user?.last_name,
      user_id: user?.id,
      user_unique_id: user?.unique_id,
    },
    payment_type_list: payment_type_list,
  };
  return formatData;
};

export const contactUpdateFormatter = async (
  data: IContact,
  user: IUser | null
) => {
  const {
    status,
    img_url,
    id,
    tag_list,
    created_date,
    attachment_list,
    contact_channel_list,
    contact_person_list,
    is_cheque,
    is_transfer,
    is_cash,
    ...otherData
  } = data;

  const payment_type_list = [];
  if (is_cheque) {
    payment_type_list.push("is_cheque");
  }
  if (is_transfer) {
    payment_type_list.push("is_transfer");
  }
  if (is_cash) {
    payment_type_list.push("is_cash");
  }

  let uploded_img: string[] = [];
  if (img_url && img_url.length > 0) {
    const filteredFile = img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "contact",
        id?.toString() || ""
      );
      uploded_img.push(Location);
    } else {
      uploded_img = img_url;
    }
  }

  const transformedContactPersons = await Promise.all(
    contact_person_list?.map(async (person: any, index: number) => {
      let updatedImgUrl: string[] = [];
      if (person.img_url && person.img_url.length > 0) {
        const filteredFile = person.img_url.filter(
          (img: string | File) => img instanceof File
        );

        if (filteredFile && filteredFile.length > 0) {
          const { Location } = await uploadFileToS3(
            filteredFile[0],
            "contact",
            data?.unique_id + person.unique_id + index || ""
          );
          updatedImgUrl.push(Location);
        } else {
          updatedImgUrl = person.img_url;
        }
        const formatContactChannelList: IContactChannel[] = [];
        person.contact_channel_list.forEach((channel: IContactChannel) => {
          if (
            (channel.contact_channel_info &&
              channel.contact_channel_info.trim().length > 0) ||
            (channel.contact_channel_type &&
              channel.contact_channel_type.trim().length > 0) ||
            index === 0
          ) {
            formatContactChannelList.push(channel);
          }
        });
        return {
          ...person,
          img_url: updatedImgUrl || [],
          contact_channel_list: formatContactChannelList,
        };
      } else {
        const formatContactChannelList: IContactChannel[] = [];
        person.contact_channel_list.forEach((channel: IContactChannel) => {
          if (
            (channel.contact_channel_info &&
              channel.contact_channel_info.trim().length > 0) ||
            (channel.contact_channel_type &&
              channel.contact_channel_type.trim().length > 0) ||
            index === 0
          ) {
            formatContactChannelList.push(channel);
          }
        });
        return { ...person, contact_channel_list: formatContactChannelList };
      }
    }) || []
  );

  let uploadedAttachment: IAttachment[] = [];
  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "contact",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        uploadedAttachment.push(formatAttachment);
      } else {
        uploadedAttachment.push(file);
      }
    }
  }

  const tag_id_list: number[] = [];

  tag_list.forEach((tag) => {
    if (tag.id) {
      tag_id_list.push(tag.id);
    }
  });

  let formatContactChannelList: IContactChannel[] = [];

  contact_channel_list.forEach((channel, index) => {
    if (
      channel.contact_channel_info.trim().length > 0 ||
      channel.contact_channel_type.trim().length > 0 ||
      index === 0
    ) {
      formatContactChannelList.push(channel);
    }
  });

  const formatData: ContactUpdateUniput = {
    ...otherData,
    last_updated_by: {
      email: user?.email,
      first_name: user?.first_name,
      img_url: user?.img_url,
      last_name: user?.last_name,
      user_id: user?.id,
      user_unique_id: user?.unique_id,
    },
    status:
      (typeof status === "string" && status === "1") || status === 1 ? 1 : 0,
    img_url: uploded_img,
    tag_id_list,
    contact_channel_list: formatContactChannelList,
    attachment_list: uploadedAttachment,
    contact_person_list: transformedContactPersons,
    sales_person_list:
      (otherData.sales_person_list as ContactUpdateUniput["sales_person_list"]) ||
      [],
    payment_type_list: payment_type_list,
  };
  return formatData;
};

export const contactQueryFotmatter = (
  data: ContactFindUniqueQuery["ContactFindUnique"]
) => {
  const is_cheque = data?.payment_type_list?.includes("is_cheque") || false;
  const is_transfer = data?.payment_type_list?.includes("is_transfer") || false;
  const is_cash = data?.payment_type_list?.includes("is_cash") || false;
  const formatData: IContact = {
    is_cheque,
    is_transfer,
    is_cash,
    ...data,
  } as IContact;
  return formatData;
};

export const sourceFormatter = (source: string, isThai: boolean = true) => {
  const formatToThai = (source: string) => {
    switch (source) {
      case "sales_person":
        return "พนักงานขาย";
      case "facebook":
        return "Facebook";
      case "instagram":
        return "Instagram";
      case "website":
        return "Website";
      case "email":
        return "Email";
      case "line":
        return "Line";
      case "phone":
        return "เบอร์โทรศัพท์";
      case "market_place":
        return "Market Place";
      case "other":
        return "อื่นๆ";
      default:
        return "-";
    }
  };

  const migrateToEnglish = (source: string) => {
    switch (source) {
      case "พนักงานขาย":
        return "sales_person";
      case "Facebook":
        return "facebook";
      case "Instagram":
        return "instagram";
      case "Website":
        return "website";
      case "Email":
        return "email";
      case "Line":
        return "line";
      case "เบอร์โทรศัพท์":
        return "phone";
      case "Market Place":
        return "market_place";
      case "อื่นๆ":
        return "other";
      default:
        return undefined;
    }
  };

  return isThai ? formatToThai(source) : migrateToEnglish(source);
};
