import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography, Radio, Grid } from "@mui/material";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import CustomizedButton from "components/Custom/CustomizedButton";
import dayjs from "dayjs";
import * as Yup from "yup";
import { BillingNoteReportType } from "generated/sales";
import { GraphQLClient } from "graphql-request";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { BILLING_NOTE_GENERATE_REPORT } from "services/Sales/Report/GenerateReport";
import { IExporterBillingNote } from "types/global";
import ModalUI from "./ModalUI";
import { getBillingNoteExporterSchema } from "utils/Formatter/Sales/BillingNoteExport";


type Props = {
    open: boolean;
    closeModalHandler: () => void;
    documentType: BillingNoteReportType;
  };
  
  const exporterValidation = Yup.object().shape({
    type: Yup.string().required("กรุณาเลือกประเภทข้อมูล"),
    start_date: Yup.date().when("report_type", {
      is: "date_range",
      then: (schema) => schema.required("กรุณาเลือกวันที่"),
      otherwise: (schema) => schema.nullable(),
    }),
    end_date: Yup.date().when("report_type", {
      is: "date_range",
      then: (schema) => schema.required("กรุณาเลือกวันที่"),
      otherwise: (schema) => schema.nullable(),
    }),
  });
  
  const ExporterBillingNoteModal = ({
    open,
    closeModalHandler,
    documentType,
  }: Props) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const {
      control,
      setValue,
      handleSubmit,
      formState: { isValid },
      reset,
      watch,
    } = useForm<IExporterBillingNote>({
      defaultValues: getBillingNoteExporterSchema(documentType),
      resolver: yupResolver<any>(exporterValidation),
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
  
    const graphQLClientWithHeaderSales: GraphQLClient =
      createGraphQLClientWithMiddleware("sales");
  
    // const typeOptions: ISelectOption[] =
    //   getPurchaseExportTypeOptions(documentType);
  
    const onClose = () => {
      reset(getBillingNoteExporterSchema(documentType));
      closeModalHandler();
    };
  
    const exportHandler = async (data: IExporterBillingNote) => {
      setIsLoading(true);
      if (data) {
        const { type, start_date, end_date } = data;
        const formattedStartDate = start_date
          ? dayjs(start_date).startOf("day")
          : null;
        const formattedEndDate = end_date ? dayjs(end_date).endOf("day") : null;
  
        try {
          const { GenerateBillingNoteReport } =
            await graphQLClientWithHeaderSales.request(BILLING_NOTE_GENERATE_REPORT, {
              input: {
                report_type: type,
                start_date: formattedStartDate,
                end_date: formattedEndDate,
              },
            });
  
          window.open(GenerateBillingNoteReport, "_blank", "noopener,noreferrer");
  
          setIsLoading(false);
  
          enqueueSnackbar(
            `นำออก${
              documentType === BillingNoteReportType.BillingNote
                ? "ไฟล์ใบวางบิล"
                : ""
            }สำเร็จ`,
            {
              variant: "success",
            }
          );
        } catch (error) {
          setIsLoading(false);
  
          enqueueSnackbar(
            `นำออก${
              documentType === BillingNoteReportType.BillingNote
                ? "ไฟล์ใบวางบิล"
                : ""
            }ไม่สำเร็จ`,
            {
              variant: "error",
            }
          );
          console.error(error);
        }
        closeModalHandler();
      }
    };
  
    // const watchPurchaseType = watch("type");
    const watchExportType = watch("report_type");
  
    const onClickAll = () => {
      setValue("report_type", "all");
      setValue("start_date", null);
      setValue("end_date", null);
    };
    const onClickRange = () => {
      setValue("report_type", "date_range");
    };
  
    return (
      <ModalUI
        titleSize={"24px"}
        title={t("button.export")}
        open={open}
        handleClose={onClose}
        maxWidth={"xs"}
        titleSx={{ paddingBottom: 1.7 }}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "8px",
          },
        }}
        action={
          <Box sx={{ display: "flex", gap: 1.9 }}>
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              onClick={onClose}
            />
            <CustomizedButton
              title={t("button.confirm")}
              variant="contained"
              onClick={handleSubmit((data) => {
                exportHandler(data)
                  .then(() => {
                    if (isValid) {
                      onClose();
                    }
                  })
                  .catch((error) => {
                    console.error("Export failed:", error);
                  });
              })}
              disabled={isLoading}
            />
          </Box>
        }
      >
        <Typography fontSize={"14px"} fontWeight={600} mb={1.6}>
          ช่วงเวลา
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          sx={{ marginBottom: watchExportType === "all" ? 0 : 1.7 }}
        >
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            onClick={() => {
              onClickAll();
            }}
            sx={{ cursor: "pointer" }}
          >
            <Radio checked={watchExportType === "all"} />
            <Typography color={"#333333"} fontSize={"14px"}>
              {t("global.all")}
            </Typography>
          </Box>
  
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            onClick={() => {
              onClickRange();
            }}
            sx={{ cursor: "pointer" }}
          >
            <Radio checked={watchExportType === "date_range"} />
            <Typography color={"#333333"} fontSize={"14px"}>
              {t("global.date_range")}
            </Typography>
          </Box>
        </Box>
        {watchExportType === "date_range" && (
          <>
            <Grid container spacing={1.5}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledDatePicker
                  control={control}
                  name="start_date"
                  label={t("date.start_date")}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledDatePicker
                  control={control}
                  name="end_date"
                  label={t("date.end_date")}
                  required
                />
              </Grid>
            </Grid>
          </>
        )}
      </ModalUI>
    );
  };
  
  export default ExporterBillingNoteModal;
  