import { gql } from "graphql-request";

export const PURCHASE_BY_DUE_DATE_EXPORT_VIEW = gql`
  query PurchaseByDueDateExportViewsAggrid($aggridInput: AnyAggridInput) {
    PurchaseByDueDateExportViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        unique_id
        status
        due_date
        supplier_unique_id
        supplier_name
        net_amount
        vat_amount
        total_amount
        pre_vat_amount
        additional_discount
        post_discount_amount
        withholding_tax_amount
      }
    }
  }
`;
