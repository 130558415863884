import { gql } from "graphql-request";

export const VEHICLE_FIND_UNIQUE = gql`
  query VehicleFindUnique($vehicleFindUniqueId: Int!) {
    VehicleFindUnique(id: $vehicleFindUniqueId) {
      id
      unique_id
      register_id
      type
      driver {
        user_id
        first_name
        last_name
        email
        img_url
      }
      current_kilometer
      due_distance_kilometer
      brand
      model
      detail
      act_id
      act_end_date
      status
      status_note
      created_date

      created_by
      last_updated_by
      trailer {
        id
        unique_id
        register_id
        type
        driver {
          user_id
          first_name
          last_name
          email
          img_url
        }
        current_kilometer
        due_distance_kilometer
        brand
        model
        detail
        act_id
        act_end_date
        status
        created_date
        last_updated_date
        created_by
        updated_by
      }
      image {
        id
        is_primary
        file_path
      }
      insurance_vehicle {
        id
        tier
        policy_id
        protection_value
        type
        start_date
        end_date
      }
      insurance_product {
        id
        tier
        policy_id
        protection_value
        type
        start_date
        end_date
      }
      last_updated_date
    }
  }
`;
