import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  CheckboxSelectionCallbackParams,
  ColDef,
  RowSelectedEvent,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReactProps } from "ag-grid-react";
import AgGrid from "components/UI/AgGrid";
import CustomizedButton from "components/Custom/CustomizedButton";
import ModalUI from "components/UI/Modal/ModalUI";
import ButtonLayout from "components/UI/ButtonLayout";
import { useTranslation } from "react-i18next";

type ExtendedProps = {
  gridRef: any;
  modalTitle: string;
  cancelBtnTitle?: string;
  btnTitle: string;
  modalIsOpen: boolean;
  height: number | string;
  onFinishEditing: (data: any) => void;
  closeModal: () => void;
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  idsSnapshot: string[];
  setIdsSnapshot: React.Dispatch<React.SetStateAction<string[]>>;
  lockRows?: (string | undefined)[];
  disabledSidebar?: boolean;
  rowsToDisable?: any;
  columnDefs: ColDef[];
  isLoading?: boolean;
  keyName?: string;
};

type CheckboxAggridModalProps = AgGridReactProps & ExtendedProps;

const CheckboxAggridModal: React.FC<CheckboxAggridModalProps> = ({
  gridRef,
  modalTitle,
  modalIsOpen,
  columnDefs,
  rowSelection,
  height,
  rowData,
  onFinishEditing,
  enableRangeSelection,
  closeModal,
  selectedIds,
  setSelectedIds,
  idsSnapshot,
  setIdsSnapshot,
  lockRows,
  getRowId,
  rowsToDisable,
  onFirstDataRendered,
  masterDetail,
  detailCellRendererParams,
  onRowClicked,
  onRowDoubleClicked,
  disabledSidebar,
  isLoading,
  onGridReady,
  children,
  keyName,
}: CheckboxAggridModalProps) => {
  const { t } = useTranslation();
  const [updatedColumnDefs, setUpdatedColumnDefs] = useState<ColDef<any>[]>([]);

  const isRowSelected = useCallback(
    (params: ValueGetterParams) => {
      const idKeyName = keyName ? keyName : columnDefs[0]?.field;
      return !!idKeyName && selectedIds.includes(params?.node?.data[idKeyName]);
    },
    [columnDefs, keyName, selectedIds]
  );

  const checkboxColumn = useMemo(() => {
    return {
      ...columnDefs[0],
      checkboxSelection: (params: CheckboxSelectionCallbackParams) => {
        if (!rowsToDisable || rowsToDisable.length === 0) return true;
        const idKeyName = keyName ? keyName : columnDefs[0]?.field;
        return !idKeyName || !rowsToDisable.includes(params?.data[idKeyName]);
      },
      valueGetter: (params: ValueGetterParams) => {
        if (isRowSelected(params) && params?.node)
          params.node.setSelected(true);
        const idKeyName = columnDefs[0]?.field;
        return idKeyName ? params?.data[idKeyName] : undefined;
      },
    };
  }, [columnDefs, rowsToDisable, keyName, isRowSelected]);

  useEffect(() => {
    if (columnDefs && checkboxColumn) {
      const newColumnDefs: ColDef[] = [checkboxColumn, ...columnDefs.slice(1)];
      setUpdatedColumnDefs(newColumnDefs);
    }
  }, [columnDefs, rowsToDisable, selectedIds, checkboxColumn]);

  const handleRowSelection = (params: RowSelectedEvent) => {
    const idKeyName = keyName ? keyName : columnDefs[0]?.field;
    if (!idKeyName) return setSelectedIds([]);

    const selectedId = params?.node?.data[idKeyName];
    if (!selectedId) {
      setSelectedIds([]);
      return;
    }

    if (rowsToDisable?.includes(selectedId)) {
      params?.node?.setSelected(false);
    } else if (lockRows?.includes(selectedId)) {
      params?.node?.setSelected(true);
    } else {
      if (params?.node?.isSelected()) {
        setSelectedIds((prevIds: string[]) => [...prevIds, selectedId]);
      } else {
        setSelectedIds((prevIds: string[]) =>
          prevIds.filter((id: string) => id !== selectedId)
        );
      }
    }
  };

  const handleFinishSelecting = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setIdsSnapshot(selectedIds);
    const selectedData =
      rowSelection === "single" ? selectedRows[0] : selectedRows;
    onFinishEditing(selectedData || null);
    closeModal();
  };

  const handleCancelModal = () => {
    setSelectedIds(idsSnapshot);
    closeModal();
  };

  return (
    <ModalUI
      open={modalIsOpen}
      handleClose={handleCancelModal}
      title={modalTitle}
      fullWidth
      maxWidth="lg"
      isLoading={isLoading}
      action={
        <ButtonLayout>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            size="medium"
            onClick={handleCancelModal}
          />
          <CustomizedButton
            title={t("button.confirm")}
            onClick={handleFinishSelecting}
            variant="contained"
            size="medium"
          />
        </ButtonLayout>
      }
    >
      {children}
      <AgGrid
        ref={gridRef}
        columnDefs={updatedColumnDefs}
        height={height}
        onGridReady={onGridReady}
        rowSelection={rowSelection}
        rowData={rowData}
        onRowSelected={handleRowSelection}
        onRowClicked={onRowClicked}
        onRowDoubleClicked={onRowDoubleClicked}
        enableRangeSelection={enableRangeSelection}
        getRowId={getRowId}
        rowMultiSelectWithClick={rowSelection !== "single"}
        onFirstDataRendered={onFirstDataRendered}
        masterDetail={masterDetail}
        detailCellRendererParams={detailCellRendererParams}
        disabledSidebar={disabledSidebar}
      />
    </ModalUI>
  );
};

export default CheckboxAggridModal;
