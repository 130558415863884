import { gql } from "graphql-request";

export const GET_STOCK_REPORT_VIEW = gql`
  query CurrentStockByWarehouseReportView($aggridInput: AnyAggridInput) {
    CurrentStockByWarehouseReportView(aggridInput: $aggridInput) {
      results {
        unique_id
        seller_sku
        name
        warehouse_id
        warehouse_unique_id
        warehouse_name
        available_qty
        stock_qty
        min_purchase_request_qty
        min_stock_qty
        max_stock_qty
        reorder_point
        status
      }
      count
    }
  }
`;

export const GET_TRACE_ENTRY_REPORT_VIEW = gql`
  query TraceEntryReportView($aggridInput: AnyAggridInput) {
    TraceEntryReportView(aggridInput: $aggridInput) {
      results {
        created_date
        posted_date
        source_warehouse_id
        source_warehouse_name
        source_bin_location_id
        source_bin_location_name
        destination_warehouse_id
        destination_warehouse_name
        destination_bin_location_id
        destination_bin_location_name
        item_unique_id
        seller_sku
        item_name
        serial_no
        qty
        uom
        type
        reference_unique_id
        created_by_name
      }
      count
    }
  }
`;

export const GET_NO_ENTRY_REPORT_VIEW = gql`
  query NoTraceEntryReportView($aggridInput: AnyAggridInput) {
    NoTraceEntryReportView(aggridInput: $aggridInput) {
      results {
        created_date
        unique_id
        seller_sku
        name
        type
        is_purchasable
        is_saleable
        is_stockable
        description
        stock_qty
        available_qty
        cate_1
        cate_1_id
        is_active
      }
      count
    }
  }
`;

export const GET_CURRENT_STOCK_BY_SN_REPORT_VIEW = gql`
  query CurrentStockBySNReportView($aggridInput: AnyAggridInput) {
    CurrentStockBySNReportView(aggridInput: $aggridInput) {
      results {
        unique_id
        seller_sku
        name
        cate_1
        cate_1_id
        serial_no
        barcode
        warehouse_id
        warehouse_unique_id
        warehouse_name
        bin_location_id
        bin_name
        stock_qty
        lot_date
      }
      count
    }
  }
`;

export const GET_ALL_UOM = gql`
  query Uoms {
    uoms {
      id
      unique_id
      name
    }
  }
`;

export const GET_BIN_LOCATION = gql`
  query BinLocationsFindAll {
    BinLocationsFindAll {
      id
      name
      warehouse_level_3 {
        id
        name
        warehouse_level_2 {
          id
          name
          warehouse_level_1 {
            id
            name
            warehouse {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_WAREHOUSES = gql`
  query WarehousesFindAll($whereInput: WarehouseWhereInput) {
    WarehousesFindAll(whereInput: $whereInput) {
      id
      unique_id
      name
      warehouse_level_1_list {
        id
        name
        warehouse_level_2_list {
          id
          name
          warehouse_level_3_list {
            id
            name
            bin_location_list {
              name
              id
            }
          }
        }
      }
    }
  }
`;
