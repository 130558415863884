import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import EditIcon from "@mui/icons-material/Edit";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../Controller/ControlledTextField";

import CheckedSupplierModal from "../../../UI/Modal/CheckedModal/CheckedSupplierModal";

import { useDisable } from "../../../../hooks/use-disable";
import { useContactDescription } from "../../../../hooks/use-contact-description";

type Props = {
  forceDisabled?: boolean;
};

const SupplierInfo = ({ forceDisabled }: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    openContactTable,
    closeContactTableHandler,
    contactIds,
    setContactIds,
    contactIdsSnapshot,
    setContactIdsSnapshot,
    finishContactsSelect,
    openContactTableHandler,
    selectedContactId,
    openContactDetail,
  } = useContactDescription("purchase");

  return (
    <Fragment>
      <CustomizedBox>
        <Box display="flex" gap={1} alignItems="center" mb={2}>
          <Typography fontWeight={600}>
            {t("purchase.supplier.description")}
          </Typography>
          {!disabled && !forceDisabled && (
            <CustomizedTooltip title={t("sentence.choose_customer")}>
              <IconButton onClick={openContactTableHandler} color="primary">
                <EditIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
          {disabled && selectedContactId && (
            <CustomizedTooltip title="กดเพื่อดูรายละเอียดซัพพลายเออร์">
              <IconButton onClick={openContactDetail} color="primary">
                <LaunchOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Box>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={4} display={"flex"}>
            <ControlledTextField
              control={control}
              name="supplier_details.unique_id_name"
              label={
                t("purchase.supplier.unique_id") +
                " - " +
                t("purchase.supplier.name")
              }
              disabled
              viewMode={disabled}
              error={Boolean((errors as any).supplier_details?.unique_id_name)}
              helperText={(
                errors as any
              )?.supplier_details?.unique_id_name?.message?.toString()}
              required
            />
            {!disabled && selectedContactId && (
              <CustomizedTooltip title="กดเพื่อดูรายละเอียดซัพพลายเออร์">
                <IconButton onClick={openContactDetail} color="primary">
                  <LaunchOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="supplier_details.identity_no"
              label={t("sales.identity_no")}
              viewMode={disabled}
              disabled
            />
          </Grid>
          <Box width="100%" />
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="supplier_details.phone"
              label={t("global.phone_number")}
              viewMode={disabled}
              disabled={forceDisabled}
              error={Boolean((errors as any).supplier_details?.phone)}
              helperText={(
                errors as any
              )?.supplier_details?.phone?.message?.toString()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="supplier_details.email"
              label={t("global.email")}
              viewMode={disabled}
              disabled={forceDisabled}
              error={Boolean((errors as any).supplier_details?.["email"])}
              helperText={(
                errors as any
              )?.supplier_details?.email?.message?.toString()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="supplier_details.fax"
              label={t("global.fax")}
              viewMode={disabled}
              disabled={forceDisabled}
              error={Boolean((errors as any).supplier_details?.fax)}
              helperText={(
                errors as any
              )?.supplier_details?.fax?.message?.toString()}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <CheckedSupplierModal
        showSelectSupplier={openContactTable}
        closeSupplierTable={closeContactTableHandler}
        supplierIds={contactIds}
        setSupplierIds={setContactIds}
        supplierIdsSnapshot={contactIdsSnapshot}
        setSupplierIdsSnapshot={setContactIdsSnapshot}
        finishSuppliersSelect={finishContactsSelect}
        rowSelection="single"
      />
    </Fragment>
  );
};

export default SupplierInfo;
