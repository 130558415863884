import { useEffect, RefObject } from "react";
import { useNoEntryReportColumnDefs } from "./columnDefs";
import { GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import AgGrid from "components/UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { GET_NO_ENTRY_REPORT_VIEW } from "services/AgGrid/InventoryReportAgGrid";
import { dateFilterModel } from "utils/AgGridFilter";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const NoEntryReportTable = ({ gridRef }: Props) => {
  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const columnDefs = useNoEntryReportColumnDefs();
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { is_active, created_date, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => parseInt(v)),
        },
        created_date: dateFilterModel(created_date),
      };
      try {
        const { NoTraceEntryReportView } = await graphQLClientWms.request(
          GET_NO_ENTRY_REPORT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: NoTraceEntryReportView.results as any[],
          rowCount: NoTraceEntryReportView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
    }
  }, [gridRef]);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onGridReady={onGridReady}
    />
  );
};

export default NoEntryReportTable;
