import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { Fragment } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";

const InventoryReport = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.itemStock.index"),
      to: "stock",
    },
    {
      name: t("inventory.noEntry.index"),
      to: "no-entry",
    },
    {
      name: t("inventory.traceEntry.index"),
      to: "trace-entry",
    },
    {
      name: t("inventory.itemCurrentStockSerialNumber.index"),
      to: "current-stock-by-sn",
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default InventoryReport;
