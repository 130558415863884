import { useCallback, useEffect, useRef } from "react";
import useContactColumnDefs from "./columnDefs";
import {
  GridReadyEvent,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import AgGrid from "../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { CONTACT_VIEW } from "../../../services/AgGrid/ContactAgGrid";
import { dateFilterModel } from "../../../utils/AgGridFilter";

type Props = {
  type: "all" | "customer" | "supplier";
};

const ContactTable = ({ type }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const graphQLClientContact: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const columnDefs = useContactColumnDefs();
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { status, tag_list, contact_type, created_date, ...otherFilter } =
        filterModel;
      const formatFilter = {
        ...otherFilter,
        status: status && {
          ...status,
          values: status.values.map((v: string) => parseInt(v)),
        },
        contact_type: contact_type && {
          type: "hasSome",
          filterType: "array",
          values: contact_type.values,
        },
        tag_list: tag_list && {
          type: "hasSome",
          filterType: "array",
          values: tag_list.values,
        },
        created_date: dateFilterModel(created_date),
      };
      try {
        const { ContactViewAggrid } = await graphQLClientContact.request(
          CONTACT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: ContactViewAggrid.results as any[],
          rowCount: ContactViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instance = params.api.getFilterInstance("contact_type");
      const statusInstance = params.api.getFilterInstance("status");
      switch (type) {
        case "all":
          instance?.setModel({});
          break;
        case "customer":
          instance?.setModel({ values: ["is_customer"] });
          break;
        case "supplier":
          instance?.setModel({ values: ["is_supplier"] });
          break;

        default:
          instance?.setModel({});
          break;
      }
      statusInstance?.setModel({
        values: ["1"],
      });
      params.api.onFilterChanged();
    },
    [type]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/contact/all/${id}?tab=address`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onRowDoubleClicked={rowSelectHandler}
      onGridReady={onGridReady}
      path={`/contact/all`}
    />
  );
};

export default ContactTable;
