import { useFieldArray, useFormContext } from "react-hook-form";
import { IContact, ISalesPerson } from "../../../types/Contact/contact";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../../Custom/CustomizedButton";
import { Box, Grid, Typography } from "@mui/material";
import { useDisable } from "../../../hooks/use-disable";
import { useModal } from "../../../hooks/use-modal";
import { useEffect, useState } from "react";
import CheckedUserModal from "../../UI/Modal/CheckedModal/CheckedUserModal";
import SalesPersonCard from "../../UI/SalesPersonCard";
import { useSnackbar } from "notistack";

const SalesPersonList = () => {
  const { control } = useFormContext<IContact>();
  const { t } = useTranslation();

  const [disabled] = useDisable();

  const { fields, remove, replace } = useFieldArray({
    control,
    name: "sales_person_list",
  });

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const { enqueueSnackbar } = useSnackbar();

  const [userIds, setUsersIds] = useState<string[]>([]);
  const [userIdsSnapshot, setUsersIdsSnapshot] = useState<string[]>([]);

  const finishUsersSelect = (data: any[]) => {
    if (data.length <= 12) {
      // filter out users not selected in current modal session
      let filteredUsers = fields.filter((user) =>
        userIds.includes(user.unique_id)
      );

      // get current users id to prevent duplication when appending newly selected users
      const filteredUsersId = filteredUsers.map((user) => user.unique_id);

      data.forEach((user) => {
        if (!filteredUsersId.includes(user.unique_id)) {
          filteredUsers.push(user);
        }
      });

      // sort for proerly render order (and for role assignment when submitting)
      filteredUsers = filteredUsers.sort((a, b) => {
        return userIds.indexOf(a.unique_id) - userIds.indexOf(b.unique_id);
      });
      const formatSalesPerson: ISalesPerson[] = filteredUsers.map((user) => ({
        unique_id: user.unique_id,
        first_name: user.first_name,
        img_url: user.img_url,
        last_name: user.last_name,
        position: user.position,
        title_name: user.title_name,
        nick_name: user.nick_name,
        department: user.department,
        status: user.status,
      }));
      replace(formatSalesPerson);
      closeModalHandler();
    } else {
      enqueueSnackbar("เลือกได้สูงสุด 12 รายการ", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    const userUniqueId = fields.map((field) => field.unique_id);
    setUsersIds(userUniqueId);
    setUsersIdsSnapshot(userUniqueId);
  }, [fields]);

  return (
    <>
      <Box my={2}>
        <Grid container spacing={1.5}>
          {fields.length > 0 &&
            fields.map((person, index) => (
              <SalesPersonCard
                key={person.id}
                person={person}
                remove={remove}
                index={index}
              />
            ))}
        </Grid>
        {disabled && fields.length === 0 && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            pt={3}
          >
            <Typography>ไม่มีข้อมูล</Typography>
          </Box>
        )}
      </Box>
      {!disabled && fields.length === 0 && (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <CustomizedButton
            startAddIcon
            variant="outlined"
            title={`${t("button.add")}${t("contact.sales_person.index")}`}
            onClick={openModalHandler}
          />
        </Box>
      )}
      {!disabled && fields.length > 0 && fields.length < 12 && (
        <CustomizedButton
          startAddIcon
          title={`${t("button.add")}${t("contact.sales_person.index")}`}
          onClick={openModalHandler}
        />
      )}
      <CheckedUserModal
        userIds={userIds}
        setUserIds={setUsersIds}
        userIdsSnapshot={userIdsSnapshot}
        setUserIdsSnapshot={setUsersIdsSnapshot}
        showSelectUser={modal}
        closeUserTable={closeModalHandler}
        finishUsersSelect={finishUsersSelect}
        rowSelection="multiple"
      />
    </>
  );
};

export default SalesPersonList;
