import { Box, Typography, Divider } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";

type Props = {
  status?: string;
  documentType?: string;
};

const PDFSigner = ({ status, documentType }: Props) => {
  const fontSize = 10.66;

  let firstSigner;
  let secondSigner;
  let thirdSigner;

  switch (documentType) {
    case "quotation":
      firstSigner = "ผู้ออกเอกสาร";
      secondSigner = "ผู้ตรวจสอบ";
      thirdSigner = "ผู้อนุมัติ";
      break;
    case "billing_note":
      firstSigner = "ผู้วางบิล";
      secondSigner = "ผู้รับวางบิล";
      break;
    case "maintenance":
      firstSigner = "ผู้แจ้ง";
      secondSigner = "ผู้รับแจ้ง";
      thirdSigner = "ช่างซ่อม";
      break;
    default:
      firstSigner = "ผู้สั่งซื้อ";
      secondSigner = "ผู้ตรวจสอบ";
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          flexWrap: "nowrap",
          width: "197.3mm",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          <Typography
            sx={{ fontSize: fontSize, fontWeight: 600, lineHeight: 2 }}
          >
            สแกนดูเอกสาร
          </Typography>
          <QRCodeSVG value={window.location.href} size={60} />
        </Box>

        {/* TODO convert to map function */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontSize: fontSize, fontWeight: 600, mb: 8.5, lineHeight: 2 }}
          >
            {firstSigner}
          </Typography>
          <Divider
            flexItem
            sx={{
              borderBottomWidth: 1.33,
              borderBottomColor: "#BDBDBD",
              width: 104,
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              mt: 2.5,
            }}
          >
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 32,
              }}
            />
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 32,
              }}
            />
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 32,
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: fontSize,
              fontWeight: 600,
              mb: 8.5,
              lineHeight: 2,
            }}
          >
            {secondSigner}
          </Typography>
          <Divider
            flexItem
            sx={{
              borderBottomWidth: 1.33,
              borderBottomColor: "#BDBDBD",
              width: 104,
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              mt: 2.5,
            }}
          >
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 32,
              }}
            />
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 32,
              }}
            />
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 32,
              }}
            />
          </Box>
        </Box>

        {documentType &&
          ["quotation", "maintenance"].includes(documentType) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: fontSize,
                  fontWeight: 600,
                  mb: 8.5,
                  lineHeight: 2,
                }}
              >
                {thirdSigner}
              </Typography>
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  borderBottomColor: "#BDBDBD",
                  width: 104,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  mt: 2.5,
                }}
              >
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    borderBottomColor: "#BDBDBD",
                    width: 32,
                  }}
                />
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    borderBottomColor: "#BDBDBD",
                    width: 32,
                  }}
                />
                <Divider
                  flexItem
                  sx={{
                    borderBottomWidth: 1.33,
                    borderBottomColor: "#BDBDBD",
                    width: 32,
                  }}
                />
              </Box>
            </Box>
          )}
      </Box>
    </>
  );
};

export default PDFSigner;
