import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMaintenanceTaskListPdfHeader } from "./columnDef";
import { MaintenanceTask } from "generated/sales";
import { numberFormatter } from "utils/Formatter/Global";
import { formatNumber } from "utils/Global";

type Props = {
  itemList: MaintenanceTask[];
  startNumber: number;
  lastTable: boolean;
};

const MaintenanceTaskListPdfTable = ({
  itemList,
  startNumber,
  lastTable,
}: Props) => {
  const headers = useMaintenanceTaskListPdfHeader();

  const filteredItemList = itemList.filter(
    (item: any) => item.pdf_table_type !== "second"
  );

  const tablePadding = {
    px: 1,
    py: 1,
    borderBottom: "none",
  };

  const fontSize = 10.66;

  const sxRow = (index: number) => {
    return lastTable && index === filteredItemList.length - 1
      ? {
          // borderBottom: "none",
        }
      : {};
  };

  if (
    itemList.length === 0 ||
    (itemList.length > 0 && filteredItemList.length === 0)
  ) {
    return <></>;
  }

  return (
    <>
      <Box>
        <TableContainer
          sx={{
            border: `1px solid #d6cfcf`,
            borderBottom: "none",
            borderRadius: "5.33px",
            my: 1,
          }}
        >
          <Table aria-label="simple table">
            <TableHead sx={{ bgcolor: "#ECF6FD" }}>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align || "center"}
                    key={index}
                    sx={tablePadding}
                    width={header.width}
                  >
                    <Typography fontSize={fontSize} fontWeight={600}>
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItemList.map((item, index) => (
                <TableRow
                  key={item.id}
                  sx={{ verticalAlign: "top", height: "48.78px" }}
                >
                  <TableCell align="center" sx={sxRow(index)}>
                    <Typography fontSize={fontSize}>
                      {startNumber + index}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={sxRow(index)}>
                    <Typography fontSize={fontSize} fontWeight={600}>
                      {item.name}
                    </Typography>
                    <Typography fontSize={fontSize} color={"#737373"}>
                      {item.description ?? "-"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={sxRow(index)} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default MaintenanceTaskListPdfTable;
