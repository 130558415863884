import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { IUser } from "../../../types/Auth/user";
import { ICreatedBy } from "../../../types/global";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import RelatedEmployeeIcon from "./RelatedEmployeeIcon";
import { useStateContext } from "../../../contexts/auth-context";
import { useDisable } from "../../../hooks/use-disable";
import CheckedUserModal from "../../UI/Modal/CheckedModal/CheckedUserModal";

type FieldArrayType = {
  related_user_list: IUser[];
};

type Props = {
  hideCreator?: boolean;
  disabledLockRow?: boolean;
  disabledRemoveCreator?: boolean;
  isDriver?: boolean;
  maxSelectionLimit?: number;
  disabledStatus?: boolean;
  billingNote?: boolean;
};

const EmployeeListForm = ({
  hideCreator,
  disabledLockRow,
  disabledRemoveCreator,
  isDriver,
  maxSelectionLimit = Infinity,
  disabledStatus,
  billingNote,
}: Props) => {
  const { control, setValue, getValues } = useFormContext<any>();
  const [showSelectEmployees, setShowSelectEmployees] =
    useState<boolean>(false);
  const [employeeIds, setEmployeeIds] = useState<string[]>([]);
  const [employeeIdsSnapshot, setEmployeeIdsSnapshot] = useState<string[]>([]);
  const [disabled] = useDisable();

  const { pathname } = useLocation();

  const {
    state: { authUser },
  } = useStateContext();

  const watchRelatedEmployees = useWatch({
    control,
    name: "related_user_list",
  });

  const watchCreatedBy = useWatch({
    control,
    name: "created_by",
  });

  const { fields, remove } = useFieldArray<
    FieldArrayType,
    "related_user_list",
    "id"
  >({
    control,
    name: "related_user_list",
  });

  const removeEmployee = (index: number) => {
    remove(index);
    setEmployeeIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setEmployeeIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
  };

  const renderAvatars = () => {
    return fields.map((item, index) => {
      return (
        <RelatedEmployeeIcon
          isMain={false}
          key={item.id}
          first_name={item.first_name}
          last_name={item.last_name}
          index={index}
          img_url={item.img_url ? item?.img_url[0] : ""}
          remove={removeEmployee}
          disabled={disabled || disabledStatus}
        />
      );
    });
  };

  const renderCreatorAvatar = () => {
    if (watchCreatedBy) {
      return (
        <RelatedEmployeeIcon
          isMain={watchCreatedBy && fields.length > 0}
          first_name={watchCreatedBy?.first_name}
          last_name={watchCreatedBy?.last_name}
          img_url={watchCreatedBy.img_url ? watchCreatedBy?.img_url[0] : ""}
          index={0}
        />
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (!watchCreatedBy) {
      if (authUser) {
        setValue("created_by", {
          user_unique_id: authUser?.unique_id,
          email: authUser?.email,
          first_name: authUser?.first_name,
          last_name: authUser?.last_name,
          img_url: authUser?.img_url,
        });
      }
    }
  }, [watchCreatedBy, setValue, authUser]);

  useEffect(() => {
    if (
      pathname.split("/").at(-1) === "add" &&
      watchRelatedEmployees &&
      watchRelatedEmployees.length === 0
    ) {
      // setValue("related_user_list", [{ ...user }]);
      if (authUser) {
        if (!isDriver) {
          setEmployeeIds([authUser?.unique_id]);
          setEmployeeIdsSnapshot([authUser?.unique_id]);
        }
      }
    } else {
      if (watchCreatedBy) {
        const mappedEmployeeIds =
          watchRelatedEmployees?.map(
            (employee: ICreatedBy) => employee?.user_unique_id
          ) ?? [];
        if (!isDriver) {
          setEmployeeIds([watchCreatedBy.user_unique_id, ...mappedEmployeeIds]);
          setEmployeeIdsSnapshot([
            watchCreatedBy.user_unique_id,
            ...mappedEmployeeIds,
          ]);
        } else {
          setEmployeeIds([...mappedEmployeeIds]);
          setEmployeeIdsSnapshot([...mappedEmployeeIds]);
        }
      }
    }
  }, [
    pathname,
    watchRelatedEmployees,
    watchCreatedBy,
    authUser,
    setValue,
    getValues,
    isDriver,
  ]);

  const finishEmployeesSelect = (data: any[]) => {
    // filter out employees not selected in current modal session
    let filteredEmployees = fields.filter((employee) =>
      employeeIds.includes(employee.unique_id)
    );

    // get current employees id to prevent duplication when appending newly selected employees
    const filteredEmployeesId = filteredEmployees.map(
      (employee) => employee.unique_id
    );

    data.forEach((employee) => {
      if (!filteredEmployeesId.includes(employee.unique_id)) {
        filteredEmployees.push(employee);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredEmployees = filteredEmployees.sort((a, b) => {
      return (
        employeeIds.indexOf(a.unique_id) - employeeIds.indexOf(b.unique_id)
      );
    });

    const removedCreator = !disabledRemoveCreator
      ? filteredEmployees.filter(
          (employee) => employee.unique_id !== watchCreatedBy.user_unique_id
        )
      : filteredEmployees;

    const formatRelatedUserList: ICreatedBy[] = removedCreator.map(
      (employee) => ({
        user_unique_id: employee.unique_id,
        email: employee.email,
        first_name: employee.first_name,
        last_name: employee.last_name,
        img_url: employee.img_url,
      })
    );

    if (formatRelatedUserList.length > maxSelectionLimit)
      setValue(
        "related_user_list",
        formatRelatedUserList.splice(0, maxSelectionLimit)
      );
    else setValue("related_user_list", formatRelatedUserList);
    setShowSelectEmployees(false);
  };

  const openEmployeeTable = () => {
    setShowSelectEmployees(true);
  };

  const closeEmployeeTable = () => {
    setShowSelectEmployees(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        {!hideCreator && renderCreatorAvatar()}
        {renderAvatars()}
        {!disabled && !disabledStatus && fields.length < maxSelectionLimit && (
          <Grid item>
            <CustomizedTooltip
              title={isDriver ? "เพิ่มพนักงานขนส่ง" : "เพิ่มผู้เกี่ยวข้อง"}
              enterNextDelay={200}
            >
              <IconButton onClick={openEmployeeTable} color="primary">
                <ControlPointOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          </Grid>
        )}
      </Grid>
      <CheckedUserModal
        showSelectUser={showSelectEmployees}
        closeUserTable={closeEmployeeTable}
        finishUsersSelect={finishEmployeesSelect}
        userIds={employeeIds}
        setUserIds={setEmployeeIds}
        userIdsSnapshot={employeeIdsSnapshot}
        setUserIdsSnapshot={setEmployeeIdsSnapshot}
        lockRows={!disabledLockRow ? [authUser?.unique_id] : []}
        rowSelection="multiple"
        maxSelectionLimit={maxSelectionLimit}
        isDriver={isDriver}
        billingNote={billingNote}
      />
    </>
  );
};

export default EmployeeListForm;
