import { Box, Typography } from "@mui/material";
import { useExpensesSummary } from "hooks/Logistic/use-expenses-summary";
import { useMemo } from "react";
import { numberFormatter } from "utils/Formatter/Global";

interface ISummary {
  name?: string;
  label: string;
  amount: number;
}

const ExpnesesSummary = () => {
  const summary = useExpensesSummary();

  const defaultsSummaryList: ISummary[] = useMemo(
    () => [
      {
        name: "withholding_tax_amount",
        label: `ภาษีรายได้`,
        amount: summary.expenses_withholding_tax_amount,
      },
      {
        name: "total_amount",
        label: `ยอดโอน`,
        amount: summary.expenses_total_amount,
      },
    ],
    [summary.expenses_total_amount, summary.expenses_withholding_tax_amount]
  );
  return (
    <>
      {defaultsSummaryList.map((item) => (
        <Box key={item.label} sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1.5,
              gap: 3,
              py: item.name === "net_amount" ? 1 : 0,
            }}
            bgcolor={item.name === "net_amount" ? "secondary.main" : undefined}
            maxWidth={461}
            marginLeft="auto"
            justifyItems="flex-end"
            borderRadius={1}
          >
            <>
              <Typography
                color={"primary.main"}
                textAlign={"right"}
                flex={2}
                fontWeight={
                  ["net_amount", "total_amount"].includes(item.name || "")
                    ? 600
                    : 400
                }
              >
                {item.label}
              </Typography>
              <Typography
                color={"primary.main"}
                fontWeight={
                  ["net_amount", "total_amount"].includes(item.name || "")
                    ? 600
                    : 400
                }
                sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
              >
                {numberFormatter(item.amount)}
              </Typography>

              <Typography
                color={"primary.main"}
                sx={{ ml: 2.5, mr: 4 }}
                fontWeight={
                  ["net_amount", "total_amount"].includes(item.name || "")
                    ? 600
                    : 400
                }
              >
                บาท
              </Typography>
            </>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default ExpnesesSummary;
