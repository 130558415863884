import { useTranslation } from "react-i18next";
import { IContact } from "../../../types/Contact/contact";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledCheckbox from "../../Controller/ControlledCheckbox";
import AddressDetail from "./AddressDetail";
import { useDisable } from "../../../hooks/use-disable";
import { Fragment } from "react";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { ISelectOption } from "../../../types/global";
import LabelInput from "../../UI/LabelInput";

interface Props {
  addressList: any[] | null | undefined;
}

const addressTypeOptions: ISelectOption[] = [
  {
    id: 1,
    label: "ที่อยู่จัดส่ง",
    value: "ที่อยู่จัดส่ง",
  },
  {
    id: 2,
    label: "ที่อยู่จัดส่งเอกสาร",
    value: "ที่อยู่จัดส่งเอกสาร",
  },
];

const ContactAddress = ({ addressList }: Props) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IContact>();
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "address_list",
  });

  const [disabled] = useDisable();

  const addAddressHandler = () => {
    append({
      address_type: "",
      name: "",
      district: "",
      is_default: false,
      is_same_as_default_address: false,
      postal_code: "",
      province: "",
      sub_district: "",
      address_contact_name: "",
      address_contact_phone: "",
      address_contact_fax: "",
      country: "ไทย",
    });
  };

  const removeAddressHandler = (index: number) => {
    remove(index);
  };

  return (
    <>
      {fields.map((address, index) => (
        <CustomizedBox
          key={address.id}
          border={"none"}
          margin={
            index === fields.length - 1
              ? "1rem 0rem"
              : index % 2 !== 0
              ? 0
              : "1rem 0rem"
          }
          bgcolor={(theme) => theme.palette.secondary.main}
        >
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {index === 0 ? (
                <Typography fontWeight={600}>ที่อยู่จดทะเบียน</Typography>
              ) : (
                <>
                  {disabled ? (
                    <LabelInput
                      label="ประเภทที่อยู่"
                      value={getValues(`address_list.${index}.address_type`)}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={`address_list.${index}.address_type`}
                      render={({ field }) => (
                        <CustomizedComboBox
                          {...field}
                          label="ประเภทที่อยู่"
                          freeSolo
                          options={addressTypeOptions}
                          onChange={(_, newValue) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            } else {
                              field.onChange("");
                            }
                          }}
                          onInputChange={(_, newValue) => {
                            if (newValue) {
                              field.onChange(newValue);
                            } else {
                              field.onChange("");
                            }
                          }}
                          error={Boolean(
                            errors?.address_list &&
                              errors?.address_list[index] &&
                              errors?.address_list[index]?.address_type
                          )}
                          helperText={
                            errors?.address_list &&
                            errors?.address_list[index] &&
                            errors?.address_list[index]?.address_type?.message
                          }
                        />
                      )}
                    />
                  )}
                </>
              )}
            </Grid>
            {index !== 0 && !disabled && (
              <Fragment key={index}>
                <Grid item xs={9} sm={9} md={5}>
                  <ControlledCheckbox
                    name={`address_list.${index}.is_same_as_default_address`}
                    control={control}
                    label="เหมือนที่อยู่จดทะเบียน"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={3} sm={3} md={1}>
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                  >
                    <IconButton onClick={() => removeAddressHandler(index)}>
                      <CloseOutlinedIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Fragment>
            )}
            <AddressDetail index={index} addressList={addressList} />
          </Grid>
        </CustomizedBox>
      ))}
      {!disabled && fields.length < 10 && (
        <CustomizedButton
          title={t("button.add") + t("contact.address.index")}
          onClick={addAddressHandler}
          startAddIcon
        />
      )}
    </>
  );
};

export default ContactAddress;
