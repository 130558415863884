import { useCallback, useEffect, useRef } from "react";
import { useGoodsTransferColumnDefs } from "./columnDefs";
import {
  GridReadyEvent,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import AgGrid from "components/UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { dateFilterModel } from "utils/AgGridFilter";
import { GOODS_TRANSFER_VIEW } from "services/AgGrid/GoodsTransferAggrid";

type Props = {
  status: string | null;
};

const GoodsTransferTable = ({ status }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const columnDefs = useGoodsTransferColumnDefs();
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { created_date, scanned_date, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        created_date: dateFilterModel(created_date),
      };
      try {
        const { GoodTransfersViewAggrid } = await graphQLClientWms.request(
          GOODS_TRANSFER_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: GoodTransfersViewAggrid.results as any[],
          rowCount: GoodTransfersViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const statusInstance = params.api.getFilterInstance("aggrid_status");
      switch (status) {
        case "draft":
        case "wait_transfer":
        case "finished":
        case "cancelled":
          statusInstance.setModel({ values: [status] });
          break;
        default:
          statusInstance.setModel({
            values: ["draft", "wait_transfer", "finished"],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [status]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/inventory/goods-transfer/${id}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onRowDoubleClicked={rowSelectHandler}
      onGridReady={onGridReady}
      path={`/inventory/goods-transfer`}
    />
  );
};

export default GoodsTransferTable;
