import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Divider, Grid, Stack } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledAddressBox from "../../Controller/ControlledAddressBox";
import ControlledSelect from "../../Controller/ControlledSelect";
import { useForm, useFormContext } from "react-hook-form";
import { countryOptions } from "../../../utils/Global";
import { useEffect, useState } from "react";
import {
  IAddress,
  IDeliveryAddress,
  ISelectOption,
} from "../../../types/global";

type Props = {
  open: boolean;
  handleClose: () => void;
  viewMode: boolean;
  name: string;
};

const addressOptions: ISelectOption[] = [
  {
    label: "ที่อยู่จดทะเบียน",
    value: "ที่อยู่จดทะเบียน",
  },
  {
    label: "ที่อยู่จัดส่ง",
    value: "ที่อยู่จัดส่ง",
  },
];

const DeliveryAddressModal = ({ open, handleClose, viewMode, name }: Props) => {
  const { setValue: setContextValue, getValues: getContextValues } =
    useFormContext();

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<IDeliveryAddress>({
    defaultValues: {
      is_same_as_default_address: false,
      address_type: "",
      address_contact_name: "",
      address_contact_phone: "",
      name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  });

  const onSubmitHandler = (data: IDeliveryAddress) => {
    setContextValue(`${name}.delivery_address`, data);
    handleClose();
  };

  const [addressValue, setAddressValue] = useState<any>("");

  useEffect(() => {
    if (open) {
      const currentAddress = getContextValues(`${name}.delivery_address`);
      setAddressValue(currentAddress);
      reset(currentAddress);
    }
  }, [getContextValues, name, open, reset]);

  const { t } = useTranslation();
  return (
    <ModalUI
      open={open}
      handleClose={handleClose}
      title={t("address.delivery_address")}
      maxWidth="md"
      action={
        <Stack direction={"row"} gap={1}>
          <CustomizedButton
            title={t("button.cancel")}
            onClick={handleClose}
            variant="outlined"
          />
          <CustomizedButton
            title={t("button.confirm")}
            onClick={handleSubmit(onSubmitHandler)}
            variant="contained"
          />
        </Stack>
      }
    >
      <Grid container spacing={1.5} mt={0.1}>
        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            label="ชื่อที่อยู่"
            control={control}
            name={`type`}
            viewMode={viewMode}
          />
        </Grid> */}
        <Grid item xs={4}>
          <ControlledSelect
            control={control}
            name={`address_type`}
            label={t("contact.address.type")}
            options={addressOptions}
            onChange={(e: any) => {
              const addressValue = getContextValues(
                "supplier_details.address_list"
              );

              if (addressValue?.length > 0) {
                const filterAddress = addressValue.filter(
                  (address: IAddress) => address.address_type === e.target.value
                );

                if (filterAddress?.length > 0) {
                  setAddressValue(filterAddress[0]);
                  reset(filterAddress[0]);
                }
              }
            }}
          />
        </Grid>
        <Box width="100%"></Box>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            name={`address_contact_name`}
            control={control}
            label={t("contact.address.contact_person_name")}
            error={Boolean(errors.address_contact_name)}
            helperText={
              errors.address_contact_name &&
              errors.address_contact_name?.message
            }
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            name={`address_contact_phone`}
            control={control}
            label={t("contact.address.phone_number")}
            error={Boolean(errors.address_contact_phone)}
            helperText={
              errors.address_contact_phone &&
              errors.address_contact_phone?.message
            }
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name={`name`}
            control={control}
            label={t("contact.address.index")}
            error={Boolean(errors.name)}
            helperText={errors.name && errors.name?.message}
            rows={4}
            multiline
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="sub_district"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="district"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="province"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="postal_code"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSelect
            name={`country`}
            control={control}
            label={t("contact.address.country")}
            error={Boolean(errors.country)}
            helperText={errors.country && errors.country?.message}
            options={countryOptions}
            disabled={true}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default DeliveryAddressModal;
