import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Box, Grid, Typography } from "@mui/material";
import CustomizedChips from "../../Custom/CustomizedChips";

import { formatDateTime } from "../../../utils/Date";

const UserHeader = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { getValues } = useFormContext();

  const chipData: { title: string; value: string }[] = [
    {
      title: t("user.account.id"),
      value: getValues("unique_id") ?? "-",
    },
    {
      title: "วันที่สร้าง",
      value: getValues("created_date")
        ? formatDateTime(getValues("created_date"))
        : "-",
    },
    {
      title: "วันที่เข้าสู่ระบบล่าสุด",
      value: getValues("last_login_date")
        ? formatDateTime(getValues("last_login_date"))
        : "-",
    },
  ];

  return (
    <Box>
      {id && (
        <Grid container spacing={1} mt={1}>
          {chipData.map((chip) => (
            <Grid item key={chip.title}>
              <CustomizedChips
                variant="filled"
                noneColor
                label={
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">{chip.title}</Typography>
                    <Typography>{chip.value}</Typography>
                  </Box>
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default UserHeader;
