import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { IExporter } from "../../../types/global";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import CustomizedButton from "../../Custom/CustomizedButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  exportHandler: (data: IExporter) => void;
};

const ExporterMaintenanceModal = ({
  open,
  closeModalHandler,
  exportHandler,
}: Props) => {
  const exporterSchema: IExporter = {
    export_type: "all",
    start_date: null,
    end_date: null,
  };

  const exporterValidation = Yup.object().shape({
    start_date: Yup.date().when("export_type", {
      is: "date_range",
      then: (schema) => schema.required("กรุณาเลือกวันที่"),
      otherwise: (schema) => schema.nullable(),
    }),
    end_date: Yup.date().when("export_type", {
      is: "date_range",
      then: (schema) => schema.required("กรุณาเลือกวันที่"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const { t } = useTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
  } = useForm<IExporter>({
    defaultValues: exporterSchema,
    resolver: yupResolver<any>(exporterValidation),
  });

  const onClose = () => {
    reset(exporterSchema);
    closeModalHandler();
  };

  const watchExportType = watch("export_type");
  const onSubmit = (data: IExporter) => {
    if (data.export_type === "date_range") {
      data.start_date = dayjs(data.start_date).startOf("day").toDate();
      data.end_date = dayjs(data.end_date).endOf("day").toDate();
    }
    exportHandler(data);
    onClose();
  };
  return (
    <ModalUI
      title={t("button.export")}
      open={open}
      handleClose={onClose}
      maxWidth={"xs"}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.confirm")}
            variant="contained"
            onClick={() => {
              handleSubmit(onSubmit)();
              if (isValid) {
                onClose();
              }
            }}
          />
        </Box>
      }
    >
      <Typography fontWeight={600} mb={1}>
        ช่วงเวลา
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <FormControlLabel
                control={
                  <Radio
                    onClick={() => {
                      setValue("export_type", "all");
                      setValue("start_date", null);
                      setValue("end_date", null);
                    }}
                    checked={watchExportType === "all"}
                  />
                }
                label={t("global.all")}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Radio
                    onClick={() => {
                      setValue("export_type", "date_range");
                    }}
                    checked={watchExportType === "date_range"}
                  />
                }
                label={t("global.date_range")}
              />
            </Grid>
          </Grid>
        </Grid>
        {watchExportType === "date_range" && (
          <>
            <Grid item xs={12} sm={6}>
              <ControlledDatePicker
                control={control}
                name="start_date"
                label={t("date.from")}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledDatePicker
                control={control}
                name="end_date"
                required
                label={t("date.to")}
              />
            </Grid>
          </>
        )}
      </Grid>
    </ModalUI>
  );
};

export default ExporterMaintenanceModal;
