import { Typography } from "@mui/material";
import { Traceability } from "generated/wms";
import { useFormContext, useWatch } from "react-hook-form";
import { IGoodsAdjust } from "types/Inventory/goodsAdjust";

type Props = {
  nestedIndex: number;
  traceability: Traceability;
};

const StockQty = ({ nestedIndex, traceability }: Props) => {
  const { control } = useFormContext<IGoodsAdjust>();
  const trace = useWatch({
    control,
    name: `trace_entry_list.${nestedIndex}`,
  });

  const serialList = trace.serial_list;

  if (traceability === Traceability.Normal) {
    return <Typography fontSize={14}>{trace?.stock_qty || 0}</Typography>;
  } else {
    const totalStockQty = serialList?.reduce((prev, curr) => {
      return prev + (curr?.stock_qty || 0);
    }, 0);
    return <Typography fontSize={14}>{totalStockQty}</Typography>;
  }
};

export default StockQty;
