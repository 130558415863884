import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { useDisable } from "hooks/use-disable";

import { ITire } from "types/Logistic/tire";

import { Grid, Typography } from "@mui/material";

import { CustomizedBox } from "components/Custom/CustomizedBox";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import ControlledSSCreatable from "components/Controller/ControlledSSCreatable";
import { CreatableFieldDocumentType } from "generated/general";

const TireInfo = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<ITire>();

  return (
    <CustomizedBox>
      <Grid container spacing={1.5}>
        {/* Left Layout */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.tire.serial")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={11}
              sm={11}
              md={6}
              minHeight={50}
              display="flex"
              alignItems="center"
            >
              <ControlledTextField
                name="serial"
                control={control}
                placeholder={t("logistic.tire.serial")}
                error={Boolean(errors?.serial)}
                helperText={
                  errors?.serial && errors?.serial.message?.toString()
                }
                disabled={Boolean(id)}
                viewMode={Boolean(id) && disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.tire.band")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={11}
              sm={11}
              md={6}
              minHeight={50}
              display="flex"
              alignItems="center"
            >
              <ControlledSSCreatable
                control={control}
                setValue={setValue}
                documentType={CreatableFieldDocumentType.Tire}
                name="band"
                placeholder={t("logistic.tire.band")}
                defaultOptions={[]}
                error={Boolean(errors.band)}
                helperText={errors?.band?.message?.toString()}
                viewMode={Boolean(id) && disabled}
                endArrowIcon
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.tire.model")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={11}
              sm={11}
              md={6}
              minHeight={50}
              display="flex"
              alignItems="center"
            >
              <ControlledSSCreatable
                control={control}
                setValue={setValue}
                documentType={CreatableFieldDocumentType.Tire}
                name="model"
                placeholder={t("logistic.tire.model")}
                defaultOptions={[]}
                error={Boolean(errors.model)}
                helperText={errors?.model?.message?.toString()}
                viewMode={Boolean(id) && disabled}
                endArrowIcon
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Left Layout */}
        {/* Right Layout */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.tire.current_distance")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={11}
              sm={11}
              md={6}
              minHeight={50}
              alignContent="center"
              justifyContent="right"
            >
              <ControlledNumberTextField
                name="current_distance"
                control={control}
                placeholder={t("logistic.tire.current_distance")}
                error={Boolean(errors?.current_distance)}
                helperText={
                  errors?.current_distance &&
                  errors?.current_distance.message?.toString()
                }
                viewMode={Boolean(id) && disabled}
                endAdornmentMessage="กิโลเมตร"
                decimalScale={0}
                textAlign={"right"}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.tire.due_distance")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={11}
              sm={11}
              md={6}
              minHeight={50}
              alignContent="center"
              justifyContent="right"
            >
              <ControlledNumberTextField
                name="due_distance"
                control={control}
                placeholder={t("logistic.tire.due_distance")}
                error={Boolean(errors?.due_distance)}
                helperText={
                  errors?.due_distance &&
                  errors?.due_distance.message?.toString()
                }
                viewMode={Boolean(id) && disabled}
                endAdornmentMessage="กิโลเมตร"
                decimalScale={0}
                textAlign={"right"}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.tire.price_per_unit")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={11}
              sm={11}
              md={6}
              minHeight={50}
              display="flex"
              alignItems="center"
              justifyContent="right"
            >
              <ControlledNumberTextField
                name="price_per_unit"
                control={control}
                placeholder={t("logistic.tire.price_per_unit")}
                error={Boolean(errors?.price_per_unit)}
                helperText={
                  errors?.price_per_unit &&
                  errors?.price_per_unit.message?.toString()
                }
                textAlign={"right"}
                viewMode={Boolean(id) && disabled}
                lebelSx={{ paddingRight: "66.18px" }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Right Layout */}
      </Grid>
    </CustomizedBox>
  );
};

export default TireInfo;
