import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import AgGrid from "components/UI/AgGrid";
import { t } from "i18next";
import { useRef } from "react";

type Props = {
    data: any[];
    watchType: string;
};

const LocationImporterTable = ({ data, watchType }: Props) => {
    const gridRef = useRef<AgGridReact>(null);
    const columnDefs: ColDef[] = [
        {
            field: "unique_id",
            headerName: t("logistic.location.unique_id"),
            filter: "agTextColumnFilter",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "name",
            headerName: t("logistic.location.name"),
            filter: "agTextColumnFilter",
            flex: 1,
            minWidth: 200,
        },
        ...(watchType !== "update_shipping_cost"
            ? [
                  {
                      field: "status",
                      headerName: t("logistic.location.status"),
                      filter: "agSetColumnFilter",
                      flex: 1,
                      minWidth: 200,
                      filterParams: {
                          values: [1, 0],
                          valueFormatter: ({ value }: { value: number }) => {
                              if (value) return "ใช้งาน";
                              return "หยุดใช้งาน";
                          },
                      },
                      cellRenderer: ({ value }: { value: boolean }) => {
                          if (value) return <CustomizedStatus status="active" />;
                          return <CustomizedStatus status="inactive" />;
                      },
                      cellStyle: {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                      },
                  },
              ]
            : []),
    ];

    return (
        <AgGrid
            ref={gridRef}
            columnDefs={columnDefs}
            height={665}
            rowData={data}
            disabledSidebar
        />
    );
};

export default LocationImporterTable;