import { Grid, Typography } from "@mui/material";
import ControlledDateTimePicker from "components/Controller/ControlledDateTimePicker";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { useDisable } from "hooks/use-disable";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IQuotation } from "types/Sales/quotation";

const QuotationPlanDateInfo = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const { control } = useFormContext<IQuotation>();

  return (
    <CustomizedBox margin={"1rem 0"}>
      <Typography fontWeight={600} mb={1}>
        {t("sales.quotation.plan_date")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4}>
          <ControlledDateTimePicker
            name="plan_start_date"
            control={control}
            label={t("logistic.delivery_trip.plan_start_date")}
            disabled={disabled}
            viewMode={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <ControlledDateTimePicker
            name="plan_end_date"
            control={control}
            label={t("logistic.delivery_trip.plan_end_date")}
            disabled={disabled}
            viewMode={disabled}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default QuotationPlanDateInfo;
