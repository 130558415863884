import { GraphQLClient } from "graphql-request";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import CheckboxAggridModal from "../CheckboxAggridModal";
import { useVehicleColumnDefs } from "components/Table/Logistic/Vehicle/columnDefs";
import { VEHICLE_VIEW } from "services/AgGrid/VehicleAgGrid";

type CheckedTrailerModalProps = {
  showSelectTrailer: boolean;
  closeTrailerTable: () => void;
  finishTrailerSelect: (data: any) => void;
  trailerId: string[];
  setTrailerId: Dispatch<SetStateAction<string[]>>;
  trailerIdSnapshot: string[];
  setTrailerIdSnapshot: Dispatch<SetStateAction<string[]>>;
  trailerOnly: boolean;
};

const CheckedTrailerModal = ({
  showSelectTrailer,
  closeTrailerTable,
  finishTrailerSelect,
  trailerId,
  setTrailerId,
  trailerIdSnapshot,
  setTrailerIdSnapshot,
  trailerOnly,
}: CheckedTrailerModalProps) => {
  const { t } = useTranslation();
  const selectTrailerGridRef = useRef();

  const columnDefs = useVehicleColumnDefs(true, trailerOnly);
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      try {
        const { VehicleFindViewAggrid } = await graphQLClient.request(
          VEHICLE_VIEW,
          {
            aggridInput: {
              startRow: startRow,
              endRow: endRow,
              filterModel: {
                ...filterModel,
                type: {
                  filterType: "set",
                  values: ["trailer"],
                },
              },
              sortModel,
            },
          }
        );

        params.success({
          rowData: VehicleFindViewAggrid.results as any[],
          rowCount: VehicleFindViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    params.api.setFilterModel({
      status: {
        type: "set",
        values: ["active"],
      },
    });
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxAggridModal
      modalTitle={t("logistic.vehicle.tail")}
      btnTitle={t("button.confirm")}
      gridRef={selectTrailerGridRef}
      height={450}
      columnDefs={columnDefs}
      rowSelection={"single"}
      onFinishEditing={finishTrailerSelect}
      modalIsOpen={showSelectTrailer}
      getRowId={getRowId}
      closeModal={closeTrailerTable}
      selectedIds={trailerId}
      setSelectedIds={setTrailerId}
      idsSnapshot={trailerIdSnapshot}
      setIdsSnapshot={setTrailerIdSnapshot}
      onGridReady={onGridReady}
      isCancelable={true}
      cancelBtnTitle={t("button.cancel")}
    />
  );
};

export default CheckedTrailerModal;
