import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";

export const useMaintenanceOption = (
  status?: string | null,
  isEditing?: boolean,
  options?: any
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: isEditing,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    [isEditing]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions([defaultOptions[1], defaultOptions[2]]); // ยกเลิก, คัดลอก
        break;
      case "cancelled":
        setSelectedModifyOptions([defaultOptions[1]]); // คัดลอก
        break;
      case "finished":
        if (
          options &&
          options.type === "CLAIM" &&
          !options.is_insurance_fully_paid
        ) {
          setSelectedModifyOptions([
            {
              value: "ได้รับเงินเคลมครบ",
            },
            defaultOptions[1],
          ]); // ได้รับเงินเคลมครบ,คัดลอก
          break;
        }
        setSelectedModifyOptions([defaultOptions[1]]); // คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions);
        break;
    }
  }, [defaultOptions, status, options]);

  return selectModifyOptions;
};
