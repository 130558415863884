import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IIncomeList, IWorkList } from "types/Logistic";

export const useWorkSummary = () => {
  const { control, setValue } = useFormContext();
  const workList: IWorkList[] = useWatch({
    control,
    name: "work_list",
  });

  const incomeList: IIncomeList[] = useWatch({
    control,
    name: "income_list",
  });

  const specialPricePerUnit = incomeList.find(
    (income) =>
      income.item_unique_id === "SP00001" || income.item_unique_id === "SP00002"
  )?.price_per_unit;

  const [workTotalAmount, setWorkTotalAmount] = useState(0);

  const disabledButton = workTotalAmount === specialPricePerUnit;

  useEffect(() => {
    if (workList?.length > 0) {
      let maxShippingCost = 0;
      let maxDistance = 0;
      let maxSecondaryDriver = 0;
      let sumDropCost = 0;

      workList.forEach((work) => {
        if (work.max_shipping_cost > 0) {
          maxShippingCost = Math.max(maxShippingCost, work.max_shipping_cost);
        }

        maxDistance = Math.max(maxDistance, work.distance);
        sumDropCost += work.drop_cost || 0;
      });

      maxSecondaryDriver =
        workList.find((work) => work.distance === maxDistance)
          ?.secondary_driver_cost || 0;

      const totalAmount = maxShippingCost + maxSecondaryDriver + sumDropCost;
      setWorkTotalAmount(totalAmount);
    } else {
      setWorkTotalAmount(0);
    }
  }, [workList]);

  useEffect(() => {
    setValue("work_total_amount", workTotalAmount);
  }, [setValue, workTotalAmount]);

  return { workTotalAmount, disabledButton };
};
