import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { TFunction } from "i18next";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import CustomizedAvatar from "components/Custom/CustomizedAvatar";
import { IUser } from "types/Auth/user";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateFilterParams } from "utils/AgGridFilter";
import { formatDate } from "utils/Date";
import {
  CreatableFieldsQuery,
  useCreatableFieldsQuery,
  CreatableFieldDocumentType,
} from "generated/general";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { GraphQLClient } from "graphql-request";

interface Props {
  t: TFunction;
  handleDelete?: (index: number) => void;
  isDriver?: boolean | undefined;
  billingNote?: boolean | undefined;
}

export const useUserColumnDefs = (
  handleDelete?: Props["handleDelete"],
  isDriver?: Props["isDriver"],
  billingNote?: Props["billingNote"]
): ColDef[] => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const { t } = useTranslation();

  const graphqlClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } = useCreatableFieldsQuery<CreatableFieldsQuery>(
    graphqlClient,
    {
      findManyInput: {
        document_type: CreatableFieldDocumentType.Employee,
        creatable_fields_name: "position",
      },
    }
  );

  useEffect(() => {
    if (!isDriver) {
      if (billingNote) {
        setColumnDefs([
          {
            field: "unique_id",
            headerName: t("user.account.id"),
            filter: "agTextColumnFilter",
          },
          {
            field: "img_url",
            headerName: t("user.account.img_url"),
            filter: false,
            floatingFilter: false,
            cellRenderer: (params: ICellRendererParams<IUser, string[]>) => {
              if (params.value && params.value.length) {
                return (
                  <CustomizedAvatar
                    avatars={params.value.map((v) => ({
                      img_url: v,
                      unique_id: params?.data?.unique_id ?? "",
                      first_name: params?.data?.first_name ?? "",
                      last_name: params?.data?.last_name ?? "",
                    }))}
                  />
                );
              } else
                return (
                  <CustomizedAvatar
                    avatars={[
                      {
                        img_url: undefined,
                        unique_id: params?.data?.unique_id ?? "",
                        first_name: params?.data?.first_name ?? "",
                        last_name: params?.data?.last_name ?? "",
                      },
                    ]}
                  />
                );
            },
            minWidth: 140,
            flex: 1,
          },
          {
            field: "first_name",
            headerName: t("user.account.first_name"),
            filter: "agTextColumnFilter",
          },
          {
            field: "last_name",
            headerName: t("user.account.last_name"),
            filter: "agTextColumnFilter",
          },
          {
            field: "department",
            headerName: t("user.account.department"),
            filter: "agSetColumnFilter",
            filterParams: {
              values: [
                "บริหาร",
                "คลังสินค้า",
                "รายรับ",
                "จัดซื้อ",
                "บัญชี",
                "ขนส่ง",
                "ผลิต",
                "ดูแลระบบ",
              ],
            },
          },
          {
            field: "position",
            headerName: t("user.account.position"),
            filter: "agSetColumnFilter",
            filterParams: {
              values: async (params: any) => {
                // fetch values from server
                const { data } = await refetch();
                const values = data?.CreatableFields;
                const formatValues = values?.map((creatable) => creatable.name);
                params.success(formatValues);
              },
            },
          },
          {
            field: "status",
            headerName: t("user.account.status"),
            filter: "agSetColumnFilter",
            filterParams: {
              values: [1, 0],
              valueFormatter: ({ value }: { value: number }) => {
                return value ? "ใช้งาน" : "หยุดใช้งาน";
              },
            },
            cellRenderer: ({ value }: { value: number }) => {
              return (
                <CustomizedStatus status={value ? "active" : "inactive"} />
              );
            },
            cellStyle: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
          {
            field: "created_date",
            headerName: t("date.created_date"),
            sort: "desc",
            hide: true,
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            suppressColumnsToolPanel: true,
            minWidth: 200,
            flex: 1,
            valueFormatter: (params: ValueFormatterParams) =>
              formatDate(params.value),
          },
        ]);
      } else
        setColumnDefs([
          {
            field: "unique_id",
            headerName: t("user.account.id"),
            filter: "agTextColumnFilter",
          },
          {
            field: "img_url",
            headerName: t("user.account.img_url"),
            filter: false,
            floatingFilter: false,
            cellRenderer: (params: ICellRendererParams<IUser, string[]>) => {
              if (params.value && params.value.length) {
                return (
                  <CustomizedAvatar
                    avatars={params.value.map((v) => ({
                      img_url: v,
                      unique_id: params?.data?.unique_id ?? "",
                      first_name: params?.data?.first_name ?? "",
                      last_name: params?.data?.last_name ?? "",
                    }))}
                  />
                );
              } else
                return (
                  <CustomizedAvatar
                    avatars={[
                      {
                        img_url: undefined,
                        unique_id: params?.data?.unique_id ?? "",
                        first_name: params?.data?.first_name ?? "",
                        last_name: params?.data?.last_name ?? "",
                      },
                    ]}
                  />
                );
            },
            minWidth: 140,
            flex: 1,
          },
          {
            field: "first_name",
            headerName: t("user.account.first_name"),
            filter: "agTextColumnFilter",
          },
          {
            field: "last_name",
            headerName: t("user.account.last_name"),
            filter: "agTextColumnFilter",
          },
          {
            field: "department",
            headerName: t("user.account.department"),
            filter: "agSetColumnFilter",
            filterParams: {
              values: [
                "บริหาร",
                "คลังสินค้า",
                "รายรับ",
                "จัดซื้อ",
                "บัญชี",
                "ขนส่ง",
                "ผลิต",
                "ดูแลระบบ",
              ],
            },
          },
          {
            field: "position",
            headerName: t("user.account.position"),
            filter: "agSetColumnFilter",
            filterParams: {
              values: async (params: any) => {
                // fetch values from server
                const { data } = await refetch();
                const values = data?.CreatableFields;
                const formatValues = values?.map((creatable) => creatable.name);
                params.success(formatValues);
              },
            },
          },
          {
            field: "email",
            headerName: t("global.email"),
            filter: "agTextColumnFilter",
          },
          {
            field: "license_expire_date",
            headerName: t(
              "user.account.delivery_position_details.license_expire_date_title"
            ),
            valueFormatter: (params) =>
              params.data.position === "พนักงานขนส่ง"
                ? formatDate(
                    params.data.delivery_position_details.license_expire_date
                  )
                : "",
            filter: "agTextColumnFilter",
          },
          {
            field: "status",
            headerName: t("user.account.status"),
            filter: "agSetColumnFilter",
            filterParams: {
              values: [1, 0],
              valueFormatter: ({ value }: { value: number }) =>
                value ? "ใช้งาน" : "หยุดใช้งาน",
            },
            cellRenderer: ({ value }: { value: number }) => {
              return (
                <CustomizedStatus status={value ? "active" : "inactive"} />
              );
            },
            cellStyle: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
          {
            field: "created_date",
            headerName: t("date.created_date"),
            sort: "desc",
            hide: true,
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            suppressColumnsToolPanel: true,
            minWidth: 200,
            flex: 1,
            valueFormatter: (params: ValueFormatterParams) =>
              formatDate(params.value),
          },
        ]);
    } else {
      setColumnDefs([
        {
          field: "unique_id",
          headerName: t("user.account.id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "img_url",
          headerName: t("user.account.img_url"),
          filter: false,
          floatingFilter: false,
          cellRenderer: (params: ICellRendererParams<IUser, string[]>) => {
            if (params.value && params.value.length) {
              return (
                <CustomizedAvatar
                  avatars={params.value.map((v) => ({
                    img_url: v,
                    unique_id: params?.data?.unique_id ?? "",
                    first_name: params?.data?.first_name ?? "",
                    last_name: params?.data?.last_name ?? "",
                  }))}
                />
              );
            } else
              return (
                <CustomizedAvatar
                  avatars={[
                    {
                      img_url: undefined,
                      unique_id: params?.data?.unique_id ?? "",
                      first_name: params?.data?.first_name ?? "",
                      last_name: params?.data?.last_name ?? "",
                    },
                  ]}
                />
              );
          },
          minWidth: 140,
          flex: 1,
        },
        {
          field: "first_name",
          headerName: t("user.account.first_name"),
          filter: "agTextColumnFilter",
        },
        {
          field: "last_name",
          headerName: t("user.account.last_name"),
          filter: "agTextColumnFilter",
        },

        {
          field: "department",
          headerName: t("user.account.department"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "บริหาร",
              "คลังสินค้า",
              "รายรับ",
              "จัดซื้อ",
              "บัญชี",
              "ขนส่ง",
              "ผลิต",
              "ดูแลระบบ",
            ],
          },
        },
        {
          field: "position",
          headerName: t("user.account.position"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: async (params: any) => {
              // fetch values from server
              const { data } = await refetch();
              const values = data?.CreatableFields;
              const formatValues = values?.map((creatable) => creatable.name);
              params.success(
                formatValues ? [...formatValues, "พนักงานขนส่ง"] : []
              );
            },
          },
        },
        {
          field: "status",
          headerName: t("user.account.status"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: [1, 0],
            valueFormatter: ({ value }: { value: number }) => {
              return value ? "ใช้งาน" : "หยุดใช้งาน";
            },
          },
          cellRenderer: ({ value }: { value: number }) => {
            return <CustomizedStatus status={value ? "active" : "inactive"} />;
          },
          cellStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
        {
          field: "created_date",
          headerName: t("date.created_date"),
          sort: "desc",
          hide: true,
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
      ]);
    }
  }, [t, isDriver, handleDelete, billingNote, refetch]);

  return columnDefs;
};

export const userDefaultFilter = () => {
  const defaultFilterModel = {
    position: {
      type: "equals",
      filter: "พนักงานขนส่ง",
    },
    status: {
      type: "set",
      values: ["1"],
    },
  };

  return defaultFilterModel;
};
