import { useState, useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MaintenanceRepairType } from "generated/sales";
import CustomizedCheckbox from "components/Custom/CustomizedCheckbox";

type Props = {
  maTypeList: MaintenanceRepairType[];
};

type maintenanceType = {
  label: string;
  selected: boolean;
  value: MaintenanceRepairType;
};

const MaintenanceTypeList = ({ maTypeList }: Props) => {
  const { t } = useTranslation();
  const [maType, setMaType] = useState<maintenanceType[]>([
   
  ]);

  useEffect(() => {
    setMaType([
      {
        label: t("logistic.maintenance.ma_details.ma_type.engine"),
        selected: maTypeList.includes(MaintenanceRepairType.Engine),
        value: MaintenanceRepairType.Engine,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.clutch"),
        selected: maTypeList.includes(MaintenanceRepairType.Clutch),
        value: MaintenanceRepairType.Clutch,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.gear_rear"),
        selected: maTypeList.includes(MaintenanceRepairType.GearRear),
        value: MaintenanceRepairType.GearRear,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.brake"),
        selected: maTypeList.includes(MaintenanceRepairType.Brake),
        value: MaintenanceRepairType.Brake,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.electrical_AC"),
        selected: maTypeList.includes(MaintenanceRepairType.ElectricalAc),
        value: MaintenanceRepairType.ElectricalAc,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.suspension"),
        selected: maTypeList.includes(MaintenanceRepairType.Suspension),
        value: MaintenanceRepairType.Suspension,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.tire_wheel"),
        selected: maTypeList.includes(MaintenanceRepairType.TireWheel),
        value: MaintenanceRepairType.TireWheel,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.AIRuu_a4"),
        selected: maTypeList.includes(MaintenanceRepairType.Airuu),
        value: MaintenanceRepairType.Airuu,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.tail_lift"),
        selected: maTypeList.includes(MaintenanceRepairType.TailLift),
        value: MaintenanceRepairType.TailLift,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.battery"),
        selected: maTypeList.includes(MaintenanceRepairType.Battery),
        value: MaintenanceRepairType.Battery,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.air_generator_40"),
        selected: maTypeList.includes(MaintenanceRepairType.AirGenerator_40),
        value: MaintenanceRepairType.AirGenerator_40,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.engine_oil"),
        selected: maTypeList.includes(MaintenanceRepairType.EngineOil),
        value: MaintenanceRepairType.EngineOil,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.gear_oil"),
        selected: maTypeList.includes(MaintenanceRepairType.GearOil),
        value: MaintenanceRepairType.GearOil,
      },
      {
        label: t("logistic.maintenance.ma_details.ma_type.others"),
        selected: maTypeList.includes(MaintenanceRepairType.Others),
        value: MaintenanceRepairType.Others,
      },
    ]);
  }, [t, maTypeList]);

  return (
    <Box
      sx={{
        border: `1px solid #d6cfcf`,
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      <Box sx={{ bgcolor: "#ECF6FD", padding: "0.5rem 0" }}>
        <Typography
          sx={{
            fontSize: "10.66px",
            fontWeight: 600,
            ml: "1rem",
          }}
        >
          {t("logistic.maintenance.ma_details.ma_type.index")}
        </Typography>
      </Box>
      <Grid
        container
        margin={"0.5rem"}
        sx={{
          fontSize: "10.66px",
          ml: 1.5,
        }}
      >
        {maType.map((type) => (
          <Grid item xs={6} sm={3} md={3}>
            <CustomizedCheckbox
              label={
                <Typography
                  sx={{
                    fontSize: "10.66px",
                    color: "#000000",
                    textDecoration: "none",
                  }}
                >
                  {type.label}
                </Typography>
              }
              value={type.selected}
              sx={{
                "& .MuiSvgIcon-root": {
                  width: "14px",
                  height: "14px",
                },
                height: "1rem",
              }}
              isDisabled
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MaintenanceTypeList;
