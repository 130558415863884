import { Grid, Box, Typography } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { IBankAccountDetail, IContact } from "../../../types/Contact/contact";
import ControlledNumberTextField from "../../Controller/ControlledNumberTextField";
import { useTranslation } from "react-i18next";
import { useDisable } from "../../../hooks/use-disable";
import ControlledCheckbox from "../../Controller/ControlledCheckbox";
import ControlledTextField from "../../Controller/ControlledTextField";
import CustomizedButton from "../../Custom/CustomizedButton";
import { useModal } from "../../../hooks/use-modal";
import BankModal from "../../UI/Modal/BankModal";
import BankCard from "../../UI/BankCard";

const ContactFinance = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IContact>();
  const { t } = useTranslation();

  const { modal, closeModalHandler, openModalHandler } = useModal();

  const [disabled] = useDisable();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "bank_account_detail_list",
  });

  const addBankHandler = (data: IBankAccountDetail) => {
    append(data);
  };

  return (
    <Box mt={2}>
      <Typography fontWeight={600}>
        {t("contact.financial.payment_type")}
      </Typography>
      <ControlledCheckbox
        label="เช็ค"
        control={control}
        name="is_cheque"
        disabled={disabled}
      />
      <ControlledCheckbox
        label="โอนเงิน"
        control={control}
        name="is_transfer"
        disabled={disabled}
      />
      <ControlledCheckbox
        label="เงินสด"
        control={control}
        name="is_cash"
        disabled={disabled}
      />
      <Typography fontWeight={600} mb={1.5}>
        {t("contact.financial.payment_date")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledNumberTextField
            fullWidth
            control={control}
            name="payment_duration"
            error={Boolean(errors.payment_duration)}
            helperText={errors.payment_duration?.message?.toString()}
            label={t("contact.financial.payment_duration")}
            viewMode={disabled}
            endAdornmentMessage="วัน"
            decimalScale={0}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            label={t("contact.financial.contact_person")}
            name="payment_contact_person"
            error={Boolean(errors.payment_contact_person)}
            helperText={errors.payment_contact_person?.message?.toString()}
            viewMode={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            label={t("contact.financial.contact_phone")}
            name="payment_contact_phone"
            error={Boolean(errors.payment_contact_person)}
            helperText={errors.payment_contact_person?.message?.toString()}
            viewMode={disabled}
          />
        </Grid>
      </Grid>
      <Typography fontWeight={600} my={1.5}>
        {t("contact.financial.billing_note_date")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledNumberTextField
            fullWidth
            control={control}
            name="billing_note_duration"
            error={Boolean(errors.billing_note_duration)}
            helperText={errors.billing_note_duration?.message?.toString()}
            label={t("contact.financial.billing_note_duration")}
            viewMode={disabled}
            endAdornmentMessage="วัน"
            decimalScale={0}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            label={t("contact.financial.contact_person")}
            name="billing_note_contact_person"
            error={Boolean(errors.billing_note_contact_person)}
            helperText={errors.billing_note_contact_person?.message?.toString()}
            viewMode={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            label={t("contact.financial.contact_phone")}
            name="billing_note_contact_phone"
            error={Boolean(errors.billing_note_contact_person)}
            helperText={errors.billing_note_contact_person?.message?.toString()}
            viewMode={disabled}
          />
        </Grid>
      </Grid>
      <Typography fontWeight={600} my={1.5}>
        {t("contact.financial.bank.index")}
      </Typography>
      {fields.length > 0 ? (
        <Grid container spacing={1.5}>
          <>
            {fields.map((bank, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                key={`${bank.provider}-${bank.account_number}-${index}`}
              >
                <BankCard
                  bank={bank}
                  index={index}
                  remove={remove}
                  update={update}
                />
              </Grid>
            ))}
          </>
        </Grid>
      ) : disabled ? (
        <Typography sx={{ color: "#737373" }}>ไม่มีข้อมูล</Typography>
      ) : undefined}
      {!disabled && fields.length < 12 && (
        <CustomizedButton
          title="เพิ่มธนาคาร"
          startAddIcon
          onClick={openModalHandler}
        />
      )}
      <Typography fontWeight={600} my={1.5}>
        {t("contact.financial.credit_request")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledNumberTextField
            fullWidth
            control={control}
            name="credit_request"
            error={Boolean(errors.credit_request)}
            helperText={errors.credit_request?.message?.toString()}
            label={t("contact.financial.credit_request")}
            viewMode={disabled}
            endAdornmentMessage="บาท"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledNumberTextField
            fullWidth
            control={control}
            name="credit_request_duration"
            error={Boolean(errors.credit_request_duration)}
            helperText={errors.credit_request_duration?.message?.toString()}
            label={t("contact.financial.credit_request_duration")}
            viewMode={disabled}
            endAdornmentMessage="วัน"
            decimalScale={0}
          />
        </Grid>
      </Grid>
      <Typography fontWeight={600} my={1.5}>
        {t("contact.financial.account")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="account_debtor"
            error={Boolean(errors.account_debtor)}
            helperText={errors.account_debtor?.message?.toString()}
            label={t("contact.financial.account_debtor")}
            viewMode={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="account_creditor"
            error={Boolean(errors.account_creditor)}
            helperText={errors.account_creditor?.message?.toString()}
            label={t("contact.financial.account_creditor")}
            viewMode={disabled}
          />
        </Grid>
      </Grid>
      <BankModal
        open={modal}
        closeModalHandler={closeModalHandler}
        onAddHandler={addBankHandler}
        isCreate
      />
    </Box>
  );
};

export default ContactFinance;
