import { InputAdornment, Typography } from "@mui/material";
import { IOptionsCreatable } from "types/global";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { numberFormatter } from "utils/Formatter/Global";
import CustomizedComboBox from "components/Custom/CustomizedComboBox";
import { IQuotation } from "types/Sales/quotation";
import { useDisable } from "hooks/use-disable";
import { useEffect } from "react";

type Props = {
  field: any;
  index: number;
  disabledStatus: boolean;
};

const withholdingTaxTypeOption: IOptionsCreatable[] = [
  { id: 1, value: "ยังไม่ระบุ", label: "ยังไม่ระบุ" },
  { id: 2, value: "ไม่มี", label: "ไม่มี" },
  { id: 3, value: "0.75", label: "0.75" },
  { id: 4, value: "1", label: "1" },
  { id: 5, value: "1.5", label: "1.5" },
  { id: 6, value: "2", label: "2" },
  { id: 7, value: "3", label: "3" },
  { id: 8, value: "5", label: "5" },
  { id: 9, value: "10", label: "10" },
  { id: 10, value: "15", label: "15" },
];

const withholdingTaxTypeOptionValues = withholdingTaxTypeOption.map(
  (option) => option.value
);

const WithholdingTaxCell = ({ field, index, disabledStatus }: Props) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IQuotation>();

  const [disabled] = useDisable();

  const item_post_discount_amount = useWatch({
    control,
    name: `item_list.${index}.post_discount_amount`,
  });

  const withholding_tax_type = useWatch({
    control,
    name: `item_list.${index}.withholding_tax_type`,
  });

  const withholding_tax_value = useWatch({
    control,
    name: `item_list.${index}.withholding_tax_value`,
  });

  useEffect(() => {
    setValue(
      `item_list.${index}.withholding_tax_value`,
      (item_post_discount_amount *
        (withholding_tax_type === "ยังไม่ระบุ" ||
        withholding_tax_type === "ไม่มี" ||
        withholding_tax_type === "" ||
        withholding_tax_type === undefined
          ? 0
          : parseFloat(withholding_tax_type))) /
        100
    );
  }, [index, item_post_discount_amount, setValue, withholding_tax_type]);

  const noWithholdingTax = ["ไม่มี", "ยังไม่ระบุ", "", null].includes(
    withholding_tax_type
  );

  const validWithholdingTax =
    withholdingTaxTypeOptionValues.includes(withholding_tax_type);

  return (
    <>
      {disabled ? (
        <>
          <Typography>
            {field.withholding_tax_type} {noWithholdingTax ? undefined : "%"}
          </Typography>
          {noWithholdingTax || !validWithholdingTax ? undefined : (
            <Typography variant="caption">
              {numberFormatter(withholding_tax_value)} บาท
            </Typography>
          )}
        </>
      ) : (
        <Controller
          control={control}
          name={`item_list.${index}.withholding_tax_type`}
          render={({ field }) => (
            <CustomizedComboBox
              {...field}
              freeSolo
              disableClearable
              sx={{ mt: noWithholdingTax || !validWithholdingTax ? 0 : 3 }}
              options={withholdingTaxTypeOption}
              onChange={(_, newValue) => {
                if (newValue) {
                  field.onChange(newValue.value || "");
                }
              }}
              onInputChange={(_, newValue) => {
                if (newValue) {
                  field.onChange(newValue);
                }
              }}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              error={Boolean(
                errors?.item_list &&
                  errors?.item_list[index] &&
                  errors?.item_list[index]?.withholding_tax_type
              )}
              helperText={
                noWithholdingTax || !validWithholdingTax
                  ? undefined
                  : numberFormatter(withholding_tax_value) + " บาท"
              }
              required
              disabled={disabledStatus}
            />
          )}
        />
      )}
    </>
  );
};

export default WithholdingTaxCell;
