import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/Date";
import { dateFilterParams } from "utils/AgGridFilter";
import { statusValueFormatter } from "utils/Formatter/Global";

import CustomizedStatus from "components/Custom/CustomizedStatus";

import { formatNumber } from "utils/Global";
import { typeStatusValueFormatter } from "utils/Formatter/Logistic/Vehicle";

export const useTireHistoryColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "tire_notification_status",
        headerName: t("logistic.tire.history.tire_status"),
        filter: "agSetColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
        filterParams: {
          values: ["normal", "approaching", "overdue"],
          valueFormatter: (params: ValueFormatterParams) =>
            typeStatusValueFormatter(params.value),
        },
      },
      {
        field: "tire_series",
        headerName: t("logistic.tire.serial"),
        filter: "agTextColumnFilter",
      },
      {
        field: "action_type",
        headerName: t("logistic.tire.history.action_type"),
        filter: "agTextColumnFilter",
      },
      {
        field: "previous_position",
        headerName: t("logistic.tire.history.previous_position"),
        filter: "agTextColumnFilter",
      },
      {
        field: "current_position",
        headerName: t("logistic.tire.history.current_position"),
        filter: "agTextColumnFilter",
      },
      {
        field: "action_date",
        headerName: t("logistic.tire.history.action_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "action_distance",
        headerName: t("logistic.tire.history.action_distance"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          params.value.toLocaleString(),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "tire_distance",
        headerName: t("logistic.tire.current_distance"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          params.value.toLocaleString(),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "due_distance",
        headerName: t("logistic.tire.due_distance"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          params.value.toLocaleString(),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "usage_distance",
        headerName: t("logistic.tire.history.usage_distance"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          params.value.toLocaleString(),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "price_per_unit",
        headerName: t("logistic.tire.price_per_unit"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          formatNumber(params.value || 0),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "band",
        headerName: t("logistic.tire.band"),
        filter: "agTextColumnFilter",
      },
      {
        field: "model",
        headerName: t("logistic.tire.model"),
        filter: "agTextColumnFilter",
      },
      {
        field: "vehicle_unique_id",
        headerName: t("logistic.tire.history.vehicle_unique_id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "vehicle_license",
        headerName: t("logistic.tire.history.vehicle_license"),
        filter: "agTextColumnFilter",
      },
      {
        field: "remark",
        headerName: t("logistic.tire.history.remark"),
        filter: "agTextColumnFilter",
      },
      {
        field: "tire_status",
        headerName: t("logistic.tire.history.tire_notification_status"),
        filter: "agSetColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
        filterParams: {
          values: ["stock", "active", "in_active"],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  return columnDefs;
};
