import { RefObject, useState } from "react";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { AgGridReact } from "ag-grid-react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { PurchaseExportType } from "generated/purchase";
import { GENERATE_EXPORT } from "services/Purchase/Report/GenerateExport";
import { dateFilterModel } from "utils/AgGridFilter";

export const usePurchaseReport = (
  gridRef: RefObject<AgGridReact<any>>,
  exportType: PurchaseExportType
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const onBtnExport = async () => {
    setIsLoading(true);
    try {
      const filterModel = gridRef?.current?.api.getFilterModel();
      const sortModel = gridRef?.current?.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));

      if (filterModel) {
        const { due_date } = filterModel;
        const formatFilter = {
          ...filterModel,
          due_date: dateFilterModel(due_date),
        };

        try {
          const { GenerateExport } =
            await graphQLClientWithHeaderPurchase.request(GENERATE_EXPORT, {
              input: {
                export_type: exportType,
                params: {
                  aggridInput: {
                    filterModel: formatFilter,
                    sortModel,
                  },
                },
              },
            });
          if (GenerateExport) {
            window.open(GenerateExport || "", "_blank");
            enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
              variant: "success",
            });
          } else {
            enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
              variant: "error",
            });
          }
        } catch (error) {
          enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };
  return {
    onBtnExport,
    isLoading,
  };
};
