import { useEffect, RefObject } from "react";
import { useCurrentStockBySNReportColumnDefs } from "./columnDefs";
import { GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import AgGrid from "components/UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { GET_CURRENT_STOCK_BY_SN_REPORT_VIEW } from "services/AgGrid/InventoryReportAgGrid";
import { dateFilterModel } from "utils/AgGridFilter";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const CurrentStockBySNReportTable = ({ gridRef }: Props) => {
  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const columnDefs = useCurrentStockBySNReportColumnDefs();
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { lot_date, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        lot_date: dateFilterModel(lot_date),
      };
      try {
        const { CurrentStockBySNReportView } = await graphQLClientWms.request(
          GET_CURRENT_STOCK_BY_SN_REPORT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: CurrentStockBySNReportView.results as any[],
          rowCount: CurrentStockBySNReportView.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
    }
  }, [gridRef]);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onGridReady={onGridReady}
    />
  );
};

export default CurrentStockBySNReportTable;
