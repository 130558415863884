import { Control, Controller } from "react-hook-form";
import CustomizedRadioGroup from "../Custom/CustomizedRadioGroup";
import { RadioGroupProps } from "@mui/material";
import { IRadio } from "../../types/global";

type ExtendedRadioGroupProps = {
  control: Control<any>;
  name: string;
  row: RadioGroupProps["row"];
  radioLists: IRadio[];
  disabled: boolean;
  onChangeCallback?: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: any
  ) => void;
};

const ControlledRadioGroup = ({
  name,
  row,
  control,
  radioLists,
  disabled,
  onChangeCallback,
}: ExtendedRadioGroupProps) => {
  return (
    <Controller
      key={`${name}-radio-group`}
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedRadioGroup
          {...field}
          radioList={radioLists}
          row={row}
          disabled={disabled}
          onChangeCallback={onChangeCallback}
        />
      )}
    />
  );
};

export default ControlledRadioGroup;
