import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";

export const usePurchaseOrderOption = (
  status?: string | null,
  disabled?: boolean
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "อ้างอิงเอกสารภายนอก",
        disabled: false,
      },
      {
        value: "แก้ไข",
        disabled: !disabled || false,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
      {
        value: "ยกเลิกจำนวนคงค้าง",
        disabled: false,
      },
    ],
    [disabled]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions([defaultOptions[2], defaultOptions[3]]); // ยกเลิก, คัดลอก
        break;
      case "wait_ordered":
        setSelectedModifyOptions([
          defaultOptions[0],
          defaultOptions[1],
          defaultOptions[2],
          defaultOptions[3],
        ]); // คัดลอก
        break;
      case "partially_imported":
        setSelectedModifyOptions([
          defaultOptions[0],
          defaultOptions[4],
          defaultOptions[2],
        ]); // คัดลอก
        break;
      case "cancelled":
        setSelectedModifyOptions([defaultOptions[2]]); // คัดลอก
        break;
      case "fully_ordered":
      case "fully_imported":
        setSelectedModifyOptions([defaultOptions[0], defaultOptions[2]]); // อ้างอิงเอกสารภายนอก , คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions);
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};

export const usePurchaseOrderCreate = (
  status?: string | null,
  isMaxQty?: boolean
) => {
  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: t("inventory.goods_receive.index"),
        disabled: isMaxQty,
      },
      {
        value: t("purchase.return.index"),
        disabled: false,
      },
    ],
    [isMaxQty, t]
  );

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [options, setOptions] = useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "wait_ordered":
      case "fully_ordered":
      case "partially_imported":
      case "fully_imported":
        setShowOptions(true);
        break;
      default:
        setShowOptions(false);
    }
  }, [status]);

  useEffect(() => {
    switch (status) {
      case "fully_ordered":
        setOptions([defaultOptions[0], defaultOptions[1]]);
        break;
      case "partially_imported":
        setOptions([defaultOptions[0], defaultOptions[1]]);
        break;
      case "fully_imported":
        setOptions([defaultOptions[1]]);
        break;
      default:
        setOptions(defaultOptions);
    }
  }, [defaultOptions, status]);

  return {
    selectCreateOptions: options,
    showCreateOptions: showOptions,
  };
};
