import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import ModalUI from "./ModalUI";
import LabelInput from "../LabelInput";
import { ISalesPerson } from "../../../types/Contact/contact";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import CustomizedStatus from "../../Custom/CustomizedStatus";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  data: ISalesPerson;
};

const SalesPersonModal = ({ open, closeModalHandler, data }: Props) => {
  const { t } = useTranslation();

  const onClose = () => {
    closeModalHandler();
  };

  return (
    <ModalUI
      title={
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography fontWeight={600} fontSize={18}>
            {t("contact.sales_person.index")}
          </Typography>
          <CustomizedStatus status={data.status ? "active" : "inactive"} />
        </Box>
      }
      open={open}
      handleClose={onClose}
      width={900}
      maxWidth="md"
    >
      <Grid container spacing={1.5} mt={0}>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <Box
            sx={{
              height: "fit-content",
              width: "fit-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderWidth: 2,
              borderRadius: 2,
              borderColor: "#eeeeee",
              borderStyle: "dashed",
            }}
          >
            <Box
              className="image-container"
              width={134}
              height={134}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {data.img_url[0] ? (
                <img
                  alt="company"
                  src={data.img_url[0] as string}
                  className={"image-no-hover"}
                />
              ) : (
                <ImageOutlinedIcon color={"primary"} fontSize="large" />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LabelInput label={t("user.account.id")} value={data.unique_id} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LabelInput
                label={t("user.account.nick_name")}
                value={data.nick_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LabelInput
                label={t("user.account.first_name")}
                value={data.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LabelInput
                label={t("user.account.last_name")}
                value={data.last_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LabelInput
                label={t("user.account.department")}
                value={data.department}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LabelInput
                label={t("user.account.position")}
                value={data.position}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default SalesPersonModal;
