import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
  IServerSideGetRowsParams,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import AgGrid from "components/UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { useSalesAgGrid } from "hooks/Sales/use-sales-aggrid";
import { RefObject } from "react";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { DELIVERY_TRIP_EXPENSES_REPORT } from "services/Logistic/Report/DeliveryTripReportAgGrid";
import { dateFilterModel, dateFilterParams } from "utils/AgGridFilter";
import { formatDate, formatDateTime } from "utils/Date";
import { numberFormatter, statusValueFormatter } from "utils/Formatter/Global";
import { typeValueFormatter } from "utils/Formatter/Logistic/Vehicle";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const DeliveryTripExpensesReportTable = ({ gridRef }: Props) => {
  const { quotationFilterParams } = useSalesAgGrid("quotation");
  const columnDefs: ColDef[] = [
    {
      field: "unique_id",
      headerName: "เลขที่เอกสาร",
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      field: "reference_document",
      headerName: "หมายเลขอ้างอิง QT",
      filter: "agSetColumnFilter",
      filterParams: quotationFilterParams,
      valueFormatter: (params: ValueFormatterParams) => {
        if (Array.isArray(params.value) && params.value.length > 0) {
          return params.value.join(", ");
        } else {
          return "-";
        }
      },
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "สถานะ",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["wait_deliver", "delivering", "finished"],
        valueFormatter: (params: ValueFormatterParams) =>
          statusValueFormatter(params.value),
      },
      cellRenderer: (params: ICellRendererParams) => (
        <CustomizedStatus status={params.value} />
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-center",
        width: "100%",
      },
      minWidth: 200,
    },
    {
      field: "flag_status",
      headerName: "เกินกำหนด",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [1, 0],
        valueFormatter: ({ value }: { value: number }) => {
          if (value) return "ใช่";
          return "ไม่ใช่";
        },
      },
      cellRenderer: ({ value }: { value: number }) => {
        return <CustomizedStatus status={value ? "late" : ""} />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "flex-center",
        width: "100%",
      },
      minWidth: 200,
    },
    {
      field: "job_no",
      headerName: "Job Number",
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "vehicle_no",
      headerName: "รหัสรถ",
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      field: "vehicle_license_plate",
      headerName: "ทะเบียนรถ",
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      field: "trailer_license_plate",
      headerName: "ทะเบียนหาง",
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "primary_driver_name",
      headerName: "พนักงานขนส่ง",
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "secondary_driver_name",
      headerName: "พนักงานขนส่ง 2",
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value && params.value !== " ") return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "related_user_list",
      headerName: "ผู้เกี่ยวข้อง",
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (Array.isArray(params.value) && params.value.length > 0) {
          return params.value.join(", ");
        } else {
          return "-";
        }
      },
      minWidth: 200,
    },
    {
      field: "created_date",
      headerName: "วันที่สร้าง",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
      minWidth: 200,
    },
    {
      field: "issue_date",
      headerName: "วันที่ออกเอกสาร",
      filter: "agDateColumnFilter",
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
      minWidth: 200,
    },
    {
      field: "customer_unique_id",
      headerName: "รหัสลูกค้า",
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      field: "customer_name",
      headerName: "ชื่อลูกค้า",
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      field: "vehicle_type",
      headerName: "ประเภทรถ",
      filter: "agSetColumnFilter",
      valueFormatter: (params: ValueFormatterParams) =>
        typeValueFormatter(params.value),
      filterParams: {
        values: ["head", "four_wheel", "six_wheel", "ten_wheel"],
        valueFormatter: (params: ValueFormatterParams) =>
          typeValueFormatter(params.value),
      },
      minWidth: 200,
    },
    {
      field: "container_type",
      headerName: "ประเภทตู้",
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "item_type",
      headerName: "ประเภทสินค้า",
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "item_amount",
      headerName: "มูลค่าสินค้า",
      filter: false,
      valueFormatter: (params) => {
        const value = params.value;
        if (value === null || value === undefined || value === "") {
          return "-";
        }
        return isNaN(value) ? value : numberFormatter(Number(value));
      },
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      headerClass: "ag-end-header",
      minWidth: 200,
    },
    {
      field: "expected_temperature",
      headerName: "อุณหภูมิ",
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "is_frozen",
      headerName: "Frozen",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        valueFormatter: (params: ValueFormatterParams) =>
          params.value ? "ใช่" : "ไม่ใช่",
      },
      valueFormatter: ({ value }: { value: boolean }) => {
        switch (value) {
          case true:
            return "ใช่";
          case false:
            return "ไม่ใช่";
          default:
            return "-";
        }
      },
      minWidth: 200,
    },
    {
      field: "plan_start_date",
      headerName: "วันที่เวลาเริ่มต้น (แผน)",
      filter: "agDateColumnFilter",
      valueFormatter: (params: ValueFormatterParams) =>
        formatDateTime(params.value),
      minWidth: 200,
    },
    {
      field: "plan_end_date",
      headerName: "วันที่เวลาสิ้นสุด (แผน)",
      filter: "agDateColumnFilter",
      valueFormatter: (params: ValueFormatterParams) =>
        formatDateTime(params.value),
      minWidth: 200,
    },
    {
      field: "actual_start_date",
      headerName: "วันที่เวลาเริ่มต้น (จริง)",
      filter: "agDateColumnFilter",
      valueFormatter: (params: ValueFormatterParams) =>
        params.value ? formatDateTime(params.value) : "-",
      minWidth: 200,
    },
    {
      field: "actual_end_date",
      headerName: "วันที่เวลาสิ้นสุด (จริง)",
      filter: "agDateColumnFilter",
      valueFormatter: (params: ValueFormatterParams) =>
        params.value ? formatDateTime(params.value) : "-",
      minWidth: 200,
    },
    {
      field: "item_id",
      headerName: "รายการ",
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "item_unique_id",
      headerName: "รหัสสินค้า (บริการ)",
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "item_name",
      headerName: "ชื่อสินค้า (บริการ)",
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "item_sku_desc",
      headerName: "รายละเอียดสินค้า (บริการ)",
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "qty",
      headerName: "จำนวน",
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      headerClass: "ag-end-header",
      minWidth: 200,
    },
    {
      field: "uom_name",
      headerName: "หน่วย",
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "price_per_unit",
      headerName: "ราคา/หน่วย",
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return numberFormatter(params.value);
        else return "0.00";
      },
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      headerClass: "ag-end-header",
      minWidth: 200,
    },
    {
      field: "discount",
      headerName: "ส่วนลด",
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return numberFormatter(params.value);
        else return "0.00";
      },
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      headerClass: "ag-end-header",
      minWidth: 200,
    },
    {
      field: "vat_percentage",
      headerName: "ภาษี (%)",
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "pre_vat_amount",
      headerName: "จำนวนเงิน",
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return numberFormatter(params.value);
        else return "0.00";
      },
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      headerClass: "ag-end-header",
      minWidth: 200,
    },
    {
      field: "withholding_tax_type",
      headerName: "ภาษีรายได้ (%)",
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
    {
      field: "expenses_net_amount",
      headerName: "มูลค่ารวมสุทธิ",
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return numberFormatter(params.value);
        else return "0.00";
      },
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      headerClass: "ag-end-header",
      minWidth: 200,
    },
    {
      field: "expenses_total_amount",
      headerName: "ยอดโอน",
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return numberFormatter(params.value);
        else return "0.00";
      },
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      headerClass: "ag-end-header",
      minWidth: 200,
    },
    {
      field: "remark",
      headerName: "หมายเหตุเอกสาร",
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value || "-";
        else return "-";
      },
      minWidth: 200,
    },
  ];

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const {
        created_date,
        issue_date,
        plan_start_date,
        plan_end_date,
        actual_start_date,
        actual_end_date,
        is_frozen,
        flag_status,
        reference_document,
      } = filterModel;

      const formatFilter = {
        ...filterModel,
        created_date: dateFilterModel(created_date),
        issue_date: dateFilterModel(issue_date),
        plan_start_date: dateFilterModel(plan_start_date),
        plan_end_date: dateFilterModel(plan_end_date),
        actual_start_date: dateFilterModel(actual_start_date),
        actual_end_date: dateFilterModel(actual_end_date),
        is_frozen: is_frozen && {
          filterType: "setBoolean",
          values: is_frozen.values.map((v: string) => v === "true"),
        },
        flag_status: flag_status && {
          ...flag_status,
          values: flag_status.values.map((v: string) => parseInt(v)),
        },
        reference_document: reference_document
          ? {
              filterType: "array",
              type: "hasSome",
              values: reference_document.values,
            }
          : undefined,
      };
      const formatSort = [
        ...sortModel,
        { colId: "issue_date", sort: "desc" },
        { colId: "item_id", sort: "asc" },
      ];
      try {
        const { DeliveryTripExpensesReportViewsAggrid } =
          await graphQLClient.request(DELIVERY_TRIP_EXPENSES_REPORT, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel: formatSort,
            },
          });
        params.success({
          rowData: DeliveryTripExpensesReportViewsAggrid.results,
          rowCount: DeliveryTripExpensesReportViewsAggrid.count,
        });
      } catch (err) {
        console.error("Error loading rows:", err);
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setFilterModel({
      status: {
        type: "set",
        values: ["delivering", "finished"],
      },
    });
    params.api.onFilterChanged();
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default DeliveryTripExpensesReportTable;
