import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useDeliveryTripReport } from "hooks/Logistic/DeliveryTrip/use-delivery-trip-report";
import { DeliveryReportType } from "generated/sales";
import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import DeliveryTripReportTable from "components/Table/Logistic/Report/DeliveryTripReportTable";

const DeliveryTripReport = () => {
    const { t } = useTranslation();
    const gridRef = useRef<AgGridReact>(null);

    const { onBtnExport, isLoading } = useDeliveryTripReport(
        gridRef,
        DeliveryReportType.DeliveryTrip
    );
    const breadcrumbs: IBreadcrumbsAndMenu[] = [
        {
            name: t("logistic.index"),
            to: "/logistic"
        },
        {
            name: t("report"),
            to: "/logistic/report"
        },
        {
            name: t("logistic.delivery_trip.index")
        },
    ];

    return (
        <>
            <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
            <HeaderLayout noMaxWidth>
                <Typography variant="h5">
                    {`${t("logistic.delivery_trip.index")}`}
                </Typography>
                <CustomizedButton
                    title={`${t("button.export")}`}
                    variant="contained"
                    onClick={onBtnExport}
                    disabled={isLoading}
                />
            </HeaderLayout>
            <DeliveryTripReportTable gridRef={gridRef} />
        </>
    );
};

export default DeliveryTripReport;
