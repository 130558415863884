import { useEffect, RefObject } from "react";
import { useTraceEntryReportColumnDefs } from "./columnDefs";
import { GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import AgGrid from "components/UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { TRACE_ENTRY_VIEW } from "services/AgGrid/TraceEntryAggrid";
import { dateFilterModel } from "utils/AgGridFilter";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const TraceEntryReportTable = ({ gridRef }: Props) => {
  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const columnDefs = useTraceEntryReportColumnDefs();
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { created_date, posted_date, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        created_date: dateFilterModel(created_date),
        posted_date: dateFilterModel(posted_date),
      };
      try {
        const { TraceEntriesViewAggrid } = await graphQLClientWms.request(
          TRACE_ENTRY_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: TraceEntriesViewAggrid.results as any[],
          rowCount: TraceEntriesViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
    }
  }, [gridRef]);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onGridReady={onGridReady}
    />
  );
};

export default TraceEntryReportTable;
