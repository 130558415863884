import { Box, Typography } from "@mui/material";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {
  qty: number;
  icon: ReactNode;
  title: string;
  margin?: number | string;
  url: string;
};

const QtyBox = ({ qty, icon, title, margin, url }: Props) => {
  const { t } = useTranslation();
  return (
    <Link
      to={url}
      style={{
        textDecoration: "none",
      }}
    >
      <CustomizedBox
        height={"calc(50% - 8px)"}
        border={`1px solid white`}
        margin={margin}
        padding={"0.5rem 1rem"}
        bgcolor={"white"}
        boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.05)"}
        isNavigate
      >
        <Typography fontSize={16} fontWeight={600}>
          {title}
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {icon}
          <Typography variant="h5" align="right" color={"primary.main"}>
            {qty}
          </Typography>
        </Box>

        <Typography align="right" fontSize={12} color={"#737373"}>
          {t("record")}
        </Typography>
      </CustomizedBox>
    </Link>
  );
};

export default QtyBox;
