import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import {
  InventorySettingType,
  useInventorySettingsByTypeQuery,
  InventorySettingsByTypeQuery,
  useWarehousesFindAllQuery,
} from "generated/general";
import { ISelectOption } from "types/global";

export const useUnitOptions = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: uomsData, isLoading: isLoadingUoms } =
    useInventorySettingsByTypeQuery<InventorySettingsByTypeQuery>(
      graphQLClient,
      {
        type: InventorySettingType.Unit,
      }
    );

  const uomOptions: ISelectOption[] =
    uomsData?.InventorySettingsByType?.map((uom) => ({
      id: uom?.id,
      label: uom?.name,
      value: uom?.id,
    })) || [];

  const renderFieldValue = (value?: number) => {
    return (
      value &&
      uomsData?.InventorySettingsByType?.find((uom) => uom?.id === value)?.name
    );
  };

  const allUnit = uomsData?.InventorySettingsByType?.map((uom) => ({
    id: uom?.id,
    name: uom?.name,
  }));

  return {
    options: uomOptions,
    isLoading: isLoadingUoms,
    renderOption: renderFieldValue,
    allUnits: allUnit || [],
  };
};

export const useWarehouseOptions = () => {
  const graphQLClient = createGraphQLClientWithMiddleware("general");

  const { data, isLoading } = useWarehousesFindAllQuery(graphQLClient);

  const options: ISelectOption[] =
    data?.WarehousesFindAll?.map((warehouse) => ({
      id: warehouse?.id,
      label: warehouse.unique_id + " - " + warehouse?.name,
      value: warehouse?.id,
    })) || [];

  const renderFieldValue = (value?: number) => {
    const findOption = data?.WarehousesFindAll?.find(
      (warehouse) => warehouse?.id === value
    );
    if (value && findOption)
      return findOption.unique_id + " - " + findOption?.name;
  };

  return {
    options,
    isLoading,
    renderOption: renderFieldValue,
  };
};

export const useCategory1Options = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } =
    useInventorySettingsByTypeQuery<InventorySettingsByTypeQuery>(
      graphQLClient,
      {
        type: InventorySettingType.Cate_1,
      }
    );

  const options: ISelectOption[] =
    data?.InventorySettingsByType?.map((category) => ({
      id: category?.id,
      label: category?.name,
      value: category?.id,
    })) || [];

  const renderFieldValue = (value?: number) => {
    return (
      value &&
      data?.InventorySettingsByType?.find((cate1) => cate1?.id === value)?.name
    );
  };

  return {
    options,
    isLoading,
    renderOption: renderFieldValue,
    allCategories: data || [],
  };
};

export const useCategory2Options = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } =
    useInventorySettingsByTypeQuery<InventorySettingsByTypeQuery>(
      graphQLClient,
      {
        type: InventorySettingType.Cate_2,
      }
    );

  const options: ISelectOption[] =
    data?.InventorySettingsByType?.map((category) => ({
      id: category?.id,
      label: category?.name,
      value: category?.id,
    })) || [];

  const renderFieldValue = (value?: number) => {
    return (
      value &&
      data?.InventorySettingsByType?.find((cate2) => cate2?.id === value)?.name
    );
  };

  return {
    options,
    isLoading,
    renderOption: renderFieldValue,
    allCategories: data || [],
  };
};

export const useCategory3Options = () => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } =
    useInventorySettingsByTypeQuery<InventorySettingsByTypeQuery>(
      graphQLClient,
      {
        type: InventorySettingType.Cate_3,
      }
    );

  const options: ISelectOption[] =
    data?.InventorySettingsByType?.map((category) => ({
      id: category?.id,
      label: category?.name,
      value: category?.id,
    })) || [];

  const renderFieldValue = (value?: number) => {
    return (
      value &&
      data?.InventorySettingsByType?.find((cate3) => cate3?.id === value)?.name
    );
  };

  return {
    options,
    isLoading,
    renderOption: renderFieldValue,
    allCategories: data || [],
  };
};
