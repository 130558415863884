import { Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
import BillingNoteByCustomerReportTable from "components/Table/Sales/Reports/BillingNoteByCustomerReport";
import HeaderLayout from "components/UI/HeaderLayout";
import { BillingNoteReportType } from "generated/sales";
import { useBillingNoteReport } from "hooks/Sales/BillingNote/use-billing-note-report";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "types/global";

const BillingNoteByCustomerReport = () => {
    const { t } = useTranslation();
  
    const gridRef = useRef<AgGridReact>(null);
  
    const { onBtnExport, isLoading } = useBillingNoteReport(
      gridRef,
      BillingNoteReportType.BillingNoteByCustomer
    );
  
    const breadcrumbs: IBreadcrumbsAndMenu[] = [
      {
        name: t("logistic.index"),
        to: "/logistic"
    },
    {
        name: t("report"),
        to: "/logistic/report"
    },
      {
        name: t("reports.delivery_trip_income_by_customer"),
      },
    ];
  
    return (
      <>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        <HeaderLayout noMaxWidth={true}>
          <Typography variant="h5">
            {t("reports.delivery_trip_income_by_customer")}
          </Typography>
          <CustomizedButton
            title={`${t("button.export")}`}
            variant="contained"
            onClick={onBtnExport}
            disabled={isLoading}
          />
        </HeaderLayout>
        <BillingNoteByCustomerReportTable gridRef={gridRef} />
      </>
    );
  };

export default BillingNoteByCustomerReport;