import { Box, Typography } from "@mui/material";
import { useWorkSummary } from "hooks/Logistic/use-work-summary";
import { useFormContext } from "react-hook-form";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import { numberFormatter } from "utils/Formatter/Global";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CustomizedButton from "components/Custom/CustomizedButton";
import { useDisable } from "hooks/use-disable";

const WorkTotalAmount = () => {
  const { getValues, setValue } = useFormContext<IDeliveryTrip>();
  const { workTotalAmount, disabledButton } = useWorkSummary();

  const updateIncomeListCost = () => {
    const work_total_amount = getValues("work_total_amount");
    const income_list = getValues("income_list");
    const foundIndex = income_list.findIndex(
      (income) =>
        income.item_unique_id === "SP00001" ||
        income.item_unique_id === "SP00002"
    );
    setValue(`income_list.${foundIndex}.price_per_unit`, work_total_amount);
  };

  const [disabled] = useDisable();

  return (
    <Box display={"flex"} justifyContent={"flex-end"}>
      {!disabled && (
        <CustomizedButton
          title="อัปเดตรายการรายรับ"
          startIcon={<RestartAltOutlinedIcon />}
          onClick={updateIncomeListCost}
          disabled={disabledButton}
        />
      )}
      <Box
        sx={{
          my: 1,
          display: "flex",
          alignItems: "center",
          gap: 3,
          height: "2rem",
          flex: 1,
        }}
        bgcolor={"secondary.main"}
        maxWidth={461}
        justifyItems="flex-end"
        borderRadius={1}
      >
        <>
          <Typography
            color={"primary.main"}
            textAlign={"right"}
            flex={2}
            minWidth={50}
          >
            รวม
          </Typography>
          <Typography
            color={"primary.main"}
            variant="subtitle1"
            sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
          >
            {numberFormatter(workTotalAmount)}
          </Typography>
        </>
        <Typography color={"primary.main"} sx={{ ml: 2.5, mr: 4 }}>
          บาท
        </Typography>
      </Box>
    </Box>
  );
};

export default WorkTotalAmount;
