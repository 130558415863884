import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { useBillingNoteByCustomerReportColumnDefs } from "./columnDefs";
import { IServerSideGetRowsParams, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "components/UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { RefObject, useEffect } from "react";
import { BILLING_NOTE_BY_CUSTOMER_REPORT } from "services/Sales/Report/BillingNoteReportViewsAggrid";

interface Props {
    gridRef: RefObject<AgGridReact>;
  }
  
  const BillingNoteByCustomerReportTable = ({ gridRef }: Props) => {
    const graphQLClientWms: GraphQLClient = createGraphQLClientWithMiddleware("sales");
    const columnDefs = useBillingNoteByCustomerReportColumnDefs();
    const datasource = {
      async getRows(params: IServerSideGetRowsParams) {
        const { request } = params;
        const { startRow, endRow, filterModel, sortModel } = request;
        const formatFilter = {
          ...filterModel,
        };
        const formatSort = [
          ...sortModel,
        ];
        try {
          const { BillingNoteByCustomerReportViewsAggrid } = await graphQLClientWms.request(
            BILLING_NOTE_BY_CUSTOMER_REPORT,
            {
              aggridInput: {
                startRow,
                endRow,
                filterModel: formatFilter,
                sortModel: formatSort,
              },
            }
          );
          params.success({
            rowData: BillingNoteByCustomerReportViewsAggrid.results,
            rowCount: BillingNoteByCustomerReportViewsAggrid.count,
          });
        } catch (err) {
          params.fail();
        }
      },
    };
  
    const onGridReady = (params: GridReadyEvent) => {
      params.api.onFilterChanged();
      params.api.setServerSideDatasource(datasource);
    };
  
    useEffect(() => {
      if (gridRef.current && gridRef.current.api) {
      }
    }, [gridRef]);
  
    return (
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        height={670}
        onGridReady={onGridReady}
      />
    );
  };
  
  export default BillingNoteByCustomerReportTable;