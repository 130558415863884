import * as Yup from "yup";
import dayjs from "dayjs";
import { IQuotation } from "types/Sales/quotation";
import { stringMaxInput } from "utils/Validate";
import { v4 as uuidv4 } from "uuid";

export const quotationSchema: IQuotation = {
  unique_id: "",
  created_date: dayjs(),
  created_by: null,
  issue_date: dayjs(),
  due_date: dayjs(),
  plan_start_date: dayjs(),
  plan_end_date: dayjs(),
  credit_day: 0,
  related_user_list: [],
  contact_unique_id: "",
  customer_details: {
    unique_id_name: "",
    customer_name: "",
    phone: "",
    email: "",
    fax: "",
    identity_no: "",
    billing_address: {
      name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  vehicle_type: null,
  item_type: "",
  item_value: "",
  is_frozen: false,
  packing_location: "",
  expected_temperature: "",
  container_type: "",
  pre_discount_amount: 0,
  post_discount_amount: 0,
  vat_exempted_amount: 0,
  vat_0_percent_amount: 0,
  vat_7_percent_amount: 0,
  vat_amount: 0,
  net_amount: 0,
  withholding_tax_amount: 0,
  total_amount: 0,
  remark: "",
  main_status: "",
  sub_status: "",
  flag_status: [],
  aggrid_status: "",
  item_unique_id_list: [],
  item_list: [
    {
      unique_id: uuidv4(),
      item_id: 157,
      item_unique_id: "SP00001",
      item_name: "ค่าขนส่ง",
      item_sku_desc: "ใช้สำหรับคิดค่าขนส่งสินค้าประเภท Chilled",
      qty: 1,
      uom_id: 87,
      uom_name: "เที่ยว",
      uom: {
        id: 87,
        name: "เที่ยว",
      },
      uom_conversion_list: [],
      discount: 0,
      post_discount_amount: 0,
      price_per_unit: 0,
      withholding_tax_type: "ยังไม่ระบุ",
      withholding_tax_value: 0,
      vat_percentage: "ไม่มี",
      item_img_url: [],
    },
  ],
  // accepted_date: dayjs(),
  accepted_remark: "",
  attachment_list: [],
};

const yupNumber = Yup.number().transform((value) =>
  isNaN(value) || value === null || value === undefined ? 0 : value
);

const itemListValidation = Yup.object().shape({
  qty: yupNumber.positive("กรุณาระบุ").required("กรุณาระบุ"),
  price_per_unit: yupNumber.positive("กรุณาระบุ").required("กรุณาระบุ"),
});

export const quotationValidation = Yup.object().shape({
  unique_id: stringMaxInput(15, undefined, true, "กรุณาระบุ"),
  customer_unique_id: Yup.string().required("กรุณาระบุ"),
  vehicle_type: stringMaxInput(200, undefined, true, "กรุณาระบุ"),
  item_type: stringMaxInput(200, undefined, true, "กรุณาระบุ"),
  item_value: stringMaxInput(200, undefined, true, "กรุณาระบุ"),
  packing_location: stringMaxInput(200, undefined, true, "กรุณาระบุ"),
  expected_temperature: stringMaxInput(200, undefined, true, "กรุณาระบุ"),
  plan_start_date: Yup.date(),
  plan_end_date: Yup.date().when("plan_start_date", {
    is: (plan_start_date: any) => plan_start_date != null,
    then: (schema) =>
      schema.min(Yup.ref("plan_start_date"), "กรุณาระบุข้อมูลให้ถูกต้อง"),
  }),
  issue_date: Yup.date().required("กรุณาระบุ"),
  due_date: Yup.date().required("กรุณาระบุ"),
  item_list: Yup.array()
    .of(itemListValidation)
    .min(1, "กรุณาเพิ่มสินค้าอย่างน้อย 1 รายการ"),
  remark: stringMaxInput(2000, undefined, false),
});
