import { useModal } from "hooks/use-modal";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IMaintenance } from "types/Logistic/maintenance";

const useEntityState = () => {
  const [ids, setIds] = useState<string[]>([]);
  const [idsSnapshot, setIdsSnapshot] = useState<string[]>([]);
  return { ids, setIds, idsSnapshot, setIdsSnapshot };
};

const useModalHandlers = () => {
  const { modal, openModalHandler, closeModalHandler } = useModal();
  return { modal, openModalHandler, closeModalHandler };
};

export const useMaintenanceModal = () => {
  const { control } = useFormContext<IMaintenance>();
  const vehicleState = useEntityState();
  const trailerState = useEntityState();

  const vehicleModalHandlers = useModalHandlers();
  const trailerModalHandlers = useModalHandlers();

  const vehicleId = useWatch({
    control,
    name: "vehicle_details.vehicle_no",
  });

  const trailerId = useWatch({
    control,
    name: "trailer_details.vehicle_no",
  });

  useEffect(() => {
    if (vehicleId) {
      vehicleState.setIds([vehicleId]);
      vehicleState.setIdsSnapshot([vehicleId]);
    } else {
      vehicleState.setIds([]);
      vehicleState.setIdsSnapshot([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId]);

  useEffect(() => {
    if (trailerId) {
      trailerState.setIds([trailerId]);
      trailerState.setIdsSnapshot([trailerId]);
    } else {
      trailerState.setIds([]);
      trailerState.setIdsSnapshot([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailerId]);

  return {
    vehicle: {
      ...vehicleState,
      ...vehicleModalHandlers,
    },
    trailer: {
      ...trailerState,
      ...trailerModalHandlers,
    },
  };
};
