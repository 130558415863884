import { Box, Typography } from "@mui/material";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import GanttChart from "components/UI/Logistic/GanttChart/GanttChart";
import GanttChartFilter from "components/UI/Logistic/GanttChart/GanttChartFilter";
import dayjs from "dayjs";
import {
  GanttChartVehicleStatus,
  GanttChartVehicleType,
  useVehicleGanttChartQuery,
  VehicleGanttChartQuery,
} from "generated/vehicle";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IBreadcrumbsAndMenu } from "types/global";

const GanttChartPage = () => {
  const today = dayjs();

  const methods = useForm({
    defaultValues: {
      car_type: GanttChartVehicleType.FourWheel,
      status: GanttChartVehicleStatus.All,
      date: today,
      license_plate: null,
    },
  });

  const { control, getValues } = methods;
  const [filters, setFilters] = useState({
    car_type: GanttChartVehicleType.FourWheel,
    date: today,
    status: GanttChartVehicleStatus.All,
    license_plate: null,
  });
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.gantt_chart.index"),
      to: "/logistic/gantt-chart",
    },
  ];

  const graphqlClient = createGraphQLClientWithMiddleware("sales");

  const { refetch } = useVehicleGanttChartQuery<VehicleGanttChartQuery>(
    graphqlClient,
    {
      ganttChartFilterInput: {
        date: getValues("date"),
        license_plate: getValues("license_plate"),
        status: getValues("status") as any,
        vehicle_type: getValues("car_type") as any,
      },
    },
    {
      enabled: false,
    }
  );

  const fetchFilteredData = useCallback(async () => {
    const { data: car } = await refetch();
    const filteredData = car?.VehicleGanttChart?.map((vehicle) => {
      if (vehicle?.status === "in_active") {
        return {
          ...vehicle,
          stop_date: vehicle.car?.last_updated_date,
          tasks: [],
        };
      }

      const filteredTask = vehicle?.tasks?.map((task) => {
        return {
          type: task?.type,
          info: {
            ...task?.info,
            start_date: new Date(task?.info?.start_date),
            end_date: new Date(task?.info?.end_date),
          },
        };
      });

      return {
        ...vehicle,
        tasks: filteredTask,
      };
    });
    setFilteredData(filteredData || []);
  }, [refetch]);

  useEffect(() => {
    fetchFilteredData();
  }, [fetchFilteredData, filters]);

  const handleFilter = () => {
    const carType = getValues("car_type");
    const selectedDate = getValues("date") || today;
    const selectedStatus = getValues("status") || null;
    const licensePlate = getValues("license_plate");

    setFilters({
      car_type: carType,
      date: selectedDate,
      status: selectedStatus as any,
      license_plate: licensePlate,
    });
  };

  const handleClearFilter = () => {
    methods.reset();
    setFilters({
      car_type: GanttChartVehicleType.FourWheel,
      date: today,
      status: GanttChartVehicleStatus.All,
      license_plate: null,
    });
  };

  const handleRefreshData = () => {
    fetchFilteredData();
  };

  return (
    <FormProvider {...methods}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box>
        <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
          Gantt Chart
        </Typography>
        <GanttChartFilter
          control={control}
          onFilterHandle={handleFilter}
          onClearFilterHandle={handleClearFilter}
          onRefreshData={handleRefreshData}
        />
        <GanttChart data={filteredData} date={filters.date} />
      </Box>
    </FormProvider>
  );
};

export default GanttChartPage;
