import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import dayjs from "dayjs";

import { useModal } from "hooks/use-modal";
import { useDisable } from "hooks/use-disable";
import { useDeliveryTripListHeader } from "hooks/Sales/BillingNote/use-delivery-trip-list-header";

import {
  Box,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import Confirmation from "components/UI/Confirmation";
import ButtonLayout from "components/UI/ButtonLayout";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedButton from "components/Custom/CustomizedButton";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import CheckedDeliveryTripModal from "components/UI/Modal/CheckedModal/CheckedDeliveryTripModal";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

import { IBillingNote } from "types/Sales/billing-note";
import { IDeliveryTripList } from "types/Sales/billing-note";

import { formatDate } from "utils/Date";
import { numberFormatter } from "utils/Formatter/Global";
import { typeValueFormatter } from "utils/Formatter/Logistic/Vehicle";
import { useDeliveryTripListContext } from "contexts/billing-note-context";
import { DELIVERY_TRIP_VIEW } from "services/AgGrid/DeliveryTripAgGrid";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

const DeliveryTripList = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IBillingNote>();
  const [disabled] = useDisable();
  const status = useWatch({ control, name: "aggrid_status" });
  const headers = useDeliveryTripListHeader(disabled, status);
  const graphQLClientWithHeaderDeliveryTrip: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const {
    modal: deliveryTripModal,
    openModalHandler: openDeliveryTripModalHandler,
    closeModalHandler: closeDeliveryTripModalHandler,
  } = useModal();

  const [deliveryTripIds, setDeliveryTripIds] = useState<string[]>([]);
  const [deliveryTripIdsSnapshot, setDeliveryTripIdsSnapshot] = useState<
    string[]
  >([]);

  // const watchCustomerUniqueId = useWatch({
  //   control,
  //   name: "customer_unique_id",
  // });

  const watchUniqueId = useWatch({
    control,
    name: "unique_id",
  });

  const { fields, controlledFields, move, remove, replace } =
    useDeliveryTripListContext();

  useEffect(() => {
    const deliverTripIds = fields.map((field) => field.dt_reference_unique_id);
    setDeliveryTripIds(deliverTripIds);
    setDeliveryTripIdsSnapshot(deliverTripIds);
  }, [fields]);

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = () => {
    if (deletedIndex || deletedIndex === 0) {
      remove(deletedIndex);
      setDeliveryTripIds((prev) =>
        prev.filter((_, index) => index !== deletedIndex)
      );
      setDeliveryTripIdsSnapshot((prev) =>
        prev.filter((_, index) => index !== deletedIndex)
      );
    }
    closeDeleteItemConfirmationHandler();
  };

  const addDeliveryTripsHandler = (selectedDeliveryTrips: any[]) => {
    if (selectedDeliveryTrips?.length) {
      let filteredDeliveryTrips = fields.filter((dt) =>
        deliveryTripIds.includes(dt.dt_reference_unique_id)
      );

      // get current Items id to prevent duplication when appending newly selected Items
      const filteredDeliveryTripIds = filteredDeliveryTrips.map(
        (item) => item.dt_reference_unique_id
      );

      selectedDeliveryTrips.forEach((dt) => {
        if (!filteredDeliveryTripIds.includes(dt.unique_id)) {
          filteredDeliveryTrips.push({
            ...dt,
            dt_reference_unique_id: dt.unique_id,
            unique_id: undefined,
          });
        }
      });

      // sort for proerly render order (and for role assignment when submitting)
      filteredDeliveryTrips = filteredDeliveryTrips.sort((a, b) => {
        return (
          deliveryTripIds.indexOf(a.dt_reference_unique_id) -
          deliveryTripIds.indexOf(b.dt_reference_unique_id)
        );
      });

      const mappedDeliveryTrips = filteredDeliveryTrips.map(
        (deliveryTrip: any) => {
          if (
            filteredDeliveryTripIds.includes(
              deliveryTrip.dt_reference_unique_id
            )
          ) {
            return deliveryTrip as IDeliveryTripList;
          }
          return {
            unique_id: deliveryTrip.unique_id ?? uuidv4(),
            dt_reference_unique_id: deliveryTrip.dt_reference_unique_id,
            dt_income_unique_id: "",
            issue_date: dayjs(deliveryTrip.issue_date),
            dt_income_post_discount_amount:
              deliveryTrip.income_net_amount -
              deliveryTrip.billing_total_amount,
            income_post_discount_amount:
              deliveryTrip.income_net_amount -
              deliveryTrip.billing_total_amount,
            income_vat_exempted_amount: deliveryTrip.income_vat_exempted_amount,
            income_vat_0_percent_amount:
              deliveryTrip.income_vat_0_percent_amount,
            income_vat_7_percent_amount:
              deliveryTrip.income_vat_7_percent_amount,
            income_vat_amount: deliveryTrip.income_vat_amount,
            income_net_amount: deliveryTrip.income_net_amount,
            dt_income_withholding_tax_amount:
              deliveryTrip.income_withholding_tax_amount,
            income_withholding_tax_amount:
              deliveryTrip.income_withholding_tax_amount,
            income_total_amount: deliveryTrip.income_total_amount,
            description: deliveryTrip.description ?? "",
            source_location: deliveryTrip.work_list?.[0]?.location_name ?? "-",
            destination_location:
              deliveryTrip.work_list?.length &&
              deliveryTrip.work_list?.length !== 1
                ? deliveryTrip.work_list[deliveryTrip.work_list.length - 1]
                    ?.location_name
                : "-",
            vehicle_id: deliveryTrip.vehicle_id,
            vehicle_details: {
              type: deliveryTrip.vehicle?.type,
              license_plate: deliveryTrip.vehicle?.license_plate,
              vehicle_no: deliveryTrip.vehicle?.vehicle_no,
            },
            customer_details: {
              id: deliveryTrip.customer_details?.id,
              name: deliveryTrip.customer_details?.name,
              identity_no: deliveryTrip.customer_details?.identity_no,
              email: deliveryTrip.customer_details?.email,
              phone: deliveryTrip.customer_details?.phone,
              fax: deliveryTrip.customer_details?.fax,
            },
          } as IDeliveryTripList;
        }
      );
      replace(mappedDeliveryTrips);
    } else if (selectedDeliveryTrips.length === 0) {
      replace([]);
    }
    closeDeliveryTripModalHandler();
  };

  const openDTfromUniqueId = async (dt_reference_unique_id: string) => {
    try {
      const { DeliveryTripViewAggrid } =
        await graphQLClientWithHeaderDeliveryTrip.request(DELIVERY_TRIP_VIEW, {
          aggridInput: {
            startRow: 0,
            endRow: 1,
            filterModel: {
              unique_id: {
                filter: dt_reference_unique_id,
                filterType: "text",
                type: "equals",
              },
            },
          },
        });

      window.open(
        `/logistic/delivery-trip/${DeliveryTripViewAggrid.results[0].id}`,
        "_blank"
      );
    } catch (error) {}
  };

  return (
    <CustomizedBox
      sx={{ breakAfter: "auto" }}
      margin="1rem 0 0 0"
      maxWidth={1650}
    >
      <Box display="flex" alignItems="center" mb={2}>
        <Typography fontWeight={600}>การจัดส่ง</Typography>
      </Box>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align="center"
                  key={index}
                  sx={{
                    px: 1,
                    py: 1.5,
                    backgroundColor: "secondary.main",
                  }}
                  width={header.width}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      width: header.width,
                      justifyContent: header.align || "center",
                    }}
                  >
                    <Typography fontSize={14} fontWeight={600}>
                      {header.thaiLabel}
                    </Typography>
                    {header.required && (
                      <Typography
                        fontSize={14}
                        color={"error.main"}
                        fontWeight={600}
                      >
                        *
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" isDropDisabled={disabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {controlledFields.map((field, index) => (
                    <Draggable
                      key={field.id}
                      draggableId={"item-" + field.unique_id}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided, snapshot) => (
                        <>
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              ...provided.draggableProps.style,
                              breakInside: "avoid",
                              "& .MuiTableCell-root": {
                                borderBottom: snapshot.isDragging
                                  ? "1px solid #E0E0E0"
                                  : "none",
                              },
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                px: 1,
                                py: 1,
                              }}
                              width={
                                (headers && headers.length > 0
                                  ? headers[0]?.width || 0
                                  : 0) + 16
                              }
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderBottom: "none",
                                }}
                              >
                                {!disabled && (
                                  <MenuIcon
                                    fontSize="small"
                                    sx={{ color: "#BDBDBD" }}
                                  />
                                )}
                                <Typography fontSize={14}>
                                  {index + 1}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              {disabled ? (
                                <Link
                                  component="button"
                                  onClick={async () =>
                                    openDTfromUniqueId(
                                      field.dt_reference_unique_id
                                    )
                                  }
                                  underline="none"
                                  fontSize={14}
                                  // target="_blank"
                                >
                                  {field.dt_reference_unique_id}
                                </Link>
                              ) : (
                                <Box display="flex" alignItems="center" gap={4}>
                                  <Typography fontSize={14}>
                                    {field.dt_reference_unique_id}
                                  </Typography>
                                  <IconButton
                                    onClick={async () =>
                                      openDTfromUniqueId(
                                        field.dt_reference_unique_id
                                      )
                                    }
                                    color="primary"
                                  >
                                    <LaunchOutlinedIcon />
                                  </IconButton>
                                </Box>
                              )}
                            </TableCell>
                            <TableCell>
                              <Typography fontSize={14}>
                                {formatDate(field.issue_date)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontSize={14}>
                                {field.vehicle_details?.license_plate}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontSize={14}>
                                {typeValueFormatter(
                                  field.vehicle_details?.type ?? ""
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <ControlledTextField
                                name={`item_list.${index}.source_location`}
                                control={control}
                                disabled={disabled}
                                viewMode={disabled}
                                error={Boolean(
                                  errors?.item_list?.[index]?.source_location
                                )}
                                helperText={
                                  errors?.item_list?.[index]?.source_location
                                    ?.message
                                }
                              />
                            </TableCell>
                            <TableCell align={disabled ? "left" : "center"}>
                              {field.destination_location === "-" ? (
                                <Typography fontSize={14}>-</Typography>
                              ) : (
                                <ControlledTextField
                                  name={`item_list.${index}.destination_location`}
                                  control={control}
                                  disabled={disabled}
                                  viewMode={disabled}
                                  error={Boolean(
                                    errors?.item_list?.[index]
                                      ?.destination_location
                                  )}
                                  helperText={
                                    errors?.item_list?.[index]
                                      ?.destination_location?.message
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                "& .MuiBox-root": {
                                  mr: disabled ? "1rem" : 0,
                                },
                              }}
                            >
                              <ControlledNumberTextField
                                fullWidth
                                control={control}
                                name={`item_list.${index}.income_post_discount_amount`}
                                error={Boolean(
                                  errors?.item_list?.[index]
                                    ?.income_post_discount_amount
                                )}
                                helperText={
                                  errors?.item_list?.[index]
                                    ?.income_post_discount_amount?.message
                                }
                                viewMode={disabled}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Box>
                                <Typography fontSize={14}>
                                  {numberFormatter(
                                    field.income_net_amount ?? 0
                                  )}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align="right">
                              <Box>
                                <Typography fontSize={14}>
                                  {numberFormatter(
                                    field.dt_income_post_discount_amount -
                                      (field.income_post_discount_amount ?? 0)
                                  )}
                                </Typography>
                              </Box>
                            </TableCell>
                            {!disabled && (
                              <TableCell
                                align="center"
                                sx={{
                                  px: 1,
                                  py: 1,
                                }}
                                width={
                                  (headers && headers.length > 0
                                    ? headers[11]?.width || 0
                                    : 0) + 16
                                }
                              >
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                  onClick={() =>
                                    openDeleteItemConfirmationHandler(index)
                                  }
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow
                            sx={{
                              ...provided.draggableProps.style,
                              height: "3.5rem",
                              breakInside: "avoid",
                              visibility: snapshot.isDragging
                                ? "hidden"
                                : "inherit",
                            }}
                          >
                            <TableCell />
                            <td
                              align="left"
                              colSpan={9}
                              valign="top"
                              style={{
                                borderBottom: "1px solid #E0E0E0",
                              }}
                            >
                              {disabled ? (
                                <Typography
                                  fontSize={14}
                                  sx={{ mt: 1.5, ml: 0.5, color: "#737373" }}
                                >
                                  {field.description || "-"}
                                </Typography>
                              ) : (
                                <ControlledTextField
                                  name={`item_list.${index}.description`}
                                  control={control}
                                  label={
                                    disabled ? undefined : t("sentence.detail")
                                  }
                                  disabled={disabled}
                                  viewMode={disabled}
                                  error={Boolean(
                                    errors?.item_list?.[index]?.description
                                  )}
                                  helperText={
                                    errors?.item_list?.[index]?.description
                                      ?.message
                                  }
                                />
                              )}
                            </td>
                            {!disabled && <TableCell />}
                          </TableRow>
                        </>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {!disabled && (
        <ButtonLayout sx={{ marginTop: 2 }}>
          <CustomizedButton
            title={`${t("button.add")}${t("logistic.delivery_trip.index")}`}
            variant="outlined"
            onClick={openDeliveryTripModalHandler}
            startAddIcon
            // disabled={!watchCustomerUniqueId}
          />
        </ButtonLayout>
      )}
      <Confirmation
        title="ยืนยันการลบใบงานจัดส่ง"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
      <CheckedDeliveryTripModal
        showSelectDeliveryTrip={deliveryTripModal}
        deliveryTripIds={deliveryTripIds}
        setDeliveryTripIds={setDeliveryTripIds}
        deliveryTripIdsSnapshot={deliveryTripIdsSnapshot}
        setDeliveryTripIdsSnapshot={setDeliveryTripIdsSnapshot}
        rowSelection="multiple"
        closeDeliveryTripTable={closeDeliveryTripModalHandler}
        finishDeliveryTripsSelect={addDeliveryTripsHandler}
        // customerUniqueId={watchCustomerUniqueId}
        billingNoteUniqueId={watchUniqueId}
      />
    </CustomizedBox>
  );
};

export default DeliveryTripList;
