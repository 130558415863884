import { gql } from "graphql-request";

export const GENERATE_REPORT = gql`
    query QuotationReport($input: QuotationReportGenerateInput) {
        GenerateQuotationReport(input: $input)
    }
`;

export const BILLING_NOTE_GENERATE_REPORT = gql`
    query BillingNoteReport($input: BillingNoteReportGenerateInput) {
        GenerateBillingNoteReport(input: $input)
    }
`;