import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../../types/global";

const InventorySetting = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.inventory.location.index"),
      to: "/setting/inventory/location",
    },
    {
      name: `${t("setting.inventory.category")} 1`,
      to: "/setting/inventory/category-one",
    },
    {
      name: `${t("setting.inventory.category")} 2`,
      to: "/setting/inventory/category-two",
    },
    {
      name: `${t("setting.inventory.category")} 3`,
      to: "/setting/inventory/category-three",
    },
    {
      name: t("setting.inventory.uom"),
      to: "/setting/inventory/uom",
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default InventorySetting;
