import { IItem } from "types/Inventory/item";
import { Traceability, ItemType } from "generated/wms";
import * as Yup from "yup";

const stringValidator = (maxLength: number, required?: boolean) => {
  if (required) {
    return Yup.string()
      .required("กรุณาระบุ")
      .max(maxLength, `กรอกได้ไม่เกิน ${maxLength} ตัวอักษร`);
  }
  return Yup.string()
    .max(maxLength, `กรอกได้ไม่เกิน ${maxLength} ตัวอักษร`)
    .nullable();
};

export const itemSchema: IItem = {
  unique_id: "",
  seller_sku: "",
  type: ItemType.Normal,
  name: undefined,
  description: undefined,
  img_url_list: [],
  is_purchasable: true,
  is_saleable: true,
  is_stockable: true,
  is_active: 1,
  remark_status: undefined,
  tag_id_list: [],
  tag_list: [],
  created_by: undefined,
  cate_1_id: undefined,
  cate_2_id: undefined,
  cate_3_id: undefined,
  width_uom_id: undefined,
  length_uom_id: undefined,
  thickness_uom_id: undefined,
  height_uom_id: undefined,
  width: undefined,
  length: undefined,
  thick: undefined,
  height: undefined,
  tracability: Traceability.Serial,
  barcode: "",
  stock_uom_id: undefined,
  sales_uom_id: undefined,
  purchase_uom_id: undefined,
  uom_conversion_list: [],
  purchase_price: undefined,
  min_purchase_request_qty: undefined,
  purchase_vat_type: undefined,
  sale_price: undefined,
  special_price: undefined,
  sale_vat_type: undefined,
  min_stock_qty: undefined,
  max_stock_qty: undefined,
  stock_qty: undefined,
  available_qty: undefined,
  purchase_ordered_qty: undefined,
  sale_committed_qty: undefined,
};

export const itemSchemaValidation = Yup.object().shape(
  {
    unique_id: stringValidator(15, true),
    name: stringValidator(200, true),
    seller_sku: stringValidator(200),
    description: stringValidator(200),
    remark_status: Yup.string().when("is_active", {
      is: (value: any) => value === 0 || value === "0",
      then: (schema: any) =>
        schema.required("กรุณาระบุ").max(200, `กรอกได้ไม่เกิน 100 ตัวอักษร`),
      otherwise: (schema) =>
        schema.max(200, `กรอกได้ไม่เกิน 100 ตัวอักษร`).nullable(),
    }),
    cate_1_id: Yup.number().required("กรุณาระบุ"),
    stock_uom_id: Yup.number().required("กรุณาระบุ"),
    tracability: Yup.string(),
    barcode: Yup.string().when("tracability", {
      is: Traceability.Normal,
      then: (schema) => schema.required("กรุณาระบุ"),
    }),
    width: Yup.number().when("width_uom_id", {
      is: (value: any) => value !== undefined && value !== null,
      then: (schema) =>
        schema
          .required("กรุณาระบุ")
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .max(99999999, "กรอกได้ไม่เกิน 8 หลัก"),
      otherwise: (schema) => schema.nullable(),
    }),
    length: Yup.number().when("length_uom_id", {
      is: (value: any) => value !== undefined && value !== null,
      then: (schema) =>
        schema
          .required("กรุณาระบุ")
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .max(99999999, "กรอกได้ไม่เกิน 8 หลัก"),
      otherwise: (schema) => schema.nullable(),
    }),
    thick: Yup.number().when("thickness_uom_id", {
      is: (value: any) => value !== undefined && value !== null,
      then: (schema) =>
        schema
          .required("กรุณาระบุ")
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .max(99999999, "กรอกได้ไม่เกิน 8 หลัก"),
      otherwise: (schema) => schema.nullable(),
    }),
    height: Yup.number().when("height_uom_id", {
      is: (value: any) => value !== undefined && value !== null,
      then: (schema) =>
        schema
          .required("กรุณาระบุ")
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .max(99999999, "กรอกได้ไม่เกิน 8 หลัก"),
      otherwise: (schema) => schema.nullable(),
    }),
    width_uom_id: Yup.number().when("width", {
      is: (value: any) => {
        return value !== undefined && value !== null;
      },
      then: (schema) => schema.required("กรุณาระบุ"),
      otherwise: (schema) => schema.nullable(),
    }),
    length_uom_id: Yup.number().when("length", {
      is: (value: any) => {
        return value !== undefined && value !== null;
      },
      then: (schema) => schema.required("กรุณาระบุ"),
      otherwise: (schema) => schema.nullable(),
    }),
    thickness_uom_id: Yup.number().when("thick", {
      is: (value: any) => {
        return value !== undefined && value !== null;
      },
      then: (schema) => schema.required("กรุณาระบุ"),
      otherwise: (schema) => schema.nullable(),
    }),
    height_uom_id: Yup.number().when("height", {
      is: (value: any) => {
        return value !== undefined && value !== null;
      },
      then: (schema) => schema.required("กรุณาระบุ"),
      otherwise: (schema) => schema.nullable(),
    }),
    uom_conversion_list: Yup.array().of(
      Yup.object().shape({
        base_uom_rate: Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .max(99999999, "กรอกได้ไม่เกิน 8 หลัก"),
        target_uom_rate: Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .max(99999999, "กรอกได้ไม่เกิน 8 หลัก"),
      })
    ),
    purchase_price: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .max(99999999, "กรอกได้ไม่เกิน 8 หลัก"),
    min_purchase_request_qty: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .max(99999999, "กรอกได้ไม่เกิน 8 หลัก"),
    sale_price: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .max(99999999, "กรอกได้ไม่เกิน 8 หลัก"),
    special_price: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .max(99999999, "กรอกได้ไม่เกิน 8 หลัก"),
    min_stock_qty: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .max(99999999, "กรอกได้ไม่เกิน 8 หลัก"),
    max_stock_qty: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .max(99999999, "กรอกได้ไม่เกิน 8 หลัก"),
  },
  [
    ["width", "width_uom_id"],
    ["length", "length_uom_id"],
    ["thick", "thickness_uom_id"],
    ["height", "height_uom_id"],
  ]
);
