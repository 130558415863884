import { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedTextField from "components/Custom/CustomizedTextField";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import ControlledSelect from "components/Controller/ControlledSelect";
import { useTranslation } from "react-i18next";
import { useDisable } from "hooks/use-disable";
import { IHeaderTable } from "types/global";
import CloseIcon from "@mui/icons-material/Close";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useUnitOptions } from "hooks/Inventory/use-inventory-setting-option";

const ItemConversion = () => {
  const { t } = useTranslation();
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  const { renderOption, options } = useUnitOptions();

  const [disabled] = useDisable();
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const watchBaseUom = watch("stock_uom_id");

  const { fields, replace, append } = useFieldArray({
    control,
    name: "uom_conversion_list",
  });

  const handleAppendData = () => {
    append({
      base_uom_rate: 1,
      target_uom_rate: 1,
      target_uom_id: "",
    });
  };

  const handleRemoveAttribute = (index: number) => {
    const uomValues = getValues("uom_conversion_list");
    const newValues =
      uomValues?.filter((uom: any, uomInd: number) => index !== uomInd) || [];
    replace(newValues);
  };

  useEffect(() => {
    if (disabled)
      setHeaders([
        {
          label: t("inventory.item.warehouse.uom.conversion.quantity"),
          width: 150,
        },
        {
          label: t("inventory.item.warehouse.uom.conversion.baseUom"),
          width: 150,
        },
        {
          label: t(
            "inventory.item.warehouse.uom.conversion.quantityConversion"
          ),
          width: 150,
        },
        {
          label: t("inventory.item.warehouse.uom.conversion.conversionUom"),
          width: 150,
        },
      ]);
    else
      setHeaders([
        {
          label: t("inventory.item.warehouse.uom.conversion.quantity"),
          width: 130,
        },
        {
          label: t("inventory.item.warehouse.uom.conversion.baseUom"),
          width: 130,
        },
        {
          label: t(
            "inventory.item.warehouse.uom.conversion.quantityConversion"
          ),
          width: 130,
        },
        {
          label: t("inventory.item.warehouse.uom.conversion.conversionUom"),
          width: 130,
        },
        { label: "", width: 50 },
      ]);
  }, [disabled, t]);

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 100, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              bgcolor: "#ecf6fd",
            }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell align="center" key={index} width={header.width}>
                  <Box minWidth={header.width}>
                    <Typography textAlign="left" fontSize={14} fontWeight={700}>
                      {header.label}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((row, index) => {
              return (
                <TableRow key={row.id}>
                  <TableCell align="left">
                    <Box sx={{ mt: "2px" }}>
                      <ControlledNumberTextField
                        name={`uom_conversion_list[${index}].base_uom_rate`}
                        control={control}
                        viewMode={disabled}
                        error={Boolean(
                          (errors as any)?.uom_conversion_list?.[index]
                            ?.base_uom_rate
                        )}
                        helperText={(errors as any).uom_conversion_list?.[
                          index
                        ]?.base_uom_rate?.message?.toString()}
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={{ mt: "2px" }}>
                      {!disabled && (
                        <CustomizedTextField
                          value={renderOption(watchBaseUom)}
                          disabled
                        />
                      )}
                      {disabled && (
                        <Typography
                          sx={{
                            whiteSpace: "pre-line",
                            wordWrap: "break-word",
                            my: 1,
                          }}
                        >
                          {renderOption(watchBaseUom)}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={{ mt: "2px" }}>
                      <ControlledNumberTextField
                        name={`uom_conversion_list[${index}].target_uom_rate`}
                        control={control}
                        viewMode={disabled}
                        error={Boolean(
                          (errors as any)?.uom_conversion_list?.[index]
                            ?.target_uom_rate
                        )}
                        helperText={(errors as any).uom_conversion_list?.[
                          index
                        ]?.target_uom_rate?.message?.toString()}
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={{ mt: "2px" }}>
                      {!disabled && (
                        <ControlledSelect
                          name={`uom_conversion_list[${index}].target_uom_id`}
                          control={control}
                          error={Boolean(errors.target_uom_id)}
                          helperText={errors.target_uom_id?.message?.toString()}
                          options={options}
                          sx={{
                            textAlign: "left",
                          }}
                          onChange={(e: any) => {
                            setValue(
                              `uom_conversion_list[${index}].target_uom_name`,
                              renderOption(e.target.value)
                            );
                          }}
                        />
                      )}
                      {disabled && (
                        <Typography
                          sx={{
                            whiteSpace: "pre-line",
                            wordWrap: "break-word",
                          }}
                        >
                          {getValues(
                            `uom_conversion_list[${index}].target_uom_name`
                          )}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                  {!disabled && (
                    <TableCell align="center">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                        onClick={() => {
                          handleRemoveAttribute(index);
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!disabled && fields.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <CustomizedButton
            title={t("inventory.item.warehouse.uom.conversion.addConversion")}
            variant="outlined"
            onClick={handleAppendData}
            sx={{ mt: 2 }}
            startAddIcon
          />
        </Box>
      )}
      {disabled && fields.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Typography fontSize={14}>{"ไม่มีข้อมูล"}</Typography>
        </Box>
      )}
      {!disabled && fields.length > 0 && fields.length < 10 && (
        <CustomizedButton
          title={t("inventory.item.warehouse.uom.conversion.addConversion")}
          variant="text"
          onClick={handleAppendData}
          sx={{ my: 1 }}
          startAddIcon
        />
      )}
    </>
  );
};

export default ItemConversion;
