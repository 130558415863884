import { ChangeEventHandler, FC, useMemo } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useSnackbar } from "notistack";
import { useDropzone, FileError, FileRejection } from "react-dropzone";

import { IconButton, Typography, Box } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import CustomizedIconMenuOptions from "../Custom/CustomizedIconMenuOptions";

import { IMenuOption } from "../../types/global";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
} as const;

const activeStyle = {
  borderColor: "#2196f3",
} as const;

const acceptStyle = {
  borderColor: "#00e676",
} as const;

const rejectStyle = {
  borderColor: "#ff1744",
} as const;

const DropzoneField: FC<{
  control: Control<any>;
  name: string;
  setValue: UseFormSetValue<any>;
  multiple?: boolean;
  options?: IMenuOption[];
  acceptedFileType?: string;
  maxSize?: number;
  maxFileSize?: number;
  disabled?: boolean;
  isNotRevoke?: boolean;
  width?: number;
  height?: number;
}> = ({
  control,
  name,
  setValue,
  multiple,
  options,
  acceptedFileType,
  maxSize,
  maxFileSize,
  disabled,
  isNotRevoke,
  width,
  height,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <Dropzone
          control={control}
          name={name}
          setValue={setValue}
          multiple={multiple}
          onChange={(e) =>
            onChange(multiple ? e.target.files : e.target.files?.[0] ?? null)
          }
          options={options}
          acceptedFileType={acceptedFileType}
          maxSize={maxSize}
          disabled={disabled}
          maxFileSize={maxFileSize}
          isNotRevoke={isNotRevoke}
          width={width}
          height={height}
        />
      )}
    />
  );
};

const Dropzone: FC<{
  control: Control;
  name: string;
  setValue: UseFormSetValue<any>;
  multiple?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  options?: IMenuOption[];
  maxSize?: number;
  maxFileSize?: number;
  acceptedFileType?: string;
  isNotRevoke?: boolean;
  height?: number;
  width?: number;
}> = ({
  control,
  name,
  setValue,
  multiple,
  onChange,
  disabled,
  options,
  acceptedFileType,
  maxSize,
  maxFileSize,
  isNotRevoke,
  height,
  width,
}) => {
  const getAcceptFileType = (
    fileType: string | undefined
  ): {
    "image/jpg"?: any[];
    "image/jpeg"?: any[];
    "image/png"?: any[];
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"?: any[];
    "application/pdf"?: any[];
  } => {
    switch (fileType) {
      case "image":
        return {
          "image/jpg": [],
          "image/jpeg": [],
          "image/png": [],
        };
      // case "xlsx":
      //   return {
      //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      //       [],
      //   };
      default:
        return {
          "image/jpg": [],
          "image/jpeg": [],
          "image/png": [],
          "application/pdf": [],
        };
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: getAcceptFileType(acceptedFileType),
    maxSize: maxFileSize ? maxFileSize : 10485760,
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      fileRejections.forEach((file: any) => {
        file.errors.forEach((err: FileError) => {
          if (err.code === "file-invalid-type") {
            enqueueSnackbar("ประเภทไฟล์ไม่ถูกต้อง", {
              variant: "error",
            });
          }
          if (err.code === "file-too-large") {
            enqueueSnackbar(
              "ไม่สามารถอัปโหลดได้\nเนื่องจากไฟล์มีขนาดใหญ่เกิน 10MB",
              {
                variant: "error",
                style: { whiteSpace: "pre-line" },
              }
            );
          }
        });
      });

      const newFiles: File[] =
        (!!files?.length && [...files].concat(acceptedFiles)) || acceptedFiles;

      const url = newFiles?.map((file: File) => {
        if (typeof file === "object")
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        else return file;
      });
      setValue(name, url, {
        shouldDirty: true,
      });
    },
    disabled,
    multiple,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  const files = useWatch({
    control,
    name,
  });

  const removeFile = (file: any) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setValue(name, newFiles, {
      shouldDirty: true,
    });
  };

  const moveToFirst = (arr: string[] & File[], index: number) => {
    // Check if index is within the bounds of the array
    if (index < 0 || index >= arr.length) {
      console.log("Invalid index!");
      return arr;
    }

    // Remove element at index and store it in a variable
    const element = arr.splice(index, 1)[0];

    // Insert the element at the beginning of the array
    arr.unshift(element);

    return arr;
  };

  if (!multiple && files && files.length > 0) {
    return (
      <Box
        sx={{
          height: "fit-content",
          width: "fit-content",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderWidth: 2,
          borderRadius: 2,
          borderColor: "#eeeeee",
          borderStyle: "dashed",
        }}
      >
        <Box
          className="image-container"
          width={width || 200}
          height={height || 200}
        >
          <img
            alt="img"
            src={files[0].preview || files[0]}
            className={disabled ? "image-no-hover" : "image"}
            // Revoke data uri after image is loaded
            onLoad={() => {
              if (!isNotRevoke) {
                URL.revokeObjectURL(files[0].preview || files[0]);
              }
            }}
          />
          {!disabled && (
            <>
              <div className="delete">
                <IconButton aria-label="cancel" onClick={removeFile(files[0])}>
                  <DeleteOutlineOutlinedIcon sx={{ color: "white" }} />
                </IconButton>
              </div>
              <div className="overlay"></div>
            </>
          )}
        </Box>
      </Box>
    );
  }

  const renderGallary = () =>
    multiple && (
      <Box display={"flex"} flexWrap="wrap" gap={2}>
        {files?.map((file: any, index: number) => {
          return (
            <Box
              justifyContent="center"
              width={width || 180}
              height={height || 180}
              key={typeof file === "string" ? file : file.preview}
            >
              <div
                className="image-container"
                style={{
                  border: "2px solid #e5e5e5",
                }}
              >
                <img
                  alt="company"
                  src={file.preview || file}
                  className="image-no-hover"
                  // Revoke data uri after image is loaded
                  onLoad={() => {
                    if (!isNotRevoke) {
                      URL.revokeObjectURL(file.preview);
                    }
                  }}
                />
                {!disabled && (
                  <>
                    <div className="delete-multiple">
                      <CustomizedIconMenuOptions
                        icon={<MoreVertIcon />}
                        options={options ?? []}
                        onSelect={(e) => {
                          const value = e.target as HTMLElement;
                          switch (value.innerText) {
                            case "ลบ":
                              const newFiles = [...files];
                              newFiles.splice(newFiles.indexOf(file), 1);
                              setValue(name, newFiles, {
                                shouldDirty: true,
                              });
                              break;
                            case "เลือกเป็นภาพหลัก":
                              const compareFiles = moveToFirst(files, index);
                              setValue(name, compareFiles, {
                                shouldDirty: true,
                              });
                              break;
                            default:
                              break;
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </Box>
          );
        })}
        {files.length !== maxSize && !disabled && (
          <Box
            display="flex"
            justifyContent="center"
            width={width || 200}
            height={height || 200}
          >
            <div {...getRootProps({ style })}>
              <input {...getInputProps({ onChange })} />
              <ImageOutlinedIcon color={"primary"} />
              <Typography color="primary" fontSize={12}>
                อัปโหลดรูป
              </Typography>
              <Typography color="primary" fontSize={12}>
                .jpg .jpeg .png
              </Typography>
              <Typography color="primary" fontSize={12}>
                {files.length}/{maxSize}
              </Typography>
            </div>
          </Box>
        )}
      </Box>
    );

  return (
    <>
      {renderGallary()}
      {!multiple && (
        <Box
          display="flex"
          justifyContent="center"
          width={width || 200}
          height={height || 200}
        >
          <div {...getRootProps({ style })}>
            <input {...getInputProps({ onChange })} />
            <Box mb={0.25}>
              <ImageOutlinedIcon color={"primary"} />
            </Box>
            {!disabled && (
              <>
                <Typography color="primary" fontSize={12}>
                  อัปโหลดรูป
                </Typography>
                <Typography color="primary" fontSize={12}>
                  .jpg .jpeg .png
                </Typography>
              </>
            )}
            {maxSize && maxSize > 1 && (
              <Typography color="primary">
                {files.length}/{maxSize}
              </Typography>
            )}
          </div>
        </Box>
      )}
    </>
  );
};

export default DropzoneField;
