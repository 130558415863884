import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { IBreadcrumbsAndMenu, ITab } from "types/global";

import HeaderLayout from "components/UI/HeaderLayout";
import TireTable from "components/Table/Logistic/Tire";
import CustomizedTab from "components/Custom/CustomizedTab";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuOptionsMeatball from "components/Custom/CustomizedMenuOptionsMeatball";

const Tire = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get("status");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.tire.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("logistic.tire.stock"),
      path: `${pathname}?status=stock`,
    },
    {
      label: t("status.active"),
      path: `${pathname}?status=active`,
    },
    {
      label: t("status.in_active"),
      path: `${pathname}?status=in_active`,
    },
  ];

  const currentTab = pathname + (filter ? `?status=${filter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5">{t("logistic.tire.index")}</Typography>
        <Box display="flex">
          <Box ml="auto">
            <CustomizedButton
              title={`${t("button.create")}${t("logistic.tire.index")}`}
              variant="contained"
              onClick={() => navigate(`${pathname}/add`)}
              startAddIcon
            />
            <CustomizedMenuOptionsMeatball
              isIcon
              options={[]}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case `${t("purchase.export")}`:
                    // openExportModalHandler();
                    break;
                  default:
                }
              }}
              sx={{
                width: "46px",
                minWidth: "46px",
              }}
              disableRipple={true}
            />
          </Box>
        </Box>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <TireTable status={filter} />
    </>
  );
};

export default Tire;
