import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";

const PurchaseReport = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("report"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.report.purchase_by_due_date"),
      to: "purchase-by-due-date",
    },
    {
      name: t("purchase.report.purchase_by_product"),
      to: "purchase-by-product",
    },
    {
      name: t("purchase.report.purchase_by_supplier"),
      to: "purchase-by-supplier",
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default PurchaseReport;
