import { Typography } from "@mui/material";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import DocumentDropzoneUI from "components/UI/DocumentDropzoneUI";
import { useDisable } from "hooks/use-disable";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const DailyWorksheet = () => {
  const { control, setValue, watch } = useFormContext();
  const [disabled] = useDisable();
  const { t } = useTranslation();

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;

  const isFinished = status === "finished";

  return (
    <CustomizedBox margin={0}>
      <Typography fontWeight={600}>
        {t("logistic.delivery_trip.attachment_list")}
      </Typography>
      <DocumentDropzoneUI
        control={control}
        name="attachment_list"
        setValue={setValue}
        disabled={disabled || isFinished}
        maxSize={5}
        multiple
      />
    </CustomizedBox>
  );
};

export default DailyWorksheet;
