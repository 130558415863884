import { useDisable } from "../../../../hooks/use-disable";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { Box, Typography } from "@mui/material";
import ItemImage from "components/Form/Inventory/Item/ItemImage";

const MaintenanceAttachment = () => {
  const { control, watch, setValue } = useFormContext();
  const [disabled] = useDisable();
  const { t } = useTranslation();

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const cancelled = flagStatus.includes("cancelled");

  const status = !cancelled ? mainStatus : aggrid_status;

  const disabledStatus = ["finished"].includes(status);

  return (
    <CustomizedBox>
      <Box display={"flex"} alignItems={"center"} mb={1} gap={1}>
        <Typography fontWeight={600}>
          {t("logistic.maintenance.attachment_list")}
        </Typography>
      </Box>
      <ItemImage
        control={control}
        name="attachment_list"
        acceptedFileType="strict-image"
        setValue={setValue}
        disabled={disabled || disabledStatus}
        maxSize={5}
        multiple
        disableMainIndex
      />
    </CustomizedBox>
  );
};

export default MaintenanceAttachment;
