import {
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "components/UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

import { VEHICLE_VIEW } from "services/AgGrid/VehicleAgGrid";
import { dateFilterModel } from "utils/AgGridFilter";
import { useVehicleColumnDefs } from "./columnDefs";

type Props = {
  type: string | null;
};

const VehicleTable = ({ type }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const columnDefs = useVehicleColumnDefs(false);

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        created_date,
        driver_object_list,
        created_by_object,
        act_end_date,
        insurance_vehicle_start_date,
        insurance_vehicle_end_date,
        insurance_product_start_date,
        insurance_product_end_date,
        ...otherFilter
      } = filterModel;

      const formatFilter = {
        ...otherFilter,
        driver: driver_object_list
          ? {
              filterType: "array",
              type: "hasSome",
              values: driver_object_list.values,
            }
          : undefined,
        created_by: created_by_object,
        created_date: dateFilterModel(created_date),
        act_end_date: dateFilterModel(act_end_date),
        insurance_vehicle_start_date: dateFilterModel(
          insurance_vehicle_start_date
        ),
        insurance_vehicle_end_date: dateFilterModel(insurance_vehicle_end_date),
        insurance_product_start_date: dateFilterModel(
          insurance_product_start_date
        ),
        insurance_product_end_date: dateFilterModel(insurance_product_end_date),
      };

      const formatSort = [
        ...sortModel,
        { colId: "created_date", sort: "desc" },
      ];

      try {
        const { VehicleFindViewAggrid } = await graphQLClient.request(
          VEHICLE_VIEW,
          {
            aggridInput: {
              startRow: startRow,
              endRow: endRow,
              filterModel: formatFilter,
              sortModel: formatSort,
            },
          }
        );

        params.success({
          rowData: VehicleFindViewAggrid.results as any[],
          rowCount: VehicleFindViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      params.api.setFilterModel(null);
      params.api.setFilterModel({
        status: {
          type: "set",
          values: ["active"],
        },
      });
      const typeInstance = params.api.getFilterInstance("type");
      switch (type) {
        case "head":
          typeInstance?.setModel({
            values: ["four_wheel", "six_wheel", "ten_wheel", "head"],
          });
          break;
        case "trailer":
          typeInstance?.setModel({ values: [type] });
          break;
        case "approaching":
          params.api.getFilterInstance("has_approaching").setModel({
            type: "equals",
            filter: 1,
          });
          break;
        case "overdue":
          params.api.getFilterInstance("has_overdue").setModel({
            type: "equals",
            filter: 1,
          });
          break;
        default:
          typeInstance?.setModel({
            values: ["four_wheel", "six_wheel", "ten_wheel", "head", "trailer"],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [type]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/logistic/vehicle/${id}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onRowDoubleClicked={rowSelectHandler}
      onGridReady={onGridReady}
      path={`/logistic/vehicle`}
    />
  );
};

export default VehicleTable;
