import { useCallback, useState } from "react";
import { useStateContext } from "../contexts/auth-context";
import {
  ActivityLogCreateInput,
  useActivityLogCreateMutation,
} from "../generated/general";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";

type ActivityLogCreateInputWithoutCreatedBy = Pick<
  ActivityLogCreateInput,
  Exclude<keyof ActivityLogCreateInput, "created_by">
>;

export const useActivityLog = () => {
  const {
    state: { authUser },
  } = useStateContext();

  const [openActivityLog, setOpenActivityLog] = useState<boolean>(false);

  const onActivityLogOpen = () => {
    setOpenActivityLog(true);
  };

  const onActivityLogClose = () => {
    setOpenActivityLog(false);
  };

  const graphqlClientGeneral = createGraphQLClientWithMiddleware("general");

  const { isLoading, mutateAsync } =
    useActivityLogCreateMutation<Error>(graphqlClientGeneral);

  const createActivityLog = useCallback(
    async (input: ActivityLogCreateInputWithoutCreatedBy) => {
      try {
        const { ActivityLogCreate } = await mutateAsync({
          data: {
            ...input,
            created_by: {
              user_id: authUser!.id,
              user_unique_id: authUser!.unique_id,
              first_name: authUser!.first_name,
              last_name: authUser!.last_name,
              email: authUser!.email,
              img_url: authUser!.img_url,
            },
          },
        });
        return ActivityLogCreate;
      } catch (error) {
        console.log(error);
      }
    },
    [authUser, mutateAsync]
  );

  return {
    isLoading,
    createActivityLog,
    openActivityLog,
    onActivityLogOpen,
    onActivityLogClose,
  };
};
