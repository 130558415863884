import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMaintenanceItemListPdfHeader } from "./columnDef";
import { ISalesItemList } from "types/Sales";
import { numberFormatter } from "utils/Formatter/Global";
import { formatNumber } from "utils/Global";

type Props = {
  itemList: ISalesItemList[];
  startNumber: number;
  lastTable: boolean;
  totalAmount: number;
};

const MaintenanceItemListPdfTable = ({
  itemList,
  startNumber,
  lastTable,
  totalAmount,
}: Props) => {
  const headers = useMaintenanceItemListPdfHeader();

  const filteredItemList = itemList.filter(
    (item: any) => item.pdf_table_type === "second"
  );

  const hasFirstInstance = itemList.some((item) => item.first_instance);

  const tablePadding = {
    px: 1,
    py: 1,
    borderBottom: "none",
  };

  const fontSize = 10.66;

  const sxRow = (index: number) => {
    return lastTable && index === filteredItemList.length - 1
      ? { borderBottom: "none" }
      : {};
  };

  if (
    itemList.length === 0 ||
    (itemList.length > 0 && filteredItemList.length === 0)
  ) {
    return <></>;
  }

  return (
    <>
      {hasFirstInstance && (
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: fontSize,
          }}
        >
          {"รายการค่าใช้จ่ายและอะไหล่"}
        </Typography>
      )}
      <Box>
        <TableContainer
          sx={{
            border: `1px solid #d6cfcf`,
            borderBottom: "none",
            borderRadius: "5.33px",
            my: 1,
          }}
        >
          <Table aria-label="simple table">
            <TableHead sx={{ bgcolor: "#ECF6FD" }}>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align || "center"}
                    key={index}
                    sx={tablePadding}
                    width={header.width}
                  >
                    <Typography fontSize={fontSize} fontWeight={600}>
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItemList.map((item, index) => (
                <TableRow
                  key={item.unique_id}
                  sx={{ verticalAlign: "top", height: "48.78px" }}
                >
                  <TableCell align="center" sx={sxRow(index)}>
                    <Typography fontSize={fontSize}>
                      {startNumber + index}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={sxRow(index)}>
                    <Typography fontSize={fontSize}>
                      {item.item_unique_id}
                    </Typography>
                    <Typography
                      fontSize={fontSize}
                      color={"primary.main"}
                      fontWeight={600}
                    >
                      {item.item_name}
                    </Typography>
                    <Typography
                      fontSize={fontSize}
                      sx={{
                        color: "#737373",
                        wordBreak: "break-word",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {item.item_sku_desc}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={sxRow(index)}>
                    <Typography
                      fontSize={fontSize}
                      sx={{
                        wordBreak: "break-word",
                      }}
                    >
                      {numberFormatter(item.qty)}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={sxRow(index)}>
                    <Typography fontSize={fontSize}>{item.uom_name}</Typography>
                  </TableCell>
                  <TableCell align="right" sx={sxRow(index)}>
                    <Typography
                      fontSize={fontSize}
                      sx={{
                        wordBreak: "break-word",
                      }}
                    >
                      {item.price_per_unit
                        ? formatNumber(item.price_per_unit)
                        : ""}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={sxRow(index)}>
                    <Typography
                      fontSize={fontSize}
                      sx={{
                        wordBreak: "break-word",
                      }}
                    >
                      {item.discount ? formatNumber(item.discount) : "0.00"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={sxRow(index)}>
                    <Typography
                      fontSize={fontSize}
                      sx={{
                        wordBreak: "break-word",
                      }}
                    >
                      {item.vat_percentage}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={sxRow(index)}>
                    <Typography
                      fontSize={fontSize}
                      sx={{
                        wordBreak: "break-word",
                      }}
                    >
                      {item.post_discount_amount
                        ? formatNumber(item.post_discount_amount)
                        : ""}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={sxRow(index)}>
                    <Typography
                      fontSize={fontSize}
                      sx={{
                        wordBreak: "break-word",
                      }}
                    >
                      {item.withholding_tax_type
                        ? item.withholding_tax_type === "ยังไม่ระบุ" ||
                          item.withholding_tax_type === "ไม่มี"
                          ? item.withholding_tax_type
                          : formatNumber(
                              parseFloat(item.withholding_tax_type)
                            ) + " %"
                        : "ยังไม่ระบุ"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={sxRow(index)}>
                    <Typography fontSize={fontSize}>{item.remark}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {lastTable && (
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#ECF6FD",
                  }}
                >
                  <TableCell align="right" sx={{ pl: 50, pr: 5, py: 0.5 }}>
                    <Typography
                      fontSize={fontSize}
                      color={"#2167D3"}
                      fontWeight={600}
                    >
                      รวม
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ py: 0.5, pr: 0 }}>
                    <Typography
                      fontWeight={600}
                      fontSize={fontSize}
                      color={"#2167D3"}
                    >
                      {numberFormatter(totalAmount)}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ py: 0.5 }}>
                    <Typography
                      fontWeight={600}
                      fontSize={fontSize}
                      color={"#2167D3"}
                    >
                      บาท
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          )}
        </TableContainer>
      </Box>
    </>
  );
};

export default MaintenanceItemListPdfTable;
