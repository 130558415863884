import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// import { IApprovalTemplate } from "../../../types/global";
import { IPurchaseOrder } from "../../../types/Purchase/purchaseOrder";
import {
  PurchaseOrderInput,
  PurchaseOrderFindUniqueQuery,
  PurchaseOrderUpdateInput,
  usePurchaseOrderCreateMutation,
  usePurchaseOrderFindUniqueQuery,
  usePurchaseOrderUpdateMutation,
  ActivityType,
  ActivityLogDocumentType,
} from "../../../generated/purchase";

import { useActivityLog } from "hooks/use-activity-log";
import { useDisable } from "../../../hooks/use-disable";

import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

import { Stack } from "@mui/material";
import LoadingUI from "../../../components/UI/LoadingUI";
import PurchaseFooter from "../../../components/Form/Purchase/Footer";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import SupplierInfo from "../../../components/Form/Purchase/SupplierInfo";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
// import RequesterInfo from "../../../components/Form/Purchase/RequesterInfo";
import PurchaseOrderHeader from "../../../components/Form/Purchase/Order/Header";
import AddressInfo from "../../../components/Form/Purchase/SupplierInfo/AddressInfo";
import PurchaseOrderItemList from "../../../components/Table/Purchase/ItemList/PurchaseItemList";
// import PurchaseRequestItemList from "../../../components/Table/Purchase/ItemList/PurchaseIRequesttemList";

import {
  purchaseOrderQueryFormatter,
  purchaseOrderCreatePayloadFormatter,
  purchaseOrderUpdatePayloadFormatter,
} from "../../../utils/Formatter/Purchase/PurchaseOrder";
import { errorMessageFormatter } from "../../../utils/Global";
import { IExternalProps } from "types/Sales";
import { usePurchaseError } from "hooks/Purchase/use-purchase-error";
// import { errorMessageFormatter } from "../../../utils/Global";

const DocumentInfoTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const documentType = "purchase_order";
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useDisable();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  const { createActivityLog } = useActivityLog();

  const graphQLClient = createGraphQLClientWithMiddleware("purchase");

  const {
    reset,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IPurchaseOrder>();

  const status = watch("aggrid_status");

  const referenceNo = watch("reference_no");

  const { data, isLoading, isSuccess, refetch } =
    usePurchaseOrderFindUniqueQuery<PurchaseOrderFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 10,
      }
    );

  useEffect(() => {
    setIsLoadingData(true);
    if (isSuccess) {
      const { PurchaseOrderFindUnique } = data;

      const getPurchaseRequestData = async () => {
        const formattedPurchaseRequest = purchaseOrderQueryFormatter(
          PurchaseOrderFindUnique
        );
        reset(formattedPurchaseRequest);
        setIsLoadingData(false);
      };
      getPurchaseRequestData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (id && status !== "draft") {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled, status]);

  useEffect(() => {
    if (state) {
      const { copied_unique_id, copied_id, ...otherState } = state;
      reset({
        ...otherState,
        created_date: dayjs(),
        due_date: dayjs(),
        issue_date: dayjs(),
        within_date: dayjs(),
        expected_date: dayjs(),
      });
    }
  }, [reset, state]);

  const { mutateAsync: create, isLoading: isCreating } =
    usePurchaseOrderCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    usePurchaseOrderUpdateMutation<Error>(graphQLClient);

  const draftHandler = async (data: IPurchaseOrder) => {
    if (!id) {
      try {
        const formatData = purchaseOrderCreatePayloadFormatter(data, "draft");
        const { PurchaseOrderCreate } = await create({
          data: formatData as PurchaseOrderInput,
        });
        enqueueSnackbar(t("snack_bar.purchase_order.create.success"), {
          variant: "success",
        });
        navigate(`/purchase/order/${PurchaseOrderCreate?.id}`);

        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.PurchaseOrder,
            reference_id: PurchaseOrderCreate?.id || 0,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: PurchaseOrderCreate?.id,
                unique_id: PurchaseOrderCreate?.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.PurchaseOrder,
          reference_id: PurchaseOrderCreate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(
          formatError || t("snack_bar.purchase_order.create.fail"),
          {
            variant: "error",
          }
        );
      }
    } else {
      try {
        const formatData = await purchaseOrderUpdatePayloadFormatter(
          data,
          "draft"
        );
        const { PurchaseOrderUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          data: formatData as PurchaseOrderUpdateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });

        await refetch();

        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.PurchaseOrder,
          reference_id: PurchaseOrderUpdate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Edit,
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const waitOrderedHandler = async (data: IPurchaseOrder) => {
    if (!id) {
      try {
        const formatData = purchaseOrderCreatePayloadFormatter(
          data,
          "wait_ordered"
        );

        const { PurchaseOrderCreate } = await create({
          data: formatData as PurchaseOrderInput,
        });
        enqueueSnackbar(t("snack_bar.purchase_order.create.success"), {
          variant: "success",
        });
        navigate(`/purchase/order/${PurchaseOrderCreate?.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.PurchaseOrder,
            reference_id: PurchaseOrderCreate?.id || 0,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: PurchaseOrderCreate?.id,
                unique_id: PurchaseOrderCreate?.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.PurchaseOrder,
          reference_id: PurchaseOrderCreate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_ordered",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(
          formatError || t("snack_bar.purchase_order.create.fail"),
          {
            variant: "error",
          }
        );
      }
    } else {
      try {
        let changingStatus = "wait_ordered";
        const formatData = await purchaseOrderUpdatePayloadFormatter(
          data,
          changingStatus
        );
        const { PurchaseOrderUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          data: formatData as PurchaseOrderUpdateInput,
        });
        enqueueSnackbar(t("snack_bar.purchase_order.create.success"), {
          variant: "success",
        });

        await refetch();

        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.PurchaseOrder,
          reference_id: PurchaseOrderUpdate?.id || 0,
          activity_detail: {
            prev_status: data.aggrid_status,
            curr_status: "wait_ordered",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");

        enqueueSnackbar(
          formatError || t("snack_bar.purchase_order.create.fail"),
          {
            variant: "error",
          }
        );
      }
    }
  };

  const editHandler = async (data: IPurchaseOrder, status?: string) => {
    try {
      const formatData = await purchaseOrderUpdatePayloadFormatter(
        data,
        status || data?.main_status || ""
      );
      const { PurchaseOrderUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        data: formatData as PurchaseOrderUpdateInput,
      });

      enqueueSnackbar(
        status === "fully_imported"
          ? t("snack_bar.purchase_order.cancelPOQty.success")
          : `${t("sentence.edit")}สำเร็จ`,
        {
          variant: "success",
        }
      );

      setDisabled(true);

      await refetch();

      if (status) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.PurchaseOrder,
          reference_id: PurchaseOrderUpdate?.id || 0,
          activity_detail: {
            prev_status: data.aggrid_status,
            curr_status: status,
          },
        });
      } else {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.PurchaseOrder,
          reference_id: PurchaseOrderUpdate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Edit,
          },
        });
      }
    } catch (err) {
      const formatError = errorMessageFormatter(err, "document");

      enqueueSnackbar(
        formatError || status === "fully_imported"
          ? t("snack_bar.purchase_order.cancelPOQty.fail")
          : `${t("sentence.edit")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const externalReferenceHandler = async (data: IExternalProps) => {
    try {
      const formData = getValues();

      const { PurchaseOrderUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        data: {
          unique_id: formData.unique_id,
          reference_no: data.external_reference_no,
          destination_warehouse_id: formData.destination_warehouse_id,
        } as PurchaseOrderUpdateInput,
      });

      enqueueSnackbar(`อ้างอิงเอกสารภายนอกสำเร็จ`, {
        variant: "success",
      });

      await refetch();

      await createActivityLog({
        activity_type: ActivityType.Edit,
        document_type: ActivityLogDocumentType.PurchaseOrder,
        reference_id: PurchaseOrderUpdate?.id || 0,
        activity_detail: {
          secondary_operation: ActivityType.Edit,
        },
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("sentence.edit")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      const data = getValues();
      const flag_status =
        data?.flag_status && data.flag_status.length > 0
          ? data.flag_status.includes("cancelled")
            ? [...data.flag_status]
            : [...data.flag_status, "cancelled"]
          : ["cancelled"];

      const { PurchaseOrderUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        data: {
          destination_warehouse_id: data.destination_warehouse_id,
          flag_status: flag_status,
        } as PurchaseOrderUpdateInput,
      });

      enqueueSnackbar(t("snack_bar.purchase_order.cancel.success"), {
        variant: "success",
      });

      await refetch();

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.PurchaseOrder,
        reference_id: PurchaseOrderUpdate?.id || 0,
        activity_detail: {
          secondary_operation: ActivityType.Edit,
          prev_status: data.aggrid_status,
          curr_status: "cancelled",
        },
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("snack_bar.purchase_order.cancel.fail"), {
        variant: "error",
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    reset();
  };

  const renderButton = (status: string | undefined) => {
    switch (status) {
      case "draft":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              title={t("purchase.order.name")}
              variant="contained"
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(waitOrderedHandler)}
            />
          </>
        );
      case "wait_ordered":
        if (!disabled)
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isUpdating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={() => handleSubmit((data) => editHandler(data))()}
                disabled={isUpdating}
              />
            </>
          );
        if (disabled)
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={() => navigate(`/purchase/order`)}
              />
              <CustomizedButton
                title={t("button.confirm")}
                variant="contained"
                onClick={() =>
                  handleSubmit((data) => editHandler(data, "fully_ordered"))()
                }
                disabled={
                  isUpdating ||
                  Boolean(
                    !referenceNo || (referenceNo && referenceNo.length === 0)
                  )
                }
              />
            </>
          );
        return;
      case "fully_ordered":
        if (!disabled)
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isUpdating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={() => handleSubmit((data) => editHandler(data))()}
                disabled={isUpdating}
              />
            </>
          );
        break;
      case "partially_imported":
      case "fully_imported":
      case "cancelled":
        break;
      default:
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              title={t("purchase.order.name")}
              variant="contained"
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(waitOrderedHandler)}
            />
          </>
        );
    }
  };

  usePurchaseError(errors);

  if (id && (isLoadingData || isLoading || isCreating || isUpdating)) {
    return <LoadingUI />;
  }

  return (
    <>
      <PurchaseOrderHeader
        editClickHandler={editClickHandler}
        editHandler={editHandler}
        cancelHandler={cancelHandler}
        externalReferenceHandler={externalReferenceHandler}
      />
      <SupplierInfo />
      <AddressInfo />
      <PurchaseOrderItemList />
      <PurchaseFooter documentType={documentType} />
      <BottomNavbar>
        <Stack direction="row" spacing={1} alignItems="center">
          {renderButton(status)}
        </Stack>
      </BottomNavbar>
    </>
  );
};

export default DocumentInfoTab;
