import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { IBreadcrumbsAndMenu, IExporter, IMenuOption, ISelectOption, ITab } from "../../types/global";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import { Box, Typography } from "@mui/material";
import CustomizedButton from "../../components/Custom/CustomizedButton";
import CustomizedTab from "../../components/Custom/CustomizedTab";
import ContactTable from "../../components/Table/Contact";
import CustomizedMenuOptionsMeatball from "components/Custom/CustomizedMenuOptionsMeatball";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { useGenerateContactReportQuery, GenerateContactReportQuery, ContactReportType, ReportContactGenerateInput } from "generated/crm";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { useModal } from "hooks/use-modal";
import ExporterContactModal from "components/UI/Modal/ExporterContactModal";
import LoadingAnimation from "components/Animation/Loading";

type Props = {
  type: "all" | "customer" | "supplier";
};

const Contact = ({ type }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();


  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: t(`contact.contact_type.${type}`),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `/contact/all`,
    },
    {
      label: t("contact.contact_type.customer"),
      path: `/contact/customer`,
    },
    {
      label: t("contact.contact_type.supplier"),
      path: `/contact/supplier`,
    },
  ];

  const currentTab = pathname;
  const options: IMenuOption[] = [
    {
      value: "นำเข้าผู้ติดต่อ"
    },
    {
      value: "นำออกผู้ติดต่อ"
    }
  ]
  const typeOptions: ISelectOption[] = [
    {
      label: "ผู้ติดต่อ",
      value: "contact",
    },
    {
      label: "ลูกค้า",
      value: "customer",
    },
    {
      label: "ซัพพลายเออร์",
      value: "supplier",
    },
  ]

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const graphqlClient = createGraphQLClientWithMiddleware("crm");

  const [exportInput, setExportInput] = useState<ReportContactGenerateInput | null>(
    null
  );

  const { refetch: refetchData, isLoading: isExporting } =
    useGenerateContactReportQuery<GenerateContactReportQuery>(
      graphqlClient,
      {
        input: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: IExporter) => {
    if (data) {
      const { type, start_date, end_date } = data;
      setExportInput({
        report_type: type as ContactReportType,
        start_date: start_date,
        end_date: end_date,
      });
    }
  };

  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        if (exportInput) {
          const { data: exportData } = await refetchData();
          if (exportData?.GenerateReportContactNoQueue) {
            const url = exportData.GenerateReportContactNoQueue;
            window.open(url, '_blank');
            enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
              variant: "success",
            });
          } else {
            throw new Error("No data received");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [enqueueSnackbar, exportInput, refetchData, t]);

  if (exportInput && isExporting) {
    return <LoadingAnimation />;
  }
  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="h5">{t("contact.index")}</Typography>
        <Box display="flex" alignItems="center">
          <Box ml="auto">
            <CustomizedButton
              startAddIcon
              title={`${t("button.create")}${t("contact.index")}`}
              variant="contained"
              onClick={() => navigate(`/contact/all/add?tab=address`)}
            />
            <CustomizedMenuOptionsMeatball
              isIcon
              options={options}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case "นำเข้าผู้ติดต่อ" :
                    navigate(`/contact/import`);
                    break;
                  case "นำออกผู้ติดต่อ":
                    openModalHandler();
                    break;
                  default:
                }
              }}
              sx={{
                width: "44px",
                minWidth: "44px",
                height: "33px",
                minHeight: "33px"
              }}
              disableRipple={true}
            />
          </Box>
        </Box>
      </Box>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <ContactTable type={type} />
      <ExporterContactModal
        open={modal}
        closeModalHandler={closeModalHandler}
        typeOptions={typeOptions}
        exportHandler={exportHandler}
      />
    </>
  );
};

export default Contact;