import { BillingNoteReportType } from "generated/sales";
import { IExporterBillingNote, ISelectOption } from "types/global";

export const getBillingNoteExportTypeOptions = (pageType: BillingNoteReportType) => {
  switch (pageType) {
    case BillingNoteReportType.BillingNote:
      return billingNoteTypeOptions;
    default:
      return [];
  }
};

const billingNoteTypeOptions: ISelectOption[] = [
  {
    label: "ใบวางบิล",
    value: BillingNoteReportType.BillingNote,
  },
];


export const getBillingNoteExporterSchema = (pageType: BillingNoteReportType) => {
  let type;

  switch (pageType) {
    case BillingNoteReportType.BillingNote:
      type = BillingNoteReportType.BillingNote;
      break;
    default:
      type = BillingNoteReportType.BillingNote;
  }

  const exporterSchema: IExporterBillingNote = {
    type: type,
    report_type: "all",
    start_date: null,
    end_date: null,
  };

  return exporterSchema;
};
