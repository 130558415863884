import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

const useStylesRadioCheckBoxTable = (
  theme?: Theme
): Record<string, SxProps<Theme>> => ({
  checkboxTable: {
    "& .ag-theme-material": {
      // Styles for checkboxes in filter row
      "& .ag-header-row .ag-checkbox-input-wrapper": {
        width: "18px",
        height: "18px",
        border: "2px solid #1976d2",
        borderRadius: "4px",
        backgroundColor: "#fff",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        cursor: "pointer",

        "& input": {
          opacity: 0,
          position: "absolute",
          width: "100%",
          height: "100%",
          cursor: "pointer",
        },

        "&.ag-checked::after": {
          content: '"\\2714"',
          color: "#1976d2",
          fontSize: "14px",
          lineHeight: 1,
        },
      },

      // Styles specific to selection row checkboxes
      "& .ag-selection-checkbox .ag-checkbox-input-wrapper": {
        border: "none !important",
        background: "none !important",
        boxShadow: "none !important",
        width: "20px",
        height: "20px",
        position: "relative",
        display: "inline-block",
        overflow: "visible",
        cursor: "pointer",

        "& input": {
          appearance: "none",
          WebkitAppearance: "none",
          opacity: 0,
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          margin: 0,
          padding: 0,
          cursor: "pointer",
          zIndex: 3,
        },

        "&::after": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "18px",
          height: "18px",
          borderRadius: "50%",
          border: "2px solid #1976d2",
          backgroundColor: "transparent",
          transition: "all 0.2s ease-in-out",
          boxSizing: "border-box",
          zIndex: 1,
        },

        "&.ag-checked::after": {
          backgroundColor: "white",
          borderColor: "#1976d2",
        },

        "&.ag-checked::before": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "#1976d2",
          zIndex: 2,
          transition: "all 0.2s ease-in-out",
        },

        "&:not(.ag-checked)::after": {
          borderColor: "#757575",
          backgroundColor: "transparent",
        },

        "&:not(.ag-checked)::before": {
          opacity: 0,
        },

        "&:focus, &:active, & input:focus": {
          outline: "none !important",
          boxShadow: "none !important",
        },
      },

      // Row styles
      // "& .ag-row-selected": {
      //   backgroundColor: "inherit !important",

      //   "&::before": {
      //     backgroundColor: "transparent !important",
      //   },

      //   "&:hover": {
      //     backgroundColor: "inherit !important",
      //   },
      // },

      // "& .ag-row, & .ag-row:hover, & .ag-row-hover, & .ag-row-selected, & .ag-row-selected:hover, & .ag-row-selected.ag-row-hover":
      //   {
      //     backgroundColor: "transparent !important",

      //     "&::before": {
      //       display: "none !important",
      //     },
      //   },

      // "& .ag-cell, & .ag-cell:hover, & .ag-cell-hover": {
      //   backgroundColor: "transparent !important",
      // },
    },
  },
});

export default useStylesRadioCheckBoxTable;
