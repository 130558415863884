import { gql } from "graphql-request";

export const LOCATION_DELETE = gql`
  mutation LocationDelete($uniqueInput: LocationUniqueInput!) {
    LocationDelete(uniqueInput: $uniqueInput) {
      id
      unique_id
    }
  }
`;

export const LOCATION_VIEW = gql`
  query LocationViewAggrid($aggridInput: AnyAggridInput) {
    LocationViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        name
        province
        region
        contact_unique_id
        contact_full_name
        contact_phone
        coordinator_name
        coordinator_phone
        google_map_url
        latitude
        longitude
        distance
        status
        four_wheel_chilled
        four_wheel_frozen
        six_wheel_chilled
        six_wheel_frozen
        ten_wheel_chilled
        ten_wheel_frozen
        head_chilled
        head_frozen
        secondary_driver_cost
        drop_cost
        shipping_cost {
          type
          cost
          is_frozen
        }
      }
    }
  }
`;
