import { useCallback, useEffect, useRef } from "react";
import {
  GridReadyEvent,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import AgGrid from "components/UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { dateFilterModel } from "utils/AgGridFilter";
import { useDeliveryTripColumnDefs } from "./columnDefs";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { DELIVERY_TRIP_VIEW } from "services/AgGrid/DeliveryTripAgGrid";

type Props = {
  status: string | null;
  referenceUniqueId?: string | null;
};

const DeliveryTripTable = ({ status, referenceUniqueId }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const columnDefs = useDeliveryTripColumnDefs();
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        aggrid_status,
        plan_start_date,
        plan_end_date,
        driver_object_list,
        ...otherFilter
      } = filterModel;
      const formatFilter = {
        ...otherFilter,
        reference_quotation_unique_id_list: referenceUniqueId &&
          referenceUniqueId?.trim() !== "" && {
            type: "hasSome",
            filterType: "array",
            values: [referenceUniqueId],
          },
        plan_start_date: dateFilterModel(plan_start_date),
        plan_end_date: dateFilterModel(plan_end_date),
        driver: driver_object_list && {
          filterType: "array",
          type: "hasSome",
          values: driver_object_list.values,
        },
        aggrid_status: aggrid_status && {
          ...aggrid_status,
          values: [...aggrid_status.values],
        },
      };
      try {
        const { DeliveryTripViewAggrid } = await graphQLClientWms.request(
          DELIVERY_TRIP_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: DeliveryTripViewAggrid.results as any[],
          rowCount: DeliveryTripViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const statusInstance = params.api.getFilterInstance("aggrid_status");
      const mainStatusInstance = params.api.getFilterInstance("main_status");
      if (status) {
        if (status === "delivering" || status === "finished") {
          statusInstance.setModel({
            values: [status, "late"],
          });
        } else {
          statusInstance.setModel({
            values: [status],
          });
        }

        if (status !== "cancelled" && status !== "late") {
          mainStatusInstance.setModel({ values: [status] });
        } else {
          mainStatusInstance.setModel({});
        }
      } else {
        statusInstance.setModel({
          values: ["wait_deliver", "delivering", "late", "finished"],
        });
        mainStatusInstance.setModel({});
      }
      params.api.onFilterChanged();
    },
    [status]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/logistic/delivery-trip/${id}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onRowDoubleClicked={rowSelectHandler}
      onGridReady={onGridReady}
      path={`/logistic/delivery-trip`}
    />
  );
};

export default DeliveryTripTable;
