import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import CustomizedButton from "../../../Custom/CustomizedButton";
import LocationImporterDropzoneUI from "components/UI/LocationImporterDropzoneUI";

const LocationImportUploader = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const type = watch("type");

  const downloadTemplateHandler = () => {
    const filePath = `/static/importer/location/template_สถานที่.xlsx`;
    window.open(filePath, "_blank");
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h6">
              {"เลือกประเภทการนำเข้า"}
            </Typography>

          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Radio
                  onClick={() => {
                    setValue("type", "create");
                  }}
                  checked={type === "create"}
                // disabled={createButtonDisabled}
                />
              }
              label={t("button.create")}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Radio
                  onClick={() => {
                    setValue("type", "update_location");
                  }}
                  checked={type === "update_location"}
                // disabled={editButtonDisabled}
                />
              }
              label={("แก้ไขข้อมูลสถานที่")}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Radio
                  onClick={() => {
                    setValue("type", "update_shipping_cost");
                  }}
                  checked={type === "update_shipping_cost"}
                // disabled={editButtonDisabled}
                />
              }
              label={("แก้ไขค่าขนส่ง")}
            />
          </Grid>
          <Grid item xs />
          <Grid item>
            <CustomizedButton
              title={t("ดาวน์โหลด Template")}
              variant="outlined"
              size="medium"
              onClick={downloadTemplateHandler}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container alignItems="center" spacing={2}>

          <Grid item xs={12}>
            <LocationImporterDropzoneUI hasLabelHeader />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default LocationImportUploader;
