import { Grid } from "@mui/material";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import ControlledSelect from "components/Controller/ControlledSelect";
import { useFormContext } from "react-hook-form";
import { useDisable } from "hooks/use-disable";
import { useTranslation } from "react-i18next";
import LabelInput from "components/UI/LabelInput";

const ItemPurchase = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const purchaseVatTypeOptions = [
    { id: 0, value: "NO_VAT", label: "ไม่มี" },
    { id: 1, value: "VAT_0", label: "0 %" },
    { id: 2, value: "VAT_7", label: "7 %" },
  ];

  const renderVatType = (vatType: string) => {
    return purchaseVatTypeOptions.find((item) => item.value === vatType)?.label;
  };

  return (
    <>
      <Grid container spacing={1.5} mt={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledNumberTextField
            name="purchase_price"
            label={t("inventory.item.purchase.purchase_price")}
            control={control}
            viewMode={disabled}
            error={Boolean(errors.purchase_price)}
            helperText={errors.purchase_price?.message?.toString()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledNumberTextField
            name="min_purchase_request_qty"
            label={t("inventory.item.purchase.min_purchase_request_qty")}
            control={control}
            viewMode={disabled}
            error={Boolean(errors.min_purchase_request_qty)}
            helperText={errors.min_purchase_request_qty?.message?.toString()}
            showZero
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {!disabled && (
            <ControlledSelect
              name="purchase_vat_type"
              label={t("inventory.item.purchase.purchase_vat_type")}
              control={control}
              disabled={disabled}
              options={purchaseVatTypeOptions}
              error={Boolean(errors.purchase_vat_type)}
              helperText={errors.purchase_vat_type?.message?.toString()}
            />
          )}
          {disabled && (
            <LabelInput
              label={t("inventory.item.purchase.purchase_vat_type")}
              value={renderVatType(getValues("purchase_vat_type"))}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ItemPurchase;
