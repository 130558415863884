import { useCallback, useEffect } from "react";
import { FieldArrayWithId, useFormContext, useWatch } from "react-hook-form";
import { Typography } from "@mui/material";
import { numberFormatter } from "../../../../utils/Formatter/Global";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import { useDisable } from "hooks/use-disable";

type Props = {
  field: FieldArrayWithId<IDeliveryTrip, "expenses_list", "id">;
  index: number;
};

const PreVatAmountCell = ({ field, index }: Props) => {
  const [disabled] = useDisable();
  const { control, setValue } = useFormContext<IDeliveryTrip>();
  const qty =
    useWatch({
      control,
      name: `expenses_list.${index}.qty`,
    }) || 0;

  const pricePerUnit = useWatch({
    control,
    name: `expenses_list.${index}.price_per_unit`,
  });

  const discount = useWatch({
    control,
    name: `expenses_list.${index}.discount`,
  });

  const calculation = useCallback(
    (qty: number, price_per_unit: number, discount: number) => {
      const preVatAmount = qty * price_per_unit - discount;
      return preVatAmount;
    },
    []
  );

  useEffect(() => {
    const preVatAmount = calculation(qty, pricePerUnit, discount);
    setValue(`expenses_list.${index}.pre_vat_amount`, preVatAmount);
  }, [calculation, discount, index, pricePerUnit, qty, setValue]);

  if (disabled) {
    return <Typography>{numberFormatter(field.pre_vat_amount)}</Typography>;
  }

  return (
    <Typography>
      {numberFormatter(calculation(qty, pricePerUnit, discount))}
    </Typography>
  );
};

export default PreVatAmountCell;
