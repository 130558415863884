import { Grid } from "@mui/material";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import ControlledSelect from "components/Controller/ControlledSelect";
import CustomizedButton from "components/Custom/CustomizedButton";
import dayjs from "dayjs";
import { DateRange } from "generated/general";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IDashboardDateFilter } from "types/global";

type Props = {
  filterHandler: (data: IDashboardDateFilter) => void;
  isLoading: boolean;
};

const DashboardDateFilter = ({ filterHandler, isLoading }: Props) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm<IDashboardDateFilter>({
    defaultValues: {
      date_range: DateRange.Last_7Days,
      start_date: dayjs().startOf("day").subtract(6, "day"),
      end_date: dayjs().endOf("day"),
    },
  });

  const dateRateOption = useMemo(
    () => [
      {
        label: "วันนี้",
        value: DateRange.Today,
      },
      { label: "เมื่อวาน", value: DateRange.Yesterday },
      { label: "7 วันที่แล้ว", value: DateRange.Last_7Days },
      { label: "14 วันที่แล้ว", value: DateRange.Last_14Days },
      { label: "30 วันที่แล้ว", value: DateRange.Last_30Days },
      { label: "เดือนนี้", value: DateRange.ThisMonth },
      { label: "เดือนที่แล้ว", value: DateRange.LastMonth },
      { label: "3 เดือนที่แล้ว", value: DateRange.Last_3Months },
      { label: "ปีนี้", value: DateRange.ThisYear },
      { label: "ปีที่แล้ว", value: DateRange.LastYear },
      {
        label: "กำหนดเอง",
        value: DateRange.Custom,
      },
    ],
    []
  );

  const onDateRangeChangeHandler = (e: any) => {
    const value = e.target.value;
    switch (value) {
      case DateRange.Today:
        setValue("start_date", dayjs().startOf("day"));
        setValue("end_date", dayjs().endOf("day"));
        break;
      case DateRange.Yesterday:
        setValue("start_date", dayjs().startOf("day").subtract(1, "day"));
        setValue("end_date", dayjs().endOf("day").subtract(1, "day"));
        break;
      case DateRange.Last_7Days:
        setValue("start_date", dayjs().startOf("day").subtract(6, "day"));
        setValue("end_date", dayjs().endOf("day"));
        break;
      case DateRange.Last_14Days:
        setValue("start_date", dayjs().startOf("day").subtract(13, "day"));
        setValue("end_date", dayjs().endOf("day"));
        break;
      case DateRange.Last_30Days:
        setValue("start_date", dayjs().startOf("day").subtract(29, "day"));
        setValue("end_date", dayjs().endOf("day"));
        break;
      case DateRange.ThisMonth:
        setValue("start_date", dayjs().startOf("month"));
        setValue("end_date", dayjs().endOf("month"));
        break;
      case DateRange.LastMonth:
        setValue("start_date", dayjs().startOf("month").subtract(1, "month"));
        setValue("end_date", dayjs().endOf("month").subtract(1, "month"));
        break;
      case DateRange.Last_3Months:
        setValue("start_date", dayjs().startOf("day").subtract(2, "month"));
        setValue("end_date", dayjs().endOf("day"));
        break;
      case DateRange.ThisYear:
        setValue("start_date", dayjs().startOf("year"));
        setValue("end_date", dayjs().endOf("year"));
        break;
      case DateRange.LastYear:
        setValue("start_date", dayjs().startOf("year").subtract(1, "year"));
        setValue("end_date", dayjs().endOf("year").subtract(1, "year"));
        break;
      default:
        break;
    }
  };

  return (
    <Grid container spacing={1.5} alignItems={"center"}>
      <Grid item xs={12} sm={12} md={3}>
        <ControlledSelect
          label={t("date.range")}
          name="date_range"
          control={control}
          options={dateRateOption}
          onChange={onDateRangeChangeHandler}
          required
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <ControlledDatePicker
          control={control}
          name="start_date"
          label={t("date.from")}
          onCusomChange={() => {
            setValue("date_range", DateRange.Custom);
          }}
          required
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <ControlledDatePicker
          control={control}
          name="end_date"
          label={t("date.to")}
          onCusomChange={() => {
            setValue("date_range", DateRange.Custom);
          }}
          required
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <CustomizedButton
          title={t("button.filter")}
          variant="outlined"
          onClick={handleSubmit(filterHandler)}
          disabled={isLoading}
          sx={{ bgcolor: "white" }}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default DashboardDateFilter;
