import {
  ColDef,
  ValueFormatterParams,
  IServerSideGetRowsParams,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import AgGrid from "../../../UI/AgGrid";
import { RefObject } from "react";
import { AgGridReact } from "ag-grid-react";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";

import CustomizedStatus from "../../../Custom/CustomizedStatus";
import {
  numberFormatter,
  statusValueFormatter,
} from "../../../../utils/Formatter/Global";
import { formatDate } from "utils/Date";
import { PURCHASE_BY_DUE_DATE_EXPORT_VIEW } from "services/Purchase/Report/PurchaseByDueDateExportViewAggrid";
import { dateFilterModel, dateFilterParams } from "utils/AgGridFilter";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

export const PurchaseByDueDateTable = ({ gridRef }: Props) => {
  const { t } = useTranslation();

  const columnDefs: ColDef[] = [
    {
      field: "unique_id",
      headerName: "เลขที่เอกสาร",
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("status.index"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "draft",
          "wait_ordered",
          "fully_ordered",
          "partially_imported",
          "fully_imported",
          "cancelled",
          "finished",
        ],
        valueFormatter: (params: ValueFormatterParams) =>
          statusValueFormatter(params.value),
      },
      cellRenderer: (params: ICellRendererParams) => (
        <CustomizedStatus status={params.value} />
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-center",
        width: "100%",
      },
      // headerClass: "ag-center-header",
    },
    {
      field: "due_date",
      headerName: "วันครบกำหนดชำระ",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      sort: "asc",
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "supplier_unique_id",
      headerName: "รหัสซัพพลายเออร์",
      filter: "agTextColumnFilter",
    },
    {
      field: "supplier_name",
      headerName: "ชื่อซัพพลายเออร์",
      filter: "agTextColumnFilter",
    },
    {
      field: "net_amount",
      headerName: "มูลค่ารวมสุทธิ",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      //   headerClass: "ag-end-header",
    },
    {
      field: "vat_amount",
      headerName: "ภาษีมูลค่าเพิ่มรวม",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      //   headerClass: "ag-end-header",
    },
    {
      field: "total_amount",
      headerName: "จำนวนเงินชำระทั้งสิ้น",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      //   headerClass: "ag-end-header",
    },
    {
      field: "pre_vat_amount",
      headerName: "มูลค่าก่อนหักส่วนลด",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      //   headerClass: "ag-end-header",
    },
    {
      field: "additional_discount",
      headerName: "ส่วนลดเพิ่มเติม",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      //   headerClass: "ag-end-header",
    },
    {
      field: "post_discount_amount",
      headerName: "มูลค่าก่อนภาษี",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      //   headerClass: "ag-end-header",
    },
    {
      field: "withholding_tax_amount",
      headerName: "ภาษีหัก ณ ที่จ่าย",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => numberFormatter(params.value ?? 0),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      //   headerClass: "ag-end-header",
    },
  ];

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const { due_date } = filterModel;

      const formatFilter = {
        ...filterModel,
        due_date: dateFilterModel(due_date),
      };

      try {
        const { PurchaseByDueDateExportViewsAggrid } =
          await graphQLClient.request(PURCHASE_BY_DUE_DATE_EXPORT_VIEW, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });

        params.success({
          rowData: PurchaseByDueDateExportViewsAggrid.results,
          rowCount: PurchaseByDueDateExportViewsAggrid.count,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    const instanceStatus = params.api.getFilterInstance("status");
    instanceStatus?.setModel({
      values: ["fully_ordered", "partially_imported", "fully_imported"],
    });

    params.api.onFilterChanged();
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};
