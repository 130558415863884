import * as Yup from "yup";
import dayjs from "dayjs";
import { IPurchaseReturn } from "../../../../types/Purchase/purchaseReturn";
import {
  AdditionalDiscountType,
  PriceVatType,
} from "../../../../generated/purchase";
import { itemListSchema } from "../ItemListSchema";
import { stringMaxInput } from "utils/Validate";
// import { itemListPRSchema } from "../../Sales/itemListSchema";

export const purchaseReturnSchema: IPurchaseReturn = {
  unique_id: "",
  main_status: "",
  sub_status: "",
  aggrid_status: "",
  flag_status: [],
  issue_date: dayjs(),
  delivery_date: dayjs(),
  last_updated_by: null,
  created_date: dayjs(),
  created_by: null,
  reference_no: "",
  related_user_list: [],
  reference_document_list: [],
  reason_to_return: "",
  remark: "",
  tag_list: [],
  item_list: [],
  additional_discount: 0,
  additional_discount_type: AdditionalDiscountType.Baht,
  price_vat_type: PriceVatType.ExcludedVat,
  post_discount_amount: 0,
  total_amount: 0,
  vat_0_percent_amount: 0,
  vat_7_percent_amount: 0,
  vat_amount: 0,
  vat_exempted_amount: 0,
  withholding_tax_amount: 0,
};

export const purchaseReturnValidation = Yup.object().shape({
  unique_id: stringMaxInput(15, "กรุณาระบุเลขที่เอกสาร", true),
  created_date: Yup.date().required("กรุณาระบุวันที่สร้าง"),
  issue_date: Yup.date().required("กรุณาระบุวันที่ออกเอกสาร"),
  reason_to_return: stringMaxInput(
    200,
    undefined,
    true,
    "กรุณาระบุเหตุผลการส่งคืน"
  ),
  // title_name: stringMaxInput(),
  supplier_details: Yup.object().shape({
    // supplier_unique_id: stringMaxInput(200, undefined, true),
    email: stringMaxInput(100, undefined, false),
    fax: stringMaxInput(20, undefined, false),
    phone: stringMaxInput(20, undefined, false),
  }),
  price_vat_type: Yup.string().required("กรุณาระบุประเภทราคา"),
  item_list: Yup.array().of(itemListSchema).min(1, "กรุณาเพิ่มสินค้า"),
  additional_discount: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
});
