import { Box, IconButton, Typography } from "@mui/material";
import ControlledTextField from "components/Controller/ControlledTextField";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import { useDisable } from "hooks/use-disable";
import { FieldArrayWithId, useFormContext } from "react-hook-form";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import LaunchIcon from "@mui/icons-material/Launch";

type Props = {
  field: FieldArrayWithId<IDeliveryTrip, "income_list", "id">;
  index: number;
  disabledStatus: boolean;
};

const ItemCell = ({ field, index, disabledStatus }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IDeliveryTrip>();

  const [disabled] = useDisable();
  return (
    <Box display={"flex"} gap={0.5}>
      <Box>
        {!disabled && !disabledStatus ? (
          <>
            <ControlledTextField
              control={control}
              name={`income_list.${index}.item_name`}
              error={Boolean(
                errors?.income_list && errors.income_list[index]?.item_name
              )}
            />
            <Typography variant="overline">{field.item_unique_id}</Typography>
            <ControlledTextField
              control={control}
              name={`income_list.${index}.item_sku_desc`}
              error={Boolean(
                errors?.income_list && errors.income_list[index]?.item_sku_desc
              )}
            />
          </>
        ) : (
          <>
            <Typography>{field.item_name}</Typography>
            <Typography variant="overline">{field.item_unique_id}</Typography>
            <Typography>{field.item_sku_desc}</Typography>
          </>
        )}
      </Box>

      {!disabled && !disabledStatus && (
        <Box display={"flex"} alignItems={"center"}>
          <CustomizedTooltip title="กดเพื่อดูรายละเอียดสินค้า">
            <IconButton
              size="small"
              onClick={() =>
                window.open(
                  `/inventory/item/${field.item_id}?tab=item&subtab=general`,
                  "_blank"
                )
              }
              color="primary"
            >
              <LaunchIcon fontSize="small" />
            </IconButton>
          </CustomizedTooltip>
        </Box>
      )}
    </Box>
  );
};

export default ItemCell;
