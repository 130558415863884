import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { Typography } from "@mui/material";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import NoEntryReportTable from "components/Table/Inventory/Reports/NoEntryReport";
import HeaderLayout from "components/UI/HeaderLayout";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "types/global";
import CustomizedButton from "components/Custom/CustomizedButton";
import { useInventoryReport } from "hooks/Inventory/use-inventory-report";
import { InventoryReportType } from "generated/wms";

const NoEntry = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
      to: "/inventory/report",
    },
    {
      name:`${t("report")}${t("inventory.noEntry.index")}`,
    },
  ];

  const gridRef = useRef<AgGridReact>(null);

  const { onBtnExport, isExporting, isFetching } = useInventoryReport(
    gridRef,
    InventoryReportType.NoTraceEntry
  );

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5">{`${t("report")}${t("inventory.noEntry.index")}`}</Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
          disabled={isExporting && isFetching}
        />
      </HeaderLayout>
      <NoEntryReportTable gridRef={gridRef} />
    </>
  );
};

export default NoEntry;
