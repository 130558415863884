import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import { FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import CustomizedButton from "../../../Custom/CustomizedButton";
import ImporterDropzoneUI from "components/UI/ImporterDropzoneUI";
import { ItemImportType } from "generated/wms";

const ItemImportUploader = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  // const itemTypeOptions: ISelectOption[] = [
  //   { label: t("inventory.item.normal"), value: ItemImportType.Normal },
  //   { label: t("inventory.item.service"), value: ItemImportType.Service },
  // ];

  // const type = watch("type");
  const import_type = watch("import_type");

  useEffect(() => {
    setValue("type", "create");
  }, [setValue]);

  const downloadTemplateHandler = () => {
    // const filePath = `/static/importer/item/Template_${import_type}_item_importer.xlsx`;
    let filePath = '';
    switch (import_type) {
      case ItemImportType.Normal:
        filePath = `/static/importer/item/template_สินค้าธรรมดา.xlsx`;
        break;
      case ItemImportType.Service:
        filePath = `/static/importer/item/template_บริการ.xlsx`;
        break;
    }
    window.open(filePath, "_blank");
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h6">
              {"สร้างโดยการนำเข้าไฟล์สินค้า"}
            </Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <CustomizedButton
              title={t("ดาวน์โหลด Template")}
              variant="outlined"
              size="medium"
              onClick={downloadTemplateHandler}
            />
          </Grid>
        </Grid>
        {/* <Grid item>
          <FormControlLabel
            control={
              <Radio
                onClick={() => {
                  setValue("type", "create");
                }}
                checked={type === "create"}
              // disabled={createButtonDisabled}
              />
            }
            label={t("button.create")}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Radio
                onClick={() => {
                  setValue("type", "update");
                }}
                checked={type === "update"}
              // disabled={editButtonDisabled}
              />
            }
            label={t("button.edit")}
          />
        </Grid> */}
        <Grid item xs={12} container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h6">
              {t("inventory.item.type")}
            </Typography>
          </Grid>

          <Grid item>
            <FormControlLabel
              control={
                <Radio
                  onClick={() => {
                    setValue("import_type", ItemImportType.Normal);
                  }}
                  checked={import_type === ItemImportType.Normal}
                // disabled={createButtonDisabled}
                />
              }
              label={t("inventory.item.normal")}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Radio
                  onClick={() => {
                    setValue("import_type", ItemImportType.Service);
                  }}
                  checked={import_type === ItemImportType.Service}
                // disabled={editButtonDisabled}
                />
              }
              label={t("inventory.item.service")}
            />
          </Grid>

          <Grid item xs={12}>
            <ImporterDropzoneUI hasLabelHeader />
          </Grid>
        </Grid>
        </Grid>
    </Fragment>
  );
};

export default ItemImportUploader;
