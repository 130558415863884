import { Box, Grid, IconButton, Typography } from "@mui/material";
import ControlledTextField from "components/Controller/ControlledTextField";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import {
  InventoryModelType,
  WmsUniqueIdGenerateQuery,
  useWmsUniqueIdGenerateQuery,
} from "generated/wms";
import useInventoryControl from "hooks/Inventory/use-inventory-control";
import { useDisable } from "hooks/use-disable";
import { useCallback, useEffect, useState } from "react";
import {
  Controller,
  UseFieldArrayReplace,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import LabelInput from "components/UI/LabelInput";
import CustomizedComboBox from "components/Custom/CustomizedComboBox";
import { useConfirmation } from "hooks/use-confirmation";
import { IGoodsAdjust } from "types/Inventory/goodsAdjust";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

type Props = {
  replace: UseFieldArrayReplace<IGoodsAdjust, "trace_entry_list">;
};

const GoodsAdjustInfo = ({ replace }: Props) => {
  const { t } = useTranslation();

  const { id } = useParams();

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const [currentWarehouse, setCurrentWarehouse] = useState<number>();

  const graphQLClient = createGraphQLClientWithMiddleware("wms");

  const [disabled] = useDisable();

  const { warehousesOptions, renderWarehousesOptions } = useInventoryControl();

  const { refetch: refetchUniqueId } =
    useWmsUniqueIdGenerateQuery<WmsUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: InventoryModelType.GoodAdjust,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const onWarehouseChangeHandler = () => {
    replace([]);
    setValue("source_warehouse_id", currentWarehouse);
  };

  const {
    confirmation: warehouseConfirmation,
    openConfirmationHandler: openWarehouseConfirmationHandler,
    closeConfirmationHandler: closeWarehouseConfirmationHandler,
    submitConfirmationHandler: submitWarehouseConfirmationHandler,
  } = useConfirmation(onWarehouseChangeHandler);

  return (
    <CustomizedBox margin={"0 0 1.5rem 0"}>
      <Typography fontWeight={"bold"} mb={2}>
        {t("sentence.detail")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid
          item
          xs={11}
          sm={11}
          md={id ? 4 : 3.4}
          lg={id ? 4 : 3.4}
          xl={id ? 4 : 3.4}
        >
          <ControlledTextField
            name="unique_id"
            control={control}
            label={t(`sentence.unique_id`)}
            error={Boolean(errors.unique_id)}
            helperText={errors.unique_id?.message?.toString()}
            onChange={(e, field) => {
              return field.onChange(e.target.value ?? "");
            }}
            disabled={Boolean(id)}
            viewMode={disabled}
            required
          />
        </Grid>
        {!id && (
          <Grid item xs={1} sm={1} md={0.6} lg={0.6} xl={0.6}>
            {!Boolean(id) && (
              <CustomizedTooltip
                title={`เรียก${t(`sentence.unique_id`)}`}
                enterNextDelay={200}
              >
                <IconButton
                  onClick={generateUniqueId}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {disabled ? (
            <LabelInput
              label={t("inventory.item.warehouse.index")}
              value={renderWarehousesOptions(getValues("source_warehouse_id"))}
            />
          ) : (
            <Controller
              control={control}
              name="source_warehouse_id"
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  label={t("inventory.item.warehouse.index")}
                  options={warehousesOptions}
                  disabled={warehousesOptions.length === 0}
                  onChange={(_, newValue) => {
                    setCurrentWarehouse(newValue?.value);
                    const currentTrace = getValues("trace_entry_list");
                    if (currentTrace.length > 0) {
                      openWarehouseConfirmationHandler();
                    } else {
                      field.onChange(newValue?.value ?? "");
                    }
                  }}
                  error={Boolean(errors.source_warehouse_id)}
                  helperText={errors.source_warehouse_id?.message?.toString()}
                  value={renderWarehousesOptions(field.value)}
                  required
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="remark"
            label={t("sentence.remark")}
            viewMode={disabled}
            error={Boolean(errors.remark)}
            helperText={errors.remark?.message?.toString()}
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        title="ยืนยันการเปลี่ยนคลัง"
        message={
          <Box textAlign="center" my={1} mb={2}>
            <ErrorOutlineOutlinedIcon
              sx={{
                fontSize: "4rem",
                marginTop: 1,
                color: "gray.light",
              }}
            />
            <Typography>{"หากเปลี่ยนคลัง"}</Typography>
            <Typography>{"รายการสินค้าด้านล่างจะถูกเปลี่ยนทั้งหมด"}</Typography>
          </Box>
        }
        open={warehouseConfirmation}
        handleClose={closeWarehouseConfirmationHandler}
        action={submitWarehouseConfirmationHandler}
        noDivider
      />
    </CustomizedBox>
  );
};

export default GoodsAdjustInfo;
