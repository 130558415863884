import { ReactNode } from "react";
import { Stack, StackProps } from "@mui/material";

type Props = {
  children?: ReactNode;
  sx?: StackProps["sx"];
};

const ButtonLayout = ({ sx, children }: Props) => {
  return (
    <Stack sx={sx} direction="row" spacing={1} alignItems="center">
      {children}
    </Stack>
  );
};

export default ButtonLayout;
