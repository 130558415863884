import dayjs from "dayjs";
import { ItemSkuQtysQuery, TraceEntryType, Traceability } from "generated/wms";
import { IUser } from "types/Auth/user";
import { ITraceEntry } from "types/Inventory";
import { IGoodsAdjust } from "types/Inventory/goodsAdjust";
import { ICreatedBy } from "types/global";
import { v4 as uuidv4 } from "uuid";

export const goodsAdjustCreatePayloadFormatter = (
  data: IGoodsAdjust,
  status: string
) => {
  const {
    created_date,
    main_status,
    aggrid_status,
    trace_entry_list,
    source_warehouse_id,
    ...otherPayload
  } = data;

  const new_trace_entry_list: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            id,
            uom_id,
            reference_status,
            source_bin_location,
            all_bin_locations,
            ...serial
          }) => ({
            ...serial,
            reference_unique_id: data.unique_id,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            barcode: serial.barcode,
            uom_id: typeof uom_id === "string" ? parseInt(uom_id) : uom_id,
            scanned_by: data.created_by ?? {},
          })
        );
        new_trace_entry_list.push(...formatSerialList);
      } else {
        const {
          id,
          serial_list,
          uom_id,
          reference_status,
          source_bin_location,
          all_bin_locations,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          reference_unique_id: data.unique_id,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
          uom_id: typeof uom_id === "string" ? parseInt(uom_id) : uom_id,
          scanned_by: data.created_by ?? {},
          destination_scanned_by: {},
        };
        new_trace_entry_list.push(formatTrace);
      }
    } else {
      new_trace_entry_list.push(trace);
    }
  });

  return {
    ...otherPayload,
    trace_entry_list: new_trace_entry_list,
    main_status: status,
    source_warehouse_id:
      typeof source_warehouse_id === "string"
        ? parseInt(source_warehouse_id)
        : source_warehouse_id,
  };
};

export const goodsAdjustUpdatePayloadFormatter = (
  data: IGoodsAdjust,
  status: string,
  user: IUser | null
) => {
  const {
    id,
    created_date,
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    source_warehouse,
    source_warehouse_id,
    trace_entry_list,
    created_by,
    last_updated_date,
    ...otherPayload
  } = data;

  const updatedBy: ICreatedBy = {
    user_unique_id: user?.unique_id,
    email: user?.email,
    first_name: user?.first_name,
    last_name: user?.last_name,
    img_url: user?.img_url,
  };

  const new_trace_entry_list: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            id,
            uom_id,
            created_date,
            source_bin_location,
            destination_bin_location,
            scanned_by,
            destination_scanned_by,
            reference_status,
            all_bin_locations,
            ...serial
          }) => ({
            ...serial,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            posted_qty: trace.posted_qty,
            barcode: serial.barcode,
            uom_id: typeof uom_id === "string" ? parseInt(uom_id) : uom_id,
            scanned_by: scanned_by ? scanned_by : {},
            destination_scanned_by: {},
          })
        );
        new_trace_entry_list.push(...formatSerialList);
      } else {
        const {
          id,
          serial_list,
          uom_id,
          source_bin_location,
          destination_bin_location,
          created_date,
          scanned_by,
          destination_scanned_by,
          reference_status,
          all_bin_locations,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          uom_id: typeof uom_id === "string" ? parseInt(uom_id) : uom_id,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
          scanned_by: scanned_by ? scanned_by : {},
          destination_scanned_by: {},
        };
        new_trace_entry_list.push(formatTrace);
      }
    } else {
      new_trace_entry_list.push(trace);
    }
  });

  return {
    ...otherPayload,
    trace_entry_list: new_trace_entry_list,
    main_status: status,
    source_warehouse_id:
      typeof source_warehouse_id === "string"
        ? parseInt(source_warehouse_id)
        : source_warehouse_id,
    last_updated_by: updatedBy,
  };
};

const groupBy = (xs: any[], key: string) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const goodsAdjustQueryFormatter = (
  data: IGoodsAdjust,
  allSkuQtys?: ItemSkuQtysQuery
) => {
  const { trace_entry_list, ...otherData } = data;
  const groupedTrace = groupBy(trace_entry_list, "item_unique_id");

  const allTraceEntries: ITraceEntry[] = [];

  for (const entry of Object.entries(groupedTrace)) {
    const key = entry[0] as string;
    const value = entry[1] as ITraceEntry[];

    const allSkuQtyInWarehouse = allSkuQtys?.ItemSkuQtys
      ? allSkuQtys?.ItemSkuQtys.filter(
          (sku) => sku?.warehouse_id === Number(otherData?.source_warehouse_id)
        ).map((sku) => ({
          id: sku?.bin_location_id,
          barcode: sku?.barcode,
          name: sku?.bin_name,
          stock_qty: sku?.stock_qty,
          warehouse_id: sku?.warehouse_id,
        }))
      : undefined;

    const filterBinLocations =
      allSkuQtyInWarehouse && value[0].traceability === Traceability.Normal
        ? allSkuQtyInWarehouse.filter(
            (sku) => sku?.barcode === value[0].barcode
          )
        : undefined;

    const formatSerial = value.map((serial) => {
      const stock_qty = allSkuQtys?.ItemSkuQtys
        ? allSkuQtys.ItemSkuQtys?.find(
            (sku) =>
              sku?.barcode === serial.barcode &&
              sku?.bin_location_id === serial.source_bin_location_id
          )?.stock_qty
        : serial.stock_qty;

      const filterBinLocationsSerial = allSkuQtyInWarehouse
        ? allSkuQtyInWarehouse.filter((sku) => sku?.barcode === serial.barcode)
        : undefined;

      return {
        ...serial,
        scanned_by: serial.scanned_by ?? undefined,
        all_bin_locations: filterBinLocationsSerial,
        stock_qty,
      };
    });

    const formatTrace = {
      item_unique_id: key,
      po_reference_unique_id: value[0].po_reference_unique_id,
      item_name: value[0].item_name,
      seller_sku: value[0].seller_sku,
      item_img_url: value[0].item_img_url,
      traceability: value[0].traceability,
      type: value[0].type,
      unique_id: value[0].unique_id,
      qty: value.reduce((prev, curr) => prev + curr.qty, 0),
      posted_qty: value[0].posted_qty,
      document_item_qty: value[0].document_item_qty,
      scanned_date:
        value[0].traceability === Traceability.Normal
          ? value[0].scanned_date
          : undefined,
      created_date: value[0].created_date,
      uom_id: value[0].uom_id,
      uom: value[0].uom,
      source_bin_location:
        value[0].traceability === Traceability.Normal
          ? value[0].source_bin_location
          : undefined,
      source_bin_location_id:
        value[0].traceability === Traceability.Normal
          ? value[0].source_bin_location_id
          : undefined,
      barcode:
        value[0].traceability === Traceability.Normal
          ? value[0].barcode
          : undefined,
      serial_list: value.some(
        (serial) => serial.traceability === Traceability.Normal
      )
        ? undefined
        : formatSerial,
      status:
        value[0].traceability === Traceability.Normal
          ? value[0].status
          : undefined,
      scanned_by:
        value[0].traceability === Traceability.Normal
          ? value[0].scanned_by ?? undefined
          : undefined,
      destination_scanned_by: {},
      remark: value[0].remark ?? undefined,
      reference_unique_id: value[0].reference_unique_id,
      lot_date: value[0].lot_date ? dayjs(value[0].lot_date) : null,
      all_bin_locations: filterBinLocations,
      stock_qty: formatSerial?.reduce<number>(
        (prev, curr) => prev + (curr.stock_qty ?? 0),
        0
      ),
    } as ITraceEntry;
    allTraceEntries.push(formatTrace);
  }

  return {
    ...otherData,
    trace_entry_list: allTraceEntries,
    created_date: dayjs(otherData.created_date),
    posted_date: dayjs(otherData.posted_date),
  };
};

export const goodsAdjustTraceEntryFormatter = (
  trace: ITraceEntry,
  binLocations: any[],
  user: IUser | null
) => {
  const { type, created_date, scanned_by, unique_id, ...otherPayload } = trace;

  if (user) {
    const formatTrace: ITraceEntry = {
      ...otherPayload,
      qty: 0,
      source_bin_location: binLocations[0],
      source_bin_location_id: binLocations[0].id,
      all_bin_locations: binLocations,
      unique_id: uuidv4(),
      type: TraceEntryType.GoodAdjust,
      scanned_by: {
        user_unique_id: user.unique_id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        img_url: user.img_url,
      },
      scanned_date: dayjs().toDate(),
      stock_qty: binLocations[0].stock_qty,
      status:
        trace.traceability === Traceability.Normal
          ? "is_scanned"
          : trace.status,
    };
    return formatTrace;
  }
  return;
};
