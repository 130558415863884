import { Box, CircularProgress, Typography } from "@mui/material";
import CustomizedButton from "components/Custom/CustomizedButton";
import QuotationListPdfTable from "components/Table/Sales/Quotation/PDF";
import PDFLayout from "components/UI/PDF/Layout";
import {
  QuotationFindUniqueQuery,
  useQuotationFindUniqueQuery,
} from "generated/sales";
import { usePDFMultiplePage } from "hooks/use-pdf-multiple-page";
import { ReactInstance, useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IPdfInfo } from "types/global";
import { ISalesItemList } from "types/Sales";
import { formatDate } from "utils/Date";
import { renderAddressString } from "utils/Formatter/Global";

const vehicleTypes = [
  {
    label: "4 ล้อ",
    value: "four_wheel",
  },
  {
    label: "6 ล้อ",
    value: "six_wheel",
  },
  {
    label: "10 ล้อ",
    value: "ten_wheel",
  },
  {
    label: "หัวลาก",
    value: "head",
  },
];

const QuotationPDF = () => {
  const { id } = useParams();
  const componentRef = useRef<ReactInstance | null>(null);

  const graphQLClient = createGraphQLClientWithMiddleware("sales");
  const { data, isLoading } =
    useQuotationFindUniqueQuery<QuotationFindUniqueQuery>(graphQLClient, {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    });

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.QuotationFindUnique, //data
    "quotation", // document type
    139.66, // name and description row width
    640, // max height without footer
    495, // max height with footer
    224.875, // left header minimum height
    247.797, // right header minimum height
    37.313, // left footer minimum height (you don't need to specify this if the document doesn't have remark and tags)
    87.963 // right footer minimum height (you don't need to specify this if the document doesn't have summary)
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data?.QuotationFindUnique?.unique_id,
  });

  const highlightSection: IPdfInfo = {
    headers: ["เลขที่เอกสาร", "วันที่ออกเอกสาร", "ใช้ได้ถึง", "เครดิต"],
    info: [
      data?.QuotationFindUnique.unique_id || "-",
      data?.QuotationFindUnique.issue_date
        ? formatDate(data?.QuotationFindUnique.issue_date)
        : "-",
      data?.QuotationFindUnique.due_date
        ? formatDate(data?.QuotationFindUnique.due_date)
        : "-",
      data?.QuotationFindUnique.credit_day
        ? data.QuotationFindUnique.credit_day + " วัน"
        : "-",
    ],
  };

  const leftSection: IPdfInfo = {
    headers: [
      "ลูกค้า",
      "ที่อยู่",
      "เลขประจำตัวผู้เสียภาษี",
      "เบอร์โทรศัพท์@Fax",
      "Email",
      "สถานที่บรรจุ",
    ],
    info: [
      `${data?.QuotationFindUnique.contact_unique_id} - ${data?.QuotationFindUnique.customer_details.customer_name}`,
      renderAddressString(
        data?.QuotationFindUnique.customer_details.billing_address
      ) || "-",
      data?.QuotationFindUnique.customer_details.identity_no || "-",
      data?.QuotationFindUnique.customer_details.phone +
        "@" +
        data?.QuotationFindUnique.customer_details.fax || "-",
      data?.QuotationFindUnique.customer_details.email || "-",
      data?.QuotationFindUnique.packing_location || "-",
    ],
  };

  const rightSection: IPdfInfo = {
    headers: [
      "ประเภทรถ",
      "ประเภทตู้",
      "ประเภทสินค้า",
      "มูลค่าสินค้า",
      "อุณหภูมิ",
      "ประเภทงาน",
    ],
    info: [
      vehicleTypes.find(
        (vehicle) => vehicle.value === data?.QuotationFindUnique.vehicle_type
      )?.label || "-",
      data?.QuotationFindUnique.container_type || "-",
      data?.QuotationFindUnique.item_type || "-",
      data?.QuotationFindUnique.item_value || "-",
      data?.QuotationFindUnique.expected_temperature || "-",
      data?.QuotationFindUnique.is_frozen ? "Frozen" : "Chilled",
    ],
  };

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mx: "auto" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">ใบเสนอราคา</Typography>
        <CustomizedButton
          title="พิมพ์เอกสาร"
          variant="outlined"
          onClick={handlePrint}
        />
      </Box>
      <Box ref={componentRef} sx={{ m: "auto" }}>
        {paginatedItems.map((item, index) => (
          <PDFLayout
            highlightSection={highlightSection}
            leftSection={leftSection}
            rightSection={rightSection}
            name={"ใบเสนอราคา"}
            data={data?.QuotationFindUnique}
            summary={index === paginatedItems.length - 1}
            multiplePage={paginatedItems.length > 1}
            page={index + 1}
            allPages={paginatedItems.length}
            documentType="quotation"
          >
            <QuotationListPdfTable
              itemList={(item as ISalesItemList[]) || []}
              startNumber={startNumbers[index]}
              lastTable={
                paginatedItems[paginatedItems.length - 1].length === 0
                  ? index === paginatedItems.length - 2
                  : index === paginatedItems.length - 1
              }
              totalAmount={
                data?.QuotationFindUnique.total_amount
                  ? data?.QuotationFindUnique.total_amount
                  : 0
              }
            />
          </PDFLayout>
        ))}
      </Box>
    </Box>
  );
};

export default QuotationPDF;
