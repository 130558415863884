import { Controller, Control } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  DesktopDateTimePickerProps,
  DesktopDateTimePicker,
} from "@mui/x-date-pickers";
import LabelInput from "components/UI/LabelInput";
import { formatDateTime } from "utils/Date";

type ExtendedDateTimePickerProps = DesktopDateTimePickerProps<Date> & {
  name: string;
  control: Control<any>;
  required?: boolean;
  viewMode?: boolean | null;
};

const ControlledDateTimePicker = ({
  name,
  control,
  label,
  disabled,
  disablePast,
  required,
  viewMode,
}: ExtendedDateTimePickerProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <>
          {viewMode ? (
            <LabelInput
              label={label}
              value={value ? formatDateTime(value.toDate()) : ""}
            />
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDateTimePicker
                format="DD/MM/YYYY HH:mm"
                label={label}
                onChange={onChange}
                value={value}
                ref={ref}
                disablePast={disablePast}
                disabled={disabled}
                slotProps={{
                  textField: {
                    required: required,
                    fullWidth: true,
                    size: "small",
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
              />
            </LocalizationProvider>
          )}
        </>
      )}
    />
  );
};

export default ControlledDateTimePicker;
