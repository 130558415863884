import { Box, Grid, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import {
  NotificationVehicleFindQuery,
  useNotificationVehicleFindQuery,
} from "generated/general";
import { useEffect, useMemo, useState } from "react";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IInsuranceDetail } from "types/Logistic/vehicle";

export enum reminderType {
  "insurance_vehicle" = "ประกันรถ",
  "insurance_product" = "ประกันสินค้า",
  "act_tax" = "พรบ./ภาษี",
  "wheel" = "ยาง",
  "maintenance" = "ซ่อมบำรุง",
}

type Props = {
  type: reminderType | null;
  insurance_vehicle?: IInsuranceDetail | null;
  insurance_product?: IInsuranceDetail | null;
  act_end_date?: Dayjs | null;
  current_kilometer?: number | null;
  due_distance_kilometer?: number | null;
};

type statusProps = {
  color: string;
  text: string;
};

const CustomizedReminderVehicleBox = ({
  type,
  insurance_vehicle,
  insurance_product,
  act_end_date,
  current_kilometer,
  due_distance_kilometer,
}: Props) => {
  const statusProps = useMemo(
    () => [
      { color: "#22915A", text: "ปกติ" },
      { color: "#FB8C00", text: "ใกล้ครบกำหนด" },
      { color: "#E41B1B", text: "เกินกำหนด" },
      { color: "#BDBDBD", text: "ไม่มีข้อมูล" },
    ],
    []
  );

  const [status, setStatus] = useState<statusProps>();

  // vehicle notication setting
  const graphqlClient = createGraphQLClientWithMiddleware("general");

  const { data } =
    useNotificationVehicleFindQuery<NotificationVehicleFindQuery>(
      graphqlClient
    );

  const reminderProductDate =
    data?.NotificationVehicleFind?.product_insurance_due_date || 0;
  const reminderVehicleDate =
    data?.NotificationVehicleFind?.vehicle_insurance_due_date || 0;
  const reminderActDate = data?.NotificationVehicleFind?.act_due_date || 0;
  const reminderDistance =
    data?.NotificationVehicleFind?.maintenance_distance || 0;

  useEffect(() => {
    const currentDate = dayjs();
    let beforeRemind;
    let end_date;

    switch (type) {
      case reminderType.insurance_product:
        beforeRemind = dayjs(insurance_product?.end_date).subtract(
          reminderProductDate,
          "day"
        );
        end_date = dayjs(insurance_product?.end_date);
        break;
      case reminderType.insurance_vehicle:
        beforeRemind = dayjs(insurance_vehicle?.end_date).subtract(
          reminderVehicleDate,
          "day"
        );
        end_date = dayjs(insurance_vehicle?.end_date);

        break;
      case reminderType.act_tax:
        beforeRemind = dayjs(act_end_date).subtract(reminderActDate, "day");
        end_date = dayjs(act_end_date);
        break;
      default:
        break;
    }

    if (
      (beforeRemind &&
        end_date &&
        currentDate.isBefore(beforeRemind) &&
        currentDate.isBefore(end_date)) ||
      (current_kilometer &&
        due_distance_kilometer &&
        due_distance_kilometer - current_kilometer > reminderDistance)
    ) {
      setStatus(statusProps[0]);
    } else if (
      (beforeRemind &&
        end_date &&
        currentDate.isAfter(beforeRemind) &&
        currentDate.isBefore(end_date)) ||
      (current_kilometer &&
        due_distance_kilometer &&
        due_distance_kilometer - current_kilometer < reminderDistance &&
        due_distance_kilometer - current_kilometer >= 0)
    ) {
      setStatus(statusProps[1]);
    } else if (
      (beforeRemind &&
        end_date &&
        currentDate.isAfter(beforeRemind) &&
        currentDate.isAfter(end_date)) ||
      (current_kilometer &&
        due_distance_kilometer &&
        due_distance_kilometer - current_kilometer < 0)
    ) {
      setStatus(statusProps[2]);
    } else if (
      !insurance_vehicle?.start_date &&
      !insurance_vehicle?.end_date &&
      !insurance_product?.start_date &&
      !insurance_product?.end_date &&
      !act_end_date &&
      !current_kilometer &&
      !due_distance_kilometer
    ) {
      setStatus(statusProps[3]);
    } else {
      setStatus(statusProps[1]);
    }
  }, [
    type,
    insurance_vehicle,
    insurance_product,
    act_end_date,
    current_kilometer,
    due_distance_kilometer,
    statusProps,
    reminderDistance,
    reminderProductDate,
    reminderVehicleDate,
    reminderActDate,
  ]);

  switch (type) {
    case reminderType.insurance_product:
    case reminderType.insurance_vehicle:
    case reminderType.act_tax:
    case reminderType.wheel:
      return (
        <Box
          sx={{
            minWidth: "185px",
            width: "100%",
            height: "58px",
            borderRadius: "8px",
            border: `1px solid ${status?.color}`,
            padding: "8px 10px 8px 10px",
            display: "flex",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="caption"
                sx={{ color: "#333333", fontWeight: 600, fontSize: 14 }}
              >
                {type}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color:
                    status?.color === "#BDBDBD" ? "#737373" : status?.color,
                  fontWeight: 400,
                  fontSize: 14,
                  marginLeft: 0.6,
                }}
              >
                {status?.text}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="caption"
                sx={{ color: "#333333", fontSize: 12, fontWeight: 400 }}
              >
                {type !== reminderType.wheel ? `วันที่สิ้นสุด` : `เกินกำหนด`}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  marginLeft: 0.6,
                  color: "#737373",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {status?.text === "ไม่มีข้อมูล"
                  ? `-`
                  : `${dayjs(
                      insurance_vehicle?.end_date ||
                        insurance_product?.end_date ||
                        act_end_date
                    ).format("DD/MM/YYYY")}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      );

    case reminderType.maintenance:
      return (
        <Box
          sx={{
            minWidth: "185px",
            width: "100%",
            height: "58px",
            borderRadius: "8px",
            border: `1px solid ${status?.color}`,
            padding: "8px 10px 8px 10px",
            display: "flex",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="caption"
                sx={{ color: "#333333", fontWeight: 600, fontSize: 14 }}
              >
                {type}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color:
                    status?.color === "#BDBDBD" ? "#737373" : status?.color,
                  fontWeight: 400,
                  fontSize: 14,
                  marginLeft: 0.6,
                }}
              >
                {status?.text}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="caption"
                sx={{ color: "#333333", fontSize: 12, fontWeight: 400 }}
              >
                เช็คระยะ
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  marginLeft: 0.6,
                  color: "#737373",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {status?.text === "ไม่มีข้อมูล"
                  ? `-`
                  : `${parseInt(
                      (current_kilometer as unknown as string) || ""
                    ).toLocaleString()} / ${parseInt(
                      due_distance_kilometer as unknown as string
                    )?.toLocaleString()}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      );

    default:
      return <Box>test1</Box>;
  }
};

export default CustomizedReminderVehicleBox;
