type Props = {
  data: any;
};

export const useDailyWorkSheetMultiplePage = ({ data }: Props) => {
  if (data) {
    const paginatedWorkLists: any[][] = [];

    let work_list: any[] = [];
    for (let i = 0; i < data.work_list.length; ++i) {
      work_list.push(data.work_list[i]);
      if (
        i % 5 === 4 ||
        (data.work_list.length - paginatedWorkLists.length * 5 < 5 &&
          i === data.work_list.length - 1)
      ) {
        paginatedWorkLists.push(work_list);
        work_list = [];
      }
      if (i === 4 && i === data.work_list.length - 1) {
        paginatedWorkLists.push(work_list);
      }
    }

    return paginatedWorkLists;
  }
  return [];
};
