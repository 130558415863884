import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import InventoryDashboard from "components/UI/Dashboard/InventoryDashboard";
import LogisticDashboard from "components/UI/Dashboard/LogisticDashboard";
import PurchaseDashboard from "components/UI/Dashboard/PurchaseDashboard";
import SalesDashboard from "components/UI/Dashboard/SalesDashboard";
import { IBreadcrumbsAndMenu } from "types/global";

const Dashboard = () => {
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: "หน้าแรก",
    },
  ];
  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <LogisticDashboard />
      <SalesDashboard />
      <InventoryDashboard />
      <PurchaseDashboard />
    </>
  );
};

export default Dashboard;
