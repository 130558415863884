import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import { useSalesAgGrid } from "hooks/Sales/use-sales-aggrid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate, formatDateTime } from "utils/Date";
import {
  numberFormatter,
  statusValueFormatter,
  vatItemTypeFormatter,
} from "utils/Formatter/Global";
import { typeValueFormatter } from "utils/Formatter/Logistic/Vehicle";

export const useQuotationReportColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const { t } = useTranslation();
  const { salesUserFilterParams } = useSalesAgGrid("quotation");

  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: t("reports.document_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
      },
      {
        field: "issue_date",
        headerName: "วันที่ออกเอกสาร",
        filter: "agDateColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
        minWidth: 200,
      },
      {
        field: "status",
        headerName: t("reports.status"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [
            "draft",
            "wait_accept",
            "accepted",
            "not_accepted",
            "finished",
            "cancelled",
          ],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
        cellRenderer: (params: ICellRendererParams) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifyContent: "flex-center",
          width: "100%",
        },
        minWidth: 200,
      },
      {
        field: "related_user_list",
        headerName: t("reports.employee_list"),
        filter: "agSetColumnFilter",
        filterParams: salesUserFilterParams,
        valueFormatter: (params: ValueFormatterParams) => {
          if (Array.isArray(params.value) && params.value.length > 0) {
            return params.value.join(", ");
          } else {
            return "-";
          }
        },
        minWidth: 200,
      },
      {
        field: "created_date",
        headerName: "วันที่สร้าง",
        filter: "agDateColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
        minWidth: 200,
      },
      // {
      //     field: "related_user_list",
      //     headerName: t("reports.employee_list"),
      //     filter: "agTextColumnFilter",
      //     filterParams: {
      //         textFormatter: (value: any) => {
      //             if (Array.isArray(value)) {
      //                 return value.join(', ').toLowerCase();
      //             }
      //             return value ? value.toLowerCase() : '';
      //         },
      //     },
      //     valueFormatter: (params: ValueFormatterParams) => {
      //         if (Array.isArray(params.value) && params.value.length > 0) {
      //             return params.value.join(', ');
      //         } else {
      //             return "-";
      //         }
      //     },
      //     minWidth: 200,
      // },

      {
        field: "customer_unique_id",
        headerName: t("reports.contact_document_id"),
        filter: "agTextColumnFilter",
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      {
        field: "customer_name",
        headerName: t("reports.contact_name"),
        filter: "agTextColumnFilter",
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      // {
      //     field: "customer_identify_no",
      //     headerName: t("reports.contact.identity_no"),
      //     filter: "agTextColumnFilter",
      //     valueFormatter: (params) => (params.value ? params.value : "-"),
      // },
      // {
      //     field: "customer_phone",
      //     headerName: t("reports.phone"),
      //     filter: "agTextColumnFilter",
      //     valueFormatter: (params) => (params.value ? params.value : "-"),
      // },
      // {
      //     field: "customer_email",
      //     headerName: t("reports.email"),
      //     filter: "agTextColumnFilter",
      //     valueFormatter: (params) => (params.value ? params.value : "-"),
      // },
      // {
      //     field: "billing_address",
      //     headerName: t("reports.billing_address"),
      //     filter: "agTextColumnFilter",
      //     valueFormatter: (params) => (params.value ? params.value : "-"),
      // },
      // {
      //     field: "billing_sub_district",
      //     headerName: t(`${t("reports.billing_sub_district")} ${t("reports.billing_address")}`),
      //     filter: "agTextColumnFilter",
      //     valueFormatter: (params) => (params.value ? params.value : "-"),
      // },
      // {
      //     field: "billing_district",
      //     headerName: t(`${t("reports.billing_district")} ${t("reports.billing_address")}`),
      //     filter: "agTextColumnFilter",
      //     valueFormatter: (params) => (params.value ? params.value : "-"),
      // },
      // {
      //     field: "billing_province",
      //     headerName: t(`${t("reports.billing_province")} ${t("reports.billing_address")}`),
      //     filter: "agTextColumnFilter",
      //     valueFormatter: (params) => (params.value ? params.value : "-"),
      // },
      // {
      //     field: "billing_postal_code",
      //     headerName: t(`${t("reports.billing_postal_code")} ${t("reports.billing_address")}`),
      //     filter: "agTextColumnFilter",
      //     valueFormatter: (params) => (params.value ? params.value : "-"),
      // },
      // {
      //     field: "billing_country",
      //     headerName: t(`${t("reports.billing_country")} ${t("reports.billing_address")}`),
      //     filter: "agTextColumnFilter",
      //     valueFormatter: (params) => (params.value ? params.value : "-"),
      // },
      {
        field: "plan_start_date",
        headerName: "วันที่เวลาเริ่มงาน",
        filter: "agDateColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          formatDateTime(params.value),
        minWidth: 200,
      },
      {
        field: "plan_end_date",
        headerName: "วันที่เวลาสิ้นสุดงาน",
        filter: "agDateColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          formatDateTime(params.value),
        minWidth: 200,
      },
      {
        field: "vehicle_type",
        headerName: t("reports.quotation.vehicle_type"),
        filter: "agSetColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          typeValueFormatter(params.value),
        filterParams: {
          values: ["head", "four_wheel", "six_wheel", "ten_wheel"],
          valueFormatter: (params: ValueFormatterParams) =>
            typeValueFormatter(params.value),
        },
        minWidth: 200,
      },
      {
        field: "container_type",
        headerName: t("reports.quotation.container_type"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      {
        field: "item_type",
        headerName: t("reports.quotation.item_type"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      {
        field: "packing_location",
        headerName: t("reports.quotation.packing_location"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      {
        field: "is_frozen",
        headerName: "Frozen",
        filter: "agSetColumnFilter",
        filterParams: {
          values: [true, false],
          valueFormatter: (params: ValueFormatterParams) =>
            params.value ? "ใช่" : "ไม่ใช่",
        },
        valueFormatter: ({ value }: { value: boolean }) => {
          switch (value) {
            case true:
              return "ใช่";
            case false:
              return "ไม่ใช่";
            default:
              return "-";
          }
        },
        minWidth: 200,
      },
      {
        field: "item_value",
        headerName: t("reports.quotation.item_value"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      {
        field: "remark",
        headerName: t("reports.remark"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      {
        field: "temperature",
        headerName: t("reports.quotation.temperature"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      {
        field: "item_unique_id",
        headerName: t("reports.quotation.order.item_unique_id"),
        filter: "agTextColumnFilter",
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      {
        field: "item_name",
        headerName: t("reports.quotation.order.item_name"),
        filter: "agTextColumnFilter",
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      {
        field: "item_sku_desc",
        headerName: t("reports.quotation.order.item_sku_desc"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      {
        field: "qty",
        headerName: t("reports.quotation.order.qty"),
        filter: false,
        sortable: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return numberFormatter(params.value);
          else return "-";
        },
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        headerClass: "ag-end-header",
        minWidth: 200,
      },
      {
        field: "uom_name",
        headerName: t("reports.quotation.order.uom_name"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      {
        field: "price_per_unit",
        headerName: t("reports.quotation.order.price_per_unit"),
        filter: false,
        sortable: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return numberFormatter(params.value);
          else return "-";
        },
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        headerClass: "ag-end-header",
        minWidth: 200,
      },
      {
        field: "discount",
        headerName: t("reports.quotation.order.discount"),
        filter: false,
        sortable: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return numberFormatter(params.value);
          else return "-";
        },
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        headerClass: "ag-end-header",
        minWidth: 200,
      },
      {
        field: "vat_percentage",
        headerName: t("reports.quotation.order.vat_percentage"),
        // sortable: false,
        filter: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return vatItemTypeFormatter(params.value);
          else return "-";
        },
        minWidth: 200,
      },
      {
        field: "post_discount_amount",
        headerName: t("reports.quotation.order.post_discount_amount"),
        filter: false,
        sortable: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return numberFormatter(params.value);
          else return "-";
        },
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        headerClass: "ag-end-header",
        minWidth: 200,
      },
      {
        field: "item_withholding_tax_value",
        headerName: t("reports.quotation.order.withholding_tax_value"),
        // sortable: false,
        filter: false,
        valueFormatter: (params) => (params.value ? params.value : "-"),
        minWidth: 200,
      },
      {
        field: "vat_exempted_amount",
        headerName: t("reports.quotation.vat_exempted_amount"),
        filter: false,
        sortable: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return numberFormatter(params.value);
          else return "-";
        },
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        headerClass: "ag-end-header",
        minWidth: 200,
      },
      {
        field: "vat_0_percent_amount",
        headerName: t("reports.quotation.vat_0_percent_amount"),
        filter: false,
        sortable: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return numberFormatter(params.value);
          else return "-";
        },
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        headerClass: "ag-end-header",
        minWidth: 200,
      },
      {
        field: "vat_7_percent_amount",
        headerName: t("reports.quotation.vat_7_percent_amount"),
        filter: false,
        sortable: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return numberFormatter(params.value);
          else return "-";
        },
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        headerClass: "ag-end-header",
        minWidth: 200,
      },
      {
        field: "vat_amount",
        headerName: t("reports.quotation.vat_amount"),
        filter: false,
        sortable: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return numberFormatter(params.value);
          else return "-";
        },
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        headerClass: "ag-end-header",
        minWidth: 200,
      },
      {
        field: "net_amount",
        headerName: t("reports.quotation.net_amount"),
        filter: false,
        sortable: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return numberFormatter(params.value);
          else return "-";
        },
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        headerClass: "ag-end-header",
        minWidth: 200,
      },
      {
        field: "withholding_tax_value",
        headerName: t("reports.quotation.withholding_tax_value"),
        filter: false,
        sortable: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return numberFormatter(params.value);
          else return "-";
        },
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        headerClass: "ag-end-header",
        minWidth: 200,
      },
      {
        field: "total_amount",
        headerName: t("reports.quotation.total_amount"),
        filter: false,
        sortable: false,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) return numberFormatter(params.value);
          else return "-";
        },
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        headerClass: "ag-end-header",
        minWidth: 200,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  return columnDefs;
};
