import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

export const usePurchaseItemListHeader = (
  documentType: string,
  disabled: boolean,
  status?: string
) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "รายการ",
        label: "No.",
        width: 75,
      },
      {
        thaiLabel: "ชื่อสินค้า",
        label: "Name",
        width: 320,
        align: "left",
        required: true,
      },
      {
        thaiLabel: "จำนวน",
        label: "Qty",
        width: 150,
        required: true,
        align: "right",
      },
      {
        thaiLabel: "หน่วย",
        label: "Unit",
        width: 100,
        align: "left",
        required: true,
      },
      {
        thaiLabel: "ราคา/หน่วย",
        label: "Price/Unit",
        width: 150,
        required: true,
        align: "right",
      },
      {
        thaiLabel: "ส่วนลด",
        label: "Discount",
        width: 150,
        align: "right",
      },

      {
        thaiLabel: "ภาษี (%)",
        label: "Vat",
        width: 100,
        align: "left",
      },
      {
        thaiLabel: "จำนวนเงิน",
        label: "Pre-vat Amount",
        width: 150,
        align: "right",
      },
      {
        thaiLabel: "ภาษีหัก ณ ที่จ่าย (%)",
        label: "Withholding Tax (%)",
        width: 150,
        align: "left",
      },
      {
        thaiLabel: "จำนวนคลัง",
        label: "Stock Qty",
        width: 100,
        align: "right",
      },
      {
        thaiLabel: "หมายเหตุรายการ",
        label: "Product Remark",
        width: 150,
        align: "left",
      },
    ];

    const rsHeaders: IHeaderTable[] = [
      {
        thaiLabel: "รายการ",
        label: "No.",
        width: 75,
      },
      {
        thaiLabel: "ชื่อสินค้า",
        label: "Name",
        width: 320,
        align: "left",
      },
      {
        thaiLabel: "จำนวนสั่งซื้อ",
        label: "Qty",
        width: 150,
        align: "right",
      },
      {
        thaiLabel: "จำนวนคืน",
        label: "Qty",
        width: 150,
        required: true,
        align: "right",
      },
      {
        thaiLabel: "หน่วย",
        label: "Unit",
        width: 100,
        align: "left",
      },
      {
        thaiLabel: "ราคา/หน่วย",
        label: "Price/Unit",
        width: 150,
        align: "right",
      },
      {
        thaiLabel: "ส่วนลด",
        label: "Discount",
        width: 150,
        align: "right",
      },

      {
        thaiLabel: "ภาษี (%)",
        label: "Vat",
        width: 100,
        align: "left",
      },
      {
        thaiLabel: "จำนวนเงิน",
        label: "Pre-vat Amount",
        width: 150,
        align: "right",
      },
      {
        thaiLabel: "ภาษีหัก ณ ที่จ่าย (%)",
        label: "Withholding Tax (%)",
        width: 150,
        align: "left",
      },
      {
        thaiLabel: "จำนวนคลัง",
        label: "Stock Qty",
        width: 100,
        align: "right",
      },
      {
        thaiLabel: "หมายเหตุรายการ",
        label: "Product Remark",
        width: 150,
        align: "left",
      },
    ];

    if (documentType === "purchase_return") {
      if (
        [null, undefined, "", "draft", "wait_returned", "cancelled"].includes(
          status
        )
      ) {
        if (!disabled)
          setHeaders([
            ...rsHeaders,
            {
              label: "",
              thaiLabel: "",
              width: 20,
            },
          ]);
        else setHeaders(rsHeaders);
      } else {
        const RSQty = {
          thaiLabel: "ส่งคืนแล้ว",
          label: "RS Qty",
          width: 100,
          align: "right" as IHeaderTable["align"],
        };

        if (!disabled) {
          const headers = [
            ...rsHeaders,
            {
              label: "",
              thaiLabel: "",
              width: 20,
            },
          ];
          headers.splice(11, 0, RSQty);
          setHeaders(headers);
        } else {
          const headers = rsHeaders;
          headers.splice(11, 0, RSQty);
          setHeaders(headers);
        }
      }
    } else {
      if (
        [null, undefined, "", "draft", "wait_ordered", "cancelled"].includes(
          status
        )
      ) {
        if (!disabled)
          setHeaders([
            ...defaultHeaders,
            {
              label: "",
              thaiLabel: "",
              width: 20,
            },
          ]);
        else setHeaders(defaultHeaders);
      } else {
        const POQty = {
          thaiLabel: "นำเข้าแล้ว",
          label: "PO Qty",
          width: 100,
          align: "right" as IHeaderTable["align"],
        };

        if (!disabled) {
          const headers = [
            ...defaultHeaders,
            {
              label: "",
              thaiLabel: "",
              width: 20,
            },
          ];
          headers.splice(10, 0, POQty);
          setHeaders(headers);
        } else {
          const headers = defaultHeaders;
          headers.splice(10, 0, POQty);
          setHeaders(headers);
        }
      }
    }
  }, [disabled, documentType, status]);

  return headers;
};
