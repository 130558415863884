import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../../types/global";
import { useTranslation } from "react-i18next";

export const usePurchaseReturnOption = (
  status?: string | null,
  disabled?: boolean
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: !disabled || false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    [disabled]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  useEffect(() => {
    switch (status) {
      case "draft":
      case "wait_returned":
        setShowOptions(true);
        break;
      default:
        setShowOptions(false);
    }
  }, [status]);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions([defaultOptions[1]]); // ยกเลิก, คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions);
        break;
    }
  }, [defaultOptions, status]);

  return { selectModifyOptions, showOptions };
};

export const usePurchaseReturnCreate = (
  status?: string | null,
  isMaxQty?: boolean
) => {
  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: t("inventory.goods_issue.index"),
        disabled: isMaxQty,
      },
    ],
    [isMaxQty, t]
  );

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [options, setOptions] = useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "finished":
        setShowOptions(true);
        break;
      default:
        setShowOptions(false);
    }
  }, [status]);

  useEffect(() => {
    switch (status) {
      default:
        setOptions(defaultOptions);
    }
  }, [defaultOptions, status]);

  return {
    selectCreateOptions: options,
    showCreateOptions: showOptions,
  };
};
