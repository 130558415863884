import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/Date";
import { dateFilterParams } from "utils/AgGridFilter";
import { statusValueFormatter } from "utils/Formatter/Global";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import { typeValueFormatter } from "utils/Formatter/Logistic/Vehicle";
import { formatNumber } from "utils/Global";

export const useDeliveryTripListColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: t("logistic.delivery_trip.unique_id"),
        filter: "agTextColumnFilter",
        // minWidth: 200,
        // flex: 1,
      },
      {
        field: "customer_unique_id",
        filter: "agTextColumnFilter",
        suppressColumnsToolPanel: true,
        hide: true,
      },
      {
        field: "issue_date",
        headerName: t("date.issue_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "customer_name",
        headerName: t("logistic.delivery_trip.customer_details.name"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "vehicle_license_plate",
        headerName: t("logistic.delivery_trip.vehicle.license_plate"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "vehicle_type",
        headerName: t("logistic.delivery_trip.vehicle.vehicle_type"),
        filter: "agSetColumnFilter",
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          typeValueFormatter(params.value),
        filterParams: {
          values: ["head", "four_wheel", "six_wheel", "ten_wheel", "trailer"],
          valueFormatter: (params: ValueFormatterParams) =>
            typeValueFormatter(params.value),
        },
      },
      {
        field: "income_net_amount",
        headerName: t("price.net_amount"),
        filter: "agNumberColumnFilter",
        minWidth: 200,
        flex: 1,
        cellStyle: { display: "flex", justifyContent: "flex-end" },
        valueFormatter: (params: ValueFormatterParams) =>
          formatNumber(params.value),
      },
      {
        field: "aggrid_status",
        headerName: t("status.index"),
        minWidth: 200,
        flex: 1,
        cellRenderer: (params: ICellRendererParams<any, string>) => {
          if (params.value === "late") {
            return <CustomizedStatus status={params.data.main_status} />;
          } else {
            return <CustomizedStatus status={params.value} />;
          }
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
        },
        filterParams: {
          values: ["finished"],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
      },
    ]);
  }, [t]);
  return columnDefs;
};
