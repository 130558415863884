import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { CustomizedBox } from "components/Custom/CustomizedBox";
import { useDisable } from "hooks/use-disable";
import {
  InventoryModelType,
  WmsUniqueIdGenerateQuery,
  useWmsUniqueIdGenerateQuery,
} from "generated/wms";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IItem } from "types/Inventory/item";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledCheckbox from "components/Controller/ControlledCheckbox";
import ControlledRadioGroup from "components/Controller/ControlledRadioGroup";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import TagList from "components/UI/TagList";
import ItemImage from "./ItemImage";

const ItemInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [disabled] = useDisable();
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<IItem>();

  const watchStatus = watch("is_active");
  const type = watch("type");

  const graphqlClientWms = createGraphQLClientWithMiddleware("wms");

  const { refetch: refetchUniqueId } =
    useWmsUniqueIdGenerateQuery<WmsUniqueIdGenerateQuery>(
      graphqlClientWms,
      {
        modelType:
          type === "normal"
            ? InventoryModelType.Item
            : InventoryModelType.Service,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate || "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  return (
    <>
      <CustomizedBox margin={"2rem 0 0 0"}>
        <Typography fontWeight={600} mb={2}>
          {t("inventory.description")}
        </Typography>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={4}>
            <Box display={"flex"} alignItems={"center"}>
              <ControlledTextField
                control={control}
                name="unique_id"
                label={t("inventory.item.unique_id")}
                error={Boolean(errors.unique_id)}
                helperText={errors.unique_id?.message}
                viewMode={disabled}
                disabled={id ? true : false}
                required
              />
              {!disabled && !id && (
                <CustomizedTooltip
                  title={`เรียก${t("inventory.item.unique_id")}`}
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateUniqueId}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              control={control}
              name="name"
              label={t("inventory.item.name")}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              viewMode={disabled}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              control={control}
              name="seller_sku"
              label={t("inventory.item.seller_sku")}
              error={Boolean(errors.seller_sku)}
              helperText={errors.seller_sku?.message}
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="description"
              label={t("inventory.item.description")}
              error={Boolean(errors.description)}
              helperText={errors.description?.message}
              viewMode={disabled}
            />
          </Grid>
        </Grid>
        <Typography fontWeight={600} my={2}>
          {t("inventory.item.usage")}
        </Typography>
        <Grid container spacing={1.5}>
          <Grid item xs={11} alignItems="center">
            <Box display="flex" alignItems="center">
              <ControlledCheckbox
                control={control}
                name="is_purchasable"
                label={t("inventory.item.is_purchasable")}
                disabled={disabled}
              />
              <ControlledCheckbox
                control={control}
                name="is_saleable"
                label={t("inventory.item.is_saleable")}
                disabled={disabled}
              />
              {type === "normal" && (
                <ControlledCheckbox
                  control={control}
                  name="is_stockable"
                  label={t("inventory.item.is_stockable")}
                  disabled={Boolean(id)}
                />
              )}
              {type === "normal" && (
                <CustomizedTooltip
                  title={
                    <Box>
                      <Typography fontSize={14}>
                        {t("inventory.sentence.stockable_information")}
                      </Typography>
                    </Box>
                  }
                  isNoMaxWidth
                  placement="right"
                >
                  <ErrorOutlineIcon
                    sx={{ color: (theme) => theme.palette.primary.main }}
                  />
                </CustomizedTooltip>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box display={"flex"} alignItems={"center"} my={2}>
          <Typography fontWeight={600}>{t("status.index")}</Typography>
          <CustomizedTooltip
            title={
              <Box>
                <Typography fontSize={14} fontWeight={600}>
                  {t("status.index") + t("status.active")}
                </Typography>
                <Typography fontSize={14}>
                  {t("inventory.sentence.active")}
                </Typography>
                <Typography fontSize={14} fontWeight={600}>
                  {t("status.index") + t("status.in_active")}
                </Typography>
                <Typography fontSize={14}>
                  {t("inventory.sentence.in_active")}
                </Typography>
              </Box>
            }
            isNoMaxWidth
            placement="right"
          >
            <ErrorOutlineIcon
              sx={{
                color: (theme) => theme.palette.primary.main,
                marginLeft: 1,
              }}
            />
          </CustomizedTooltip>
        </Box>
        <ControlledRadioGroup
          control={control}
          name="is_active"
          disabled={disabled}
          row
          radioLists={[
            {
              label: "ใช้งาน",
              value: 1,
            },
            {
              label: "หยุดใช้งาน",
              value: 0,
            },
          ]}
        />
        {(typeof watchStatus === "string"
          ? parseInt(watchStatus)
          : watchStatus) === 0 && (
          <Grid container spacing={1.5} mt={0}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <ControlledTextField
                label={t("inventory.item.remark_status")}
                control={control}
                name="remark_status"
                error={Boolean(errors.remark_status)}
                helperText={errors.remark_status?.message}
                required
                viewMode={disabled}
              />
            </Grid>
          </Grid>
        )}
        <Typography fontWeight={600} my={2}>
          {t("global.tag_list")}
        </Typography>
        <Grid container spacing={1.5}>
          <TagList
            name="tag_list"
            label={t("global.tag_list")}
            documentType={"item"}
          />
        </Grid>
      </CustomizedBox>
      {type === "normal" && (
        <CustomizedBox margin={"2rem 0 0 0"}>
          <Typography fontWeight={600} mb={2}>
            {t("global.picture")}
          </Typography>
          <Box marginTop={2}>
            <ItemImage
              control={control}
              name="img_url_list"
              setValue={setValue}
              maxSize={10}
              disabled={disabled}
              acceptedFileType="image"
              isNotRevoke
            />
          </Box>
        </CustomizedBox>
      )}
    </>
  );
};

export default ItemInfo;
