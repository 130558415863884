import * as Yup from "yup";

export const itemListSchema = Yup.object().shape({
  item_name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนมากกว่า 0")
    .required("กรุณาระบุจำนวนสินค้า"),
  discount: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
  uom_id: Yup.number().required("กรุณาเลือกหน่วย"),
  price_per_unit: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุราคาต่อหน่วยที่มากกว่า 0")
    .required("กรุณาระบุราคาต่อหน่วย"),
});
