import { GraphQLClient } from "graphql-request";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { CONTACT_VIEW } from "../../../../services/AgGrid/ContactAgGrid";
import CheckboxAggridModal from "../CheckboxAggridModal";
import useContactColumnDefs from "../../../Table/Contact/columnDefs";

type CheckedCustomerModalProps = {
  showSelectCustomer: boolean;
  closeCustomerTable: () => void;
  finishCustomersSelect: (data: any) => void;
  customerIds: string[];
  setCustomerIds: Dispatch<SetStateAction<string[]>>;
  customerIdsSnapshot: string[];
  setCustomerIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowSelection?: "single";
  onlySupplier: boolean;
};

const CheckedCustomerModal = ({
  showSelectCustomer,
  closeCustomerTable,
  finishCustomersSelect,
  customerIds,
  setCustomerIds,
  customerIdsSnapshot,
  setCustomerIdsSnapshot,
  rowSelection,
  onlySupplier
}: CheckedCustomerModalProps) => {
  const { t } = useTranslation();
  const selectCustomerGridRef = useRef();

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware("crm");

  const columnDefs = useContactColumnDefs("delivery", onlySupplier);

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        status,
        contact_type,
        tag_list,
        contact_channel_list,
        ...otherFilterModel
      } = filterModel;
      const formatFilter = status && {
        ...otherFilterModel,
        status: {
          filterType: "set",
          values: status.values.map((value: string) => parseInt(value)),
        },
        contact_type: contact_type && {
          type: "hasSome",
          filterType: "array",
          values: contact_type.values,
        },
        tag_list: tag_list && {
          type: "hasSome",
          filterType: "array",
          values: tag_list.values,
        },
        // contact_channel_list: contact_channel_list && {
        //   filterType: "objectArray",
        //   type: "some",
        //   filter: {
        //     contact_channel_info: contact_channel_list,
        //   },
        // },
      };
      try {
        const { ContactViewAggrid } = await graphQLClient.request(
          CONTACT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );

        params.success({
          rowData: ContactViewAggrid.results as any[],
          rowCount: ContactViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setFilterModel({
      status: {
        type: "set",
        values: ["1"],
      },
      contact_type: {
        type: "set",
        values: onlySupplier ? ["is_supplier"] : ["is_customer"],
      },
    });
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxAggridModal
      modalTitle={onlySupplier ? "ซัพพลายเออร์" : t("sales.customer")}
      gridRef={selectCustomerGridRef}
      height={450}
      columnDefs={columnDefs}
      rowSelection={rowSelection ?? "multiple"}
      onFinishEditing={finishCustomersSelect}
      modalIsOpen={showSelectCustomer}
      getRowId={getRowId}
      closeModal={closeCustomerTable}
      selectedIds={customerIds}
      setSelectedIds={setCustomerIds}
      idsSnapshot={customerIdsSnapshot}
      setIdsSnapshot={setCustomerIdsSnapshot}
      onGridReady={onGridReady}
    />
  );
};

export default CheckedCustomerModal;
