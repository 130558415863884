import { Box, CircularProgress, Typography } from "@mui/material";
import CustomizedButton from "components/Custom/CustomizedButton";
import { DailyWorkSheetWorkListInfo } from "components/UI/PDF/DailyWorkSheet/Body/WorkListInfo";
import { DailyWorkSheetLayout } from "components/UI/PDF/DailyWorkSheet/Layout";
import { useDeliveryTripFindUniqueQuery } from "generated/sales";
import { DeliveryTripFindUniqueQuery } from "generated/vehicle";
import { useDailyWorkSheetMultiplePage } from "hooks/Logistic/DeliveryTrip/use-daily-worksheet-multiple-page";
import { ReactInstance, useRef } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

export const DailyWorksheetPDF = () => {
  const { id } = useParams();
  const graphqlClient = createGraphQLClientWithMiddleware("sales");
  const { data, isLoading } =
    useDeliveryTripFindUniqueQuery<DeliveryTripFindUniqueQuery>(graphqlClient, {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    });
  const paginatedWorkLists = useDailyWorkSheetMultiplePage({
    data: data?.DeliveryTripFindUnique,
  });

  const componentRef = useRef<ReactInstance | null>(null);

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mx: "auto" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h5">Daily Worksheet</Typography>
        <ReactToPrint
          trigger={() => (
            <CustomizedButton title="พิมพ์เอกสาร" variant="outlined" />
          )}
          content={() => componentRef.current}
          documentTitle={data?.DeliveryTripFindUnique?.unique_id || ""}
        />
      </Box>
      <Box ref={componentRef} sx={{ m: "auto" }}>
        {paginatedWorkLists.map((item, index) => (
          <DailyWorkSheetLayout
            index={index}
            isLast={
              index === paginatedWorkLists.length - 1 ||
              paginatedWorkLists.length === 1
            }
            totalPages={paginatedWorkLists.length}
            data={data?.DeliveryTripFindUnique}
            isMultiplePage={paginatedWorkLists.length !== 1}
          >
            <DailyWorkSheetWorkListInfo data={item} />
          </DailyWorkSheetLayout>
        ))}
      </Box>
    </Box>
  );
};
