import { useDisable } from "hooks/use-disable";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IHeaderTable } from "types/global";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";

export const useWorkListHeader = () => {
  const { watch } = useFormContext<IDeliveryTrip>();
  const { t } = useTranslation();
  const [headers, setHeader] = useState<IHeaderTable[]>([]);
  const [disabled] = useDisable();

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;
  const isFinished = status === "finished";

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: t("logistic.work_list.location_unique_id"),
        width: 154,
        align: "left",
        required: true,
      },
      {
        thaiLabel: t("logistic.work_list.location_name"),
        width: 134,
        align: "left",
        required: true,
      },
      {
        thaiLabel: t("logistic.work_list.type"),
        width: 84,
        align: "left",
        required: true,
      },
      {
        thaiLabel: t("logistic.work_list.distance"),
        width: 104,
        align: "right",
        required: true,
      },
      {
        thaiLabel: t("logistic.work_list.coordinator_name"),
        width: 134,
        align: "left",
        required: true,
      },
      {
        thaiLabel: t("logistic.work_list.coordinator_phone"),
        width: 144,
        align: "left",
        required: true,
      },
      {
        thaiLabel: t("logistic.work_list.special_request"),
        width: 134,
        align: "left",
      },
      {
        thaiLabel: t("logistic.work_list.customer_name"),
        width: 134,
        align: "left",
      },
      {
        thaiLabel: t("logistic.work_list.reference_code"),
        width: 154,
        align: "left",
      },
      {
        thaiLabel: t("logistic.work_list.seal_no"),
        width: 154,
        align: "left",
      },
      // {
      //   thaiLabel: t("logistic.work_list.start_date"),
      //   width: 178,
      //   align: "left",
      // },
      {
        thaiLabel: t("logistic.work_list.end_date"),
        width: 184,
        align: "left",
      },
      {
        thaiLabel: t("logistic.work_list.arrived_kilometer"),
        width: 134,
        align: "left",
      },
      {
        thaiLabel: t("logistic.work_list.shipping_cost"),
        width: 104,
        align: "right",
      },
      {
        thaiLabel: t("logistic.work_list.secondary_driver_cost"),
        width: 134,
        align: "right",
      },
      {
        thaiLabel: t("logistic.work_list.drop_cost"),
        width: 104,
        align: "right",
      },
      {
        thaiLabel: t("logistic.work_list.max_shipping_cost"),
        width: 104,
        align: "right",
        required: true,
      },
    ];

    if (!disabled && !isFinished) {
      setHeader([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 35,
        },
      ]);
    } else if (isFinished) {
      const newHeader: IHeaderTable[] = [
        {
          thaiLabel: t("logistic.work_list.location_unique_id"),
          width: 154,
          align: "left",
          required: true,
        },
        {
          thaiLabel: t("logistic.work_list.location_name"),
          width: 134,
          align: "left",
          required: true,
        },
        {
          thaiLabel: t("logistic.work_list.type"),
          width: 84,
          align: "left",
          required: true,
        },
        {
          thaiLabel: t("logistic.work_list.distance"),
          width: 104,
          align: "right",
          required: true,
        },
        {
          thaiLabel: t("logistic.work_list.shipping_cost"),
          width: 104,
          align: "right",
          required: true,
        },
        {
          thaiLabel: t("logistic.work_list.secondary_driver_cost"),
          width: 134,
          align: "right",
        },
        {
          thaiLabel: t("logistic.work_list.drop_cost"),
          width: 104,
          align: "right",
        },
        {
          thaiLabel: t("logistic.work_list.max_shipping_cost"),
          width: 104,
          align: "right",
          required: true,
        },
        {
          thaiLabel: t("logistic.work_list.coordinator_name"),
          width: 134,
          align: "left",
          required: true,
        },
        {
          thaiLabel: t("logistic.work_list.coordinator_phone"),
          width: 144,
          align: "left",
          required: true,
        },
        {
          thaiLabel: t("logistic.work_list.special_request"),
          width: 134,
          align: "left",
        },
        {
          thaiLabel: t("logistic.work_list.customer_name"),
          width: 134,
          align: "left",
        },
        {
          thaiLabel: t("logistic.work_list.reference_code"),
          width: 154,
          align: "left",
        },
        {
          thaiLabel: t("logistic.work_list.seal_no"),
          width: 154,
          align: "left",
        },
        // {
        //   thaiLabel: t("logistic.work_list.start_date"),
        //   width: 178,
        //   align: "left",
        // },
        {
          thaiLabel: t("logistic.work_list.end_date"),
          width: 184,
          align: "left",
        },
        {
          thaiLabel: t("logistic.work_list.arrived_kilometer"),
          width: 134,
          align: "left",
        },
      ];
      setHeader(newHeader);
    } else {
      setHeader(defaultHeaders);
    }
  }, [disabled, isFinished, t]);

  return headers;
};
