import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ModalUI from "../ModalUI";
import { Divider } from "@mui/material";
import { VehicleType } from "generated/sales";

interface ModalComponentProps {
  open: boolean;
  handleClose: () => void;
  data: any;
}

const DeliveryCarInfoModal: React.FC<ModalComponentProps> = ({
  open,
  handleClose,
  data,
}) => {
  const getVehicleTypeName = (type: any) => {
    switch (type) {
      case VehicleType.FourWheel:
        return "4 ล้อ";
      case VehicleType.Head:
        return "หัวลาก";
      case VehicleType.SixWheel:
        return "6 ล้อ";
      case VehicleType.TenWheel:
        return "10 ล้อ";
      default:
        return type;
    }
  };

  return (
    <>
      {data && (
        <ModalUI
          open={open}
          handleClose={handleClose}
          startIcon={
            <Box display="flex">
              <Typography fontSize="24px" fontWeight={600}>
                รายละเอียดรถ
              </Typography>
            </Box>
          }
          closeIconSize={16}
        >
          <Divider />
          <Box fontSize={"14px"} mt={1}>
            <Box display="flex">
              <Typography flex={0.5}>รหัสรถ</Typography>
              <Typography flex={1}>
                {data.car.id ? data.car.id : "-"}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography flex={0.5}>หมายเลขทะเบียนรถ</Typography>
              <Typography flex={1}>
                {data.car.license ? data.car.license : "-"}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography flex={0.5}>ประเภทรถ</Typography>
              <Typography flex={1}>
                {data.car.type ? getVehicleTypeName(data.car.type) : "-"}
              </Typography>
            </Box>
          </Box>
        </ModalUI>
      )}
    </>
  );
};

export default DeliveryCarInfoModal;
