import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Grid, Stack } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledAddressBox from "../../Controller/ControlledAddressBox";
import ControlledSelect from "../../Controller/ControlledSelect";
import { useForm, useFormContext } from "react-hook-form";
import { countryOptions } from "../../../utils/Global";
import { useEffect, useState } from "react";
import { IBillingAddress } from "../../../types/global";

type Props = {
  open: boolean;
  handleClose: () => void;
  viewMode: boolean;
  name: string;
};

const BillingAddressModal = ({ open, handleClose, viewMode, name }: Props) => {
  const { setValue: setContextValue, getValues: getContextValues } =
    useFormContext();
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<IBillingAddress>({
    defaultValues: {
      name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  });

  const onSubmitHandler = (data: IBillingAddress) => {
    setContextValue(`${name}.billing_address`, data);
    handleClose();
  };

  const [addressValue, setAddressValue] = useState<any>("");

  useEffect(() => {
    if (open) {
      const currentAddress = getContextValues(`${name}.billing_address`);
      setAddressValue(currentAddress);
      reset(currentAddress);
    }
  }, [getContextValues, name, open, reset]);

  const { t } = useTranslation();
  return (
    <ModalUI
      open={open}
      handleClose={handleClose}
      title={
        name === "ma_details"
          ? t("logistic.maintenance.ma_details.billing_address")
          : t("address.billing_address")
      }
      maxWidth="md"
      action={
        <Stack direction={"row"} gap={1}>
          <CustomizedButton
            title={t("button.cancel")}
            onClick={handleClose}
            variant="outlined"
          />
          <CustomizedButton
            title={t("button.confirm")}
            onClick={handleSubmit(onSubmitHandler)}
            variant="contained"
          />
        </Stack>
      }
    >
      <Grid container spacing={1.5} mt={0.1}>
        <Grid item xs={12}>
          <ControlledTextField
            name={`name`}
            control={control}
            label={t("contact.address.index")}
            error={Boolean(errors.name)}
            helperText={errors.name && errors.name?.message}
            rows={4}
            multiline
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="sub_district"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="district"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="province"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledAddressBox
            type="postal_code"
            getValues={getValues}
            setValue={setValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            viewMode={viewMode}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSelect
            name={`country`}
            control={control}
            label={t("contact.address.country")}
            error={Boolean(errors.country)}
            helperText={errors.country && errors.country?.message}
            options={countryOptions}
            disabled={true}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default BillingAddressModal;
