import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import ModalUI from "components/UI/Modal/ModalUI";
import CustomizedButton from "components/Custom/CustomizedButton";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import ControlledTextField from "components/Controller/ControlledTextField";

import { IExternalProps } from "types/Sales";

interface Props {
  open: boolean;
  handleClose: () => void;
  action: (data: IExternalProps) => void;
}

const ExternalReferenceModal = ({ open, handleClose, action }: Props) => {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm<IExternalProps>({
    defaultValues: {
      external_reference_no: "",
    },
  });

  return (
    <ModalUI
      title={t("sales.external_reference_no")}
      open={open}
      handleClose={handleClose}
      maxWidth="xs"
      fullWidth
      action={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={() => {
              handleClose();
              reset();
            }}
          />
          <CustomizedButton
            title={t("button.confirm")}
            variant="contained"
            onClick={handleSubmit(action)}
          />
        </Box>
      }
    >
      <Box display="flex" gap={1}>
        <Typography mb={2}>เลขที่เอกสาร</Typography>
        <CustomizedTooltip
          title={
            <Box>
              <Typography fontSize={14}>
                การอ้างอิงเอกสารไม่มีผลต่อสถานะของเอกสาร
              </Typography>
              <Typography fontSize={14}>
                เพียงแต่จะมีเลขอ้างอิงเพิ่มขึ้นในหน้าเอกสาร
              </Typography>
            </Box>
          }
          isNoMaxWidth
          placement="right"
        >
          <ErrorOutlineIcon color="primary" fontSize="small" />
        </CustomizedTooltip>
      </Box>
      <ControlledTextField
        control={control}
        name="external_reference_no"
        placeholder={t("sentence.unique_id")}
      />
    </ModalUI>
  );
};

export default ExternalReferenceModal;
