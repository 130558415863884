import { useFieldArray, useFormContext } from "react-hook-form";
import { IContact, IContactPerson } from "../../../types/Contact/contact";
import { useTranslation } from "react-i18next";
import PersonCard from "../../UI/PersonCard";
import CustomizedButton from "../../Custom/CustomizedButton";
import { Box, Grid, Typography } from "@mui/material";
import { useModal } from "../../../hooks/use-modal";
import PersonModal from "../../UI/Modal/PersonModal";
import { useDisable } from "../../../hooks/use-disable";

const ContactPersonList = () => {
  const { control } = useFormContext<IContact>();
  const { t } = useTranslation();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "contact_person_list",
  });

  const [disabled] = useDisable();

  const addContactPersonHandler = (data: IContactPerson) => {
    append(data);
  };

  const { modal, openModalHandler, closeModalHandler } = useModal();

  return (
    <>
      <Box my={2}>
        <Grid container spacing={1.5}>
          {fields.length > 0 &&
            fields.map((person, index) => (
              <PersonCard
                key={`${person.id}`}
                person={person}
                index={index}
                remove={remove}
                update={update}
              />
            ))}
        </Grid>
        {disabled && fields.length === 0 && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            pt={3}
          >
            <Typography>ไม่มีข้อมูล</Typography>
          </Box>
        )}
      </Box>
      {!disabled && fields.length > 0 && fields.length < 12 && (
        <CustomizedButton
          startAddIcon
          title={`${t("button.add")}${t("contact.contact_person.index")}`}
          onClick={openModalHandler}
        />
      )}
      {!disabled && fields.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomizedButton
            startAddIcon
            variant="outlined"
            title={`${t("button.add")}${t("contact.contact_person.index")}`}
            onClick={openModalHandler}
          />
        </Box>
      )}
      <PersonModal
        open={modal}
        closeModalHandler={closeModalHandler}
        onAddHandler={addContactPersonHandler}
        isCreate
      />
    </>
  );
};

export default ContactPersonList;
