import dayjs from "dayjs";

// import { ITEM_SKU_DETAIL_AGGRID } from "../../../services/AgGrid/InventoryAggrid";
import { v4 as uuidv4 } from "uuid";
import { IPurchaseItemList } from "../../../types/Purchase";
import { IPurchaseOrder } from "../../../types/Purchase/purchaseOrder";
import { purchaseOrderSchema } from "components/Form/Purchase/Order/schema";
import { IUser } from "types/Auth/user";
import { IPurchaseReturn } from "types/Purchase/purchaseReturn";
import {
  PurchaseDocumentType,
  PurchaseModelType,
  ReferenceDocumentType,
  Traceability,
} from "generated/purchase";
import { purchaseReturnSchema } from "components/Form/Purchase/Return/schema";
import { ITraceEntry } from "types/Inventory";
import { IGoodsReceive } from "types/Inventory/goodsReceive";
import { TraceEntryType } from "generated/wms";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { CONTACT_VIEW } from "services/AgGrid/ContactAgGrid";

export const purchaseOrderCreatePayloadFormatter = (
  data: IPurchaseOrder,
  status: string
) => {
  const { item_list, tag_list, created_date, supplier_details, ...otherData } =
    data;
  const formatItemList = item_list.map(
    ({ reference_line_item, item_sku_qty, ...otherItem }) => ({
      ...otherItem,
      reference_unique_id: data.unique_id,
      reference_document_type: ReferenceDocumentType.PurchaseOrder,
      reference_line_item: {
        ...reference_line_item,
        line_item_unique_id: otherItem.unique_id,
      },
    })
  );

  const { unique_id_name, ...new_supplier_details } = supplier_details;

  const formatPayload = {
    ...otherData,
    supplier_details: new_supplier_details,
    item_list: formatItemList,
    main_status: status,
    sub_status: status,
  };
  return formatPayload;
};

export const purchaseOrderUpdatePayloadFormatter = async (
  data: IPurchaseOrder,
  status: string
) => {
  const {
    id,
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    last_updated_date,
    item_list,
    tag_list,
    created_by,
    created_date,
    supplier_details,
    destination_warehouse,
    ...otherData
  } = data;

  const formatItemList = item_list.map(
    ({ item_sku_qty, reference_line_item, ...otherItem }) => ({
      ...otherItem,
      reference_unique_id: unique_id,
      reference_document_type: ReferenceDocumentType.PurchaseOrder,
      reference_line_item: {
        ...reference_line_item,
        line_item_unique_id: otherItem.unique_id,
      },
    })
  );

  const { unique_id_name, ...new_supplier_details } = supplier_details;

  const formatPayload = {
    ...otherData,
    supplier_details: new_supplier_details,
    item_list: formatItemList,
    main_status: status,
    sub_status: status,
    flag_status: flag_status,
  };
  return formatPayload;
};

export const purchaseOrderQueryFormatter = (data: any) => {
  let formatItemList: IPurchaseItemList[] = [];
  if (data) {
    if (data.item_list && data.item_list.length > 0) {
      data.item_list.forEach((item: IPurchaseItemList) => {
        const itemType = item as IPurchaseItemList;

        formatItemList.push({
          ...itemType,
          // uom_group: itemSkuDetails[foundItemIndex]?.item.uom_group,
          // item_sku_qty: itemSkuDetails[foundItemIndex]?.item_sku_qty,
        });
      });
    }

    const formatPayload = {
      ...data,
      item_list: formatItemList,
      created_date: data.created_date ? dayjs(data.created_date) : undefined,
      issue_date: data.issue_date ? dayjs(data.issue_date) : undefined,
      due_date: data.due_date ? dayjs(data.due_date) : undefined,
      expected_date: data.due_date ? dayjs(data.expected_date) : undefined,
      within_date: data.due_date ? dayjs(data.within_date) : undefined,
      supplier_details: data.supplier_unique_id
        ? {
            ...data.supplier_details,
            unique_id_name: `${data.supplier_unique_id} - ${data.supplier_details.name}`,
          }
        : {},
    } as IPurchaseOrder;

    return formatPayload;
  }
};

export const copyPurchaseOrderFormatter = async (
  data: IPurchaseOrder,
  currentUser: IUser | null
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      due_date,
      expected_date,
      within_date,
      created_by,
      reference_no,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      destination_warehouse,
      related_user_list,
      ...otherData
    } = data;

    const graphqlClient = createGraphQLClientWithMiddleware("crm");

    const { ContactViewAggrid } = await graphqlClient.request(CONTACT_VIEW, {
      aggridInput: {
        startRow: 0,
        endRow: 1,
        filterModel: {
          unique_id: {
            filterType: "text",
            type: "contains",
            filter: data.supplier_unique_id,
          },
        },
      },
    });

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(
            ({
              id,
              unique_id,
              po_qty,
              wait_receive_qty,
              rs_qty,
              ...otherItemList
            }) => ({
              ...otherItemList,
              unique_id: uuidv4(),
            })
          )
        : [];

    const formatRelatedUserList =
      // related_user_list.filter((user) => user.user_unique_id !== currentUser?.unique_id)
      created_by?.user_unique_id !== currentUser?.unique_id
        ? [created_by, ...(related_user_list || [])]
        : related_user_list;

    return {
      ...purchaseOrderSchema,
      ...otherData,
      created_by: {
        user_unique_id: currentUser?.unique_id,
        email: currentUser?.email,
        first_name: currentUser?.first_name,
        last_name: currentUser?.last_name,
        img_url: currentUser?.img_url,
      },
      credit_day: ContactViewAggrid?.results?.[0].payment_duration || 0,
      related_user_list: formatRelatedUserList,
      copied_id: id,
      copied_unique_id: unique_id,
      item_list: formatItemList,
    };
  }
};

export const createPurchaseReturnFromPurchaseOrder = (
  data: IPurchaseOrder
): IPurchaseReturn | undefined => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      expected_date,
      within_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      reference_no,
      tag_list,
      net_amount,
      post_discount_amount,
      pre_vat_amount,
      price_vat_type,
      vat_0_percent_amount,
      vat_7_percent_amount,
      vat_amount,
      vat_exempted_amount,
      withholding_tax_amount,
      total_amount,
      shipping_cost,
      additional_discount,
      additional_discount_type,
      destination_warehouse,
      destination_warehouse_id,
      tag_id_list,
      ...otherData
    } = data;

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(
            ({
              id,
              reference_unique_id,
              unique_id,
              rs_qty,
              wait_receive_qty,
              ...otherItemList
            }) => ({
              ...otherItemList,
              unique_id: uuidv4(),
              reference_unique_id: "",
              reference_document_type: ReferenceDocumentType.PurchaseReturn,
              reference_line_item: {
                line_item_unique_id: unique_id,
                line_item_document_unique_id: data.unique_id || "",
                line_item_document_type: PurchaseDocumentType.PurchaseOrder,
              },
              qty: 0,
              po_qty: otherItemList.qty,
            })
          )
        : [];

    return {
      ...otherData,
      ...purchaseReturnSchema,
      reference_document_list: [
        {
          document_id: data.id,
          document_unique_id: data.unique_id,
          document_type: PurchaseModelType.PurchaseOrder,
        },
      ],
      item_list: formatItemList,
    } as IPurchaseReturn;
  }
};

export const formatPurchaseItemListToGoodsReceiveItemList = (
  data: IPurchaseOrder
): ITraceEntry[] => {
  const formatData: ITraceEntry[] = data.item_list.map((item) => ({
    unique_id: uuidv4(),
    reference_unique_id: "",
    type: TraceEntryType.GoodReceive,
    item_unique_id: item.item_unique_id || "",
    item_name: item.item_name || "",
    item_img_url: item.item_img_url || [],
    qty: 0,
    document_item_qty: item.qty,
    posted_qty: item.po_qty,
    uom_id: item.uom_id || 0,
    uom: item.uom_name,
    remark: "",
    po_reference_unique_id: data.unique_id,
    reference_line_item: {
      line_item_unique_id: item.unique_id,
      line_item_document_unique_id: data.unique_id || "",
      line_item_document_type: PurchaseDocumentType.PurchaseOrder,
    },
    traceability: (item.traceability as Traceability) || Traceability.Normal,
    barcode: item.barcode,
    status: item.traceability === "serial" ? undefined : "is_active",
    stock_qty: item.stock_qty,
  }));

  return formatData as ITraceEntry[];
};

export const createGoodsReceiveFromPurchaseOrder = (
  data: IPurchaseOrder,
  currentUser: IUser | null
): IGoodsReceive => {
  const formatData = {
    unique_id: "",
    type: "purchase",
    reference_unique_id_list: [data.unique_id],
    remark: "",
    trace_entry_list: formatPurchaseItemListToGoodsReceiveItemList(data),
    created_by: {
      user_unique_id: currentUser?.unique_id,
      email: currentUser?.email,
      first_name: currentUser?.first_name,
      last_name: currentUser?.last_name,
      img_url: currentUser?.img_url,
    },
    main_status: "",
    aggrid_status: "",
    flag_status: [],
  };

  return formatData as IGoodsReceive;
};
