import { Cookies } from "react-cookie";
import React, { useEffect } from "react";
import { GraphQLClient, gql } from "graphql-request";
import { useStateContext } from "../contexts/auth-context";
import {
  ValidateTokenQuery,
  useValidateTokenQuery,
} from "../generated/general";
import { IUser } from "../types/Auth/user";
import { useNavigate } from "react-router";
import jwtDecode from "jwt-decode";
import { IToken } from "../types/Auth/token";
import { useLocation } from "react-router-dom";
import { useConfirmation } from "../hooks/use-confirmation";
import ExpiredConfirmation from "../components/UI/Modal/ExpiredConfirmation";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { Box, CircularProgress } from "@mui/material";

const cookies = new Cookies();

export const REFRESH_ACCESS_TOKEN = gql`
  mutation {
    refreshToken {
      access_token
    }
  }
`;

type AuthMiddlewareProps = {
  children: React.ReactElement;
};

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { confirmation, closeConfirmationHandler, openConfirmationHandler } =
    useConfirmation();

  const { dispatch } = useStateContext();

  // const [cookies, setCookie, removeCookie] = useCookies([
  //   "access_token",
  //   "refresh_token",
  //   "logged_in",
  // ]);

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware(
    "company-user",
    false
  );

  const { refetch, isLoading, isFetching, isError } = useValidateTokenQuery<
    ValidateTokenQuery,
    Error
  >(
    graphQLClientWithHeaderCompany,
    {},
    {
      retry: 1,
      enabled: cookies.get("logged_in"),
      // Boolean(sessionStorage.getItem("tenant-id")),
      staleTime: 3600 * 1000,
      onSuccess: (data) => {
        //TODO: set tenant id
        dispatch({
          type: "SET_USER",
          payload: data.validateToken as IUser,
        });
      },
      async onError(error: any) {
        const refresh_token = cookies.get("refresh_token");
        if (refresh_token) {
          for (const err of error.response.errors) {
            if (err.message.includes("Unauthorized")) {
              try {
                const { refreshToken } = await graphQLClient.request(
                  REFRESH_ACCESS_TOKEN
                );
                const { access_token } = refreshToken;
                const { exp: accessMaxAge } = jwtDecode<IToken>(access_token);
                cookies.set("access_token", access_token, {
                  path: "/",
                  maxAge: accessMaxAge,
                });
                await refetch();
              } catch (refreshError) {
                console.log("error here");
                cookies.remove("access_token");
                cookies.remove("refresh_token");
                cookies.set("logged_in", "false", { path: "/" });
                navigate("/login");
              }
            }
          }
        } else {
          openConfirmationHandler();
        }
      },
    }
  );

  useEffect(() => {
    if (!cookies.get("access_token") && cookies.get("logged_in")) {
      refetch();
    }
  }, [refetch, location]);

  const loading =
    isLoading || isFetching || (isError && cookies.get("refresh_token"));

  const logoutHandler = () => {
    cookies.remove("access_token", {
      path: "/",
    });
    cookies.remove("refresh_token", {
      path: "/",
    });
    cookies.set("logged_in", "false", {
      path: "/",
    });
    sessionStorage.removeItem("tenant-id");
    sessionStorage.removeItem("company-info");
    dispatch({
      type: "SET_USER",
      payload: null,
    });
    closeConfirmationHandler();
    navigate("/login", { replace: true });
  };

  if (!confirmation && loading && cookies.get("logged_in")) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {children}
      <ExpiredConfirmation open={confirmation} logout={logoutHandler} />
    </>
  );
};

export default AuthMiddleware;
