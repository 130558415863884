import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MaintenanceTable from "components/Table/Logistic/Maintenance";

const MaintenanceTab = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">
          {t("logistic.vehicle.maintenance")}
        </Typography>
      </Box>
      <MaintenanceTable status={""} vehicleId={id} />
    </>
  );
};

export default MaintenanceTab;
