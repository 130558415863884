import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  styled,
} from "@mui/material";
import { forwardRef } from "react";
import { ISelectOption } from "../../types/global";

type ExtendedSelectProps = SelectProps & {
  options: ISelectOption[];
  helperText?: any;
  required?: boolean;
  helperTextSize?: number;
};

const StyledFormControl = styled(FormControl)<FormControlProps>(
  ({ theme, error }) => ({
    ...{
      "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
        {
          borderColor: error ? theme.palette.error.main : undefined,
        },
      "& .MuiInputLabel-root.Mui-disabled": {
        color: error ? theme.palette.error.main : undefined,
      },
    },
  })
);

const CustomizedSelect = forwardRef<HTMLInputElement, ExtendedSelectProps>(
  (
    {
      id,
      name,
      label,
      value,
      onChange,
      options,
      disabled,
      error,
      helperText,
      sx,
      color,
      renderValue,
      defaultValue,
      required,
      helperTextSize,
      inputRef,
      inputProps,
      MenuProps,
      placeholder,
      endAdornment,
    },
    ref
  ) => {
    return (
      <StyledFormControl
        size="small"
        fullWidth
        disabled={disabled}
        error={error}
        ref={ref}
        color={color}
      >
        <InputLabel id="demo-simple-select-label" required={required}>
          {label}
        </InputLabel>
        <Select
          id={id}
          error={error}
          name={name}
          value={value || value === 0 ? value : ""}
          label={label}
          onChange={onChange}
          inputRef={inputRef}
          sx={sx}
          size="small"
          renderValue={renderValue}
          defaultValue={defaultValue || ""}
          required={required}
          inputProps={inputProps}
          MenuProps={MenuProps}
          placeholder={placeholder}
          endAdornment={endAdornment}
        >
          {options &&
            options.map((option) => {
              return (
                <MenuItem value={option.value} key={option.label}>
                  {option.label}
                </MenuItem>
              );
            })}
        </Select>
        {helperText && (
          <FormHelperText sx={{ fontSize: helperTextSize }}>
            {helperText}
          </FormHelperText>
        )}
      </StyledFormControl>
    );
  }
);

export default CustomizedSelect;
