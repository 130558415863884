import { Box, Divider, Stack, Typography } from "@mui/material";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import Chart from "react-google-charts";
import { useTranslation } from "react-i18next";
import EmptyChart from "../EmptyChart";
import { handleChartReady } from "utils/Global";
import { Link } from "react-router-dom";

type Props = {
  data: any;
};

const BillingNoteChart = ({ data }: Props) => {
  const { t } = useTranslation();

  const formatData = [
    [
      t("sales.billing_note.index"),
      t("status.draft"),
      t("status.wait_payment"),
      t("status.fully_payment"),
      t("status.cancelled"),
    ],
    [
      t("status.index"),
      data?.draft || 0,
      data?.wait_payment || 0,
      data?.fully_payment || 0,
      data?.cancelled || 0,
    ],
  ];

  const options = {
    chartArea: {
      width: "100%",
      height: "25%",
      top: 16,
    },
    isStacked: true,
    hAxis: { textPosition: "none", gridlines: { count: 0 } },
    vAxis: { textPosition: "none", gridlines: { count: 0 } },
    baselineColor: "transparent",
    legend: {
      position: "bottom",
      alignment: "start",
      textStyle: {
        fontName: "Kanit",
        fontSize: 12,
        color: "#737373",
      },
    },
    tooltip: {
      textStyle: {
        fontName: "Kanit",
        fontSize: 12,
        color: "#737373",
      },
    },
    bar: { groupWidth: "100%" },
    colors: ["#E2E2E2", "#ED6C02", "#4CAF50", "#737373"],
  };

  const total =
    (data?.draft ?? 0) +
    (data?.wait_payment ?? 0) +
    (data?.fully_payment ?? 0) +
    (data?.cancelled ?? 0);

  return (
    <CustomizedBox
      height={"100%"}
      border={`1px solid white`}
      margin={"0"}
      padding={"0.5rem 1rem"}
      bgcolor={"white"}
      boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.05)"}
    >
      <Typography fontSize={16} fontWeight={600}>
        {t("sales.billing_note.index")}
      </Typography>
      {total === 0 ? (
        <EmptyChart />
      ) : (
        <>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            mt={2}
          >
            <Link
              to={"/sales/billing-note?filter=wait_payment"}
              style={{ textDecoration: "none", flex: 1 }}
            >
              <Box sx={{ cursor: "pointer" }}>
                <Typography align="center" variant="h5" color={"primary.main"}>
                  {data?.wait_payment || 0}
                </Typography>
                <Typography align="center" color={"#737373"}>
                  {t("status.wait_payment")}
                </Typography>
              </Box>
            </Link>
            <Link
              to={"/sales/billing-note?filter=fully_payment"}
              style={{ textDecoration: "none", flex: 1 }}
            >
              <Box sx={{ cursor: "pointer" }}>
                <Typography align="center" variant="h5" color={"primary.main"}>
                  {data?.fully_payment || 0}
                </Typography>
                <Typography align="center" color={"#737373"}>
                  {t("status.fully_payment")}
                </Typography>
              </Box>
            </Link>
          </Stack>
          <Chart
            chartType="BarChart"
            width={"100%"}
            height={100}
            data={formatData}
            options={options}
            chartEvents={[
              {
                eventName: "ready",
                callback: ({ chartWrapper }: { chartWrapper: any }) =>
                  handleChartReady(chartWrapper),
              },
            ]}
          />
        </>
      )}
    </CustomizedBox>
  );
};

export default BillingNoteChart;
