import { Box, Grid, Typography } from "@mui/material";
import { ChangeEventHandler, FC, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { FileRejection } from "react-dropzone";
import { useSnackbar } from "notistack";
import { FileError } from "react-dropzone";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { IAttachment, IMenuOption } from "../../types/global";
import dayjs from "dayjs";
import { useStateContext } from "../../contexts/auth-context";
import AttachmentCard from "./AttachmentCard";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  borderWidth: 2,
  borderRadius: 14,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
  height: 120,
} as const;

const activeStyle = {
  borderColor: "#2196f3",
} as const;

const acceptStyle = {
  borderColor: "#00e676",
} as const;

const rejectStyle = {
  borderColor: "#ff1744",
} as const;

const DocumentDropzoneUI: FC<{
  control: Control;
  name: string;
  setValue: UseFormSetValue<any>;
  multiple?: boolean;
  options?: IMenuOption[];
  acceptedFileType?: string;
  maxSize?: number;
  maxFileSize?: number;
  disabled?: boolean;
  isModal?: boolean;
  fullWidth?: boolean;
  customGridSize?: number;
  separateAttachmentDate?: boolean;
  gridDropzone?: boolean;
}> = ({
  control,
  name,
  setValue,
  multiple,
  options,
  acceptedFileType,
  maxSize,
  maxFileSize,
  disabled,
  isModal,
  fullWidth,
  customGridSize,
  separateAttachmentDate,
  gridDropzone,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <Dropzone
          control={control}
          name={name}
          setValue={setValue}
          multiple={multiple}
          onChange={(e) => {
            if (multiple) {
              onChange(e.target.files);
            } else {
              onChange(e.target.files?.[0] ?? null);
            }
          }}
          options={options}
          acceptedFileType={acceptedFileType}
          maxSize={maxSize}
          disabled={disabled}
          maxFileSize={maxFileSize}
          isModal={isModal}
          fullWidth={fullWidth}
          customGridSize={customGridSize}
          separateAttachmentDate={separateAttachmentDate}
          gridDropzone={gridDropzone}
        />
      )}
    />
  );
};

const Dropzone: FC<{
  control: Control;
  name: string;
  setValue: UseFormSetValue<any>;
  multiple?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  options?: IMenuOption[];
  maxSize?: number;
  maxFileSize?: number;
  acceptedFileType?: string;
  isModal?: boolean;
  fullWidth?: boolean;
  customGridSize?: number;
  separateAttachmentDate?: boolean;
  gridDropzone?: boolean;
}> = ({
  control,
  name,
  setValue,
  multiple,
  onChange,
  disabled,
  options,
  acceptedFileType,
  maxSize,
  maxFileSize,
  isModal,
  fullWidth,
  customGridSize,
  separateAttachmentDate,
  gridDropzone,
}) => {
  const getAcceptFileType = (
    fileType: string | undefined
  ): {
    "image/jpg"?: any[];
    "image/jpeg"?: any[];
    "image/png"?: any[];
    "application/pdf"?: any[];
  } => {
    return {
      "image/jpg": [],
      "image/jpeg": [],
      "image/png": [],
      "application/pdf": [],
    };
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    state: { authUser },
  } = useStateContext();

  const files: any[] =
    useWatch({
      control,
      name,
    }) || [];

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: maxSize,
    accept: getAcceptFileType(acceptedFileType),
    maxSize: maxFileSize ? maxFileSize : 10485760,
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      fileRejections.forEach((file: any) => {
        file.errors.forEach((err: FileError) => {
          if (err.code === "file-invalid-type") {
            enqueueSnackbar("ประเภทไฟล์ไม่ถูกต้อง", {
              variant: "error",
            });
          }
          if (err.code === "file-too-large") {
            enqueueSnackbar(
              "ไม่สามารถอัปโหลดได้\nเนื่องจากไฟล์มีขนาดใหญ่เกิน 10MB",
              {
                variant: "error",
                style: { whiteSpace: "pre-line" },
              }
            );
          }
          if (err.code === "too-many-files") {
            enqueueSnackbar(`อัปโหลดได้สูงสุด ${maxSize} ไฟล์`, {
              variant: "error",
            });
          }
        });
      });

      const newFiles: (File & IAttachment)[] =
        (!!files?.length && [...files].concat(acceptedFiles)) || acceptedFiles;

      if (newFiles.length > (maxSize || 0)) {
        enqueueSnackbar(`อัปโหลดได้สูงสุด ${maxSize} ไฟล์`, {
          variant: "error",
        });
        return;
      }

      const formatFiles: IAttachment[] = newFiles?.map(
        (file: File & IAttachment) => {
          if (file instanceof File)
            return Object.assign(file, {
              attachment_name: file.attachment_name || file.name,
              uploaded_by: {
                user_unique_id: authUser?.unique_id || "",
                first_name: authUser?.first_name || "",
                last_name: authUser?.last_name || "",
              },
              uploaded_date: dayjs(),
              url: URL.createObjectURL(file),
            });
          else return file;
        }
      );

      setValue(name, formatFiles, {
        shouldDirty: true,
      });
    },
    disabled: files.length === maxSize,
    multiple,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const removeFile = (file: any) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setValue(name, newFiles, {
      shouldDirty: true,
    });
  };

  const fileList = Array.isArray(files) ? files : [files];

  const inputDropzone = (
    <div {...getRootProps({ style })}>
      <input {...getInputProps({ onChange })} />
      <ImageOutlinedIcon
        color={files.length >= (maxSize || 0) ? "disabled" : "primary"}
      />
      <Typography
        color={files.length >= (maxSize || 0) ? "#BDBDBD" : "primary"}
        fontSize={12}
      >
        อัปโหลดไฟล์/รูป .jpg .jpeg .png .pdf
      </Typography>
      <Typography
        color={files.length >= (maxSize || 0) ? "#BDBDBD" : "primary"}
        fontSize={12}
      >
        {files.length}/{maxSize}
      </Typography>
    </div>
  );

  return (
    <Box my={2}>
      {!gridDropzone && !disabled && <Box mb={2}>{inputDropzone}</Box>}
      <Grid container spacing={2}>
        {fileList?.length > 0 &&
          fileList?.map((file: any, index: number) => (
            <Grid
              item
              md={
                fullWidth
                  ? 12
                  : isModal
                  ? 6
                  : customGridSize
                  ? customGridSize
                  : 4
              }
              key={index}
            >
              <AttachmentCard
                control={control}
                disabled={disabled}
                file={file}
                index={index}
                removeFile={removeFile}
                name={name}
                separateDate={separateAttachmentDate}
              />
            </Grid>
          ))}

        {gridDropzone &&
          !disabled &&
          (files?.length === 0 || (files?.length ?? 0) < (maxSize ?? 10)) && (
            <Grid
              item
              md={
                fullWidth
                  ? 12
                  : isModal
                  ? 6
                  : customGridSize
                  ? customGridSize
                  : 4
              }
            >
              <Box
                display="flex"
                justifyContent="center"
                position="relative"
                sx={{
                  "& > div": {
                    height: "205px !important",
                  },
                }}
                mb={2}
              >
                {inputDropzone}
              </Box>
            </Grid>
          )}
      </Grid>
      {disabled && files.length === 0 && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          pt={1.5}
        >
          <Typography color="#737373">ไม่มีข้อมูล</Typography>
        </Box>
      )}
    </Box>
  );
};

export default DocumentDropzoneUI;
