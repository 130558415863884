import { deepen } from "components/UI/LocationImporterDropzoneUI";
import { useStateContext } from "contexts/auth-context";
import {
  LocationImportMode,
  useImportLocationMutation,
  useValidateLocationImportQuery,
  ValidateLocationImportQuery,
} from "generated/crm";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IImporterError, IShippingCost } from "types/global";
import { formatFloat, formatString } from "utils/Global";
import {
  addImporterErrorHandler,
  findDuplicatesWithList,
} from "utils/Importer";
import * as XLSX from "xlsx";

const columns = [
  "unique_id",
  "name",
  "province",
  "region",
  "contact_unique_id",
  "contact_name",
  "phone_number",
  "coordinator_name",
  "coordinator_phone",
  "google_map_url",
  "latitude",
  "longitude",
  "distance",
  "status",
  "shipping_cost_four_chilled",
  "shipping_cost_four_frozen",
  "shipping_cost_six_chilled",
  "shipping_cost_six_frozen",
  "shipping_cost_ten_chilled",
  "shipping_cost_ten_frozen",
  "shipping_cost_head_chilled",
  "shipping_cost_head_frozen",
  "secondary_driver_cost",
  "drop_cost",
];

const count_limit = 5000;
export const useLocationImporter = (
  type: LocationImportMode,
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const { t } = useTranslation();

  const requiredKeys = [
    {
      label: "รหัสสถานที่",
      value: "unique_id",
    },
    {
      label: "ชื่อสถานที่",
      value: "name",
    },
    {
      label: "จังหวัด",
      value: "province",
    },
    {
      label: "ภูมิภาค",
      value: "region",
    },
    {
      label: "รหัสผู้ติดต่อ",
      value: "contact_unique_id",
    },
    {
      label: "ชื่อผู้ประสานงาน",
      value: "coordinator_name",
    },
    {
      label: "เบอร์โทรศัพท์ผู้ประสานงาน",
      value: "coordinator_phone",
    },
    // {
    //     label: "Google Map URL",
    //     value: "google_map_url",
    // },
    // {
    //     label: "Latitude",
    //     value: "latitude",
    // },
    // {
    //     label: "Longitude",
    //     value: "longitude",
    // },
    {
      label: "KM",
      value: "distance",
    },
    {
      label: "สถานะ",
      value: "status",
    },
  ];

  const [locationUniqueIdList, setLocationUniqueIdList] = useState<string[]>(
    []
  );
  const [contactUniqueIdList, setContactUniqueIdList] = useState<string[]>([]);

  const graphqlClient = createGraphQLClientWithMiddleware("crm");

  const { refetch: validateLocation, isFetching: isValidating } =
    useValidateLocationImportQuery<ValidateLocationImportQuery>(
      graphqlClient,
      {
        validateInput: {
          location_unique_id_list: locationUniqueIdList,
          contact_unique_id_list: contactUniqueIdList,
          import_mode: getValues("type"),
        },
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: createOrUpdate, isLoading: isCreatingOrUpdating } =
    useImportLocationMutation<Error>(graphqlClient);

  const formatLocationData = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));
      if (missingCols.length > 0) {
        throw new Error("template");
      }
      const errorData: IImporterError[] = [];
      const requiredFieldErrors: any[] = [];
      const invalidTypeErrors: any[] = [];
      const formattedDataSnapshot = [...data];

      formattedDataSnapshot.forEach((item) => {
        requiredKeys.forEach(({ value, label }) => {
          if (!item[value]) {
            requiredFieldErrors.push({
              unique_id: item.unique_id,
              type: "required",
              field: label,
            });
          }
        });

        const validationRules = {
          idFields: [
            { field: "รหัสสถานที่", value: item.unique_id },
            { field: "รหัสผู้ติดต่อ", value: item.contact_unique_id },
          ],
          numberFields: [
            { field: "KM", value: item.distance },
            {
              field: "ค่าขนส่ง 4 ล้อ (Chilled)",
              value: item.shipping_cost_four_chilled,
            },
            {
              field: "ค่าขนส่ง 4 ล้อ (Frozen)",
              value: item.shipping_cost_four_frozen,
            },
            {
              field: "ค่าขนส่ง 6 ล้อ (Chilled)",
              value: item.shipping_cost_six_chilled,
            },
            {
              field: "ค่าขนส่ง 6 ล้อ (Frozen)",
              value: item.shipping_cost_six_frozen,
            },
            {
              field: "ค่าขนส่ง 10 ล้อ (Chilled)",
              value: item.shipping_cost_ten_chilled,
            },
            {
              field: "ค่าขนส่ง 10 ล้อ (Frozen)",
              value: item.shipping_cost_ten_frozen,
            },
            {
              field: "ค่าขนส่งหัวลาก (Chilled)",
              value: item.shipping_cost_head_chilled,
            },
            {
              field: "ค่าขนส่งหัวลาก (Frozen)",
              value: item.shipping_cost_head_frozen,
            },
          ],
          numberOrDashFields: [
            {
              field: "ค่าพนักงานขนส่งที่ 2",
              value: item.secondary_driver_cost,
            },
            { field: "ค่า Drop", value: item.drop_cost },
          ],
          maxLengthFields: [
            { field: "ชื่อสถานที่", value: item.name },
            { field: "จังหวัด", value: item.province },
            { field: "ภูมิภาค", value: item.region },
            { field: "ชื่อผู้ประสานงาน", value: item.coordinator_name },
            {
              field: "เบอร์โทรศัพท์ผู้ประสานงาน",
              value: item.coordinator_phone,
            },
            { field: "Latitude", value: item.latitude },
            { field: "Longitude", value: item.longitude },
          ],
        };

        if (item.region === "-") {
          requiredFieldErrors.push({
            unique_id: item.unique_id,
            type: "required",
            field: "ภูมิภาค",
          });
        }

        validationRules.maxLengthFields.forEach(({ field, value }) => {
          if (value && value.length > 200) {
            invalidTypeErrors.push({
              unique_id: item.unique_id,
              type: "invalid",
              field,
              detail: value,
            });
          }
        });

        validationRules.numberFields.forEach(({ field, value }) => {
          if (
            value != null &&
            (isNaN(value) || value < 0 || value >= 1000000000)
          ) {
            invalidTypeErrors.push({
              unique_id: item.unique_id,
              type: "invalid",
              field,
              detail: value,
            });
          }
        });

        validationRules.idFields.forEach(({ field, value }) => {
          if (value && value.length > 15) {
            invalidTypeErrors.push({
              unique_id: item.unique_id,
              type: "invalid",
              field,
              detail: value,
            });
          }
        });

        validationRules.numberOrDashFields.forEach(({ field, value }) => {
          if (
            value != null &&
            ((isNaN(value) && value !== "-") ||
              value < 0 ||
              value >= 1000000000)
          ) {
            invalidTypeErrors.push({
              unique_id: item.unique_id,
              type: "invalid",
              field,
              detail: value,
            });
          }
        });
      });

      [requiredFieldErrors, invalidTypeErrors].forEach((errors) => {
        errors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            error.type === "required"
              ? "กรุณาระบุข้อมูลที่จำเป็น"
              : "รูปแบบข้อมูลไม่ถูกต้อง",
            error.field,
            [error.detail || ""]
          );
        });
      });

      let formattedData: any[] = [];

      const uniqueIdList: string[] = [];
      const contactUniqueIdList: string[] = [];

      data.forEach((location: any) => {
        const { unique_id, contact_unique_id } = location;

        if (unique_id) {
          uniqueIdList.push(unique_id);
        }
        if (contact_unique_id) {
          contactUniqueIdList.push(contact_unique_id);
        }

        const contact_location = {
          unique_id: formatString(location.contact_unique_id),
          name: "",
          last_name: "",
          phone: "",
        };

        const shipping_cost: IShippingCost[] = [
          {
            type: "four_wheel",
            cost: location.shipping_cost_four_chilled
              ? formatFloat(location.shipping_cost_four_chilled, 0)
              : undefined,
            is_frozen: false,
          },
          {
            type: "four_wheel",
            cost: location.shipping_cost_four_frozen
              ? formatFloat(location.shipping_cost_four_frozen, 0)
              : undefined,
            is_frozen: true,
          },
          {
            type: "six_wheel",
            cost: location.shipping_cost_six_chilled
              ? formatFloat(location.shipping_cost_six_chilled, 0)
              : undefined,
            is_frozen: false,
          },
          {
            type: "six_wheel",
            cost: location.shipping_cost_six_frozen
              ? formatFloat(location.shipping_cost_six_frozen, 0)
              : undefined,
            is_frozen: true,
          },
          {
            type: "ten_wheel",
            cost: location.shipping_cost_ten_chilled
              ? formatFloat(location.shipping_cost_ten_chilled, 0)
              : undefined,
            is_frozen: false,
          },
          {
            type: "ten_wheel",
            cost: location.shipping_cost_ten_frozen
              ? formatFloat(location.shipping_cost_ten_frozen, 0)
              : undefined,
            is_frozen: true,
          },
          {
            type: "head",
            cost: location.shipping_cost_head_chilled
              ? formatFloat(location.shipping_cost_head_chilled, 0)
              : undefined,
            is_frozen: false,
          },
          {
            type: "head",
            cost: location.shipping_cost_head_frozen
              ? formatFloat(location.shipping_cost_head_frozen, 0)
              : undefined,
            is_frozen: true,
          },
        ];
        // if (location.shipping_cost_four_chilled ||
        //     location.shipping_cost_four_frozen ||
        //     location.shipping_cost_six_chilled ||
        //     location.shipping_cost_six_frozen ||
        //     location.shipping_cost_ten_chilled ||
        //     location.shipping_cost_ten_frozen ||
        //     location.shipping_cost_head_chilled ||
        //     location.shipping_cost_head_frozen) {

        //     if (location.shipping_cost_four_chilled) {
        //         shipping_cost.push({
        //             type: "four_wheel",
        //             cost: formatFloat(location.shipping_cost_four_chilled, 0),
        //             is_frozen: false
        //         });
        //     }
        //     if (location.shipping_cost_four_frozen) {
        //         shipping_cost.push({
        //             type: "four_wheel",
        //             cost: formatFloat(location.shipping_cost_four_frozen, 0),
        //             is_frozen: true
        //         });
        //     }
        //     if (location.shipping_cost_six_chilled) {
        //         shipping_cost.push({
        //             type: "six_wheel",
        //             cost: formatFloat(location.shipping_cost_six_chilled, 0),
        //             is_frozen: false
        //         });
        //     }
        //     if (location.shipping_cost_six_frozen) {
        //         shipping_cost.push({
        //             type: "six_wheel",
        //             cost: formatFloat(location.shipping_cost_six_frozen, 0),
        //             is_frozen: true
        //         });
        //     }
        //     if (location.shipping_cost_ten_chilled) {
        //         shipping_cost.push({
        //             type: "ten_wheel",
        //             cost: formatFloat(location.shipping_cost_ten_chilled, 0),
        //             is_frozen: false
        //         });
        //     }
        //     if (location.shipping_cost_ten_frozen) {
        //         shipping_cost.push({
        //             type: "ten_wheel",
        //             cost: formatFloat(location.shipping_cost_ten_frozen, 0),
        //             is_frozen: true
        //         });
        //     }
        //     if (location.shipping_cost_head_chilled) {
        //         shipping_cost.push({
        //             type: "head",
        //             cost: formatFloat(location.shipping_cost_head_chilled, 0),
        //             is_frozen: false
        //         });
        //     }
        //     if (location.shipping_cost_head_frozen) {
        //         shipping_cost.push({
        //             type: "head",
        //             cost: formatFloat(location.shipping_cost_head_frozen, 0),
        //             is_frozen: true
        //         });
        //     }
        // }

        const mappedData = {
          unique_id: formatString(location.unique_id),
          name: formatString(location.name),
          province: formatString(location.province),
          region: formatString(location.region),
          contact: contact_location,
          coordinator_name: formatString(location.coordinator_name),
          coordinator_phone: formatString(location.coordinator_phone),
          google_map_url: formatString(location.google_map_url),
          latitude: formatString(location.latitude),
          longitude: formatString(location.longitude),
          distance: formatFloat(location.distance),
          status: location.status === "ใช้งาน" ? 1 : 0,
          shipping_cost,
          secondary_driver_cost: location.secondary_driver_cost
            ? formatFloat(location.secondary_driver_cost)
            : undefined,
          drop_cost: location.drop_cost
            ? formatFloat(location.drop_cost)
            : undefined,
        };

        formattedData.push(mappedData);
      });

      const duplicateUniqueId = findDuplicatesWithList(uniqueIdList);
      const allError = [
        duplicateUniqueId,
        requiredFieldErrors,
        invalidTypeErrors,
      ];
      addImporterErrorHandler(
        errorData,
        `${t(`logistic.location.unique_id`)}ในไฟล์ซ้ำ`,
        `${t(`logistic.location.unique_id`)}`,
        duplicateUniqueId
      );
      setErrorData(errorData);
      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength) {
        openModalHandler();
        return [];
      }

      setLocationUniqueIdList(Array.from(new Set(uniqueIdList)));
      setContactUniqueIdList(Array.from(new Set(contactUniqueIdList)));

      return formattedData;
    } catch (e) {
      console.error(e);
      let message = "Template ไม่ตรง";
      if (data.length === 0) {
        message = "กรุณาระบุข้อมูล";
      }
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const validateHandler = async () => {
    try {
      const filePreview = getValues("filePreview");

      const reader = new FileReader();
      reader.readAsArrayBuffer(filePreview);

      // if (tempDoc.length > 0) {
      //     const dataCols = Object.keys(tempDoc[0]);

      //     for (let i = 0; i < columns.length; i++) {
      //         if (columns[i] !== dataCols[i]) {
      //             throw new Error("column order");
      //         }
      //     }
      // }

      reader.onload = async () => {
        const binaryStr = reader.result;
        const wb = XLSX.read(binaryStr, { type: "binary", cellDates: true });

        if (!wb.SheetNames.includes("สถานที่")) {
          enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
            variant: "error",
          });
          return;
        }

        const XL_row_object = XLSX.utils.sheet_to_json(wb.Sheets["สถานที่"], {
          defval: null,
          raw: false,
          rawNumbers: false,
          range: 1,
        });

        const deepened = XL_row_object.map((record) => {
          return deepen(record);
        });

        setValue("file", deepened);

        const formattedData = await formatLocationData(deepened);
        if (formattedData && formattedData.length > 0) {
          if (formattedData.length > count_limit) {
            enqueueSnackbar(
              `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
              {
                variant: "error",
              }
            );
          } else {
            setRowData(formattedData);
            enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
              variant: "success",
            });
            setValue("step", 1);
          }
        }
        // else if (deepened.length > 0) {
        //     enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
        //         variant: "success",
        //     });
        // }
        // else {
        //     enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        //         variant: "error",
        //     });
        // }
      };

      reader.onerror = (err) => {
        console.error(err);
        enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
          variant: "error",
        });
      };
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const importHandler = async () => {
    try {
      const { data } = await validateLocation();
      const existingUniqueId =
        data?.ValidateLocationImport.existing_location_unique_id_list || [];
      const missingUniqueId =
        data?.ValidateLocationImport.missing_location_unique_id_list || [];
      const missingContactUniqueId =
        data?.ValidateLocationImport.missing_contact_unique_id_list || [];

      const arrays = [
        existingUniqueId,
        missingUniqueId,
        missingContactUniqueId,
      ];

      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      const errorData: IImporterError[] = [];

      addImporterErrorHandler(
        errorData,
        `ข้อมูลซ้ำกับในระบบ`,
        `${t(`logistic.location.unique_id`)}`,
        existingUniqueId
      );
      addImporterErrorHandler(
        errorData,
        `ไม่พบข้อมูลในระบบ`,
        `${t(`logistic.location.unique_id`)}`,
        missingUniqueId
      );
      addImporterErrorHandler(
        errorData,
        `ไม่พบผู้ติดต่อภายในระบบ`,
        `${t(`contact.unique_id`)}`,
        missingContactUniqueId
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไฟล์สถานที่ไม่สำเร็จ", {
          variant: "error",
        });
        return;
      } else {
        try {
          await createOrUpdate({
            importInput: {
              import_mode: getValues("type"),
              data: rowData,
              // priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าไฟล์สถานที่สำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (err) {
          enqueueSnackbar("นำเข้าไฟล์สถานที่ไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไฟล์สถานที่ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
  return {
    rowData,
    isLoading: isValidating || isCreatingOrUpdating,
    validateHandler,
    importHandler,
  };
};
export default useLocationImporter;
