import { IGoodsReceive } from "types/Inventory/goodsReceive";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Traceability } from "generated/wms";
import { stringMaxInput } from "utils/Validate";

export const goodsReceiveSchema: IGoodsReceive = {
  unique_id: "",
  type: "",
  reference_unique_id_list: [],
  source_warehouse_id: undefined,
  created_date: dayjs(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  created_by: {},
  main_status: "",
  aggrid_status: "",
  flag_status: [],
};

const goodsReceiveTraceValidation = Yup.object().shape({
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1, "กรุณาระบุจำนวนที่นำเข้า")
    .required("กรุณาระบุจำนวนที่นำเข้า"),
  remark: stringMaxInput(200, undefined, false),
});

// .test(
//   "exceed-po-qty",
//   "",
//   (item) => {
//     const qty =
//       item > item.document_item_qty - item.posted_qty
//     return sum === 100;
//   }
// )

const goodsReceiveSerialValidation = Yup.object().shape({
  qty: Yup.number().when("traceability", (traceability: any) =>
    traceability && traceability[0] === Traceability.Normal
      ? Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .min(1, "กรุณาระบุจำนวนที่นำเข้า")
          .required("กรุณาระบุจำนวนที่นำเข้า")
      : Yup.number().nullable()
  ),
  remark: stringMaxInput(200, undefined, false),
  serial_list: Yup.array().when("traceability", (traceability: any) =>
    traceability && traceability[0] === Traceability.Serial
      ? Yup.array()
          .of(goodsReceiveTraceValidation)
          .min(1, "กรุณาเพิ่ม SN ในรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsReceiveValidation = Yup.object().shape({
  unique_id: stringMaxInput(15, undefined, true, "กรุณาระบุเลขที่เอกสาร"),
  type: Yup.string().required("กรุณาระบุประเภท"),
  source_warehouse_id: Yup.string().required("กรุณาเลือกคลัง"),
  posted_date: Yup.date().required("กรุณาระบุ"),
  reference_unique_id_list: Yup.array().when("type", (type, schema) => {
    if (type[0] && type[0] === "purchase") {
      return schema
        .min(1, "กรุณาระบุเอกสารอ้างอิง")
        .required("กรุณาระบุเอกสารอ้างอิง");
    } else {
      return schema;
    }
  }),
  remark: stringMaxInput(200, undefined, false),
  trace_entry_list: Yup.array()
    .of(goodsReceiveSerialValidation)
    .min(1, "กรุณาเพิ่มสินค้า"),
});
