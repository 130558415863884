import { IUser } from "types/Auth/user";
import { ITire } from "types/Logistic/tire";

export const tireQueryFormatter = (data: any) => {
  const formatData = {
    ...data,
    current_distance: parseFloat(data.current_distance),
    due_distance: parseFloat(data.due_distance),
    price_per_unit: data.price_per_unit || null,
  } as ITire;

  return formatData;
};

export const tireCreateFormatter = (data: ITire, authUser?: IUser | null) => {
  const { current_distance, model, band, price_per_unit, ...otherData } = data;

  const formatData = {
    ...otherData,
    distance: current_distance || 0,
    model: model || "",
    band: band || "",
    price_per_unit: price_per_unit || 0,
    created_by: {
      user_id: authUser?.id || 0,
      user_unique_id: authUser?.unique_id || "",
      email: authUser?.email || "",
      first_name: authUser?.first_name || "",
      last_name: authUser?.last_name || "",
      img_url: authUser?.img_url || [],
    },
  };

  return formatData;
};

export const tireUpdateFormatter = (
  data: ITire,
  id: string,
  authUser?: IUser | null
) => {
  const {
    last_updated_date,
    created_date,
    serial,
    created_by,
    current_distance,
    status,
    ...otherData
  } = data;

  const formatData = {
    ...otherData,
    distance: current_distance,
    id: parseInt(id),
    last_updated_by: {
      user_id: authUser?.id || 0,
      user_unique_id: authUser?.unique_id || "",
      email: authUser?.email || "",
      first_name: authUser?.first_name || "",
      last_name: authUser?.last_name || "",
      img_url: authUser?.img_url || [],
    },
    remark: otherData.is_active ? "" : otherData.remark,
    price_per_unit: otherData.price_per_unit || null,
  };

  return formatData;
};
