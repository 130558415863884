import { useCallback, useEffect, useRef } from "react";
import { useItemColumnDefs } from "./columnDefs";
import {
  GridReadyEvent,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { ITEM_VIEW } from "../../../../services/AgGrid/ItemAggrid";

type Props = {
  type: string | null;
};

const ItemTable = ({ type }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const columnDefs = useItemColumnDefs({ type });
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { is_active, tag_list, cate_1, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => parseInt(v)),
        },
        tag_list: tag_list && {
          type: "hasSome",
          filterType: "array",
          values: tag_list.values,
        },
        cate_1: cate_1 && {
          type: "in",
          filterType: "set",
          values: cate_1.values,
        },
      };
      const formatSort = [
        ...sortModel,
        { colId: "created_date", sort: "desc" },
      ];
      try {
        const { ItemsViewAggrid } = await graphQLClientWms.request(ITEM_VIEW, {
          aggridInput: {
            startRow,
            endRow,
            filterModel: formatFilter,
            sortModel: formatSort,
          },
        });
        params.success({
          rowData: ItemsViewAggrid.results as any[],
          rowCount: ItemsViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const typeInstance = params.api.getFilterInstance("type");
      const isActiveInstance = params.api.getFilterInstance("is_active");
      switch (type) {
        case "normal":
          typeInstance?.setModel({ values: ["normal"] });
          isActiveInstance?.setModel({
            values: ["1"],
          });
          break;
        case "service":
          typeInstance?.setModel({ values: ["service"] });
          isActiveInstance?.setModel({
            values: ["1"],
          });
          break;
        case "in_active":
          typeInstance?.setModel({});
          isActiveInstance?.setModel({
            values: ["0"],
          });
          break;
        default:
          typeInstance?.setModel({});
          isActiveInstance?.setModel({
            values: ["1"],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [type]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id, type } = params.data;
    const navigateRoute = type === "normal" ? "item" : "service";
    navigate(`/inventory/${navigateRoute}/${id}?tab=item&subtab=general`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onRowDoubleClicked={rowSelectHandler}
      onGridReady={onGridReady}
      path={`/inventory/item`}
    />
  );
};

export default ItemTable;
