import { gql } from "graphql-request";

export const BILLING_NOTE_BY_ITEM_REPORT = gql`
    query BillingNoteByItemReportViewsAggrid($aggridInput: AnyAggridInput) {
        BillingNoteByItemReportViewsAggrid(aggridInput: $aggridInput) {
            count
            results {
                item_unique_id
                item_name
                qty
                uom_name
                net_amount
                pre_vat_amount
                vat_amount
            }
        }
    }
`;

export const BILLING_NOTE_BY_CUSTOMER_REPORT = gql`
    query BillingNoteByCustomerReportViewsAggrid($aggridInput: AnyAggridInput) {
        BillingNoteByCustomerReportViewsAggrid(aggridInput: $aggridInput) {
            count
            results {
                customer_unique_id
                customer_name
                dt_document_amount
                income_net_amount
                income_pre_vat_amount
                income_vat_amount
            }
        }
    }
`;