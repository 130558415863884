import { styled } from "@mui/material";
import {
  TreeItem2,
  TreeItem2Props,
  UseTreeItem2ContentSlotOwnProps,
  treeItemClasses,
  useTreeItem2Utils,
} from "@mui/x-tree-view";
import { forwardRef } from "react";

const StyledTreeItem = styled(TreeItem2)(() => ({
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px solid #BDBDBD`,
  },
}));

const CustomizedTreeItem = forwardRef(function MyTreeItem(
  props: TreeItem2Props,
  ref: React.Ref<HTMLLIElement>
) {
  const { interactions } = useTreeItem2Utils({
    itemId: props.itemId,
    children: props.children,
  });

  const handleContentClick: UseTreeItem2ContentSlotOwnProps["onClick"] = (
    event
  ) => {
    event.defaultMuiPrevented = true;
    interactions.handleSelection(event);
  };

  const handleIconContainerClick = (event: React.MouseEvent) => {
    interactions.handleExpansion(event);
  };

  return (
    <StyledTreeItem
      {...props}
      ref={ref}
      slotProps={{
        content: { onClick: handleContentClick },
        iconContainer: { onClick: handleIconContainerClick },
      }}
    />
  );
});

export default CustomizedTreeItem;
