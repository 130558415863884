import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { useDisable } from "hooks/use-disable";
import { useStateContext } from "contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

import LoadingUI from "components/UI/LoadingUI";
import ButtonLayout from "components/UI/ButtonLayout";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import TireHeader from "components/Form/Logistic/Tire/Header";
import TireInfo from "components/Form/Logistic/Tire/TireInfo";
import TireStatus from "components/Form/Logistic/Tire/TireStatus";
import CustomizedButton from "components/Custom/CustomizedButton";

import { ITire } from "types/Logistic/tire";
import {
  ActivityLogDocumentType,
  ActivityType,
  TireCreateMutation,
  TireFineUniqueQuery,
  TireRemoveMutation,
  TireUpdateMutation,
  useTireCreateMutation,
  useTireRemoveMutation,
  useTireUpdateMutation,
} from "generated/sales";
import { useTireFineUniqueQuery } from "generated/vehicle";
import { errorMessageFormatter } from "utils/Global";
import {
  tireCreateFormatter,
  tireQueryFormatter,
  tireUpdateFormatter,
} from "utils/Formatter/Logistic/Tire";
import { useActivityLog } from "hooks/use-activity-log";

const DocumentInfoTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useDisable();
  const [prevStatus, setPrevStatus] = useState<string | undefined>("");
  const { createActivityLog } = useActivityLog();

  const graphqlClient = createGraphQLClientWithMiddleware("sales");

  const {
    state: { authUser },
  } = useStateContext();

  const {
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = useFormContext<ITire>();

  useEffect(() => {
    if (id) setDisabled(true);
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled]);

  const { data, isLoading, isSuccess, refetch } =
    useTireFineUniqueQuery<TireFineUniqueQuery>(
      graphqlClient,
      {
        tireFineUniqueId: id ? parseInt(id) : 0,
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const { mutateAsync: create, isLoading: isCreating } =
    useTireCreateMutation<TireCreateMutation>(graphqlClient);
  const { mutateAsync: update, isLoading: isUpdating } =
    useTireUpdateMutation<TireUpdateMutation>(graphqlClient);
  const { mutateAsync: deleteTire } =
    useTireRemoveMutation<TireRemoveMutation>(graphqlClient);

  useEffect(() => {
    if (isSuccess) {
      const { TireFineUnique } = data;
      const formatData = tireQueryFormatter(TireFineUnique);
      setPrevStatus(formatData.status);
      reset(formatData);
    }
  }, [data, isSuccess, reset]);

  const confirmHandler = async (data: ITire) => {
    if (!id) {
      try {
        const formatData = tireCreateFormatter(data, authUser);

        const { TireCreate } = await create({
          createTireInput: formatData,
        });

        enqueueSnackbar(t("snack_bar.tire.create.success"), {
          variant: "success",
        });

        await createActivityLog({
          activity_type: ActivityType.Create,
          document_type: ActivityLogDocumentType.Tire,
          reference_id: parseInt(TireCreate?.id) || 0,
          activity_detail: {},
        });

        if (TireCreate?.status === "in_active") {
          await createActivityLog({
            activity_type: ActivityType.InActive,
            document_type: ActivityLogDocumentType.Tire,
            reference_id: parseInt(TireCreate?.id) || 0,
            activity_detail: {},
          });
        }

        navigate(`/logistic/tire/${TireCreate?.id}`);
      } catch (error) {
        const formatErrors = errorMessageFormatter(error, "tire");
        enqueueSnackbar(formatErrors || t("snack_bar.tire.create.fail"), {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = await tireUpdateFormatter(data, id, authUser);

        const { TireUpdate } = await update({
          updateTireInput: formatData,
        });

        if (isDirty) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.Tire,
            reference_id: parseInt(TireUpdate?.id) || 0,
            activity_detail: {
              secondary_operation: ActivityType.Edit,
            },
          });

          if (prevStatus === "stock") {
            if (TireUpdate?.status === "in_active") {
              await createActivityLog({
                activity_type: ActivityType.InActive,
                document_type: ActivityLogDocumentType.Tire,
                reference_id: parseInt(TireUpdate?.id) || 0,
                activity_detail: {},
              });
            }
          } else {
            if (TireUpdate?.status === "stock") {
              await createActivityLog({
                activity_type: ActivityType.Active,
                document_type: ActivityLogDocumentType.Tire,
                reference_id: parseInt(TireUpdate?.id) || 0,
                activity_detail: {},
              });
            }
          }
        }

        if (dirtyFields.hasOwnProperty("is_active") && prevStatus === "stock") {
          enqueueSnackbar(t("snack_bar.tire.inactivate.success"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("snack_bar.tire.update.success"), {
            variant: "success",
          });
        }

        setDisabled(true);

        await refetch();
      } catch (error) {
        if (dirtyFields.hasOwnProperty("in_active") && !dirtyFields.is_active) {
          enqueueSnackbar(t("snack_bar.tire.inactivate.fail"), {
            variant: "success",
          });
        } else {
          const formatErrors = errorMessageFormatter(error, "tire");
          enqueueSnackbar(formatErrors || t("snack_bar.tire.update.fail"), {
            variant: "error",
          });
        }
      }
    }
  };

  const deleteHandler = async () => {
    try {
      deleteTire({
        tireRemoveId: parseInt(id || "0"),
      });
      enqueueSnackbar(t("snack_bar.tire.delete.success"), {
        variant: "success",
      });
      navigate("/logistic/tire");
    } catch (error) {
      const formatErrors = errorMessageFormatter(error, "tire");
      enqueueSnackbar(formatErrors || t("snack_bar.tire.delete.fail"), {
        variant: "error",
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
  };

  const cancelConfirmHandler = () => {
    if (id) {
      reset();
      setDisabled(true);
    } else {
      reset();
      navigate(`/logistic/tire`);
    }
  };

  if (id && (isLoading || isCreating || isUpdating)) {
    return <LoadingUI />;
  }

  return (
    <>
      <TireHeader
        data={data?.TireFineUnique}
        editClickHandler={editClickHandler}
        deleteHandler={deleteHandler}
      />
      <TireInfo />
      <TireStatus />
      {!disabled && (
        <BottomNavbar>
          <ButtonLayout>
            <CustomizedButton
              variant="outlined"
              title={t("button.cancel")}
              disabled={isCreating || isUpdating}
              onClick={() => cancelConfirmHandler()}
            />
            <CustomizedButton
              title={t("button.confirm")}
              variant="contained"
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(confirmHandler)}
            />
          </ButtonLayout>
        </BottomNavbar>
      )}
    </>
  );
};

export default DocumentInfoTab;
