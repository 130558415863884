import { useModal } from "hooks/use-modal";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";

const useEntityState = () => {
  const [ids, setIds] = useState<string[]>([]);
  const [idsSnapshot, setIdsSnapshot] = useState<string[]>([]);
  return { ids, setIds, idsSnapshot, setIdsSnapshot };
};

const useModalHandlers = () => {
  const { modal, openModalHandler, closeModalHandler } = useModal();
  return { modal, openModalHandler, closeModalHandler };
};

export const useDeliveryTripModal = () => {
  const { control } = useFormContext<IDeliveryTrip>();
  const vehicleState = useEntityState();
  const primaryDriverState = useEntityState();
  const secondaryDriverState = useEntityState();
  const trailerState = useEntityState();

  const vehicleModalHandlers = useModalHandlers();
  const primaryDriverModalHandlers = useModalHandlers();
  const secondaryDriverModalHandlers = useModalHandlers();
  const trailerModalHandlers = useModalHandlers();

  const vehicleId = useWatch({
    control,
    name: "vehicle.vehicle_no",
  });

  const trailerId = useWatch({
    control,
    name: "trailer.trailer_no",
  });

  const primaryDriverId = useWatch({
    control,
    name: "primary_driver.user_unique_id",
  });

  const secondaryDriverId = useWatch({
    control,
    name: "secondary_driver.user_unique_id",
  });

  useEffect(() => {
    if (vehicleId) {
      vehicleState.setIds([vehicleId]);
      vehicleState.setIdsSnapshot([vehicleId]);
    } else {
      vehicleState.setIds([]);
      vehicleState.setIdsSnapshot([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId]);

  useEffect(() => {
    if (trailerId) {
      trailerState.setIds([trailerId]);
      trailerState.setIdsSnapshot([trailerId]);
    } else {
      trailerState.setIds([]);
      trailerState.setIdsSnapshot([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailerId]);

  useEffect(() => {
    if (primaryDriverId) {
      primaryDriverState.setIds([primaryDriverId]);
      primaryDriverState.setIdsSnapshot([primaryDriverId]);
    } else {
      primaryDriverState.setIds([]);
      primaryDriverState.setIdsSnapshot([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryDriverId]);

  useEffect(() => {
    if (secondaryDriverId) {
      secondaryDriverState.setIds([secondaryDriverId]);
      secondaryDriverState.setIdsSnapshot([secondaryDriverId]);
    } else {
      secondaryDriverState.setIds([]);
      secondaryDriverState.setIdsSnapshot([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryDriverId]);

  return {
    vehicle: {
      ...vehicleState,
      ...vehicleModalHandlers,
    },
    primaryDriver: {
      ...primaryDriverState,
      ...primaryDriverModalHandlers,
    },
    secondaryDriver: {
      ...secondaryDriverState,
      ...secondaryDriverModalHandlers,
    },
    trailer: {
      ...trailerState,
      ...trailerModalHandlers,
    },
  };
};
