import { PurchaseExportType } from "generated/purchase";
import { IExporterPurchase, ISelectOption } from "types/global";

export const getPurchaseExportTypeOptions = (pageType: PurchaseExportType) => {
  switch (pageType) {
    case PurchaseExportType.PurchaseOrderExport:
      return orderTypeOptions;
    case PurchaseExportType.PurchaseReturnExport:
      return returnTypeOptions;
    default:
      return [];
  }
};

const orderTypeOptions: ISelectOption[] = [
  {
    label: "ใบสั่งซื้อ",
    value: PurchaseExportType.PurchaseOrderExport,
  },
];

const returnTypeOptions: ISelectOption[] = [
  {
    label: "ใบสั่งคืน",
    value: PurchaseExportType.PurchaseReturnExport,
  },
];

export const getPurchaseExporterSchema = (pageType: PurchaseExportType) => {
  let type;

  switch (pageType) {
    case PurchaseExportType.PurchaseOrderExport:
      type = PurchaseExportType.PurchaseOrderExport;
      break;
    case PurchaseExportType.PurchaseReturnExport:
      type = PurchaseExportType.PurchaseReturnExport;
      break;
    default:
      type = PurchaseExportType.PurchaseOrderExport;
  }

  const exporterSchena: IExporterPurchase = {
    type: type,
    export_type: "all",
    start_date: null,
    end_date: null,
  };

  return exporterSchena;
};
