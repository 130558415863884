import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ControlledTextField from "components/Controller/ControlledTextField";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { useStateContext } from "contexts/auth-context";
import {
  ItemSkuQtysQuery,
  TraceEntriesAggridQuery,
  TraceEntryType,
  Traceability,
  useItemSkuQtysQuery,
  useTraceEntriesAggridQuery,
} from "generated/wms";
import { useGoodsTransferItemList } from "hooks/Inventory/use-transfer-item-list";
import { useDisable } from "hooks/use-disable";
import { useSnackbar } from "notistack";
import { Fragment, useCallback } from "react";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IBarcodeForm, ITraceEntry } from "types/Inventory";
import { IGoodsTransfer } from "types/Inventory/goodsTransfer";
import { formatDateTimeNoAMPM } from "utils/Date";
import { goodsTransferTraceEntryFormatter } from "utils/Formatter/Inventory/GoodsTransfer";
import ClearIcon from "@mui/icons-material/Clear";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import TotalPostedQuantityCell from "./TotalPostedQuantityCell";
import ControlledSelect from "components/Controller/ControlledSelect";
import CustomizedAvatar from "components/Custom/CustomizedAvatar";
import SerialList from "./SerialList";
import { useTranslation } from "react-i18next";
import useInventoryControl from "hooks/Inventory/use-inventory-control";
import dayjs from "dayjs";
import { checkIsNotDraftOrEmptyStatus } from "utils/Global";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";

type Props = {
  fields: FieldArrayWithId<IGoodsTransfer, "trace_entry_list", "id">[];
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsTransfer, "trace_entry_list">;
  append: UseFieldArrayAppend<IGoodsTransfer, "trace_entry_list">;
};

const GoodsTransferItemList = ({ fields, remove, update, append }: Props) => {
  const {
    control,
    getValues,
    // setValue,
    formState: { errors },
  } = useFormContext<IGoodsTransfer>();
  const [disabled] = useDisable();

  const { enqueueSnackbar } = useSnackbar();

  const graphqlClientWms = createGraphQLClientWithMiddleware("wms");

  const watchWarehouseId = useWatch({
    control,
    name: "source_warehouse_id",
  });

  const watchDestinationWarehouseId = useWatch({
    control,
    name: "destination_warehouse_id",
  });

  const { t } = useTranslation();

  const { binLocationOptions, allBinLocation } = useInventoryControl(
    watchDestinationWarehouseId
  );

  const status =
    useWatch({
      control,
      name: "aggrid_status",
    }) || "";

  const headers = useGoodsTransferItemList(disabled, status);

  const {
    state: { authUser },
  } = useStateContext();

  const {
    control: barcodeControl,
    reset: resetBarcode,
    watch: watchBarcode,
    getValues: getBarcode,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      destination_bin_location_id: undefined,
    },
  });

  const { refetch: refetchTraceEntries } =
    useTraceEntriesAggridQuery<TraceEntriesAggridQuery>(
      graphqlClientWms,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            barcode: {
              filterType: "text",
              type: "equals",
              filter:
                watchBarcode("barcodeMobile")?.trim() ||
                watchBarcode("barcode").trim(),
            },
          },
        },
      },
      {
        enabled: false,
      }
    );

  const { refetch: refetchSkuQty } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphqlClientWms,
    {
      findManyInput: {
        where: {
          barcode: {
            equals:
              watchBarcode("barcodeMobile")?.trim() ||
              watchBarcode("barcode").trim(),
          },
        },
      },
    },
    {
      enabled: false,
    }
  );

  const onBarcodeSubmitHandler = useCallback(async () => {
    const { data } = await refetchTraceEntries();
    const { data: skuQtyData } = await refetchSkuQty();
    const warehouse_id = getValues("source_warehouse_id");

    if (skuQtyData?.ItemSkuQtys) {
      if (skuQtyData.ItemSkuQtys.length > 0) {
        const filteredWarehouse = skuQtyData.ItemSkuQtys.filter(
          (itemSkuQty) => itemSkuQty?.warehouse_id === warehouse_id
        );

        if (filteredWarehouse.length > 0) {
          const formatBinLocations = skuQtyData.ItemSkuQtys.map((itemSku) => ({
            id: itemSku?.bin_location_id,
            name: itemSku?.bin_name,
            stock_qty: itemSku?.stock_qty,
            warehouse_id: itemSku?.warehouse_id,
          }));

          const filterBinLocations = formatBinLocations.filter(
            (bin) => bin.warehouse_id === watchWarehouseId
          );

          const traceEntries = data?.TraceEntriesAggrid?.results;
          const traceEntry = traceEntries
            ? traceEntries.find(
                (trace) => trace?.type === TraceEntryType.GoodReceive
              )
            : null;
          if (traceEntry) {
            const traceEntryType = traceEntry as ITraceEntry;
            const formatTrace = goodsTransferTraceEntryFormatter(
              traceEntryType,
              filterBinLocations,
              authUser
            );
            if (formatTrace) {
              if (formatTrace.traceability === Traceability.Serial) {
                const foundExisting = fields.findIndex(
                  (trace) => trace.item_unique_id === formatTrace.item_unique_id
                );
                if (foundExisting === -1) {
                  // append new trace (serial view)
                  const {
                    // posted_date,
                    serial_no,
                    source_bin_location,
                    source_bin_location_id,
                    scanned_by,
                    barcode,
                    ...otherTrace
                  } = formatTrace;

                  const formatTraceWithSerial = {
                    ...otherTrace,
                    serial_list: [
                      {
                        ...otherTrace,
                        // posted_date,
                        serial_no,
                        source_bin_location,
                        source_bin_location_id,
                        scanned_by,
                        barcode,
                      },
                    ],
                  };
                  append(formatTraceWithSerial);
                  enqueueSnackbar(`สแกน SN :\n${barcode}\nสำเร็จ`, {
                    variant: "success",
                    style: { whiteSpace: "pre-line" },
                  });
                } else {
                  // update serial
                  const currentTrace: ITraceEntry = getValues(
                    `trace_entry_list.${foundExisting}`
                  );
                  if (currentTrace && currentTrace.serial_list) {
                    const foundBarcode = currentTrace.serial_list.findIndex(
                      (serial) =>
                        serial.barcode ===
                        (watchBarcode("barcodeMobile")?.trim() ||
                          watchBarcode("barcode").trim())
                    );
                    if (foundBarcode === -1) {
                      const { document_item_qty, posted_qty, ...otherTrace } =
                        formatTrace;
                      const formatTraceWithDocument: ITraceEntry = {
                        ...otherTrace,
                        document_item_qty: currentTrace.document_item_qty,
                        posted_qty: currentTrace.posted_qty,
                      };

                      update(foundExisting, {
                        ...currentTrace,
                        serial_list: [
                          ...currentTrace.serial_list,
                          formatTraceWithDocument,
                        ],
                      });
                      enqueueSnackbar(
                        `สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`,
                        {
                          variant: "success",
                          style: { whiteSpace: "pre-line" },
                        }
                      );
                    } else {
                      enqueueSnackbar(
                        `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                        {
                          variant: "error",
                          style: { whiteSpace: "pre-line" },
                        }
                      );
                    }
                  }
                }
              } else {
                // append new trace (normal view)
                const foundExisting = fields.findIndex(
                  (trace) => trace.item_unique_id === formatTrace.item_unique_id
                );

                if (foundExisting === -1) {
                  append(formatTrace);
                  enqueueSnackbar(`สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`, {
                    variant: "success",
                    style: { whiteSpace: "pre-line" },
                  });
                } else {
                  enqueueSnackbar(
                    `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                    {
                      variant: "error",
                      style: { whiteSpace: "pre-line" },
                    }
                  );
                }
              }
            }
          }
        } else {
          // Warehouse not match
          enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในคลัง กรุณาเปลี่ยนคลัง", {
            variant: "error",
          });
        }
      } else {
        // Barcode not found
        enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่", {
          variant: "error",
        });
      }
    }
    // }
    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  }, [
    append,
    authUser,
    enqueueSnackbar,
    fields,
    getValues,
    refetchTraceEntries,
    refetchSkuQty,
    resetBarcode,
    update,
    watchBarcode,
    watchWarehouseId,
  ]);

  const onBarcodeSubmitWaitTransferHandler = useCallback(() => {
    //TODO: Validation
    const barcode = getBarcode("barcode");
    const destination_bin_location_id = getBarcode(
      "destination_bin_location_id"
    );

    if (destination_bin_location_id) {
      const foundBinLocation = allBinLocation?.find(
        (bin) => bin.id === destination_bin_location_id
      );

      const fields = getValues("trace_entry_list") as ITraceEntry[];

      if (barcode) {
        fields.forEach((trace, index) => {
          if (trace.traceability === Traceability.Normal) {
            if (trace.barcode === barcode.trim()) {
              if (
                destination_bin_location_id === trace.source_bin_location_id
              ) {
                enqueueSnackbar(
                  `กรุณาเลือกสถานที่ปลายทางที่ต่างจากสถานที่ต้นทาง`,
                  {
                    variant: "error",
                    style: { whiteSpace: "pre-line" },
                  }
                );
              } else {
                if (trace.status === "is_active") {
                  const currentTrace = getValues(`trace_entry_list.${index}`);
                  update(index, {
                    ...currentTrace,
                    destination_scanned_by: {
                      first_name: authUser?.first_name || "",
                      last_name: authUser?.last_name || "",
                      email: authUser?.email || "",
                      img_url: authUser?.img_url,
                      user_unique_id: authUser?.unique_id || "",
                    },
                    destination_bin_location: foundBinLocation,
                    destination_bin_location_id: Number(
                      destination_bin_location_id
                    ),
                    status: "is_scanned",
                    destination_scanned_date: dayjs().toDate(),
                  });
                  enqueueSnackbar(
                    `สแกน SN :\n${currentTrace.barcode}\nสำเร็จ`,
                    {
                      variant: "success",
                      style: { whiteSpace: "pre-line" },
                    }
                  );
                } else {
                  enqueueSnackbar(
                    `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                    {
                      variant: "error",
                      style: { whiteSpace: "pre-line" },
                    }
                  );
                }
              }
            }
          } else {
            // const totalSerial = trace.serial_list?.length;
            // const totalScanned = trace.serial_list
            //   ? trace.serial_list.filter(
            //       (serial) => serial.status === "is_scanned"
            //     ).length
            //   : 0;
            trace.serial_list?.forEach((serial, subIndex) => {
              if (serial.barcode === barcode.trim()) {
                if (
                  destination_bin_location_id === serial.source_bin_location_id
                ) {
                  enqueueSnackbar(
                    `กรุณาเลือกสถานที่ปลายทางที่ต่างจากสถานที่ต้นทาง`,
                    {
                      variant: "error",
                      style: { whiteSpace: "pre-line" },
                    }
                  );
                } else {
                  if (serial.status === "is_active") {
                    const currentTrace = getValues(`trace_entry_list.${index}`);
                    if (currentTrace.serial_list) {
                      const currentSerial = currentTrace.serial_list[subIndex];

                      const formatSerial = {
                        ...currentSerial,
                        destination_scanned_by: {
                          first_name: authUser?.first_name || "",
                          last_name: authUser?.last_name || "",
                          email: authUser?.email || "",
                          img_url: authUser?.img_url,
                          user_unique_id: authUser?.unique_id || "",
                        },
                        destination_bin_location: foundBinLocation,
                        destination_bin_location_id: Number(
                          destination_bin_location_id
                        ),
                        status: "is_scanned",
                        destination_scanned_date: dayjs().toDate(),
                      };
                      currentTrace.serial_list[subIndex] = formatSerial;
                      update(index, currentTrace);
                      enqueueSnackbar(
                        `สแกน SN :\n${currentSerial.barcode}\nสำเร็จ`,
                        {
                          variant: "success",
                          style: { whiteSpace: "pre-line" },
                        }
                      );
                    }
                  } else {
                    enqueueSnackbar(
                      `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                      {
                        variant: "error",
                        style: { whiteSpace: "pre-line" },
                      }
                    );
                  }
                }
              }
            });
          }
        });

        if (
          !fields.some((trace) => {
            if (trace.traceability === Traceability.Normal) {
              return trace.barcode === barcode.trim();
            } else {
              if (
                !trace.serial_list?.some(
                  (serial) => serial.barcode === barcode.trim()
                )
              ) {
                return false;
              } else {
                return true;
              }
            }
          })
        ) {
          enqueueSnackbar(
            `QR/Barcode\nสินค้านี้ไม่อยู่ในรายการ\nกรุณาสแกนใหม่`,
            {
              variant: "error",
              style: { whiteSpace: "pre-line" },
            }
          );
        }
      }
    }

    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  }, [
    allBinLocation,
    authUser?.email,
    authUser?.first_name,
    authUser?.img_url,
    authUser?.last_name,
    authUser?.unique_id,
    enqueueSnackbar,
    getBarcode,
    getValues,
    resetBarcode,
    update,
  ]);

  const notDraft = checkIsNotDraftOrEmptyStatus(status);

  const rescanBarcodeHandler = (index: number) => {
    const { id, ...otherFields } = fields[index];
    update(index, {
      ...otherFields,
      status: "is_active",
      destination_bin_location: undefined,
      destination_bin_location_id: undefined,
      destination_scanned_by: {},
      destination_scanned_date: undefined,
    });
  };

  return (
    <CustomizedBox maxWidth={1650} margin={"0 0 1.5rem 0"}>
      {!disabled && (
        <>
          <Typography fontWeight={"bold"} mb={2}>
            สแกน Barcode
          </Typography>
          {(!status || status === "draft") && (
            <Box maxWidth={996}>
              <Grid container spacing={1.5}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <ControlledTextField
                    label="Scan Barcode"
                    control={barcodeControl}
                    name="barcode"
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.shiftKey === false) {
                        onBarcodeSubmitHandler();
                      }
                    }}
                    disabled={!watchWarehouseId || !watchDestinationWarehouseId}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
      {status && status === "wait_transfer" && (
        <Box maxWidth={996}>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledSelect
                label={t("inventory.goods_transfer.destination_bin_location")}
                control={barcodeControl}
                name="destination_bin_location_id"
                options={binLocationOptions}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <ControlledTextField
                label="Scan Barcode"
                control={barcodeControl}
                name="barcode"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    onBarcodeSubmitWaitTransferHandler();
                  }
                }}
                disabled={
                  !watchWarehouseId ||
                  !watchBarcode("destination_bin_location_id")
                }
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {/* {fields?.length > 0 && ( */}
      <>
        <Typography fontWeight={"bold"} mb={2} mt={disabled ? 0 : 2}>
          รายการสินค้า
        </Typography>

        <TableContainer>
          <Table
            sx={{ minWidth: 650, overflow: "scroll" }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                bgcolor: "#ecf6fd",
              }}
            >
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align ? header.align : "center"}
                    key={index}
                    sx={{
                      px: 1,
                    }}
                    width={header.width}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      width={header.width}
                    >
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((trace, index) => (
                <Fragment key={trace.id}>
                  <TableRow>
                    <TableCell
                      align="center"
                      // sx={{
                      //   px: 1,
                      //   py: 1,
                      // }}
                      // width={
                      //   (headers && headers.length > 0
                      //     ? headers[0]?.width || 0
                      //     : 0) + 16
                      // }
                    >
                      <Typography fontSize={14}>{index + 1}</Typography>
                    </TableCell>
                    <TableCell
                      // sx={{
                      //   px: 1,
                      //   py: 1,
                      // }}
                      align="left"
                      // width={
                      //   (headers && headers.length > 0
                      //     ? headers[1]?.width || 0
                      //     : 0) + 16
                      // }
                    >
                      {trace.traceability === Traceability.Normal && (
                        <Typography fontSize={14}>
                          {formatDateTimeNoAMPM(trace.scanned_date)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      // sx={{
                      //   px: 1,
                      //   py: 1,
                      // }}
                      // width={
                      //   (headers && headers.length > 0
                      //     ? headers[1]?.width || 0
                      //     : 0) + 16
                      // }
                      align="center"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          minWidth: "90px",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          alt={trace.item_name}
                          src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                          sx={{
                            width: 60,
                            height: 60,
                            border: "1px solid #BEBEBE",
                            borderRadius: "2px",
                          }}
                          variant="square"
                        >
                          <ImageOutlinedIcon
                            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                            fontSize="medium"
                          />
                        </Avatar>
                      </Box>
                    </TableCell>
                    <TableCell
                    // width={
                    //   (headers && headers.length > 0
                    //     ? headers[2]?.width || 0
                    //     : 0) + 16
                    // }
                    >
                      <Typography fontSize={14}>
                        {trace.item_unique_id}
                      </Typography>
                      <Typography fontSize={14}>{trace.item_name}</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      // width={
                      //   (headers && headers.length > 0
                      //     ? headers[4]?.width || 0
                      //     : 0) + 16
                      // }
                    >
                      {trace.traceability === Traceability.Normal ? (
                        <ControlledNumberTextField
                          fullWidth
                          control={control}
                          name={`trace_entry_list.${index}.qty`}
                          error={Boolean(
                            errors &&
                              errors.trace_entry_list &&
                              errors.trace_entry_list[index] &&
                              errors.trace_entry_list[index]?.qty
                          )}
                          decimalScale={0}
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          FormHelperTextProps={{
                            style: { fontSize: "10px", textAlign: "end" },
                          }}
                          helperText={
                            trace.scanned_by
                              ? `จำนวนคงคลัง ${
                                  trace?.all_bin_locations
                                    ?.find(
                                      (bin) =>
                                        bin.id === trace.source_bin_location_id
                                    )
                                    ?.stock_qty?.toString() || "-"
                                }`
                              : undefined
                          }
                          sx={{
                            pr: "0.5rem",
                          }}
                          viewMode={notDraft}
                        />
                      ) : (
                        <TotalPostedQuantityCell nestedIndex={index} />
                      )}
                    </TableCell>
                    <TableCell
                      // width={
                      //   (headers && headers.length > 0
                      //     ? headers[5]?.width || 0
                      //     : 0) + 16
                      // }
                      align="center"
                    >
                      <Typography fontSize={14}>{trace.uom}</Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell>
                      {trace.traceability === Traceability.Normal ? (
                        trace.all_bin_locations &&
                        trace.all_bin_locations.length > 1 &&
                        !disabled &&
                        status !== "wait_transfer" ? (
                          <ControlledSelect
                            name={`trace_entry_list.${index}.source_bin_location_id`}
                            control={control}
                            label={""}
                            error={Boolean(
                              errors.trace_entry_list?.[index]
                                ?.source_bin_location_id
                            )}
                            helperText={
                              errors.trace_entry_list &&
                              errors.trace_entry_list?.[index]
                                ?.source_bin_location_id &&
                              errors.trace_entry_list?.[index]
                                ?.source_bin_location_id?.message
                            }
                            onChange={(e: any) => {
                              const matchingBinLocation =
                                trace.all_bin_locations?.find(
                                  (bin) => bin.id === e.target.value
                                );
                              if (matchingBinLocation) {
                                const currentTrace = getValues(
                                  `trace_entry_list.${index}`
                                );
                                update(index, {
                                  ...currentTrace,
                                  stock_qty: matchingBinLocation.stock_qty,
                                });
                                // setValue(
                                //   `trace_entry_list.${index}.stock_qty`,
                                //   matchingBinLocation.stock_qty
                                // );
                              }
                            }}
                            options={
                              trace.all_bin_locations?.map((bin) => ({
                                value: bin.id,
                                label: bin.name,
                              })) || []
                            }
                          />
                        ) : (
                          <Typography fontSize={14}>
                            {trace.source_bin_location?.name}
                          </Typography>
                        )
                      ) : null}
                    </TableCell>
                    {notDraft && (
                      <TableCell>
                        {trace.traceability === Traceability.Normal && (
                          <Typography fontSize={14}>
                            {trace.destination_bin_location?.name}
                          </Typography>
                        )}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {trace.scanned_by &&
                        Object.keys(trace.scanned_by).length > 0 && (
                          <Box display={"flex"} justifyContent={"center"}>
                            <CustomizedAvatar
                              avatars={[
                                {
                                  unique_id:
                                    trace.scanned_by.user_unique_id || "",
                                  first_name: trace.scanned_by.first_name || "",
                                  last_name: trace.scanned_by.last_name || "",
                                  img_url: trace.scanned_by.img_url
                                    ? trace.scanned_by.img_url[0]
                                    : "",
                                },
                              ]}
                            />
                          </Box>
                        )}
                    </TableCell>
                    {notDraft && (
                      <>
                        <TableCell>
                          {trace.traceability === Traceability.Normal && (
                            <Box display="flex" justifyContent={"center"}>
                              <CustomizedStatus status={trace.status} />
                            </Box>
                          )}
                        </TableCell>
                        <TableCell>
                          {!disabled && trace.status === "is_scanned" && (
                            <CustomizedTooltip title="สแกนใหม่">
                              <IconButton
                                onClick={() => rescanBarcodeHandler(index)}
                              >
                                <ReplayOutlinedIcon fontSize="small" />
                              </IconButton>
                            </CustomizedTooltip>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {trace.destination_scanned_by &&
                            Object.keys(trace.destination_scanned_by).length >
                              0 && (
                              <Box display={"flex"} justifyContent={"center"}>
                                <CustomizedAvatar
                                  avatars={[
                                    {
                                      unique_id:
                                        trace.destination_scanned_by
                                          .user_unique_id || "",
                                      first_name:
                                        trace.destination_scanned_by
                                          .first_name || "",
                                      last_name:
                                        trace.destination_scanned_by
                                          .last_name || "",
                                      img_url: trace.destination_scanned_by
                                        .img_url
                                        ? trace.destination_scanned_by
                                            .img_url[0]
                                        : "",
                                    },
                                  ]}
                                />
                              </Box>
                            )}
                        </TableCell>
                        <TableCell>
                          {trace.traceability === Traceability.Normal && (
                            <Typography fontSize={14}>
                              {formatDateTimeNoAMPM(
                                trace.destination_scanned_date
                              )}
                            </Typography>
                          )}
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      {trace.traceability === Traceability.Normal &&
                        trace.barcode}
                    </TableCell>
                    <TableCell />
                    {!disabled && status !== "wait_transfer" && (
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                  <SerialList
                    nestedIndex={index}
                    disabled={disabled}
                    status={status}
                  />
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
      {/* )} */}
    </CustomizedBox>
  );
};

export default GoodsTransferItemList;
