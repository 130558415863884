import { useTranslation } from "react-i18next";
import ControlledTextField from "../../Controller/ControlledTextField";
import { Typography, Grid, IconButton, Box } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import ControlledSelect from "../../Controller/ControlledSelect";
import { Fragment } from "react";
import { IContact } from "../../../types/Contact/contact";
import CustomizedButton from "../../Custom/CustomizedButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { contactChannelOptions } from "../../../utils/Global";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../../UI/LabelInput";
import { contactChannelFormatter } from "../../../utils/Formatter/Global";

const ContactChannel = () => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IContact>();
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    name: "contact_channel_list",
    control,
  });

  const [disabled] = useDisable();

  const addContactChannel = () => {
    append({
      contact_channel_type: "",
      contact_channel_info: "",
    });
  };

  const removeContactChannel = (index: number) => {
    remove(index);
  };

  return (
    <>
      <Typography fontWeight={600} my={2}>
        {t("contact.contact_channel.index")}
      </Typography>
      <Grid container spacing={1.5} alignItems={"center"}>
        {disabled &&
        fields.length === 1 &&
        fields[0].contact_channel_info?.trim().length +
          fields[0].contact_channel_type?.trim().length ===
          0 ? (
          <Grid item xs={12}>
            -
          </Grid>
        ) : (
          fields.map((channel, index) => (
            <Fragment key={channel.id}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                {disabled ? (
                  <LabelInput
                    label={t("contact.contact_channel.type")}
                    value={contactChannelFormatter(
                      getValues(
                        `contact_channel_list.${index}.contact_channel_type`
                      )
                    )}
                  />
                ) : (
                  <ControlledSelect
                    name={`contact_channel_list.${index}.contact_channel_type`}
                    control={control}
                    label={t("contact.contact_channel.type")}
                    options={contactChannelOptions}
                    error={Boolean(
                      errors.contact_channel_list?.[index]?.contact_channel_type
                    )}
                    helperText={
                      errors.contact_channel_list?.[index]?.contact_channel_type
                        ?.message
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Box display="flex" alignItems="center">
                  <ControlledTextField
                    name={`contact_channel_list.${index}.contact_channel_info`}
                    control={control}
                    label={t("contact.contact_channel.info")}
                    error={Boolean(
                      errors.contact_channel_list?.[index]?.contact_channel_info
                    )}
                    helperText={
                      errors.contact_channel_list?.[index]?.contact_channel_info
                        ?.message
                    }
                    viewMode={disabled}
                  />
                  {!disabled && index !== 0 && (
                    <IconButton onClick={() => removeContactChannel(index)}>
                      <CloseOutlinedIcon />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Fragment>
          ))
        )}
      </Grid>
      {!disabled && fields.length < 10 && (
        <CustomizedButton
          sx={{
            mt: 1,
          }}
          startAddIcon
          title={t("button.add") + t("contact.contact_channel.index")}
          onClick={addContactChannel}
        />
      )}
    </>
  );
};

export default ContactChannel;
