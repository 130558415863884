import { ReactNode } from "react";
import DashboardBottom from "../DashboardLayout/DashboardBottom";
import { useTheme, Toolbar, Box } from "@mui/material";
import { useNavbar } from "../../../hooks/use-navbar";

interface Props {
  children: ReactNode;
}

const BottomNavbar = ({ children }: Props) => {
  const theme = useTheme();
  const navbar = useNavbar();
  return (
    <DashboardBottom
      position="fixed"
      color="inherit"
      theme={theme}
      open={navbar?.sidebar.isSidebarOpen}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2,
        }}
      >
        <Box
          display="flex"
          justifyContent={"flex-end"}
          flexGrow={1}
          maxWidth={1040}
        >
          {children}
        </Box>
      </Toolbar>
    </DashboardBottom>
  );
};

export default BottomNavbar;
