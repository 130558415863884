import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IVehicle } from "types/Logistic/vehicle";

export const useVehicleDescription = () => {
  const [openTrailerTable, setOpenTrailerTable] = useState<boolean>(false);
  const [trailerId, setTrailerId] = useState<string[]>([]);
  const [trailerIdSnapshot, setTrailerIdSnapshot] = useState<string[]>([]);

  const openTrailerTableHandler = async () => {
    setOpenTrailerTable(true);
  };
  const closeTrailerTableHandler = () => {
    setOpenTrailerTable(false);
  };

  const { control, setValue, reset } = useFormContext();

  const trailerUniqueId = useWatch({
    control,
    name: "trailer.unique_id",
  });

  const finishTrailerSelect = (data: IVehicle) => {
    if (data) {
      setValue("trailer_unique_id", data.unique_id);
      setValue("trailer_id", data.id);
    } else {
      reset((prev) => ({
        ...prev,
        trailer_unique_id: "",
        trailer_id: null,
      }));
    }
  };

  useEffect(() => {
    if (trailerUniqueId) {
      setTrailerId([trailerUniqueId]);
      setTrailerIdSnapshot([trailerUniqueId]);
    }
  }, [trailerUniqueId]);

  return {
    openTrailerTable,
    setOpenTrailerTable,
    trailerId,
    setTrailerId,
    trailerIdSnapshot,
    setTrailerIdSnapshot,
    openTrailerTableHandler,
    closeTrailerTableHandler,
    finishTrailerSelect,
  };
};
