import { ITab } from "types/global";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import ItemInfo from "components/Form/Inventory/Item/ItemInfo";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedTab from "components/Custom/CustomizedTab";
import CustomizedScrollbar from "components/Custom/CustomizedScrollbar";

import ItemGeneral from "components/Form/Inventory/Item/ItemGeneral";
import ItemWarehouse from "components/Form/Inventory/Item/ItemWarehouse";
import ItemPurchase from "components/Form/Inventory/Item/ItemPurchase";
import ItemSales from "components/Form/Inventory/Item/ItemSales";
import ItemStock from "components/Form/Inventory/Item/ItemStock";
import { useEffect, useState } from "react";

const DetailTab = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("subtab");

  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const type = watch("type");
  const isStockable = watch("is_stockable");

  const [tabs, setTabs] = useState<ITab[]>([]);

  useEffect(() => {
    const allTab = [
      {
        testid: "item-tab-general",
        label: t("inventory.item.general.index"),
        path: `${pathname}?tab=item&subtab=general`,
        errors:
          Boolean(errors.cate_1_id) ||
          Boolean(errors.width) ||
          Boolean(errors.length) ||
          Boolean(errors.thick) ||
          Boolean(errors.height) ||
          Boolean(errors.width_uom_id) ||
          Boolean(errors.length_uom_id) ||
          Boolean(errors.thickness_uom_id) ||
          Boolean(errors.height_uom_id),
      },
      {
        testid: "item-tab-warehouse",
        label: t("inventory.item.warehouse.index"),
        path: `${pathname}?tab=item&subtab=warehouse`,
        errors:
          Boolean(errors.stock_uom_id) ||
          Boolean(errors.barcode) ||
          Boolean(errors.uom_conversion_list),
      },
      {
        testid: "item-tab-purchase",
        label: t("inventory.item.purchase.index"),
        path: `${pathname}?tab=item&subtab=purchase`,
        errors:
          Boolean(errors.purchase_price) ||
          Boolean(errors.min_purchase_request_qty),
      },
      {
        testid: "item-tab-sales",
        label: t("inventory.item.sales.index"),
        path: `${pathname}?tab=item&subtab=sales`,
        errors: Boolean(errors.sale_price) || Boolean(errors.special_price),
      },
      {
        testid: "item-tab-stock",
        label: t("inventory.item.stock.index"),
        path: `${pathname}?tab=item&subtab=stock`,
        errors: Boolean(errors.min_stock_qty) || Boolean(errors.max_stock_qty),
      },
    ];
    if (isStockable && type === "normal") {
      setTabs(allTab);
    } else {
      const newTabs = allTab.splice(0, 4);
      setTabs(newTabs);
    }
  }, [
    pathname,
    type,
    isStockable,
    t,
    errors.cate_1_id,
    errors.stock_uom_id,
    errors.width,
    errors.length,
    errors.thick,
    errors.height,
    errors.width_uom_id,
    errors.length_uom_id,
    errors.thickness_uom_id,
    errors.height_uom_id,
    errors.barcode,
    errors.uom_conversion_list,
    errors.purchase_price,
    errors.min_purchase_request_qty,
    errors.sale_price,
    errors.special_price,
    errors.min_stock_qty,
    errors.max_stock_qty,
  ]);

  const currentTab =
    pathname + (tab ? `?tab=item&subtab=${tab}` : "?tab=item&subtab=general");

  const renderTab = () => {
    switch (tab) {
      case "general":
        return <ItemGeneral />;
      case "warehouse":
        return <ItemWarehouse />;
      case "purchase":
        return <ItemPurchase />;
      case "sales":
        return <ItemSales />;
      case "stock":
        return <ItemStock />;
      default:
        return <ItemGeneral />;
    }
  };

  return (
    <>
      <ItemInfo />
      <CustomizedBox>
        <CustomizedScrollbar>
          <CustomizedTab subtab tabs={tabs} currentTab={currentTab} />
        </CustomizedScrollbar>
        <CustomizedScrollbar>{renderTab()}</CustomizedScrollbar>
      </CustomizedBox>
    </>
  );
};

export default DetailTab;
