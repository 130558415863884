import { Typography } from "@mui/material";
import { FieldArrayWithId, useFormContext, useWatch } from "react-hook-form";
import { numberFormatter } from "../../../../utils/Formatter/Global";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import { useEffect } from "react";

type Props = {
  field: FieldArrayWithId<IDeliveryTrip, "expenses_list", "id">;
  index: number;
};

const WithholdingTaxCell = ({ field, index }: Props) => {
  const { control, setValue } = useFormContext<IDeliveryTrip>();

  const item_pre_vat_amount = useWatch({
    control,
    name: `expenses_list.${index}.pre_vat_amount`,
  });

  const withholding_tax_type = useWatch({
    control,
    name: `expenses_list.${index}.withholding_tax_type`,
  });

  const withholding_tax_value = useWatch({
    control,
    name: `expenses_list.${index}.withholding_tax_value`,
  });

  useEffect(() => {
    setValue(
      `expenses_list.${index}.withholding_tax_value`,
      (item_pre_vat_amount *
        (withholding_tax_type === "ยังไม่ระบุ" ||
        withholding_tax_type === "ไม่มี" ||
        withholding_tax_type === "" ||
        withholding_tax_type === undefined
          ? 0
          : parseFloat(withholding_tax_type))) /
        100
    );
  }, [index, item_pre_vat_amount, setValue, withholding_tax_type]);
  return (
    <>
      <Typography>
        {field.withholding_tax_type}
        {field.withholding_tax_type !== "ไม่มี" &&
        field.withholding_tax_type !== "ยังไม่ระบุ"
          ? " %"
          : ""}
      </Typography>
      {field.withholding_tax_type !== "ไม่มี" &&
        field.withholding_tax_type !== "ยังไม่ระบุ" && (
          <Typography variant="caption">
            {numberFormatter(withholding_tax_value)} บาท
          </Typography>
        )}
    </>
  );
};

export default WithholdingTaxCell;
