import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { Typography } from "@mui/material";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import StockReportTable from "components/Table/Inventory/Reports/StockReport";
import HeaderLayout from "components/UI/HeaderLayout";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "types/global";
import CustomizedButton from "components/Custom/CustomizedButton";
import { useInventoryReport } from "hooks/Inventory/use-inventory-report";
import { InventoryReportType } from "generated/wms";
import { useSearchParams } from "react-router-dom";

const Stock = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const statusFilter = searchParams.get("filter");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
      to: "/inventory/report",
    },
    {
      name: `${t("report")}${t("inventory.itemStock.index")}`,
    },
  ];

  const gridRef = useRef<AgGridReact>(null);

  const { onBtnExport, isExporting, isFetching } = useInventoryReport(
    gridRef,
    InventoryReportType.CurrentStockByWarehouse
  );

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout noMaxWidth>
        <Typography variant="h5">{`${t("report")}${t(
          "inventory.itemStock.index"
        )}`}</Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
          disabled={isExporting && isFetching}
        />
      </HeaderLayout>
      <StockReportTable gridRef={gridRef} status={statusFilter} />
    </>
  );
};

export default Stock;
