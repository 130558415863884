import { Box, IconButton, Typography } from "@mui/material";
import ControlledTextField from "components/Controller/ControlledTextField";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import LaunchIcon from "@mui/icons-material/Launch";
import { IDefaultForm } from "types/global";
import { useState } from "react";
import { useWatch } from "react-hook-form";

interface Props {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: boolean;
  field: any;
  index: number;
  documentType: string;
}

const ItemNameCell = ({ control, field, errors, index, disabled }: Props) => {
  const watchItemList = useWatch({
    control,
    name: `ma_item_list.${index}`,
  });

  const watchItemSkuDetail = watchItemList.item_sku_desc || "";

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const maxChars = 100;

  const focusHandler = () => {
    setIsFocused(true);
  };

  const blurHandler = () => {
    setIsFocused(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "stretch",
          flex: 1,
          textAlign: "left",
        }}
      >
        {disabled ? (
          <Typography
            variant="body2"
            align="left"
            mt={1}
            color="primary"
            onClick={() =>
              window.open(
                `/inventory/item/${field.item_id}?tab=item&subtab=general`,
                "_blank"
              )
            }
            sx={{ cursor: "pointer" }}
          >
            {field.item_name}
          </Typography>
        ) : (
          <ControlledTextField
            disabled={disabled}
            placeholder="ชื่อสินค้า"
            control={control}
            error={Boolean(
              errors?.ma_item_list &&
                errors?.ma_item_list[index] &&
                errors?.ma_item_list[index]?.item_name
            )}
            name={`ma_item_list.${index}.item_name`}
            sx={{ mt: 1 }}
          />
        )}
        <Typography
          variant="overline"
          sx={{
            my: 0,
            ml: !disabled ? 2 : 0,
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {field.item_unique_id}
        </Typography>
        {disabled ? (
          <Typography
            variant="body2"
            align="left"
            gutterBottom
            sx={{
              whiteSpace: "normal",
            }}
          >
            {field.item_sku_desc}
          </Typography>
        ) : (
          <ControlledTextField
            disabled={disabled}
            control={control}
            name={`ma_item_list.${index}.item_sku_desc`}
            placeholder="คำอธิบาย"
            multiline
            minRows={1}
            maxRows={5}
            sx={{ mb: 1 }}
            error={Boolean(
              errors?.ma_item_list &&
                errors?.ma_item_list[index] &&
                errors?.ma_item_list[index]?.item_sku_desc
            )}
            viewModeNoHelperText
            helperText={
              isFocused ? `${watchItemSkuDetail.length}/${maxChars}` : undefined
            }
            onFocus={focusHandler}
            onBlur={blurHandler}
            FormHelperTextProps={{
              style: {
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 0,
              },
            }}
            inputProps={{
              maxLength: maxChars,
            }}
          />
        )}
      </Box>
      {!disabled && (
        <CustomizedTooltip title="กดเพื่อดูรายละเอียดสินค้า">
          <IconButton
            size="small"
            color="primary"
            onClick={() =>
              window.open(
                `/inventory/item/${field.item_id}?tab=item&subtab=general`,
                "_blank"
              )
            }
          >
            <LaunchIcon fontSize="small" />
          </IconButton>
        </CustomizedTooltip>
      )}
    </Box>
  );
};

export default ItemNameCell;
