import { gql } from "graphql-request";

export const QUOTATION_VIEW = gql`
  query QuotationViewsAggrid($aggridInput: AnyAggridInput) {
    QuotationViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        issue_date
        due_date
        customer_name
        net_amount
        created_by
        related_user_list
        related_user_object_list {
          user_id
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        aggrid_status
      }
    }
  }
`;

export const BILLING_NOTE_VIEW = gql`
  query BillingNoteViewsAggrid($aggridInput: AnyAggridInput) {
    BillingNoteViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        issue_date
        due_date
        customer_name
        net_amount
        created_by
        created_by_object {
          user_id
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        related_user_list
        related_user_object_list {
          user_id
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        aggrid_status
      }
    }
  }
`;

export const MAINTENANCE_VIEW = gql`
  query MaintenanceViewAggrid($aggridInput: AnyAggridInput) {
    MaintenanceViewAggrid(aggridInput: $aggridInput) {
      results {
        id
        unique_id
        main_status
        flag_status
        aggrid_status
        vehicle_id
        vehicle_no
        vehicle_license_plate
        trailer_id
        trailer_no
        trailer_license_plate
        type
        garage_type
        garage_name
        accident_date
        net_amount
        is_insurance_fully_paid
        created_by
        driver_list
        created_date
        last_updated_date
        ma_start_date
        ma_end_date
        reference_document_unique_id_list
        claim_no
      }
      count
    }
  }
`;
