import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";

import { useDisable } from "../../../hooks/use-disable";

import {
  FormControl,
  FormHelperText,
  Grid,
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import ControlledTextField from "../../Controller/ControlledTextField";
import { IUser } from "../../../types/Auth/user";

const ChangePasswordDetail = () => {
  const { t } = useTranslation();

  const [disabled] = useDisable();
  const {
    control,
    formState: { errors },
  } = useFormContext<IUser>();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <>
      <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
        {t("user.account.password")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Controller
            name="old_password"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                disabled={disabled}
                required
              >
                <InputLabel
                  htmlFor="outlined-adornment-password3"
                  error={Boolean(
                    (field.value?.length ?? 0) > 200 || errors.old_password
                  )}
                >
                  {t("user.account.old_password")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password3"
                  type={showOldPassword ? "text" : "password"}
                  endAdornment={
                    !disabled ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowOldPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showOldPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <></>
                    )
                  }
                  label={t("user.account.old_password")}
                  {...field}
                  autoComplete="new-password"
                  error={Boolean(
                    (field.value?.length ?? 0) > 200 || errors.old_password
                  )}
                />
                <FormHelperText
                  id="helper-text-password1"
                  error={Boolean(
                    (field.value?.length ?? 0) > 200 || errors.old_password
                  )}
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {((field.value?.length ?? 0) > 200 &&
                    "กรอกได้ไม่เกิน 200 ตัวอักษร") ||
                    errors.old_password?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Box width="100%" />
        <Grid item xs={12} sm={12} md={6}>
          <Controller
            name="new_password"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                disabled={disabled}
                required
              >
                <InputLabel
                  htmlFor="outlined-adornment-password2"
                  error={Boolean(
                    (field.value?.length ?? 0) > 200 || errors.new_password
                  )}
                >
                  {t("user.account.new_password")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password2"
                  type={showNewPassword ? "text" : "password"}
                  endAdornment={
                    !disabled ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <></>
                    )
                  }
                  label={t("user.account.new_password")}
                  {...field}
                  error={Boolean(
                    (field.value?.length ?? 0) > 200 || errors.new_password
                  )}
                />
                <FormHelperText
                  id="helper-text-password1"
                  error={Boolean(
                    (field.value?.length ?? 0) > 200 || errors.new_password
                  )}
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {((field.value?.length ?? 0) > 200 &&
                    "กรอกได้ไม่เกิน 200 ตัวอักษร") ||
                    errors.new_password?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            control={control}
            type="password"
            name="confirm_new_password"
            error={Boolean(errors.confirm_new_password)}
            helperText={errors.confirm_new_password?.message}
            label={t("user.account.confirm_password")}
            disabled={disabled}
            required
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ChangePasswordDetail;
