import { gql } from "graphql-request";

export const DELIVERY_TRIP_REPORT = gql`
    query DeliveryReportViewsAggrid($aggridInput: AnyAggridInput) {
        DeliveryReportViewsAggrid(aggridInput: $aggridInput) {
            count
            results {
                unique_id
                reference_document
                status
                flag_status
                job_no
                vehicle_no
                vehicle_license_plate
                trailer_license_plate
                primary_driver_name
                secondary_driver_name
                related_user_list
                created_date
                issue_date
                customer_unique_id
                customer_name
                customer_identity_no
                customer_phone
                customer_email
                billing_address_name
                billing_address_sub_district
                billing_address_district
                billing_address_province
                billing_address_postal_code
                billing_address_country 
                vehicle_type
                container_type
                item_type
                item_amount
                expected_temperature
                is_frozen
                plan_start_date
                plan_end_date
                actual_start_date
                actual_end_date
                start_kilometer 
                end_kilometer 
                total_kilometer_distance 
                temperature_start_hours 
                temperature_end_hours 
                total_temperature_hours 
                fuel_total_quota
                fuel_appa
                fuel_actual_fleet
                fuel_other
                fuel_total_usage
                remark
            }
        }
    }
`;

export const DELIVERY_TRIP_WORK_REPORT = gql`
    query DeliveryTripWorkReportViewsAggrid($aggridInput: AnyAggridInput) {
        DeliveryTripWorkReportViewsAggrid(aggridInput: $aggridInput) {
            count
            results {
                unique_id
                reference_document
                status
                flag_status
                job_no
                vehicle_no
                vehicle_license_plate
                trailer_license_plate
                primary_driver_name
                secondary_driver_name
                related_user_list
                created_date
                issue_date
                customer_unique_id
                customer_name
                customer_identity_no
                customer_phone
                customer_email
                billing_address_name
                billing_address_sub_district
                billing_address_district
                billing_address_province
                billing_address_postal_code
                billing_address_country 
                vehicle_type
                container_type
                item_type
                item_amount
                expected_temperature
                is_frozen
                plan_start_date
                plan_end_date
                actual_start_date
                actual_end_date
                start_kilometer
                end_kilometer
                total_kilometer_distance
                temperature_start_hours
                temperature_end_hours
                total_temperature_hours
                fuel_total_quota
                fuel_appa
                fuel_actual_fleet
                fuel_other
                fuel_total_usage
                l_id
                location_unique_id
                location_name
                type
                distance
                coordinator_name
                coordinator_phone
                special_request
                work_customer_name
                reference_code
                seal_no
                work_end_date
                arrived_kilometer
                shipping_cost
                secondary_driver_cost
                drop_cost
                max_shipping_cost
                work_total_amount
                remark
            }
        }
    }
`;

export const DELIVERY_TRIP_EXPENSES_REPORT = gql`
    query DeliveryTripExpensesReportViewsAggrid($aggridInput: AnyAggridInput) {
        DeliveryTripExpensesReportViewsAggrid(aggridInput: $aggridInput) {
            count
            results {
                unique_id
                reference_document
                status
                flag_status
                job_no
                vehicle_no
                vehicle_license_plate
                trailer_license_plate
                primary_driver_name
                secondary_driver_name
                related_user_list
                created_date
                issue_date
                customer_unique_id
                customer_name
                customer_identity_no
                customer_phone
                customer_email
                billing_address_name
                billing_address_sub_district
                billing_address_district
                billing_address_province
                billing_address_postal_code
                billing_address_country 
                vehicle_type
                container_type
                item_type
                item_amount
                expected_temperature
                is_frozen
                plan_start_date
                plan_end_date
                actual_start_date
                actual_end_date
                start_kilometer
                end_kilometer
                total_kilometer_distance
                temperature_start_hours
                temperature_end_hours
                total_temperature_hours
                fuel_total_quota
                fuel_appa
                fuel_actual_fleet
                fuel_other
                fuel_total_usage
                item_id
                item_unique_id
                item_name
                item_sku_desc
                qty
                uom_name
                price_per_unit
                discount
                vat_percentage
                pre_vat_amount
                withholding_tax_type
                expenses_post_discount_amount
                expenses_vat_exempted_amount
                expenses_vat_0_percent_amount
                expenses_vat_7_percent_amount
                expenses_vat_amount
                expenses_net_amount
                expenses_withholding_tax_amount
                expenses_total_amount
                remark
            }
        }
    }
`;

export const DELIVERY_TRIP_INCOME_REPORT = gql`
    query DeliveryTripIncomeReportViewsAggrid($aggridInput: AnyAggridInput) {
        DeliveryTripIncomeReportViewsAggrid(aggridInput: $aggridInput) {
            count
            results {
                unique_id
                reference_document
                status
                flag_status
                job_no
                vehicle_no
                vehicle_license_plate
                trailer_license_plate
                primary_driver_name
                secondary_driver_name
                related_user_list
                created_date
                issue_date
                customer_unique_id
                customer_name
                customer_identity_no
                customer_phone
                customer_email
                billing_address_name
                billing_address_sub_district
                billing_address_district
                billing_address_province
                billing_address_postal_code
                billing_address_country 
                vehicle_type
                container_type
                item_type
                item_amount
                expected_temperature
                is_frozen
                plan_start_date
                plan_end_date
                actual_start_date
                actual_end_date
                start_kilometer
                end_kilometer
                total_kilometer_distance
                temperature_start_hours
                temperature_end_hours
                total_temperature_hours
                fuel_total_quota
                fuel_appa
                fuel_actual_fleet
                fuel_other
                fuel_total_usage
                item_id
                item_unique_id
                item_name
                item_sku_desc
                qty
                uom_name
                price_per_unit
                discount
                vat_percentage
                pre_vat_amount
                withholding_tax_type
                income_post_discount_amount
                income_vat_exempted_amount
                income_vat_0_percent_amount
                income_vat_7_percent_amount
                income_vat_amount
                income_net_amount
                income_withholding_tax_amount
                income_total_amount
                remark
            }
        }
    }
`;

export const DELIVERY_REPORT = gql`
    query DeliveryReport($input: DeliveryReportGenerateInput) {
        GenerateDeliveryReport(input: $input)
    }
`;