import { Box, Stack, Typography } from "@mui/material";
import CustomizedButton from "components/Custom/CustomizedButton";
import Accident from "components/Form/Logistic/Maintenance/Accident";
import GreenCheckIcon from "components/UI/GreenCheckIcon";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
// import ModalUI from "components/UI/Modal/ModalUI";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import dayjs from "dayjs";
import { ActivityLogDocumentType, ActivityType } from "generated/general";
import {
  MaintenanceCreateInput,
  MaintenanceType,
  MaintenanceUpdateInput,
  SalesModelType,
  useMaintenanceCreateMutation,
  useMaintenanceUpdateMutation,
  MaintenanceFindUniqueQuery,
} from "generated/sales";

import { useActivityLog } from "hooks/use-activity-log";
import { useConfirmation } from "hooks/use-confirmation";
import { useDisable } from "hooks/use-disable";
// import { useModal } from "hooks/use-modal";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { errorMessageFormatter } from "utils/Global";

import LoadingUI from "components/UI/LoadingUI";
import { IMaintenance } from "types/Logistic/maintenance";
import MaintenanceHeader from "components/Form/Logistic/Maintenance/Header";
import MaintenanceVehicle from "components/Form/Logistic/Maintenance/Vehicle";
import MaintenanceInfo from "components/Form/Logistic/Maintenance/MaintenanceInfo";
import {
  maintenanceCreatePayload,
  maintenanceDataFormatter,
  maintenanceUpdatePayload,
} from "utils/Formatter/Logistic/Maintenance";
import MaintenanceAttachment from "components/Form/Logistic/Maintenance/Attachment";
import MaintenanceItemList from "components/Table/Logistic/Maintenance/ItemList";
import MaintenanceFooter from "components/Form/Logistic/Maintenance/Footer";
import MaintenanceTask from "components/Form/Logistic/Maintenance/MaintenanceTask";
import { useStateContext } from "contexts/auth-context";

type Props = {
  refetch: () => void;
  data?: MaintenanceFindUniqueQuery;
};

const DocumentInfoTab = ({ refetch, data }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    watch,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { dirtyFields },
  } = useFormContext<IMaintenance>();
  const {
    state: { authUser },
  } = useStateContext();

  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { createActivityLog } = useActivityLog();

  const [, setDisabled] = useDisable();

  const type = watch("type");

  // const { modal, openModalHandler, closeModalHandler } = useModal();

  const graphqlClient = createGraphQLClientWithMiddleware("sales");

  useEffect(() => {
    if (state) {
      const {
        created_date,
        issue_date,
        ma_start_date,
        ma_end_date,
        accident_date,
        copied_id,
        copied_unique_id,
        ...otherState
      } = state;
      const formatState = {
        ...otherState,
        issue_date: dayjs(),
        ma_start_date: dayjs(),
        ma_end_date: dayjs(),
        created_by: authUser
          ? {
              user_unique_id: authUser?.unique_id,
              email: authUser?.email,
              first_name: authUser?.first_name,
              last_name: authUser?.last_name,
              img_url: authUser?.img_url,
            }
          : null,
      };
      reset(formatState);
    }
  }, [reset, state, authUser]);

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");
  const cancelled = flagStatus.includes("cancelled");

  const status = !cancelled ? mainStatus : aggrid_status;

  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    if (status && status !== "draft") {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [setDisabled, status]);

  const { mutateAsync: create, isLoading: isCreating } =
    useMaintenanceCreateMutation<Error>(graphqlClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useMaintenanceUpdateMutation<Error>(graphqlClient);

  const refreshTaskList = () => {
    setValue(
      "ma_list",
      getValues("ma_list").filter(
        (element) => element.name && element.name !== ""
      )
    );
  };

  const draftHandler = async (data: IMaintenance) => {
    try {
      if (!id) {
        const formatPayload = maintenanceCreatePayload(data, "draft");
        const { MaintenanceCreate } = await create({
          createInput: (await formatPayload) as MaintenanceCreateInput,
        });
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.Maintenance,
            reference_id: MaintenanceCreate?.id || 0,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: MaintenanceCreate?.id,
                unique_id: MaintenanceCreate?.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.Maintenance,
          reference_id: MaintenanceCreate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });
        navigate(`/logistic/maintenance/${MaintenanceCreate?.id}`);
      } else {
        const formatPayload = await maintenanceUpdatePayload(data, "draft");
        const { MaintenanceUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: formatPayload as MaintenanceUpdateInput,
        });
        const isDirty = Object.keys(dirtyFields)?.length > 0;
        if (isDirty) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.Maintenance,
            reference_id: MaintenanceUpdate?.id || 0,
            activity_detail: {
              secondary_operation: ActivityType.Edit,
            },
          });
        }
      }
      enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
      refreshTaskList();
    } catch (err) {
      const formatError = errorMessageFormatter(err, "document");
      enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const waitMaintenanceHandler = async (data: IMaintenance) => {
    try {
      if (!id) {
        const formatPayload = maintenanceCreatePayload(
          data,
          "wait_maintenance"
        );
        const { MaintenanceCreate } = await create({
          createInput: (await formatPayload) as MaintenanceCreateInput,
        });
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.Maintenance,
            reference_id: MaintenanceCreate?.id || 0,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: MaintenanceCreate?.id,
                unique_id: MaintenanceCreate?.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.Maintenance,
          reference_id: MaintenanceCreate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_maintenance",
          },
        });
        navigate(`/logistic/maintenance/${MaintenanceCreate?.id}`);
      } else {
        const formatPayload = await maintenanceUpdatePayload(
          data,
          "wait_maintenance"
        );
        const { MaintenanceUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: formatPayload as MaintenanceUpdateInput,
        });
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.Maintenance,
          reference_id: MaintenanceUpdate?.id || 0,
          activity_detail: {
            prev_status: data.aggrid_status,
            curr_status: "wait_maintenance",
          },
        });
      }
      enqueueSnackbar(`สร้างใบแจ้งซ่อมสำเร็จ`, {
        variant: "success",
      });
      await refetch();
      refreshTaskList();
    } catch (err) {
      const formatError = errorMessageFormatter(err, "document");
      enqueueSnackbar(formatError || `สร้างใบแจ้งซ่อมไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const maintenancingHandler = async (data: IMaintenance) => {
    try {
      const formatPayload = await maintenanceUpdatePayload(
        data,
        "maintenancing"
      );
      const { MaintenanceUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: formatPayload as MaintenanceUpdateInput,
      });

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.Maintenance,
        reference_id: MaintenanceUpdate?.id || 0,
        activity_detail: {
          prev_status: data.aggrid_status,
          curr_status: "maintenancing",
        },
      });

      enqueueSnackbar(`ปรับสถานะสำเร็จ`, {
        variant: "success",
      });
      await refetch();
      refreshTaskList();
    } catch (err) {
      enqueueSnackbar(`ปรับสถานะไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const finishedHandler = async (data: IMaintenance) => {
    try {
      const formatPayload = await maintenanceUpdatePayload(data, "finished");
      const { MaintenanceUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: formatPayload as MaintenanceUpdateInput,
      });

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.Maintenance,
        reference_id: MaintenanceUpdate?.id || 0,
        activity_detail: {
          prev_status: data.aggrid_status,
          curr_status: "finished",
        },
      });

      enqueueSnackbar(`ปรับสถานะสำเร็จ`, {
        variant: "success",
      });
      await refetch();
      refreshTaskList();
    } catch (err) {
      enqueueSnackbar(`ปรับสถานะไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setIsEdit(false);
    setDisabled(true);

    const formatData = maintenanceDataFormatter(
      data?.MaintenanceFindUnique
    ) as IMaintenance;

    reset(formatData);
  };

  const editHandler = async (data: IMaintenance) => {
    try {
      const formatPayload = await maintenanceUpdatePayload(
        data,
        data.main_status
      );

      const { MaintenanceUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: formatPayload as MaintenanceUpdateInput,
      });
      const isDirty = Object.keys(dirtyFields)?.length > 0;

      if (isDirty) {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.Maintenance,
          reference_id: MaintenanceUpdate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Edit,
          },
        });
      }

      enqueueSnackbar(`${t("sentence.edit")}สำเร็จ`, {
        variant: "success",
      });

      await refetch();
      setIsEdit(false);
      setDisabled(true);
    } catch (err) {
      enqueueSnackbar(`${t("sentence.edit")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const fullyPaidInsuranceHandler = async () => {
    try {
      const data = getValues();
      const formatPayload = await maintenanceUpdatePayload(
        { ...data, is_insurance_fully_paid: true },
        data.main_status
      );

      const { MaintenanceUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: formatPayload as MaintenanceUpdateInput,
      });

      await createActivityLog({
        activity_type: ActivityType.Edit,
        document_type: ActivityLogDocumentType.Maintenance,
        reference_id: MaintenanceUpdate?.id || 0,
        activity_detail: {
          secondary_operation: ActivityType.Edit,
        },
      });

      enqueueSnackbar(`${t("sentence.edit")}สำเร็จ`, {
        variant: "success",
      });

      await refetch();
      setIsEdit(false);
      setDisabled(true);
    } catch (err) {
      enqueueSnackbar(`${t("sentence.edit")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      const data = getValues();
      const flag_status =
        data?.flag_status && data.flag_status.length > 0
          ? data.flag_status.includes("cancelled")
            ? [...data.flag_status]
            : [...data.flag_status, "cancelled"]
          : ["cancelled"];
      const formatPayload = await maintenanceUpdatePayload(
        data,
        data.main_status
      );
      const { MaintenanceUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: {
          ...formatPayload,
          flag_status,
        } as MaintenanceUpdateInput,
      });

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.Maintenance,
        reference_id: MaintenanceUpdate?.id || 0,
        activity_detail: {
          secondary_operation: ActivityType.Edit,
          prev_status: data.aggrid_status,
          curr_status: "cancelled",
        },
      });

      enqueueSnackbar(
        `${t("button.cancel")}${t("logistic.maintenance.index")}สำเร็จ`,
        {
          variant: "success",
        }
      );

      await refetch();
    } catch (err) {
      enqueueSnackbar(
        `${t("button.cancel")}${t("logistic.maintenance.index")}ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const errorHandler = (error: any) => {
    let emptyTraceError = false;
    let emptySnError = false;
    let noQtyError = false;

    if (error?.type?.message === "กรุณาระบุประเภท") {
      enqueueSnackbar("กรุณาระบุประเภท", { variant: "error" });
    }
    if (error?.source_warehouse_id?.message === "กรุณาเลือกคลัง") {
      enqueueSnackbar("กรุณาเลือกคลัง", { variant: "error" });
    }
    if (
      error?.ma_item_list?.message === "กรุณาเพิ่มสินค้า" ||
      error?.ma_item_list?.root?.message === "กรุณาเพิ่มสินค้า"
    ) {
      emptyTraceError = true;
    }
    if (error?.trace_entry_list && Array.isArray(error.trace_entry_list)) {
      error.trace_entry_list.forEach((trace: any) => {
        if (trace.qty?.message === "กรุณาระบุจำนวนที่นำเข้า") {
          noQtyError = true;
        }
        if (trace.serial_list?.message === "กรุณาเพิ่ม SN ในรายการสินค้า") {
          emptySnError = true;
        }
        if (Array.isArray(trace.serial_list)) {
          trace.serial_list.forEach((serial: any) => {
            if (serial.qty?.message === "กรุณาระบุจำนวนที่นำเข้า") {
              noQtyError = true;
            }
          });
        }
      });
    }

    if (emptyTraceError) {
      enqueueSnackbar("กรุณาเพิ่มสินค้า", { variant: "error" });
    }
    if (emptySnError)
      enqueueSnackbar("กรุณาเพิ่ม SN ในรายการสินค้า", { variant: "error" });
    if (noQtyError)
      enqueueSnackbar("กรุณาระบุจำนวนมากกว่า 0", { variant: "error" });
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(async () => {
    if (status === "wait_maintenance") {
      handleSubmit(maintenancingHandler, errorHandler)();
    } else {
      handleSubmit(finishedHandler)();
    }
  });

  const renderButton = (status: string | undefined) => {
    switch (status) {
      case "draft":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isMutating}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              variant="contained"
              title={t("status.wait_maintenance")}
              disabled={isMutating}
              onClick={handleSubmit(waitMaintenanceHandler)}
            />
          </>
        );
      case "wait_maintenance":
        if (isEdit) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={cancelEditHandler}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(editHandler)}
                disabled={isUpdating}
              />
            </>
          );
        } else {
          return (
            <>
              <CustomizedButton
                variant="contained"
                title={t("status.maintenancing")}
                onClick={openConfirmationHandler}
              />
            </>
          );
        }
      case "maintenancing":
        if (isEdit) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={cancelEditHandler}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(editHandler)}
                disabled={isUpdating}
              />
            </>
          );
        } else {
          return (
            <>
              <CustomizedButton
                variant="contained"
                title={t("status.finished")}
                onClick={openConfirmationHandler}
              />
            </>
          );
        }
      case "finished":
        if (isEdit) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={cancelEditHandler}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(editHandler)}
                disabled={isUpdating}
              />
            </>
          );
        } else {
          return;
        }
      case "cancelled":
        return;
      default:
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isMutating}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              variant="contained"
              title={t("status.wait_maintenance")}
              disabled={isMutating}
              onClick={handleSubmit(waitMaintenanceHandler)}
            />
          </>
        );
    }
  };

  const disabledStatus = ["maintenance", "finished"].includes(status);

  const isMutating = isCreating || isUpdating;

  if (id && isMutating) {
    return <LoadingUI />;
  }

  return (
    <>
      <MaintenanceHeader
        editClickHandler={editClickHandler}
        cancelHandler={cancelHandler}
        fullPaidInsuranceHandler={fullyPaidInsuranceHandler}
        isEdit={isEdit}
      />
      <MaintenanceVehicle />
      <MaintenanceInfo forceDisabled={disabledStatus} />
      <MaintenanceTask />
      <MaintenanceAttachment />
      {type === (MaintenanceType.Claim || MaintenanceType.DtClaim) && (
        <Accident />
      )}
      <MaintenanceItemList documentType={SalesModelType.Maintenance} />
      <MaintenanceFooter documentType={SalesModelType.Maintenance} />
      <BottomNavbar>
        <Stack direction="row" spacing={1} alignItems="center">
          {renderButton(status)}
        </Stack>
      </BottomNavbar>
      <ConfirmationModal
        title={"ยืนยันการปรับสถานะ"}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
        noDivider
        message={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Box mb={1}>
              <GreenCheckIcon size={64} />
            </Box>
            <Box>
              {status === "wait_maintenance" ? (
                <>
                  <Typography>กรุณายืนยืนการปรับสถานะใบแจ้งซ่อม</Typography>
                  <Typography>{getValues("unique_id")}</Typography>
                  <Box display={"flex"} gap={0.5} justifyContent={"center"}>
                    <Typography>จาก</Typography>
                    <Typography fontWeight={600}>รอซ่อม</Typography>
                    <Typography>เป็น</Typography>
                    <Typography fontWeight={600}>ระหว่างซ่อม</Typography>
                  </Box>
                </>
              ) : (
                <>
                  <>
                    <Typography>กรุณายืนยืนการปรับสถานะใบแจ้งซ่อม</Typography>
                    <Typography>{getValues("unique_id")}</Typography>
                    <Box display={"flex"} gap={0.5} justifyContent={"center"}>
                      <Typography>จาก</Typography>
                      <Typography fontWeight={600}>ระหว่างซ่อม</Typography>
                      <Typography>เป็น</Typography>
                      <Typography fontWeight={600}>เสร็จสิ้น</Typography>
                    </Box>
                  </>
                </>
              )}
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default DocumentInfoTab;
