import { Box, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ConfirmationModal from "./ConfirmationModal";

type Props = {
  confirmation: boolean;
  closeConfirmationHandler: () => void;
  submitConfirmationHandler: () => void;
};

const VehicleConfirmation = ({
  confirmation,
  closeConfirmationHandler,
  submitConfirmationHandler,
}: Props) => {
  return (
    <ConfirmationModal
      title="การเปลี่ยนรถ"
      message={
        <Box textAlign="center">
          <ErrorOutlineIcon
            sx={{
              fontSize: "4rem",
              color: "gray.light",
            }}
          />
          <Typography>
            {"ข้อมูลด้านล่างจะเปลี่ยนแปลงเมื่อเลือกเปลี่ยนรถ"}
          </Typography>
        </Box>
      }
      open={confirmation}
      handleClose={closeConfirmationHandler}
      action={submitConfirmationHandler}
      noDivider
    />
  );
};

export default VehicleConfirmation;
