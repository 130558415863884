// import { IPermission } from "../types/Auth/user";
// import {
//   PermissionBinaryType,
//   PermissionBinaryViewType,
//   PermissionViewType,
// } from "../generated/rbac";

export const departmentType = [];

export const positionType = [
  {
    label: "พนักงานขนส่ง",
    value: "พนักงานขนส่ง",
  },
];

// export const defaultPermission: IPermission = {
//   contact: {
//     view: PermissionBinaryType.Allow,
//   },
//   inventory: {
//     view: PermissionBinaryType.Allow,
//   },
//   project: {
//     view: PermissionBinaryType.Allow,
//   },
//   purchase: {
//     view: PermissionBinaryType.Allow,
//   },
//   sales: {
//     view: PermissionBinaryType.Allow,
//   },
//   delivery: {
//     view: PermissionBinaryType.Allow,
//   },
//   manufacture: {
//     view: PermissionBinaryType.Allow,
//   },
//   setting: {
//     view: PermissionBinaryType.Allow,
//   },
//   quotation: {
//     view: PermissionViewType.All,
//   },
//   sales_order: {
//     view: PermissionViewType.All,
//   },
//   sales_return: {
//     view: PermissionViewType.All,
//   },
//   sales_report: {
//     view: PermissionBinaryViewType.All,
//   },
//   purchase_request: {
//     view: PermissionViewType.All,
//   },
//   purchase_order: {
//     view: PermissionViewType.All,
//   },
//   purchase_return: {
//     view: PermissionViewType.All,
//   },
//   purchase_report: {
//     view: PermissionBinaryViewType.All,
//   },
//   goods_receive: {
//     view: PermissionViewType.All,
//   },
//   goods_issue: {
//     view: PermissionViewType.All,
//   },
//   goods_adjustment: {
//     view: PermissionViewType.All,
//   },
//   goods_transfer: {
//     view: PermissionViewType.All,
//   },
//   goods_return: {
//     view: PermissionViewType.All,
//   },
//   item: {
//     view: PermissionViewType.All,
//   },
//   inventory_report: {
//     view: PermissionBinaryViewType.All,
//   },
//   delivery_order: {
//     view: PermissionViewType.All,
//   },
//   delivery_trip: {
//     view: PermissionViewType.All,
//   },
//   logistic_report: {
//     view: PermissionBinaryViewType.All,
//   },
//   user_information: {
//     view: PermissionViewType.All,
//   },
//   role: {
//     view: PermissionViewType.All,
//   },
//   customer: {
//     view: PermissionViewType.All,
//   },
//   vendor: {
//     view: PermissionViewType.All,
//   },
//   contact_report: {
//     view: PermissionBinaryViewType.All,
//   },
//   manufacture_order: {
//     view: PermissionViewType.All,
//   },
//   bom: {
//     view: PermissionViewType.All,
//   },
//   routing: {
//     view: PermissionViewType.All,
//   },
//   work_order: {
//     view: PermissionViewType.All,
//   },
//   manufacture_report: {
//     view: PermissionBinaryViewType.All,
//   },
//   projects: {
//     view: PermissionViewType.All,
//   },
//   event: {
//     view: PermissionViewType.All,
//   },
//   setting_company: {
//     view: PermissionBinaryViewType.All,
//   },
//   setting_approval: {
//     view: PermissionBinaryViewType.All,
//   },
//   setting_remark: {
//     view: PermissionBinaryViewType.All,
//   },
//   setting_inventory: {
//     view: PermissionBinaryViewType.All,
//   },
//   setting_manufacture: {
//     view: PermissionBinaryViewType.All,
//   },
//   setting_logistic: {
//     view: PermissionBinaryViewType.All,
//   },
//   setting_project: {
//     view: PermissionBinaryViewType.All,
//   },
// };
