import {
  Box,
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { IBankAccountDetail, IContact } from "../../types/Contact/contact";
import {
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
} from "react-hook-form";
import { useModal } from "../../hooks/use-modal";
import { useState } from "react";
import { useDisable } from "../../hooks/use-disable";
import { useTranslation } from "react-i18next";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import BankModal from "./Modal/BankModal";

type Props = {
  bank: FieldArrayWithId<IContact, "bank_account_detail_list", "id">;
  remove: UseFieldArrayRemove;
  index: number;
  update: UseFieldArrayUpdate<IContact, any>;
};

const BankCard = ({ bank, remove, index, update }: Props) => {
  const { modal, openModalHandler, closeModalHandler } = useModal();
  const [anchorPos, setAnchorPos] = useState(null);
  const [disabled] = useDisable();

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const openActionsHandler = (event: any) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const updateContactPersonHandler = (data: IBankAccountDetail) => {
    update(index, data);
  };

  const showActions = Boolean(anchorPos);

  const { t } = useTranslation();

  return (
    <>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
      >
        <MenuItem
          onClick={() => {
            closeActionsHandler();
            openModalHandler();
            setIsEdit(true);
          }}
        >
          {t("contact.utility.edit")}
        </MenuItem>

        <MenuItem
          onClick={() => {
            closeActionsHandler();
            remove(index);
          }}
        >
          {t("contact.utility.delete")}
        </MenuItem>
      </Menu>
      <Card
        variant="outlined"
        sx={{
          borderRadius: "14px",

          backgroundColor: "secondary.main",
        }}
      >
        <CardContent
          sx={{
            "&:last-child": {
              p: 2,
            },
            p: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box
              flexGrow={1}
              onClick={openModalHandler}
              sx={{
                cursor: "pointer",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography fontWeight={600} noWrap>
                {bank.provider}
              </Typography>
              <Typography noWrap>{bank.name}</Typography>
              <Typography
                noWrap
                sx={{
                  color: "#737373",
                }}
              >
                {bank.account_number}
              </Typography>
            </Box>
            {!disabled && (
              <IconButton
                onClick={openActionsHandler}
                sx={{
                  height: "fit-content",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <MoreVertOutlinedIcon />
              </IconButton>
            )}
          </Box>
        </CardContent>
      </Card>
      <BankModal
        open={modal}
        closeModalHandler={closeModalHandler}
        onUpdateHandler={updateContactPersonHandler}
        data={bank}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    </>
  );
};

export default BankCard;
