import { Box, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ConfirmationModal from "./ConfirmationModal";

type Props = {
  confirmation: boolean;
  closeConfirmationHandler: () => void;
  submitConfirmationHandler: () => void;
};

const FrozenConfirmation = ({
  confirmation,
  closeConfirmationHandler,
  submitConfirmationHandler,
}: Props) => {
  return (
    <ConfirmationModal
      title="การขนส่งแบบ Frozen"
      message={
        <Box textAlign="center" my={1} mb={2}>
          <ErrorOutlineIcon
            sx={{
              fontSize: "4rem",
              my: 1,
              color: "gray.light",
            }}
          />
          <Typography>
            {"หากเปลี่ยนประเภทงานจะส่งผลเปลี่ยนแปลงข้อมูลด้านล่าง"}
          </Typography>
          <Typography fontWeight="bold">
            {"กรุณายืนยันการเปลี่ยนแปลงประเภทงาน"}
          </Typography>
        </Box>
      }
      open={confirmation}
      handleClose={closeConfirmationHandler}
      action={submitConfirmationHandler}
      noDivider
    />
  );
};

export default FrozenConfirmation;
