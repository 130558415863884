import { yupResolver } from "@hookform/resolvers/yup";
import {
  deliveryTripSchema,
  deliveryTripValidation,
} from "components/Form/Logistic/DeliveryTrip/schema";
import { ActivityLog } from "components/UI/ActivityLog";
import BreadcrumbsLayout from "components/UI/BreadcrumbsLayout";
import { useActivityLog } from "hooks/use-activity-log";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { IBreadcrumbsAndMenu, ITab } from "types/global";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import CustomizedTab from "components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import BillingNoteTab from "./BillingNoteTab";
import { useDeliveryTripFindUniqueQuery } from "generated/vehicle";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import LoadingUI from "components/UI/LoadingUI";
import { deliveryTripDataFormatter } from "utils/Formatter/Logistic/DeliveryTrip";

const DeliveryTripContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const graphqlClient = createGraphQLClientWithMiddleware("sales");

  const { data, isLoading, isSuccess } = useDeliveryTripFindUniqueQuery(
    graphqlClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
    }
  );

  const tabs: ITab[] = [
    {
      label: t("document_info"),
      path: `${pathname}`,
    },
    {
      label: "ใบวางบิล",
      path: `${pathname}?tab=billing-note`,
    },
  ];

  const methods = useForm<IDeliveryTrip>({
    defaultValues: { ...deliveryTripSchema },
    resolver: yupResolver<any>(deliveryTripValidation),
  });

  const {
    watch,
    formState: { errors },
    reset,
  } = methods;

  const { openActivityLog, onActivityLogOpen, onActivityLogClose } =
    useActivityLog();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const { work_list, ...otherErrors } = errors;

      if (Object.keys(otherErrors).length > 0) {
        enqueueSnackbar("กรุณาระบุข้อมูลที่จำเป็น", {
          variant: "error",
        });
      }

      if (work_list) {
        if (Array.isArray(work_list)) {
          work_list.forEach((work) => {
            if (work) {
              const { shipping_cost } = work;
              if (shipping_cost) {
                enqueueSnackbar(shipping_cost.message, {
                  variant: "error",
                });
              }
            }
          });
        } else {
          if (work_list.root) {
            enqueueSnackbar(work_list.root.message, {
              variant: "error",
            });
          } else {
            enqueueSnackbar(work_list.message, {
              variant: "error",
            });
          }
        }
      }
    }
  }, [enqueueSnackbar, errors]);

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "billing-note":
        return <BillingNoteTab isLoading={isLoading} />;
      default:
        return <DocumentInfoTab />;
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const { DeliveryTripFindUnique } = data;
      const formatData = deliveryTripDataFormatter(
        DeliveryTripFindUnique
      ) as IDeliveryTrip;
      reset(formatData);
    }
  }, [data, isSuccess, reset]);

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.delivery_trip.index"),
      to: "/logistic/delivery-trip",
    },
    {
      name: id
        ? data?.DeliveryTripFindUnique?.unique_id || "-"
        : t("button.create") + t("logistic.delivery_trip.index"),
    },
  ];

  if (id && isLoading) {
    return <LoadingUI />;
  }

  return (
    <FormProvider {...methods}>
      <BreadcrumbsLayout
        breadcrumbs={breadcrumbs}
        onActivityLogOpen={onActivityLogOpen}
      />
      {status === "finished" && (
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider />
      )}
      {renderTab(tab)}
      {id && (
        <ActivityLog
          open={openActivityLog}
          onClose={onActivityLogClose}
          documentId={id!}
          documentType={"delivery_trip"}
        />
      )}
    </FormProvider>
  );
};

export default DeliveryTripContainer;
