import { Typography, Stack } from "@mui/material";
import LoadingAnimation from "components/Animation/Loading";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
// import CustomizedSteppers from "components/Custom/CustomizedStepper";
import ContactImportUploader from "components/Form/Contact/ImportUploader";
import ContactImporterTable from "components/Table/Contact/ImporterTable";
import ImporterErrorModal from "components/UI/Modal/ImporterErrorModal";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import useContactImporter from "hooks/Importer/Contact/use-contact-importer";
// import { useStateContext } from "contexts/auth-context";
import { useModal } from "hooks/use-modal";
import { MouseEventHandler, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IBreadcrumbsAndMenu } from "types/global";

const ContactImporter = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: "นำเข้าไฟล์ผู้ติดต่อ",
      to: "/contact/importer",
    },
  ];
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const [errorData, setErrorData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const methods = useForm<any>({
    defaultValues: {
      import_type: "customer",
      type: "create",
      file: {},
      step: 0,
      errors: [],
    },
  });

  const { watch, getValues, setValue } = methods;
  const activeStep = watch("step");
  const importType = watch("import_type");

  // const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const { importHandler, isLoading, rowData, validateHandler } =
    useContactImporter(getValues, setValue, setErrorData, openModalHandler);

  const backToUploadHandler = () => {
    setValue("step", 0);
  };

  const renderTable = (importType: string) => {
    switch (importType) {
      case "customer":
        if (isFilter) return <ContactImporterTable data={[]} />;
        return <ContactImporterTable data={rowData} />;
    }
  };

  const renderContent = (step: number, importType: string) => {
    if (step === 0) {
      return <ContactImportUploader />;
    } else {
      return (
        <>
          {/* {step === 3 && (
            <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
          )} */}
          {renderTable(importType)}
        </>
      );
    }
  };

  // const renderButton = (step: number, importType: string) => {
  //   const getHandler = (
  //     handlerType: "validate" | "import"
  //   ): MouseEventHandler<HTMLButtonElement> | undefined => {
  //     switch (importType) {
  //       case "customer":
  //         return handlerType === "validate" ? validateHandler : importHandler;

  //       default:
  //         return undefined;
  //     }
  //   };
  const renderButton = (step: number, importType: string) => {
    const getHandler = (
      handlerType: "validate" | "import"
    ): MouseEventHandler<HTMLButtonElement> | undefined => {
      switch (importType) {
        case "customer":
          if (handlerType === "validate") {
            return async () => {
              setLoading(true); // Set loading to true
              try {
                await validateHandler();
              } finally {
                setLoading(false); // Set loading to false after validation
              }
            };
          }
          return importHandler;
        default:
          return undefined;
      }
    };

    const validateContactHandler = getHandler("validate");
    const importContactHandler = getHandler("import");

    switch (step) {
      case 0:
        return (
          <CustomizedButton
            title="ตรวจสอบไฟล์"
            variant="contained"
            onClick={validateContactHandler}
          />
        );
      case 1:
        return (
          <>
            <CustomizedButton
              title="ย้อนกลับ"
              variant="outlined"
              onClick={backToUploadHandler}
            />
            <CustomizedButton
              title="นำเข้าข้อมูล"
              variant="contained"
              onClick={importContactHandler}
            />
          </>
        );
      case 3:
        return (
          <CustomizedButton
            title="ดูลูกค้าทั้งหมด"
            variant="contained"
            onClick={() => navigate("/contact/customer")}
          />
        );
      default:
        return null;
    }
  };

  if (isLoading || loading) {
    // Check for either isLoading or loading state
    return <LoadingAnimation />; // Use the LoadingAnimation component
  }
  // return (
  //   <Box
  //     sx={{
  //       height: "calc(100dvh - 176px)",
  //       marginRight: "260px",
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center",
  //     }}
  //   >
  //     <CircularProgress />
  //   </Box>
  // );
  // }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" mt={3}>
        {"นำเข้าไฟล์ผู้ติดต่อ"}
      </Typography>
      <FormProvider {...methods}>
        <CustomizedBox>
          {/* <Box my={5}>
            <CustomizedSteppers steps={steps} activeStep={activeStep} />
          </Box> */}
          {renderContent(activeStep, importType)}
        </CustomizedBox>
      </FormProvider>
      <BottomNavbar>
        <Stack direction={"row"} gap={1}>
          {renderButton(activeStep, importType)}
        </Stack>
      </BottomNavbar>
      <ImporterErrorModal
        open={modal}
        closeModalHandler={closeModalHandler}
        data={errorData}
      />
    </>
  );
};

export default ContactImporter;
