import { gql } from "graphql-request";

export const PURCHASE_BY_SUPPLIER_EXPORT_VIEW = gql`
  query PurchaseBySupplierExportViewsAggrid($aggridInput: AnyAggridInput) {
    PurchaseBySupplierExportViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        supplier_unique_id
        supplier_name
        total_document
        net_amount
        vat_amount
        total_amount
        pre_vat_amount
        additional_discount
        post_discount_amount
        withholding_tax_amount
      }
    }
  }
`;
