import { Grid, Typography } from "@mui/material";
import ControlledSSCreatable from "components/Controller/ControlledSSCreatable";
import ControlledSwitch from "components/Controller/ControlledSwitch";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledSelect from "components/Controller/ControlledSelect";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import FrozenConfirmation from "components/UI/Modal/FrozenConfirmation";
import { useConfirmation } from "hooks/use-confirmation";
import { useDisable } from "hooks/use-disable";
import { useFormContext, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IQuotation } from "types/Sales/quotation";
import { ISelectOption } from "types/global";
import { v4 as uuidv4 } from "uuid";

const vehicleTypeOptions: ISelectOption[] = [
  {
    label: "4 ล้อ",
    value: "four_wheel",
    disabled: false,
  },
  {
    label: "6 ล้อ",
    value: "six_wheel",
    disabled: false,
  },
  {
    label: "10 ล้อ",
    value: "ten_wheel",
    disabled: false,
  },
  {
    label: "หัวลาก",
    value: "head",
    disabled: false,
  },
];

const QuotationDeliveryInfo = () => {
  const {
    control,
    setValue,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext<IQuotation>();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;

  const disabledStatus = ["finished"].includes(status);
  const isFinished = status === "finished";

  const {
    confirmation: frozenConfirmation,
    openConfirmationHandler: openFrozenConfirmation,
    closeConfirmationHandler: closeFrozenConfirmation,
    submitConfirmationHandler: submitFrozenConfirmation,
  } = useConfirmation(async () => await frozenChangeHandler());

  const frozenChangeHandler = async () => {
    const isFrozen = getValues("is_frozen");
    setValue("is_frozen", !isFrozen);
    const currentFrozen = getValues("is_frozen");

    if (currentFrozen) {
      const currentItemList = getValues("item_list");

      const formatItemList = currentItemList.filter(
        (item) => item.item_id !== 157
      );

      setValue(`item_list`, [
        {
          unique_id: uuidv4(),
          item_id: 158,
          item_unique_id: "SP00002",
          item_name: "ค่าขนส่ง (Frozen)",
          item_sku_desc: "ใช้สำหรับคิดค่าขนส่งสินค้าประเภท Frozen",
          qty: 1,
          uom: {
            id: 87,
            name: "เที่ยว",
          },
          uom_id: 87,
          uom_name: "เที่ยว",
          uom_conversion_list: [],
          discount: 0,
          price_per_unit: 0,
          withholding_tax_type: "ยังไม่ระบุ",
          withholding_tax_value: 0,
          vat_percentage: "ไม่มี",
          item_img_url: [],
          post_discount_amount: 0,
        },
        ...formatItemList,
      ]);
    } else {
      const currentItemList = getValues("item_list");

      const formatItemList = currentItemList.filter(
        (item) => item.item_id !== 158
      );

      setValue(`item_list`, [
        {
          unique_id: uuidv4(),
          item_id: 157,
          item_unique_id: "SP00001",
          item_name: "ค่าขนส่ง",
          item_sku_desc: "ใช้สำหรับคิดค่าขนส่งสินค้าประเภท Chilled",
          qty: 1,
          uom: {
            id: 87,
            name: "เที่ยว",
          },
          uom_id: 87,
          uom_name: "เที่ยว",
          uom_conversion_list: [],
          discount: 0,
          post_discount_amount: 0,
          item_img_url: [],
          price_per_unit: 0,
          withholding_tax_type: "ยังไม่ระบุ",
          withholding_tax_value: 0,
          vat_percentage: "ไม่มี",
        },
        ...formatItemList,
      ]);
    }
  };

  return (
    <>
      <CustomizedBox margin={0}>
        <Typography fontWeight={600} mb={1}>
          {t("logistic.delivery_trip.delivery_info")}
        </Typography>
        <Grid container spacing={1.5} mb={2}>
          <Grid item xs={12} sm={12} md={4}>
            <ControlledSelect
              label={t("logistic.delivery_trip.vehicle.vehicle_type")}
              control={control}
              name="vehicle_type"
              options={vehicleTypeOptions}
              error={Boolean(errors?.vehicle_type)}
              helperText={(errors?.vehicle_type as FieldError)?.message}
              required
              disabled={disabled || disabledStatus}
              viewMode={disabled}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={4}>
            <ControlledTextField
              control={control}
              name="item_type"
              label={t("logistic.delivery_trip.item_type")}
              error={Boolean(errors.item_type)}
              helperText={errors.item_type?.message}
              required
              disabled={disabled || disabledStatus}
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <ControlledTextField
              control={control}
              name="item_value"
              label={t("logistic.delivery_trip.item_amount")}
              error={Boolean(errors.item_value)}
              helperText={errors.item_value?.message}
              required
              disabled={disabled || disabledStatus}
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <ControlledSwitch
              label={t("logistic.delivery_trip.is_frozen")}
              control={control}
              name="is_frozen"
              disabled={disabled || disabledStatus}
              onChange={openFrozenConfirmation}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <ControlledTextField
              control={control}
              name="packing_location"
              label={t("logistic.delivery_trip.packaging_location")}
              error={Boolean(errors.packing_location)}
              helperText={errors.packing_location?.message}
              required
              disabled={disabled || disabledStatus}
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <ControlledTextField
              control={control}
              name="expected_temperature"
              label={t("logistic.delivery_trip.expected_temperature")}
              error={Boolean(errors.expected_temperature)}
              helperText={errors.expected_temperature?.message}
              required
              disabled={disabled || disabledStatus}
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <ControlledSSCreatable
              control={control}
              name="container_type"
              setValue={setValue}
              defaultOptions={[]}
              documentType="delivery_trip"
              label={t("logistic.delivery_trip.container_type")}
              error={Boolean(errors.container_type)}
              helperText={errors?.container_type?.message}
              viewMode={disabled}
              disabled={isFinished}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <FrozenConfirmation
        confirmation={frozenConfirmation}
        closeConfirmationHandler={closeFrozenConfirmation}
        submitConfirmationHandler={submitFrozenConfirmation}
      />
    </>
  );
};

export default QuotationDeliveryInfo;
