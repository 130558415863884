import { useEffect, useMemo, useState } from "react";
import { IHeaderTable } from "types/global";

export const useMaintenanceTaskListPdfHeader = () => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);
  const columns = useMemo<IHeaderTable[]>(
    () => [
      {
        thaiLabel: "",
        width: 12,
      },
      {
        thaiLabel: "รายการแจ้งซ่อม",
        width: 122.2,
        align: "left",
      },
      {
        thaiLabel: "",
        width: 370,
      },
    ],
    []
  );
  useEffect(() => {
    setHeaders(columns);
  }, [columns]);

  return headers;
};
