import CheckIcon from '@mui/icons-material/Check';

const GreenCheckIcon = ({ size = 40 }) => (
  <div style={{
    backgroundColor: '#e6f8f1',
    borderRadius: '50%',
    width: `${size}px`,
    height: `${size}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}>
    <CheckIcon sx={{ color: '#37b96d', fontSize: `${size * 0.6}px` }} />
  </div>
);

export default GreenCheckIcon;