import { useWatch } from "react-hook-form";
import { IDefaultForm } from "types/global";
import { ISalesItemList } from "types/Sales";
import { useEffect, useState } from "react";

export const useSalesSummary = (
  control: IDefaultForm["control"],
  setValue: IDefaultForm["setValue"],
  name: "item_list"
) => {
  const itemList: ISalesItemList[] = useWatch({
    control,
    name,
  });

  const priceVatType: string = useWatch({
    control,
    name: "post_discount_amount",
  });

  const [summary, setSummary] = useState({
    post_discount_amount: 0,
    vat_exempted_amount: 0,
    vat_0_percent_amount: 0,
    vat_7_percent_amount: 0,
    vat_amount: 0,
    net_amount: 0,
    withholding_tax_amount: 0,
    total_amount: 0,
  });

  useEffect(() => {
    setSummary(
      ({
        net_amount,
        post_discount_amount = 0,
        total_amount,
        vat_0_percent_amount,
        vat_7_percent_amount,
        vat_amount,
        vat_exempted_amount,
        withholding_tax_amount,
      }) => {
        const total_pre_vat_amount = itemList.reduce(
          (acc, item) => acc + (item.post_discount_amount || 0),
          0
        );

        post_discount_amount = total_pre_vat_amount;

        vat_exempted_amount = itemList
          .filter((item) => item?.vat_percentage === "ไม่มี")
          .reduce((acc, item) => {
            return acc + (item.post_discount_amount || 0);
          }, 0);

        vat_0_percent_amount = itemList
          .filter((item) => item?.vat_percentage === "0 %")
          .reduce((acc, item) => {
            return acc + (item.post_discount_amount || 0);
          }, 0);

        // calculate vat amount
        vat_7_percent_amount = itemList
          .filter((item) => item?.vat_percentage === "7 %")
          .reduce((acc, item) => {
            return acc + (item.post_discount_amount || 0);
          }, 0);
        vat_amount = vat_7_percent_amount * 0.07;

        withholding_tax_amount =
          itemList
            .filter(
              (item) =>
                !["ยังไม่ระบุ", "ไม่มี", "", undefined, null].includes(
                  item?.withholding_tax_type
                )
            )
            .reduce((acc, item) => {
              return (
                acc +
                ((item.post_discount_amount || 0) *
                  parseFloat(item?.withholding_tax_type || "0")) /
                  100
              );
            }, 0) || 0;

        net_amount =
          vat_exempted_amount +
          vat_0_percent_amount +
          vat_7_percent_amount +
          vat_amount;

        total_amount = net_amount - withholding_tax_amount;

        return {
          net_amount,
          post_discount_amount,
          total_amount,
          vat_0_percent_amount,
          vat_7_percent_amount,
          vat_amount,
          vat_exempted_amount,
          withholding_tax_amount,
        };
      }
    );
  }, [itemList, priceVatType]);

  useEffect(() => {
    setValue("post_discount_amount", summary.post_discount_amount);
    setValue("post_discount_amount", summary.post_discount_amount);
    setValue("vat_exempted_amount", summary.vat_exempted_amount);
    setValue("vat_0_percent_amount", summary.vat_0_percent_amount);
    setValue("vat_7_percent_amount", summary.vat_7_percent_amount);
    setValue("vat_amount", summary.vat_amount);
    setValue("net_amount", summary.net_amount);
    setValue("withholding_tax_amount", summary.withholding_tax_amount);
    setValue("total_amount", summary.total_amount);
  }, [
    setValue,
    summary.net_amount,
    summary.post_discount_amount,
    summary.total_amount,
    summary.vat_0_percent_amount,
    summary.vat_7_percent_amount,
    summary.vat_amount,
    summary.vat_exempted_amount,
    summary.withholding_tax_amount,
  ]);
  return summary;
};
