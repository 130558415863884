import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../types/global";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
// import { useStateContext } from "../../contexts/auth-context";

const User = () => {
  const { t } = useTranslation();

  // const {
  //   state: { authUser, permissions },
  // } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("user.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("user.account.index"),
      to: "account",
    },
    // {
    //   name: t("user.rbac.index"),
    //   to: "rbac",
    // },
    // {
    //   name: t("user.setting.index"),
    //   to: "setting/" + authUser?.unique_id + "?subtab=inventory",
    // },
  ].filter(Boolean) as IBreadcrumbsAndMenu[];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default User;
