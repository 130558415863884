import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next"
import { IBreadcrumbsAndMenu } from "types/global";
import { Typography } from "@mui/material";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
import HeaderLayout from "components/UI/HeaderLayout";
import { DeliveryReportType } from "generated/sales";
import DeliveryTripWorkReportTable from "components/Table/Logistic/Report/DeliveryTripWorkReportTable";
import { useDeliveryTripReport } from "hooks/Logistic/DeliveryTrip/use-delivery-trip-report";
import { useRef } from "react";

const DeliveryTripWorkReport = () => {
    const { t } = useTranslation();
    const gridRef = useRef<AgGridReact>(null);

    const { onBtnExport, isLoading } = useDeliveryTripReport(
        gridRef,
        DeliveryReportType.DeliveryTripWork
    );
    const breadcrumbs: IBreadcrumbsAndMenu[] = [
        {
            name: t("logistic.index"),
            to: "/logistic"
        },
        {
            name: t("report"),
            to: "/logistic/report"
        },
        {
            name: t("logistic.report.delivery_trip_work")
        },
    ];
    return (
        <>
            <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
            <HeaderLayout noMaxWidth>
                <Typography variant="h5">
                    {`${t("logistic.report.delivery_trip_work")}`}
                </Typography>
                <CustomizedButton
                    title={`${t("button.export")}`}
                    variant="contained"
                    onClick={onBtnExport}
                    disabled={isLoading}
                />
            </HeaderLayout>
            <DeliveryTripWorkReportTable gridRef={gridRef} />
        </>
    );
};

export default DeliveryTripWorkReport;
