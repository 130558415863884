import { Avatar, Box } from "@mui/material";
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import {
  TagDocumentType,
  TagsFindManyByDocumentTypeQuery,
  useTagsFindManyByDocumentTypeQuery,
  InventorySettingType,
  InventorySettingsByTypeQuery,
  useInventorySettingsByTypeQuery,
} from "../../../../generated/general";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import CustomizedChips from "../../../Custom/CustomizedChips";
import { ItemType } from "generated/wms";
import { numberFormatter } from "utils/Formatter/Global";

type Props = {
  type: string | null;
  active_only?: boolean;
  is_item_modal?: boolean;
  isCheckbox?: boolean;
  isIncome?: boolean;
  isExpenses?: boolean;
};

export const useItemColumnDefs = ({
  type,
  active_only,
  is_item_modal,
  isCheckbox,
  isIncome,
  isExpenses,
}: Props) => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } =
    useTagsFindManyByDocumentTypeQuery<TagsFindManyByDocumentTypeQuery>(
      graphQLClient,
      {
        documentType: TagDocumentType.Item,
      },
      {
        enabled: false,
      }
    );

  const { refetch: refetchCategory } =
    useInventorySettingsByTypeQuery<InventorySettingsByTypeQuery>(
      graphQLClient,
      {
        type: InventorySettingType.Cate_1,
      },
      {
        enabled: false,
      }
    );

  const { t } = useTranslation();
  useEffect(() => {
    if (isIncome) {
      setColumnDefs([
        {
          field: "unique_id",
          headerName: t("inventory.item.unique_id"),
          checkboxSelection: isCheckbox,
          filter: "agTextColumnFilter",
        },
        {
          field: "created_date",
          sort: "desc",
          hide: true,
          filter: false,
          suppressColumnsToolPanel: true,
        },
        {
          field: "name",
          headerName: t("inventory.item.name"),
          filter: "agTextColumnFilter",
        },
        {
          field: "seller_sku",
          headerName: t("inventory.item.seller_sku"),
          filter: "agTextColumnFilter",
        },
        {
          field: "description",
          headerName: t("inventory.item.description"),
          filter: "agTextColumnFilter",
        },
        {
          field: "sale_price",
          headerName: t("inventory.item.sales.sale_price"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params: ValueFormatterParams<any, any>) => {
            if (params.value) {
              return numberFormatter(params.value);
            } else return "";
          },
        },
        {
          field: "special_price",
          headerName: t("inventory.item.sales.special_price"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params: ValueFormatterParams<any, any>) => {
            if (params.value) {
              return numberFormatter(params.value);
            } else return "";
          },
        },
        {
          field: "tag_list",
          headerName: t("global.tag_list"),
          filterParams: {
            values: async (params: any) => {
              // fetch values from server
              const { data: tagListResult } = await refetch();
              const values = tagListResult?.TagsFindManyByDocumentType;
              const formatValues = values?.map((tag) => tag.name);
              params.success(formatValues);
            },
          },
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value)
              return params.value?.map((tag: string) => (
                <Box sx={{ display: "inline-block", mr: 1, gap: 1 }} key={tag}>
                  <CustomizedChips
                    label={tag}
                    variant="outlined"
                    color="primary"
                    isRounded
                  />
                </Box>
              ));
          },
        },
        {
          field: "is_active",
          headerName: t("inventory.item.status"),
          filter: "agSetColumnFilter",
          floatingFilter: active_only ? false : true,
          filterParams: {
            values: ["1", "0"],
            valueFormatter: ({ value }: { value: string }) => {
              const isActive = parseInt(value);
              return isActive ? "ใช้งาน" : "หยุดใช้งาน";
            },
            comparator: (a: string, b: string) => {
              if (b) {
                return 1;
              } else {
                return -1;
              }
            },
          },
          cellRenderer: ({ value }: { value: number }) => {
            return <CustomizedStatus status={value ? "active" : "inactive"} />;
          },
          cellStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      ]);
    } else if (isExpenses) {
      setColumnDefs([
        {
          field: "unique_id",
          headerName: t("inventory.item.unique_id"),
          checkboxSelection: isCheckbox,
          filter: "agTextColumnFilter",
        },
        {
          field: "created_date",
          sort: "desc",
          hide: true,
          filter: false,
          suppressColumnsToolPanel: true,
        },
        {
          field: "name",
          headerName: t("inventory.item.name"),
          filter: "agTextColumnFilter",
        },
        {
          field: "seller_sku",
          headerName: t("inventory.item.seller_sku"),
          filter: "agTextColumnFilter",
        },
        {
          field: "description",
          headerName: t("inventory.item.description"),
          filter: "agTextColumnFilter",
        },
        {
          field: "type",
          headerName: t("inventory.item.type"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: [ItemType.Normal, ItemType.Service],
            valueFormatter: (params: ValueFormatterParams) => {
              return t(`inventory.item.${params.value}`);
            },
          },
          valueFormatter: (params: ValueFormatterParams) => {
            return t(`inventory.item.${params.value}`);
          },
        },
        {
          field: "purchase_price",
          headerName: t("inventory.item.purchase.purchase_price"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params: ValueFormatterParams<any, any>) => {
            if (params.value) {
              return numberFormatter(params.value);
            } else return "";
          },
        },
        {
          field: "tag_list",
          headerName: t("global.tag_list"),
          filterParams: {
            values: async (params: any) => {
              // fetch values from server
              const { data: tagListResult } = await refetch();
              const values = tagListResult?.TagsFindManyByDocumentType;
              const formatValues = values?.map((tag) => tag.name);
              params.success(formatValues);
            },
          },
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value)
              return params.value?.map((tag: string) => (
                <Box sx={{ display: "inline-block", mr: 1, gap: 1 }} key={tag}>
                  <CustomizedChips
                    label={tag}
                    variant="outlined"
                    color="primary"
                    isRounded
                  />
                </Box>
              ));
          },
        },
        {
          field: "is_active",
          headerName: t("inventory.item.status"),
          filter: "agSetColumnFilter",
          floatingFilter: active_only ? false : true,
          filterParams: {
            values: ["1", "0"],
            valueFormatter: ({ value }: { value: string }) => {
              const isActive = parseInt(value);
              return isActive ? "ใช้งาน" : "หยุดใช้งาน";
            },
            comparator: (a: string, b: string) => {
              if (b) {
                return 1;
              } else {
                return -1;
              }
            },
          },
          cellRenderer: ({ value }: { value: number }) => {
            return <CustomizedStatus status={value ? "active" : "inactive"} />;
          },
          cellStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      ]);
    } else {
      setColumnDefs([
        {
          field: "unique_id",
          headerName: t("inventory.item.unique_id"),
          checkboxSelection: isCheckbox,
          filter: "agTextColumnFilter",
        },
        {
          field: "created_date",
          sort: "desc",
          hide: true,
          filter: false,
          suppressColumnsToolPanel: true,
        },
        {
          field: "img_url_list",
          headerName: t("inventory.item.image"),
          filter: false,
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value && params.value.length > 0) {
              const mainImg =
                params.value.find((img: any) => img.is_main_img)?.img_url ||
                params.value?.[0]?.img_url ||
                "";
              return <Avatar src={mainImg} variant="square" />;
            }
          },
          minWidth: 100,
          flex: 1,
        },
        {
          field: "name",
          headerName: t("inventory.item.name"),
          filter: "agTextColumnFilter",
        },
        {
          field: "description",
          headerName: t("inventory.item.description"),
          filter: "agTextColumnFilter",
        },
        {
          field: "type",
          headerName: t("inventory.item.type"),
          filter: "agSetColumnFilter",
          floatingFilter:
            type &&
            [ItemType.Normal, ItemType.Service].includes(type as ItemType)
              ? false
              : true,
          filterParams: {
            values: [ItemType.Normal, ItemType.Service],
            valueFormatter: (params: ValueFormatterParams) => {
              return t(`inventory.item.${params.value}`);
            },
          },
          valueFormatter: (params: ValueFormatterParams) => {
            return t(`inventory.item.${params.value}`);
          },
        },
        {
          field: "barcode",
          headerName: t("inventory.item.barcode"),
          filter: "agTextColumnFilter",
        },
        {
          field: "stock_qty",
          headerName: t("inventory.item.stock_qty"),
          filter: "agNumberColumnFilter",
          floatingFilter: is_item_modal ? false : true,
          sortable: is_item_modal ? false : true,
        },
        {
          field: "available_qty",
          headerName: t("inventory.item.available_qty"),
          filter: "agNumberColumnFilter",
          floatingFilter: is_item_modal ? false : true,
          sortable: is_item_modal ? false : true,
        },
        {
          field: "tag_list",
          headerName: t("global.tag_list"),
          filterParams: {
            values: async (params: any) => {
              // fetch values from server
              const { data: tagListResult } = await refetch();
              const values = tagListResult?.TagsFindManyByDocumentType;
              const formatValues = values?.map((tag) => tag.name);
              params.success(formatValues);
            },
          },
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value)
              return params.value?.map((tag: string) => (
                <Box sx={{ display: "inline-block", mr: 1, gap: 1 }} key={tag}>
                  <CustomizedChips
                    label={tag}
                    variant="outlined"
                    color="primary"
                    isRounded
                  />
                </Box>
              ));
          },
        },
        {
          field: "cate_1",
          headerName: t("inventory.item.category_one"),
          filterParams: {
            values: async (params: any) => {
              // fetch values from server
              const { data: categoriesResult } = await refetchCategory();
              const values = categoriesResult?.InventorySettingsByType;
              const formatValues = values?.map((category) => category.name);
              params.success(formatValues);
            },
          },
        },
        {
          field: "is_active",
          headerName: t("inventory.item.status"),
          filter: "agSetColumnFilter",
          floatingFilter: active_only ? false : true,
          filterParams: {
            values: ["1", "0"],
            valueFormatter: ({ value }: { value: string }) => {
              const isActive = parseInt(value);
              return isActive ? "ใช้งาน" : "หยุดใช้งาน";
            },
            comparator: (a: string, b: string) => {
              if (b) {
                return 1;
              } else {
                return -1;
              }
            },
          },
          cellRenderer: ({ value }: { value: number }) => {
            return <CustomizedStatus status={value ? "active" : "inactive"} />;
          },
          cellStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      ]);
    }
  }, [
    t,
    refetch,
    refetchCategory,
    type,
    active_only,
    is_item_modal,
    isCheckbox,
    isIncome,
    isExpenses,
  ]);
  return columnDefs;
};
