import styled from "@emotion/styled";
import { Box, BoxProps } from "@mui/material";

type ExtendedBoxProps = BoxProps & {
  radius?: number | string;
  padding?: number | string;
  margin?: number | string;
  border?: any;
  boxShadow?: any;
  borderColor?: string;
  clipPath?: string;
  total?: boolean;
  url?: string;
  maxWidth?: number | string;
  isNavigate?: boolean;
};

export const CustomizedBox = styled(Box)<ExtendedBoxProps>(
  ({
    padding,
    margin,
    radius,
    total,
    boxShadow,
    clipPath,
    border,
    borderColor,
    url,
    maxWidth,
    isNavigate,
  }) => ({
    border: border ? border : `1px solid #d6cfcf`,
    borderRadius: radius || "14px",
    padding: padding ?? "1.5rem",
    margin: margin ?? "1rem 0rem",
    ...(total && {
      borderLeft: `5px ${
        borderColor ? borderColor : "rgba(72, 48, 222, 1) "
      } solid`,
    }),
    clipPath: clipPath,
    cursor: url || isNavigate ? "pointer" : "auto",
    maxWidth: maxWidth || "1040px",
    boxShadow,
  })
);
