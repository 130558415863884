import { SxProps } from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode } from "react";

type Props = {
  sx?: SxProps;
  children?: ReactNode;
  marginBottom?: number;
  marginTop?: number;
  onlyButton?: boolean;
  noMaxWidth?: boolean;
};

const HeaderLayout = ({
  sx,
  children,
  marginBottom,
  marginTop,
  onlyButton,
  noMaxWidth,
}: Props) => {
  return (
    <Box
      maxWidth={noMaxWidth ? "none" : 1040}
      sx={{
        ...sx,
        mb: marginBottom ?? 3,
        mt: marginTop ?? 3,
        display: "flex",
        justifyContent: onlyButton ? "flex-end" : "space-between",
      }}
    >
      {children}
    </Box>
  );
};

export default HeaderLayout;
