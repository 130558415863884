import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
  Box,
  DialogContentText,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type IDefault = {
  value: string;
  unique_id?: string;
};

type Props = {
  title: string;
  open: boolean;
  closeModalHandler: () => void;
  onSubmit: (data: IDefault) => void;
};

const LocationModal = ({ title, open, closeModalHandler, onSubmit }: Props) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useFormContext<IDefault>();
  const { t } = useTranslation();

  return (
    <ModalUI
      title={t(`setting.inventory.location.${title}`)}
      open={open}
      handleClose={closeModalHandler}
      maxWidth="xs"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={closeModalHandler}
          />
          <CustomizedButton
            title={t("button.save")}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          />
        </Box>
      }
    >
      {title !== "deleteLocation" && title !== "deleteWarehouse" ? (
        <Box mt={1}>
          <Grid container spacing={1.5}>
            {title === "addWarehouse" && (
              <Grid item xs={12}>
                <ControlledTextField
                  name={"unique_id"}
                  control={control}
                  label={
                    <Box display={"flex"} alignItems={"center"} gap={0.5}>
                      <>รหัสคลังสินค้า/สถานที่</>
                      <CustomizedTooltip
                        placement="top"
                        title="รหัสคลังสินค้า/สถานที่ ไม่สามารถแก้ไขได้หลังจากการเพิ่มคลัง"
                      >
                        <InfoOutlinedIcon sx={{ fontSize: 14 }} />
                      </CustomizedTooltip>
                      <Typography color={"error.main"} fontSize={14}>
                        *
                      </Typography>
                    </Box>
                  }
                  error={Boolean(errors.unique_id)}
                  helperText={errors.unique_id?.message?.toString()}
                  InputLabelProps={{ style: { pointerEvents: "auto" } }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ControlledTextField
                name={"value"}
                control={control}
                label={"ชื่อ"}
                error={Boolean(errors.value)}
                helperText={errors.value?.message?.toString()}
                required
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <>
          <Divider />
          <DialogContentText>
            <Box textAlign="center">
              <DeleteForeverIcon
                sx={{
                  fontSize: "4rem",
                  marginTop: 1,
                  color: "gray.light",
                }}
              />
              <Box>
                <Typography>
                  ยืนยันการ{t(`setting.inventory.location.${title}`)}
                </Typography>
                <Typography fontWeight="bold">{getValues("value")}</Typography>
              </Box>
            </Box>
          </DialogContentText>
          <Divider sx={{ marginTop: "1rem" }} />
        </>
      )}
    </ModalUI>
  );
};

export default LocationModal;
