import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import CustomizedAvatar from "../Custom/CustomizedAvatar";
import { IContact, IContactPerson } from "../../types/Contact/contact";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useDisable } from "../../hooks/use-disable";
import { useModal } from "../../hooks/use-modal";
import PersonModal from "./Modal/PersonModal";
import {
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
} from "react-hook-form";
import { CustomizedTooltip } from "../Custom/CustomizedTooltip";

type Props = {
  person: FieldArrayWithId<IContact, "contact_person_list", "id">;
  remove: UseFieldArrayRemove;
  index: number;
  update: UseFieldArrayUpdate<IContact, "contact_person_list">;
};

const PersonCard = ({ person, remove, index, update }: Props) => {
  const { modal, openModalHandler, closeModalHandler } = useModal();
  const [anchorPos, setAnchorPos] = useState(null);
  const [disabled] = useDisable();

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const openActionsHandler = (event: any) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const updateContactPersonHandler = (data: IContactPerson) => {
    update(index, data);
  };

  const showActions = Boolean(anchorPos);

  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
      >
        <MenuItem
          onClick={() => {
            closeActionsHandler();
            openModalHandler();
            setIsEdit(true);
          }}
        >
          {t("contact.utility.edit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeActionsHandler();
            remove(index);
          }}
        >
          {t("contact.utility.delete")}
        </MenuItem>
      </Menu>
      <Card
        variant="outlined"
        sx={{
          borderRadius: "14px",
          backgroundColor: "secondary.main",
        }}
      >
        <CardContent
          sx={{
            "&:last-child": {
              p: 2,
            },
            p: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              flexWrap={"nowrap"}
              gap={2}
              alignItems="center"
              flexGrow={1}
              sx={{
                cursor: "pointer",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={openModalHandler}
            >
              <CustomizedAvatar
                noTooltip
                avatars={[
                  {
                    img_url:
                      person.img_url[0] instanceof File
                        ? URL.createObjectURL(person.img_url[0])
                        : person.img_url[0],
                    unique_id: person.first_name + person.last_name,
                    first_name: person.first_name,
                    last_name: person.last_name,
                  },
                ]}
              />
              <Box sx={{ flex: 1, minWidth: 0 }}>
                <CustomizedTooltip
                  title={`${
                    person.title_name === "ไม่ระบุ" ? "" : person.title_name
                  }${person.first_name} ${person.last_name}`}
                  placement="top"
                >
                  <Typography fontWeight={600} noWrap>
                    {`${
                      person.title_name === "ไม่ระบุ" ? "" : person.title_name
                    }${person.first_name} ${person.last_name}`}
                  </Typography>
                </CustomizedTooltip>
                <CustomizedTooltip
                  title={
                    person?.contact_channel_list[0]?.contact_channel_info || ""
                  }
                >
                  <Typography>
                    {person?.contact_channel_list[0]?.contact_channel_info ||
                      ""}
                  </Typography>
                </CustomizedTooltip>
              </Box>
            </Box>
            {!disabled && (
              <IconButton
                onClick={openActionsHandler}
                sx={{
                  height: "fit-content",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <MoreVertOutlinedIcon />
              </IconButton>
            )}
          </Box>
        </CardContent>
      </Card>
      <PersonModal
        open={modal}
        closeModalHandler={closeModalHandler}
        onUpdateHandler={updateContactPersonHandler}
        data={person}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    </Grid>
  );
};

export default PersonCard;
