import { useCallback, useEffect, useRef } from "react";
import { useTraceEntryColumnDefs } from "./columnDefs";
import { GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import AgGrid from "components/UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { TRACE_ENTRY_VIEW } from "services/AgGrid/TraceEntryAggrid";
import { dateFilterModel } from "utils/AgGridFilter";

type Props = {
  itemUniqueId?: string | null;
};

const TraceEntryTable = ({ itemUniqueId }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);

  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const hasFixedItemUniqueId = Boolean(itemUniqueId);

  const columnDefs = useTraceEntryColumnDefs(hasFixedItemUniqueId);
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const formatFilter = {
        ...filterModel,
        item_unique_id: itemUniqueId && {
          type: "equals",
          filterType: "text",
          filter: itemUniqueId,
        },
        created_date: dateFilterModel(filterModel.created_date),
        scanned_date: dateFilterModel(filterModel.scanned_date),
        lot_date: dateFilterModel(filterModel.lot_date),
      };
      try {
        const { TraceEntriesViewAggrid } = await graphQLClientWms.request(
          TRACE_ENTRY_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel: sortModel,
            },
          }
        );
        params.success({
          rowData: TraceEntriesViewAggrid.results as any[],
          rowCount: TraceEntriesViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const itemUniqueIdInstance =
        params.api.getFilterInstance("item_unique_id");
      itemUniqueIdInstance?.setModel({ values: itemUniqueId });

      params.api.onFilterChanged();
    },
    [itemUniqueId]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onGridReady={onGridReady}
    />
  );
};

export default TraceEntryTable;
