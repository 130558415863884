import styled from "@emotion/styled";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

type ExtendedProps = TooltipProps & {
  isNoMaxWidth?: boolean;
  isCalendar?: boolean;
  disabled?: boolean;
  backgroundColor?: string;
  borderRadius?: number;
};

const CustomizedTooltipComponent = ({ className, ...props }: ExtendedProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
);

export const CustomizedTooltip = styled(CustomizedTooltipComponent)(
  ({ isCalendar, disabled, isNoMaxWidth, backgroundColor, borderRadius }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: backgroundColor ? backgroundColor : "#ECF6FD",
      color: disabled ? "#666666" : isCalendar ? "#32418F" : "#333333",
      boxShadow: "0px 2px 10px #E5E5E5",
      fontSize: 14,
      fontWeight: isCalendar ? 500 : 400,
      maxWidth: isNoMaxWidth ? "none" : 500,
      border: isCalendar ? "1px solid #32418F" : "none",
      borderRadius: borderRadius ? borderRadius : 16,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: backgroundColor ? backgroundColor : "#ECF6FD",
      "&::before": {
        border: isCalendar ? "1px solid #32418F" : "none",
        boxSizing: "border-box",
      },
    },
  })
);
