import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import HeaderLayout from "components/UI/HeaderLayout";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import PurchaseReturnTable from "../../../components/Table/Purchase/Return";
import ExporterPurchaseModal from "components/UI/Modal/ExporterPurchaseModal";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuOptionsMeatball from "components/Custom/CustomizedMenuOptionsMeatball";

import { IBreadcrumbsAndMenu, IMenuOption, ITab } from "../../../types/global";
import { useModal } from "hooks/use-modal";
import { PurchaseExportType } from "generated/purchase";

const PurchaseReturn = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.return.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_returned"),
      path: `${pathname}?filter=wait_returned`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  // const onFilterReset = () => {
  //   if (gridRef) {
  //     gridRef.current?.api.setFilterModel({});
  //   }
  // };

  const options: IMenuOption[] = [
    {
      value: t("purchase.export"),
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout sx={{ minHeight: "48px" }} noMaxWidth>
        <Typography variant="h5" marginY={"auto"}>
          {t("purchase.return.index")}
        </Typography>
        <Box display="flex">
          <CustomizedMenuOptionsMeatball
            isIcon
            options={options}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case `${t("purchase.export")}`:
                  openModalHandler();
                  break;
                default:
              }
            }}
            sx={{
              width: "46px",
              minWidth: "46px",
            }}
            disableRipple={true}
          />
        </Box>
      </HeaderLayout>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      </Box>
      <PurchaseReturnTable isFilter={isFilter} />
      <ExporterPurchaseModal
        open={modal}
        closeModalHandler={closeModalHandler}
        documentType={PurchaseExportType.PurchaseReturnExport}
      />
    </>
  );
};

export default PurchaseReturn;
