import { useEffect, useState } from "react";
import { IHeaderTable } from "types/global";

const defaultHeaders: IHeaderTable[] = [
  {
    thaiLabel: "",
    width: 50,
  },
  {
    thaiLabel: "วันที่และเวลา",
    width: 120,
  },
  {
    thaiLabel: "รูป",
    width: 100,
    align: "center",
  },
  {
    thaiLabel: "รหัส-ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวนคงคลัง",
    width: 120,
  },
  {
    thaiLabel: "จำนวนปรับปรุง",
    width: 120,
  },
  {
    thaiLabel: "จำนวนคงคลังใหม่",
    width: 120,
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    thaiLabel: "Batch/SN",
    width: 200,
  },
  {
    thaiLabel: "สถานที่",
    width: 150,
  },
  {
    thaiLabel: "ผู้สแกน",
    width: 100,
    align: "center",
  },
  {
    thaiLabel: "Barcode",
    width: 200,
    align: "left",
  },
  {
    thaiLabel: "Lot",
    width: 100,
  },
  {
    thaiLabel: "",
    width: 20,
  },
];

const headerPdf: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 20,
  },
  // {
  //   thaiLabel: "รูป",
  //   width: 50,
  // },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 110,
  },
  {
    thaiLabel: "จำนวนนำออก - หน่วย",
    width: 110,
  },
  {
    thaiLabel: "Batch/SN",
    width: 120,
  },
  {
    thaiLabel: "สถานที่",
    width: 120,
  },
  {
    thaiLabel: "Barcode",
    width: 200,
  },
  {
    thaiLabel: "หมายเหตุ",
    width: 80,
  },
  {
    thaiLabel: "LOT",
    width: 70,
  },
];

export const useGoodsAdjustItemList = (disabled: boolean) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    if (!disabled) {
      setHeaders([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 20,
        },
      ]);
    } else {
      setHeaders(defaultHeaders);
    }
  }, [disabled]);

  return headers;
};

export const useGoodsAdjustItemListPdf = () => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    setHeaders(headerPdf);
  }, []);

  return headers;
};
