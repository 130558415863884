import { GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "components/UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { RefObject } from "react";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { dateFilterModel } from "utils/AgGridFilter";
import { useQuotationReportColumnDefs } from "./columnDefs";
import { QUOTATION_REPORT } from "services/Sales/Report/QuotationReportViewsAggrid";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const QuotationReportTable = ({ gridRef }: Props) => {
  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const columnDefs = useQuotationReportColumnDefs();
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        issue_date,
        plan_start_date,
        plan_end_date,
        created_date,
        is_frozen,
        related_user_list,
      } = filterModel;
      const formatFilter = {
        ...filterModel,
        issue_date: dateFilterModel(issue_date),
        plan_start_date: dateFilterModel(plan_start_date),
        plan_end_date: dateFilterModel(plan_end_date),
        created_date: dateFilterModel(created_date),
        is_frozen: is_frozen && {
          filterType: "setBoolean",
          values: is_frozen.values.map((v: string) => v === "true"),
        },
        related_user_list: related_user_list
          ? {
              filterType: "array",
              type: "hasSome",
              values: related_user_list.values,
            }
          : undefined,
      };
      const formatSort = [...sortModel, { colId: "issue_date", sort: "desc" }];
      try {
        const { QuotationReportViewsAggrid } = await graphQLClientWms.request(
          QUOTATION_REPORT,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel: formatSort,
            },
          }
        );
        params.success({
          rowData: QuotationReportViewsAggrid.results,
          rowCount: QuotationReportViewsAggrid.count,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.onFilterChanged();
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onGridReady={onGridReady}
    />
  );
};

export default QuotationReportTable;
