import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  CheckboxSelectionCallbackParams,
  ColDef,
  RowSelectedEvent,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReactProps } from "ag-grid-react";
import AgGrid from "../AgGrid";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "./ModalUI";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import useStylesRadioCheckBoxTable from "theme/radio-check-box-table";

type ExtendedProps = {
  gridRef: any;
  modalTitle: string;
  btnTitle?: string;
  modalIsOpen: boolean;
  height: number | string;
  onFinishEditing: (data: any) => void;
  closeModal: () => void;
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  idsSnapshot: string[];
  setIdsSnapshot: React.Dispatch<React.SetStateAction<string[]>>;
  lockRows?: (string | undefined)[];
  disabledSidebar?: boolean;
  rowsToDisable?: any;
  columnDefs: ColDef[];
  isLoading?: boolean;
  keyName?: string;
  cancelBtnTitle?: string;
  isCancelable?: boolean;
  maxSelectionLimit?: number;
  rowMultiSelectWithClick?: boolean;
};

type CheckboxAggridModalProps = AgGridReactProps & ExtendedProps;

const CheckboxAggridModal: React.FC<CheckboxAggridModalProps> = ({
  gridRef,
  modalTitle,
  btnTitle,
  modalIsOpen,
  columnDefs,
  rowSelection,
  height,
  rowData,
  onFinishEditing,
  enableRangeSelection,
  closeModal,
  selectedIds,
  setSelectedIds,
  idsSnapshot,
  setIdsSnapshot,
  lockRows,
  getRowId,
  rowsToDisable,
  onFirstDataRendered,
  masterDetail,
  detailCellRendererParams,
  onRowClicked,
  onRowDoubleClicked,
  disabledSidebar,
  isLoading,
  onGridReady,
  children,
  keyName,
  maxSelectionLimit = Infinity,
  rowMultiSelectWithClick,
}: CheckboxAggridModalProps) => {
  const { t } = useTranslation();
  const [updatedColumnDefs, setUpdatedColumnDefs] = useState<ColDef<any>[]>([]);

  const isRowSelected = useCallback(
    (params: ValueGetterParams) => {
      const idKeyName = keyName ? keyName : columnDefs[0]?.field;
      return !!idKeyName && selectedIds.includes(params?.node?.data[idKeyName]);
    },
    [columnDefs, keyName, selectedIds]
  );

  const checkboxColumn = useMemo(() => {
    return {
      ...columnDefs[0],
      checkboxSelection: (params: CheckboxSelectionCallbackParams) => {
        if (!rowsToDisable || rowsToDisable.length === 0) return true;
        const idKeyName = keyName ? keyName : columnDefs[0]?.field;
        return !idKeyName || !rowsToDisable.includes(params?.data[idKeyName]);
      },
      valueGetter: (params: ValueGetterParams) => {
        if (isRowSelected(params) && params?.node)
          params.node.setSelected(true);
        const idKeyName = columnDefs[0]?.field;
        return idKeyName ? params?.data[idKeyName] : undefined;
      },
    };
  }, [columnDefs, rowsToDisable, keyName, isRowSelected]);

  useEffect(() => {
    if (columnDefs && checkboxColumn) {
      const newColumnDefs: ColDef[] = [checkboxColumn, ...columnDefs.slice(1)];
      setUpdatedColumnDefs(newColumnDefs);
    }
  }, [columnDefs, rowsToDisable, selectedIds, checkboxColumn]);

  const handleRowSelection = (params: RowSelectedEvent) => {
    const idKeyName = keyName ? keyName : columnDefs[0]?.field;
    if (!idKeyName) return setSelectedIds([]);

    const selectedId = params?.node?.data[idKeyName];
    if (!selectedId) {
      setSelectedIds([]);
      return;
    }

    if (rowsToDisable?.includes(selectedId)) {
      params?.node?.setSelected(false);
      return;
    }

    if (lockRows?.includes(selectedId)) {
      params?.node?.setSelected(true);
      return;
    }

    if (params?.node?.isSelected()) {
      if (
        !selectedIds.includes(selectedId) &&
        selectedIds.length < maxSelectionLimit
      ) {
        setSelectedIds((prevIds: string[]) => [...prevIds, selectedId]);
      } else if (selectedIds.length >= maxSelectionLimit) {
        params?.node?.setSelected(false);
      }
    } else {
      setSelectedIds((prevIds: string[]) =>
        prevIds.filter((id: string) => id !== selectedId)
      );
    }
  };

  const handleFinishSelecting = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setIdsSnapshot(selectedIds);
    const selectedData =
      rowSelection === "single" ? selectedRows[0] : selectedRows;
    onFinishEditing(selectedData || null);
    closeModal();
  };

  const handleCancelModal = () => {
    setSelectedIds(idsSnapshot);
    closeModal();
  };

  const style = useStylesRadioCheckBoxTable();

  return (
    <ModalUI
      open={modalIsOpen}
      handleClose={handleCancelModal}
      title={modalTitle}
      fullWidth
      maxWidth="lg"
      isLoading={isLoading}
      action={
        <Stack direction={"row"} gap={1} sx={{ mt: 2 }}>
          <CustomizedButton
            title={t("button.cancel")}
            onClick={handleCancelModal}
            variant="outlined"
          />
          <CustomizedButton
            title={btnTitle ?? t("button.confirm")}
            variant="contained"
            onClick={handleFinishSelecting}
          />
        </Stack>
      }
    >
      {children}
      <Box sx={rowSelection === "single" ? style.checkboxTable : undefined}>
        <AgGrid
          ref={gridRef}
          columnDefs={updatedColumnDefs}
          height={height}
          onGridReady={onGridReady}
          rowSelection={rowSelection}
          rowData={rowData}
          onRowSelected={handleRowSelection}
          onRowClicked={onRowClicked}
          onRowDoubleClicked={onRowDoubleClicked}
          enableRangeSelection={enableRangeSelection}
          getRowId={getRowId}
          rowMultiSelectWithClick={rowSelection !== "single"}
          onFirstDataRendered={onFirstDataRendered}
          masterDetail={masterDetail}
          detailCellRendererParams={detailCellRendererParams}
          disabledSidebar={disabledSidebar}
        />
      </Box>
    </ModalUI>
  );
};

export default CheckboxAggridModal;
