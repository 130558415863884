import { useCallback, useEffect, useRef } from "react";
import {
  GridReadyEvent,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import AgGrid from "components/UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { dateFilterModel } from "utils/AgGridFilter";
import { useMaintenanceColumnDefs } from "./columnDefs";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { MAINTENANCE_VIEW } from "services/AgGrid/MaintenanceAgGrid";

type Props = {
  status: string | null;
  referenceUniqueId?: string | null;
  vehicleId?: string;
};

const MaintenanceTable = ({ status, referenceUniqueId, vehicleId }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const columnDefs = useMaintenanceColumnDefs({
    sortByDate: Boolean(vehicleId),
  });
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        aggrid_status,
        issue_date,
        is_insurance_fully_paid,
        accident_date,
        ma_start_date,
        ma_end_date,
        created_date,
        last_updated_date,
        driver_object_list,
        reference_document_unique_id_list,
        created_by_object,
        ...otherFilter
      } = filterModel;
      const formatFilter = {
        ...otherFilter,
        issue_date: dateFilterModel(issue_date),
        ma_start_date: dateFilterModel(ma_start_date),
        ma_end_date: dateFilterModel(ma_end_date),
        is_insurance_fully_paid:
          is_insurance_fully_paid?.values?.length > 0
            ? {
                filterType: "boolean",
                type: "equals",
                filter: is_insurance_fully_paid.values?.[0] === "success",
              }
            : undefined,
        reference_document_unique_id_list:
          reference_document_unique_id_list && {
            filterType: "array",
            type: "hasSome",
            values: [reference_document_unique_id_list.filter],
          },
        accident_date: dateFilterModel(accident_date),
        created_date: dateFilterModel(created_date),
        last_updated_date: dateFilterModel(last_updated_date),
        created_by: created_by_object && {
          filterType: "set",
          values: created_by_object.values,
        },
        driver_list: driver_object_list && {
          filterType: "array",
          type: "hasSome",
          values: driver_object_list.values,
        },
        aggrid_status: aggrid_status && {
          ...aggrid_status,
          values: [...aggrid_status.values],
        },
        _or: vehicleId && {
          _orArray: [
            {
              vehicle_id: {
                filterType: "number",
                type: "equals",
                filter: parseInt(vehicleId),
              },
            },
            {
              trailer_id: {
                filterType: "number",
                type: "equals",
                filter: parseInt(vehicleId),
              },
            },
          ],
        },
      };
      try {
        const { MaintenanceViewAggrid } = await graphQLClientWms.request(
          MAINTENANCE_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: MaintenanceViewAggrid.results as any[],
          rowCount: MaintenanceViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const statusInstance = params.api.getFilterInstance("aggrid_status");
      if (status) {
        statusInstance.setModel({
          values: [status],
        });
        params.api.onFilterChanged();
      } else {
        statusInstance.setModel({
          values: ["draft", "wait_maintenance", "maintenancing", "finished"],
        });
        params.api.onFilterChanged();
      }
    },
    [status]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/logistic/maintenance/${id}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onRowDoubleClicked={rowSelectHandler}
      onGridReady={onGridReady}
      path={`/logistic/maintenance`}
    />
  );
};

export default MaintenanceTable;
