import { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import {
  useForm,
  useFormContext,
  useWatch,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFieldArrayReplace,
} from "react-hook-form";
import { useStateContext } from "contexts/auth-context";
import { useTranslation } from "react-i18next";

import { useModal } from "hooks/use-modal";
import { useDisable } from "hooks/use-disable";
import { useGoodsReceiveItemList } from "hooks/Inventory/use-receive-item-list";
import useInventoryControl from "hooks/Inventory/use-inventory-control";

import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import { TraceEntryType, Traceability } from "generated/wms";
import {
  Box,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  IconButton,
} from "@mui/material";

import SerialList from "./SerialList";
import TotalPostedQuantityCell from "./TotalPostedQuantityCell";

import SerialModal from "components/UI/Modal/SerialModal";
import CheckedItemModal from "components/UI/Modal/CheckedModal/CheckedItemModal";

import ControlledSelect from "components/Controller/ControlledSelect";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";

import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import CustomizedAvatar from "components/Custom/CustomizedAvatar";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";

import ClearIcon from "@mui/icons-material/Clear";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { IGoodsReceive, ISerialForm } from "types/Inventory/goodsReceive";
import { IBarcodeForm, ITraceEntry } from "types/Inventory";
import { IItem } from "types/Inventory/item";

import { formatDateTime } from "utils/Date";
import { checkIsNotDraftOrEmptyStatus } from "utils/Global";

// const options: IMenuOption[] = [
//   {
//     value: "นำออก Excel",
//     disabled: false,
//   },
//   {
//     value: "นำออก CSV",
//     disabled: false,
//   },
//   // {
//   //   value: "พิมพ์ทั้งหมด",
//   //   disabled: false,
//   // },
// ];

type Props = {
  fields: FieldArrayWithId<IGoodsReceive, "trace_entry_list", "id">[];
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsReceive, "trace_entry_list">;
  replace: UseFieldArrayReplace<IGoodsReceive, "trace_entry_list">;
};

const GoodsReceiveItemList = ({ fields, remove, replace, update }: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const { enqueueSnackbar } = useSnackbar();
  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<IGoodsReceive>();

  const watchType = useWatch({
    control,
    name: "type",
  });

  const watchWarehouseId = useWatch({
    control,
    name: "source_warehouse_id",
  });

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const { binLocationOptions } = useInventoryControl(watchWarehouseId);

  const headers = useGoodsReceiveItemList(Boolean(disabled), status);

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const {
    control: serialControl,
    reset: serialReset,
    getValues: getSerialValues,
  } = useForm<ISerialForm>({
    defaultValues: {
      serial_quantity: 1,
      quantity_per_serial: 1,
      remark: "",
      is_lot: false,
      lot_date: null,
    },
  });

  const {
    state: { authUser },
  } = useStateContext();

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const addItemsHandler = (selectedItems: IItem[]) => {
    const documentUniqueId = getValues("unique_id");

    if (selectedItems) {
      let filteredItems = fields
        .filter((item) => itemIds.includes(item.item_unique_id))
        .map(({ id, ...other }) => other) as ITraceEntry[];

      // get current items id to prevent duplication when appending newly selected items
      const filteredItemsId = filteredItems.map((item) => item.item_unique_id);

      selectedItems.forEach((item) => {
        if (!filteredItemsId.includes(item.unique_id)) {
          let formatItems: ITraceEntry | undefined;
          const itemImage =
            item.img_url_list?.find((img) => img.is_main_img)?.img_url ??
            undefined;
          const formattedItemImage = itemImage ? [itemImage] : [];

          if (item.tracability === Traceability.Serial) {
            formatItems = {
              unique_id: uuidv4(),
              type: TraceEntryType.GoodReceive,
              item_unique_id: item.unique_id || "",
              po_reference_unique_id: "",
              item_name: item.name || "",
              seller_sku: item.seller_sku || "",
              item_img_url: formattedItemImage,
              traceability: item.tracability,
              qty: 0,
              uom_id: item.stock_uom_id ?? undefined,
              uom: item.stock_uom_name || "",
              reference_unique_id: documentUniqueId,
              serial_list: [],
            };
          } else {
            formatItems = {
              unique_id: uuidv4(),
              type: TraceEntryType.GoodReceive,
              item_unique_id: item.unique_id || "",
              po_reference_unique_id: "",
              item_name: item.name || "",
              seller_sku: item.seller_sku || "",
              item_img_url: formattedItemImage,
              traceability: item.tracability || Traceability.Normal,
              qty: 0,
              uom_id: item.stock_uom_id ?? undefined,
              uom: item.stock_uom_name || "",
              status: "is_active",
              reference_unique_id: documentUniqueId,
              barcode: item.hidden_barcode,
              remark: "",
              lot_date: null,
            };
          }
          filteredItems.push(formatItems);
        }
      });
      // sort for proerly render order (and for role assignment when submitting)
      filteredItems = filteredItems.sort((a, b) => {
        return (
          itemIds.indexOf(a.item_unique_id) - itemIds.indexOf(b.item_unique_id)
        );
      });
      replace(filteredItems);
    }
    closeItemModalHandler();
  };

  const {
    modal: serialModal,
    openModalHandler: openSerialModalHandler,
    closeModalHandler: closeSerialModalHandler,
  } = useModal();

  const addSerialsHandler = () => {
    const { serial_quantity, quantity_per_serial, is_lot, lot_date, remark } =
      getSerialValues();

    const currentTrace: ITraceEntry = getValues(
      `trace_entry_list.${currentIndex}`
    );

    const currentSerialList = getValues(
      `trace_entry_list.${currentIndex}.serial_list`
    ) as ITraceEntry[];

    const warehouseId = getValues("source_warehouse_id");
    const uniqueId = getValues("unique_id");
    if (!uniqueId || uniqueId === "") {
      enqueueSnackbar(`กรุณาระบุเลขที่เอกสาร`, {
        variant: "error",
      });
      return closeSerialModalHandler();
    }

    for (let i = 0; i < serial_quantity; i++) {
      const padSn = String(currentSerialList.length + 1).padStart(4, "0");
      currentSerialList.push({
        item_unique_id: currentTrace.item_unique_id,
        po_reference_unique_id: currentTrace.po_reference_unique_id,
        item_name: currentTrace.item_name,
        seller_sku: currentTrace.seller_sku,
        item_img_url: currentTrace.item_img_url,
        reference_unique_id: currentTrace.reference_unique_id,
        type: currentTrace.type,
        unique_id: uuidv4(),
        qty: quantity_per_serial,
        traceability: Traceability.Serial,
        uom_id: currentTrace.uom_id,
        uom: currentTrace.uom,
        serial_no: `${warehouseId}#${uniqueId}#${padSn}`,
        status: "is_active",
        is_lot,
        lot_date: is_lot ? lot_date : null,
        remark,
      });
    }

    const traceWithUpdatedSerialList = {
      ...currentTrace,
      serial_list: currentSerialList,
    };

    update(currentIndex, traceWithUpdatedSerialList);

    closeSerialModalHandler();
    serialReset();
  };

  const {
    control: barcodeControl,
    getValues: getBarcodeValues,
    reset: resetBarcode,
    watch: watchBarcode,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      source_bin_location_id: undefined,
    },
  });

  const onBarcodeSubmitHandler = (barcode?: string) => {
    const source_bin_location_id = getBarcodeValues("source_bin_location_id");
    const foundBinLocation = binLocationOptions?.find(
      (bin) => bin.value === source_bin_location_id
    );

    const traceList = getValues("trace_entry_list");

    if (barcode) {
      for (let index = 0; index < traceList.length; index++) {
        const trace = traceList[index];
        if (trace.traceability === Traceability.Normal) {
          if (trace.barcode === barcode.trim()) {
            if (trace.status === "is_active") {
              const currentTrace = getValues(`trace_entry_list.${index}`);
              update(index, {
                ...currentTrace,
                scanned_by: {
                  first_name: authUser?.first_name || "",
                  last_name: authUser?.last_name || "",
                  email: authUser?.email || "",
                  img_url: authUser?.img_url,
                  user_unique_id: authUser?.unique_id || "",
                },
                source_bin_location: {
                  id: foundBinLocation?.value,
                  name: foundBinLocation?.label,
                },
                source_bin_location_id,
                status: "is_scanned",
                scanned_date: dayjs().toDate(),
              });
              enqueueSnackbar(`สแกน SN :\n${currentTrace.barcode}\nสำเร็จ`, {
                variant: "success",
                style: { whiteSpace: "pre-line" },
              });
              break;
            } else {
              if (
                traceList.findIndex((trace) => trace.status === "is_active") ===
                -1
              ) {
                enqueueSnackbar(
                  `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                  {
                    variant: "error",
                    style: { whiteSpace: "pre-line" },
                  }
                );
              }
            }
          }
        } else {
          trace.serial_list?.forEach((serial, subIndex) => {
            if (serial.barcode === barcode.trim()) {
              if (serial.status === "is_active") {
                const currentTrace = traceList[index];
                const formatSerial = {
                  ...serial,
                  scanned_by: {
                    first_name: authUser?.first_name || "",
                    last_name: authUser?.last_name || "",
                    email: authUser?.email || "",
                    img_url: authUser?.img_url,
                    user_unique_id: authUser?.unique_id || "",
                  },
                  source_bin_location: {
                    id: foundBinLocation?.value,
                    name: foundBinLocation?.label,
                  },
                  source_bin_location_id,
                  status: "is_scanned",
                  scanned_date: dayjs().toDate(),
                };
                if (currentTrace && currentTrace.serial_list) {
                  currentTrace.serial_list[subIndex] = formatSerial;
                }
                update(index, currentTrace);
                enqueueSnackbar(`สแกน SN :\n${serial.barcode}\nสำเร็จ`, {
                  variant: "success",
                  style: { whiteSpace: "pre-line" },
                });
              } else {
                enqueueSnackbar(
                  `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                  {
                    variant: "error",
                    style: { whiteSpace: "pre-line" },
                  }
                );
              }
            }
          });
        }
      }

      if (
        !fields.some((trace) => {
          if (trace.traceability === Traceability.Normal) {
            return trace.barcode === barcode.trim();
          } else {
            if (
              !trace.serial_list?.some(
                (serial) => serial.barcode === barcode.trim()
              )
            ) {
              return false;
            } else {
              return true;
            }
          }
        })
      ) {
        enqueueSnackbar(`QR/Barcode\nสินค้านี้ไม่อยู่ในรายการ\nกรุณาสแกนใหม่`, {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        });
      }
    }

    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  };

  const notDraft = checkIsNotDraftOrEmptyStatus(status);
  const isFinished = ["finished", "cancelled"].includes(status);

  const rescanBarcodeHandler = (index: number) => {
    const { id, ...otherFields } = fields[index];
    update(index, {
      ...otherFields,
      status: "is_active",
      source_bin_location: undefined,
      source_bin_location_id: undefined,
      scanned_by: undefined,
      scanned_date: undefined,
    });
  };

  return (
    <>
      <CustomizedBox maxWidth={1650} margin={"0 0 1.5rem 0"}>
        {status && status === "wait_receive" && (
          <>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography fontWeight={"bold"}>สแกน Barcode</Typography>
            </Box>
            <Box maxWidth={996}>
              <Grid container spacing={1.5}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledSelect
                    label="เลือกสถานที่"
                    control={barcodeControl}
                    name="source_bin_location_id"
                    options={binLocationOptions}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <ControlledTextField
                    label="Scan Barcode"
                    control={barcodeControl}
                    name="barcode"
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.shiftKey === false) {
                        const target = e.target as HTMLInputElement;
                        const data = target.value;
                        onBarcodeSubmitHandler(data);
                      }
                    }}
                    disabled={!watchBarcode("source_bin_location_id")}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography
            fontWeight={"bold"}
            mt={status && status === "wait_receive" ? 2 : 0}
          >
            รายการสินค้า
          </Typography>
          {/* {notDraft && (
            <CustomizedMenuOptions
              size="medium"
              label="นำออก Barcode"
              options={options}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case `นำออก Excel`:
                    exportBarcodeHandler("xlsx");
                    break;
                  case `นำออก CSV`:
                    exportBarcodeHandler();
                    break;
                  case `พิมพ์ทั้งหมด`:
                    // const traceEntry =
                    //   goodReceiveData?.trace_entry_list as ITraceEntry[];
                    // printBarcodeHandler(traceEntry);
                    break;
                  default:
                }
              }}
            />
          )} */}
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 650, overflow: "scroll" }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                bgcolor: "#ecf6fd",
              }}
            >
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align ? header.align : "center"}
                    key={index}
                    sx={{
                      px: 1,
                    }}
                    width={header.width}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      width={header.width}
                    >
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((trace, index) => (
                <Fragment key={trace.id}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[0]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{index + 1}</Typography>
                    </TableCell>
                    {notDraft && (
                      <TableCell
                        align="center"
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[0]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>
                          {formatDateTime(trace.scanned_date)}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[1]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          minWidth: "90px",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          alt={trace.item_name}
                          src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                          sx={{
                            width: 60,
                            height: 60,
                            border: "1px solid #BEBEBE",
                            borderRadius: "2px",
                          }}
                          variant="square"
                        >
                          <ImageOutlinedIcon
                            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                            fontSize="medium"
                          />
                        </Avatar>
                      </Box>
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[2]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {trace.item_unique_id}
                      </Typography>
                      <Typography fontSize={14}>{trace.item_name}</Typography>
                      {/* {goodReceiveData?.main_status === "draft" &&
                        initialTraceItems?.includes(trace.item_unique_id) &&
                        !existingInitialTraceItems?.includes(
                          trace.item_unique_id
                        ) && (
                          <Typography
                            fontSize={12}
                            sx={{
                              mt: 0.5,
                              color: (theme) => theme.palette.error.main,
                            }}
                          >
                            สินค้านี้ถูกลบออกจากระบบแล้ว กรุณาลบสินค้า
                          </Typography>
                        )} */}
                    </TableCell>
                    {!notDraft && (
                      <TableCell
                        align="center"
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[4]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>
                          {trace.document_item_qty || 0}
                        </Typography>
                      </TableCell>
                    )}
                    {!notDraft && (
                      <TableCell
                        align="center"
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[5]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>
                          {trace.posted_qty || 0}
                        </Typography>
                      </TableCell>
                    )}
                    {!notDraft && (
                      <TableCell
                        align="center"
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[6]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>
                          {(trace.document_item_qty || 0) -
                            (trace.posted_qty || 0)}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[7]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.traceability === Traceability.Normal ? (
                        <ControlledNumberTextField
                          fullWidth
                          control={control}
                          name={`trace_entry_list.${index}.qty`}
                          error={Boolean(
                            (errors &&
                              errors.trace_entry_list &&
                              errors.trace_entry_list[index] &&
                              errors.trace_entry_list[index]?.qty) ||
                              (watchType !== "other" &&
                                trace.qty >
                                  (trace.document_item_qty || 0) -
                                    (trace.posted_qty || 0))
                          )}
                          viewMode={notDraft}
                          decimalScale={0}
                        />
                      ) : (
                        <TotalPostedQuantityCell nestedIndex={index} />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[8]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography fontSize={14}>{trace.uom}</Typography>
                      </Box>
                    </TableCell>
                    {!notDraft && (
                      <>
                        <TableCell />
                        <TableCell
                          align="center"
                          width={
                            (headers && headers.length > 0
                              ? headers[10]?.width || 0
                              : 0) + 16
                          }
                        >
                          {trace.traceability === Traceability.Serial && (
                            <IconButton
                              onClick={() => {
                                setCurrentIndex(index);
                                openSerialModalHandler();
                              }}
                              disabled={!watchWarehouseId}
                              color={
                                errors &&
                                errors.trace_entry_list &&
                                errors.trace_entry_list[index] &&
                                errors.trace_entry_list?.[index]?.serial_list &&
                                errors.trace_entry_list?.[index]?.serial_list
                                  ?.type === "min"
                                  ? "error"
                                  : "primary"
                              }
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          )}
                        </TableCell>
                      </>
                    )}
                    {notDraft && (
                      <Fragment>
                        <TableCell />
                        <TableCell align="center">
                          {trace.source_bin_location?.name}
                        </TableCell>
                        <TableCell>
                          {trace.traceability === Traceability.Normal && (
                            <Box
                              display={"flex"}
                              justifyContent={
                                isFinished ? "center" : "flex-start"
                              }
                            >
                              <CustomizedStatus status={trace.status} />
                              {!isFinished && trace.status === "is_scanned" && (
                                <CustomizedTooltip title="สแกนใหม่">
                                  <IconButton
                                    onClick={() => rescanBarcodeHandler(index)}
                                  >
                                    <ReplayOutlinedIcon fontSize="small" />
                                  </IconButton>
                                </CustomizedTooltip>
                              )}
                            </Box>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {trace.scanned_by &&
                            Object.keys(trace.scanned_by).length > 0 && (
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <CustomizedAvatar
                                  avatars={[
                                    {
                                      unique_id:
                                        trace.scanned_by.user_unique_id || "",
                                      first_name:
                                        trace.scanned_by.first_name || "",
                                      last_name:
                                        trace.scanned_by.last_name || "",
                                      img_url: trace.scanned_by.img_url
                                        ? trace.scanned_by.img_url[0]
                                        : "",
                                    },
                                  ]}
                                />
                              </Box>
                            )}
                        </TableCell>
                        <TableCell>
                          {trace.traceability === Traceability.Normal &&
                            trace.barcode}
                        </TableCell>
                      </Fragment>
                    )}
                    {!notDraft && (
                      <TableCell align="center">
                        {trace.traceability === Traceability.Normal && (
                          <Typography fontSize={14}>
                            <ControlledTextField
                              placeholder={t("sentence.remark")}
                              control={control}
                              name={`trace_entry_list.${index}.remark`}
                              error={Boolean(
                                errors?.trace_entry_list?.[index]?.remark
                              )}
                              helperText={
                                errors?.trace_entry_list?.[index]?.remark
                                  ?.message
                              }
                              viewMode={isFinished}
                              viewModeNoLabel
                            />
                          </Typography>
                        )}
                      </TableCell>
                    )}
                    <TableCell align="center"></TableCell>
                    {!notDraft && (
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            remove(index);
                            setItemIds((prev) =>
                              prev.filter((id) => id !== trace.item_unique_id)
                            );
                            setItemIdsSnapshot((prev) =>
                              prev.filter((id) => id !== trace.item_unique_id)
                            );
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                  <SerialList
                    nestedIndex={index}
                    disabled={disabled}
                    status={status}
                  />
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2} display={"flex"} alignItems={"center"} gap={1}>
          {(!status || status === "draft") && (
            <CustomizedButton
              variant="outlined"
              title={t("button.add_item")}
              onClick={openItemModalHandler}
              disabled={watchType?.length === 0 || watchType === "purchase"}
              startAddIcon
            />
          )}
        </Box>
      </CustomizedBox>

      {/* <CustomizedBox maxWidth={1650} margin={"0 0 1.5rem 0"}>

      </CustomizedBox> */}
      <CheckedItemModal
        showSelectItem={itemModal}
        closeItemTable={closeItemModalHandler}
        finishItemsSelect={addItemsHandler}
        itemIds={itemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIds={setItemIds}
        setItemIdsSnapshot={setItemIdsSnapshot}
        rowSelection="multiple"
      />
      <SerialModal
        control={serialControl}
        reset={serialReset}
        serialModal={serialModal}
        closeSerialModalHandler={closeSerialModalHandler}
        addSerialsHandler={addSerialsHandler}
      />
    </>
  );
};

export default GoodsReceiveItemList;
