import { Dispatch, SetStateAction, useCallback, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ColDef, GetRowIdParams, GridReadyEvent } from "ag-grid-community";
import { IServerSideDatasource } from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { DELIVERY_TRIP_FOR_BILLING_NOTE_VIEW } from "services/AgGrid/DeliveryTripAgGrid";
import CheckboxAggridModal from "./CheckboxAggridModal";
import { useDeliveryTripListColumnDefs } from "components/Table/Sales/BillingNote/DeliveryTripList/columnDefs";
import { dateFilterModel } from "utils/AgGridFilter";

type CheckedDeliveryTripModalProps = {
  showSelectDeliveryTrip: boolean;
  closeDeliveryTripTable: () => void;
  finishDeliveryTripsSelect: (data: any) => void;
  deliveryTripIds: string[];
  setDeliveryTripIds: Dispatch<SetStateAction<string[]>>;
  deliveryTripIdsSnapshot: string[];
  setDeliveryTripIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowData?: any[];
  isLoading?: boolean;
  rowSelection?: "single" | "multiple";
  customerUniqueId?: string;
  billingNoteUniqueId?: string;
};

const CheckedDeliveryTripModal = ({
  showSelectDeliveryTrip,
  closeDeliveryTripTable,
  finishDeliveryTripsSelect,
  deliveryTripIds,
  setDeliveryTripIds,
  deliveryTripIdsSnapshot,
  setDeliveryTripIdsSnapshot,
  isLoading,
  rowSelection = "multiple",
  customerUniqueId,
  billingNoteUniqueId,
}: CheckedDeliveryTripModalProps) => {
  const { t } = useTranslation();
  const selectGridRef = useRef();

  const graphQLClientWithHeaderDeliveryTrip: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const columnDefs: ColDef[] = useDeliveryTripListColumnDefs();

  const datasource: IServerSideDatasource = useMemo(
    () => ({
      async getRows(params) {
        const { request } = params;
        const { startRow, endRow, filterModel, sortModel } = request;

        const {
          issue_date,
          aggrid_status,
          customer_unique_id,
          ...otherFilter
        } = filterModel;
        const formatFilter = {
          ...otherFilter,
          issue_date: dateFilterModel(issue_date),
          aggrid_status: aggrid_status && {
            ...aggrid_status,
            values: [...aggrid_status.values],
          },
          customer_unique_id: customerUniqueId
            ? { filterType: "text", type: "equals", filter: customerUniqueId }
            : customer_unique_id,
          exceed_total_amount: {
            filterType: "setBoolean",
            values: [false],
          },
          // billing_note_unique_id_list: {
          //   filterType: "array",
          //   operator: "OR",
          //   // condtions are either the array is empty or array contain input unique id
          //   condition1: {
          //     filterType: "array",
          //     type: "isEmpty",
          //     values: true,
          //   },
          //   condition2: {
          //     filterType: "array",
          //     type: "has",
          //     values: billingNoteUniqueId ?? "",
          //   },
          // },
        };

        try {
          const { DeliveryTripForBillingNoteViewAggrid } =
            await graphQLClientWithHeaderDeliveryTrip.request(
              DELIVERY_TRIP_FOR_BILLING_NOTE_VIEW,
              {
                aggridInput: {
                  startRow,
                  endRow,
                  filterModel: formatFilter,
                  sortModel,
                },
              }
            );
          params.success({
            rowData: DeliveryTripForBillingNoteViewAggrid.results as any[],
            rowCount: DeliveryTripForBillingNoteViewAggrid.count as number,
          });
        } catch (err) {
          console.error("Error fetching data:", err);
          params.fail();
        }
      },
    }),
    [customerUniqueId, graphQLClientWithHeaderDeliveryTrip]
  );

  const onFilterChanged = useCallback((params: any) => {
    params.api.onFilterChanged();
  }, []);

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxAggridModal
      modalTitle={t("logistic.delivery_trip.index")}
      cancelBtnTitle={t("button.cancel")}
      btnTitle={t("button.confirm")}
      gridRef={selectGridRef}
      height={665}
      columnDefs={columnDefs}
      rowSelection={rowSelection}
      onFinishEditing={finishDeliveryTripsSelect}
      modalIsOpen={showSelectDeliveryTrip}
      getRowId={getRowId}
      closeModal={closeDeliveryTripTable}
      selectedIds={deliveryTripIds}
      setSelectedIds={setDeliveryTripIds}
      idsSnapshot={deliveryTripIdsSnapshot}
      setIdsSnapshot={setDeliveryTripIdsSnapshot}
      isLoading={isLoading}
      onGridReady={onGridReady}
      rowMultiSelectWithClick={true}
      keyName="unique_id"
    />
  );
};

export default CheckedDeliveryTripModal;
