import { useCallback, useEffect, useRef } from "react";
import { useGoodsReceiveColumnDefs } from "./columnDefs";
import {
  GridReadyEvent,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import AgGrid from "components/UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { dateFilterModel } from "utils/AgGridFilter";
import { GOODS_RECEIVE_VIEW } from "services/AgGrid/GoodsReceiveAggrid";

type Props = {
  status?: string | null;
  referenceUniqueId?: string;
};

const GoodsReceiveTable = ({ status, referenceUniqueId }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const graphQLClientWms: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const columnDefs = useGoodsReceiveColumnDefs();
  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        reference_unique_id_list,
        created_date,
        scanned_date,
        posted_date,
        ...otherFilter
      } = filterModel;
      const formatFilter = {
        ...otherFilter,
        created_date: dateFilterModel(created_date),
        scanned_date: dateFilterModel(scanned_date),
        posted_date: dateFilterModel(posted_date),
        reference_unique_id_list: referenceUniqueId
          ? {
              filterType: "array",
              type: "hasSome",
              values: [referenceUniqueId],
            }
          : reference_unique_id_list
          ? {
              filterType: "array",
              type: "hasSome",
              values: [reference_unique_id_list.filter],
            }
          : undefined,
      };
      const formatSort = [
        ...sortModel,
        { colId: "created_date", sort: "desc" },
      ];
      try {
        const { GoodsReceivesViewAggrid } = await graphQLClientWms.request(
          GOODS_RECEIVE_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel: formatSort,
            },
          }
        );
        params.success({
          rowData: GoodsReceivesViewAggrid.results as any[],
          rowCount: GoodsReceivesViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const statusInstance = params.api.getFilterInstance("aggrid_status");
      switch (status) {
        case "draft":
        case "wait_receive":
        case "finished":
        case "cancelled":
          statusInstance.setModel({ values: [status] });
          break;
        default:
          statusInstance.setModel({
            values: ["draft", "wait_receive", "finished"],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [status]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/inventory/goods-receive/${id}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onRowDoubleClicked={rowSelectHandler}
      onGridReady={onGridReady}
      path={`/inventory/goods-receive`}
    />
  );
};

export default GoodsReceiveTable;
