import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import { ActivityLog } from "components/UI/ActivityLog";
import { useActivityLog } from "hooks/use-activity-log";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import { Box, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { ActivityLogDocumentType } from "generated/general";
import { InventoryControlDocumentType } from "generated/wms";
import { IBreadcrumbsAndMenu } from "types/global";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

const activityLogType = (type: InventoryControlDocumentType) => {
  switch (type) {
    case "good_receive":
      return ActivityLogDocumentType.GoodsReceive;
    case "good_issue":
      return ActivityLogDocumentType.GoodsIssue;
    case "good_transfer":
      return ActivityLogDocumentType.GoodsTransfer;
    case "good_adjust":
      return ActivityLogDocumentType.GoodsAdjustment;
    default:
      return ActivityLogDocumentType.GoodsReceive;
  }
};

const formatTypeString = (type: InventoryControlDocumentType) => {
  switch (type) {
    case "good_receive":
      return "goods_receive";
    case "good_issue":
      return "goods_issue";
    case "good_transfer":
      return "goods_transfer";
    case "good_adjust":
      return "goods_adjust";
    default:
      return "goods_receive";
  }
};

const formatTypeUrlString = (type: InventoryControlDocumentType) => {
  switch (type) {
    case "good_receive":
      return "goods-receive";
    case "good_issue":
      return "goods-issue";
    case "good_transfer":
      return "goods-transfer";
    case "good_adjust":
      return "goods-adjust";
    default:
      return "goods-receive";
  }
};

interface Props {
  type: InventoryControlDocumentType;
}

const HeaderBreadcrumbs = ({ type }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { openActivityLog, onActivityLogOpen, onActivityLogClose } =
    useActivityLog();

  const { getValues } = useFormContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t(`inventory.${formatTypeString(type)}.index`),
      to: `/inventory/${formatTypeUrlString(type)}`,
    },
    {
      name:
        id && Boolean(id)
          ? getValues("unique_id")
          : t(
              `${t("button.create")}${t(
                `inventory.${formatTypeString(type)}.index`
              )}`
            ),
    },
  ];

  return (
    <>
      {id && (
        <ActivityLog
          open={openActivityLog}
          onClose={onActivityLogClose}
          documentId={id!}
          documentType={activityLogType(type)}
        />
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: 1040,
        }}
      >
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {id && (
            <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
              <IconButton
                onClick={onActivityLogOpen}
                sx={{
                  color: "primary.main",
                  padding: 0,
                  marginLeft: "4px",
                }}
              >
                <RestoreOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Box>
      </Box>
    </>
  );
};

export default HeaderBreadcrumbs;
