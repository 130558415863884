import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ColDef, GetRowIdParams, GridReadyEvent } from "ag-grid-community";
import { IServerSideDatasource } from "ag-grid-community";
import { GraphQLClient } from "graphql-request";
import { ItemType } from "generated/wms";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { ITEM_VIEW } from "services/AgGrid/ItemAggrid";
import CheckboxAggridModal from "./CheckboxAggridModal";
import { useItemColumnDefs } from "components/Table/Inventory/Item/columnDefs";

type CheckedItemModalProps = {
  showSelectItem: boolean;
  closeItemTable: () => void;
  finishItemsSelect: (data: any) => void;
  itemIds: string[];
  setItemIds: Dispatch<SetStateAction<string[]>>;
  itemIdsSnapshot: string[];
  setItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowData?: any[];
  isLoading?: boolean;
  rowSelection?: "single" | "multiple";
};

const CheckedItemModal = ({
  showSelectItem,
  closeItemTable,
  finishItemsSelect,
  itemIds,
  setItemIds,
  itemIdsSnapshot,
  setItemIdsSnapshot,
  isLoading,
  rowSelection = "multiple",
}: CheckedItemModalProps) => {
  const { t } = useTranslation();
  const selectGridRef = useRef();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");

  const columnDefs: ColDef[] = useItemColumnDefs({
    type: null,
    active_only: true,
    is_item_modal: true,
    isCheckbox: true,
  });

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { is_active, tag_list, cate_1, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => parseInt(v)),
        },
        tag_list: tag_list && {
          type: "hasSome",
          filterType: "array",
          values: tag_list.values,
        },
        cate_1: cate_1 && {
          type: "in",
          filterType: "set",
          values: cate_1.values,
        },
      };

      try {
        const { ItemsViewAggrid } = await graphQLClientWithHeaderItem.request(
          ITEM_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: ItemsViewAggrid.results as any[],
          rowCount: ItemsViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setFilterModel({
      type: {
        type: "set",
        values: [ItemType.Normal],
      },
      is_active: {
        type: "set",
        values: ["1"],
      },
    });
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxAggridModal
      modalTitle={"สินค้า"}
      cancelBtnTitle={t("button.cancel")}
      btnTitle={t("button.confirm")}
      gridRef={selectGridRef}
      height={665}
      columnDefs={columnDefs}
      rowSelection={rowSelection}
      onFinishEditing={finishItemsSelect}
      modalIsOpen={showSelectItem}
      getRowId={getRowId}
      closeModal={closeItemTable}
      selectedIds={itemIds}
      setSelectedIds={setItemIds}
      idsSnapshot={itemIdsSnapshot}
      setIdsSnapshot={setItemIdsSnapshot}
      isLoading={isLoading}
      onGridReady={onGridReady}
      rowMultiSelectWithClick={true}
      keyName="unique_id"
    />
  );
};

export default CheckedItemModal;
