import React from "react";
import { IPermission, IUser } from "../types/Auth/user";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

type State = {
  authUser: IUser | null;
  permissions: IPermission | null;
};

type Action = {
  type: string;
  payload: IUser | IPermission | null;
};

type Dispatch = (action: Action) => void;

type logout = () => void;

const initialState: State = {
  authUser: null,
  permissions: null,
};

type StateContextProviderProps = { children: React.ReactNode };

const StateContext = React.createContext<
  { state: State; dispatch: Dispatch; logout: logout } | undefined
>(undefined);

const stateReducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "SET_USER": {
      return {
        ...state,
        authUser: action.payload as IUser,
      };
    }
    case "SET_PERMISSION": {
      return {
        ...state,
        permissions: action.payload as IPermission,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const StateContextProvider = ({ children }: StateContextProviderProps) => {
  const navigate = useNavigate();
  const [, setCookie, removeCookie] = useCookies([
    "access_token",
    "refresh_token",
    "logged_in",
  ]);
  const [state, dispatch] = React.useReducer(stateReducer, initialState);

  const logout = () => {
    dispatch({
      type: "SET_USER",
      payload: null,
    });
    removeCookie("access_token");
    removeCookie("refresh_token");
    setCookie("logged_in", "false", { path: "/" });
    navigate("/login");
  };

  const value = { state, dispatch, logout };
  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
};

const useStateContext = () => {
  const context = React.useContext(StateContext);

  if (context) {
    return context;
  }

  throw new Error(`useStateContext must be used within a StateContextProvider`);
};

export { StateContextProvider, useStateContext };
