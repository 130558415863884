import { ICompany } from "../../../types/Setting/Company";
import * as Yup from "yup";
import { phoneRegExp } from "../../../utils/Global";
import { stringMaxInput } from "../../../utils/Validate";

export const companySchema: ICompany = {
  unique_id: "",
  name: "",
  type: "บริษัท",
  identity_no: "",
  register_date: null,
  is_registered_vat: false,
  vat_registration_date: null,
  img_url: [],
  contact_channel_list: [
    {
      contact_channel_type: "phone",
      contact_channel_info: "",
    },
    {
      contact_channel_type: "email",
      contact_channel_info: "",
    },
  ],
  address_list: [
    {
      address_type: "ที่อยู่จดทะเบียน",
      name: "",
      district: "",
      is_default: true,
      postal_code: "",
      province: "",
      sub_district: "",
      address_contact_name: "",
      address_contact_phone: "",
      country: "thailand",
      address_contact_fax: "",
    },
    {
      address_type: "ที่อยู่จัดส่งสินค้า",
      name: "",
      district: "",
      is_default: false,
      postal_code: "",
      province: "",
      sub_district: "",
      address_contact_name: "",
      address_contact_phone: "",
      country: "thailand",
      address_contact_fax: "",
    },
  ],
};

const contactChannelListValidation = Yup.object().shape({
  contact_channel_type: Yup.string().required("กรุณาระบุ"),
  contact_channel_info: Yup.string()
    .max(200, "กรอกได้ไม่เกิน 200 ตัวอักษร")
    .when("contact_channel_type", (contact_channel_type, schema) => {
      if (
        Array.isArray(contact_channel_type) &&
        contact_channel_type[0] === "phone"
      )
        return schema.matches(phoneRegExp, "กรุณาระบุให้ถูกต้อง");
      if (
        Array.isArray(contact_channel_type) &&
        contact_channel_type[0] === "email"
      )
        return schema.email("กรุณาระบุอีเมลให้ถูกต้อง");
      else return schema;
    }),
});

const addressValidate = stringMaxInput(200, undefined, false).nullable();

export const companyValidation = Yup.object().shape({
  unique_id: stringMaxInput(),
  name: stringMaxInput(),
  type: Yup.string().required("กรุณาระบุ"),
  identity_no: stringMaxInput(13),
  contact_channel_list: Yup.array().of(contactChannelListValidation),
  address_list: Yup.array().of(
    Yup.object().shape({
      name: addressValidate,
      district: addressValidate,
      province: addressValidate,
      sub_district: addressValidate,
      postal_code: addressValidate,
      address_contact_name: addressValidate,
      address_contact_phone: addressValidate,
      address_contact_fax: addressValidate,
    })
  ),
});
