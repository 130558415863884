import { Box, Grid, IconButton, Typography } from "@mui/material";
import ControlledDateTimePicker from "components/Controller/ControlledDateTimePicker";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import { useDisable } from "hooks/use-disable";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import {
  useVehicleCurrentKiloQuery,
  VehicleCurrentKiloQuery,
} from "generated/vehicle";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

const DateDistanceInfo = () => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<IDeliveryTrip>();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const { state } = useLocation();

  const mainStatus = useWatch({
    control,
    name: "main_status",
  });
  const aggrid_status = useWatch({
    control,
    name: "aggrid_status",
  });
  const flagStatus = useWatch({
    control,
    name: "flag_status",
  });

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;
  const vehicleId = useWatch({
    control,
    name: "vehicle_id",
  });

  const isShow = ["delivering", "finished", "cancelled"].includes(status);
  const isFinished = status === "finished";

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { refetch: refetchCurrentKilo } =
    useVehicleCurrentKiloQuery<VehicleCurrentKiloQuery>(
      graphQLClient,
      {
        vehicleFindUniqueId: vehicleId
          ? vehicleId
          : state && state.vehicle_id
          ? state.vehicle_id
          : 0,
      },
      {
        enabled: false,
      }
    );

  const fetchCurrentKilo = useCallback(async () => {
    const { data } = await refetchCurrentKilo();
    const start_kilometer = data?.VehicleFindUnique?.current_kilometer ?? 0;
    setValue("start_kilometer", start_kilometer);

    const end_kilometer = getValues("end_kilometer") || 0;

    if (end_kilometer) {
      const total_kilometer_distance = end_kilometer - start_kilometer;
      setValue("total_kilometer_distance", total_kilometer_distance);
    }
  }, [getValues, refetchCurrentKilo, setValue]);

  useEffect(() => {
    if (state && state.vehicle_id) {
      fetchCurrentKilo();
    }
  }, [fetchCurrentKilo, state]);

  return (
    <CustomizedBox>
      <Typography fontWeight={600} mb={1}>
        {t("logistic.delivery_trip.datetime")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4}>
          <ControlledDateTimePicker
            control={control}
            name="plan_start_date"
            label={t("logistic.delivery_trip.plan_start_date")}
            disabled={disabled || isShow}
            viewMode={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <ControlledDateTimePicker
            control={control}
            name="plan_end_date"
            label={t("logistic.delivery_trip.plan_end_date")}
            disabled={disabled || isShow}
            viewMode={disabled}
            required
          />
        </Grid>
        <Box width="100%" />
        {isShow && (
          <>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDateTimePicker
                control={control}
                name="actual_start_date"
                label={t("logistic.delivery_trip.actual_start_date")}
                disabled={disabled || isFinished}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDateTimePicker
                control={control}
                name="actual_end_date"
                label={t("logistic.delivery_trip.actual_end_date")}
                disabled={disabled || isFinished}
                viewMode={disabled}
                required
              />
            </Grid>
          </>
        )}
      </Grid>
      <Typography fontWeight={600} my={1}>
        {t("logistic.delivery_trip.distance")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          display={"flex"}
          alignItems={"flex-start"}
        >
          <ControlledNumberTextField
            fullWidth
            control={control}
            name="start_kilometer"
            label={t("logistic.delivery_trip.start_kilometer")}
            disabled={disabled || isFinished}
            error={Boolean(errors.start_kilometer)}
            helperText={errors.start_kilometer?.message}
            required
            onChange={(value) => {
              const end_kilometer = getValues("end_kilometer");
              if (end_kilometer) {
                const total = (end_kilometer || 0) - (value || 0);
                setValue("total_kilometer_distance", total);
              } else {
                setValue("total_kilometer_distance", 0);
              }
            }}
            decimalScale={0}
            viewMode={disabled}
            showZero
          />
          {!disabled && !isFinished && vehicleId && (
            <CustomizedTooltip title="เรียกเลขกิโลเมตรปัจจุบัน">
              <IconButton onClick={fetchCurrentKilo} color="primary">
                <RestartAltOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Grid>
        {isShow && (
          <>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledNumberTextField
                fullWidth
                control={control}
                name="end_kilometer"
                label={t("logistic.delivery_trip.end_kilometer")}
                disabled={disabled || isFinished}
                error={Boolean(errors.end_kilometer)}
                helperText={errors.end_kilometer?.message}
                required
                onChange={(value) => {
                  const start_kilometer = getValues("start_kilometer");
                  if (value) {
                    const total = (value || 0) - (start_kilometer || 0);
                    setValue("total_kilometer_distance", total);
                  } else {
                    setValue("total_kilometer_distance", 0);
                  }
                }}
                viewMode={disabled}
                decimalScale={0}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledNumberTextField
                fullWidth
                control={control}
                name="total_kilometer_distance"
                label={t("logistic.delivery_trip.total_kilometer_distance")}
                disabled
                error={Boolean(errors.total_kilometer_distance)}
                helperText={errors.total_kilometer_distance?.message}
                required
                allowNegative
                viewMode={disabled}
                decimalScale={0}
              />
            </Grid>
          </>
        )}
      </Grid>
      {isShow && (
        <>
          <Typography fontWeight={600} my={1}>
            {t("logistic.delivery_trip.temperature")}
          </Typography>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledNumberTextField
                fullWidth
                control={control}
                name="temperature_start_hours"
                label={t("logistic.delivery_trip.temperature_start_hours")}
                disabled={disabled || isFinished}
                error={Boolean(errors.temperature_start_hours)}
                helperText={errors.temperature_start_hours?.message}
                onChange={(value) => {
                  const temperature_end_hours = getValues(
                    "temperature_end_hours"
                  );
                  if (temperature_end_hours) {
                    const total = temperature_end_hours - (value || 0);
                    setValue("total_temperature_hours", total);
                  } else {
                    setValue("total_temperature_hours", 0);
                  }
                }}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledNumberTextField
                fullWidth
                control={control}
                name="temperature_end_hours"
                label={t("logistic.delivery_trip.temperature_end_hours")}
                disabled={disabled || isFinished}
                error={Boolean(errors.temperature_end_hours)}
                helperText={errors.temperature_end_hours?.message}
                onChange={(value) => {
                  const temperature_start_hours = getValues(
                    "temperature_start_hours"
                  );
                  if (value) {
                    const total = (value || 0) - (temperature_start_hours || 0);
                    setValue("total_temperature_hours", total);
                  } else {
                    setValue("total_temperature_hours", 0);
                  }
                }}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledNumberTextField
                fullWidth
                control={control}
                name="total_temperature_hours"
                label={t("logistic.delivery_trip.total_temperature_hours")}
                disabled
                error={Boolean(errors.total_temperature_hours)}
                helperText={errors.total_temperature_hours?.message}
                allowNegative
                viewMode={disabled}
              />
            </Grid>
          </Grid>
        </>
      )}
    </CustomizedBox>
  );
};

export default DateDistanceInfo;
