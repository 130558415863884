import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { ISelectOption } from "types/global";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { numberFormatter } from "utils/Formatter/Global";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import ControlledSelect from "components/Controller/ControlledSelect";
import { useDisable } from "hooks/use-disable";
import { useBillingNoteSummary } from "hooks/Sales/BillingNote/use-billing-note-summary";
import { AdditionalDiscountType } from "generated/sales";

interface ISummary {
  name?: string;
  label: string;
  amount: number;
}

const additionalDiscountTypeOptions: ISelectOption[] = [
  {
    label: "บาท",
    value: AdditionalDiscountType.Baht,
  },
  {
    label: "%",
    value: AdditionalDiscountType.Percent,
  },
];

interface Props {
  documentType?: string;
}

const BillingNoteSummary = ({ documentType }: Props) => {
  const { control, resetField, setValue } = useFormContext();
  const [disabled] = useDisable();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const income_post_discount_amount = useWatch({
    control,
    name: "income_post_discount_amount",
  });

  const additionDiscountType = useWatch({
    control,
    name: "additional_discount_type",
  });

  const { t } = useTranslation();

  const summary = useBillingNoteSummary(control, setValue, "item_list");

  const defaultsSummaryList: ISummary[] = useMemo(
    () => [
      {
        name: "income_net_amount",
        label: `${t("price.net_amount")}`,
        amount: summary.income_net_amount,
      },
    ],
    [summary.income_net_amount, t]
  );

  const [summaryList, setSummaryList] =
    useState<ISummary[]>(defaultsSummaryList);

  useEffect(() => {
    setSummaryList(defaultsSummaryList);
  }, [
    additionDiscountType,
    defaultsSummaryList,
    income_post_discount_amount,
    summary.income_net_amount,
    summary.income_post_discount_amount,
    summary.income_vat_0_percent_amount,
    summary.income_vat_7_percent_amount,
    summary.income_vat_amount,
    summary.income_vat_exempted_amount,
    t,
  ]);

  const additionalDiscountType: string = useWatch({
    control,
    name: "additional_discount_type",
  });

  return (
    <>
      {summaryList.map((item) => (
        <Box key={item.label} sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1.5,
              gap: 3,
              py: item.name === "income_net_amount" ? 1 : 0,
            }}
            bgcolor={
              item.name === "income_net_amount" ? "secondary.main" : undefined
            }
            borderRadius={1}
          >
            {!["additional_discount"].includes(item?.name || "") || disabled ? (
              <>
                <Typography
                  color={"primary.main"}
                  textAlign={isMobile ? "left" : "right"}
                  flex={2}
                  fontWeight={
                    ["income_net_amount"].includes(item.name || "") ? 600 : 400
                  }
                >
                  {item.label}
                </Typography>
                <Typography
                  color={"primary.main"}
                  fontWeight={
                    ["income_net_amount"].includes(item.name || "") ? 600 : 400
                  }
                  sx={{ minWidth: 150, textAlign: "right", mr: 2, flex: 1 }}
                >
                  {numberFormatter(item.amount)}
                </Typography>
                {additionalDiscountType === "baht" ? (
                  <Typography
                    color={"primary.main"}
                    sx={{ ml: 2.5, mr: 4.7 }}
                    fontWeight={
                      ["income_net_amount"].includes(item.name || "")
                        ? 600
                        : 400
                    }
                  >
                    บาท
                  </Typography>
                ) : item.name === "additional_discount" ? (
                  <Typography color={"primary.main"} sx={{ ml: 2.5, mr: 6 }}>
                    %
                  </Typography>
                ) : (
                  <Typography
                    color={"primary.main"}
                    sx={{ ml: 2.5, mr: 4.7 }}
                    fontWeight={
                      ["income_net_amount"].includes(item.name || "")
                        ? 600
                        : 400
                    }
                  >
                    บาท
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography
                  color={"primary.main"}
                  mr={2.5}
                  textAlign={isMobile ? "left" : "right"}
                  flex={2}
                >
                  {item.label}
                </Typography>
                <Box
                  minWidth={150}
                  flex={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <ControlledNumberTextField
                    name={item?.name || ""}
                    control={control}
                    textAlign="right"
                    disabled={documentType === "sales_return"}
                  />
                </Box>
                {item.name === "additional_discount" ? (
                  <Box
                    sx={{
                      width: 75,
                    }}
                  >
                    <ControlledSelect
                      control={control}
                      name="additional_discount_type"
                      options={additionalDiscountTypeOptions}
                      onChange={() => {
                        if (resetField)
                          resetField("additional_discount", {
                            defaultValue: 0,
                          });
                      }}
                      disabled={documentType === "sales_return"}
                    />
                  </Box>
                ) : (
                  <Typography color={"primary.main"} sx={{ ml: 2.5, mr: 4.7 }}>
                    บาท
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default BillingNoteSummary;
