import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { formatDate } from "utils/Date";
import {
  numberFormatter,
  statusValueFormatter,
} from "utils/Formatter/Global";
import { dateFilterParams } from "utils/AgGridFilter";

import CustomizedStatus from "components/Custom/CustomizedStatus";
import CustomizedAvatar from "components/Custom/CustomizedAvatar";

import { useSalesAgGrid } from "hooks/Sales/use-sales-aggrid";

export const useQuotationColumnDefs = () => {
  const { t } = useTranslation();
  const [columnDef, setColumnDef] = useState<ColDef[]>();

  const { salesUserFilterParams } = useSalesAgGrid("quotation");

  useEffect(() => {
    setColumnDef([
      {
        field: "unique_id",
        headerName: t("sales.unique_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "issue_date",
        headerName: t("date.issue_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
        sort: "desc",
      },
      {
        field: "due_date",
        headerName: t("date.to_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "customer_name",
        headerName: "ชื่อลูกค้า",
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "net_amount",
        headerName: t("price.net_amount"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams<any, any>) => {
          if (params.value) {
            return numberFormatter(params.value);
          } else return "";
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "created_by",
        headerName: t("sentence.created_by"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "related_user_object_list",
        headerName: t("sales.employee_list"),
        filter: "agSetColumnFilter",
        filterParams: salesUserFilterParams,
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value && params.value.length > 0) {
            return <CustomizedAvatar avatars={params.value} />;
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "aggrid_status",
        headerName: t("status.index"),
        filter: "agSetColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
        minWidth: 200,
        flex: 1,
        filterParams: {
          values: [
            "draft",
            "wait_accept",
            "accepted",
            "finished",
            "not_accepted",
            "cancelled",
          ],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return columnDef;
};
