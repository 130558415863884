import { Grid, Typography } from "@mui/material";

export const DailyWorkSheetFooterInfo = () => {
  const fontSize = 10.66;
  return (
    <Grid container height={110}>
      <Grid
        item
        xs={12}
        sx={{
          border: "0.5px solid #BDBDBD",
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <Grid container height={"100%"}>
          <Grid
            item
            xs={3}
            sx={{
              borderRight: "0.5px solid #BDBDBD",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                height={26}
                padding={"4px 8px 4px 8px"}
                sx={{
                  borderBottom: "0.5px solid #BDBDBD",
                  backgroundColor: "#ECF6FD",
                }}
              >
                <Typography fontSize={fontSize} fontWeight={600}>
                  รายการค่าใช้จ่าย
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                height={21}
                margin={0}
                sx={{ borderBottom: "0.5px solid #BDBDBD" }}
              />
              <Grid
                item
                xs={12}
                height={21}
                sx={{ borderBottom: "0.5px solid #BDBDBD" }}
              />
              <Grid
                item
                xs={12}
                height={21}
                sx={{ borderBottom: "0.5px solid #BDBDBD" }}
              />
            </Grid>
          </Grid>
          <Grid item xs={3} sx={{ borderRight: "0.5px solid #BDBDBD" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                height={26}
                padding={"4px 8px 4px 8px"}
                sx={{
                  borderBottom: "0.5px solid #BDBDBD",
                  backgroundColor: "#ECF6FD",
                }}
              >
                <Typography fontSize={fontSize} fontWeight={600}>
                  ราคา
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                height={21}
                margin={0}
                sx={{ borderBottom: "0.5px solid #BDBDBD" }}
              />
              <Grid
                item
                xs={12}
                height={21}
                sx={{ borderBottom: "0.5px solid #BDBDBD" }}
              />
              <Grid
                item
                xs={12}
                height={21}
                sx={{ borderBottom: "0.5px solid #BDBDBD" }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid
                item
                xs={12}
                height={26}
                padding={"4px 8px 4px 8px"}
                sx={{
                  borderBottom: "0.5px solid #BDBDBD",
                  backgroundColor: "#ECF6FD",
                }}
              >
                <Typography fontSize={fontSize} fontWeight={600}>
                  หมายเหตุ
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
