import { InputAdornment, Typography } from "@mui/material";
import { IDefaultForm, IOptionsCreatable } from "../../../../types/global";
import { Controller, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { numberFormatter } from "../../../../utils/Formatter/Global";
import {
  AdditionalDiscountType,
  PriceVatType,
} from "../../../../generated/purchase";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";

interface Props {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  setValue: IDefaultForm["setValue"];
  index: number;
  disabled: IDefaultForm["disabled"];
  viewMode: boolean;
  documentType: string;
  field: any;
}

const withholdingTaxTypeOption: IOptionsCreatable[] = [
  { id: 1, value: "ยังไม่ระบุ", label: "ยังไม่ระบุ" },
  { id: 2, value: "ไม่มี", label: "ไม่มี" },
  { id: 3, value: "0.75", label: "0.75" },
  { id: 4, value: "1", label: "1" },
  { id: 5, value: "1.5", label: "1.5" },
  { id: 6, value: "2", label: "2" },
  { id: 7, value: "3", label: "3" },
  { id: 8, value: "5", label: "5" },
  { id: 9, value: "10", label: "10" },
  { id: 10, value: "15", label: "15" },
];

const WithholdingTaxCell = ({
  control,
  index,
  errors,
  setValue,
  disabled,
  viewMode,
  field,
  documentType,
}: Props) => {
  const additional_discount = useWatch({
    control,
    name: `additional_discount`,
  });

  const additional_discount_type = useWatch({
    control,
    name: "additional_discount_type",
  });

  const pre_vat_amount = useWatch({
    control,
    name: "pre_vat_amount",
  });

  const actualAdditionalDiscount =
    additional_discount_type === AdditionalDiscountType.Percent
      ? pre_vat_amount * (additional_discount / 100)
      : additional_discount;

  const vat_type = useWatch({
    control,
    name: `item_list.${index}.vat_percentage`,
  });

  const item_pre_vat_amount = useWatch({
    control,
    name: `item_list.${index}.pre_vat_amount`,
  });

  const withholding_tax_type = useWatch({
    control,
    name: `item_list.${index}.withholding_tax_type`,
  });

  const withholding_tax_value = useWatch({
    control,
    name: `item_list.${index}.withholding_tax_value`,
  });

  const item_additional_discount =
    actualAdditionalDiscount * (item_pre_vat_amount / pre_vat_amount) || 0;

  const price_vat_type = useWatch({
    control,
    name: "price_vat_type",
  });

  useEffect(() => {
    if (price_vat_type === PriceVatType.ExcludedVat) {
      setValue(
        `item_list.${index}.withholding_tax_value`,
        ((item_pre_vat_amount - item_additional_discount) *
          parseFloat(
            withholding_tax_type === "ยังไม่ระบุ" ||
              withholding_tax_type === "ไม่มี" ||
              withholding_tax_type === "" ||
              withholding_tax_type === undefined
              ? 0
              : withholding_tax_type
          )) /
          100
      );
    } else {
      if (vat_type === "7") {
        setValue(
          `item_list.${index}.withholding_tax_value`,
          (((item_pre_vat_amount - item_additional_discount) / 1.07) *
            parseFloat(
              withholding_tax_type === "ยังไม่ระบุ" ||
                withholding_tax_type === "ไม่มี" ||
                withholding_tax_type === "" ||
                withholding_tax_type === undefined
                ? 0
                : withholding_tax_type
            )) /
            100
        );
      } else {
        setValue(
          `item_list.${index}.withholding_tax_value`,
          ((item_pre_vat_amount - item_additional_discount) *
            parseFloat(
              withholding_tax_type === "ยังไม่ระบุ" ||
                withholding_tax_type === "ไม่มี" ||
                withholding_tax_type === "" ||
                withholding_tax_type === undefined
                ? 0
                : withholding_tax_type
            )) /
            100
        );
      }
    }
  }, [
    index,
    item_additional_discount,
    item_pre_vat_amount,
    price_vat_type,
    setValue,
    vat_type,
    withholding_tax_type,
  ]);

  const noHoldingTax = ["ไม่มี", "ยังไม่ระบุ", "", null].includes(
    withholding_tax_type
  );

  return (
    <>
      {viewMode ? (
        <>
          <Typography fontSize={14}>
            {field.withholding_tax_type} {noHoldingTax ? undefined : "%"}
          </Typography>
          {noHoldingTax ? undefined : (
            <Typography variant="caption">
              {numberFormatter(withholding_tax_value)} บาท
            </Typography>
          )}
        </>
      ) : (
        <Controller
          control={control}
          name={`item_list.${index}.withholding_tax_type`}
          render={({ field }) => (
            <CustomizedComboBox
              {...field}
              freeSolo
              disableClearable
              options={withholdingTaxTypeOption}
              onChange={(_, newValue) => {
                if (newValue) {
                  field.onChange(newValue.value || "");
                }
              }}
              onInputChange={(_, newValue) => {
                if (newValue) {
                  field.onChange(newValue);
                }
              }}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              error={Boolean(
                errors?.item_list &&
                  errors?.item_list[index] &&
                  errors?.item_list[index]?.withholding_tax_type
              )}
              helperText={
                noHoldingTax
                  ? undefined
                  : numberFormatter(withholding_tax_value) + " บาท"
              }
              required
              disabled={disabled}
            />
          )}
        />
        // <ControlledServerSideCreatable
        //   sx={{ mt: 3 }}
        //   control={control}
        //   name={`item_list.${index}.withholding_tax_type`}
        //   dataName="withholding_tax_type"
        //   documentType={documentType}
        //   defaultOptions={withholdingTaxTypeOption}
        //   error={Boolean(
        //     errors?.item_list &&
        //       errors?.item_list[index] &&
        //       errors?.item_list[index]?.withholding_tax_type
        //   )}
        //   helperText={numberFormatter(withholding_tax_value) + " บาท"}
        //   setValue={setValue}
        //   disabled={documentType === "sales_return"}
        //   endAdornment={<InputAdornment position="end">%</InputAdornment>}
        // />
      )}
    </>
  );
};

export default WithholdingTaxCell;
