import { Typography } from "@mui/material";
import ControlledSelect from "components/Controller/ControlledSelect";
import { useDisable } from "hooks/use-disable";
import { FieldArrayWithId, useFormContext } from "react-hook-form";
import { ISelectOption } from "types/global";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0",
  },
  {
    label: "7 %",
    value: "7",
  },
];

interface Props {
  field: FieldArrayWithId<IDeliveryTrip, "income_list", "id">;
  index: number;
  disabledStatus: boolean;
}

const VatPercentageCell = ({ field, index, disabledStatus }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IDeliveryTrip>();
  const [disabled] = useDisable();
  return (
    <>
      {!disabled && !disabledStatus ? (
        <ControlledSelect
          control={control}
          name={`income_list.${index}.vat_percentage`}
          options={vatOptions}
          error={Boolean(
            errors?.income_list && errors.income_list[index]?.vat_percentage
          )}
        />
      ) : (
        <Typography>
          {field.vat_percentage}
          {field.vat_percentage !== "ไม่มี" ? " %" : ""}
        </Typography>
      )}
    </>
  );
};

export default VatPercentageCell;
