import { Grid } from "@mui/material";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import ControlledSelect from "components/Controller/ControlledSelect";
import { useFormContext } from "react-hook-form";
import { useDisable } from "hooks/use-disable";
import { useTranslation } from "react-i18next";
import LabelInput from "components/UI/LabelInput";

const ItemSales = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const salesVatTypeOptions = [
    { id: 0, value: "NO_VAT", label: "ไม่มี" },
    { id: 1, value: "VAT_0", label: "0 %" },
    { id: 2, value: "VAT_7", label: "7 %" },
  ];

  const renderVatType = (vatType: string) => {
    return salesVatTypeOptions.find((item) => item.value === vatType)?.label;
  };

  return (
    <>
      <Grid container spacing={1.5} mt={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledNumberTextField
            name="sale_price"
            label={t("inventory.item.sales.sale_price")}
            control={control}
            viewMode={disabled}
            error={Boolean(errors.sale_price)}
            helperText={errors.sale_price?.message?.toString()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledNumberTextField
            name="special_price"
            label={t("inventory.item.sales.special_price")}
            control={control}
            viewMode={disabled}
            error={Boolean(errors.special_price)}
            helperText={errors.special_price?.message?.toString()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {!disabled && (
            <ControlledSelect
              name="sale_vat_type"
              label={t("inventory.item.sales.sale_vat_type")}
              control={control}
              disabled={disabled}
              options={salesVatTypeOptions}
              error={Boolean(errors.sale_vat_type)}
              helperText={errors.sale_vat_type?.message?.toString()}
            />
          )}
          {disabled && (
            <LabelInput
              label={t("inventory.item.sales.sale_vat_type")}
              value={renderVatType(getValues("sale_vat_type"))}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ItemSales;
