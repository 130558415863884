import * as Yup from "yup";
import dayjs from "dayjs";
import { IGoodsAdjust } from "types/Inventory/goodsAdjust";
import { Traceability } from "generated/general";
import { stringMaxInput } from "utils/Validate";

export const goodsAdjustSchema: IGoodsAdjust = {
  id: undefined,
  unique_id: "",
  source_warehouse_id: undefined,
  source_warehouse: undefined,
  created_date: dayjs(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  created_by: null,
  flag_status: [],
  main_status: "",
};

const goodsAdjustSerialValidation = Yup.object().shape({
  stock_qty: Yup.number().nullable(),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .notOneOf([0], "กรุณาระบุจำนวนมากกว่า 0")
    .required("กรุณาระบุจำนวนมากกว่า 0")
    .when("stock_qty", (stock_qty: any, schema) => {
      return schema.test({
        test: (qty) => {
          return (qty ?? 0) + Number(stock_qty[0]) > 0;
        },
        message: "จำนวนคงคลังไม่ต่ำกว่า 0",
      });
    }),
});

const goodsAdjustTraceValidation = Yup.object().shape({
  stock_qty: Yup.number().nullable(),
  qty: Yup.number().when("traceability", (traceability: any) =>
    traceability && traceability[0] === Traceability.Normal
      ? Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .notOneOf([0], "กรุณาระบุจำนวนมากกว่า 0")
          .required("กรุณาระบุจำนวนมากกว่า 0")
          .when("stock_qty", (stock_qty: any, schema) => {
            return schema.test({
              test: (qty) => {
                return (qty ?? 0) + Number(stock_qty[0]) > 0;
              },
              message: "จำนวนคงคลังไม่ต่ำกว่า 0",
            });
          })
      : Yup.number().nullable()
  ),
  serial_list: Yup.array().when("traceability", (traceability: any) =>
    traceability && traceability[0] === Traceability.Serial
      ? Yup.array()
          .of(goodsAdjustSerialValidation)
          .min(1, "กรุณาสแกนรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsAdjustValidation = Yup.object().shape({
  unique_id: stringMaxInput(15, undefined, true, "กรุณาระบุเลขที่เอกสาร"),
  posted_date: Yup.date().required("กรุณาระบุ"),
  source_warehouse_id: Yup.string().required("กรุณาเลือกคลัง"),
  trace_entry_list: Yup.array()
    .of(goodsAdjustTraceValidation)
    .min(1, "กรุณาเพิ่มสินค้า"),
  remark: stringMaxInput(200, undefined, false),
});
