import * as Yup from "yup";
import dayjs from "dayjs";
import { IBillingNote } from "types/Sales/billing-note";
import { stringMaxInput } from "utils/Validate";

export const billingNoteSchema: IBillingNote = {
  unique_id: "",
  created_date: dayjs(),
  created_by: null,
  issue_date: dayjs(),
  due_date: dayjs(),
  credit_day: 0,
  related_user_list: [],
  customer_unique_id: "",
  customer_details: {
    unique_id_name: "",
    customer_name: "",
    phone: "",
    email: "",
    fax: "",
    identity_no: "",
    billing_address: {
      name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  pre_discount_amount: 0,
  post_discount_amount: 0,
  vat_exempted_amount: 0,
  vat_0_percent_amount: 0,
  vat_7_percent_amount: 0,
  vat_amount: 0,
  net_amount: 0,
  withholding_tax_amount: 0,
  total_amount: 0,
  remark: "",
  main_status: "",
  sub_status: "",
  flag_status: [],
  aggrid_status: "",
  accepted_remark: "",
  attachment_list: [],
  item_unique_id_list: [],
  item_list: [],
};

const yupNumber = Yup.number().transform((value) =>
  isNaN(value) || value === null || value === undefined ? 0 : value
);

const itemListValidation = Yup.object().shape({
  income_post_discount_amount: yupNumber
    .positive("กรุณาระบุ")
    .required("กรุณาระบุ")
    .test(
      "note-exceed-dt-amount",
      "กรุณากรอกไม่เกินมูลค่าในใบงานจัดส่ง",
      function (value) {
        const { dt_income_post_discount_amount } = this.parent;
        if (value && dt_income_post_discount_amount) {
          return value <= dt_income_post_discount_amount;
        }
        return true;
      }
    ),
  income_withholding_tax_amount: yupNumber
    .min(0, "กรุณาระบุ")
    .test(
      "note-exceed-dt-withholding-tax-amount",
      "กรุณากรอกไม่เกินมูลค่าในใบงานจัดส่ง",
      function (value) {
        const { dt_income_withholding_tax_amount } = this.parent;
        if (value && value > 0 && !dt_income_withholding_tax_amount)
          return false;
        if (value && dt_income_withholding_tax_amount) {
          return value <= dt_income_withholding_tax_amount;
        }
        return true;
      }
    ),
  dt_income_discount_amount: yupNumber.positive(),
  description: stringMaxInput(200, undefined, false),
  source_location: stringMaxInput(200, undefined, true, "กรุณาระบุ"),
  destination_location: stringMaxInput(200, undefined, true, "กรุณาระบุ"),
});

export const billingNoteValidation = Yup.object().shape({
  unique_id: stringMaxInput(15, undefined, true, "กรุณาระบุ"),
  customer_unique_id: Yup.string().required("กรุณาระบุ"),
  issue_date: Yup.date().required("กรุณาระบุ"),
  due_date: Yup.date().required("กรุณาระบุ"),
  item_list: Yup.array()
    .of(itemListValidation)
    .min(1, "กรุณาเพิ่มใบงานจัดส่งอย่างน้อย 1 ใบ"),
});
