import { Box, Grid, Typography } from "@mui/material";

type Props = {
  data: any;
};

export const DailyWorkSheetHighlightHeader = ({ data }: Props) => {
  const fontSize = 10.66;
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        fontSize={26.66}
        fontWeight={600}
        color={"primary.main"}
        align="right"
      >
        APPA Daily Worksheet
      </Typography>
      <Box
        sx={{
          backgroundColor: "#ECF6FD",
          borderRadius: "4px",
          padding: "4px 8px 4px 8px",
        }}
      >
        <Grid container spacing={0.3}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography fontSize={fontSize} fontWeight={600}>
                  เลขที่เอกสาร
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography fontSize={fontSize}>
                  {data.unique_id ? data.unique_id : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography fontSize={fontSize} fontWeight={600}>
                  Job Number
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography fontSize={fontSize}>
                  {data.job_no ? data.job_no : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
