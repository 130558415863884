import { Box, Typography } from "@mui/material";

const EmptyChart = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100% - 40px)",
      }}
    >
      <img src="/static/emptyBox.svg" alt="empty-box" />
      <Typography color={"#BDBDBD"}>ไม่มีข้อมูล</Typography>
    </Box>
  );
};

export default EmptyChart;
