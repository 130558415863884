import dayjs from "dayjs";

// import { ITEM_SKU_DETAIL_AGGRID } from "../../../services/AgGrid/InventoryAggrid";
import { v4 as uuidv4 } from "uuid";
import { IPurchaseItemList } from "../../../types/Purchase";
import { IPurchaseReturn } from "../../../types/Purchase/purchaseReturn";
import { purchaseReturnSchema } from "components/Form/Purchase/Return/schema";
import { IUser } from "types/Auth/user";
import { IGoodsIssue } from "types/Inventory/goodsIssue";
import { ITraceEntry } from "types/Inventory";
import { PurchaseDocumentType, Traceability } from "generated/purchase";
import { TraceEntryType } from "generated/wms";

export const purchaseReturnCreatePayloadFormatter = (
  data: IPurchaseReturn,
  status: string
) => {
  const {
    item_list,
    tag_list,
    created_date,
    supplier_details,
    credit_day,
    ...otherData
  } = data;

  const formatItemList = item_list.map(({ item_sku_qty, ...otherItem }) => ({
    ...otherItem,
    reference_unique_id: data.unique_id,
  }));

  const { unique_id_name, ...new_supplier_details } = supplier_details;

  const formatPayload = {
    ...otherData,
    supplier_details: new_supplier_details,
    item_list: formatItemList,
    main_status: status,
    sub_status: status,
    credit_day: credit_day || null,
  };
  return formatPayload;
};

export const purchaseReturnUpdatePayloadFormatter = async (
  data: IPurchaseReturn,
  status: string
) => {
  const {
    id,
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    last_updated_date,
    item_list,
    tag_list,
    created_by,
    created_date,
    supplier_details,
    credit_day,
    ...otherData
  } = data;

  const formatItemList = item_list.map(({ item_sku_qty, ...otherItem }) => ({
    ...otherItem,
    reference_unique_id: unique_id,
  }));

  const { unique_id_name, ...new_supplier_details } = supplier_details;

  const formatPayload = {
    ...otherData,
    supplier_details: new_supplier_details,
    item_list: formatItemList,
    main_status: status,
    sub_status: status,
    flag_status: flag_status,
    credit_day: credit_day || null,
  };
  return formatPayload;
};

export const purchaseReturnQueryFormatter = async (
  data: any
  // data: PurchaseReturnFindUniqueQuery["PurchaseReturnFindUnique"]
) => {
  // const graphQLClientWithHeaderItem: GraphQLClient =
  //   createGraphQLClientWithMiddleware("wms");

  // const allItemListUniqueId =
  //   data.item_list && data.item_list.length > 0
  //     ? data.item_list.map((item) => item?.item_sku_name)
  //     : [];

  // const { itemSkuDetailsFindManyAggrid } =
  //   await graphQLClientWithHeaderItem.request(ITEM_SKU_DETAIL_AGGRID, {
  //     aggridInput: {
  //       startRow: 0,
  //       endRow: 9999,
  //       filterModel: {
  //         sku_name: {
  //           filterType: "set",
  //           values: allItemListUniqueId,
  //         },
  //       },
  //     },
  //   });

  // const { results: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: IPurchaseItemList[] = [];

  if (data.item_list && data.item_list.length > 0) {
    data.item_list.forEach((item: IPurchaseItemList) => {
      // const foundItemIndex = itemSkuDetails.findIndex(
      //   (realItem: any) => realItem.sku_name === item?.item_sku_name
      // );

      const itemType = item as IPurchaseItemList;

      formatItemList.push({
        ...itemType,
        // uom_group: itemSkuDetails[foundItemIndex]?.item.uom_group,
        // item_sku_qty: itemSkuDetails[foundItemIndex]?.item_sku_qty,
      });
    });
  }

  const formatPayload = {
    ...data,
    item_list: formatItemList,
    created_date: data.created_date ? dayjs(data.created_date) : undefined,
    issue_date: data.issue_date ? dayjs(data.issue_date) : undefined,
    delivery_date: data.delivery_date ? dayjs(data.delivery_date) : undefined,
    supplier_details: data.supplier_unique_id
      ? {
          ...data.supplier_details,
          unique_id_name: `${data.supplier_unique_id} - ${data.supplier_details.name}`,
        }
      : {},
  } as IPurchaseReturn;

  return formatPayload;
};

export const copyPurchaseReturnFormatter = (
  data: IPurchaseReturn,
  currentUser: IUser | null
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      delivery_date,
      created_by,
      reference_no,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      related_user_list,
      ...otherData
    } = data;

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(
            ({
              id,
              unique_id,
              po_qty,
              rs_qty,
              wait_receive_qty,
              ...otherItemList
            }) => ({
              ...otherItemList,
              unique_id: uuidv4(),
            })
          )
        : [];

    const formatRelatedUserList =
      // related_user_list.filter((user) => user.user_unique_id !== currentUser?.unique_id)
      created_by?.user_unique_id !== currentUser?.unique_id
        ? [created_by, ...(related_user_list || [])]
        : related_user_list;

    return {
      ...purchaseReturnSchema,
      ...otherData,
      related_user_list: formatRelatedUserList,
      copied_id: id,
      copied_unique_id: unique_id,
      item_list: formatItemList,
    };
  }
};

export const formatPurchaseItemListToGoodsIssueItemList = (
  data: IPurchaseReturn
): ITraceEntry[] => {
  const formatData: ITraceEntry[] = data.item_list.map((item) => ({
    unique_id: uuidv4(),
    reference_unique_id: "",
    type: TraceEntryType.GoodIssue,
    item_unique_id: item.item_unique_id || "",
    item_name: item.item_name || "",
    item_img_url: item.item_img_url || [],
    qty: 0,
    document_item_qty: item.qty,
    posted_qty: item.rs_qty,
    uom_id: item.uom_id || 0,
    uom: item.uom_name,
    remark: "",
    reference_line_item: {
      line_item_unique_id: item.unique_id,
      line_item_document_unique_id: data.unique_id || "",
      line_item_document_type: PurchaseDocumentType.PurchaseReturn,
    },
    traceability: (item.traceability as Traceability) || Traceability.Normal,
    barcode: item.barcode,
    status: item.traceability === "serial" ? undefined : "is_active",
    stock_qty: item.stock_qty,
  }));

  return formatData as ITraceEntry[];
};

export const createGoodsIssueFromPurchaseReturn = (
  data: IPurchaseReturn,
  currentUser: IUser | null
): IGoodsIssue => {
  const formatData = {
    unique_id: "",
    type: "return",
    reference_unique_id: data.unique_id,
    remark: "",
    trace_entry_list: formatPurchaseItemListToGoodsIssueItemList(data),
    created_by: {
      user_unique_id: currentUser?.unique_id,
      email: currentUser?.email,
      first_name: currentUser?.first_name,
      last_name: currentUser?.last_name,
      img_url: currentUser?.img_url,
    },
    main_status: "",
    aggrid_status: "",
    flag_status: [],
  };

  return formatData as IGoodsIssue;
};
