import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Any: { input: any; output: any; }
  AnyAggridInput: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  Json: { input: any; output: any; }
};

/** Type description */
export type ActivityLog = {
  __typename?: 'ActivityLog';
  activity_detail?: Maybe<ActivityLogDetailObject>;
  activity_type?: Maybe<ActivityType>;
  created_by?: Maybe<CreatorObject>;
  created_date?: Maybe<Scalars['DateTime']['output']>;
  document_type?: Maybe<ActivityLogDocumentType>;
  /** field description */
  id: Scalars['Int']['output'];
  reference_id?: Maybe<Scalars['Int']['output']>;
};

export type ActivityLogByRefereceIdInput = {
  document_type: ActivityLogDocumentType;
  reference_id: Scalars['Int']['input'];
};

export type ActivityLogCopyObject = {
  __typename?: 'ActivityLogCopyObject';
  id?: Maybe<Scalars['Int']['output']>;
  unique_id?: Maybe<Scalars['String']['output']>;
};

export type ActivityLogCopyObjectInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type ActivityLogCreateInput = {
  activity_detail: ActivityLogDetailObjectInput;
  activity_type: ActivityType;
  created_by: CreatorInputObject;
  document_type: ActivityLogDocumentType;
  reference_id: Scalars['Int']['input'];
};

export type ActivityLogDetailObject = {
  __typename?: 'ActivityLogDetailObject';
  added_related_employee?: Maybe<ActivityLogRelatedEmployeeObject>;
  attachment_list?: Maybe<Array<Scalars['String']['output']>>;
  copied_from?: Maybe<ActivityLogCopyObject>;
  copied_to?: Maybe<ActivityLogCopyObject>;
  curr_status?: Maybe<Scalars['String']['output']>;
  deleted_related_employee?: Maybe<ActivityLogRelatedEmployeeObject>;
  message?: Maybe<Array<Scalars['String']['output']>>;
  prev_status?: Maybe<Scalars['String']['output']>;
  updated_fields?: Maybe<Scalars['Json']['output']>;
};

export type ActivityLogDetailObjectInput = {
  added_related_employee?: InputMaybe<ActivityLogRelatedEmployeeObjectInput>;
  attachment_list?: InputMaybe<Array<Scalars['String']['input']>>;
  copied_from?: InputMaybe<ActivityLogCopyObjectInput>;
  copied_to?: InputMaybe<ActivityLogCopyObjectInput>;
  curr_status?: InputMaybe<Scalars['String']['input']>;
  deleted_related_employee?: InputMaybe<ActivityLogRelatedEmployeeObjectInput>;
  message?: InputMaybe<Array<Scalars['String']['input']>>;
  prev_status?: InputMaybe<Scalars['String']['input']>;
  secondary_operation?: InputMaybe<ActivityType>;
  updated_fields?: InputMaybe<Scalars['Json']['input']>;
};

export enum ActivityLogDocumentType {
  BillingNote = 'billing_note',
  CashSales = 'cash_sales',
  Contact = 'contact',
  CreditNote = 'credit_note',
  DeliveryOrder = 'delivery_order',
  DeliveryTrip = 'delivery_trip',
  DepositInvoice = 'deposit_invoice',
  GoodsAdjustment = 'goods_adjustment',
  GoodsIssue = 'goods_issue',
  GoodsReceive = 'goods_receive',
  GoodsReturn = 'goods_return',
  GoodsTransfer = 'goods_transfer',
  Item = 'item',
  Maintenance = 'maintenance',
  Promotion = 'promotion',
  PurchaseInvoice = 'purchase_invoice',
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return',
  Quotation = 'quotation',
  Role = 'role',
  SalesInvoice = 'sales_invoice',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return',
  Tire = 'tire',
  User = 'user',
  Vehicle = 'vehicle'
}

export type ActivityLogFindManyAggrid = {
  __typename?: 'ActivityLogFindManyAggrid';
  count: Scalars['Int']['output'];
  results: Array<ActivityLog>;
};

export type ActivityLogRelatedEmployeeObject = {
  __typename?: 'ActivityLogRelatedEmployeeObject';
  first_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  user_unique_id?: Maybe<Scalars['String']['output']>;
};

export type ActivityLogRelatedEmployeeObjectInput = {
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  user_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type ActivityLogUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export enum ActivityType {
  Active = 'active',
  AddRelatedEmployee = 'add_related_employee',
  Approve = 'approve',
  Cancel = 'cancel',
  CancelPendingScan = 'cancel_pending_scan',
  ChangePassword = 'change_password',
  Comment = 'comment',
  Copy = 'copy',
  Create = 'create',
  Delete = 'delete',
  DeleteRelatedEmployee = 'delete_related_employee',
  Document = 'document',
  Edit = 'edit',
  EditPermission = 'edit_permission',
  Image = 'image',
  Importer = 'importer',
  InActive = 'in_active',
  StatusChange = 'status_change'
}

export enum AdditionalDiscountType {
  Baht = 'baht',
  Percent = 'percent'
}

export type AddressInputObject = {
  address_contact_fax?: InputMaybe<Scalars['String']['input']>;
  address_contact_name?: InputMaybe<Scalars['String']['input']>;
  address_contact_phone?: InputMaybe<Scalars['String']['input']>;
  address_type?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  is_same_as_default_address?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  sub_district?: InputMaybe<Scalars['String']['input']>;
};

export type AddressObject = {
  __typename?: 'AddressObject';
  address_contact_fax?: Maybe<Scalars['String']['output']>;
  address_contact_name?: Maybe<Scalars['String']['output']>;
  address_contact_phone?: Maybe<Scalars['String']['output']>;
  address_type?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  is_default?: Maybe<Scalars['Boolean']['output']>;
  is_same_as_default_address?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  sub_district?: Maybe<Scalars['String']['output']>;
};

export enum AggridArrayFilterType {
  Array = 'array'
}

export type AggridBooleanFilterModelInput = {
  filter?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AggridBooleanFilterModelType>;
};

export enum AggridBooleanFilterModelType {
  Equals = 'equals',
  NotEqual = 'notEqual'
}

export type AggridDateFilterModelInput = {
  filter?: InputMaybe<Scalars['String']['input']>;
  filterTo?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<AggridDateFilterType>;
  type?: InputMaybe<AggridDateFilterModelType>;
};

export enum AggridDateFilterModelType {
  Blank = 'blank',
  Empty = 'empty',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotEqual = 'notEqual'
}

export enum AggridDateFilterType {
  Date = 'date'
}

export type AggridFloatFilterModelInput = {
  condition1?: InputMaybe<AggridFloatFilterModelInputCondition>;
  condition2?: InputMaybe<AggridFloatFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['Float']['input']>;
  filterTo?: InputMaybe<Scalars['Float']['input']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridFloatFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['Float']['input']>;
  filterTo?: InputMaybe<Scalars['Float']['input']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridGenericArrayFilterModelInput = {
  filterType?: InputMaybe<AggridArrayFilterType>;
  type?: InputMaybe<AggridGenericArrayFilterModelType>;
  values?: InputMaybe<Scalars['Any']['input']>;
};

export enum AggridGenericArrayFilterModelType {
  Equals = 'equals',
  Has = 'has',
  HasEvery = 'hasEvery',
  HasSome = 'hasSome',
  IsEmpty = 'isEmpty'
}

export enum AggridISimpleFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Empty = 'empty',
  EndsWith = 'endsWith',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual',
  StartsWith = 'startsWith'
}

export type AggridIntFilterModelInput = {
  condition1?: InputMaybe<AggridIntFilterModelInputCondition>;
  condition2?: InputMaybe<AggridIntFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['Int']['input']>;
  filterTo?: InputMaybe<Scalars['Int']['input']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export type AggridIntFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['Int']['input']>;
  filterTo?: InputMaybe<Scalars['Int']['input']>;
  filterType?: InputMaybe<AggridNumberFilterType>;
  type?: InputMaybe<AggridNumberFilterModelType>;
};

export enum AggridNumberFilterModelType {
  Blank = 'blank',
  Empty = 'empty',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  InRange = 'inRange',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotBlank = 'notBlank',
  NotEqual = 'notEqual'
}

export enum AggridNumberFilterType {
  Number = 'number'
}

export enum AggridObjectArrayFilterModelType {
  Every = 'every',
  None = 'none',
  Some = 'some'
}

export enum AggridObjectArrayFilterType {
  ObjectArray = 'objectArray'
}

export enum AggridOperatorFilter {
  And = 'AND',
  Or = 'OR'
}

export type AggridSetSingleFilterModelInput = {
  condition1?: InputMaybe<AggridSetSingleFilterModelInputCondition>;
  condition2?: InputMaybe<AggridSetSingleFilterModelInputCondition>;
  filterType?: InputMaybe<Scalars['String']['input']>;
  operator?: InputMaybe<AggridOperatorFilter>;
  values?: InputMaybe<Array<InputMaybe<Scalars['Any']['input']>>>;
};

export type AggridSetSingleFilterModelInputCondition = {
  filterType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AggridSetSingleFilterModelType>;
  values?: InputMaybe<Array<InputMaybe<Scalars['Any']['input']>>>;
};

export enum AggridSetSingleFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Equals = 'equals',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual'
}

export type AggridSortModelItem = {
  colId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortOrder>;
};

export type AggridSortModelItemInput = {
  colId: Scalars['String']['input'];
  sort: SortOrder;
};

export type AggridStringFilterModelInput = {
  condition1?: InputMaybe<AggridStringFilterModelInputCondition>;
  condition2?: InputMaybe<AggridStringFilterModelInputCondition>;
  filter?: InputMaybe<Scalars['String']['input']>;
  filterTo?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<AggridTextFilterType>;
  mode?: InputMaybe<AggridStringModeOption>;
  operator?: InputMaybe<AggridOperatorFilter>;
  type?: InputMaybe<AggridStringFilterModelType>;
};

export type AggridStringFilterModelInputCondition = {
  filter?: InputMaybe<Scalars['String']['input']>;
  filterTo?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<AggridTextFilterType>;
  mode?: InputMaybe<AggridStringModeOption>;
  type?: InputMaybe<AggridStringFilterModelType>;
};

export enum AggridStringFilterModelType {
  Blank = 'blank',
  Contains = 'contains',
  Empty = 'empty',
  EndsWith = 'endsWith',
  Equals = 'equals',
  NotBlank = 'notBlank',
  NotContains = 'notContains',
  NotEqual = 'notEqual',
  StartsWith = 'startsWith'
}

export enum AggridStringModeOption {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type AggridTemplateInput = {
  endRow?: InputMaybe<Scalars['Int']['input']>;
  filterModel?: InputMaybe<Scalars['Any']['input']>;
  sortModel?: InputMaybe<Array<AggridSortModelItemInput>>;
  startRow?: InputMaybe<Scalars['Int']['input']>;
};

export enum AggridTextFilterType {
  Text = 'text'
}

export type AttachmentInputObject = {
  attachment_name?: InputMaybe<Scalars['String']['input']>;
  uploaded_by?: InputMaybe<CreatorInputObject>;
  uploaded_date?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AttachmentObject = {
  __typename?: 'AttachmentObject';
  attachment_name?: Maybe<Scalars['String']['output']>;
  uploaded_by?: Maybe<CreatorObject>;
  uploaded_date?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type BillingNotesStats = {
  __typename?: 'BillingNotesStats';
  cancelled?: Maybe<Scalars['Int']['output']>;
  draft?: Maybe<Scalars['Int']['output']>;
  fully_payment?: Maybe<Scalars['Int']['output']>;
  wait_payment?: Maybe<Scalars['Int']['output']>;
};

export type BinLocation = {
  __typename?: 'BinLocation';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  warehouse_level_3: WarehouseLevel3;
};

export type BinLocationCreateInput = {
  name: Scalars['String']['input'];
  warehouse_level_3_id: Scalars['Int']['input'];
};

export type BinLocationUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BinLocationUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  warehouse_level_3_id?: InputMaybe<Scalars['Int']['input']>;
};

export type Company = {
  __typename?: 'Company';
  address_list?: Maybe<Array<AddressObject>>;
  contact_channel_list?: Maybe<Array<ContactChannelObject>>;
  id: Scalars['Int']['output'];
  identity_no?: Maybe<Scalars['String']['output']>;
  img_url: Array<Scalars['String']['output']>;
  is_registered_vat?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  register_date?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
  vat_registration_date?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyCreateInput = {
  address_list: Array<AddressInputObject>;
  contact_channel_list: Array<ContactChannelInputObject>;
  identity_no?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<Scalars['String']['input']>>;
  is_registered_vat?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  register_date?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unique_id: Scalars['String']['input'];
  vat_registration_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CompanyUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyUpdateInput = {
  address_list?: InputMaybe<Array<AddressInputObject>>;
  contact_channel_list?: InputMaybe<Array<ContactChannelInputObject>>;
  identity_no?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<Scalars['String']['input']>>;
  is_registered_vat?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  register_date?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
  vat_registration_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContactChannelInputObject = {
  contact_channel_info?: InputMaybe<Scalars['String']['input']>;
  contact_channel_type?: InputMaybe<Scalars['String']['input']>;
};

export type ContactChannelObject = {
  __typename?: 'ContactChannelObject';
  contact_channel_info?: Maybe<Scalars['String']['output']>;
  contact_channel_type?: Maybe<Scalars['String']['output']>;
};

export type CreatableField = {
  __typename?: 'CreatableField';
  creatable_fields_name: Scalars['String']['output'];
  document_type: CreatableFieldDocumentType;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CreatableFieldCreateInput = {
  creatable_fields_name: Scalars['String']['input'];
  document_type: CreatableFieldDocumentType;
  name: Scalars['String']['input'];
};

export enum CreatableFieldDocumentType {
  Batch = 'batch',
  Contact = 'contact',
  CreditNote = 'credit_note',
  Customer = 'customer',
  DeliveryOrder = 'delivery_order',
  DeliveryTrip = 'delivery_trip',
  DepositInvoice = 'deposit_invoice',
  Employee = 'employee',
  Event = 'event',
  GoodsAdjust = 'goods_adjust',
  GoodsIssue = 'goods_issue',
  GoodsReceive = 'goods_receive',
  GoodsReturn = 'goods_return',
  GoodsTransfer = 'goods_transfer',
  Item = 'item',
  Maintenance = 'maintenance',
  PaymentChannel = 'payment_channel',
  PaymentMade = 'payment_made',
  PaymentReceive = 'payment_receive',
  Project = 'project',
  PurchaseInvoice = 'purchase_invoice',
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return',
  Quotation = 'quotation',
  Role = 'role',
  SalesInvoice = 'sales_invoice',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return',
  Serial = 'serial',
  Task = 'task',
  Tire = 'tire',
  Vehicle = 'vehicle',
  Vendor = 'vendor'
}

export type CreatableFieldFindManyInput = {
  creatable_fields_name?: InputMaybe<Scalars['Any']['input']>;
  document_type: CreatableFieldDocumentType;
  name?: InputMaybe<Scalars['Any']['input']>;
};

export type CreatableFieldNameDocument_TypeCreatable_Fields_NameCompoundUniqueInput = {
  creatable_fields_name: Scalars['String']['input'];
  document_type: CreatableFieldDocumentType;
  name: Scalars['String']['input'];
};

export type CreatableFieldUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_document_type_creatable_fields_name?: InputMaybe<CreatableFieldNameDocument_TypeCreatable_Fields_NameCompoundUniqueInput>;
};

export type CreatableFieldUpdateInput = {
  creatable_fields_name?: InputMaybe<Scalars['String']['input']>;
  document_type?: InputMaybe<CreatableFieldDocumentType>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreatorInputObject = {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<Scalars['String']['input']>>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  user_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type CreatorObject = {
  __typename?: 'CreatorObject';
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  img_url?: Maybe<Array<Scalars['String']['output']>>;
  last_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  user_unique_id?: Maybe<Scalars['String']['output']>;
};

export type CurrentStockStats = {
  __typename?: 'CurrentStockStats';
  normal?: Maybe<Scalars['Int']['output']>;
  ordering?: Maybe<Scalars['Int']['output']>;
  over?: Maybe<Scalars['Int']['output']>;
};

export type DashboardInventoryInput = {
  date_range: DateRange;
  end_date?: InputMaybe<Scalars['Date']['input']>;
  start_date?: InputMaybe<Scalars['Date']['input']>;
};

export type DashboardInventoryResult = {
  __typename?: 'DashboardInventoryResult';
  current_stock?: Maybe<CurrentStockStats>;
  goods_adjust?: Maybe<GoodsAdjustStats>;
  goods_issue?: Maybe<GoodsIssueStats>;
  goods_receive?: Maybe<GoodsReceiveStats>;
  goods_transfer?: Maybe<GoodsTransferStats>;
};

export type DashboardLogisticInput = {
  date_range: DateRange;
  end_date?: InputMaybe<Scalars['Date']['input']>;
  start_date?: InputMaybe<Scalars['Date']['input']>;
};

export type DashboardLogisticResult = {
  __typename?: 'DashboardLogisticResult';
  delivery?: Maybe<DeliveryStats>;
  maintenance?: Maybe<MaintenanceStats>;
  vehicle?: Maybe<VehicleStats>;
};

export type DashboardPurchaseInput = {
  date_range: DateRange;
  end_date?: InputMaybe<Scalars['Date']['input']>;
  start_date?: InputMaybe<Scalars['Date']['input']>;
};

export type DashboardPurchaseResult = {
  __typename?: 'DashboardPurchaseResult';
  purchase_order?: Maybe<PurchaseOrderStats>;
  purchase_return?: Maybe<PurchaseReturnStats>;
};

export type DashboardSalesInput = {
  date_range: DateRange;
  end_date?: InputMaybe<Scalars['Date']['input']>;
  start_date?: InputMaybe<Scalars['Date']['input']>;
};

export type DashboardSalesResult = {
  __typename?: 'DashboardSalesResult';
  billing_notes?: Maybe<BillingNotesStats>;
  quotation?: Maybe<QuotationStats>;
};

export enum DateRange {
  Custom = 'CUSTOM',
  Last_3Months = 'LAST_3_MONTHS',
  Last_7Days = 'LAST_7_DAYS',
  Last_14Days = 'LAST_14_DAYS',
  Last_30Days = 'LAST_30_DAYS',
  LastMonth = 'LAST_MONTH',
  LastYear = 'LAST_YEAR',
  ThisMonth = 'THIS_MONTH',
  ThisYear = 'THIS_YEAR',
  Today = 'TODAY',
  Yesterday = 'YESTERDAY'
}

export type DeliveryPositionDetails = {
  __typename?: 'DeliveryPositionDetails';
  dob?: Maybe<Scalars['DateTime']['output']>;
  identity_no?: Maybe<Scalars['String']['output']>;
  license_expire_date?: Maybe<Scalars['DateTime']['output']>;
  license_no?: Maybe<Scalars['String']['output']>;
  license_type?: Maybe<Scalars['String']['output']>;
};

export type DeliveryPositionDetailsInput = {
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  identity_no?: InputMaybe<Scalars['String']['input']>;
  license_expire_date?: InputMaybe<Scalars['DateTime']['input']>;
  license_no?: InputMaybe<Scalars['String']['input']>;
  license_type?: InputMaybe<Scalars['String']['input']>;
};

export type DeliveryStats = {
  __typename?: 'DeliveryStats';
  cancelled?: Maybe<Scalars['Int']['output']>;
  delivering?: Maybe<Scalars['Int']['output']>;
  draft?: Maybe<Scalars['Int']['output']>;
  finished?: Maybe<Scalars['Int']['output']>;
  late?: Maybe<Scalars['Int']['output']>;
  wait_deliver?: Maybe<Scalars['Int']['output']>;
};

export type GeneralExportInput = {
  end_date?: InputMaybe<Scalars['DateTime']['input']>;
  start_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum GeneralModelType {
  User = 'user',
  Warehouse = 'warehouse'
}

export type GoodsAdjustStats = {
  __typename?: 'GoodsAdjustStats';
  cancelled?: Maybe<Scalars['Int']['output']>;
  draft?: Maybe<Scalars['Int']['output']>;
  finished?: Maybe<Scalars['Int']['output']>;
};

export type GoodsIssueStats = {
  __typename?: 'GoodsIssueStats';
  cancelled?: Maybe<Scalars['Int']['output']>;
  draft?: Maybe<Scalars['Int']['output']>;
  finished?: Maybe<Scalars['Int']['output']>;
};

export type GoodsReceiveStats = {
  __typename?: 'GoodsReceiveStats';
  cancelled?: Maybe<Scalars['Int']['output']>;
  draft?: Maybe<Scalars['Int']['output']>;
  finished?: Maybe<Scalars['Int']['output']>;
  wait_receive?: Maybe<Scalars['Int']['output']>;
};

export type GoodsTransferStats = {
  __typename?: 'GoodsTransferStats';
  cancelled?: Maybe<Scalars['Int']['output']>;
  draft?: Maybe<Scalars['Int']['output']>;
  finished?: Maybe<Scalars['Int']['output']>;
  wait_transfer?: Maybe<Scalars['Int']['output']>;
};

export type ImageUrlInputObjectInput = {
  img_url?: InputMaybe<Scalars['String']['input']>;
  is_main_img?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ImageUrlObject = {
  __typename?: 'ImageUrlObject';
  img_url?: Maybe<Scalars['String']['output']>;
  is_main_img?: Maybe<Scalars['Boolean']['output']>;
};

export type InventorySetting = {
  __typename?: 'InventorySetting';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  type: InventorySettingType;
};

export type InventorySettingCreateInput = {
  name: Scalars['String']['input'];
  type: InventorySettingType;
};

export enum InventorySettingType {
  Cate_1 = 'cate_1',
  Cate_2 = 'cate_2',
  Cate_3 = 'cate_3',
  Unit = 'unit'
}

export type InventorySettingTypeNameCompoundUniqueInput = {
  name: Scalars['String']['input'];
  type: InventorySettingType;
};

export type InventorySettingUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  type_name?: InputMaybe<InventorySettingTypeNameCompoundUniqueInput>;
};

export type InventorySettingUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InventorySettingType>;
};

export type LoginInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  access_token: Scalars['String']['output'];
  user: User;
};

export type MaintenanceStats = {
  __typename?: 'MaintenanceStats';
  cancelled?: Maybe<Scalars['Int']['output']>;
  draft?: Maybe<Scalars['Int']['output']>;
  finished?: Maybe<Scalars['Int']['output']>;
  maintenancing?: Maybe<Scalars['Int']['output']>;
  wait_maintenance?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  ActivityLogCreate?: Maybe<ActivityLog>;
  BinLocationCreate: BinLocation;
  BinLocationDelete: BinLocation;
  BinLocationUpdate: BinLocation;
  CompanyCreate?: Maybe<Company>;
  CompanyUpdate?: Maybe<Company>;
  CreatableFieldCreate: CreatableField;
  CreatableFieldDelete: CreatableField;
  CreatableFieldUpdate: CreatableField;
  InventorySettingCreate: InventorySetting;
  InventorySettingDelete: InventorySetting;
  InventorySettingUpdate: InventorySetting;
  NotificationVehicleUpdate: NotificationVehicle;
  RoleCreate?: Maybe<Role>;
  RoleDelete?: Maybe<Role>;
  RoleUpdate?: Maybe<Role>;
  TagCreate: Tag;
  TagDelete: Tag;
  TagUpdate: Tag;
  UserCreate?: Maybe<User>;
  UserDelete?: Maybe<User>;
  UserSelfUpdate?: Maybe<User>;
  UserUpdate?: Maybe<User>;
  WarehouseCreate: Warehouse;
  WarehouseDelete: Warehouse;
  WarehouseLevel1Create: WarehouseLevel1;
  WarehouseLevel1Delete: WarehouseLevel1;
  WarehouseLevel1Update: WarehouseLevel1;
  WarehouseLevel2Create: WarehouseLevel2;
  WarehouseLevel2Delete: WarehouseLevel2;
  WarehouseLevel2Update: WarehouseLevel2;
  WarehouseLevel3Create: WarehouseLevel3;
  WarehouseLevel3Delete: WarehouseLevel3;
  WarehouseLevel3Update: WarehouseLevel3;
  WarehouseUpdate: Warehouse;
  login: LoginResponse;
};


export type MutationActivityLogCreateArgs = {
  data: ActivityLogCreateInput;
};


export type MutationBinLocationCreateArgs = {
  data: BinLocationCreateInput;
};


export type MutationBinLocationDeleteArgs = {
  uniqueInput: BinLocationUniqueInput;
};


export type MutationBinLocationUpdateArgs = {
  data: BinLocationUpdateInput;
  uniqueInput: BinLocationUniqueInput;
};


export type MutationCompanyCreateArgs = {
  data: CompanyCreateInput;
};


export type MutationCompanyUpdateArgs = {
  data: CompanyUpdateInput;
  uniqueInput: CompanyUniqueInput;
};


export type MutationCreatableFieldCreateArgs = {
  data: CreatableFieldCreateInput;
};


export type MutationCreatableFieldDeleteArgs = {
  uniqueInput: CreatableFieldUniqueInput;
};


export type MutationCreatableFieldUpdateArgs = {
  data: CreatableFieldUpdateInput;
  uniqueInput: CreatableFieldUniqueInput;
};


export type MutationInventorySettingCreateArgs = {
  createInput: InventorySettingCreateInput;
};


export type MutationInventorySettingDeleteArgs = {
  uniqueInput: InventorySettingUniqueInput;
};


export type MutationInventorySettingUpdateArgs = {
  uniqueInput: InventorySettingUniqueInput;
  updateInput: InventorySettingUpdateInput;
};


export type MutationNotificationVehicleUpdateArgs = {
  updateNotificationVehicleInput: UpdateNotificationVehicleInput;
};


export type MutationRoleCreateArgs = {
  data: RoleCreateInput;
};


export type MutationRoleDeleteArgs = {
  uniqueInput: RoleUniqueInput;
};


export type MutationRoleUpdateArgs = {
  data: RoleUpdateInput;
  uniqueInput: RoleUniqueInput;
};


export type MutationTagCreateArgs = {
  data: TagCreateInput;
};


export type MutationTagDeleteArgs = {
  uniqueInput: TagUniqueInput;
};


export type MutationTagUpdateArgs = {
  data: TagUpdateInput;
  uniqueInput: TagUniqueInput;
};


export type MutationUserCreateArgs = {
  data: UserCreateInput;
};


export type MutationUserDeleteArgs = {
  uniqueInput: UserUniqueInput;
};


export type MutationUserSelfUpdateArgs = {
  data: UserUpdateInput;
  validatePassword?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserUpdateArgs = {
  data: UserUpdateInput;
  uniqueInput: UserUniqueInput;
};


export type MutationWarehouseCreateArgs = {
  data: WarehouseCreateInput;
};


export type MutationWarehouseDeleteArgs = {
  uniqueInput: WarehouseUniqueInput;
};


export type MutationWarehouseLevel1CreateArgs = {
  data: WarehouseLevel1CreateInput;
};


export type MutationWarehouseLevel1DeleteArgs = {
  uniqueInput: WarehouseLevel1UniqueInput;
};


export type MutationWarehouseLevel1UpdateArgs = {
  data: WarehouseLevel1UpdateInput;
  uniqueInput: WarehouseLevel1UniqueInput;
};


export type MutationWarehouseLevel2CreateArgs = {
  data: WarehouseLevel2CreateInput;
};


export type MutationWarehouseLevel2DeleteArgs = {
  uniqueInput: WarehouseLevel2UniqueInput;
};


export type MutationWarehouseLevel2UpdateArgs = {
  data: WarehouseLevel2UpdateInput;
  uniqueInput: WarehouseLevel2UniqueInput;
};


export type MutationWarehouseLevel3CreateArgs = {
  data: WarehouseLevel3CreateInput;
};


export type MutationWarehouseLevel3DeleteArgs = {
  uniqueInput: WarehouseLevel3UniqueInput;
};


export type MutationWarehouseLevel3UpdateArgs = {
  data: WarehouseLevel3UpdateInput;
  uniqueInput: WarehouseLevel3UniqueInput;
};


export type MutationWarehouseUpdateArgs = {
  data: WarehouseUpdateInput;
  uniqueInput: WarehouseUniqueInput;
};


export type MutationLoginArgs = {
  loginInput?: InputMaybe<LoginInput>;
};

export type NotificationVehicle = {
  __typename?: 'NotificationVehicle';
  act_due_date?: Maybe<Scalars['Int']['output']>;
  created_by?: Maybe<NotificationVehicleCreatedBy>;
  created_date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_updated_by?: Maybe<NotificationVehicleUpdatedBy>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  maintenance_distance?: Maybe<Scalars['Decimal']['output']>;
  product_insurance_due_date?: Maybe<Scalars['Int']['output']>;
  vehicle_insurance_due_date?: Maybe<Scalars['Int']['output']>;
};

export type NotificationVehicleCreatedBy = {
  __typename?: 'NotificationVehicleCreatedBy';
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  img_url?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  last_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type NotificationVehicleUpdatedBy = {
  __typename?: 'NotificationVehicleUpdatedBy';
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  img_url?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  last_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type NotificationVehicleUpdatedByInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export enum PriceVatType {
  ExcludedVat = 'excluded_vat',
  IncludedVat = 'included_vat'
}

export enum PurchaseDocumentType {
  PurchaseOrder = 'purchase_order',
  PurchaseReturn = 'purchase_return'
}

export type PurchaseItemInput = {
  additional_discount?: InputMaybe<Scalars['Float']['input']>;
  barcode: Scalars['String']['input'];
  discount?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  item_desc?: InputMaybe<Scalars['String']['input']>;
  item_id: Scalars['Int']['input'];
  item_img_url?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  item_name: Scalars['String']['input'];
  item_unique_id: Scalars['String']['input'];
  net_amount?: InputMaybe<Scalars['Float']['input']>;
  po_qty?: InputMaybe<Scalars['Float']['input']>;
  post_discount_amount?: InputMaybe<Scalars['Float']['input']>;
  pre_discount_amount?: InputMaybe<Scalars['Float']['input']>;
  pre_vat_amount?: InputMaybe<Scalars['Float']['input']>;
  price_per_unit?: InputMaybe<Scalars['Float']['input']>;
  qty: Scalars['Float']['input'];
  qty_issued?: InputMaybe<Scalars['Float']['input']>;
  qty_ordered?: InputMaybe<Scalars['Float']['input']>;
  qty_received?: InputMaybe<Scalars['Float']['input']>;
  qty_returned?: InputMaybe<Scalars['Float']['input']>;
  reference_document_type?: InputMaybe<ReferenceDocumentType>;
  reference_line_item?: InputMaybe<Scalars['Json']['input']>;
  reference_unique_id: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  rs_qty?: InputMaybe<Scalars['Float']['input']>;
  stock_qty?: InputMaybe<Scalars['Int']['input']>;
  total_amount?: InputMaybe<Scalars['Float']['input']>;
  traceability: Traceability;
  unique_id: Scalars['String']['input'];
  uom?: InputMaybe<Scalars['Json']['input']>;
  uom_conversion_list?: InputMaybe<Array<InputMaybe<Scalars['Json']['input']>>>;
  uom_id?: InputMaybe<Scalars['Int']['input']>;
  uom_name?: InputMaybe<Scalars['String']['input']>;
  vat_amount?: InputMaybe<Scalars['Float']['input']>;
  vat_percentage?: InputMaybe<Scalars['String']['input']>;
  wait_receive_qty?: InputMaybe<Scalars['Float']['input']>;
  withholding_tax_type?: InputMaybe<Scalars['String']['input']>;
  withholding_tax_value?: InputMaybe<Scalars['Float']['input']>;
};

export type PurchaseItemList = {
  __typename?: 'PurchaseItemList';
  additional_discount?: Maybe<Scalars['Float']['output']>;
  barcode: Scalars['String']['output'];
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  item_desc?: Maybe<Scalars['String']['output']>;
  item_id: Scalars['Int']['output'];
  item_img_url?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  item_name: Scalars['String']['output'];
  item_unique_id: Scalars['String']['output'];
  net_amount?: Maybe<Scalars['Float']['output']>;
  po_qty?: Maybe<Scalars['Float']['output']>;
  post_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_discount_amount?: Maybe<Scalars['Float']['output']>;
  pre_vat_amount?: Maybe<Scalars['Float']['output']>;
  price_per_unit?: Maybe<Scalars['Float']['output']>;
  qty: Scalars['Float']['output'];
  qty_issued?: Maybe<Scalars['Float']['output']>;
  qty_ordered?: Maybe<Scalars['Float']['output']>;
  qty_received?: Maybe<Scalars['Float']['output']>;
  qty_returned?: Maybe<Scalars['Float']['output']>;
  reference_document_type?: Maybe<ReferenceDocumentType>;
  reference_line_item?: Maybe<Scalars['Json']['output']>;
  reference_unique_id: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  rs_qty?: Maybe<Scalars['Float']['output']>;
  stock_qty?: Maybe<Scalars['Int']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
  traceability?: Maybe<Traceability>;
  unique_id: Scalars['String']['output'];
  uom?: Maybe<Scalars['Json']['output']>;
  uom_conversion_list?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  uom_id?: Maybe<Scalars['Int']['output']>;
  uom_name?: Maybe<Scalars['String']['output']>;
  vat_amount?: Maybe<Scalars['Float']['output']>;
  vat_percentage?: Maybe<Scalars['String']['output']>;
  wait_receive_qty?: Maybe<Scalars['Float']['output']>;
  withholding_tax_type?: Maybe<Scalars['String']['output']>;
  withholding_tax_value?: Maybe<Scalars['Float']['output']>;
};

export type PurchaseOrderStats = {
  __typename?: 'PurchaseOrderStats';
  cancelled?: Maybe<Scalars['Int']['output']>;
  draft?: Maybe<Scalars['Int']['output']>;
  fully_imported?: Maybe<Scalars['Int']['output']>;
  fully_ordered?: Maybe<Scalars['Int']['output']>;
  partially_imported?: Maybe<Scalars['Int']['output']>;
  wait_ordered?: Maybe<Scalars['Int']['output']>;
};

export type PurchaseReturnStats = {
  __typename?: 'PurchaseReturnStats';
  cancelled?: Maybe<Scalars['Int']['output']>;
  draft?: Maybe<Scalars['Int']['output']>;
  finished?: Maybe<Scalars['Int']['output']>;
  wait_returned?: Maybe<Scalars['Int']['output']>;
};

export type PurchaseUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  ActivityLogFindManyByReferenceId: Array<ActivityLog>;
  ActivityLogFindUnique?: Maybe<ActivityLog>;
  BinLocationFindUnique?: Maybe<BinLocation>;
  BinLocationsFindAll: Array<BinLocation>;
  CompanyFindUnique?: Maybe<Company>;
  CreatableFieldFindUnique?: Maybe<CreatableField>;
  CreatableFields: Array<CreatableField>;
  DashboardInventory?: Maybe<DashboardInventoryResult>;
  DashboardLogistic?: Maybe<DashboardLogisticResult>;
  DashboardPurchase?: Maybe<DashboardPurchaseResult>;
  DashboardSales?: Maybe<DashboardSalesResult>;
  InventorySettingFindUnique?: Maybe<InventorySetting>;
  InventorySettingsByType: Array<InventorySetting>;
  NotificationVehicleFind?: Maybe<NotificationVehicle>;
  RoleFindUnique?: Maybe<Role>;
  S3GetFileUrl: Scalars['Any']['output'];
  S3GetUploadUrl: Scalars['Any']['output'];
  TagFindUnique?: Maybe<Tag>;
  TagsFindManyByDocumentType: Array<Tag>;
  UniqueIdGenerate: Scalars['String']['output'];
  UniqueIdIsExist: Scalars['Boolean']['output'];
  UserFindUnique?: Maybe<User>;
  UserViewsAggrid: UserViewAggrid;
  UsersFindManyAggrid: UserAggrid;
  UtilGetRandomUUID: Scalars['String']['output'];
  WarehouseFindUnique?: Maybe<Warehouse>;
  WarehouseLevel1FindUnique?: Maybe<WarehouseLevel1>;
  WarehouseLevel1sFindAll: Array<WarehouseLevel1>;
  WarehouseLevel2FindUnique?: Maybe<WarehouseLevel2>;
  WarehouseLevel2sFindAll: Array<WarehouseLevel2>;
  WarehouseLevel3FindUnique?: Maybe<WarehouseLevel3>;
  WarehouseLevel3sFindAll: Array<WarehouseLevel3>;
  WarehousesFindAll?: Maybe<Array<Warehouse>>;
  validateToken: User;
};


export type QueryActivityLogFindManyByReferenceIdArgs = {
  findManyInput: ActivityLogByRefereceIdInput;
};


export type QueryActivityLogFindUniqueArgs = {
  uniqueInput?: InputMaybe<ActivityLogUniqueInput>;
};


export type QueryBinLocationFindUniqueArgs = {
  uniqueInput: BinLocationUniqueInput;
};


export type QueryCompanyFindUniqueArgs = {
  uniqueInput?: InputMaybe<CompanyUniqueInput>;
};


export type QueryCreatableFieldFindUniqueArgs = {
  uniqueInput: CreatableFieldUniqueInput;
};


export type QueryCreatableFieldsArgs = {
  findManyInput?: InputMaybe<CreatableFieldFindManyInput>;
};


export type QueryDashboardInventoryArgs = {
  input?: InputMaybe<DashboardInventoryInput>;
};


export type QueryDashboardLogisticArgs = {
  input?: InputMaybe<DashboardLogisticInput>;
};


export type QueryDashboardPurchaseArgs = {
  input?: InputMaybe<DashboardPurchaseInput>;
};


export type QueryDashboardSalesArgs = {
  input?: InputMaybe<DashboardSalesInput>;
};


export type QueryInventorySettingFindUniqueArgs = {
  uniqueInput: InventorySettingUniqueInput;
};


export type QueryInventorySettingsByTypeArgs = {
  type: InventorySettingType;
};


export type QueryRoleFindUniqueArgs = {
  uniqueInput: RoleUniqueInput;
};


export type QueryS3GetFileUrlArgs = {
  keyPath: Scalars['String']['input'];
};


export type QueryS3GetUploadUrlArgs = {
  fileName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTagFindUniqueArgs = {
  uniqueInput: TagUniqueInput;
};


export type QueryTagsFindManyByDocumentTypeArgs = {
  documentType?: InputMaybe<TagDocumentType>;
};


export type QueryUniqueIdGenerateArgs = {
  modelType: GeneralModelType;
};


export type QueryUniqueIdIsExistArgs = {
  modelType: GeneralModelType;
  uniqueId: Scalars['String']['input'];
};


export type QueryUserFindUniqueArgs = {
  uniqueInput?: InputMaybe<UserUniqueInput>;
};


export type QueryUserViewsAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryUsersFindManyAggridArgs = {
  aggridInput?: InputMaybe<Scalars['AnyAggridInput']['input']>;
};


export type QueryWarehouseFindUniqueArgs = {
  uniqueInput: WarehouseUniqueInput;
};


export type QueryWarehouseLevel1FindUniqueArgs = {
  uniqueInput: WarehouseLevel1UniqueInput;
};


export type QueryWarehouseLevel2FindUniqueArgs = {
  uniqueInput: WarehouseLevel2UniqueInput;
};


export type QueryWarehouseLevel3FindUniqueArgs = {
  uniqueInput: WarehouseLevel3UniqueInput;
};


export type QueryWarehousesFindAllArgs = {
  whereInput?: InputMaybe<WarehouseWhereInput>;
};

export type QuotationStats = {
  __typename?: 'QuotationStats';
  accepted?: Maybe<Scalars['Int']['output']>;
  cancelled?: Maybe<Scalars['Int']['output']>;
  draft?: Maybe<Scalars['Int']['output']>;
  finished?: Maybe<Scalars['Int']['output']>;
  not_accepted?: Maybe<Scalars['Int']['output']>;
  wait_accept?: Maybe<Scalars['Int']['output']>;
};

export type ReferenceDocumentList = {
  __typename?: 'ReferenceDocumentList';
  document_id?: Maybe<Scalars['Int']['output']>;
  document_type?: Maybe<PurchaseDocumentType>;
  document_unique_id?: Maybe<Scalars['String']['output']>;
};

export type ReferenceDocumentListInput = {
  document_id?: InputMaybe<Scalars['Int']['input']>;
  document_type?: InputMaybe<PurchaseDocumentType>;
  document_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export enum ReferenceDocumentType {
  PurchaseOrder = 'purchase_order',
  PurchaseReturn = 'purchase_return'
}

export type RelatedUser = {
  __typename?: 'RelatedUser';
  department?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  img_url?: Maybe<Scalars['String']['output']>;
  is_active?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  title_name?: Maybe<Scalars['String']['output']>;
  user_unique_id?: Maybe<Scalars['String']['output']>;
};

export type RelatedUserList = {
  department?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  is_active?: InputMaybe<Scalars['Int']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  title_name?: InputMaybe<Scalars['String']['input']>;
  user_unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type Role = {
  __typename?: 'Role';
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  role_permission?: Maybe<Scalars['Json']['output']>;
  user_list?: Maybe<Array<User>>;
};

export type RoleCreateInput = {
  created_by: Scalars['Json']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  role_permission?: InputMaybe<Scalars['Json']['input']>;
  user_id_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RoleUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RoleUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  last_updated_by?: InputMaybe<Scalars['Json']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role_permission_list?: InputMaybe<Scalars['Json']['input']>;
  user_id_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SalesCustomerInputObject = {
  billing_address?: InputMaybe<AddressInputObject>;
  customer_id?: InputMaybe<Scalars['Int']['input']>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  customer_unique_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  identity_no?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type SalesCustomerObject = {
  __typename?: 'SalesCustomerObject';
  billing_address?: Maybe<AddressObject>;
  customer_id?: Maybe<Scalars['Int']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  customer_unique_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  identity_no?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SpectaQlOption = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Tag = {
  __typename?: 'Tag';
  document_type: TagDocumentType;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TagCreateInput = {
  document_type: TagDocumentType;
  name: Scalars['String']['input'];
};

export enum TagDocumentType {
  Batch = 'batch',
  CashSales = 'cash_sales',
  Contact = 'contact',
  CreditNote = 'credit_note',
  CustomerDeposit = 'customer_deposit',
  DeliveryOrder = 'delivery_order',
  DeliveryTrip = 'delivery_trip',
  Employee = 'employee',
  Event = 'event',
  GoodsAdjust = 'goods_adjust',
  GoodsIssue = 'goods_issue',
  GoodsReceive = 'goods_receive',
  GoodsTransfer = 'goods_transfer',
  Inventory = 'inventory',
  Item = 'item',
  PaymentMade = 'payment_made',
  PaymentReceive = 'payment_receive',
  Project = 'project',
  Promotion = 'promotion',
  Purchase = 'purchase',
  PurchaseInvoice = 'purchase_invoice',
  PurchaseOrder = 'purchase_order',
  PurchaseRequest = 'purchase_request',
  PurchaseReturn = 'purchase_return',
  Quotation = 'quotation',
  Role = 'role',
  Sale = 'sale',
  SalesInvoice = 'sales_invoice',
  SalesOrder = 'sales_order',
  SalesReturn = 'sales_return',
  Serial = 'serial',
  Task = 'task'
}

export type TagUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TagUpdateInput = {
  document_type?: InputMaybe<TagDocumentType>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum Traceability {
  Lot = 'lot',
  Normal = 'normal',
  Serial = 'serial'
}

export type UpdateNotificationVehicleInput = {
  act_due_date?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  last_updated_by?: InputMaybe<NotificationVehicleUpdatedByInput>;
  maintenance_distance?: InputMaybe<Scalars['Decimal']['input']>;
  product_insurance_due_date?: InputMaybe<Scalars['Int']['input']>;
  vehicle_insurance_due_date?: InputMaybe<Scalars['Int']['input']>;
};

export type User = {
  __typename?: 'User';
  created_by: Scalars['Json']['output'];
  created_date: Scalars['DateTime']['output'];
  delivery_position_details?: Maybe<DeliveryPositionDetails>;
  department?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  img_url?: Maybe<Array<Scalars['String']['output']>>;
  inactive_date?: Maybe<Scalars['DateTime']['output']>;
  is_delivery_position: Scalars['Int']['output'];
  last_login_date?: Maybe<Scalars['DateTime']['output']>;
  last_name: Scalars['String']['output'];
  last_updated_by?: Maybe<Scalars['Json']['output']>;
  last_updated_date?: Maybe<Scalars['DateTime']['output']>;
  nick_name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  remark_status?: Maybe<Scalars['String']['output']>;
  role_list?: Maybe<Array<Role>>;
  role_permission_template?: Maybe<Scalars['Json']['output']>;
  status: Scalars['Int']['output'];
  title_name?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['String']['output'];
};

export type UserAggrid = {
  __typename?: 'UserAggrid';
  count: Scalars['Int']['output'];
  results: Array<User>;
};

export type UserCreateInput = {
  created_by: Scalars['Json']['input'];
  delivery_position_details?: InputMaybe<DeliveryPositionDetailsInput>;
  department?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<Scalars['String']['input']>>;
  inactive_date?: InputMaybe<Scalars['DateTime']['input']>;
  is_delivery_position: Scalars['Int']['input'];
  last_name: Scalars['String']['input'];
  nick_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  remark_status?: InputMaybe<Scalars['String']['input']>;
  role_id_list?: InputMaybe<Array<Scalars['Int']['input']>>;
  role_permission_template?: InputMaybe<Scalars['Json']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  title_name?: InputMaybe<Scalars['String']['input']>;
  unique_id: Scalars['String']['input'];
  user_password: Scalars['String']['input'];
};

export type UserUniqueInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type UserUpdateInput = {
  delivery_position_details?: InputMaybe<DeliveryPositionDetailsInput>;
  department?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  img_url?: InputMaybe<Array<Scalars['String']['input']>>;
  inactive_date?: InputMaybe<Scalars['DateTime']['input']>;
  is_delivery_position?: InputMaybe<Scalars['Int']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_updated_by?: InputMaybe<Scalars['Json']['input']>;
  new_password?: InputMaybe<Scalars['String']['input']>;
  nick_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  remark_status?: InputMaybe<Scalars['String']['input']>;
  role_id_list?: InputMaybe<Array<Scalars['Int']['input']>>;
  role_permission_template?: InputMaybe<Scalars['Json']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  title_name?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type UserView = {
  __typename?: 'UserView';
  created_date?: Maybe<Scalars['DateTime']['output']>;
  delivery_position_details?: Maybe<Scalars['Json']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  img_url?: Maybe<Array<Scalars['String']['output']>>;
  last_name?: Maybe<Scalars['String']['output']>;
  license_expire_date?: Maybe<Scalars['DateTime']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  unique_id: Scalars['String']['output'];
};

export type UserViewAggrid = {
  __typename?: 'UserViewAggrid';
  count: Scalars['Int']['output'];
  results: Array<UserView>;
};

export type VehicleStats = {
  __typename?: 'VehicleStats';
  approaching?: Maybe<Scalars['Int']['output']>;
  overdue?: Maybe<Scalars['Int']['output']>;
};

export type Warehouse = {
  __typename?: 'Warehouse';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  unique_id: Scalars['String']['output'];
  warehouse_level_1_list?: Maybe<Array<WarehouseLevel1>>;
};

export type WarehouseCreateInput = {
  name: Scalars['String']['input'];
  unique_id: Scalars['String']['input'];
};

export type WarehouseLevel1 = {
  __typename?: 'WarehouseLevel1';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  warehouse: Warehouse;
  warehouse_level_2_list?: Maybe<Array<WarehouseLevel2>>;
};

export type WarehouseLevel1CreateInput = {
  name: Scalars['String']['input'];
  warehouse_id: Scalars['Int']['input'];
  warehouse_level_2_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type WarehouseLevel1UniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseLevel1UpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  warehouse_id?: InputMaybe<Scalars['Int']['input']>;
  warehouse_level_2_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type WarehouseLevel2 = {
  __typename?: 'WarehouseLevel2';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  warehouse_level_1: WarehouseLevel1;
  warehouse_level_3_list?: Maybe<Array<WarehouseLevel3>>;
};

export type WarehouseLevel2CreateInput = {
  name: Scalars['String']['input'];
  warehouse_level_1_id: Scalars['Int']['input'];
  warehouse_level_3_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type WarehouseLevel2UniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseLevel2UpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  warehouse_level_1_id?: InputMaybe<Scalars['Int']['input']>;
  warehouse_level_3_list?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type WarehouseLevel3 = {
  __typename?: 'WarehouseLevel3';
  bin_location_list?: Maybe<Array<BinLocation>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  warehouse_level_2: WarehouseLevel2;
};

export type WarehouseLevel3CreateInput = {
  bin_location_list?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  warehouse_level_2_id: Scalars['Int']['input'];
};

export type WarehouseLevel3UniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseLevel3UpdateInput = {
  bin_location_list?: InputMaybe<Array<Scalars['Int']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  warehouse_level_2_id?: InputMaybe<Scalars['Int']['input']>;
};

export type WarehouseUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<WarehouseWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<WarehouseWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<WarehouseWhereInput>>>;
};

export type ActivityLogCreateMutationVariables = Exact<{
  data: ActivityLogCreateInput;
}>;


export type ActivityLogCreateMutation = { __typename?: 'Mutation', ActivityLogCreate?: { __typename?: 'ActivityLog', id: number, activity_type?: ActivityType | null, reference_id?: number | null, document_type?: ActivityLogDocumentType | null, created_date?: any | null, activity_detail?: { __typename?: 'ActivityLogDetailObject', message?: Array<string> | null, attachment_list?: Array<string> | null, prev_status?: string | null, curr_status?: string | null, updated_fields?: any | null, added_related_employee?: { __typename?: 'ActivityLogRelatedEmployeeObject', user_unique_id?: string | null, first_name?: string | null, last_name?: string | null } | null, deleted_related_employee?: { __typename?: 'ActivityLogRelatedEmployeeObject', user_unique_id?: string | null, first_name?: string | null, last_name?: string | null } | null, copied_from?: { __typename?: 'ActivityLogCopyObject', id?: number | null, unique_id?: string | null } | null, copied_to?: { __typename?: 'ActivityLogCopyObject', id?: number | null, unique_id?: string | null } | null } | null, created_by?: { __typename?: 'CreatorObject', user_id?: number | null, user_unique_id?: string | null, first_name?: string | null, last_name?: string | null, email?: string | null, img_url?: Array<string> | null } | null } | null };

export type ActivityLogFindManyByReferenceIdQueryVariables = Exact<{
  findManyInput: ActivityLogByRefereceIdInput;
}>;


export type ActivityLogFindManyByReferenceIdQuery = { __typename?: 'Query', ActivityLogFindManyByReferenceId: Array<{ __typename?: 'ActivityLog', id: number, activity_type?: ActivityType | null, reference_id?: number | null, document_type?: ActivityLogDocumentType | null, created_date?: any | null, activity_detail?: { __typename?: 'ActivityLogDetailObject', message?: Array<string> | null, attachment_list?: Array<string> | null, prev_status?: string | null, curr_status?: string | null, updated_fields?: any | null, added_related_employee?: { __typename?: 'ActivityLogRelatedEmployeeObject', user_unique_id?: string | null, first_name?: string | null, last_name?: string | null } | null, deleted_related_employee?: { __typename?: 'ActivityLogRelatedEmployeeObject', user_unique_id?: string | null, first_name?: string | null, last_name?: string | null } | null, copied_from?: { __typename?: 'ActivityLogCopyObject', id?: number | null, unique_id?: string | null } | null, copied_to?: { __typename?: 'ActivityLogCopyObject', id?: number | null, unique_id?: string | null } | null } | null, created_by?: { __typename?: 'CreatorObject', user_id?: number | null, user_unique_id?: string | null, first_name?: string | null, last_name?: string | null, email?: string | null, img_url?: Array<string> | null } | null }> };

export type LoginMutationVariables = Exact<{
  loginInput?: InputMaybe<LoginInput>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', access_token: string, user: { __typename?: 'User', id: number, unique_id: string, email: string, img_url?: Array<string> | null, title_name?: string | null, first_name: string, last_name: string, nick_name?: string | null, gender?: string | null, phone?: string | null, department?: string | null, position?: string | null, status: number, remark_status?: string | null, inactive_date?: any | null, created_date: any, created_by: any, last_updated_date?: any | null, last_updated_by?: any | null, role_permission_template?: any | null, last_login_date?: any | null, role_list?: Array<{ __typename?: 'Role', id: number, name: string, description?: string | null, created_date: any, created_by: any, last_updated_date?: any | null, last_updated_by?: any | null, role_permission?: any | null }> | null } } };

export type ValidateTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type ValidateTokenQuery = { __typename?: 'Query', validateToken: { __typename?: 'User', id: number, unique_id: string, email: string, img_url?: Array<string> | null, title_name?: string | null, first_name: string, last_name: string, nick_name?: string | null, gender?: string | null, phone?: string | null, department?: string | null, position?: string | null, status: number, remark_status?: string | null, inactive_date?: any | null, created_date: any, created_by: any, last_updated_date?: any | null, last_updated_by?: any | null, role_permission_template?: any | null, last_login_date?: any | null, role_list?: Array<{ __typename?: 'Role', id: number, name: string, description?: string | null, created_date: any, created_by: any, last_updated_date?: any | null, last_updated_by?: any | null, role_permission?: any | null }> | null } };

export type DashboardInventoryQueryVariables = Exact<{
  input?: InputMaybe<DashboardInventoryInput>;
}>;


export type DashboardInventoryQuery = { __typename?: 'Query', DashboardInventory?: { __typename?: 'DashboardInventoryResult', current_stock?: { __typename?: 'CurrentStockStats', normal?: number | null, over?: number | null, ordering?: number | null } | null, goods_receive?: { __typename?: 'GoodsReceiveStats', wait_receive?: number | null, finished?: number | null, cancelled?: number | null, draft?: number | null } | null, goods_issue?: { __typename?: 'GoodsIssueStats', finished?: number | null, cancelled?: number | null, draft?: number | null } | null, goods_transfer?: { __typename?: 'GoodsTransferStats', wait_transfer?: number | null, finished?: number | null, cancelled?: number | null, draft?: number | null } | null, goods_adjust?: { __typename?: 'GoodsAdjustStats', finished?: number | null, cancelled?: number | null, draft?: number | null } | null } | null };

export type DashboardLogisticQueryVariables = Exact<{
  input?: InputMaybe<DashboardLogisticInput>;
}>;


export type DashboardLogisticQuery = { __typename?: 'Query', DashboardLogistic?: { __typename?: 'DashboardLogisticResult', vehicle?: { __typename?: 'VehicleStats', approaching?: number | null, overdue?: number | null } | null, delivery?: { __typename?: 'DeliveryStats', delivering?: number | null, late?: number | null, wait_deliver?: number | null, cancelled?: number | null, draft?: number | null, finished?: number | null } | null, maintenance?: { __typename?: 'MaintenanceStats', wait_maintenance?: number | null, maintenancing?: number | null, cancelled?: number | null, draft?: number | null, finished?: number | null } | null } | null };

export type DashboardPurchaseQueryVariables = Exact<{
  input?: InputMaybe<DashboardPurchaseInput>;
}>;


export type DashboardPurchaseQuery = { __typename?: 'Query', DashboardPurchase?: { __typename?: 'DashboardPurchaseResult', purchase_order?: { __typename?: 'PurchaseOrderStats', wait_ordered?: number | null, fully_ordered?: number | null, partially_imported?: number | null, fully_imported?: number | null, cancelled?: number | null, draft?: number | null } | null, purchase_return?: { __typename?: 'PurchaseReturnStats', draft?: number | null, finished?: number | null, wait_returned?: number | null, cancelled?: number | null } | null } | null };

export type DashboardSalesQueryVariables = Exact<{
  input?: InputMaybe<DashboardSalesInput>;
}>;


export type DashboardSalesQuery = { __typename?: 'Query', DashboardSales?: { __typename?: 'DashboardSalesResult', quotation?: { __typename?: 'QuotationStats', wait_accept?: number | null, accepted?: number | null, not_accepted?: number | null, cancelled?: number | null, draft?: number | null, finished?: number | null } | null, billing_notes?: { __typename?: 'BillingNotesStats', wait_payment?: number | null, fully_payment?: number | null, cancelled?: number | null, draft?: number | null } | null } | null };

export type CompanyCreateMutationVariables = Exact<{
  data: CompanyCreateInput;
}>;


export type CompanyCreateMutation = { __typename?: 'Mutation', CompanyCreate?: { __typename?: 'Company', id: number, unique_id: string, name: string, type?: string | null, identity_no?: string | null, register_date?: any | null, img_url: Array<string>, is_registered_vat?: boolean | null, vat_registration_date?: any | null, contact_channel_list?: Array<{ __typename?: 'ContactChannelObject', contact_channel_type?: string | null, contact_channel_info?: string | null }> | null, address_list?: Array<{ __typename?: 'AddressObject', is_default?: boolean | null, is_same_as_default_address?: boolean | null, address_type?: string | null, address_contact_name?: string | null, address_contact_phone?: string | null, address_contact_fax?: string | null, name?: string | null, sub_district?: string | null, district?: string | null, province?: string | null, postal_code?: string | null, country?: string | null }> | null } | null };

export type CompanyFindUniqueQueryVariables = Exact<{
  uniqueInput?: InputMaybe<CompanyUniqueInput>;
}>;


export type CompanyFindUniqueQuery = { __typename?: 'Query', CompanyFindUnique?: { __typename?: 'Company', id: number, unique_id: string, name: string, type?: string | null, identity_no?: string | null, register_date?: any | null, img_url: Array<string>, is_registered_vat?: boolean | null, vat_registration_date?: any | null, contact_channel_list?: Array<{ __typename?: 'ContactChannelObject', contact_channel_type?: string | null, contact_channel_info?: string | null }> | null, address_list?: Array<{ __typename?: 'AddressObject', is_default?: boolean | null, is_same_as_default_address?: boolean | null, address_type?: string | null, address_contact_name?: string | null, address_contact_phone?: string | null, address_contact_fax?: string | null, name?: string | null, sub_district?: string | null, district?: string | null, province?: string | null, postal_code?: string | null, country?: string | null }> | null } | null };

export type CompanyUpdateMutationVariables = Exact<{
  uniqueInput: CompanyUniqueInput;
  data: CompanyUpdateInput;
}>;


export type CompanyUpdateMutation = { __typename?: 'Mutation', CompanyUpdate?: { __typename?: 'Company', id: number, unique_id: string, name: string, type?: string | null, identity_no?: string | null, register_date?: any | null, img_url: Array<string>, is_registered_vat?: boolean | null, vat_registration_date?: any | null, contact_channel_list?: Array<{ __typename?: 'ContactChannelObject', contact_channel_type?: string | null, contact_channel_info?: string | null }> | null, address_list?: Array<{ __typename?: 'AddressObject', is_default?: boolean | null, is_same_as_default_address?: boolean | null, address_type?: string | null, address_contact_name?: string | null, address_contact_phone?: string | null, address_contact_fax?: string | null, name?: string | null, sub_district?: string | null, district?: string | null, province?: string | null, postal_code?: string | null, country?: string | null }> | null } | null };

export type CreatableFieldCreateMutationVariables = Exact<{
  data: CreatableFieldCreateInput;
}>;


export type CreatableFieldCreateMutation = { __typename?: 'Mutation', CreatableFieldCreate: { __typename?: 'CreatableField', id: number, creatable_fields_name: string, document_type: CreatableFieldDocumentType, name: string } };

export type CreatableFieldDeleteMutationVariables = Exact<{
  uniqueInput: CreatableFieldUniqueInput;
}>;


export type CreatableFieldDeleteMutation = { __typename?: 'Mutation', CreatableFieldDelete: { __typename?: 'CreatableField', name: string } };

export type CreatableFieldsQueryVariables = Exact<{
  findManyInput?: InputMaybe<CreatableFieldFindManyInput>;
}>;


export type CreatableFieldsQuery = { __typename?: 'Query', CreatableFields: Array<{ __typename?: 'CreatableField', creatable_fields_name: string, document_type: CreatableFieldDocumentType, id: number, name: string }> };

export type InventorySettingCreateMutationVariables = Exact<{
  createInput: InventorySettingCreateInput;
}>;


export type InventorySettingCreateMutation = { __typename?: 'Mutation', InventorySettingCreate: { __typename?: 'InventorySetting', id: number, name: string, type: InventorySettingType } };

export type InventorySettingsByTypeQueryVariables = Exact<{
  type: InventorySettingType;
}>;


export type InventorySettingsByTypeQuery = { __typename?: 'Query', InventorySettingsByType: Array<{ __typename?: 'InventorySetting', id: number, name: string, type: InventorySettingType }> };

export type InventorySettingFindUniqueQueryVariables = Exact<{
  uniqueInput: InventorySettingUniqueInput;
}>;


export type InventorySettingFindUniqueQuery = { __typename?: 'Query', InventorySettingFindUnique?: { __typename?: 'InventorySetting', id: number, name: string, type: InventorySettingType } | null };

export type InventorySettingUpdateMutationVariables = Exact<{
  uniqueInput: InventorySettingUniqueInput;
  updateInput: InventorySettingUpdateInput;
}>;


export type InventorySettingUpdateMutation = { __typename?: 'Mutation', InventorySettingUpdate: { __typename?: 'InventorySetting', id: number, name: string, type: InventorySettingType } };

export type InventorySettingDeleteMutationVariables = Exact<{
  uniqueInput: InventorySettingUniqueInput;
}>;


export type InventorySettingDeleteMutation = { __typename?: 'Mutation', InventorySettingDelete: { __typename?: 'InventorySetting', id: number, name: string, type: InventorySettingType } };

export type BinLocationCreateMutationVariables = Exact<{
  data: BinLocationCreateInput;
}>;


export type BinLocationCreateMutation = { __typename?: 'Mutation', BinLocationCreate: { __typename?: 'BinLocation', id: number, name: string } };

export type BinLocationDeleteMutationVariables = Exact<{
  uniqueInput: BinLocationUniqueInput;
}>;


export type BinLocationDeleteMutation = { __typename?: 'Mutation', BinLocationDelete: { __typename?: 'BinLocation', id: number, name: string } };

export type BinLocationsFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type BinLocationsFindAllQuery = { __typename?: 'Query', BinLocationsFindAll: Array<{ __typename?: 'BinLocation', id: number, name: string, warehouse_level_3: { __typename?: 'WarehouseLevel3', id: number, name: string, warehouse_level_2: { __typename?: 'WarehouseLevel2', id: number, name: string, warehouse_level_1: { __typename?: 'WarehouseLevel1', id: number, name: string, warehouse: { __typename?: 'Warehouse', id: number, name: string } } } } }> };

export type BinLocationUpdateMutationVariables = Exact<{
  uniqueInput: BinLocationUniqueInput;
  data: BinLocationUpdateInput;
}>;


export type BinLocationUpdateMutation = { __typename?: 'Mutation', BinLocationUpdate: { __typename?: 'BinLocation', id: number, name: string } };

export type WarehouseFindUniqueQueryVariables = Exact<{
  uniqueInput: WarehouseUniqueInput;
}>;


export type WarehouseFindUniqueQuery = { __typename?: 'Query', WarehouseFindUnique?: { __typename?: 'Warehouse', id: number, unique_id: string, name: string, warehouse_level_1_list?: Array<{ __typename?: 'WarehouseLevel1', id: number, name: string, warehouse_level_2_list?: Array<{ __typename?: 'WarehouseLevel2', id: number, name: string, warehouse_level_3_list?: Array<{ __typename?: 'WarehouseLevel3', id: number, name: string, bin_location_list?: Array<{ __typename?: 'BinLocation', name: string, id: number }> | null }> | null }> | null }> | null } | null };

export type WarehouseCreateMutationVariables = Exact<{
  data: WarehouseCreateInput;
}>;


export type WarehouseCreateMutation = { __typename?: 'Mutation', WarehouseCreate: { __typename?: 'Warehouse', id: number, unique_id: string, name: string, warehouse_level_1_list?: Array<{ __typename?: 'WarehouseLevel1', id: number, name: string, warehouse_level_2_list?: Array<{ __typename?: 'WarehouseLevel2', id: number, name: string, warehouse_level_3_list?: Array<{ __typename?: 'WarehouseLevel3', id: number, name: string, bin_location_list?: Array<{ __typename?: 'BinLocation', name: string, id: number }> | null }> | null }> | null }> | null } };

export type WarehouseDeleteMutationVariables = Exact<{
  uniqueInput: WarehouseUniqueInput;
}>;


export type WarehouseDeleteMutation = { __typename?: 'Mutation', WarehouseDelete: { __typename?: 'Warehouse', id: number, unique_id: string, name: string } };

export type WarehouseLevel1CreateMutationVariables = Exact<{
  data: WarehouseLevel1CreateInput;
}>;


export type WarehouseLevel1CreateMutation = { __typename?: 'Mutation', WarehouseLevel1Create: { __typename?: 'WarehouseLevel1', id: number, name: string } };

export type WarehouseLevel1DeleteMutationVariables = Exact<{
  uniqueInput: WarehouseLevel1UniqueInput;
}>;


export type WarehouseLevel1DeleteMutation = { __typename?: 'Mutation', WarehouseLevel1Delete: { __typename?: 'WarehouseLevel1', id: number, name: string } };

export type WarehouseLevel1UpdateMutationVariables = Exact<{
  data: WarehouseLevel1UpdateInput;
  uniqueInput: WarehouseLevel1UniqueInput;
}>;


export type WarehouseLevel1UpdateMutation = { __typename?: 'Mutation', WarehouseLevel1Update: { __typename?: 'WarehouseLevel1', id: number, name: string } };

export type WarehouseLevel2CreateMutationVariables = Exact<{
  data: WarehouseLevel2CreateInput;
}>;


export type WarehouseLevel2CreateMutation = { __typename?: 'Mutation', WarehouseLevel2Create: { __typename?: 'WarehouseLevel2', id: number, name: string } };

export type WarehouseLevel2DeleteMutationVariables = Exact<{
  uniqueInput: WarehouseLevel2UniqueInput;
}>;


export type WarehouseLevel2DeleteMutation = { __typename?: 'Mutation', WarehouseLevel2Delete: { __typename?: 'WarehouseLevel2', id: number, name: string } };

export type WarehouseLevel2UpdateMutationVariables = Exact<{
  uniqueInput: WarehouseLevel2UniqueInput;
  data: WarehouseLevel2UpdateInput;
}>;


export type WarehouseLevel2UpdateMutation = { __typename?: 'Mutation', WarehouseLevel2Update: { __typename?: 'WarehouseLevel2', id: number, name: string } };

export type WarehouseLevel3CreateMutationVariables = Exact<{
  data: WarehouseLevel3CreateInput;
}>;


export type WarehouseLevel3CreateMutation = { __typename?: 'Mutation', WarehouseLevel3Create: { __typename?: 'WarehouseLevel3', id: number, name: string } };

export type WarehouseLevel3DeleteMutationVariables = Exact<{
  uniqueInput: WarehouseLevel3UniqueInput;
}>;


export type WarehouseLevel3DeleteMutation = { __typename?: 'Mutation', WarehouseLevel3Delete: { __typename?: 'WarehouseLevel3', id: number, name: string } };

export type WarehouseLevel3UpdateMutationVariables = Exact<{
  uniqueInput: WarehouseLevel3UniqueInput;
  data: WarehouseLevel3UpdateInput;
}>;


export type WarehouseLevel3UpdateMutation = { __typename?: 'Mutation', WarehouseLevel3Update: { __typename?: 'WarehouseLevel3', id: number, name: string } };

export type WarehouseUpdateMutationVariables = Exact<{
  uniqueInput: WarehouseUniqueInput;
  data: WarehouseUpdateInput;
}>;


export type WarehouseUpdateMutation = { __typename?: 'Mutation', WarehouseUpdate: { __typename?: 'Warehouse', id: number, unique_id: string, name: string, warehouse_level_1_list?: Array<{ __typename?: 'WarehouseLevel1', id: number, name: string, warehouse_level_2_list?: Array<{ __typename?: 'WarehouseLevel2', id: number, name: string, warehouse_level_3_list?: Array<{ __typename?: 'WarehouseLevel3', id: number, name: string, bin_location_list?: Array<{ __typename?: 'BinLocation', name: string, id: number }> | null }> | null }> | null }> | null } };

export type WarehousesFindAllQueryVariables = Exact<{
  whereInput?: InputMaybe<WarehouseWhereInput>;
}>;


export type WarehousesFindAllQuery = { __typename?: 'Query', WarehousesFindAll?: Array<{ __typename?: 'Warehouse', id: number, unique_id: string, name: string, warehouse_level_1_list?: Array<{ __typename?: 'WarehouseLevel1', id: number, name: string, warehouse_level_2_list?: Array<{ __typename?: 'WarehouseLevel2', id: number, name: string, warehouse_level_3_list?: Array<{ __typename?: 'WarehouseLevel3', id: number, name: string, bin_location_list?: Array<{ __typename?: 'BinLocation', name: string, id: number }> | null }> | null }> | null }> | null }> | null };

export type TagCreateMutationVariables = Exact<{
  data: TagCreateInput;
}>;


export type TagCreateMutation = { __typename?: 'Mutation', TagCreate: { __typename?: 'Tag', id: number, name: string, document_type: TagDocumentType } };

export type TagDeleteMutationVariables = Exact<{
  uniqueInput: TagUniqueInput;
}>;


export type TagDeleteMutation = { __typename?: 'Mutation', TagDelete: { __typename?: 'Tag', id: number, name: string, document_type: TagDocumentType } };

export type TagsFindManyByDocumentTypeQueryVariables = Exact<{
  documentType?: InputMaybe<TagDocumentType>;
}>;


export type TagsFindManyByDocumentTypeQuery = { __typename?: 'Query', TagsFindManyByDocumentType: Array<{ __typename?: 'Tag', id: number, name: string, document_type: TagDocumentType }> };

export type NotificationVehicleFindQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationVehicleFindQuery = { __typename?: 'Query', NotificationVehicleFind?: { __typename?: 'NotificationVehicle', id?: number | null, vehicle_insurance_due_date?: number | null, product_insurance_due_date?: number | null, act_due_date?: number | null, maintenance_distance?: any | null } | null };

export type GeneralUniqueIdGenerateQueryVariables = Exact<{
  modelType: GeneralModelType;
}>;


export type GeneralUniqueIdGenerateQuery = { __typename?: 'Query', UniqueIdGenerate: string };

export type UserCreateMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type UserCreateMutation = { __typename?: 'Mutation', UserCreate?: { __typename?: 'User', id: number, unique_id: string, email: string, img_url?: Array<string> | null, title_name?: string | null, first_name: string, last_name: string, nick_name?: string | null, gender?: string | null, phone?: string | null, department?: string | null, position?: string | null, status: number, remark_status?: string | null, inactive_date?: any | null, created_date: any, created_by: any, last_updated_date?: any | null, last_updated_by?: any | null, role_permission_template?: any | null, last_login_date?: any | null, delivery_position_details?: { __typename?: 'DeliveryPositionDetails', dob?: any | null, identity_no?: string | null, license_type?: string | null, license_no?: string | null, license_expire_date?: any | null } | null, role_list?: Array<{ __typename?: 'Role', id: number, name: string, description?: string | null, created_date: any, created_by: any, last_updated_date?: any | null, last_updated_by?: any | null, role_permission?: any | null }> | null } | null };

export type UserDeleteMutationVariables = Exact<{
  uniqueInput: UserUniqueInput;
}>;


export type UserDeleteMutation = { __typename?: 'Mutation', UserDelete?: { __typename?: 'User', id: number, email: string } | null };

export type UserQueryVariables = Exact<{
  uniqueInput?: InputMaybe<UserUniqueInput>;
}>;


export type UserQuery = { __typename?: 'Query', UserFindUnique?: { __typename?: 'User', id: number, unique_id: string, email: string, img_url?: Array<string> | null, title_name?: string | null, first_name: string, last_name: string, nick_name?: string | null, gender?: string | null, phone?: string | null, department?: string | null, position?: string | null, status: number, remark_status?: string | null, inactive_date?: any | null, created_date: any, created_by: any, last_updated_date?: any | null, last_updated_by?: any | null, role_permission_template?: any | null, last_login_date?: any | null, delivery_position_details?: { __typename?: 'DeliveryPositionDetails', dob?: any | null, identity_no?: string | null, license_type?: string | null, license_no?: string | null, license_expire_date?: any | null } | null, role_list?: Array<{ __typename?: 'Role', id: number, name: string, description?: string | null, created_date: any, created_by: any, last_updated_date?: any | null, last_updated_by?: any | null, role_permission?: any | null }> | null } | null };

export type UserSelfUpdateMutationVariables = Exact<{
  data: UserUpdateInput;
  validatePassword?: InputMaybe<Scalars['String']['input']>;
}>;


export type UserSelfUpdateMutation = { __typename?: 'Mutation', UserSelfUpdate?: { __typename?: 'User', id: number, unique_id: string, email: string, img_url?: Array<string> | null, title_name?: string | null, first_name: string, last_name: string, nick_name?: string | null, gender?: string | null, phone?: string | null, department?: string | null, position?: string | null, status: number, remark_status?: string | null, inactive_date?: any | null, created_date: any, created_by: any, last_updated_date?: any | null, last_updated_by?: any | null, role_permission_template?: any | null, last_login_date?: any | null, delivery_position_details?: { __typename?: 'DeliveryPositionDetails', dob?: any | null, identity_no?: string | null, license_type?: string | null, license_no?: string | null, license_expire_date?: any | null } | null, role_list?: Array<{ __typename?: 'Role', id: number, name: string, description?: string | null, created_date: any, created_by: any, last_updated_date?: any | null, last_updated_by?: any | null, role_permission?: any | null }> | null } | null };

export type UserUpdateMutationVariables = Exact<{
  data: UserUpdateInput;
  uniqueInput: UserUniqueInput;
}>;


export type UserUpdateMutation = { __typename?: 'Mutation', UserUpdate?: { __typename?: 'User', id: number, unique_id: string, email: string, img_url?: Array<string> | null, title_name?: string | null, first_name: string, last_name: string, nick_name?: string | null, gender?: string | null, phone?: string | null, department?: string | null, position?: string | null, status: number, remark_status?: string | null, inactive_date?: any | null, created_date: any, created_by: any, last_updated_date?: any | null, last_updated_by?: any | null, role_permission_template?: any | null, last_login_date?: any | null, delivery_position_details?: { __typename?: 'DeliveryPositionDetails', dob?: any | null, identity_no?: string | null, license_type?: string | null, license_no?: string | null, license_expire_date?: any | null } | null, role_list?: Array<{ __typename?: 'Role', id: number, name: string, description?: string | null, created_date: any, created_by: any, last_updated_date?: any | null, last_updated_by?: any | null, role_permission?: any | null }> | null } | null };


export const ActivityLogCreateDocument = `
    mutation ActivityLogCreate($data: ActivityLogCreateInput!) {
  ActivityLogCreate(data: $data) {
    id
    activity_type
    activity_detail {
      message
      attachment_list
      prev_status
      curr_status
      added_related_employee {
        user_unique_id
        first_name
        last_name
      }
      deleted_related_employee {
        user_unique_id
        first_name
        last_name
      }
      updated_fields
      copied_from {
        id
        unique_id
      }
      copied_to {
        id
        unique_id
      }
    }
    reference_id
    document_type
    created_date
    created_by {
      user_id
      user_unique_id
      first_name
      last_name
      email
      img_url
    }
  }
}
    `;
export const useActivityLogCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ActivityLogCreateMutation, TError, ActivityLogCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ActivityLogCreateMutation, TError, ActivityLogCreateMutationVariables, TContext>(
      ['ActivityLogCreate'],
      (variables?: ActivityLogCreateMutationVariables) => fetcher<ActivityLogCreateMutation, ActivityLogCreateMutationVariables>(client, ActivityLogCreateDocument, variables, headers)(),
      options
    );
export const ActivityLogFindManyByReferenceIdDocument = `
    query ActivityLogFindManyByReferenceId($findManyInput: ActivityLogByRefereceIdInput!) {
  ActivityLogFindManyByReferenceId(findManyInput: $findManyInput) {
    id
    activity_type
    activity_detail {
      message
      attachment_list
      prev_status
      curr_status
      added_related_employee {
        user_unique_id
        first_name
        last_name
      }
      deleted_related_employee {
        user_unique_id
        first_name
        last_name
      }
      updated_fields
      copied_from {
        id
        unique_id
      }
      copied_to {
        id
        unique_id
      }
    }
    reference_id
    document_type
    created_date
    created_by {
      user_id
      user_unique_id
      first_name
      last_name
      email
      img_url
    }
  }
}
    `;
export const useActivityLogFindManyByReferenceIdQuery = <
      TData = ActivityLogFindManyByReferenceIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: ActivityLogFindManyByReferenceIdQueryVariables,
      options?: UseQueryOptions<ActivityLogFindManyByReferenceIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ActivityLogFindManyByReferenceIdQuery, TError, TData>(
      ['ActivityLogFindManyByReferenceId', variables],
      fetcher<ActivityLogFindManyByReferenceIdQuery, ActivityLogFindManyByReferenceIdQueryVariables>(client, ActivityLogFindManyByReferenceIdDocument, variables, headers),
      options
    );
export const LoginDocument = `
    mutation Login($loginInput: LoginInput) {
  login(loginInput: $loginInput) {
    access_token
    user {
      id
      unique_id
      email
      img_url
      title_name
      first_name
      last_name
      nick_name
      gender
      phone
      department
      position
      status
      remark_status
      inactive_date
      created_date
      created_by
      last_updated_date
      last_updated_by
      role_list {
        id
        name
        description
        created_date
        created_by
        last_updated_date
        last_updated_by
        role_permission
      }
      role_permission_template
      last_login_date
    }
  }
}
    `;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      ['Login'],
      (variables?: LoginMutationVariables) => fetcher<LoginMutation, LoginMutationVariables>(client, LoginDocument, variables, headers)(),
      options
    );
export const ValidateTokenDocument = `
    query ValidateToken {
  validateToken {
    id
    unique_id
    email
    img_url
    title_name
    first_name
    last_name
    nick_name
    gender
    phone
    department
    position
    status
    remark_status
    inactive_date
    created_date
    created_by
    last_updated_date
    last_updated_by
    role_list {
      id
      name
      description
      created_date
      created_by
      last_updated_date
      last_updated_by
      role_permission
    }
    role_permission_template
    last_login_date
  }
}
    `;
export const useValidateTokenQuery = <
      TData = ValidateTokenQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ValidateTokenQueryVariables,
      options?: UseQueryOptions<ValidateTokenQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ValidateTokenQuery, TError, TData>(
      variables === undefined ? ['ValidateToken'] : ['ValidateToken', variables],
      fetcher<ValidateTokenQuery, ValidateTokenQueryVariables>(client, ValidateTokenDocument, variables, headers),
      options
    );
export const DashboardInventoryDocument = `
    query DashboardInventory($input: DashboardInventoryInput) {
  DashboardInventory(input: $input) {
    current_stock {
      normal
      over
      ordering
    }
    goods_receive {
      wait_receive
      finished
      cancelled
      draft
    }
    goods_issue {
      finished
      cancelled
      draft
    }
    goods_transfer {
      wait_transfer
      finished
      cancelled
      draft
    }
    goods_adjust {
      finished
      cancelled
      draft
    }
  }
}
    `;
export const useDashboardInventoryQuery = <
      TData = DashboardInventoryQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: DashboardInventoryQueryVariables,
      options?: UseQueryOptions<DashboardInventoryQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<DashboardInventoryQuery, TError, TData>(
      variables === undefined ? ['DashboardInventory'] : ['DashboardInventory', variables],
      fetcher<DashboardInventoryQuery, DashboardInventoryQueryVariables>(client, DashboardInventoryDocument, variables, headers),
      options
    );
export const DashboardLogisticDocument = `
    query DashboardLogistic($input: DashboardLogisticInput) {
  DashboardLogistic(input: $input) {
    vehicle {
      approaching
      overdue
    }
    delivery {
      delivering
      late
      wait_deliver
      cancelled
      draft
      finished
    }
    maintenance {
      wait_maintenance
      maintenancing
      cancelled
      draft
      finished
    }
  }
}
    `;
export const useDashboardLogisticQuery = <
      TData = DashboardLogisticQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: DashboardLogisticQueryVariables,
      options?: UseQueryOptions<DashboardLogisticQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<DashboardLogisticQuery, TError, TData>(
      variables === undefined ? ['DashboardLogistic'] : ['DashboardLogistic', variables],
      fetcher<DashboardLogisticQuery, DashboardLogisticQueryVariables>(client, DashboardLogisticDocument, variables, headers),
      options
    );
export const DashboardPurchaseDocument = `
    query DashboardPurchase($input: DashboardPurchaseInput) {
  DashboardPurchase(input: $input) {
    purchase_order {
      wait_ordered
      fully_ordered
      partially_imported
      fully_imported
      cancelled
      draft
    }
    purchase_return {
      draft
      finished
      wait_returned
      cancelled
    }
  }
}
    `;
export const useDashboardPurchaseQuery = <
      TData = DashboardPurchaseQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: DashboardPurchaseQueryVariables,
      options?: UseQueryOptions<DashboardPurchaseQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<DashboardPurchaseQuery, TError, TData>(
      variables === undefined ? ['DashboardPurchase'] : ['DashboardPurchase', variables],
      fetcher<DashboardPurchaseQuery, DashboardPurchaseQueryVariables>(client, DashboardPurchaseDocument, variables, headers),
      options
    );
export const DashboardSalesDocument = `
    query DashboardSales($input: DashboardSalesInput) {
  DashboardSales(input: $input) {
    quotation {
      wait_accept
      accepted
      not_accepted
      cancelled
      draft
      finished
    }
    billing_notes {
      wait_payment
      fully_payment
      cancelled
      draft
    }
  }
}
    `;
export const useDashboardSalesQuery = <
      TData = DashboardSalesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: DashboardSalesQueryVariables,
      options?: UseQueryOptions<DashboardSalesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<DashboardSalesQuery, TError, TData>(
      variables === undefined ? ['DashboardSales'] : ['DashboardSales', variables],
      fetcher<DashboardSalesQuery, DashboardSalesQueryVariables>(client, DashboardSalesDocument, variables, headers),
      options
    );
export const CompanyCreateDocument = `
    mutation CompanyCreate($data: CompanyCreateInput!) {
  CompanyCreate(data: $data) {
    id
    unique_id
    name
    type
    identity_no
    register_date
    img_url
    is_registered_vat
    vat_registration_date
    contact_channel_list {
      contact_channel_type
      contact_channel_info
    }
    address_list {
      is_default
      is_same_as_default_address
      address_type
      address_contact_name
      address_contact_phone
      address_contact_fax
      name
      sub_district
      district
      province
      postal_code
      country
    }
  }
}
    `;
export const useCompanyCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CompanyCreateMutation, TError, CompanyCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CompanyCreateMutation, TError, CompanyCreateMutationVariables, TContext>(
      ['CompanyCreate'],
      (variables?: CompanyCreateMutationVariables) => fetcher<CompanyCreateMutation, CompanyCreateMutationVariables>(client, CompanyCreateDocument, variables, headers)(),
      options
    );
export const CompanyFindUniqueDocument = `
    query CompanyFindUnique($uniqueInput: CompanyUniqueInput) {
  CompanyFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name
    type
    identity_no
    register_date
    img_url
    is_registered_vat
    vat_registration_date
    contact_channel_list {
      contact_channel_type
      contact_channel_info
    }
    address_list {
      is_default
      is_same_as_default_address
      address_type
      address_contact_name
      address_contact_phone
      address_contact_fax
      name
      sub_district
      district
      province
      postal_code
      country
    }
  }
}
    `;
export const useCompanyFindUniqueQuery = <
      TData = CompanyFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: CompanyFindUniqueQueryVariables,
      options?: UseQueryOptions<CompanyFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CompanyFindUniqueQuery, TError, TData>(
      variables === undefined ? ['CompanyFindUnique'] : ['CompanyFindUnique', variables],
      fetcher<CompanyFindUniqueQuery, CompanyFindUniqueQueryVariables>(client, CompanyFindUniqueDocument, variables, headers),
      options
    );
export const CompanyUpdateDocument = `
    mutation CompanyUpdate($uniqueInput: CompanyUniqueInput!, $data: CompanyUpdateInput!) {
  CompanyUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name
    type
    identity_no
    register_date
    img_url
    is_registered_vat
    vat_registration_date
    contact_channel_list {
      contact_channel_type
      contact_channel_info
    }
    address_list {
      is_default
      is_same_as_default_address
      address_type
      address_contact_name
      address_contact_phone
      address_contact_fax
      name
      sub_district
      district
      province
      postal_code
      country
    }
  }
}
    `;
export const useCompanyUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CompanyUpdateMutation, TError, CompanyUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CompanyUpdateMutation, TError, CompanyUpdateMutationVariables, TContext>(
      ['CompanyUpdate'],
      (variables?: CompanyUpdateMutationVariables) => fetcher<CompanyUpdateMutation, CompanyUpdateMutationVariables>(client, CompanyUpdateDocument, variables, headers)(),
      options
    );
export const CreatableFieldCreateDocument = `
    mutation CreatableFieldCreate($data: CreatableFieldCreateInput!) {
  CreatableFieldCreate(data: $data) {
    id
    creatable_fields_name
    document_type
    name
  }
}
    `;
export const useCreatableFieldCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreatableFieldCreateMutation, TError, CreatableFieldCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreatableFieldCreateMutation, TError, CreatableFieldCreateMutationVariables, TContext>(
      ['CreatableFieldCreate'],
      (variables?: CreatableFieldCreateMutationVariables) => fetcher<CreatableFieldCreateMutation, CreatableFieldCreateMutationVariables>(client, CreatableFieldCreateDocument, variables, headers)(),
      options
    );
export const CreatableFieldDeleteDocument = `
    mutation CreatableFieldDelete($uniqueInput: CreatableFieldUniqueInput!) {
  CreatableFieldDelete(uniqueInput: $uniqueInput) {
    name
  }
}
    `;
export const useCreatableFieldDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreatableFieldDeleteMutation, TError, CreatableFieldDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreatableFieldDeleteMutation, TError, CreatableFieldDeleteMutationVariables, TContext>(
      ['CreatableFieldDelete'],
      (variables?: CreatableFieldDeleteMutationVariables) => fetcher<CreatableFieldDeleteMutation, CreatableFieldDeleteMutationVariables>(client, CreatableFieldDeleteDocument, variables, headers)(),
      options
    );
export const CreatableFieldsDocument = `
    query CreatableFields($findManyInput: CreatableFieldFindManyInput) {
  CreatableFields(findManyInput: $findManyInput) {
    creatable_fields_name
    document_type
    id
    name
  }
}
    `;
export const useCreatableFieldsQuery = <
      TData = CreatableFieldsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: CreatableFieldsQueryVariables,
      options?: UseQueryOptions<CreatableFieldsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CreatableFieldsQuery, TError, TData>(
      variables === undefined ? ['CreatableFields'] : ['CreatableFields', variables],
      fetcher<CreatableFieldsQuery, CreatableFieldsQueryVariables>(client, CreatableFieldsDocument, variables, headers),
      options
    );
export const InventorySettingCreateDocument = `
    mutation InventorySettingCreate($createInput: InventorySettingCreateInput!) {
  InventorySettingCreate(createInput: $createInput) {
    id
    name
    type
  }
}
    `;
export const useInventorySettingCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InventorySettingCreateMutation, TError, InventorySettingCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<InventorySettingCreateMutation, TError, InventorySettingCreateMutationVariables, TContext>(
      ['InventorySettingCreate'],
      (variables?: InventorySettingCreateMutationVariables) => fetcher<InventorySettingCreateMutation, InventorySettingCreateMutationVariables>(client, InventorySettingCreateDocument, variables, headers)(),
      options
    );
export const InventorySettingsByTypeDocument = `
    query InventorySettingsByType($type: InventorySettingType!) {
  InventorySettingsByType(type: $type) {
    id
    name
    type
  }
}
    `;
export const useInventorySettingsByTypeQuery = <
      TData = InventorySettingsByTypeQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: InventorySettingsByTypeQueryVariables,
      options?: UseQueryOptions<InventorySettingsByTypeQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<InventorySettingsByTypeQuery, TError, TData>(
      ['InventorySettingsByType', variables],
      fetcher<InventorySettingsByTypeQuery, InventorySettingsByTypeQueryVariables>(client, InventorySettingsByTypeDocument, variables, headers),
      options
    );
export const InventorySettingFindUniqueDocument = `
    query InventorySettingFindUnique($uniqueInput: InventorySettingUniqueInput!) {
  InventorySettingFindUnique(uniqueInput: $uniqueInput) {
    id
    name
    type
  }
}
    `;
export const useInventorySettingFindUniqueQuery = <
      TData = InventorySettingFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: InventorySettingFindUniqueQueryVariables,
      options?: UseQueryOptions<InventorySettingFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<InventorySettingFindUniqueQuery, TError, TData>(
      ['InventorySettingFindUnique', variables],
      fetcher<InventorySettingFindUniqueQuery, InventorySettingFindUniqueQueryVariables>(client, InventorySettingFindUniqueDocument, variables, headers),
      options
    );
export const InventorySettingUpdateDocument = `
    mutation InventorySettingUpdate($uniqueInput: InventorySettingUniqueInput!, $updateInput: InventorySettingUpdateInput!) {
  InventorySettingUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
    id
    name
    type
  }
}
    `;
export const useInventorySettingUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InventorySettingUpdateMutation, TError, InventorySettingUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<InventorySettingUpdateMutation, TError, InventorySettingUpdateMutationVariables, TContext>(
      ['InventorySettingUpdate'],
      (variables?: InventorySettingUpdateMutationVariables) => fetcher<InventorySettingUpdateMutation, InventorySettingUpdateMutationVariables>(client, InventorySettingUpdateDocument, variables, headers)(),
      options
    );
export const InventorySettingDeleteDocument = `
    mutation InventorySettingDelete($uniqueInput: InventorySettingUniqueInput!) {
  InventorySettingDelete(uniqueInput: $uniqueInput) {
    id
    name
    type
  }
}
    `;
export const useInventorySettingDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InventorySettingDeleteMutation, TError, InventorySettingDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<InventorySettingDeleteMutation, TError, InventorySettingDeleteMutationVariables, TContext>(
      ['InventorySettingDelete'],
      (variables?: InventorySettingDeleteMutationVariables) => fetcher<InventorySettingDeleteMutation, InventorySettingDeleteMutationVariables>(client, InventorySettingDeleteDocument, variables, headers)(),
      options
    );
export const BinLocationCreateDocument = `
    mutation BinLocationCreate($data: BinLocationCreateInput!) {
  BinLocationCreate(data: $data) {
    id
    name
  }
}
    `;
export const useBinLocationCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BinLocationCreateMutation, TError, BinLocationCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BinLocationCreateMutation, TError, BinLocationCreateMutationVariables, TContext>(
      ['BinLocationCreate'],
      (variables?: BinLocationCreateMutationVariables) => fetcher<BinLocationCreateMutation, BinLocationCreateMutationVariables>(client, BinLocationCreateDocument, variables, headers)(),
      options
    );
export const BinLocationDeleteDocument = `
    mutation BinLocationDelete($uniqueInput: BinLocationUniqueInput!) {
  BinLocationDelete(uniqueInput: $uniqueInput) {
    id
    name
  }
}
    `;
export const useBinLocationDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BinLocationDeleteMutation, TError, BinLocationDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BinLocationDeleteMutation, TError, BinLocationDeleteMutationVariables, TContext>(
      ['BinLocationDelete'],
      (variables?: BinLocationDeleteMutationVariables) => fetcher<BinLocationDeleteMutation, BinLocationDeleteMutationVariables>(client, BinLocationDeleteDocument, variables, headers)(),
      options
    );
export const BinLocationsFindAllDocument = `
    query BinLocationsFindAll {
  BinLocationsFindAll {
    id
    name
    warehouse_level_3 {
      id
      name
      warehouse_level_2 {
        id
        name
        warehouse_level_1 {
          id
          name
          warehouse {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export const useBinLocationsFindAllQuery = <
      TData = BinLocationsFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: BinLocationsFindAllQueryVariables,
      options?: UseQueryOptions<BinLocationsFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<BinLocationsFindAllQuery, TError, TData>(
      variables === undefined ? ['BinLocationsFindAll'] : ['BinLocationsFindAll', variables],
      fetcher<BinLocationsFindAllQuery, BinLocationsFindAllQueryVariables>(client, BinLocationsFindAllDocument, variables, headers),
      options
    );
export const BinLocationUpdateDocument = `
    mutation BinLocationUpdate($uniqueInput: BinLocationUniqueInput!, $data: BinLocationUpdateInput!) {
  BinLocationUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    name
  }
}
    `;
export const useBinLocationUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<BinLocationUpdateMutation, TError, BinLocationUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<BinLocationUpdateMutation, TError, BinLocationUpdateMutationVariables, TContext>(
      ['BinLocationUpdate'],
      (variables?: BinLocationUpdateMutationVariables) => fetcher<BinLocationUpdateMutation, BinLocationUpdateMutationVariables>(client, BinLocationUpdateDocument, variables, headers)(),
      options
    );
export const WarehouseFindUniqueDocument = `
    query WarehouseFindUnique($uniqueInput: WarehouseUniqueInput!) {
  WarehouseFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    name
    warehouse_level_1_list {
      id
      name
      warehouse_level_2_list {
        id
        name
        warehouse_level_3_list {
          id
          name
          bin_location_list {
            name
            id
          }
        }
      }
    }
  }
}
    `;
export const useWarehouseFindUniqueQuery = <
      TData = WarehouseFindUniqueQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: WarehouseFindUniqueQueryVariables,
      options?: UseQueryOptions<WarehouseFindUniqueQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<WarehouseFindUniqueQuery, TError, TData>(
      ['WarehouseFindUnique', variables],
      fetcher<WarehouseFindUniqueQuery, WarehouseFindUniqueQueryVariables>(client, WarehouseFindUniqueDocument, variables, headers),
      options
    );
export const WarehouseCreateDocument = `
    mutation WarehouseCreate($data: WarehouseCreateInput!) {
  WarehouseCreate(data: $data) {
    id
    unique_id
    name
    warehouse_level_1_list {
      id
      name
      warehouse_level_2_list {
        id
        name
        warehouse_level_3_list {
          id
          name
          bin_location_list {
            name
            id
          }
        }
      }
    }
  }
}
    `;
export const useWarehouseCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseCreateMutation, TError, WarehouseCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseCreateMutation, TError, WarehouseCreateMutationVariables, TContext>(
      ['WarehouseCreate'],
      (variables?: WarehouseCreateMutationVariables) => fetcher<WarehouseCreateMutation, WarehouseCreateMutationVariables>(client, WarehouseCreateDocument, variables, headers)(),
      options
    );
export const WarehouseDeleteDocument = `
    mutation WarehouseDelete($uniqueInput: WarehouseUniqueInput!) {
  WarehouseDelete(uniqueInput: $uniqueInput) {
    id
    unique_id
    name
  }
}
    `;
export const useWarehouseDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseDeleteMutation, TError, WarehouseDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseDeleteMutation, TError, WarehouseDeleteMutationVariables, TContext>(
      ['WarehouseDelete'],
      (variables?: WarehouseDeleteMutationVariables) => fetcher<WarehouseDeleteMutation, WarehouseDeleteMutationVariables>(client, WarehouseDeleteDocument, variables, headers)(),
      options
    );
export const WarehouseLevel1CreateDocument = `
    mutation WarehouseLevel1Create($data: WarehouseLevel1CreateInput!) {
  WarehouseLevel1Create(data: $data) {
    id
    name
  }
}
    `;
export const useWarehouseLevel1CreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel1CreateMutation, TError, WarehouseLevel1CreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel1CreateMutation, TError, WarehouseLevel1CreateMutationVariables, TContext>(
      ['WarehouseLevel1Create'],
      (variables?: WarehouseLevel1CreateMutationVariables) => fetcher<WarehouseLevel1CreateMutation, WarehouseLevel1CreateMutationVariables>(client, WarehouseLevel1CreateDocument, variables, headers)(),
      options
    );
export const WarehouseLevel1DeleteDocument = `
    mutation WarehouseLevel1Delete($uniqueInput: WarehouseLevel1UniqueInput!) {
  WarehouseLevel1Delete(uniqueInput: $uniqueInput) {
    id
    name
  }
}
    `;
export const useWarehouseLevel1DeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel1DeleteMutation, TError, WarehouseLevel1DeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel1DeleteMutation, TError, WarehouseLevel1DeleteMutationVariables, TContext>(
      ['WarehouseLevel1Delete'],
      (variables?: WarehouseLevel1DeleteMutationVariables) => fetcher<WarehouseLevel1DeleteMutation, WarehouseLevel1DeleteMutationVariables>(client, WarehouseLevel1DeleteDocument, variables, headers)(),
      options
    );
export const WarehouseLevel1UpdateDocument = `
    mutation WarehouseLevel1Update($data: WarehouseLevel1UpdateInput!, $uniqueInput: WarehouseLevel1UniqueInput!) {
  WarehouseLevel1Update(data: $data, uniqueInput: $uniqueInput) {
    id
    name
  }
}
    `;
export const useWarehouseLevel1UpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel1UpdateMutation, TError, WarehouseLevel1UpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel1UpdateMutation, TError, WarehouseLevel1UpdateMutationVariables, TContext>(
      ['WarehouseLevel1Update'],
      (variables?: WarehouseLevel1UpdateMutationVariables) => fetcher<WarehouseLevel1UpdateMutation, WarehouseLevel1UpdateMutationVariables>(client, WarehouseLevel1UpdateDocument, variables, headers)(),
      options
    );
export const WarehouseLevel2CreateDocument = `
    mutation WarehouseLevel2Create($data: WarehouseLevel2CreateInput!) {
  WarehouseLevel2Create(data: $data) {
    id
    name
  }
}
    `;
export const useWarehouseLevel2CreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel2CreateMutation, TError, WarehouseLevel2CreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel2CreateMutation, TError, WarehouseLevel2CreateMutationVariables, TContext>(
      ['WarehouseLevel2Create'],
      (variables?: WarehouseLevel2CreateMutationVariables) => fetcher<WarehouseLevel2CreateMutation, WarehouseLevel2CreateMutationVariables>(client, WarehouseLevel2CreateDocument, variables, headers)(),
      options
    );
export const WarehouseLevel2DeleteDocument = `
    mutation WarehouseLevel2Delete($uniqueInput: WarehouseLevel2UniqueInput!) {
  WarehouseLevel2Delete(uniqueInput: $uniqueInput) {
    id
    name
  }
}
    `;
export const useWarehouseLevel2DeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel2DeleteMutation, TError, WarehouseLevel2DeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel2DeleteMutation, TError, WarehouseLevel2DeleteMutationVariables, TContext>(
      ['WarehouseLevel2Delete'],
      (variables?: WarehouseLevel2DeleteMutationVariables) => fetcher<WarehouseLevel2DeleteMutation, WarehouseLevel2DeleteMutationVariables>(client, WarehouseLevel2DeleteDocument, variables, headers)(),
      options
    );
export const WarehouseLevel2UpdateDocument = `
    mutation WarehouseLevel2Update($uniqueInput: WarehouseLevel2UniqueInput!, $data: WarehouseLevel2UpdateInput!) {
  WarehouseLevel2Update(uniqueInput: $uniqueInput, data: $data) {
    id
    name
  }
}
    `;
export const useWarehouseLevel2UpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel2UpdateMutation, TError, WarehouseLevel2UpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel2UpdateMutation, TError, WarehouseLevel2UpdateMutationVariables, TContext>(
      ['WarehouseLevel2Update'],
      (variables?: WarehouseLevel2UpdateMutationVariables) => fetcher<WarehouseLevel2UpdateMutation, WarehouseLevel2UpdateMutationVariables>(client, WarehouseLevel2UpdateDocument, variables, headers)(),
      options
    );
export const WarehouseLevel3CreateDocument = `
    mutation WarehouseLevel3Create($data: WarehouseLevel3CreateInput!) {
  WarehouseLevel3Create(data: $data) {
    id
    name
  }
}
    `;
export const useWarehouseLevel3CreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel3CreateMutation, TError, WarehouseLevel3CreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel3CreateMutation, TError, WarehouseLevel3CreateMutationVariables, TContext>(
      ['WarehouseLevel3Create'],
      (variables?: WarehouseLevel3CreateMutationVariables) => fetcher<WarehouseLevel3CreateMutation, WarehouseLevel3CreateMutationVariables>(client, WarehouseLevel3CreateDocument, variables, headers)(),
      options
    );
export const WarehouseLevel3DeleteDocument = `
    mutation WarehouseLevel3Delete($uniqueInput: WarehouseLevel3UniqueInput!) {
  WarehouseLevel3Delete(uniqueInput: $uniqueInput) {
    id
    name
  }
}
    `;
export const useWarehouseLevel3DeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel3DeleteMutation, TError, WarehouseLevel3DeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel3DeleteMutation, TError, WarehouseLevel3DeleteMutationVariables, TContext>(
      ['WarehouseLevel3Delete'],
      (variables?: WarehouseLevel3DeleteMutationVariables) => fetcher<WarehouseLevel3DeleteMutation, WarehouseLevel3DeleteMutationVariables>(client, WarehouseLevel3DeleteDocument, variables, headers)(),
      options
    );
export const WarehouseLevel3UpdateDocument = `
    mutation WarehouseLevel3Update($uniqueInput: WarehouseLevel3UniqueInput!, $data: WarehouseLevel3UpdateInput!) {
  WarehouseLevel3Update(uniqueInput: $uniqueInput, data: $data) {
    id
    name
  }
}
    `;
export const useWarehouseLevel3UpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseLevel3UpdateMutation, TError, WarehouseLevel3UpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseLevel3UpdateMutation, TError, WarehouseLevel3UpdateMutationVariables, TContext>(
      ['WarehouseLevel3Update'],
      (variables?: WarehouseLevel3UpdateMutationVariables) => fetcher<WarehouseLevel3UpdateMutation, WarehouseLevel3UpdateMutationVariables>(client, WarehouseLevel3UpdateDocument, variables, headers)(),
      options
    );
export const WarehouseUpdateDocument = `
    mutation WarehouseUpdate($uniqueInput: WarehouseUniqueInput!, $data: WarehouseUpdateInput!) {
  WarehouseUpdate(uniqueInput: $uniqueInput, data: $data) {
    id
    unique_id
    name
    warehouse_level_1_list {
      id
      name
      warehouse_level_2_list {
        id
        name
        warehouse_level_3_list {
          id
          name
          bin_location_list {
            name
            id
          }
        }
      }
    }
  }
}
    `;
export const useWarehouseUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WarehouseUpdateMutation, TError, WarehouseUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WarehouseUpdateMutation, TError, WarehouseUpdateMutationVariables, TContext>(
      ['WarehouseUpdate'],
      (variables?: WarehouseUpdateMutationVariables) => fetcher<WarehouseUpdateMutation, WarehouseUpdateMutationVariables>(client, WarehouseUpdateDocument, variables, headers)(),
      options
    );
export const WarehousesFindAllDocument = `
    query WarehousesFindAll($whereInput: WarehouseWhereInput) {
  WarehousesFindAll(whereInput: $whereInput) {
    id
    unique_id
    name
    warehouse_level_1_list {
      id
      name
      warehouse_level_2_list {
        id
        name
        warehouse_level_3_list {
          id
          name
          bin_location_list {
            name
            id
          }
        }
      }
    }
  }
}
    `;
export const useWarehousesFindAllQuery = <
      TData = WarehousesFindAllQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: WarehousesFindAllQueryVariables,
      options?: UseQueryOptions<WarehousesFindAllQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<WarehousesFindAllQuery, TError, TData>(
      variables === undefined ? ['WarehousesFindAll'] : ['WarehousesFindAll', variables],
      fetcher<WarehousesFindAllQuery, WarehousesFindAllQueryVariables>(client, WarehousesFindAllDocument, variables, headers),
      options
    );
export const TagCreateDocument = `
    mutation TagCreate($data: TagCreateInput!) {
  TagCreate(data: $data) {
    id
    name
    document_type
  }
}
    `;
export const useTagCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<TagCreateMutation, TError, TagCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<TagCreateMutation, TError, TagCreateMutationVariables, TContext>(
      ['TagCreate'],
      (variables?: TagCreateMutationVariables) => fetcher<TagCreateMutation, TagCreateMutationVariables>(client, TagCreateDocument, variables, headers)(),
      options
    );
export const TagDeleteDocument = `
    mutation TagDelete($uniqueInput: TagUniqueInput!) {
  TagDelete(uniqueInput: $uniqueInput) {
    id
    name
    document_type
  }
}
    `;
export const useTagDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<TagDeleteMutation, TError, TagDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<TagDeleteMutation, TError, TagDeleteMutationVariables, TContext>(
      ['TagDelete'],
      (variables?: TagDeleteMutationVariables) => fetcher<TagDeleteMutation, TagDeleteMutationVariables>(client, TagDeleteDocument, variables, headers)(),
      options
    );
export const TagsFindManyByDocumentTypeDocument = `
    query TagsFindManyByDocumentType($documentType: TagDocumentType) {
  TagsFindManyByDocumentType(documentType: $documentType) {
    id
    name
    document_type
  }
}
    `;
export const useTagsFindManyByDocumentTypeQuery = <
      TData = TagsFindManyByDocumentTypeQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: TagsFindManyByDocumentTypeQueryVariables,
      options?: UseQueryOptions<TagsFindManyByDocumentTypeQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<TagsFindManyByDocumentTypeQuery, TError, TData>(
      variables === undefined ? ['TagsFindManyByDocumentType'] : ['TagsFindManyByDocumentType', variables],
      fetcher<TagsFindManyByDocumentTypeQuery, TagsFindManyByDocumentTypeQueryVariables>(client, TagsFindManyByDocumentTypeDocument, variables, headers),
      options
    );
export const NotificationVehicleFindDocument = `
    query NotificationVehicleFind {
  NotificationVehicleFind {
    id
    vehicle_insurance_due_date
    product_insurance_due_date
    act_due_date
    maintenance_distance
  }
}
    `;
export const useNotificationVehicleFindQuery = <
      TData = NotificationVehicleFindQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: NotificationVehicleFindQueryVariables,
      options?: UseQueryOptions<NotificationVehicleFindQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<NotificationVehicleFindQuery, TError, TData>(
      variables === undefined ? ['NotificationVehicleFind'] : ['NotificationVehicleFind', variables],
      fetcher<NotificationVehicleFindQuery, NotificationVehicleFindQueryVariables>(client, NotificationVehicleFindDocument, variables, headers),
      options
    );
export const GeneralUniqueIdGenerateDocument = `
    query GeneralUniqueIdGenerate($modelType: GeneralModelType!) {
  UniqueIdGenerate(modelType: $modelType)
}
    `;
export const useGeneralUniqueIdGenerateQuery = <
      TData = GeneralUniqueIdGenerateQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GeneralUniqueIdGenerateQueryVariables,
      options?: UseQueryOptions<GeneralUniqueIdGenerateQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GeneralUniqueIdGenerateQuery, TError, TData>(
      ['GeneralUniqueIdGenerate', variables],
      fetcher<GeneralUniqueIdGenerateQuery, GeneralUniqueIdGenerateQueryVariables>(client, GeneralUniqueIdGenerateDocument, variables, headers),
      options
    );
export const UserCreateDocument = `
    mutation UserCreate($data: UserCreateInput!) {
  UserCreate(data: $data) {
    id
    unique_id
    email
    img_url
    title_name
    first_name
    last_name
    nick_name
    gender
    phone
    department
    position
    status
    remark_status
    inactive_date
    created_date
    created_by
    delivery_position_details {
      dob
      identity_no
      license_type
      license_no
      license_expire_date
    }
    last_updated_date
    last_updated_by
    role_list {
      id
      name
      description
      created_date
      created_by
      last_updated_date
      last_updated_by
      role_permission
    }
    role_permission_template
    last_login_date
  }
}
    `;
export const useUserCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UserCreateMutation, TError, UserCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UserCreateMutation, TError, UserCreateMutationVariables, TContext>(
      ['UserCreate'],
      (variables?: UserCreateMutationVariables) => fetcher<UserCreateMutation, UserCreateMutationVariables>(client, UserCreateDocument, variables, headers)(),
      options
    );
export const UserDeleteDocument = `
    mutation UserDelete($uniqueInput: UserUniqueInput!) {
  UserDelete(uniqueInput: $uniqueInput) {
    id
    email
  }
}
    `;
export const useUserDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UserDeleteMutation, TError, UserDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UserDeleteMutation, TError, UserDeleteMutationVariables, TContext>(
      ['UserDelete'],
      (variables?: UserDeleteMutationVariables) => fetcher<UserDeleteMutation, UserDeleteMutationVariables>(client, UserDeleteDocument, variables, headers)(),
      options
    );
export const UserDocument = `
    query User($uniqueInput: UserUniqueInput) {
  UserFindUnique(uniqueInput: $uniqueInput) {
    id
    unique_id
    email
    img_url
    title_name
    first_name
    last_name
    nick_name
    gender
    phone
    department
    position
    status
    remark_status
    inactive_date
    created_date
    created_by
    delivery_position_details {
      dob
      identity_no
      license_type
      license_no
      license_expire_date
    }
    last_updated_date
    last_updated_by
    role_list {
      id
      name
      description
      created_date
      created_by
      last_updated_date
      last_updated_by
      role_permission
    }
    role_permission_template
    last_login_date
  }
}
    `;
export const useUserQuery = <
      TData = UserQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: UserQueryVariables,
      options?: UseQueryOptions<UserQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<UserQuery, TError, TData>(
      variables === undefined ? ['User'] : ['User', variables],
      fetcher<UserQuery, UserQueryVariables>(client, UserDocument, variables, headers),
      options
    );
export const UserSelfUpdateDocument = `
    mutation UserSelfUpdate($data: UserUpdateInput!, $validatePassword: String) {
  UserSelfUpdate(data: $data, validatePassword: $validatePassword) {
    id
    unique_id
    email
    img_url
    title_name
    first_name
    last_name
    nick_name
    gender
    phone
    department
    position
    status
    remark_status
    inactive_date
    created_date
    created_by
    delivery_position_details {
      dob
      identity_no
      license_type
      license_no
      license_expire_date
    }
    last_updated_date
    last_updated_by
    role_list {
      id
      name
      description
      created_date
      created_by
      last_updated_date
      last_updated_by
      role_permission
    }
    role_permission_template
    last_login_date
  }
}
    `;
export const useUserSelfUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UserSelfUpdateMutation, TError, UserSelfUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UserSelfUpdateMutation, TError, UserSelfUpdateMutationVariables, TContext>(
      ['UserSelfUpdate'],
      (variables?: UserSelfUpdateMutationVariables) => fetcher<UserSelfUpdateMutation, UserSelfUpdateMutationVariables>(client, UserSelfUpdateDocument, variables, headers)(),
      options
    );
export const UserUpdateDocument = `
    mutation UserUpdate($data: UserUpdateInput!, $uniqueInput: UserUniqueInput!) {
  UserUpdate(data: $data, uniqueInput: $uniqueInput) {
    id
    unique_id
    email
    img_url
    title_name
    first_name
    last_name
    nick_name
    gender
    phone
    department
    position
    status
    remark_status
    inactive_date
    created_date
    created_by
    delivery_position_details {
      dob
      identity_no
      license_type
      license_no
      license_expire_date
    }
    last_updated_date
    last_updated_by
    role_list {
      id
      name
      description
      created_date
      created_by
      last_updated_date
      last_updated_by
      role_permission
    }
    role_permission_template
    last_login_date
  }
}
    `;
export const useUserUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UserUpdateMutation, TError, UserUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UserUpdateMutation, TError, UserUpdateMutationVariables, TContext>(
      ['UserUpdate'],
      (variables?: UserUpdateMutationVariables) => fetcher<UserUpdateMutation, UserUpdateMutationVariables>(client, UserUpdateDocument, variables, headers)(),
      options
    );