import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IExporter, ISelectOption } from "../../../types/global";
import ControlledSelect from "../../Controller/ControlledSelect";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "./ModalUI";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  typeOptions: ISelectOption[];
  exportHandler: (data: IExporter) => void;
};

const ExporterVehicleModal = ({
  open,
  closeModalHandler,
  typeOptions,
  exportHandler,
}: Props) => {
  const exporterSchena: IExporter = {
    type: typeOptions[0].value,
    export_type: "all",
    start_date: null,
    end_date: null,
  };

  const exporterValidation = Yup.object().shape({
    type: Yup.string().required("กรุณาเลือกประเภทข้อมูล"),
  });

  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<IExporter>({
    defaultValues: exporterSchena,
    resolver: yupResolver<any>(exporterValidation),
  });

  const onClose = () => {
    reset(exporterSchena);
    closeModalHandler();
  };

  return (
    <ModalUI
      title={t("button.export")}
      open={open}
      handleClose={onClose}
      maxWidth={"sm"}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          <CustomizedButton
            title={t("button.confirm")}
            variant="contained"
            onClick={() => {
              handleSubmit(exportHandler)();
              if (isValid) {
                onClose();
              }
            }}
          />
        </Box>
      }
    >
      <Typography fontWeight={600} mb={2}>
        เลือกประเภทข้อมูล
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12}>
          <ControlledSelect
            name="type"
            control={control}
            label={"ข้อมูล"}
            error={Boolean(errors.type)}
            helperText={errors.type?.message?.toString()}
            options={typeOptions}
            required
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default ExporterVehicleModal;
