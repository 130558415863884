import { AgGridReact } from "ag-grid-react";
import { useStateContext } from "contexts/auth-context";
import { QuotationReportType } from "generated/sales";
import { GraphQLClient } from "graphql-request";
import { useSnackbar } from "notistack";
import { RefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { GENERATE_REPORT } from "services/Sales/Report/GenerateReport";
import { dateFilterModel } from "utils/AgGridFilter";

export const useQuotationReport = (
  gridRef: RefObject<AgGridReact<any>>,
  reportType: QuotationReportType
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const {
    state: { authUser },
  } = useStateContext();

  const onBtnExport = async () => {
    setIsLoading(true);
    try {
      const filterModel = gridRef?.current?.api.getFilterModel();
      const sortModel = gridRef?.current?.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));
      const formattedFilterModel = filterModel
        ? {
            ...filterModel,
            is_frozen: filterModel.is_frozen && {
              filterType: "setBoolean",
              values: filterModel.is_frozen.values.map(
                (v: string) => v === "true"
              ),
            },
            created_date: dateFilterModel(filterModel.created_date),
            issue_date: dateFilterModel(filterModel.issue_date),
            plan_start_date: dateFilterModel(filterModel.plan_start_date),
            plan_end_date: dateFilterModel(filterModel.plan_end_date),
          }
        : {};
      const params: any = {
        aggridInput: {
          filterModel: formattedFilterModel,
          sortModel,
        },
      };

      try {
        const { GenerateQuotationReport } =
          await graphQLClientWithHeaderSales.request(GENERATE_REPORT, {
            input: {
              report_type: reportType,
              user_unique_id: authUser?.unique_id || "",
              params: params,
            },
          });
        if (GenerateQuotationReport) {
          window.open(GenerateQuotationReport || "", "_blank");
          enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
            variant: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  return {
    onBtnExport,
    isLoading: isLoading,
  };
};
