import { Grid, Typography } from "@mui/material";
import ControlledDateTimePicker from "components/Controller/ControlledDateTimePicker";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { useDisable } from "hooks/use-disable";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledCheckbox from "components/Controller/ControlledCheckbox";
import ClaimAttachment from "./ClaimAttachment";

const Accident = () => {
  const { control } = useFormContext<IDeliveryTrip>();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const mainStatus = useWatch({
    control,
    name: "main_status",
  });
  const aggrid_status = useWatch({
    control,
    name: "aggrid_status",
  });
  const flagStatus = useWatch({
    control,
    name: "flag_status",
  });

  const cancelled = flagStatus.includes("cancelled");

  const status = !cancelled ? mainStatus : aggrid_status;

  const disabledStatus = ["finished"].includes(status);

  const isShow = ["finished", "cancelled"].includes(status);

  return (
    <CustomizedBox>
      <Typography fontWeight={600} mb={1}>
        {t("logistic.maintenance.accident")}
      </Typography>
      <Grid container spacing={1.5} mb={2}>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledDateTimePicker
            control={control}
            name="accident_date"
            label={t("logistic.maintenance.accident_date")}
            disabled={disabled || isShow}
            viewMode={disabled || disabledStatus}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            control={control}
            name="claim_no"
            label={t("logistic.maintenance.claim_no")}
            disabled={disabled || isShow}
            viewMode={disabled || disabledStatus}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1.5} mb={2}>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            control={control}
            name="accident_location"
            label={t("logistic.maintenance.accident_location")}
            disabled={disabled || isShow}
            viewMode={disabled || disabledStatus}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            control={control}
            name="accident_description"
            label={t("logistic.maintenance.accident_description")}
            disabled={disabled || isShow}
            viewMode={disabled || disabledStatus}
          />
        </Grid>
      </Grid>
      <Typography fontWeight={600} mb={1}>
        {t("logistic.maintenance.insurance_claim")}
      </Typography>
      <Grid container spacing={1.5} mb={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ControlledCheckbox
            control={control}
            name="is_insurance_fully_paid"
            label={t("logistic.maintenance.is_insurance_fully_paid")}
            disabled={disabled || disabledStatus}
          />
        </Grid>
      </Grid>
      <Typography fontWeight={600} mb={1}>
        {t("logistic.maintenance.claim_attachment")}
      </Typography>
      <ClaimAttachment />
    </CustomizedBox>
  );
};

export default Accident;
