import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import CustomizedLetterAvatar from "./CustomizedLetterAvatar";
import { CustomizedTooltip } from "./CustomizedTooltip";
import { IAvatar } from "../../types/global";
import { SxProps } from "@mui/material";

interface Props {
  avatars: IAvatar[];
  sx?: SxProps;
  noTooltip?: boolean;
  backgroundColor?: string;
}

const CustomizedAvatar = ({
  avatars,
  sx,
  noTooltip,
  backgroundColor,
}: Props) => {
  return (
    <AvatarGroup max={3} sx={{ justifyContent: "flex-end" }}>
      {avatars?.map((avatar: IAvatar, index: number) => {
        if (avatar.img_url && avatar.img_url.length > 0) {
          if (noTooltip) {
            return (
              <Avatar
                alt={`img${avatar.unique_id || avatar.user_unique_id}`}
                src={avatar.img_url}
                key={`img${
                  avatar.unique_id || avatar.user_unique_id || avatar
                }-${index}`}
                sx={sx}
              />
            );
          } else {
            return (
              <CustomizedTooltip
                title={`${avatar.first_name} ${avatar.last_name}`}
                key={`img${
                  avatar.unique_id || avatar.user_unique_id || avatar
                }-${index}`}
                backgroundColor={backgroundColor}
              >
                <Avatar
                  key={`img${
                    avatar.unique_id || avatar.user_unique_id || avatar
                  }-${index}`}
                  alt={`img${avatar.unique_id || avatar.user_unique_id}`}
                  src={avatar.img_url}
                  sx={sx}
                />
              </CustomizedTooltip>
            );
          }
        } else {
          return (
            <CustomizedLetterAvatar
              name={avatar.first_name + " " + avatar.last_name}
              key={`img${
                avatar.unique_id || avatar.user_unique_id || avatar
              }-${index}`}
              sx={sx}
              noTooltip={noTooltip}
            />
          );
        }
      })}
    </AvatarGroup>
  );
};

export default CustomizedAvatar;
