import dayjs from "dayjs";
import { IQuotation } from "types/Sales/quotation";
import { IUser } from "types/Auth/user";
import { v4 as uuidv4 } from "uuid";
import { quotationSchema } from "components/Form/Sales/Quotation/schema";
import { deliveryTripSchema } from "components/Form/Logistic/DeliveryTrip/schema";
import { SalesModelType } from "generated/sales";
import { IAttachment } from "types/global";
import { uploadFileToS3 } from "utils/s3";

export const quotationQueryFormatter = (data: any) => {
  const {
    created_date,
    issue_date,
    due_date,
    plan_start_date,
    plan_end_date,
    contact_unique_id,
    customer_details,
    item_list,
    accepted_date,
    ...otherData
  } = data;

  const formattedCustomer = {
    id: customer_details?.customer_id,
    unique_id: customer_details?.customer_unique_id,
    unique_id_name: `${contact_unique_id} - ${customer_details.customer_name}`,
    name: customer_details?.customer_name,
    identity_no: customer_details?.identity_no,
    email: customer_details?.email,
    phone: customer_details?.phone,
    fax: customer_details?.fax,
    billing_address: customer_details?.billing_address,
  };

  const formattedItemList = item_list?.map((item: any) => {
    return {
      ...item,
      uom: {
        id: item.uom_id,
        name: item.uom_name,
      },
    };
  });

  const formatted = {
    ...otherData,
    created_date: created_date ? dayjs(created_date) : undefined,
    issue_date: issue_date ? dayjs(issue_date) : undefined,
    due_date: due_date ? dayjs(due_date) : undefined,
    plan_start_date: plan_start_date ? dayjs(plan_start_date) : undefined,
    plan_end_date: plan_end_date ? dayjs(plan_end_date) : undefined,
    accepted_date: accepted_date ? dayjs(accepted_date) : undefined,
    customer_unique_id: contact_unique_id,
    customer_details: formattedCustomer,
    item_list: formattedItemList,
  } as IQuotation;

  return formatted;
};

export const quotationCreatePayloadFormatter = (
  data: IQuotation,
  status: string
) => {
  const {
    customer_details,
    customer_unique_id,
    created_date,
    last_updated_date,
    last_updated_by,
    aggrid_status,
    item_list,
    item_unique_id_list,
    ...otherData
  } = data;

  const formattedCustomer = {
    customer_id: customer_details?.id,
    customer_unique_id: customer_details?.unique_id,
    customer_name: customer_details?.name,
    identity_no: customer_details?.identity_no,
    email: customer_details?.email,
    phone: customer_details?.phone,
    fax: customer_details?.fax,
    billing_address: customer_details?.billing_address,
  };

  const formattedItemList = item_list?.map((item) => {
    return {
      ...item,
      uom: undefined,
      remark: undefined,
      pre_vat_amount: undefined,
      barcode: undefined,
    };
  });

  const formatted = {
    ...otherData,
    contact_unique_id: customer_unique_id,
    customer_details: formattedCustomer,
    item_list: formattedItemList,
    main_status: status,
    sub_status: status,
  };

  return formatted;
};

export const quotationUpdatePayloadFormatter = async (
  data: IQuotation,
  status: string
) => {
  const {
    id,
    unique_id,
    created_by,
    last_updated_date,
    customer_details,
    customer_unique_id,
    created_date,
    aggrid_status,
    item_list,
    item_unique_id_list,
    main_status,
    sub_status,
    flag_status,
    attachment_list,
    ...otherData
  } = data;

  const formattedCustomer = {
    customer_id: customer_details?.id,
    customer_unique_id: customer_details?.unique_id,
    customer_name: customer_details?.name,
    identity_no: customer_details?.identity_no,
    email: customer_details?.email,
    phone: customer_details?.phone,
    fax: customer_details?.fax,
    billing_address: customer_details?.billing_address,
  };

  const formattedItemList = item_list?.map((item) => {
    return {
      ...item,
      uom: undefined,
      remark: undefined,
      pre_vat_amount: undefined,
      barcode: undefined,
    };
  });

  let uploadedAttachment: IAttachment[] = [];
  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "quotation",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        uploadedAttachment.push(formatAttachment);
      } else {
        uploadedAttachment.push(file);
      }
    }
  }

  const formatted = {
    ...otherData,
    contact_unique_id: customer_unique_id,
    customer_details: formattedCustomer,
    item_list: formattedItemList,
    main_status: status,
    sub_status: status,
    flag_status: flag_status ?? [],
    attachment_list: uploadedAttachment,
  };

  return formatted;
};

export const copyQuotationFormatter = async (
  data: IQuotation,
  currentUser: IUser | null
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      due_date,
      plan_start_date,
      plan_end_date,
      accepted_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      ...otherData
    } = data;

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(({ id, unique_id, ...otherItemList }) => ({
            ...otherItemList,
            unique_id: uuidv4(),
          }))
        : [];

    return {
      ...quotationSchema,
      ...otherData,
      created_by: {
        user_unique_id: currentUser?.unique_id,
        email: currentUser?.email,
        first_name: currentUser?.first_name,
        last_name: currentUser?.last_name,
        img_url: currentUser?.img_url,
      },
      copied_id: id,
      copied_unique_id: unique_id,
      item_list: formatItemList,
      accepted_date: undefined,
      accepted_remark: "",
      attachment_list: [],
    };
  }
};

export const createDeliveryTripFromQuotation = (data: IQuotation) => {
  const {
    customer_unique_id,
    customer_details,
    item_list,
    item_type,
    item_value,
    is_frozen,
    packing_location,
    expected_temperature,
    container_type,
    related_user_list,
    plan_start_date,
    plan_end_date,
  } = data;
  const formatItemList =
    item_list && item_list.length > 0
      ? (item_list as any[]).map(
          ({
            id,
            item_id,
            reference_unique_id,
            unique_id,
            pre_discount_amount,
            post_discount_amount,
            uom,
            net_amount,
            total_amount,
            stock_qty,
            vat_amount,
            item_img_url,
            reference_document_type,
            vat_percentage,
            ...otherItemList
          }) => ({
            ...otherItemList,
            unique_id: uuidv4(),
            item_id,
            reference_unique_id: "",
            reference_line_item: {
              line_item_unique_id: unique_id,
              line_item_document_unique_id: data.unique_id || "",
              line_item_document_type: SalesModelType.Quotation,
            },
            pre_vat_amount: post_discount_amount,
            vat_percentage: formatVatPercentage(vat_percentage),
          })
        )
      : [];

  const {
    created_date,
    issue_date,
    created_by,
    customer_details: dtCustomerDetails,
    ...otherDeliveryTripTemplateData
  } = deliveryTripSchema;

  const formatted = {
    ...otherDeliveryTripTemplateData,
    related_user_list,
    customer_unique_id,
    customer_details: customer_details ?? dtCustomerDetails,
    reference_document_list: [
      {
        document_id: data.id,
        document_unique_id: data.unique_id,
        document_type: SalesModelType.Quotation,
      },
    ],
    item_type,
    is_frozen,
    expected_temperature,
    container_type,
    item_amount: item_value,
    packaging_location: packing_location,
    flag_status: [],
    work_list: [],
    income_list: formatItemList,
    expenses_list: [],
    plan_start_date: plan_start_date
      ? plan_start_date.toISOString()
      : undefined,
    plan_end_date: plan_end_date ? plan_end_date.toISOString() : undefined,
  };

  return formatted;
};

const formatVatPercentage = (vatPercentage: string) => {
  switch (vatPercentage) {
    case "ไม่มี": {
      return "ไม่มี";
    }
    case "0 %": {
      return "0";
    }
    case "7 %": {
      return "7";
    }
    default: {
      return vatPercentage;
    }
  }
};
