import { gql } from "graphql-request";

export const GOODS_TRANSFER_VIEW = gql`
  query GoodTransfersViewAggrid($aggridInput: AnyAggridInput!) {
    GoodTransfersViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        posted_date
        created_date
        source_warehouse_name
        destination_warehouse_name
        created_by
        remark
        aggrid_status
      }
    }
  }
`;
