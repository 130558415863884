import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { numberFormatter } from "utils/Formatter/Global";

export const useBillingNoteByCustomerReportColumnDefs = () => {
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        setColumnDefs([
            {
                field: "customer_unique_id",
                headerName: "รหัสลูกค้า",
                filter: "agTextColumnFilter",
                minWidth: 200,
                flex: 1,
            },
            {
                field: "customer_name",
                headerName: "ชื่อลูกค้า",
                filter: "agTextColumnFilter",
                minWidth: 200,
                flex: 1,
            },
            {
                field: "dt_document_amount",
                headerName: "จำนวนใบงานจัดส่ง",
                filter: "agNumberColumnFilter",
                cellStyle: { display: "flex", justifyContent: "flex-end" },
                headerClass: "ag-end-header",
                minWidth: 200,
                flex: 1,
            },
            {
                field: "income_net_amount",
                headerName: "มูลค่ารวมสุทธิ",
                filter: false,
                valueFormatter: (params: ValueFormatterParams) => {
                    if (params.value) return numberFormatter(params.value);
                    else return "0.00";
                },
                cellStyle: { display: "flex", justifyContent: "flex-end" },
                headerClass: "ag-end-header",
                minWidth: 200,
                flex: 1,
            },
            {
                field: "income_pre_vat_amount",
                headerName: "มูลค่าก่อนภาษี",
                filter: false,
                valueFormatter: (params: ValueFormatterParams) => {
                    if (params.value) return numberFormatter(params.value);
                    else return "0.00";
                },
                cellStyle: { display: "flex", justifyContent: "flex-end" },
                headerClass: "ag-end-header",
                minWidth: 200,
                flex: 1,
            },
            {
                field: "income_vat_amount",
                headerName: "ภาษีมูลค่าเพิ่ม",
                filter: false,
                valueFormatter: (params: ValueFormatterParams) => {
                    if (params.value) return numberFormatter(params.value);
                    else return "0.00";
                },
                cellStyle: { display: "flex", justifyContent: "flex-end" },
                headerClass: "ag-end-header",
                minWidth: 200,
                flex: 1,
            },
        ]);
    }, [t]);
    return columnDefs;
};