import {
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "components/UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

import { TIRE_VIEW } from "services/AgGrid/VehicleAgGrid";
import { dateFilterModel } from "utils/AgGridFilter";
import { useTireColumnDefs } from "./columnDefs";

type Props = {
  status: string | null;
};

const TireTable = ({ status }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const columnDefs = useTireColumnDefs();

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { created_date, created_by_object, ...otherFilter } = filterModel;

      const formatFilter = {
        ...otherFilter,
        created_by: created_by_object,
        created_date: dateFilterModel(created_date),
      };

      const formatSort = [
        ...sortModel,
        // { colId: "created_date", sort: "desc" },
      ];

      try {
        const { TireFindViewAggrid } = await graphQLClient.request(TIRE_VIEW, {
          aggridInput: {
            startRow: startRow,
            endRow: endRow,
            filterModel: formatFilter,
            sortModel: formatSort,
          },
        });

        params.success({
          rowData: TireFindViewAggrid.results as any[],
          rowCount: TireFindViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const statusInstance = params.api.getFilterInstance("status");
      switch (status) {
        case "stock":
          statusInstance?.setModel({
            values: ["stock"],
          });
          break;
        case "active":
          statusInstance?.setModel({ values: ["active"] });
          break;
        case "in_active":
          statusInstance?.setModel({ values: ["in_active"] });
          break;
        default:
          statusInstance?.setModel({
            values: ["active", "stock"],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [status]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/logistic/tire/${id}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onRowDoubleClicked={rowSelectHandler}
      onGridReady={onGridReady}
      path={`/logistic/tire`}
    />
  );
};

export default TireTable;
