import { Stack, Typography } from "@mui/material";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedButton from "components/Custom/CustomizedButton";
import VehicleHeader from "components/Form/Logistic/Vehicle/Header";
import InsuranceInfo from "components/Form/Logistic/Vehicle/InsuranceInfo";
import TireInfo from "components/Form/Logistic/Vehicle/TireInfo";
import VehicleImage from "components/Form/Logistic/Vehicle/VehicleImage";
import VehicleInfo from "components/Form/Logistic/Vehicle/VehicleInfo";
import LoadingUI from "components/UI/LoadingUI";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import { ActivityLogDocumentType, ActivityType } from "generated/sales";
import {
  VehicleCreateInput,
  VehicleCreateMutation,
  VehicleDeleteMutation,
  VehicleFindUniqueQuery,
  VehicleUpdateMutation,
  VehicleValidateInactivateQuery,
  useVehicleCreateMutation,
  useVehicleDeleteMutation,
  useVehicleFindUniqueQuery,
  useVehicleUpdateMutation,
  useVehicleValidateInactivateQuery,
  VehicleStatus,
} from "generated/vehicle";
import { useActivityLog } from "hooks/use-activity-log";
import { useDisable } from "hooks/use-disable";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IVehicle } from "types/Logistic/vehicle";
import {
  vehicleCreateFormatter,
  vehicleFormFormatter,
  vehicleQueryFormatter,
  vehicleUpdateFormatter,
} from "utils/Formatter/Logistic/Vehicle";
import { errorMessageFormatter } from "utils/Global";

const DocumentInfoTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [prevStatus, setPrevStatus] = useState<VehicleStatus | null>();

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = useFormContext<IVehicle>();

  const [disabled, setDisabled] = useDisable();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  const { createActivityLog } = useActivityLog();

  const status = useWatch({ control, name: "status" });

  useEffect(() => {
    if (id) setDisabled(true);
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled]);

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { data, isLoading, isSuccess, refetch } =
    useVehicleFindUniqueQuery<VehicleFindUniqueQuery>(
      graphQLClient,
      {
        vehicleFindUniqueId: id ? parseInt(id) : -1,
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const { refetch: refetchValidateInactivate } =
    useVehicleValidateInactivateQuery<VehicleValidateInactivateQuery>(
      graphQLClient,
      {
        vehicleValidateInactivateId: id ? parseInt(id) : -1,
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const { mutateAsync: create, isLoading: isCreating } =
    useVehicleCreateMutation<VehicleCreateMutation>(graphQLClient);
  const { mutateAsync: update, isLoading: isUpdating } =
    useVehicleUpdateMutation<VehicleUpdateMutation>(graphQLClient);
  const { mutateAsync: deleteVehicle } =
    useVehicleDeleteMutation<VehicleDeleteMutation>(graphQLClient);

  useEffect(() => {
    setIsLoadingData(true);
    if (isSuccess) {
      const { VehicleFindUnique } = data;
      setPrevStatus(VehicleFindUnique?.status);
      const formatData = vehicleQueryFormatter(VehicleFindUnique);

      reset(formatData);
    }
    setIsLoadingData(false);
  }, [data, isSuccess, reset]);

  const confirmHandler = async (data: IVehicle) => {
    if (!id) {
      try {
        const formatData = (await vehicleCreateFormatter(
          data
        )) as VehicleCreateInput;

        const { VehicleCreate } = await create({
          createInput: formatData,
        });
        enqueueSnackbar(t("snack_bar.vehicle.create.success"), {
          variant: "success",
        });

        await createActivityLog({
          activity_type: ActivityType.Create,
          document_type: ActivityLogDocumentType.Vehicle,
          reference_id: VehicleCreate?.id || 0,
          activity_detail: {},
        });

        if (VehicleCreate?.status === VehicleStatus.InActive) {
          await createActivityLog({
            activity_type: ActivityType.InActive,
            document_type: ActivityLogDocumentType.Vehicle,
            reference_id: VehicleCreate?.id || 0,
            activity_detail: {},
          });
        }

        navigate(`/logistic/vehicle/${VehicleCreate?.id}`);
      } catch (error) {
        const formatErrors = errorMessageFormatter(error, "vehicle");
        enqueueSnackbar(formatErrors || t("snack_bar.vehicle.create.fail"), {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = await vehicleUpdateFormatter(data);
        let isStatusValid = true;
        let needValidation = false;

        if (
          isDirty &&
          "status" in dirtyFields &&
          data.status === VehicleStatus.InActive
        ) {
          needValidation = true;
          const { data } = await refetchValidateInactivate();
          if (data && data.VehicleValidateInactivate === false) {
            isStatusValid = false;
          }
        }

        if (needValidation && !isStatusValid) {
          enqueueSnackbar(t("snack_bar.vehicle.inactivate.fail"), {
            variant: "error",
          });
          return;
        }

        const { VehicleUpdate } = await update({
          uniqueInput: { id: parseInt(id || "0") },
          updateInput: formatData,
        });

        if (isDirty) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.Vehicle,
            reference_id: VehicleUpdate?.id || 0,
            activity_detail: {
              secondary_operation: ActivityType.Edit,
            },
          });

          if (prevStatus === VehicleStatus.Active) {
            if (VehicleUpdate?.status === VehicleStatus.InActive) {
              await createActivityLog({
                activity_type: ActivityType.InActive,
                document_type: ActivityLogDocumentType.Vehicle,
                reference_id: VehicleUpdate?.id || 0,
                activity_detail: {},
              });
            }
          } else {
            if (VehicleUpdate?.status === VehicleStatus.Active) {
              await createActivityLog({
                activity_type: ActivityType.Active,
                document_type: ActivityLogDocumentType.Vehicle,
                reference_id: VehicleUpdate?.id || 0,
                activity_detail: {},
              });
            }
          }
        }

        if (needValidation) {
          enqueueSnackbar(t("snack_bar.vehicle.inactivate.success"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("snack_bar.vehicle.update.success"), {
            variant: "success",
          });
        }

        setDisabled(true);

        await refetch();
      } catch (error) {
        const formatErrors = errorMessageFormatter(error, "vehicle");
        enqueueSnackbar(formatErrors || t("snack_bar.vehicle.update.fail"), {
          variant: "error",
        });
      }
    }
  };

  const deleteHandler = async () => {
    try {
      await deleteVehicle({
        vehicleDeleteId: parseInt(id || "0"),
      });

      enqueueSnackbar(t("snack_bar.vehicle.delete.success"), {
        variant: "success",
      });

      navigate(`/logistic/vehicle`);
    } catch (error) {
      const formatErrors = errorMessageFormatter(error, "vehicle");
      enqueueSnackbar(formatErrors || t("snack_bar.vehicle.delete.success"), {
        variant: "error",
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
  };

  const cancelConfirmHandler = () => {
    if (id) {
      reset();
      setDisabled(true);
    } else {
      reset();
      navigate(`/logistic/vehicle`);
    }
  };

  const renderButton = (status: string) => {
    if (id) {
      if (!disabled) {
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.cancel")}
              disabled={isCreating || isUpdating}
              onClick={() => cancelConfirmHandler()}
            />
            <CustomizedButton
              title={t("button.confirm")}
              variant="contained"
              disabled={isCreating || isUpdating}
              onClick={() => {
                handleSubmit(
                  (data) => {
                    confirmHandler(data);
                  },
                  (errors) => {
                    const errors_message = vehicleFormFormatter(errors);
                    for (const error_message of errors_message) {
                      enqueueSnackbar(error_message, {
                        variant: "error",
                      });
                    }
                  }
                )();
              }}
            />
          </>
        );
      }
    } else {
      return (
        <>
          <CustomizedButton
            variant="outlined"
            title={t("button.cancel")}
            disabled={isCreating || isUpdating}
            onClick={() => cancelConfirmHandler()}
          />
          <CustomizedButton
            title={t("button.confirm")}
            variant="contained"
            disabled={isCreating || isUpdating}
            onClick={() => {
              handleSubmit(
                (data) => {
                  confirmHandler(data);
                },
                (errors) => {
                  const errors_message = vehicleFormFormatter(errors);
                  for (const error_message of errors_message) {
                    enqueueSnackbar(error_message, {
                      variant: "error",
                    });
                  }
                }
              )();
            }}
          />
        </>
      );
    }
  };

  if (id && (isLoadingData || isLoading || isCreating || isUpdating)) {
    return <LoadingUI />;
  }

  return (
    <>
      <VehicleHeader
        data={data?.VehicleFindUnique as IVehicle}
        editClickHandler={editClickHandler}
        deleteHandler={deleteHandler}
      />
      <VehicleInfo />
      <CustomizedBox>
        <Typography fontWeight={600} mb={2}>
          รูปภาพ
        </Typography>
        <VehicleImage
          control={control}
          name="image"
          setValue={setValue}
          maxSize={5}
          disabled={disabled}
          acceptedFileType="image"
          isNotRevoke
        />
      </CustomizedBox>
      <InsuranceInfo />
      <TireInfo />
      <BottomNavbar>
        <Stack direction="row" spacing={1} alignItems="center">
          {renderButton(status)}
        </Stack>
      </BottomNavbar>
    </>
  );
};

export default DocumentInfoTab;
