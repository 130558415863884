import { Grid } from "@mui/material";
import Remark from "./Remark";
import PurchaseSummary from "./Summary";
import { CustomizedBox } from "../../../Custom/CustomizedBox";

interface Props {
  documentType: string;
}

const PurchaseFooter = ({ documentType }: Props) => {
  return (
    <CustomizedBox>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6.5} lg={6.5} xl={6.5}>
          <Remark />
        </Grid>
        {documentType !== "purchase_request" && (
          <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
            <PurchaseSummary documentType={documentType} />
          </Grid>
        )}
      </Grid>
    </CustomizedBox>
  );
};

export default PurchaseFooter;
