import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";

import { useDisable } from "../../../hooks/use-disable";

import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import ControlledTextField from "../../Controller/ControlledTextField";
import { IUser } from "../../../types/Auth/user";

const PasswordDetail = () => {
  const { t } = useTranslation();

  const [disabled] = useDisable();
  const {
    control,
    formState: { errors },
  } = useFormContext<IUser>();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <>
      <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
        {t("user.account.password")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Controller
            name="new_password"
            control={control}
            render={({ field }) => (
              <FormControl
                disabled={disabled}
                variant="outlined"
                fullWidth
                size="small"
                required
              >
                <InputLabel
                  htmlFor="outlined-adornment-password1"
                  error={Boolean(errors.new_password)}
                >
                  {t("user.account.password")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password1"
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        disabled={disabled}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("user.account.password")}
                  {...field}
                  error={Boolean(errors.new_password)}
                />
                <FormHelperText
                  id="helper-text-password1"
                  sx={{ whiteSpace: "pre-wrap" }}
                  error={Boolean(errors.new_password)}
                >
                  {errors.new_password?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            control={control}
            type="password"
            name="confirm_new_password"
            error={Boolean(errors.confirm_new_password)}
            helperText={errors.confirm_new_password?.message}
            label={t("user.account.confirm_new_password")}
            disabled={disabled}
            required
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PasswordDetail;
