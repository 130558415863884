import { useTranslation } from "react-i18next";
import ModalUI from "./ModalUI";
import { Box, Grid } from "@mui/material";
import CustomizedButton from "components/Custom/CustomizedButton";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import ControlledCheckbox from "components/Controller/ControlledCheckbox";
import { IDefaultForm } from "types/global";
import { useWatch } from "react-hook-form";

interface Props {
  serialModal: boolean;
  closeSerialModalHandler: () => void;
  addSerialsHandler: () => void;
  control: IDefaultForm["control"];
  reset: IDefaultForm["reset"];
}

const SerialModal = ({
  serialModal,
  closeSerialModalHandler,
  addSerialsHandler,
  control,
  reset,
}: Props) => {
  const { t } = useTranslation();

  const isLot = useWatch({
    control,
    name: "is_lot",
    defaultValue: false,
  });

  return (
    <ModalUI
      title="เพิ่ม LOT/SN"
      open={serialModal}
      handleClose={closeSerialModalHandler}
      maxWidth="sm"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            sx={{ width: "100px" }}
            title={t("button.cancel")}
            variant="outlined"
            size="medium"
            onClick={() => {
              if (reset) {
                reset();
              }
              closeSerialModalHandler();
            }}
          />
          <CustomizedButton
            sx={{ width: "100px" }}
            title={t("button.confirm")}
            onClick={addSerialsHandler}
            variant="contained"
            size="medium"
          />
        </Box>
      }
    >
      <Grid container spacing={1.5} mt={1}>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledNumberTextField
            fullWidth
            control={control}
            label={t("inventory.goods_receive.serial.serial_quantity")}
            name="serial_quantity"
            decimalScale={0}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
           <ControlledNumberTextField
            fullWidth
            control={control}
            label={t("inventory.goods_receive.serial.quantity_per_serial")}
            name="quantity_per_serial"
            decimalScale={0}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            label={t("sentence.remark")}
            control={control}
            name="remark"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} />
        <Grid item xs={12}>
          <ControlledCheckbox
            name={`is_lot`}
            control={control}
            label="สินค้าเป็น LOT"
          />
        </Grid>
        {isLot && (
          <Grid item xs={12} sm={12} md={6}>
            <ControlledDatePicker
              label="วันที่"
              control={control}
              name="lot_date"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6} />
      </Grid>
    </ModalUI>
  );
};

export default SerialModal;
