import { useDisable } from "hooks/use-disable";
import { useFormContext } from "react-hook-form";
import DocumentDropzoneUI from "components/UI/DocumentDropzoneUI";

const ClaimAttachment = () => {
  const { control, watch, setValue } = useFormContext();
  const [disabled] = useDisable();

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const cancelled = flagStatus.includes("cancelled");

  const status = !cancelled ? mainStatus : aggrid_status;

  const disabledStatus = ["finished"].includes(status);

  return (
    <DocumentDropzoneUI
      control={control}
      name="claim_attachment_list"
      setValue={setValue}
      disabled={disabled || disabledStatus}
      maxSize={5}
      multiple
      customGridSize={2.5}
      separateAttachmentDate
      gridDropzone
    />
  );
};

export default ClaimAttachment;
