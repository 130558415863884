import * as Yup from "yup";

export const stringMaxInput = (
  maxInput: number = 200,
  maxInputMessage?: string,
  required: boolean = true,
  requiredMessage?: string
) =>
  required
    ? Yup.string()
        .required(requiredMessage || "กรุณาระบุ")
        .max(maxInput, maxInputMessage || `กรอกได้ไม่เกิน ${maxInput} ตัวอักษร`)
    : Yup.string()
        .nullable()
        .max(
          maxInput,
          maxInputMessage || `กรอกได้ไม่เกิน ${maxInput} ตัวอักษร`
        );
