import jwtDecode from "jwt-decode";
import { useSnackbar } from "notistack";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";

import { GraphQLClient } from "graphql-request";
import { useLoginMutation } from "../generated/general";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";

import { Theme, Box, Typography, useMediaQuery, Link } from "@mui/material";
import LoginForm from "../components/Form/Login";

import { useStateContext } from "../contexts/auth-context";

import { IUser } from "../types/Auth/user";
import { IToken } from "../types/Auth/token";
import packageJson from "../../package.json";

const Login = () => {
  const [, setCookie] = useCookies([
    "access_token",
    "refresh_token",
    "logged_in",
  ]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const navigate = useNavigate();
  const location = useLocation();
  const from = ((location.state as any)?.from.pathname as string) || "/landing";

  const { enqueueSnackbar } = useSnackbar();

  const { dispatch } = useStateContext();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { isLoading, mutate: login } = useLoginMutation<Error>(graphQLClient, {
    onSuccess({ login }) {
      const accessToken = login.access_token;

      dispatch({
        type: "SET_USER",
        payload: login.user as IUser,
      });

      const { exp: accessMaxAge } = jwtDecode<IToken>(accessToken);
      setCookie("access_token", accessToken, {
        path: "/",
        maxAge: accessMaxAge,
      });
      setCookie("logged_in", true, {
        path: "/",
      });
      navigate(from);
    },
    onError(error: any) {
      if (error.message === "Network request failed") {
        enqueueSnackbar(
          "ไม่สามารถเชื่อมต่อกับฐานข้อมูลได้ (Service is not avaiable)",
          {
            variant: "error",
          }
        );
      } else {
        error.response.errors.forEach((err: any) => {
          enqueueSnackbar("อีเมลหรือรหัสผ่านไม่ถูกต้อง", {
            variant: "error",
          });
        });
      }
    },
  });

  return !isMobile ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background:
          "linear-gradient(130.2deg, #3E6094 0%, #43669B 8.92%, #5686D0 23.69%, #95B5E5 44%, #B3D5F9 70.71%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="login" src="/static/loginBg.svg" />
        </Box>
        <Box
          sx={{
            borderRadius: "40px 0 0 40px",
            background: "white",
            display: "flex",
            flex: 1,
            height: "inherit",
            alignItems: "center",
            flexDirection: "column",
            p: "2rem",
            justifyContent: "space-between",
            overflowY: "scroll",
            overflowX: "hidden",
            minWidth: "fit-content",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              flex: 15,
            }}
          >
            <img alt="logo" src="/static/logo.jpg" style={{ height: 150 }} />
            <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
              เข้าสู่ระบบ
            </Typography>
            <LoginForm
              isMobile={isMobile}
              isLoading={isLoading}
              login={login}
            />
            {/* <Box sx={{ display: "flex", gap: 0.5, mt: 4 }}>
              <Typography sx={{ fontSize: 14 }}>
                บริษัท/ร้านค้าใหม่ ?
              </Typography>
              <Link
                rel="noopener noreferrer"
                href="https://forms.clickup.com/3654689/f/3fh11-20922/J08LMINOHLJX89ST77"
                underline="hover"
                target="_blank"
                sx={{
                  fontSize: 14,
                }}
              >
                สมัครใช้งาน
              </Link>
            </Box> */}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 3,
            }}
          >
            <Link
              rel="noopener noreferrer"
              href="https://doc.clickup.com/3654689/p/h/3fh11-57182/e95aa4dfc72747b"
              underline="hover"
              target="_blank"
              sx={{
                fontSize: 14,
              }}
            >
              ข้อตกลงและเงื่อนไข
            </Link>
            <Typography mx={1}>|</Typography>
            <Link
              rel="noopener noreferrer"
              href="https://doc.clickup.com/3654689/p/h/3fh11-57162/7dccea7012a1e75"
              underline="hover"
              target="_blank"
              sx={{
                fontSize: 14,
              }}
            >
              นโยบายความเป็นส่วนตัว
            </Link>
          </Box>
          <Box sx={{ width: "100%", pt: "2rem", flex: 1 }}>
            <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
              Application version {packageJson.version}
            </Typography>
            <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
              Powered by NPR Digital Partner
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        background: "white",
        display: "flex",
        height: "100dvh",
        flex: 1,
        alignItems: "center",
        flexDirection: "column",
        p: 4,
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          flex: 15,
        }}
      >
        <img alt="logo" src="/static/logo.jpg" style={{ height: 150 }} />
        <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
          เข้าสู่ระบบ
        </Typography>
        <LoginForm isMobile={isMobile} isLoading={isLoading} login={login} />
        {/* <Box sx={{ display: "flex", gap: 0.5, mt: 4 }}>
          <Typography sx={{ fontSize: 14 }}>บริษัท/ร้านค้าใหม่ ?</Typography>
          <Link
            rel="noopener noreferrer"
            href="https://forms.clickup.com/3654689/f/3fh11-20922/J08LMINOHLJX89ST77"
            underline="hover"
            target="_blank"
            sx={{
              fontSize: 14,
            }}
          >
            สมัครใช้งาน
          </Link>
        </Box> */}
      </Box>
      <Box
        sx={{
          width: "100%",
          py: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          rel="noopener noreferrer"
          href="https://doc.clickup.com/3654689/p/h/3fh11-57182/e95aa4dfc72747b"
          underline="hover"
          target="_blank"
          sx={{
            fontSize: 14,
          }}
        >
          ข้อตกลงและเงื่อนไข
        </Link>
        <Typography mx={1}>|</Typography>
        <Link
          rel="noopener noreferrer"
          href="https://doc.clickup.com/3654689/p/h/3fh11-57162/7dccea7012a1e75"
          underline="hover"
          target="_blank"
          sx={{
            fontSize: 14,
          }}
        >
          นโยบายความเป็นส่วนตัว
        </Link>
      </Box>
      <Box sx={{ width: "100%", pt: 4 }}>
        <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
          Application version {packageJson.version}
        </Typography>
        <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
          Copyright by Soft Asset Co., Ltd.
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
