import { Box, CircularProgress, Typography } from "@mui/material";
import CustomizedButton from "components/Custom/CustomizedButton";
import CustomizedSelect from "components/Custom/CustomizedSelect";
import DeliveryTripListPdfTable from "components/Table/Sales/BillingNote/DeliveryTripList/PDF";
import PDFLayout from "components/UI/PDF/Layout";
import {
  BillingNoteFindUniqueQuery,
  useBillingNoteFindUniqueQuery,
} from "generated/sales";
import { usePDFMultiplePage } from "hooks/use-pdf-multiple-page";
import { ReactInstance, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IPdfInfo } from "types/global";
import { IDeliveryTripList } from "types/Sales/billing-note";
import { formatDate } from "utils/Date";
import { renderAddressString } from "utils/Formatter/Global";

const BillingNotePDF = () => {
  const { id } = useParams();
  const componentRef = useRef<ReactInstance | null>(null);
  const [headerType, setHeaderType] = useState<string>("forwarder");

  const graphQLClient = createGraphQLClientWithMiddleware("sales");
  const { data, isLoading } =
    useBillingNoteFindUniqueQuery<BillingNoteFindUniqueQuery>(graphQLClient, {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    });

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.BillingNoteFindUnique, //data
    "billing_note", // document type
    139.66, // name and description row width
    640, // max height without footer
    495, // max height with footer
    224.875, // left header minimum height
    247.797, // right header minimum height
    37.313, // left footer minimum height (you don't need to specify this if the document doesn't have remark and tags)
    87.963 // right footer minimum height (you don't need to specify this if the document doesn't have summary)
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data?.BillingNoteFindUnique?.unique_id,
  });

  const highlightSection: IPdfInfo = {
    headers: ["เลขที่เอกสาร", "วันที่ออกเอกสาร", "วันที่ครบกำหนด", "เครดิต"],
    info: [
      data?.BillingNoteFindUnique.unique_id || "-",
      data?.BillingNoteFindUnique.issue_date
        ? formatDate(data?.BillingNoteFindUnique.issue_date)
        : "-",
      data?.BillingNoteFindUnique.due_date
        ? formatDate(data?.BillingNoteFindUnique.due_date)
        : "-",
      data?.BillingNoteFindUnique.credit_day
        ? data.BillingNoteFindUnique.credit_day + " วัน"
        : "-",
    ],
  };

  const leftSection: IPdfInfo = {
    headers: [
      "ลูกค้า",
      "ที่อยู่",
      "เลขประจำตัวผู้เสียภาษี",
      "เบอร์โทรศัพท์@Fax",
      "Email",
    ],
    info: [
      `${data?.BillingNoteFindUnique.customer_unique_id} - ${data?.BillingNoteFindUnique.customer_details.customer_name}`,
      renderAddressString(
        data?.BillingNoteFindUnique.customer_details.billing_address
      ) || "-",
      data?.BillingNoteFindUnique.customer_details.identity_no || "-",
      data?.BillingNoteFindUnique.customer_details.phone +
        "@" +
        data?.BillingNoteFindUnique.customer_details.fax || "-",
      data?.BillingNoteFindUnique.customer_details.email || "-",
    ],
  };

  const rightSection: IPdfInfo = {
    headers: [],
    info: [],
  };

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mx: "auto" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">ใบวางบิล</Typography>
        <Box display={"flex"} gap={4}>
          <CustomizedSelect
            options={[
              { value: "transfer", label: "ทรานสปอร์ต" },
              { value: "forwarder", label: "ฟอร์เวิดเดอร์" },
            ]}
            label="รูปแบบการพิมพ์"
            value={headerType}
            variant="standard"
            sx={{ minWidth: "130px" }}
            onChange={(e) => setHeaderType(e.target.value as string)}
          />
          <CustomizedButton
            fullWidth
            title="พิมพ์เอกสาร"
            variant="outlined"
            onClick={handlePrint}
          />
        </Box>
      </Box>
      <Box ref={componentRef} sx={{ m: "auto" }}>
        {paginatedItems.map((item, index) => (
          <PDFLayout
            highlightSection={highlightSection}
            leftSection={leftSection}
            rightSection={rightSection}
            name={"ใบวางบิล"}
            data={data?.BillingNoteFindUnique}
            summary={index === paginatedItems.length - 1}
            multiplePage={paginatedItems.length > 1}
            page={index + 1}
            allPages={paginatedItems.length}
            documentType="billing_note"
            headerType={headerType}
          >
            <DeliveryTripListPdfTable
              itemList={(item as IDeliveryTripList[]) || []}
              startNumber={startNumbers[index]}
              lastTable={
                paginatedItems[paginatedItems.length - 1].length === 0
                  ? index === paginatedItems.length - 2
                  : index === paginatedItems.length - 1
              }
            />
          </PDFLayout>
        ))}
      </Box>
    </Box>
  );
};

export default BillingNotePDF;
