import { Traceability } from "generated/wms";
import { ITraceEntry } from "types/Inventory";
import { IGoodsReceive } from "types/Inventory/goodsReceive";
import dayjs from "dayjs";

export const goodsReceiveCreateFormatter = (
  data: IGoodsReceive,
  status?: string
) => {
  const {
    created_date,
    trace_entry_list,
    aggrid_status,
    source_warehouse_id,
    ...otherData
  } = data;

  const new_trace_entry_list: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({ uom_id, ...serial }) => ({
            ...serial,
            reference_unique_id: data.unique_id,
            reference_line_item: trace.reference_line_item,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            barcode: `${serial.item_unique_id}#${serial.serial_no}#${serial.qty}`,
            uom_id: typeof uom_id === "string" ? parseInt(uom_id) : uom_id,
            scanned_by: {},
            destination_scanned_by: {},
          })
        );
        new_trace_entry_list.push(...formatSerialList);
      } else {
        const { serial_list, uom_id, ...otherTrace } = trace;
        const formatTrace = {
          ...otherTrace,
          reference_unique_id: data.unique_id,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
          uom_id: typeof uom_id === "string" ? parseInt(uom_id) : uom_id,
          scanned_by: {},
          destination_scanned_by: {},
        };
        new_trace_entry_list.push(formatTrace);
      }
    } else {
      new_trace_entry_list.push(trace);
    }
  });

  return {
    ...otherData,
    trace_entry_list: new_trace_entry_list,
    main_status: status,
    source_warehouse_id:
      typeof source_warehouse_id === "string"
        ? parseInt(source_warehouse_id)
        : source_warehouse_id,
  };
};

export const goodsReceiveTypeValueFormatter = (type: string) => {
  switch (type) {
    case "purchase":
      return "ซื้อ";
    case "return":
      return "รับคืน";
    case "other":
      return "อื่นๆ";
    default:
      return "";
  }
};

const groupBy = (xs: any[], key: string, altKey: string) => {
  return xs.reduce((rv, x) => {
    if (x.traceability === "normal") {
      const groupKey = x[altKey];
      rv[groupKey] = rv[groupKey] || [];
      rv[groupKey].push(x);
    } else {
      const groupKey = x[key];
      rv[groupKey] = rv[groupKey] || [];
      rv[groupKey].push(x);
    }
    return rv;
  }, {});
};

export const goodsReceiveUpdateFormatter = (
  data: IGoodsReceive,
  status: string
) => {
  const {
    id,
    source_warehouse,
    trace_entry_list,
    created_date,
    created_by,
    last_updated_date,
    unique_id,
    source_warehouse_id,
    aggrid_status,
    ...otherData
  } = data;
  const new_trace_entry_list: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            id,
            uom_id,
            created_date,
            source_bin_location,
            destination_bin_location,
            scanned_by,
            destination_scanned_by,
            ...serial
          }) => ({
            ...serial,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            posted_qty: trace.posted_qty,
            barcode: `${serial.item_unique_id}#${serial.serial_no}#${serial.qty}`,
            uom_id: typeof uom_id === "string" ? parseInt(uom_id) : uom_id,
            scanned_by: scanned_by ? scanned_by : {},
            destination_scanned_by: destination_scanned_by
              ? destination_scanned_by
              : {},
          })
        );
        new_trace_entry_list.push(...formatSerialList);
      } else {
        const {
          id,
          serial_list,
          uom_id,
          source_bin_location,
          destination_bin_location,
          created_date,
          scanned_by,
          destination_scanned_by,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          uom_id: typeof uom_id === "string" ? parseInt(uom_id) : uom_id,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
          scanned_by: scanned_by ? scanned_by : {},
          destination_scanned_by: destination_scanned_by
            ? destination_scanned_by
            : {},
        };
        new_trace_entry_list.push(formatTrace);
      }
    } else {
      new_trace_entry_list.push(trace);
    }
  });

  return {
    ...otherData,
    trace_entry_list: new_trace_entry_list,
    main_status: status,
    source_warehouse_id:
      typeof source_warehouse_id === "string"
        ? parseInt(source_warehouse_id)
        : source_warehouse_id,
  };
};

export const goodsReceiveQueryFormatter = (data: IGoodsReceive) => {
  const { trace_entry_list, ...otherData } = data;
  const groupedTrace = groupBy(trace_entry_list, "item_unique_id", "unique_id");

  const allTraceEntries: ITraceEntry[] = [];

  for (const entry of Object.entries(groupedTrace)) {
    const value = entry[1] as ITraceEntry[];

    let formatSerial = [];

    formatSerial = value.map((serial) => ({
      ...serial,
      serial_no: serial.serial_no,
      scanned_date: serial.scanned_date ?? undefined,
      scanned_by: serial.scanned_by ?? undefined,
      lot_date:
        serial.lot_date && serial.is_lot ? dayjs(serial.lot_date) : null,
    }));

    const formatTrace = {
      item_unique_id: value[0].item_unique_id,
      po_reference_unique_id: value[0].po_reference_unique_id,
      item_name: value[0].item_name,
      seller_sku: value[0].seller_sku,
      item_img_url: value[0].item_img_url,
      traceability: value[0].traceability,
      type: value[0].type,
      unique_id: value[0].unique_id,
      qty: value.reduce((prev, curr) => prev + curr.qty, 0),
      posted_qty: value[0].posted_qty,
      document_item_qty: value[0].document_item_qty,
      scanned_date:
        value[0].traceability === Traceability.Normal
          ? value[0].scanned_date
          : undefined,
      created_date: value[0].created_date,
      uom_id: value[0].uom_id,
      uom: value[0].uom,
      source_bin_location:
        value[0].traceability === Traceability.Normal
          ? value[0].source_bin_location
          : undefined,
      source_bin_location_id:
        value[0].traceability === Traceability.Normal
          ? value[0].source_bin_location_id
          : undefined,
      barcode:
        value[0].traceability === Traceability.Normal
          ? value[0].barcode
          : undefined,
      serial_list: value.some(
        (serial) => serial.traceability === Traceability.Normal
      )
        ? undefined
        : formatSerial,
      status:
        value[0].traceability === Traceability.Normal
          ? value[0].status
          : undefined,
      scanned_by:
        value[0].traceability === Traceability.Normal
          ? value[0].scanned_by ?? undefined
          : undefined,
      remark: value[0].remark ?? undefined,
      reference_unique_id: value[0].reference_unique_id,
      reference_line_item: value[0].reference_line_item,
      lot_date: value[0].lot_date ? dayjs(value[0].lot_date) : null,
    } as ITraceEntry;
    allTraceEntries.push(formatTrace);
  }

  return {
    ...otherData,
    trace_entry_list: allTraceEntries,
    created_date: dayjs(otherData.created_date),
    posted_date: otherData.posted_date ? dayjs(otherData.posted_date) : null,
  };
};
