import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import LoadingUI from "components/UI/LoadingUI";
import DeliveryTripTable from "components/Table/Logistic/DeliveryTrip";
import {
  QuotationFindUniqueQuery,
  useQuotationFindUniqueQuery,
} from "generated/sales";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { useParams } from "react-router-dom";

type Props = {
  isLoading?: boolean;
};

const DeliveryTripTab = ({ isLoading }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { data } = useQuotationFindUniqueQuery<QuotationFindUniqueQuery>(
    graphQLClient,
    {
      uniqueInput: {
        id: id ? parseInt(id) : undefined,
      },
    },
    {
      enabled: !!id,
      cacheTime: 20,
    }
  );

  if (isLoading) return <LoadingUI />;

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">
          {t("logistic.delivery_trip.index")}
        </Typography>
      </Box>
      <DeliveryTripTable
        status={null}
        referenceUniqueId={data?.QuotationFindUnique?.unique_id}
      />
    </>
  );
};

export default DeliveryTripTab;
