import { useFieldArray, useFormContext } from "react-hook-form";

import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import CustomizedAvatar from "components/Custom/CustomizedAvatar";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";

import { IDefaultForm } from "types/global";
import { formatDate, formatDateTimeNoAMPM } from "utils/Date";
import { checkIsNotDraftOrEmptyStatus } from "utils/Global";
import ControlledSelect from "components/Controller/ControlledSelect";
import { IGoodsTransfer } from "types/Inventory/goodsTransfer";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";

type Props = {
  disabled: IDefaultForm["disabled"];
  nestedIndex: number;
  status: string;
};

const SerialList = ({ nestedIndex, disabled, status }: Props) => {
  const {
    control,
    getValues,
    // setValue,
    formState: { errors },
  } = useFormContext<IGoodsTransfer>();

  const { fields, remove, update } = useFieldArray({
    control,
    name: `trace_entry_list.${nestedIndex}.serial_list` as `trace_entry_list`,
  });

  const notDraft = checkIsNotDraftOrEmptyStatus(status);

  const rescanBarcodeHandler = (index: number) => {
    const { id, ...otherFields } = fields[index];

    update(index, {
      ...otherFields,
      status: "is_active",
      destination_bin_location: undefined,
      destination_bin_location_id: undefined,
      destination_scanned_by: {},
      destination_scanned_date: undefined,
    });
  };

  return (
    <>
      {fields.map((serial, index) => (
        <TableRow key={serial.id}>
          <TableCell></TableCell>
          <TableCell
            align="left"
            sx={{
              px: 1,
              py: 1,
            }}
          >
            <Typography fontSize={14}>
              {formatDateTimeNoAMPM(serial.scanned_date)}
            </Typography>
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell align="left">
            <ControlledNumberTextField
              fullWidth
              control={control}
              name={`trace_entry_list.${nestedIndex}.serial_list.${index}.qty`}
              decimalScale={0}
              InputProps={{
                inputProps: { min: 0 },
              }}
              error={Boolean(
                errors?.trace_entry_list?.[nestedIndex]?.serial_list?.[index]
                  ?.qty
              )}
              FormHelperTextProps={{
                style: { fontSize: "10px", textAlign: "end" },
              }}
              helperText={
                serial.scanned_by
                  ? `จำนวนคงคลัง ${
                      serial?.all_bin_locations
                        ?.find(
                          (bin) => bin.id === serial.source_bin_location_id
                        )
                        ?.stock_qty?.toString() || "-"
                    }`
                  : undefined
              }
              sx={{
                pr: "0.5rem",
              }}
              viewMode={notDraft}
            />
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.uom}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontSize={14}>{serial.serial_no}</Typography>
          </TableCell>
          <TableCell>
            {serial.all_bin_locations &&
            serial.all_bin_locations.length > 1 &&
            !disabled &&
            status !== "wait_transfer" ? (
              <ControlledSelect
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.source_bin_location_id`}
                control={control}
                label={""}
                error={Boolean(
                  errors?.trace_entry_list?.[nestedIndex]?.serial_list?.[index]
                    ?.source_bin_location_id
                )}
                helperText={
                  errors?.trace_entry_list?.[nestedIndex]?.serial_list?.[index]
                    ?.source_bin_location_id?.message
                }
                onChange={(e: any) => {
                  const matchingBinLocation = serial.all_bin_locations?.find(
                    (bin) => bin.id === e.target.value
                  );
                  if (matchingBinLocation) {
                    const currentSerial = getValues(
                      `trace_entry_list.${nestedIndex}.serial_list.${index}` as any
                    );
                    update(index, {
                      ...currentSerial,
                      stock_qty: matchingBinLocation.stock_qty,
                    });

                    // setValue(
                    //   `trace_entry_list.${nestedIndex}.serial_list.${index}.stock_qty` as any,
                    //   matchingBinLocation.stock_qty
                    // );
                  }
                }}
                options={
                  serial.all_bin_locations?.map((bin) => ({
                    value: bin.id,
                    label: bin.name,
                  })) || []
                }
              />
            ) : (
              <Typography fontSize={14}>
                {serial.source_bin_location?.name}
              </Typography>
            )}
          </TableCell>
          {notDraft && (
            <TableCell>
              <Typography fontSize={14}>
                {serial.destination_bin_location?.name}
              </Typography>
            </TableCell>
          )}
          <TableCell>
            {serial.scanned_by && Object.keys(serial.scanned_by).length > 0 && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CustomizedAvatar
                  avatars={[
                    {
                      unique_id: serial.scanned_by.user_unique_id || "",
                      first_name: serial.scanned_by.first_name || "",
                      last_name: serial.scanned_by.last_name || "",
                      img_url: serial.scanned_by.img_url
                        ? serial.scanned_by.img_url[0]
                        : "",
                    },
                  ]}
                />
              </Box>
            )}
          </TableCell>
          {notDraft && (
            <>
              <TableCell align="center">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CustomizedStatus status={serial.status} />
                </Box>
              </TableCell>
              <TableCell>
                {!disabled && serial.status === "is_scanned" && (
                  <CustomizedTooltip title="สแกนใหม่">
                    <IconButton onClick={() => rescanBarcodeHandler(index)}>
                      <ReplayOutlinedIcon fontSize="small" />
                    </IconButton>
                  </CustomizedTooltip>
                )}
              </TableCell>
              <TableCell align="center">
                {serial.destination_scanned_by &&
                  Object.keys(serial.destination_scanned_by).length > 0 && (
                    <Box display={"flex"} justifyContent={"center"}>
                      <CustomizedAvatar
                        avatars={[
                          {
                            unique_id:
                              serial.destination_scanned_by.user_unique_id ||
                              "",
                            first_name:
                              serial.destination_scanned_by.first_name || "",
                            last_name:
                              serial.destination_scanned_by.last_name || "",
                            img_url: serial.destination_scanned_by.img_url
                              ? serial.destination_scanned_by.img_url[0]
                              : "",
                          },
                        ]}
                      />
                    </Box>
                  )}
              </TableCell>
              <TableCell>
                <Typography fontSize={14}>
                  {formatDateTimeNoAMPM(serial.destination_scanned_date)}
                </Typography>
              </TableCell>
            </>
          )}
          <TableCell>
            <Typography fontSize={14}>{serial.barcode}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>
              {serial.is_lot ? formatDate(serial.lot_date) : "-"}
            </Typography>
          </TableCell>
          {!notDraft && (
            <TableCell align="center">
              <IconButton onClick={() => remove(index)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default SerialList;
