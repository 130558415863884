import { IBreadcrumbsAndMenu, ITab } from "types/global";
import { useState, useEffect, useMemo, useRef } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import CustomizedBreadcrumbs from "components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "components/Custom/CustomizedButton";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import TotalBox from "components/UI/TotalBox";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import {
  ItemType,
  ItemFindUniqueQuery,
  useItemFindUniqueQuery,
  useItemCreateMutation,
  useItemUpdateMutation,
  useItemDeleteMutation,
  useItemSkuQtyItemSumQuery,
  ItemSkuQtyItemSumQuery,
} from "generated/wms";
import {
  itemSchema,
  itemSchemaValidation,
} from "components/Form/Inventory/Item/schema";
import {
  itemCreateFormatter,
  itemUpdateFormatter,
  itemQueryFormatter,
} from "utils/Formatter/Inventory/Item";
import { errorMessageFormatter } from "utils/Global";
import {
  CircularProgress,
  Box,
  Typography,
  Stack,
  Grid,
  IconButton,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DetailTab from "./DetailTab";
import CurrentStockTab from "./CurrentStockTab";
import TransactionTab from "./TransactionTab";
import { IItem } from "types/Inventory/item";
import { ICardList } from "types/global";
import { useDisable } from "hooks/use-disable";
import { useModal } from "hooks/use-modal";
import { useSnackbar } from "notistack";
import BarcodeScanNavigate from "components/UI/BarcodeScanNavigate";
import CustomizedMenuOptions from "components/Custom/CustomizedMenuOptions";
import DeleteConfirmation from "components/UI/Modal/DeleteConfirmation";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import CustomizedTab from "components/Custom/CustomizedTab";
import { yupResolver } from "@hookform/resolvers/yup";

import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { ActivityLogDocumentType, ActivityType } from "generated/general";
import { useActivityLog } from "hooks/use-activity-log";
import { ActivityLog } from "components/UI/ActivityLog";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";

type Props = {
  type: "item" | "service";
};

const ItemContainer = ({ type }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();
  const graphqlClientWms = createGraphQLClientWithMiddleware("wms");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const prevIsStock = useRef<boolean | null | undefined>(null);
  const [prevStatus, setPrevStatus] = useState<number | null>();
  const [currentTab, setCurrentTab] = useState(
    `${pathname}?tab=item&subtab=general`
  );

  const {
    createActivityLog,
    openActivityLog,
    onActivityLogOpen,
    onActivityLogClose,
  } = useActivityLog();

  const [disabled, setDisabled] = useDisable();

  const methods = useForm<IItem>({
    defaultValues: {
      ...itemSchema,
      type: type === "item" ? ItemType.Normal : ItemType.Service,
    },
    resolver: yupResolver<any>(itemSchemaValidation),
  });

  const { data, isSuccess, isLoading } =
    useItemFindUniqueQuery<ItemFindUniqueQuery>(
      graphqlClientWms,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const { mutateAsync: create, isLoading: isCreating } =
    useItemCreateMutation<Error>(graphqlClientWms);

  const { mutateAsync: update, isLoading: isUpdating } =
    useItemUpdateMutation<Error>(graphqlClientWms);

  const { mutateAsync: deleteItem, isLoading: isDeleting } =
    useItemDeleteMutation<Error>(graphqlClientWms);

  const editCancelHandler = () => {
    setDisabled(true);
    setIsEdit(false);
    reset();
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = methods;

  const isStock = useWatch({ control, name: "is_stockable" });
  const stockUomName = useWatch({ control, name: "stock_uom_name" });
  const itemUniqueId = useWatch({ control, name: "unique_id" });
  const itemType = useWatch({ control, name: "type" });

  const { data: itemSkuQtySum } =
    useItemSkuQtyItemSumQuery<ItemSkuQtyItemSumQuery>(
      graphqlClientWms,
      {
        itemUniqueId: itemUniqueId,
      },
      {
        enabled: !!id,
      }
    );

  useEffect(() => {
    switch (tab) {
      case "item":
        setCurrentTab(pathname + `?tab=item&subtab=general`);
        break;
      case "current-stock":
        setCurrentTab(pathname + `?tab=current-stock&subtab=by-warehouse`);
        break;
      default:
        setCurrentTab(pathname + `?tab=${tab}`);
        break;
    }
  }, [pathname, tab]);

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }

    return () => setDisabled(false);
  }, [id, setDisabled]);

  useEffect(() => {
    if (isSuccess) {
      const { ItemFindUnique } = data;
      setPrevStatus(ItemFindUnique?.is_active);
      const itemData = itemQueryFormatter(ItemFindUnique);
      reset(itemData);
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (prevIsStock.current && prevIsStock.current !== isStock) {
      setSearchParams({ tab: "item", subtab: "general" });
    }
    prevIsStock.current = isStock;
  }, [isStock, setSearchParams]);

  const itemCreateHandler = async (data: IItem) => {
    const typeString = type === "item" ? "สินค้า" : "บริการ";
    try {
      const formatData = await itemCreateFormatter(data, id);
      const { ItemCreate } = await create({
        createInput: formatData,
      });
      await createActivityLog({
        activity_type: ActivityType.Create,
        document_type: ActivityLogDocumentType.Item,
        reference_id: ItemCreate?.id || 0,
        activity_detail: {},
      });
      if (ItemCreate?.is_active === 0) {
        await createActivityLog({
          activity_type: ActivityType.InActive,
          document_type: ActivityLogDocumentType.Item,
          reference_id: ItemCreate?.id || 0,
          activity_detail: {},
        });
      }
      const itemData = itemQueryFormatter(ItemCreate);
      reset(itemData);
      setPrevStatus(ItemCreate?.is_active);

      enqueueSnackbar(`สร้าง${typeString}สำเร็จ`, {
        variant: "success",
      });
      navigate(
        ItemCreate?.type === ItemType.Normal
          ? `/inventory/item/${ItemCreate?.id}?subtab=general`
          : `/inventory/service/${ItemCreate?.id}?subtab=general`
      );
    } catch (error) {
      const formatError = errorMessageFormatter(error, "item");
      enqueueSnackbar(formatError || `สร้าง${typeString}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const itemUpdateHandler = async (data: IItem) => {
    const typeString = type === "item" ? "สินค้า" : "บริการ";
    try {
      const { id } = data;
      const formatData = await itemUpdateFormatter(data);
      const { ItemUpdate } = await update({
        uniqueInput: {
          id,
        },
        updateInput: formatData,
      });
      if (isDirty) {
        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.Item,
          reference_id: ItemUpdate?.id || 0,
          activity_detail: {},
        });
        if (prevStatus === 1) {
          if (ItemUpdate?.is_active === 0) {
            await createActivityLog({
              activity_type: ActivityType.InActive,
              document_type: ActivityLogDocumentType.Item,
              reference_id: ItemUpdate?.id || 0,
              activity_detail: {},
            });
          }
        } else {
          if (ItemUpdate?.is_active === 1) {
            await createActivityLog({
              activity_type: ActivityType.Active,
              document_type: ActivityLogDocumentType.Item,
              reference_id: ItemUpdate?.id || 0,
              activity_detail: {},
            });
          }
        }
      }
      const itemData = itemQueryFormatter(ItemUpdate);
      reset(itemData);
      setPrevStatus(ItemUpdate?.is_active);
      setDisabled(true);
      setIsEdit(false);
      enqueueSnackbar(`แก้ไข${typeString}สำเร็จ`, {
        variant: "success",
      });
    } catch (error) {
      const formatError = errorMessageFormatter(error, "item");
      enqueueSnackbar(formatError || `แก้ไข${typeString}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const itemDeleteHandler = async () => {
    const typeString = type === "item" ? "สินค้า" : "บริการ";
    try {
      await deleteItem({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      enqueueSnackbar(`ลบ${typeString}สำเร็จ`, {
        variant: "success",
      });
      navigate("/inventory/item");
    } catch (error) {
      enqueueSnackbar(`ลบ${typeString}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const submitErrorHandler = (error: any) => {
    // enqueueSnackbar("เกิดข้อผิดพลาด", {
    //   variant: "error",
    // });
  };

  const {
    modal: deleteConfirmation,
    openModalHandler: openDeleteConfirmationHandler,
    closeModalHandler: closeDeleteConfirmationHandler,
    submitModalHandler: submitDeleteConfirmationHandler,
  } = useModal(itemDeleteHandler);

  const {
    modal: createConfirmation,
    openModalHandler: openCreateConfirmationHandler,
    closeModalHandler: closeCreateConfirmationHandler,
    submitModalHandler: submitCreateConfirmationHandler,
  } = useModal(handleSubmit(itemCreateHandler));

  const lockItemUniqueId = [
    "SP00001",
    "SP00002",
    "SP00003",
    "SP00004",
    "SP00005",
    "SP00006",
    "SP00007",
    "SP00008",
    "SP00009",
    "SP00010",
    "SP00011",
    "SP00012",
    "SP00013",
  ];

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.item.index"),
      to: "/inventory/item",
    },
    {
      name:
        id && Boolean(id)
          ? data && data.ItemFindUnique?.unique_id
            ? data.ItemFindUnique.unique_id
            : ""
          : type === "item"
          ? t(`${t("button.create")}${t("inventory.item.normal")}`)
          : t(`${t("button.create")}${t("inventory.item.service")}`),
    },
  ];

  const tabs: ITab[] = useMemo(
    () => [
      {
        label: "ข้อมูล",
        path: `${pathname}?tab=item&subtab=general`,
      },
      {
        label: "จำนวนสินค้าคงคลัง",
        path: `${pathname}?tab=current-stock&subtab=by-warehouse`,
        disabled: isEdit,
      },
      {
        label: "รายการเคลื่อนไหวสินค้า",
        path: `${pathname}?tab=transaction`,
        disabled: isEdit,
      },
    ],
    [pathname, isEdit]
  );

  const CardList: ICardList[] = useMemo(
    () => [
      {
        title: t("inventory.item.available_qty"),
        field: "normal",
        borderColor: "#2167D3",
      },
      {
        title: t("inventory.item.stock_qty"),
        field: "all",
        borderColor: "#2167D3",
      },
      {
        title: t("inventory.item.purchase_ordered_qty"),
        field: "service",
        borderColor: "#2167D3",
      },
    ],
    [t]
  );

  const selectModifyOptions = [
    {
      value: "แก้ไข",
      disabled: !!isEdit,
    },
    ...(!lockItemUniqueId.includes(itemUniqueId)
      ? [
          {
            value: "ลบ",
            disabled: false,
          },
        ]
      : []),
  ];

  const renderCard = (list: ICardList[]) =>
    list.map((menu: ICardList, index) => {
      let count;
      switch (index) {
        case 0:
          count = itemSkuQtySum?.ItemSkuQtyItemSum?.stock_qty || 0;
          break;
        case 1:
          count = itemSkuQtySum?.ItemSkuQtyItemSum?.available_qty || 0;
          break;
        case 2:
          count = itemSkuQtySum?.ItemSkuQtyItemSum?.purchase_ordered_qty || 0;
          break;
        default:
          count = 0;
      }
      return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={menu.title}>
          <TotalBox
            title={menu.title}
            value={count}
            borderColor={menu.borderColor}
            unit={stockUomName}
          />
        </Grid>
      );
    });

  const renderTab = () => {
    if (!id) return <DetailTab />;
    switch (tab) {
      case "current-stock":
        return <CurrentStockTab />;
      case "transaction":
        return <TransactionTab />;
      default:
        return <DetailTab />;
    }
  };

  if (id && (isLoading || isCreating || isUpdating || isDeleting)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth={1040}>
      {id && (
        <ActivityLog
          open={openActivityLog}
          onClose={onActivityLogClose}
          documentId={id!}
          documentType={ActivityLogDocumentType.Item}
        />
      )}
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        </Grid>
        {id && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid
              container
              alignItems="center"
              justifyContent="end"
              spacing={2}
            >
              <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                <BarcodeScanNavigate includeTab />
              </Grid>
              <Grid item xs={2} sm={2} md={1}>
                <CustomizedTooltip
                  title="ดูความเคลื่อนไหว"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={onActivityLogOpen}
                    sx={{
                      padding: 0,
                      marginLeft: "4px",
                      color: "primary.main",
                    }}
                  >
                    <RestoreOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {id && itemType === "normal" && (
        <Box mt={2}>
          <CustomizedTab tabs={tabs} currentTab={currentTab} subtab divider />
          <Divider variant="fullWidth" />
        </Box>
      )}
      <Box display="flex" justifyContent={"space-between"} mt={2}>
        <Typography
          variant="h5"
          noWrap
          sx={{ maxWidth: isSmallScreen ? "14rem" : "50rem" }}
        >
          {id
            ? data?.ItemFindUnique?.name
            : type === "item"
            ? `สร้าง${t("inventory.item.normal")}`
            : `สร้าง${t("inventory.item.service")}`}
        </Typography>
        {id && (!tab || tab === "item") && (
          <CustomizedMenuOptions
            size="small"
            label={"ตัวเลือก"}
            options={selectModifyOptions}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case "แก้ไข":
                  setIsEdit(true);
                  setDisabled(false);
                  break;
                case "ลบ":
                  openDeleteConfirmationHandler();
                  break;

                default:
                  break;
              }
            }}
            disabled={!id}
          />
        )}
      </Box>
      {id && type === "item" && (
        <Grid container spacing={1}>
          {renderCard(CardList)}
        </Grid>
      )}
      <FormProvider {...methods}>
        {renderTab()}
        {!disabled && (
          <BottomNavbar>
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={() => {
                  if (id) {
                    editCancelHandler();
                  } else {
                    navigate("/inventory/item");
                  }
                }}
                disabled={isCreating || isUpdating}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.confirm")}
                onClick={handleSubmit(
                  id
                    ? itemUpdateHandler
                    : type === "item"
                    ? openCreateConfirmationHandler
                    : itemCreateHandler,
                  submitErrorHandler
                )}
                disabled={isCreating || isUpdating}
              />
            </Stack>
          </BottomNavbar>
        )}
      </FormProvider>
      <DeleteConfirmation
        title={`ลบข้อมูล${
          type === "item"
            ? t("inventory.item.index")
            : t("inventory.item.service")
        }`}
        message={
          <Box>
            <Typography>
              ยืนยันการลบข้อมูล
              {type === "item"
                ? t("inventory.item.index")
                : t("inventory.item.service")}
            </Typography>
            <Typography fontWeight="bold">
              {data?.ItemFindUnique?.name}
            </Typography>
          </Box>
        }
        open={deleteConfirmation}
        handleClose={closeDeleteConfirmationHandler}
        action={submitDeleteConfirmationHandler}
      />
      <ConfirmationModal
        title={`ยืนยันการสร้าง${t("inventory.item.index")}`}
        message={
          <Box my={2}>
            <Typography textAlign="center">
              {"รูปแบบการติดตามจะไม่สามารถถูกแก้ไขภายหลังได้"}
            </Typography>
            <Typography textAlign="center">
              {"(กรณีติดตามแบบทั่วไปจะสามารถแก้ไข Barcode ภายหลังได้)"}
            </Typography>
          </Box>
        }
        open={createConfirmation}
        handleClose={closeCreateConfirmationHandler}
        action={submitCreateConfirmationHandler}
      />
    </Box>
  );
};

export default ItemContainer;
