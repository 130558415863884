import { Control, Controller } from "react-hook-form";
import CustomizedSelect from "../Custom/CustomizedSelect";
import { SelectProps } from "@mui/material";
import { ISelectOption } from "../../types/global";
import LabelInput from "components/UI/LabelInput";
import { ReactNode } from "react";

type ExtendedSelectProps = {
  name: string;
  label?: string;
  control: Control<any>;
  options: ISelectOption[];
  onChange?: SelectProps["onChange"] | any;
  error?: SelectProps["error"];
  helperText?: string;
  renderValue?: any;
  disabled?: boolean;
  required?: boolean;
  sx?: SelectProps["sx"];
  inputProps?: SelectProps["inputProps"];
  MenuProps?: SelectProps["MenuProps"];
  placeholder?: SelectProps["placeholder"];
  endAdorment?: ReactNode;
  helperTextSize?: number;
  viewMode?: boolean;
};

const ControlledSelect = ({
  name,
  label,
  control,
  error,
  renderValue,
  options,
  disabled,
  required,
  sx,
  onChange,
  helperText,
  helperTextSize,
  inputProps,
  MenuProps,
  placeholder,
  viewMode,
  endAdorment,
}: ExtendedSelectProps) => {
  return (
    <Controller
      key={`${name}-select`}
      name={name}
      control={control}
      render={({ field }) => {
        const selectedOption = options.find(
          (option) => option.value === field.value
        );
        const displayValue = selectedOption
          ? selectedOption.label
          : field.value;

        return viewMode ? (
          <LabelInput label={label} value={displayValue} />
        ) : (
          <CustomizedSelect
            {...field}
            variant="standard"
            placeholder={placeholder}
            onChange={
              onChange
                ? (e) => {
                    field.onChange(e);
                    onChange(e);
                  }
                : (e) => {
                    field.onChange(e);
                  }
            }
            sx={sx}
            fullWidth
            error={error}
            helperText={helperText}
            helperTextSize={helperTextSize}
            label={label}
            renderValue={renderValue}
            options={options}
            disabled={disabled}
            endAdornment={endAdorment}
            required={required}
            inputProps={inputProps}
            MenuProps={MenuProps}
          />
        );
      }}
    />
  );
};

export default ControlledSelect;
