import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { numberFormatter } from "utils/Formatter/Global";

export const useBillingNoteByItemReportColumnDefs = () => {
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        setColumnDefs([
            {
                field: "item_unique_id",
                headerName: "รหัสสินค้า",
                filter: "agTextColumnFilter",
                minWidth: 200,
                flex: 1,
            },
            {
                field: "item_name",
                headerName: "ชื่อสินค้า",
                filter: "agTextColumnFilter",
                minWidth: 200,
                flex: 1,
            },
            {
                field: "qty",
                headerName: "จำนวน",
                filter: "agNumberColumnFilter",
                cellStyle: { display: "flex", justifyContent: "flex-end" },
                headerClass: "ag-end-header",
                minWidth: 200,
                flex: 1,
            },
            {
                field: "uom_name",
                headerName: "หน่วย",
                filter: false,
                minWidth: 200,
                flex: 1,
            },
            {
                field: "net_amount",
                headerName: "มูลค่ารวมสุทธิ",
                filter: false,
                valueFormatter: (params: ValueFormatterParams) => {
                    if (params.value) return numberFormatter(params.value);
                    else return "0.00";
                },
                cellStyle: { display: "flex", justifyContent: "flex-end" },
                headerClass: "ag-end-header",
                minWidth: 200,
                flex: 1,
            },
            {
                field: "pre_vat_amount",
                headerName: "มูลค่าก่อนภาษี",
                filter: false,
                valueFormatter: (params: ValueFormatterParams) => {
                    if (params.value) return numberFormatter(params.value);
                    else return "0.00";
                },
                cellStyle: { display: "flex", justifyContent: "flex-end" },
                headerClass: "ag-end-header",
                minWidth: 200,
                flex: 1,
            },
            {
                field: "vat_amount",
                headerName: "ภาษีมูลค่าเพิ่มรวม",
                filter: false,
                valueFormatter: (params: ValueFormatterParams) => {
                    if (params.value) return numberFormatter(params.value);
                    else return "0.00";
                },
                cellStyle: { display: "flex", justifyContent: "flex-end" },
                headerClass: "ag-end-header",
                minWidth: 200,
                flex: 1,
            },
        ]);
    }, [t]);
    return columnDefs;
};