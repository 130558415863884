import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Box, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import { useModal } from "../../../../hooks/use-modal";
import BillingAddressModal from "../../../UI/Modal/BillingAddressModal";
import { useDisable } from "../../../../hooks/use-disable";
import { countryFormatter } from "../../../../utils/Formatter/Global";

type Props = {
  forceDisabled?: boolean;
};

const AddressInfo = ({ forceDisabled }: Props) => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const { getValues } = useFormContext();

  // const customerUniqueId = watch("customer_unique_id");

  const {
    modal: openBillingAddress,
    openModalHandler: openBillingAddressHandler,
    closeModalHandler: closeBillingAddress,
  } = useModal();

  const renderAddressString = (values: string) => {
    const addressValues = getValues(`customer_details[${values}]`);
    if (addressValues) {
      const { name, sub_district, district, province, postal_code, country } =
        addressValues;

      const orderedValues = {
        name,
        sub_district,
        district,
        province,
        postal_code,
        country,
      };
      let result = "";

      if (addressValues && orderedValues) {
        if (Array.isArray(addressValues)) {
          addressValues.forEach((item) => {
            if (item?.length > 0) {
              result = result + item + ", ";
            }
          });
        } else {
          const keys = Object.keys(orderedValues);
          keys.forEach((key) => {
            let newValue = "";
            if (key === "country") {
              newValue =
                countryFormatter(
                  orderedValues[key as keyof typeof orderedValues]
                ) || "";
            } else {
              newValue = orderedValues[key as keyof typeof orderedValues];
            }

            if (newValue && newValue.length > 0) {
              result = result + newValue + ", ";
            }
          });
        }
        if (result?.trim().length === 0) {
          return "-";
        }
      }
      // remove whitespace and last comma
      return result?.trim().slice(0, -1);
      // remove whitespace and last comma
    } else return "-";
  };

  // const renderAddressContact = () => {
  //   const addressValues = getValues("customer_details.delivery_address");

  //   if (addressValues) {
  //     const { contact_name, contact_phone } = addressValues;

  //     if (contact_name && contact_phone) {
  //       const result = `${contact_name}, ${contact_phone}`;
  //       return result;
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  return (
    <>
      <CustomizedBox>
        <Box display="flex" gap={1} alignItems="center" mb={1}>
          <Typography fontWeight={600}>
            {t("address.billing_address")}
          </Typography>
          {!disabled && !forceDisabled ? (
            <CustomizedTooltip title={`แก้ไข${t("address.billing_address")}`}>
              <IconButton
                onClick={openBillingAddressHandler}
                color="primary"
                size="small"
              >
                <EditIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </CustomizedTooltip>
          ) : null}
        </Box>
        <Typography>{renderAddressString("billing_address")}</Typography>
        {/* <Typography mt={2}>{renderAddressContact()}</Typography> */}
      </CustomizedBox>
      <BillingAddressModal
        name="customer_details"
        open={openBillingAddress}
        handleClose={closeBillingAddress}
        viewMode={false}
      />
    </>
  );
};

export default AddressInfo;
