import { useState, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import { Grid, Typography } from "@mui/material";
import ControlledDateTimePicker from "components/Controller/ControlledDateTimePicker";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import { useDisable } from "hooks/use-disable";
import ControlledTextField from "components/Controller/ControlledTextField";
import DocumentDropzoneUI from "components/UI/DocumentDropzoneUI";
import { SalesModelType } from "generated/sales";

type Props = {
  isModal?: boolean;
  documentType: SalesModelType;
};

const title = {
  quotation: "การตอบรับใบเสนอราคา",
  billing_note: "แนบหลักฐานการชำระ",
  delivery_trip: "",
  maintenance: "",
};

const SalesAcceptForm = ({ isModal, documentType }: Props) => {
  const [disabled] = useDisable();
  const [isDefaultSet, setIsDefaultSet] = useState(false);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const attachmentList = useWatch({
    control,
    name: "attachment_list",
  });

  const acceptedDate = useWatch({
    control,
    name: "accepted_date",
  });

  useEffect(() => {
    if (!isDefaultSet && !acceptedDate) {
      setValue("accepted_date", dayjs());
      setIsDefaultSet(true);
    } else if (!isDefaultSet && acceptedDate) {
      setIsDefaultSet(true);
    }
  }, [acceptedDate, isDefaultSet, setValue]);

  let enabledStatus: string;

  switch (documentType) {
    case "quotation":
      enabledStatus = "wait_accept";
      break;
    case "billing_note":
      enabledStatus = "wait_payment";
      break;
    default:
      enabledStatus = "wait_accept";
  }

  const datePicker = () => {
    switch (documentType) {
      case "quotation":
        return (
          <ControlledDateTimePicker
            name="accepted_date"
            control={control}
            label="วันที่"
            disabled={disabled && status !== enabledStatus}
            viewMode={disabled && status !== enabledStatus}
            required
          />
        );
      case "billing_note":
        return (
          <ControlledDatePicker
            name="accepted_date"
            control={control}
            label="วันที่"
            disabled={disabled && status !== enabledStatus}
            viewMode={disabled && status !== enabledStatus}
            required
          />
        );
      default:
        return (
          <ControlledDateTimePicker
            name="accepted_date"
            control={control}
            label="วันที่"
            disabled={disabled && status !== enabledStatus}
            viewMode={disabled && status !== enabledStatus}
            required
          />
        );
    }
  };

  return (
    <>
      {!isModal && (
        <Typography fontWeight="bold">{title[documentType]}</Typography>
      )}
      {(isModal || documentType === SalesModelType.Quotation) && (
        <Grid container spacing={1.5} alignItems="center" mt={0}>
          <Grid item md={isModal ? 6 : 2}>
            {datePicker()}
          </Grid>
        </Grid>
      )}
      {(isModal || documentType === SalesModelType.Quotation) && (
        <>
          <Typography fontWeight="bold" mt={2}>
            หลักฐานอ้างอิง
          </Typography>
          {isModal ? (
            <ControlledTextField
              name="accepted_remark"
              control={control}
              error={Boolean(errors.accepted_remark)}
              disabled={disabled && status !== enabledStatus}
              sx={{ mt: 2 }}
              multiline
              rows={4}
              viewMode={disabled && status !== enabledStatus}
            />
          ) : (
            <Grid container spacing={1.5} alignItems="center" mt={0}>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <ControlledTextField
                  name="accepted_remark"
                  control={control}
                  error={Boolean(errors.accepted_remark)}
                  disabled={disabled && status !== enabledStatus}
                  viewMode={disabled && status !== enabledStatus}
                  sx={{ my: 2 }}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
      {(isModal || attachmentList?.length > 0) && (
        <DocumentDropzoneUI
          control={control}
          name="attachment_list"
          setValue={setValue}
          disabled={disabled && status !== enabledStatus}
          isModal={isModal}
          maxSize={5}
          multiple
          fullWidth={isModal}
          customGridSize={2.5}
          separateAttachmentDate={!isModal}
        />
      )}
    </>
  );
};

export default SalesAcceptForm;
