import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IIncomeList } from "types/Logistic";

export const useIncomeSummary = () => {
  const { control, setValue } = useFormContext();
  const incomeList: IIncomeList[] = useWatch({
    control,
    name: "income_list",
  });

  const [summary, setSummary] = useState({
    income_post_discount_amount: 0,
    income_vat_exempted_amount: 0,
    income_vat_0_percent_amount: 0,
    income_vat_7_percent_amount: 0,
    income_vat_amount: 0,
    income_net_amount: 0,
    income_withholding_tax_amount: 0,
    income_total_amount: 0,
  });

  useEffect(() => {
    setSummary(
      ({
        income_post_discount_amount,
        income_vat_exempted_amount,
        income_vat_0_percent_amount,
        income_vat_7_percent_amount,
        income_vat_amount,
        income_net_amount,
        income_withholding_tax_amount,
        income_total_amount,
      }) => {
        const postDiscountAmount = incomeList.reduce(
          (acc, item) => acc + (item.pre_vat_amount || 0),
          0
        );
        income_post_discount_amount = postDiscountAmount;

        const vatExemptedAmount = incomeList
          .filter((item) => item?.vat_percentage === "ไม่มี")
          .reduce((acc, item) => {
            return acc + (item.pre_vat_amount || 0);
          }, 0);

        income_vat_exempted_amount = vatExemptedAmount;

        const vat0percentAmount = incomeList
          .filter((item) => item?.vat_percentage === "0")
          .reduce((acc, item) => {
            return acc + (item.pre_vat_amount || 0);
          }, 0);

        income_vat_0_percent_amount = vat0percentAmount;

        const vat7percentAmount = incomeList
          .filter((item) => item?.vat_percentage === "7")
          .reduce((acc, item) => {
            return acc + (item.pre_vat_amount || 0);
          }, 0);

        income_vat_7_percent_amount = vat7percentAmount;

        income_vat_amount = vat7percentAmount * 0.07;

        income_net_amount =
          income_vat_exempted_amount +
          income_vat_0_percent_amount +
          income_vat_7_percent_amount +
          income_vat_amount;

        const withholdingTaxAmount =
          incomeList
            .filter(
              (item) =>
                !["ยังไม่ระบุ", "ไม่มี", "", undefined, null].includes(
                  item?.withholding_tax_type
                )
            )
            .reduce((acc, item) => {
              return (
                acc +
                ((item.pre_vat_amount || 0) *
                  parseFloat(item?.withholding_tax_type || "0")) /
                  100
              );
            }, 0) || 0;
        income_withholding_tax_amount = withholdingTaxAmount;

        income_total_amount = income_net_amount - withholdingTaxAmount;

        return {
          income_post_discount_amount,
          income_vat_exempted_amount,
          income_vat_0_percent_amount,
          income_vat_7_percent_amount,
          income_vat_amount,
          income_net_amount,
          income_withholding_tax_amount,
          income_total_amount,
        };
      }
    );
  }, [incomeList]);

  useEffect(() => {
    setValue(
      "income_post_discount_amount",
      summary.income_post_discount_amount
    );
    setValue("income_vat_exempted_amount", summary.income_vat_exempted_amount);
    setValue(
      "income_vat_0_percent_amount",
      summary.income_vat_0_percent_amount
    );
    setValue(
      "income_vat_7_percent_amount",
      summary.income_vat_7_percent_amount
    );
    setValue("income_vat_amount", summary.income_vat_amount);
    setValue("income_net_amount", summary.income_net_amount);
    setValue(
      "income_withholding_tax_amount",
      summary.income_withholding_tax_amount
    );
    setValue("income_total_amount", summary.income_total_amount);
  }, [
    setValue,
    summary.income_net_amount,
    summary.income_post_discount_amount,
    summary.income_total_amount,
    summary.income_vat_0_percent_amount,
    summary.income_vat_7_percent_amount,
    summary.income_vat_amount,
    summary.income_vat_exempted_amount,
    summary.income_withholding_tax_amount,
  ]);

  return summary;
};
