import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import DashboardDateFilter from "../DateFilter";
import { IDashboardDateFilter } from "types/global";
import DeliveryTripChart from "./DeliveryTripChart";
import MaintenanceChart from "./MaintenanceChart";
import {
  DashboardLogisticInput,
  DashboardLogisticQuery,
  DateRange,
  InputMaybe,
  useDashboardLogisticQuery,
} from "generated/general";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const LogisticDashboard = () => {
  const { t } = useTranslation();

  const graphQLClient = createGraphQLClientWithMiddleware("general");
  const [input, setInput] = useState<InputMaybe<DashboardLogisticInput>>({
    date_range: DateRange.Last_7Days,
    start_date: dayjs().startOf("day").subtract(6, "day"),
    end_date: dayjs().endOf("day"),
  });

  const { data, refetch, isFetching } =
    useDashboardLogisticQuery<DashboardLogisticQuery>(
      graphQLClient,
      {
        input,
      },
      {
        enabled: !!input,
        staleTime: Infinity,
      }
    );

  const filterHandler = async (data: IDashboardDateFilter) => {
    await setInput(data);
    await refetch();
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <CustomizedBox
      bgcolor={"#F9F9F9"}
      border={`1px solid white`}
      maxWidth={1650}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h5">{t("logistic.index")}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <DashboardDateFilter
            filterHandler={filterHandler}
            isLoading={isFetching}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={2}>
            {isFetching ? (
              <Skeleton variant="rectangular" height={"calc(50% - 8px)"} />
            ) : (
              <Link
                to="/logistic/vehicle?type=approaching"
                style={{ textDecoration: "none" }}
              >
                <CustomizedBox
                  height={"calc(50% - 8px)"}
                  border={`1px solid white`}
                  margin={0}
                  padding={"0.5rem 1rem"}
                  bgcolor={"white"}
                  boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.05)"}
                  isNavigate
                >
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={16} fontWeight={600}>
                      {t("logistic.vehicle.index")}
                    </Typography>
                    <Typography fontSize={16} color={"warning.main"}>
                      {t("logistic.vehicle.approaching")}
                    </Typography>
                  </Box>
                  <Typography variant="h5" align="right" color={"warning.main"}>
                    {data?.DashboardLogistic?.vehicle?.approaching || 0}
                  </Typography>
                  <Typography align="right" fontSize={12} color={"#737373"}>
                    คัน
                  </Typography>
                </CustomizedBox>
              </Link>
            )}
            {isFetching ? (
              <Skeleton
                variant="rectangular"
                height={"calc(50% - 8px)"}
                sx={{ mt: 2 }}
              />
            ) : (
              <Link
                to="/logistic/vehicle?type=overdue"
                style={{ textDecoration: "none" }}
              >
                <CustomizedBox
                  height={"calc(50% - 8px)"}
                  border={`1px solid white`}
                  margin={"1rem 0 0 0"}
                  padding={"0.5rem 1rem"}
                  bgcolor={"white"}
                  boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.05)"}
                  isNavigate
                >
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={16} fontWeight={600}>
                      {t("logistic.vehicle.index")}
                    </Typography>
                    <Typography fontSize={16} color={"error.main"}>
                      {t("logistic.vehicle.overdue")}
                    </Typography>
                  </Box>
                  <Typography variant="h5" align="right" color={"error.main"}>
                    {data?.DashboardLogistic?.vehicle?.overdue || 0}
                  </Typography>
                  <Typography align="right" fontSize={12} color={"#737373"}>
                    คัน
                  </Typography>
                </CustomizedBox>
              </Link>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            {isFetching ? (
              <Skeleton variant="rectangular" height={212} />
            ) : (
              <Box sx={{ minHeight: 212, height: "100%" }}>
                <DeliveryTripChart data={data?.DashboardLogistic?.delivery} />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            {isFetching ? (
              <Skeleton variant="rectangular" height={212} />
            ) : (
              <Box sx={{ minHeight: 212, height: "100%" }}>
                <MaintenanceChart data={data?.DashboardLogistic?.maintenance} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </CustomizedBox>
  );
};

export default LogisticDashboard;
