import { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
// import CustomizedTextField from "../Custom/CustomizedTextField";
import {
  TextField,
  TextFieldProps,
  InputAdornment,
  Typography,
  SxProps,
} from "@mui/material";
import { StandardLonghandProperties } from "../../../node_modules/csstype/index";
import LabelInput from "../UI/LabelInput";
import { numberFormatter } from "../../utils/Formatter/Global";

type ExtendedNumberTextFieldProps = {
  disabled?: TextFieldProps["disabled"];
  control: Control<any>;
  label?: TextFieldProps["label"];
  name: string;
  error?: TextFieldProps["error"];
  sx?: TextFieldProps["sx"];
  helperText?: TextFieldProps["helperText"];
  onChange?: (value: number | undefined) => void;
  onKeyDown?: TextFieldProps["onKeyDown"];
  FormHelperTextProps?: TextFieldProps["FormHelperTextProps"];
  textAlign?: StandardLonghandProperties["textAlign"];
  InputLabelProps?: TextFieldProps["InputLabelProps"];
  InputProps?: TextFieldProps["InputProps"];
  placeholder?: TextFieldProps["placeholder"];
  pricePerUnit?: number;
  helperTextAlign?: StandardLonghandProperties["textAlign"];
  fullWidth?: boolean;
  endAdornmentMessage?: string;
  viewMode?: boolean;
  decimalScale?: number;
  allowNegative?: boolean;
  required?: boolean;
  showZero?: boolean;
  lebelSx?: SxProps;
};

const StyledTextField = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      sx={{
        "& .MuiOutlinedInput-root": {
          overflow: "hidden",
        },
        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: props.error ? "error.main" : undefined,
        },
        "& .MuiInputLabel-root.Mui-disabled": {
          color: props.error ? "error.main" : undefined,
        },
      }}
    />
  );
};

const ControlledNumberTextField = ({
  disabled,
  control,
  label,
  name,
  error,
  sx,
  onChange,
  helperText,
  pricePerUnit,
  onKeyDown,
  FormHelperTextProps,
  textAlign,
  InputLabelProps,
  helperTextAlign,
  fullWidth,
  InputProps,
  endAdornmentMessage,
  viewMode,
  decimalScale,
  allowNegative,
  required,
  showZero,
  placeholder,
  lebelSx,
}: ExtendedNumberTextFieldProps) => {
  const [warn, setWarn] = useState(false);
  const showWarning = () => setWarn(true);
  const hideWarning = () => setWarn(false);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <>
          {viewMode ? (
            <LabelInput
              label={label}
              value={
                showZero && field.value === 0
                  ? "0"
                  : field.value
                  ? decimalScale === 0
                    ? field.value.toLocaleString()
                    : numberFormatter(field.value)
                  : undefined
              }
              endAdornment={field.value ? endAdornmentMessage : undefined}
              textAlign={textAlign}
              lebelSx={lebelSx}
            />
          ) : (
            <NumericFormat
              fullWidth={fullWidth}
              size="small"
              label={label}
              value={field.value}
              allowNegative={Boolean(allowNegative)}
              thousandSeparator=","
              decimalScale={
                decimalScale ? decimalScale : decimalScale === 0 ? 0 : 2
              }
              onValueChange={(v) => {
                if (typeof v.floatValue === "undefined") {
                  field.onChange("");
                } else {
                  field.onChange(v.floatValue);
                }
                onChange && onChange(v.floatValue || 0);
                if (pricePerUnit) {
                  const value = v.floatValue || 0;
                  if (pricePerUnit > value) {
                    showWarning();
                  } else hideWarning();
                }
              }}
              disabled={disabled}
              customInput={StyledTextField}
              helperText={helperText}
              error={error || warn}
              inputProps={{
                style: { textAlign: textAlign ? textAlign : "right" },
              }}
              InputProps={{
                ...InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {endAdornmentMessage}
                    {endAdornmentMessage && !label && required && (
                      <Typography color="red" ml={1}>
                        *
                      </Typography>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={InputLabelProps}
              FormHelperTextProps={
                FormHelperTextProps
                  ? FormHelperTextProps
                  : {
                      style: {
                        textAlign: helperTextAlign ? helperTextAlign : "left",
                      },
                    }
              }
              onKeyDown={onKeyDown}
              sx={sx}
              fixedDecimalScale
              required={required}
              onFocus={(e) => e.target.select()}
              placeholder={placeholder}
            />
          )}
        </>
      )}
    />
  );
};

export default ControlledNumberTextField;
