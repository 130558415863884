import { GraphQLClient } from "graphql-request";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { CONTACT_VIEW } from "../../../../services/AgGrid/ContactAgGrid";
import CheckboxAggridModal from "../CheckboxAggridModal";
import useSupplierColumnDefs from "../../../Table/Contact/supplierColumnDefs";

type CheckedSupplierModalProps = {
  showSelectSupplier: boolean;
  closeSupplierTable: () => void;
  finishSuppliersSelect: (data: any) => void;
  supplierIds: string[];
  setSupplierIds: Dispatch<SetStateAction<string[]>>;
  supplierIdsSnapshot: string[];
  setSupplierIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowSelection?: "single";
};

const CheckedSupplierModal = ({
  showSelectSupplier,
  closeSupplierTable,
  finishSuppliersSelect,
  supplierIds,
  setSupplierIds,
  supplierIdsSnapshot,
  setSupplierIdsSnapshot,
  rowSelection,
}: CheckedSupplierModalProps) => {
  const { t } = useTranslation();
  const selectSupplierGridRef = useRef();

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware("crm");

  const columnDefs = useSupplierColumnDefs();

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const formatFilter = {
        ...filterModel,
        status: filterModel.status && {
          filterType: "set",
          values: filterModel.status.values.map(
            (value: string) => value && parseInt(value)
          ),
        },
        contact_type: {
          type: "hasSome",
          filterType: "array",
          values: ["is_supplier"],
        },
      };
      try {
        const { ContactViewAggrid } = await graphQLClient.request(
          CONTACT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );

        params.success({
          rowData: ContactViewAggrid.results as any[],
          rowCount: ContactViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    const instance = params.api.getFilterInstance("status");
    instance?.setModel({
      values: ["1"],
    });
    params.api.onFilterChanged();
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxAggridModal
      modalTitle={t("purchase.supplier.index")}
      gridRef={selectSupplierGridRef}
      height={450}
      columnDefs={columnDefs}
      rowSelection={rowSelection ?? "multiple"}
      onFinishEditing={finishSuppliersSelect}
      modalIsOpen={showSelectSupplier}
      getRowId={getRowId}
      closeModal={closeSupplierTable}
      selectedIds={supplierIds}
      setSelectedIds={setSupplierIds}
      idsSnapshot={supplierIdsSnapshot}
      setIdsSnapshot={setSupplierIdsSnapshot}
      onGridReady={onGridReady}
    />
  );
};

export default CheckedSupplierModal;
