import { Box } from "@mui/material";
import DeliveryCarInfoModal from "components/UI/Modal/Logistic/DeliveryCarInfoModal";
import gantt from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import { useEffect, useRef, useState } from "react";
import { Task } from "types/Logistic/ganttChart";
import { formatDateTime } from "utils/Date";
import DeliveryTaskInfoModal from "../../Modal/Logistic/DeliveryTaskInfoModal";
import GanttStyles from "./GanttStyles";
import TaskBarText from "./TaskBarText";

type Props = {
  data: any;
  date: any;
};

const GanttChart = ({ data, date }: Props) => {
  const ganttContainer = useRef<HTMLDivElement>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [carModalOpen, setCarModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [selectedCar, setSelectedCar] = useState<any>(null);

  const formatDateForGantt = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedMonth = month < 10 ? `${month}` : `${month}`;
    const formattedDay = day < 10 ? `${day}` : `${day}`;
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}`;
  };

  useEffect(() => {
    const getTomorrow = () => {
      const tomorrow = new Date(date);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      return tomorrow;
    };

    const getDate9AM = () => {
      const selectedDate = new Date(date);
      selectedDate.setHours(0, 0, 0, 0);
      return selectedDate;
    };
    const startDate = getDate9AM();
    const endDate = getTomorrow();

    gantt.plugins({
      marker: true,
    });

    if (ganttContainer.current) {
      gantt.config.columns = [
        {
          name: "text",
          label: "รถ",
          width: 120,
          tree: true,
          resize: true,
          template: (task: any) =>
            task.parent
              ? ""
              : `<span class="car-name" data-id="${task.id}">${task.car.id}</span>`,
        },
        {
          name: "status",
          label: "สถานะ",
          width: 120,
          resize: true,
          template: (task: any) => {
            let statusClass = "";
            switch (task.status) {
              case "available":
                statusClass = "status-vacant";
                break;
              case "in_progress":
                statusClass = "status-operating";
                break;
              case "maintenance":
                statusClass = "status-repair";
                break;
              case "incident":
                statusClass = "status-incident";
                break;
              default:
                statusClass = "status-stop";
            }
            return `<div class="car-status ${statusClass}">${getStatusName(
              task.status
            )}</div>`;
          },
        },
      ];

      const getStatusName = (status: string): string => {
        switch (status) {
          case "available":
            return "ว่าง";
          case "in_progress":
            return "ดำเนินการ";
          case "incident":
            return "อุบัติเหตุ";
          case "maintenance":
            return "ระหว่างซ่อม";
          case "in_active":
            return "หยุดใช้งาน";
          default:
            return status;
        }
      };

      gantt.config.readonly = true;

      gantt.config.scale_unit = "hour";
      gantt.config.date_scale = "%H:%i";
      gantt.config.step = 1;
      gantt.config.date_format = "%Y-%m-%d %H:%i";

      gantt.config.row_height = 48;
      gantt.config.grid_row_height = 48;
      gantt.config.task_row_height = 48;

      gantt.config.start_date = startDate;
      gantt.config.end_date = endDate;

      gantt.config.show_errors = false;

      gantt.templates.scale_date = (date: Date) => {
        return gantt.date.date_to_str("%H:%i")(date);
      };

      gantt.templates.task_text = (start: Date, end: Date, task: any) => {
        return TaskBarText(task);
      };

      gantt.templates.task_class = function (
        start: any,
        end: any,
        task: { type: string; status: string; today_task: boolean }
      ) {
        if (task.status === "in_active") return "task-status-out-of-service";
        if (!task.today_task) {
          return "task-status-vacant";
        } else {
          if (task.status === "available") {
            if (task.type === "DT") {
              return "task-delivery-trip";
            } else if (task.type === "MA") {
              return "task-ma";
            } else {
              return "task-status-vacant";
            }
          } else if (task.status === "incident") {
            if (!task.today_task) {
              return "task-status-vacant";
            } else if (task.type === "DT") {
              return "task-delivery-trip";
            } else if (task.type === "MA") {
              return "task-ma";
            }
          } else {
            if (task.type === "DT") {
              return "task-delivery-trip";
            } else if (task.type === "MA") {
              return "task-ma";
            }
          }
        }

        return "";
      };

      gantt.templates.task_row_class = function (
        start: any,
        end: any,
        task: { status: string; type: string; parent: any }
      ) {
        if (
          task.status === "incident" ||
          (task.parent && gantt.getTask(task.parent).status === "incident")
        ) {
          return "row-accident";
        } else if (task.status === "in_progress") {
          return "row-operating";
        } else if (task.status === "maintenance") {
          return "row-maintenance";
        }
        return "";
      };

      const markerId = gantt.addMarker({
        start_date: new Date(),
        css: "today",
        text: "Now",
        title: "Current time",
      });

      if (markerId !== null && markerId !== undefined) {
        setInterval(() => {
          const now = gantt.getMarker(markerId);
          if (now) {
            now.start_date = new Date();
            gantt.updateMarker(markerId);
          }
        }, 60000);
      }

      gantt.attachEvent("onBeforeTaskDisplay", function (id, task) {
        if (task.parent !== 0) {
          const parent = gantt.getTask(task.parent);
          parent.open = true;
        }
        return true;
      });

      gantt.attachEvent("onTaskLoading", function (task) {
        task.open = true;
        return true;
      });

      const getTasks = () => {
        let taskList: any[] = [];
        let id = 1;
        let parentId = 0;

        data.forEach((car: any) => {
          let stopEndDate: Date = new Date(date);
          stopEndDate.setDate(stopEndDate.getDate() + 1);
          const defaultTask = {
            id: id++,
            text: car.status === "in_active" ? "หยุดใช้งาน" : car.car?.id,
            status: car.status,
            start_date: new Date(new Date(date).setHours(0, 0, 0, 1)),
            end_date: new Date(new Date(stopEndDate).setHours(23, 59, 59, 59)),
            stop_date: formatDateTime(car.stop_date),
            today_task: false,
            task: car.tasks,
            car_id: car.car?.id,
            car: car.car,
            isParent: true,
            remark: car.remark,
          };

          if (!car.today_task || car.tasks.length === 0) {
            taskList.push(defaultTask);
            return;
          }

          car.tasks.forEach((task: Task, index: number) => {
            const taskText =
              task.type === "DT"
                ? `${task.info.document_id} - ${task.info.deliver_1}`
                : task.info.document_id;

            const formattedStartDate = formatDateForGantt(task.info.start_date);
            const formattedEndDate = formatDateForGantt(task.info.end_date);

            const taskDetails = {
              id: id,
              text: taskText,
              status: task.info.status,
              start_date: formattedStartDate,
              end_date: formattedEndDate,
              car_id: car.car?.id,
              car: car.car,
              type: task.type,
              today_task: true,
              task: {
                ...task,
                info: {
                  ...task.info,
                  start_date: formatDateTime(task.info.start_date),
                  end_date: formatDateTime(task.info.end_date),
                },
              },
              isParent: index === 0,
              parent: index === 0 ? undefined : parentId,
              trailer: car.trailer,
              remark: task.type === "MA" ? task.info.remark : car.remark,
            };

            taskList.push(taskDetails);
            if (index === 0) parentId = id;
            id++;
          });
        });

        const statusOrder = [
          "available",
          "in_progress",
          "maintenance",
          "incident",
          "in_active",
        ];

        const results = taskList.sort(
          (a, b) =>
            statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
        );

        return results;
      };

      gantt.init(ganttContainer.current);

      gantt.setSizes();

      gantt.parse({
        data: getTasks(),
      });

      const nineAM = new Date();
      nineAM.setHours(9, 0, 0, 0);
      setTimeout(() => {
        const scrollPosition = gantt.posFromDate(nineAM);
        gantt.scrollTo(scrollPosition, null);
      }, 100);

      const handleCarNameClick = (event: Event) => {
        const target = event.target as HTMLElement;
        const carNameElement = target.querySelector(".car-name");
        if (carNameElement) {
          const id = carNameElement.getAttribute("data-id");
          if (id) {
            const car = gantt.getTask(id);
            setSelectedCar(car);
            setCarModalOpen(true);
          }
          return;
        }

        if (target.classList.contains("car-name")) {
          const id = target.getAttribute("data-id");
          if (id) {
            const car = gantt.getTask(id);
            setSelectedCar(car);
            setCarModalOpen(true);
          }
          return;
        }
      };

      const handleTaskBarClick = (event: Event) => {
        const target = event.target as HTMLElement;
        if (target.getAttribute("bar-task-id")) {
          const id = target.getAttribute("bar-task-id");
          if (id) {
            const task = gantt.getTask(id);
            setSelectedTask(task);
            setModalOpen(true);
          }
        }
      };

      ganttContainer.current.addEventListener("click", handleCarNameClick);
      ganttContainer.current.addEventListener("click", handleTaskBarClick);

      return () => {
        ganttContainer.current?.removeEventListener(
          "click",
          handleCarNameClick
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ganttContainer.current?.removeEventListener(
          "click",
          handleTaskBarClick
        );
        gantt.clearAll();
      };
    }
  }, [data, date]);

  const handleModalClose = () => setModalOpen(false);
  const handleCarModalClose = () => setCarModalOpen(false);

  return (
    <Box>
      <GanttStyles />
      <Box ref={ganttContainer} sx={{ width: "100%", height: `485px` }} />
      <DeliveryTaskInfoModal
        open={modalOpen}
        handleClose={handleModalClose}
        data={selectedTask}
      />
      <DeliveryCarInfoModal
        open={carModalOpen}
        handleClose={handleCarModalClose}
        data={selectedCar}
      />
    </Box>
  );
};

export default GanttChart;
