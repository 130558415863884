import { Grid, Typography } from "@mui/material";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import ControlledSSCreatable from "components/Controller/ControlledSSCreatable";
import ControlledTextField from "components/Controller/ControlledTextField";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { useDisable } from "hooks/use-disable";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IVehicle } from "types/Logistic/vehicle";

const InsuranceInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [disable] = useDisable();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<IVehicle>();

  const type = useWatch({
    control,
    name: "type",
  });

  return (
    <CustomizedBox>
      <Grid container spacing={2.3}>
        <Grid item xs={12}>
          <Grid container spacing={2.3}>
            <Grid item xs={12}>
              <Typography fontWeight={600}>รายละเอียดประกันรถ</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1.5}>
                <Grid item xs={12} md={4}>
                  <ControlledSSCreatable
                    control={control}
                    setValue={setValue}
                    documentType="vehicle"
                    name="insurance_vehicle.tier"
                    label={t("logistic.vehicle.insurance_detail.tier")}
                    defaultOptions={[]}
                    error={Boolean(errors.insurance_vehicle?.tier)}
                    helperText={errors?.insurance_vehicle?.tier?.message?.toString()}
                    viewMode={Boolean(id) && disable}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ControlledTextField
                    name="insurance_vehicle.policy_id"
                    control={control}
                    label={t("logistic.vehicle.insurance_detail.policy_id")}
                    error={Boolean(errors?.insurance_vehicle?.policy_id)}
                    helperText={
                      errors?.insurance_vehicle?.policy_id &&
                      errors?.insurance_vehicle?.policy_id.message?.toString()
                    }
                    viewMode={Boolean(id) && disable}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ControlledNumberTextField
                    name="insurance_vehicle.protection_value"
                    control={control}
                    label={t(
                      "logistic.vehicle.insurance_detail.protection_value"
                    )}
                    error={Boolean(errors?.insurance_vehicle?.protection_value)}
                    helperText={
                      errors?.insurance_vehicle?.protection_value &&
                      errors?.insurance_vehicle?.protection_value.message?.toString()
                    }
                    fullWidth
                    viewMode={Boolean(id) && disable}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ControlledDatePicker
                    name="insurance_vehicle.start_date"
                    control={control}
                    label={t("logistic.vehicle.insurance_detail.start_date")}
                    viewMode={Boolean(id) && disable}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ControlledDatePicker
                    name="insurance_vehicle.end_date"
                    control={control}
                    label={t("logistic.vehicle.insurance_detail.end_date")}
                    viewMode={Boolean(id) && disable}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {type !== "trailer" && (
          <Grid item xs={12}>
            <Grid container spacing={2.3}>
              <Grid item xs={12}>
                <Typography fontWeight={600}>รายละเอียดประกันสินค้า</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12} md={4}>
                    <ControlledSSCreatable
                      control={control}
                      setValue={setValue}
                      documentType="vehicle"
                      name="insurance_product.tier"
                      label={t("logistic.vehicle.insurance_detail.tier")}
                      defaultOptions={[]}
                      error={Boolean(errors.insurance_product?.tier)}
                      helperText={errors?.insurance_product?.tier?.message?.toString()}
                      viewMode={Boolean(id) && disable}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      name="insurance_product.policy_id"
                      control={control}
                      label={t("logistic.vehicle.insurance_detail.policy_id")}
                      error={Boolean(errors?.insurance_product?.policy_id)}
                      helperText={
                        errors?.insurance_product?.policy_id &&
                        errors?.insurance_product?.policy_id.message?.toString()
                      }
                      viewMode={Boolean(id) && disable}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledNumberTextField
                      name="insurance_product.protection_value"
                      control={control}
                      label={t(
                        "logistic.vehicle.insurance_detail.protection_value"
                      )}
                      error={Boolean(
                        errors?.insurance_product?.protection_value
                      )}
                      helperText={
                        errors?.insurance_product?.protection_value &&
                        errors?.insurance_product?.protection_value.message?.toString()
                      }
                      fullWidth
                      viewMode={Boolean(id) && disable}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledDatePicker
                      name="insurance_product.start_date"
                      control={control}
                      label={t("logistic.vehicle.insurance_detail.start_date")}
                      viewMode={Boolean(id) && disable}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledDatePicker
                      name="insurance_product.end_date"
                      control={control}
                      label={t("logistic.vehicle.insurance_detail.end_date")}
                      viewMode={Boolean(id) && disable}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </CustomizedBox>
  );
};

export default InsuranceInfo;
