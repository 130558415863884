import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import { useDisable } from "hooks/use-disable";
import { FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import EditIcon from "@mui/icons-material/Edit";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledSelect from "components/Controller/ControlledSelect";
import CheckedVehicleModal from "components/UI/Modal/CheckedModal/CheckedVehicleModal";
import { useDeliveryTripModal } from "hooks/Logistic/DeliveryTrip/use-delivery-trip-modal";
import CheckedUserModal from "components/UI/Modal/CheckedModal/CheckedUserModal";
import { ICreatedBy, ISelectOption } from "types/global";
import CheckedTrailerModal from "components/UI/Modal/CheckedModal/CheckedTrailerModal";
import { useSnackbar } from "notistack";
import { workListQueryFormatter } from "utils/Formatter/Logistic/DeliveryTrip";
import { useConfirmation } from "hooks/use-confirmation";
import VehicleConfirmation from "components/UI/Modal/VehicleConfirmation";
import { useState } from "react";

const typeOptions: ISelectOption[] = [
  {
    label: "4 ล้อ",
    value: "four_wheel",
    disabled: false,
  },
  {
    label: "6 ล้อ",
    value: "six_wheel",
    disabled: false,
  },
  {
    label: "10 ล้อ",
    value: "ten_wheel",
    disabled: false,
  },
  {
    label: "หัวลาก",
    value: "head",
    disabled: false,
  },
  {
    label: "หางลาก",
    value: "trailer",
    disabled: false,
  },
];

const DeliveryTripVehicle = () => {
  const {
    control,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useFormContext<IDeliveryTrip>();

  const { enqueueSnackbar } = useSnackbar();

  const [vehicleData, setVehicleData] = useState<any>();

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;

  const [disabled] = useDisable();

  const { t } = useTranslation();

  const { vehicle, primaryDriver, secondaryDriver, trailer } =
    useDeliveryTripModal();

  const vehicleChangeHandler = async (vehicleData: any) => {
    if (vehicleData) {
      const {
        id,
        type,
        unique_id,
        register_id,
        trailer_id,
        trailer_unique_id,
        trailer_register_id,
        driver_object_list,
        current_kilometer,
      } = vehicleData;
      const vehicleObject = {
        type,
        vehicle_no: unique_id,
        license_plate: register_id,
      };

      const primaryDriverObject =
        driver_object_list &&
        driver_object_list.length > 0 &&
        driver_object_list[0];

      let formatPrimaryDriver: ICreatedBy | undefined;

      if (primaryDriverObject) {
        const { user_id, ...otherPrimary } = primaryDriverObject;

        formatPrimaryDriver = {
          ...otherPrimary,
          user_unique_id: user_id,
          name:
            driver_object_list[0].first_name +
            " " +
            driver_object_list[0].last_name,
        };
      }

      const secondaryDriverObject =
        driver_object_list &&
        driver_object_list.length > 1 &&
        driver_object_list[1];

      let formatSecondaryDriver: ICreatedBy | undefined;

      if (secondaryDriverObject) {
        const { user_id, ...otherSecondary } = secondaryDriverObject;

        formatSecondaryDriver = {
          ...otherSecondary,
          user_unique_id: user_id,
          name:
            driver_object_list[1].first_name +
            " " +
            driver_object_list[1].last_name,
        };
      }

      const workList = getValues("work_list");
      const isFrozen = getValues("is_frozen");

      const newWorkList = await workListQueryFormatter(
        workList,
        vehicleObject.type,
        isFrozen
      );

      const endKilometer = getValues("end_kilometer");

      reset((prev) => ({
        ...prev,
        vehicle: vehicleObject,
        vehicle_id: id,
        trailer_id: trailer_id,
        trailer: {
          trailer_no: trailer_unique_id,
          license_plate: trailer_register_id,
        },
        primary_driver: formatPrimaryDriver,
        secondary_driver: formatSecondaryDriver,
        start_kilometer: current_kilometer,
        total_kilometer_distance: endKilometer
          ? endKilometer - current_kilometer
          : 0,
        work_list: newWorkList,
      }));
    } else {
      reset((prev) => ({
        ...prev,
        vehicle: {
          type: "",
          vehicle_no: "",
          license_plate: "",
        },
        vehicle_id: undefined,
        trailer_id: undefined,
        trailer: {
          trailer_no: "",
          license_plate: "",
        },
        primary_driver: undefined,
        secondary_driver: undefined,
        start_kilometer: undefined,
      }));
    }
  };

  const finishVehiclesSelect = async (vehicleData: any) => {
    const workList = getValues("work_list");
    if (workList.length > 0) {
      openVehicleConfirmation();
    } else {
      await vehicleChangeHandler(vehicleData);
    }
    setVehicleData(vehicleData);
  };

  const finishTrailersSelect = (data: any) => {
    if (data) {
      const { id, unique_id, register_id } = data;
      reset((prev) => ({
        ...prev,
        trailer_id: id,
        trailer: {
          trailer_no: unique_id,
          license_plate: register_id,
        },
      }));
    } else {
      reset((prev) => ({
        ...prev,
        trailer_id: undefined,
        trailer: {
          trailer_no: "",
          license_plate: "",
        },
      }));
    }
  };

  const finishPrimaryDriverSelect = (data: any) => {
    if (data) {
      const secondaryDriver = getValues("secondary_driver");

      const { unique_id, first_name, last_name, email, img_url } = data;

      if (secondaryDriver && secondaryDriver.user_unique_id === unique_id) {
        enqueueSnackbar("พนักงานขนส่งไม่สามารถซ้ำกันภายในเอกสาร", {
          variant: "error",
        });
      } else {
        const formatData = {
          user_unique_id: unique_id,
          name: `${first_name} ${last_name}`,
          first_name,
          last_name,
          email,
          img_url,
        };
        reset((prev) => ({ ...prev, primary_driver: formatData }));
      }
    } else {
      reset((prev) => ({ ...prev, primary_driver: undefined }));
    }
  };

  const finishSecondaryDriverSelect = (data: any) => {
    if (data) {
      const primaryDriver = getValues("primary_driver");

      const { unique_id, first_name, last_name, email, img_url } = data;

      if (primaryDriver && primaryDriver.user_unique_id === unique_id) {
        enqueueSnackbar("พนักงานขนส่งไม่สามารถซ้ำกันภายในเอกสาร", {
          variant: "error",
        });
      } else {
        const formatData = {
          user_unique_id: unique_id,
          name: `${first_name} ${last_name}`,
          first_name,
          last_name,
          email,
          img_url,
        };
        reset((prev) => ({ ...prev, secondary_driver: formatData }));
      }
    } else {
      reset((prev) => ({ ...prev, secondary_driver: undefined }));
    }
  };

  const {
    confirmation: vehicleConfirmation,
    openConfirmationHandler: openVehicleConfirmation,
    closeConfirmationHandler: closeVehicleConfirmation,
    submitConfirmationHandler: submitVehicleConfirmation,
  } = useConfirmation(async () => await vehicleChangeHandler(vehicleData));

  const disabledStatus = ["finished"].includes(status);

  return (
    <>
      <CustomizedBox>
        <Box display={"flex"} alignItems={"center"} mb={1} gap={1}>
          <Typography fontWeight={600}>
            {t("logistic.delivery_trip.vehicle.index")}
          </Typography>
          {!disabled && !disabledStatus && (
            <CustomizedTooltip title="เลือกรถ">
              <IconButton
                onClick={vehicle.openModalHandler}
                color="primary"
                size="small"
              >
                <EditIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </CustomizedTooltip>
          )}
        </Box>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledSelect
              label={t("logistic.delivery_trip.vehicle.vehicle_type")}
              control={control}
              name="vehicle.type"
              options={typeOptions}
              error={Boolean(errors?.vehicle?.type)}
              helperText={(errors?.vehicle?.type as FieldError)?.message}
              required
              disabled
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              label={t("logistic.delivery_trip.vehicle.vehicle_no")}
              control={control}
              name="vehicle.vehicle_no"
              error={Boolean(errors?.vehicle?.vehicle_no)}
              helperText={errors?.vehicle?.vehicle_no?.message}
              required
              disabled
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              label={t("logistic.delivery_trip.vehicle.license_plate")}
              control={control}
              name="vehicle.license_plate"
              error={Boolean(errors?.vehicle?.license_plate)}
              helperText={errors?.vehicle?.license_plate?.message}
              required
              disabled
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              control={control}
              name="trailer.license_plate"
              label={t("logistic.delivery_trip.trailer.license_plate")}
              error={Boolean(errors?.trailer?.license_plate)}
              InputProps={{
                endAdornment: !disabled && !disabledStatus && (
                  <InputAdornment position="end">
                    <CustomizedTooltip
                      title={`เลือก${t(
                        "logistic.delivery_trip.trailer.license_plate"
                      )}`}
                    >
                      <IconButton
                        onClick={trailer.openModalHandler}
                        color="primary"
                        size="small"
                      >
                        <EditIcon
                          sx={{
                            fontSize: 16,
                          }}
                        />
                      </IconButton>
                    </CustomizedTooltip>
                  </InputAdornment>
                ),
              }}
              disabled
              viewMode={disabled}
            />
          </Grid>
        </Grid>
        <Typography fontWeight={600} my={1}>
          {t("logistic.delivery_trip.primary_driver.index")}
        </Typography>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              control={control}
              name="primary_driver.name"
              label={t("logistic.delivery_trip.primary_driver.index")}
              error={Boolean(errors.primary_driver?.name)}
              helperText={errors.primary_driver?.name?.message}
              InputProps={{
                endAdornment: !disabled && !disabledStatus && (
                  <InputAdornment position="end">
                    <CustomizedTooltip
                      title={`เลือก${t(
                        "logistic.delivery_trip.primary_driver.index"
                      )}`}
                    >
                      <IconButton
                        onClick={primaryDriver.openModalHandler}
                        color="primary"
                        size="small"
                      >
                        <EditIcon
                          sx={{
                            fontSize: 16,
                          }}
                        />
                      </IconButton>
                    </CustomizedTooltip>
                  </InputAdornment>
                ),
              }}
              disabled
              required
              viewMode={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledTextField
              control={control}
              name="secondary_driver.name"
              label={t("logistic.delivery_trip.secondary_driver.index")}
              error={Boolean(errors.secondary_driver?.name)}
              helperText={errors.secondary_driver?.name?.message}
              InputProps={{
                endAdornment: !disabled && !disabledStatus && (
                  <InputAdornment position="end">
                    <CustomizedTooltip
                      title={`เลือก${t(
                        "logistic.delivery_trip.secondary_driver.index"
                      )}`}
                    >
                      <IconButton
                        onClick={secondaryDriver.openModalHandler}
                        color="primary"
                        size="small"
                      >
                        <EditIcon
                          sx={{
                            fontSize: 16,
                          }}
                        />
                      </IconButton>
                    </CustomizedTooltip>
                  </InputAdornment>
                ),
              }}
              disabled
              viewMode={disabled}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <CheckedVehicleModal
        showSelectVehicle={vehicle.modal}
        vehicleIds={vehicle.ids}
        setVehicleIds={vehicle.setIdsSnapshot}
        vehicleIdsSnapshot={vehicle.idsSnapshot}
        setVehicleIdsSnapshot={vehicle.setIdsSnapshot}
        rowSelection="single"
        closeVehicleTable={vehicle.closeModalHandler}
        finishVehiclesSelect={finishVehiclesSelect}
      />
      <CheckedTrailerModal
        showSelectTrailer={trailer.modal}
        trailerId={trailer.ids}
        setTrailerId={trailer.setIdsSnapshot}
        trailerIdSnapshot={trailer.idsSnapshot}
        setTrailerIdSnapshot={trailer.setIdsSnapshot}
        closeTrailerTable={trailer.closeModalHandler}
        finishTrailerSelect={finishTrailersSelect}
        trailerOnly={false}
      />
      <CheckedUserModal
        showSelectUser={primaryDriver.modal}
        userIds={primaryDriver.ids}
        setUserIds={primaryDriver.setIdsSnapshot}
        userIdsSnapshot={primaryDriver.idsSnapshot}
        setUserIdsSnapshot={primaryDriver.setIdsSnapshot}
        rowSelection="single"
        closeUserTable={primaryDriver.closeModalHandler}
        finishUsersSelect={finishPrimaryDriverSelect}
        isDriver
      />
      <CheckedUserModal
        showSelectUser={secondaryDriver.modal}
        userIds={secondaryDriver.ids}
        setUserIds={secondaryDriver.setIdsSnapshot}
        userIdsSnapshot={secondaryDriver.idsSnapshot}
        setUserIdsSnapshot={secondaryDriver.setIdsSnapshot}
        rowSelection="single"
        closeUserTable={secondaryDriver.closeModalHandler}
        finishUsersSelect={finishSecondaryDriverSelect}
        isDriver
      />
      <VehicleConfirmation
        confirmation={vehicleConfirmation}
        closeConfirmationHandler={closeVehicleConfirmation}
        submitConfirmationHandler={submitVehicleConfirmation}
      />
    </>
  );
};

export default DeliveryTripVehicle;
