import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDeliveryTripListPdfHeader } from "./columnDef";
import { IDeliveryTripList } from "types/Sales/billing-note";
import { numberFormatter } from "utils/Formatter/Global";
import { formatDate } from "utils/Date";

type Props = {
  itemList: IDeliveryTripList[];
  startNumber: number;
  lastTable: boolean;
};

export const vehicleTypes = (type: string) => {
  switch (type) {
    case "head":
      return "หัวลาก";
    case "four_wheel":
      return "4 ล้อ";
    case "six_wheel":
      return "6 ล้อ";
    case "ten_wheel":
      return "10 ล้อ";
    case "trailer":
      return "หางลาก";
    default:
      return "";
  }
};

const DeliveryTripListPdfTable = ({
  itemList,
  startNumber,
  lastTable,
}: Props) => {
  const headers = useDeliveryTripListPdfHeader();

  const tablePadding = {
    px: 1,
    py: 1,
    borderBottom: "none",
  };

  const fontSize = 10.66;

  return (
    <>
      <Box>
        <TableContainer
          sx={{
            border: `1px solid #d6cfcf`,
            borderBottom: "none",
            borderRadius: "5.33px",
            my: 1,
          }}
        >
          <Table aria-label="simple table">
            <TableHead sx={{ bgcolor: "#ECF6FD" }}>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align || "center"}
                    key={index}
                    sx={tablePadding}
                    width={header.width}
                  >
                    <Typography fontSize={fontSize} fontWeight={600}>
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {itemList.map((item, index) => (
                <>
                  <TableRow
                    key={item.unique_id}
                    sx={{
                      verticalAlign: "top",
                      height: "32.52px",
                      "& .MuiTableCell-root": {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <TableCell align="center">
                      <Typography fontSize={fontSize}>
                        {startNumber + index}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography fontSize={fontSize}>
                        {item.dt_reference_unique_id}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography fontSize={fontSize}>
                        {formatDate(item.issue_date)}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography fontSize={fontSize}>
                        {item.vehicle_details?.license_plate}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography fontSize={fontSize}>
                        {vehicleTypes(item.vehicle_details?.type ?? "")}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography fontSize={fontSize}>
                        {item.source_location}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography fontSize={fontSize}>
                        {item.destination_location}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        fontSize={fontSize}
                        sx={{
                          wordBreak: "break-word",
                        }}
                      >
                        {numberFormatter(item.income_post_discount_amount)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      breakInside: "avoid",
                    }}
                  >
                    <TableCell />
                    <td
                      align="left"
                      colSpan={10}
                      valign="top"
                      style={{
                        borderBottom: "1px solid #E0E0E0",
                      }}
                    >
                      <Typography
                        fontSize={fontSize}
                        sx={{ ml: 0.5, mb: 1, color: "#737373" }}
                      >
                        {item.description || "-"}
                      </Typography>
                    </td>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default DeliveryTripListPdfTable;
