import { Box, Grid, IconButton, Typography } from "@mui/material";
import ControlledTextField from "components/Controller/ControlledTextField";
import CustomizedMenuOptions from "components/Custom/CustomizedMenuOptions";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import EmployeeListForm from "components/Form/EmployeeList";
import Confirmation from "components/UI/Confirmation";
import { useDeliveryTripOption } from "hooks/Logistic/DeliveryTrip/use-delivery-trip-option";
import { useConfirmation } from "hooks/use-confirmation";
import { useDisable } from "hooks/use-disable";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IMenuOption } from "types/global";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import {
  SalesModelType,
  SalesUniqueIdGenerateQuery,
  useSalesUniqueIdGenerateQuery,
} from "generated/sales";
import { useCallback, useEffect } from "react";
import {
  copyDeliveryTripHandler,
  createMaintenanceFromDeliveryTrip,
} from "utils/Formatter/Logistic/DeliveryTrip";
import { useStateContext } from "contexts/auth-context";
import CancelConfirmation from "components/UI/CancelConfirmation";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CustomizedChips from "components/Custom/CustomizedChips";
import { VEHICLE_VIEW } from "services/AgGrid/VehicleAgGrid";

type Props = {
  editClickHandler: () => void;
  cancelHandler?: () => void;
  isEdit: boolean;
};

const DeliveryTripHeader = ({
  cancelHandler,
  editClickHandler,
  isEdit,
}: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext<IDeliveryTrip>();
  const navigate = useNavigate();

  const {
    state: { authUser },
  } = useStateContext();

  const [disabled] = useDisable();

  const mainStatus = watch("main_status");
  const aggrid_status = useWatch({
    control,
    name: "aggrid_status",
  });
  const flagStatus = watch("flag_status");

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;

  const printOptions: IMenuOption[] = [
    {
      value: `Daily Worksheet`,
      disabled: !id,
    },
  ];

  const createOptions: IMenuOption[] = [
    {
      value: t("logistic.maintenance.index"),
    },
  ];

  const copyDocumentHandler = () => {
    const drliveryTrip = getValues();
    const formatDeliveryTrip = copyDeliveryTripHandler(drliveryTrip, authUser);
    navigate("/logistic/delivery-trip/add", {
      state: formatDeliveryTrip,
    });
  };

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { refetch: refetchUniqueId } =
    useSalesUniqueIdGenerateQuery<SalesUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: SalesModelType.DeliveryTrip,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const createMaintenance = async () => {
    const deliveryTrip = getValues();
    const { VehicleFindViewAggrid: vehicles } = await graphQLClient.request(
      VEHICLE_VIEW,
      {
        aggridInput: {
          filterModel: {
            id: {
              values: [deliveryTrip.vehicle_id, deliveryTrip.trailer_id].filter(
                Boolean
              ),
              filterType: "set",
            },
          },
        },
      }
    );
    const vehicle_details = vehicles.results?.find(
      (vehicle: any) => vehicle.id === deliveryTrip.vehicle_id
    );
    const trailer_details = vehicles.results?.find(
      (vehicle: any) => vehicle.id === deliveryTrip.trailer_id
    );
    navigate("/logistic/maintenance/add", {
      state: createMaintenanceFromDeliveryTrip({
        ...deliveryTrip,
        vehicle: vehicle_details ?? deliveryTrip.vehicle,
        trailer: trailer_details ?? deliveryTrip.trailer,
      }),
    });
  };

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(cancelHandler);

  const selectModifyOptions = useDeliveryTripOption(status, isEdit);

  const disabledStatus = ["delivering", "finished"].includes(status);

  const uniqueId = watch("unique_id");

  const reference_document_list = useWatch({
    control,
    name: "reference_document_list",
  });

  const filteredRefDocument = reference_document_list?.filter(
    (ref) => !ref.hidden
  );

  return (
    <Box maxWidth={1040} mt={status === "finished" ? 0 : 2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              {t("logistic.delivery_trip.index")}
            </Typography>
            <CustomizedStatus status={status} />
            {late && (
              <Box display={"flex"} gap={0.5} alignItems={"center"}>
                <PanoramaFishEyeIcon
                  sx={{
                    color: "#E41B1B",
                    bgcolor: "#FCE8E8",
                    fontSize: "10px",
                    borderRadius: 5,
                  }}
                />
                <Typography sx={{ color: "#E41B1B" }}>
                  {t("status.late")}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        {id && (
          <Grid item sm={12} md={6}>
            <Grid container spacing={1.5} justifyContent="end">
              {(status === "wait_deliver" || status === "delivering") && (
                <Grid item sm={5.5} md={4}>
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"พิมพ์เอกสาร"}
                    options={printOptions}
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      if (value.innerText === `Daily Worksheet`) {
                        navigate(`/logistic/delivery-trip/${id}/pdf`);
                      }
                    }}
                  />
                </Grid>
              )}
              <Grid item sm={5.5} md={4}>
                <CustomizedMenuOptions
                  fullWidth
                  size="medium"
                  label={"ตัวเลือก"}
                  options={selectModifyOptions}
                  onSelect={(e) => {
                    const value = e.target as HTMLElement;
                    switch (value.innerText) {
                      case "แก้ไข":
                        editClickHandler();
                        break;
                      case "คัดลอก":
                        openCopyConfirmation();
                        break;
                      case "ยกเลิก":
                        openCancelConfirmation();
                        break;
                      default:
                        break;
                    }
                  }}
                  disabled={!id}
                />
              </Grid>
              {mainStatus === "finished" && (
                <Grid item sm={5.5} md={4}>
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"สร้าง"}
                    options={createOptions}
                    variant="contained"
                    onSelect={async (e) => {
                      const value = e.target as HTMLElement;
                      switch (value.innerText) {
                        case `${t("logistic.maintenance.index")}`:
                          await createMaintenance();
                          break;
                        default:
                      }
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.delivery_trip.unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="unique_id"
                control={control}
                placeholder={t("logistic.delivery_trip.unique_id")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={1}>
              {!Boolean(id) && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton onClick={generateUniqueId} color="primary">
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.delivery_trip.reference_document_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <Box>
                {filteredRefDocument && filteredRefDocument?.length > 0
                  ? filteredRefDocument.map((reference) => (
                      <CustomizedChips
                        key={`${reference.document_id}-${reference.document_unique_id}-${reference.document_type}`}
                        onClick={() => {
                          window.open(
                            `/sales/${reference.document_type?.replace(
                              "_",
                              "-"
                            )}/${reference.document_id}`,
                            "_blank"
                          );
                        }}
                        value={reference.document_unique_id}
                        isRounded
                      />
                    ))
                  : "-"}
              </Box>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.delivery_trip.job_no")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="job_no"
                control={control}
                error={Boolean(errors?.job_no)}
                helperText={
                  errors?.job_no && errors?.job_no.message?.toString()
                }
                placeholder={t("logistic.delivery_trip.job_no")}
                disabled={disabled || status === "finished"}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <EmployeeListForm disabledStatus={status === "finished"} />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} justifyContent="flex-end">
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                label={t("date.created_date")}
                disabled={true}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                label={t("date.issue_date")}
                disabled={disabled || disabledStatus}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CancelConfirmation
        uniqueId={uniqueId}
        confirmation={cancelConfirmation}
        closeConfirmationHandler={closeCancelConfirmation}
        submitConfirmationHandler={submitCancelConfirmation}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
    </Box>
  );
};

export default DeliveryTripHeader;
