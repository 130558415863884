import { Grid } from "@mui/material";
import Remark from "./Remark";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import MaintenanceSummary from "./Summary";

interface Props {
  documentType: string;
}

const MaintenanceFooter = ({ documentType }: Props) => {
  return (
    <CustomizedBox>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6.5} lg={6.5} xl={6.5}>
          <Remark />
        </Grid>
        <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
          <MaintenanceSummary documentType={documentType} />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default MaintenanceFooter;
