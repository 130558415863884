import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import dayjs from "dayjs";
import {
  DashboardInventoryInput,
  DashboardInventoryQuery,
  DateRange,
  useDashboardInventoryQuery,
} from "generated/general";
import { InputMaybe } from "generated/wms";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IDashboardDateFilter } from "types/global";
import DashboardDateFilter from "../DateFilter";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import QtyBox from "./QtyBox";

const InventoryDashboard = () => {
  const { t } = useTranslation();

  const graphQLClient = createGraphQLClientWithMiddleware("general");
  const [input, setInput] = useState<InputMaybe<DashboardInventoryInput>>({
    date_range: DateRange.Last_7Days,
    start_date: dayjs().startOf("day").subtract(6, "day"),
    end_date: dayjs().endOf("day"),
  });
  const { data, refetch, isFetching } =
    useDashboardInventoryQuery<DashboardInventoryQuery>(
      graphQLClient,
      {
        input,
      },
      {
        enabled: !!input,
        staleTime: Infinity,
      }
    );

  const filterHandler = async (data: IDashboardDateFilter) => {
    await setInput(data);
    await refetch();
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <CustomizedBox
      bgcolor={"#F9F9F9"}
      border={`1px solid white`}
      maxWidth={1650}
      minHeight={316}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h5">{t("inventory.index")}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <DashboardDateFilter
            filterHandler={filterHandler}
            isLoading={isFetching}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={2}>
            {isFetching ? (
              <Skeleton variant="rectangular" height={93} />
            ) : (
              <QtyBox
                title={t("inventory.itemCurrentStock.ordering")}
                icon={
                  <ProductionQuantityLimitsIcon
                    sx={{ fontSize: 24, color: "#737373" }}
                  />
                }
                qty={data?.DashboardInventory?.current_stock?.ordering || 0}
                margin={0}
                url="/inventory/report/stock?filter=ordering"
              />
            )}
            {isFetching ? (
              <Skeleton variant="rectangular" height={93} sx={{ mt: 2 }} />
            ) : (
              <QtyBox
                title={t("inventory.itemCurrentStock.over")}
                icon={<AllInboxIcon sx={{ fontSize: 24, color: "#737373" }} />}
                qty={data?.DashboardInventory?.current_stock?.over || 0}
                margin={"1rem 0 0 0"}
                url="/inventory/report/stock?filter=over"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            {isFetching ? (
              <Skeleton variant="rectangular" height={93} />
            ) : (
              <QtyBox
                title={t("status.wait_receive")}
                icon={
                  <FileDownloadOutlinedIcon
                    sx={{ fontSize: 24, color: "#737373" }}
                  />
                }
                qty={data?.DashboardInventory?.goods_receive?.wait_receive || 0}
                margin={0}
                url="/inventory/goods-receive?filter=wait_receive"
              />
            )}
            {isFetching ? (
              <Skeleton variant="rectangular" height={93} sx={{ mt: 2 }} />
            ) : (
              <QtyBox
                title={t("status.wait_transfer")}
                icon={
                  <MultipleStopIcon sx={{ fontSize: 24, color: "#737373" }} />
                }
                qty={
                  data?.DashboardInventory?.goods_transfer?.wait_transfer || 0
                }
                margin={"1rem 0 0 0"}
                url="/inventory/goods-transfer?filter=wait_transfer"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            {isFetching ? (
              <Skeleton variant="rectangular" height={93} />
            ) : (
              <QtyBox
                title={t("status.wait_issue")}
                icon={
                  <FileUploadOutlinedIcon
                    sx={{ fontSize: 24, color: "#737373" }}
                  />
                }
                qty={data?.DashboardInventory?.goods_issue?.draft || 0}
                margin={0}
                url="/inventory/goods-issue?filter=draft"
              />
            )}
            {isFetching ? (
              <Skeleton variant="rectangular" height={93} sx={{ mt: 2 }} />
            ) : (
              <QtyBox
                title={"รอ" + t("inventory.goods_adjust.index")}
                icon={
                  <BuildOutlinedIcon sx={{ fontSize: 24, color: "#737373" }} />
                }
                qty={data?.DashboardInventory?.goods_adjust?.draft || 0}
                margin={"1rem 0 0 0"}
                url="/inventory/goods-adjust?filter=draft"
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </CustomizedBox>
  );
};

export default InventoryDashboard;
