import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useModal } from "../../../hooks/use-modal";
import { useSnackbar } from "notistack";
import { IBreadcrumbsAndMenu, IInventorySetting } from "../../../types/global";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  InventorySettingFindUniqueQuery,
  InventorySettingType,
  InventorySettingsByTypeQuery,
  useInventorySettingCreateMutation,
  useInventorySettingDeleteMutation,
  useInventorySettingFindUniqueQuery,
  useInventorySettingUpdateMutation,
  useInventorySettingsByTypeQuery,
} from "../../../generated/general";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Box, Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import InventorySettingModal from "../../../components/UI/Modal/InventorySettingModal";
import InventorySettingTable from "../../../components/Table/Setting/InventorySetting";
import DeleteConfirmation from "../../../components/UI/Modal/DeleteConfirmation";

const UomSetting = () => {
  const { t } = useTranslation();
  const [deletingId, setDeletingId] = useState<number>();

  let [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");

  const setSearchParamsHandler = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const removeSearchParamsHandler = (key: string) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const { modal, openModalHandler, closeModalHandler } = useModal(
    undefined,
    () => removeSearchParamsHandler("id")
  );

  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.uom"),
    },
  ];

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: rowData, refetch: refetchRowData } =
    useInventorySettingsByTypeQuery<InventorySettingsByTypeQuery>(
      graphQLClientGeneral,
      {
        type: InventorySettingType.Unit,
      }
    );

  const { data, isLoading, isSuccess } =
    useInventorySettingFindUniqueQuery<InventorySettingFindUniqueQuery>(
      graphQLClientGeneral,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: Boolean(id),
      }
    );

  const { mutateAsync: createUom } =
    useInventorySettingCreateMutation<Error>(graphQLClientGeneral);

  const { mutateAsync: updateUom } =
    useInventorySettingUpdateMutation<Error>(graphQLClientGeneral);

  const { mutateAsync: deleteUom } =
    useInventorySettingDeleteMutation<Error>(graphQLClientGeneral);

  const onAddHandler = async (data: IInventorySetting) => {
    try {
      await createUom({
        createInput: {
          name: data.name,
          type: InventorySettingType.Unit,
        },
      });
      enqueueSnackbar(`เพิ่ม${t("setting.inventory.uom")}สำเร็จ`, {
        variant: "success",
      });
      await refetchRowData();
    } catch (err) {
      enqueueSnackbar(`เพิ่ม${t("setting.inventory.uom")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onEditHandler = (id?: number) => {
    if (id) {
      setSearchParamsHandler("id", id.toString());
      openModalHandler();
    }
  };

  const onUpdateHandler = async (data: IInventorySetting) => {
    try {
      const { id, ...otherData } = data;
      await updateUom({
        uniqueInput: {
          id: id,
        },
        updateInput: {
          name: otherData.name,
          type: InventorySettingType.Unit,
        },
      });
      enqueueSnackbar(`แก้ไข${t("setting.inventory.uom")}สำเร็จ`, {
        variant: "success",
      });
      await refetchRowData();
    } catch (err) {
      enqueueSnackbar(`แก้ไข${t("setting.inventory.uom")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onDeleteHandler = (id?: number) => {
    if (id) {
      setDeletingId(id);
      openConfirmationHandler();
    }
  };

  const deleteActionHandler = async () => {
    try {
      await deleteUom({
        uniqueInput: {
          id: deletingId,
        },
      });
      setDeletingId(undefined);
      enqueueSnackbar(`ลบ${t("setting.inventory.uom")}สำเร็จ`, {
        variant: "success",
      });
      await refetchRowData();
    } catch (err) {
      enqueueSnackbar(`ลบ${t("setting.inventory.uom")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(deleteActionHandler);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">{t("setting.inventory.uom")}</Typography>
        <CustomizedButton
          title={t("button.add") + t("setting.inventory.uom")}
          variant="contained"
          onClick={openModalHandler}
        />
      </Box>
      <InventorySettingTable
        data={rowData?.InventorySettingsByType || []}
        onEditHandler={onEditHandler}
        onDeleteHandler={onDeleteHandler}
      />
      <InventorySettingModal
        label={`${t("setting.inventory.uom")}`}
        open={modal}
        closeModalHandler={closeModalHandler}
        onAddHandler={onAddHandler}
        onUpdateHandler={onUpdateHandler}
        data={data?.InventorySettingFindUnique}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
      <DeleteConfirmation
        title={`ลบ${t("setting.inventory.uom")}`}
        message={
          <Box>
            <Typography>{`ยืนยันการลบ${t(
              "setting.inventory.uom"
            )}`}</Typography>
            <Typography fontWeight="bold">
              {
                rowData?.InventorySettingsByType.find(
                  (setting) => setting.id === deletingId
                )?.name
              }
            </Typography>
          </Box>
        }
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default UomSetting;
