import * as Yup from "yup";
import dayjs from "dayjs";
import { Traceability } from "generated/wms";
import { IGoodsTransfer } from "types/Inventory/goodsTransfer";
import { stringMaxInput } from "utils/Validate";

export const goodsTransferSchema: IGoodsTransfer = {
  id: undefined,
  unique_id: "",
  source_warehouse_id: undefined,
  source_warehouse: undefined,
  destination_warehouse_id: undefined,
  destination_warehouse: undefined,
  created_date: dayjs(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  created_by: null,
  flag_status: [],
  main_status: "",
};

const goodsTransferSerialValidation = Yup.object().shape({
  stock_qty: Yup.number().nullable(),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1, "กรุณาระบุจำนวนมากกว่า 0")
    .required("กรุณาระบุจำนวนมากกว่า 0")
    .when(["stock_qty", "destination_scanned_by"], (value: any, schema) => {
      if (value[1] && Object.keys(value[1]).length > 0) return schema;
      return (
        (value[0] || value[0] === 0) &&
        schema.max(value[0], "จำนวนโอนย้ายมากกว่าจำนวนคงคลัง")
      );
    }),
});

const goodsTransferTraceValidation = Yup.object().shape({
  stock_qty: Yup.number().nullable(),
  qty: Yup.number().when("traceability", (traceability: any, schema) =>
    traceability && traceability[0] === Traceability.Normal
      ? schema
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .min(1, "กรุณาระบุจำนวนมากกว่า 0")
          .required("กรุณาระบุจำนวนมากกว่า 0")
          .when("stock_qty", (value: any, schema) => {
            return schema.max(value[0], "จำนวนโอนย้ายมากกว่าจำนวนคงคลัง");
          })
      : schema.nullable()
  ),

  serial_list: Yup.array().when("traceability", (traceability: any) =>
    traceability && traceability[0] === Traceability.Serial
      ? Yup.array()
          .of(goodsTransferSerialValidation)
          .min(1, "กรุณาสแกนรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsTransferValidation = Yup.object().shape({
  unique_id: stringMaxInput(15, undefined, true, "กรุณาระบุเลขที่เอกสาร"),
  posted_date: Yup.date().required("กรุณาระบุ"),
  source_warehouse_id: Yup.string().required("กรุณาเลือกคลังต้นทาง"),
  destination_warehouse_id: Yup.string().required("กรุณาเลือกคลังปลายทาง"),
  trace_entry_list: Yup.array()
    .of(goodsTransferTraceValidation)
    .min(1, "กรุณาเพิ่มสินค้า"),
  remark: stringMaxInput(200, undefined, false),
});
