import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { IContactChannel } from "../../../types/global";
import { Avatar, Box } from "@mui/material";
import CustomizedChips from "../../Custom/CustomizedChips";
import {
  businessTypeFormatter,
  contactChannelFormatter,
} from "../../../utils/Formatter/Global";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  TagDocumentType,
  TagsFindManyByDocumentTypeQuery,
  useTagsFindManyByDocumentTypeQuery,
} from "../../../generated/general";
import { formatDate } from "../../../utils/Date";
// import { GraphQLClient } from "graphql-request";
// import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

const useContactColumnDefs = (type?: string, onlySupplier?: boolean) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } =
    useTagsFindManyByDocumentTypeQuery<TagsFindManyByDocumentTypeQuery>(
      graphQLClient,
      {
        documentType: TagDocumentType.Contact,
      },
      {
        enabled: false,
      }
    );

  const contactChannelAction = (type: string, info: string) => {
    switch (type) {
      case "phone":
      case "fax":
        window.open(`tel:${info}`, "_self");
        return;
      case "email":
        window.open("mailto:" + info, "_self");
        return;
      case "line":
        window.open(
          "https://line.me/R/ti/p/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      case "facebook":
        window.open(
          "https://facebook.com/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      case "website":
        window.open(`https://${info}`, "_blank", "noopener,noreferrer");
        return;
      case "instagram":
        window.open(
          "https://instagram.com/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    if (type === "delivery") {
      setColumnDefs([
        {
          field: "unique_id",
          headerName: onlySupplier ? `รหัสซัพพลายเออร์` : `รหัสลูกค้า`,
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "business_type",
          headerName: t("contact.info.business_type"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["individual", "corporate"],
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.value)
                return businessTypeFormatter(params.value) || "-";
              else return "-";
            },
          },
          valueFormatter: (params: ValueFormatterParams) => {
            if (params.value) return businessTypeFormatter(params.value) || "-";
            else return "-";
          },
          flex: 1,
          minWidth: 200,
        },
        {
          field: "full_name",
          headerName: `${t("contact.info.brand_name")}/ชื่อ นามสกุล`,
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "contact_channel_list",
          headerName: t("contact.contact_channel.index"),
          filter: false,
          cellRenderer: ({ value }: { value: IContactChannel[] }) => {
            if (
              value.length === 1 &&
              (!value[0].contact_channel_info ||
                value[0].contact_channel_info.trim().length === 0) &&
              (!value[0].contact_channel_type ||
                value[0].contact_channel_type.trim().length === 0)
            ) {
              return;
            } else {
              return value.map((channel, index) => (
                <Box
                  sx={{ display: "inline-block", mr: 1 }}
                  key={`${channel.contact_channel_info}-${index}`}
                >
                  <CustomizedChips
                    color="primary"
                    variant="outlined"
                    onClick={() =>
                      contactChannelAction(
                        channel.contact_channel_type,
                        channel.contact_channel_info || "-"
                      )
                    }
                    label={`${contactChannelFormatter(
                      channel.contact_channel_type
                    )}: ${channel.contact_channel_info || "-"}`}
                    isRounded
                  />
                </Box>
              ));
            }
          },
          flex: 1,
          minWidth: 200,
        },
        {
          field: "tag_list",
          headerName: t("contact.tag_list"),
          filter: "agSetColumnFilter",
          sortable: false,
          filterParams: {
            values: async (params: any) => {
              // fetch values from server
              const { data: tagListResult } = await refetch();
              const values = tagListResult?.TagsFindManyByDocumentType;
              const formatValues = values?.map((tag) => tag.name);
              params.success(formatValues);
            },
          },
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value)
              return params.value?.map((tag: string) => (
                <Box sx={{ display: "inline-block", mr: 1, gap: 1 }} key={tag}>
                  <CustomizedChips
                    label={tag}
                    variant="outlined"
                    color="primary"
                    isRounded
                  />
                </Box>
              ));
          },
          flex: 1,
          minWidth: 200,
        },
        {
          field: "status",
          headerName: t("status.index"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["1", "0"],
            valueFormatter: ({ value }: { value: string }) => {
              const isActive = parseInt(value);
              return isActive ? "ใช้งาน" : "หยุดใช้งาน";
            },
            comparator: (a: string, b: string) => {
              if (b) {
                return 1;
              } else {
                return -1;
              }
            },
          },
          cellRenderer: ({ value }: { value: number }) => {
            return <CustomizedStatus status={value ? "active" : "inactive"} />;
          },
          cellStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
        {
          field: "created_date",
          headerName: t("date.created_date"),
          filter: "agDateColumnFilter",
          hide: true,
          suppressColumnsToolPanel: true,
          sort: "desc",
          valueFormatter: (params: ValueFormatterParams) => {
            if (params && params.value) {
              return formatDate(params.value);
            } else {
              return "-";
            }
          },
        },
      ]);
    } else {
      setColumnDefs([
        {
          field: "unique_id",
          headerName: `รหัส${t(`contact.index`)}`,
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "business_type",
          headerName: t("contact.info.business_type"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["individual", "corporate"],
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.value)
                return businessTypeFormatter(params.value) || "-";
              else return "-";
            },
          },
          valueFormatter: (params: ValueFormatterParams) => {
            if (params.value) return businessTypeFormatter(params.value) || "-";
            else return "-";
          },
          flex: 1,
          minWidth: 200,
        },
        {
          field: "contact_type",
          headerName: t("contact.contact_type.index"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["is_customer", "is_supplier"],
            valueFormatter: (params: ValueFormatterParams) => {
              switch (params.value) {
                case "is_customer":
                  return t(`contact.contact_type.customer`);
                case "is_supplier":
                  return t(`contact.contact_type.supplier`);
                default:
                  return "-";
              }
            },
            readOnly: true,
          },
          valueFormatter: (params: ValueFormatterParams) => {
            if (params.value) {
              return params.value.map((value: string) => {
                switch (value) {
                  case "is_customer":
                    return t(`contact.contact_type.customer`);
                  case "is_supplier":
                    return t(`contact.contact_type.supplier`);
                  default:
                    return "-";
                }
              });
            }
          },
          flex: 1,
          minWidth: 200,
          floatingFilter: false,
        },
        {
          field: "img_url",
          headerName: "รูป",
          flex: 1,
          minWidth: 200,
          filter: false,
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value && params.value.length > 0) {
              return (
                <Avatar
                  key={`${params.value[0]}`}
                  alt={`${params.value[0]}`}
                  src={params.value[0]}
                />
              );
            }
          },
        },
        {
          field: "full_name",
          headerName: `${t("contact.info.brand_name")}/ชื่อ นามสกุล`,
          filter: "agTextColumnFilter",
          flex: 1,
          minWidth: 200,
        },
        {
          field: "contact_channel_list",
          headerName: t("contact.contact_channel.index"),
          filter: false,
          cellRenderer: ({ value }: { value: IContactChannel[] }) => {
            if (
              value.length === 1 &&
              (!value[0].contact_channel_info ||
                value[0].contact_channel_info.trim().length === 0) &&
              (!value[0].contact_channel_type ||
                value[0].contact_channel_type.trim().length === 0)
            ) {
              return;
            } else {
              return value.map((channel, index) => (
                <Box
                  sx={{ display: "inline-block", mr: 1 }}
                  key={`${channel.contact_channel_info}-${index}`}
                >
                  <CustomizedChips
                    color="primary"
                    variant="outlined"
                    onClick={() =>
                      contactChannelAction(
                        channel.contact_channel_type,
                        channel.contact_channel_info || "-"
                      )
                    }
                    label={`${contactChannelFormatter(
                      channel.contact_channel_type
                    )}: ${channel.contact_channel_info || "-"}`}
                    isRounded
                  />
                </Box>
              ));
            }
          },
          flex: 1,
          minWidth: 200,
        },
        {
          field: "tag_list",
          headerName: t("contact.tag_list"),
          filter: "agSetColumnFilter",
          sortable: false,
          filterParams: {
            values: async (params: any) => {
              // fetch values from server
              const { data: tagListResult } = await refetch();
              const values = tagListResult?.TagsFindManyByDocumentType;
              const formatValues = values?.map((tag) => tag.name);
              params.success(formatValues);
            },
          },
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value)
              return params.value?.map((tag: string) => (
                <Box sx={{ display: "inline-block", mr: 1, gap: 1 }} key={tag}>
                  <CustomizedChips
                    label={tag}
                    variant="outlined"
                    color="primary"
                    isRounded
                  />
                </Box>
              ));
          },
          flex: 1,
          minWidth: 200,
        },
        {
          field: "status",
          headerName: t("status.index"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["1", "0"],
            valueFormatter: ({ value }: { value: string }) => {
              const isActive = parseInt(value);
              return isActive ? "ใช้งาน" : "หยุดใช้งาน";
            },
            comparator: (a: string, b: string) => {
              if (b) {
                return 1;
              } else {
                return -1;
              }
            },
          },
          cellRenderer: ({ value }: { value: number }) => {
            return <CustomizedStatus status={value ? "active" : "inactive"} />;
          },
          cellStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
        {
          field: "created_date",
          headerName: t("date.created_date"),
          filter: "agDateColumnFilter",
          hide: true,
          sort: "desc",
          valueFormatter: (params: ValueFormatterParams) => {
            if (params && params.value) {
              return formatDate(params.value);
            } else {
              return "-";
            }
          },
        },
      ]);
    }
  }, [onlySupplier, refetch, t, type]);

  return columnDefs;
};

export default useContactColumnDefs;
