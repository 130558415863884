import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import {
  GeneralModelType,
  GeneralUniqueIdGenerateQuery,
  WarehousesFindAllQuery,
  useBinLocationCreateMutation,
  useBinLocationDeleteMutation,
  useBinLocationUpdateMutation,
  useGeneralUniqueIdGenerateQuery,
  useWarehouseCreateMutation,
  useWarehouseDeleteMutation,
  useWarehouseLevel1CreateMutation,
  useWarehouseLevel1DeleteMutation,
  useWarehouseLevel1UpdateMutation,
  useWarehouseLevel2CreateMutation,
  useWarehouseLevel2DeleteMutation,
  useWarehouseLevel2UpdateMutation,
  useWarehouseLevel3CreateMutation,
  useWarehouseLevel3DeleteMutation,
  useWarehouseLevel3UpdateMutation,
  useWarehouseUpdateMutation,
  useWarehousesFindAllQuery,
} from "generated/general";
import { errorMessageFormatter } from "utils/Global";

interface IDefault {
  value: string;
  unique_id?: string;
}

export const useInventoryLocation = (
  selectedLocation?: any[],
  handleClose?: () => void
) => {
  const { t } = useTranslation();
  const graphQLClient = createGraphQLClientWithMiddleware("general");
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    refetch,
  } = useWarehousesFindAllQuery(graphQLClient);

  const { refetch: refetchUniqueId } =
    useGeneralUniqueIdGenerateQuery<GeneralUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: GeneralModelType.Warehouse,
      },
      {
        enabled: false,
        cacheTime: 0,
        staleTime: Infinity,
      }
    );

  const { mutateAsync: warehouseCreate } =
    useWarehouseCreateMutation<Error>(graphQLClient);
  const { mutateAsync: warehouseLv1Create } =
    useWarehouseLevel1CreateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv2Create } =
    useWarehouseLevel2CreateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv3Create } =
    useWarehouseLevel3CreateMutation<Error>(graphQLClient);

  const { mutateAsync: binlocationCreate } =
    useBinLocationCreateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseUpdate } =
    useWarehouseUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv1Update } =
    useWarehouseLevel1UpdateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv2Update } =
    useWarehouseLevel2UpdateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv3Update } =
    useWarehouseLevel3UpdateMutation<Error>(graphQLClient);

  const { mutateAsync: binlocationUpdate } =
    useBinLocationUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseDelete } =
    useWarehouseDeleteMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv1Delete } =
    useWarehouseLevel1DeleteMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv2Delete } =
    useWarehouseLevel2DeleteMutation<Error>(graphQLClient);

  const { mutateAsync: warehouseLv3Delete } =
    useWarehouseLevel3DeleteMutation<Error>(graphQLClient);

  const { mutateAsync: binlocationDelete } =
    useBinLocationDeleteMutation<Error>(graphQLClient);

  const handleSubmitCreate = async (data: IDefault) => {
    try {
      if (selectedLocation?.length === 0 || !selectedLocation) {
        const input = {
          unique_id: data?.unique_id || "",
          name: data.value,
        };
        await warehouseCreate({ data: input });
      } else if (selectedLocation?.length === 1) {
        const input = {
          name: data.value,
          warehouse_id: selectedLocation[0].id,
        };
        await warehouseLv1Create({ data: input });
      } else if (selectedLocation?.length === 2) {
        const input = {
          name: data.value,
          warehouse_level_1_id: selectedLocation[1].id,
        };
        await warehouseLv2Create({ data: input });
      } else if (selectedLocation?.length === 3) {
        const input = {
          name: data.value,
          warehouse_level_2_id: selectedLocation[2].id,
        };
        await warehouseLv3Create({ data: input });
      } else if (selectedLocation?.length === 4) {
        const input = {
          name: data.value,
          warehouse_level_3_id: selectedLocation[3].id,
        };
        await binlocationCreate({ data: input });
      }
      if (handleClose) {
        handleClose();
      }
      enqueueSnackbar(
        `เพิ่ม${
          !selectedLocation ? "คลัง" : t("setting.inventory.location.index")
        }สำเร็จ`,
        {
          variant: "success",
        }
      );
    } catch (err) {
      const formatError = errorMessageFormatter(err, "warehouse");
      enqueueSnackbar(
        formatError ||
          `เพิ่ม${
            !selectedLocation ? "คลัง" : t("setting.inventory.location.index")
          }ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleSubmitUpdate = async (data: IDefault) => {
    try {
      if (selectedLocation?.length === 1) {
        const input = {
          data: {
            name: data.value,
          },
          uniqueInput: {
            unique_id: selectedLocation[0].unique_id,
          },
        };
        await warehouseUpdate(input);
      } else if (selectedLocation?.length === 2) {
        const input = {
          data: {
            name: data.value,
          },
          uniqueInput: {
            id: selectedLocation[1].id,
          },
        };
        await warehouseLv1Update(input);
      } else if (selectedLocation?.length === 3) {
        const input = {
          data: {
            name: data.value,
          },
          uniqueInput: {
            id: selectedLocation[2].id,
          },
        };
        await warehouseLv2Update(input);
      } else if (selectedLocation?.length === 4) {
        const input = {
          data: {
            name: data.value,
          },
          uniqueInput: {
            id: selectedLocation[3].id,
          },
        };
        await warehouseLv3Update(input);
      } else if (selectedLocation?.length === 5) {
        const input = {
          data: {
            name: data.value,
          },
          uniqueInput: {
            id: selectedLocation[4].id,
          },
        };
        await binlocationUpdate(input);
      }
      if (handleClose) {
        handleClose();
      }
      enqueueSnackbar(
        `แก้ไข${
          selectedLocation && selectedLocation.length === 1
            ? "คลัง"
            : t("setting.inventory.location.index")
        }สำเร็จ`,
        {
          variant: "success",
        }
      );
    } catch (err) {
      const formatError = errorMessageFormatter(err, "warehouse");
      enqueueSnackbar(
        formatError ||
          `แก้ไข${
            selectedLocation && selectedLocation.length === 1
              ? "คลัง"
              : t("setting.inventory.location.index")
          }ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleSubmitDelete = async () => {
    try {
      if (selectedLocation?.length === 1) {
        const input = {
          uniqueInput: {
            unique_id: selectedLocation[0].unique_id,
          },
        };
        await warehouseDelete(input);
      } else if (selectedLocation?.length === 2) {
        const input = {
          uniqueInput: {
            id: selectedLocation[1].id,
          },
        };
        await warehouseLv1Delete(input);
      } else if (selectedLocation?.length === 3) {
        const input = {
          uniqueInput: {
            id: selectedLocation[2].id,
          },
        };
        await warehouseLv2Delete(input);
      } else if (selectedLocation?.length === 4) {
        const input = {
          uniqueInput: {
            id: selectedLocation[3].id,
          },
        };
        await warehouseLv3Delete(input);
      } else if (selectedLocation?.length === 5) {
        const input = {
          uniqueInput: {
            id: selectedLocation[4].id,
          },
        };
        await binlocationDelete(input);
      }
      if (handleClose) {
        handleClose();
      }
      enqueueSnackbar(
        `ลบ${
          selectedLocation && selectedLocation.length === 1
            ? "คลัง"
            : t("setting.inventory.location.index")
        }สำเร็จ`,
        {
          variant: "success",
        }
      );
    } catch (err) {
      const formatError = errorMessageFormatter(err, "warehouse");
      enqueueSnackbar(
        formatError ||
          `ลบ${
            selectedLocation && selectedLocation.length === 1
              ? "คลัง"
              : t("setting.inventory.location.index")
          }ไม่สำเร็จ`,
        {
          variant: "error",
        }
      );
    }
  };

  const sortDataByUniqueIdAndName = (data: any) => {
    if (Array.isArray(data)) {
      // Sort by 'unique_id' if available, otherwise by 'name'
      data = data.sort((a, b) => {
        if (a.unique_id && b.unique_id) {
          return a.unique_id.localeCompare(b.unique_id); // Sort top-level by 'unique_id'
        } else if (a.name && b.name) {
          return a.name.localeCompare(b.name); // Sort nested levels by 'name'
        }
        return 0;
      });

      // Recursively sort each level
      data.forEach((item: any) => {
        if (item.warehouse_level_1_list) {
          item.warehouse_level_1_list = sortDataByUniqueIdAndName(
            item.warehouse_level_1_list
          );
        }
        if (item.warehouse_level_2_list) {
          item.warehouse_level_2_list = sortDataByUniqueIdAndName(
            item.warehouse_level_2_list
          );
        }
        if (item.warehouse_level_3_list) {
          item.warehouse_level_3_list = sortDataByUniqueIdAndName(
            item.warehouse_level_3_list
          );
        }
      });
    }
    return data;
  };

  const sortedWarehouse: WarehousesFindAllQuery["WarehousesFindAll"] =
    sortDataByUniqueIdAndName(warehouses?.WarehousesFindAll);

  const allId: string[] = [];
  sortedWarehouse?.forEach((warehouse: any) => {
    if (
      warehouse.warehouse_level_1_list &&
      warehouse.warehouse_level_1_list.length > 0
    ) {
      allId.push(warehouse.unique_id);
      warehouse.warehouse_level_1_list.forEach((locationLv1: any) => {
        if (
          locationLv1.warehouse_level_2_list &&
          locationLv1.warehouse_level_2_list.length > 0
        ) {
          allId.push(warehouse.unique_id + locationLv1.id);
          locationLv1.warehouse_level_2_list.forEach((locationLv2: any) => {
            if (
              locationLv2.warehouse_level_3_list &&
              locationLv2.warehouse_level_3_list.length > 0
            ) {
              allId.push(warehouse.unique_id + locationLv1.id + locationLv2.id);
              locationLv2.warehouse_level_3_list.forEach((locationLv3: any) => {
                if (
                  locationLv3 &&
                  locationLv3.bin_location_list &&
                  locationLv3.bin_location_list.length > 0
                )
                  allId.push(
                    warehouse.unique_id +
                      locationLv1.id +
                      locationLv2.id +
                      locationLv3.id
                  );
              });
            }
          });
        }
      });
    }
  });

  return {
    isLoading: isWarehousesLoading,
    warehouses: sortedWarehouse || [],
    allWarehouseIds: allId,
    refetch,
    handleSubmitCreate,
    handleSubmitUpdate,
    handleSubmitDelete,
    refetchUniqueId,
  };
};
