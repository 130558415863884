import { gql } from "graphql-request";

export const CONTACT_VIEW = gql`
  query ContactViewAggrid($aggridInput: AnyAggridInput) {
    ContactViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        contact_type
        business_type
        img_url
        full_name
        name
        last_name
        contact_channel_list
        tag_list
        status
        created_date
        address_list
        sales_person_list
        taxpayer_id
        payment_duration
        payment_credit_duration
        billing_note_duration
        credit_request_duration
      }
    }
  }
`;
