import dayjs from "dayjs";
import { IUser } from "../../../types/Auth/user";
import * as Yup from "yup";
import { phoneRegExp } from "../../../utils/Global";
import { stringMaxInput } from "../../../utils/Validate";

export const userSchema: IUser = {
  title_name: "",
  nick_name: "",
  first_name: "",
  last_name: "",
  gender: "",
  start_date: dayjs(),
  inactive_date: null,
  position: "",
  department: "",
  created_date: null,
  created_by: {} as unknown as IUser,
  is_delivery_position: 0,
  delivery_position_details: {
    dob: dayjs(),
    identity_no: "",
    license_type: "",
    license_no: "",
    license_expire_date: dayjs(),
  },
  email: "",
  img_url: [],
  last_login_date: null,
  last_updated_date: null,
  phone: "",
  status: 1,
  remark_status: "",
  unique_id: "",
  new_password: "",
  confirm_new_password: "",
  old_password: "",
};

const YupStringValidate = Yup.string().max(200).required("กรุณาระบุ");

const validateDriver = Yup.object().shape({
  dob: YupStringValidate,
  identity_no: Yup.string()
    .min(13, "กรุณาระบุให้ครบ 13 ตัว")
    .max(13, "กรุณาระบุให้ครบ 13 ตัว")
    .matches(/^(([0-9]{13}))$/, "กรุณาระบุเป็นตัวเลข")
    .required("กรุณาระบุ"),
  license_type: YupStringValidate,
  license_no: Yup.string()
    .max(11, "กรุณาระบุให้ครบ 11 ตัว")
    .required("กรุณาระบุ")
    .matches(
      /^([\u0E00-\u0E7F]{2})[.]([0-9]{5})[/]([0-9]{2})+$/,
      "กรุณาระบุเลขที่ใบขับขี่ให้ถูกต้อง"
    ),
  license_expire_date: YupStringValidate,
});

const defaultValidate = {
  unique_id: Yup.string().required("กรุณาระบุ"),
  nick_name: stringMaxInput(200, undefined, false),
  title_name: stringMaxInput(),
  first_name: stringMaxInput(),
  last_name: stringMaxInput(),
  position: stringMaxInput(),
  department: stringMaxInput(),
  email: stringMaxInput().email("กรุณาระบุให้ถูกต้อง"),
  phone: stringMaxInput().matches(phoneRegExp, "กรุณาระบุให้ถูกต้อง"),
  status: Yup.number().required(),
  remark_status: Yup.string().when("status", (status: any, schema) =>
    status && status[0] === 0
      ? schema.max(200).required("กรุณาระบุหมายเหตุสถานะ")
      : schema.nullable()
  ),
  is_delivery_position: Yup.number(),
  delivery_position_details: Yup.object().when("position", (position, schema) =>
    position && position[0] === "พนักงานขนส่ง" ? validateDriver : schema
  ),
};

export const userValidation = Yup.object().shape({
  ...defaultValidate,
  new_password: Yup.string()
    .min(8, "กรุณาระบุมากกว่า 8 ตัว")
    .matches(
      /[a-z]+/,
      "กรุณาระบุตัวอักษรพิมพ์ใหญ่ และ พิมพ์เล็กอย่างน้อย 1 ตัว"
    )
    .matches(
      /[A-Z]+/,
      "กรุณาระบุตัวอักษรพิมพ์ใหญ่ และ พิมพ์เล็กอย่างน้อย 1 ตัว"
    )
    .matches(/\d+/, "ตัวเลขอย่างน้อย 1 ตัว")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
      `อักขระพิเศษ (!@#$%^&*()_+|~-=\`{}[]:";'<>?,./) อย่างน้อย 1 ตัว`
    )
    .max(200),
  confirm_new_password: Yup.string().when(
    "new_password",
    (new_password, schema) =>
      new_password.some((val) => val && val !== "")
        ? schema
            .required("กรุณาระบุ")
            .oneOf([Yup.ref("new_password")], "รหัสผ่านไม่ตรงกัน")
            .max(200)
        : schema
  ),
});

export const userValidationEditSelf = Yup.object().shape({
  ...defaultValidate,
  old_password: Yup.string().when("new_password", (new_password, schema) =>
    new_password.some((val) => val && val !== "")
      ? schema.required("กรุณาระบุ").max(200)
      : schema.optional().nullable()
  ),
  new_password: Yup.string()
    .min(8, "กรุณาระบุมากกว่า 8 ตัว")
    .matches(
      /[a-z]+/,
      "กรุณาระบุตัวอักษรพิมพ์ใหญ่ และ พิมพ์เล็กอย่างน้อย 1 ตัว"
    )
    .matches(
      /[A-Z]+/,
      "กรุณาระบุตัวอักษรพิมพ์ใหญ่ และ พิมพ์เล็กอย่างน้อย 1 ตัว"
    )
    .matches(/\d+/, "ตัวเลขอย่างน้อย 1 ตัว")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
      `อักขระพิเศษ (!@#$%^&*()_+|~-=\`{}[]:";'<>?,./) อย่างน้อย 1 ตัว`
    )
    .max(200),
  confirm_new_password: Yup.string().when(
    "new_password",
    (new_password, schema) =>
      new_password.some((val) => val && val !== "")
        ? schema
            .required("กรุณาระบุ")
            .oneOf([Yup.ref("new_password")], "รหัสผ่านไม่ตรงกัน")
            .max(200)
        : schema
  ),
});
