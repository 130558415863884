import React, { createContext, useContext, ReactNode } from "react";
import {
  useFormContext,
  useWatch,
  useFieldArray,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayMove,
  UseFieldArrayReplace,
  FieldArray,
} from "react-hook-form";
import { IBillingNote } from "types/Sales/billing-note";

interface DeliveryTripListContextValue {
  fields: FieldArrayWithId<IBillingNote, "item_list", "id">[];
  controlledFields: FieldArray<IBillingNote, "item_list">[];
  move: UseFieldArrayMove;
  remove: UseFieldArrayRemove;
  replace: UseFieldArrayReplace<IBillingNote, "item_list">;
}

const DeliveryTripListContext =
  createContext<DeliveryTripListContextValue | null>(null);

export const DeliveryTripListProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { control } = useFormContext<IBillingNote>();

  const { fields, move, remove, replace } = useFieldArray({
    control,
    name: "item_list",
  });

  const watchFieldArray = useWatch({
    control,
    name: "item_list",
  });

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }));

  return (
    <DeliveryTripListContext.Provider
      value={{ fields, controlledFields, move, remove, replace }}
    >
      {children}
    </DeliveryTripListContext.Provider>
  );
};

export const useDeliveryTripListContext = () => {
  const context = useContext(DeliveryTripListContext);
  if (!context) {
    throw new Error(
      "useDeliveryTripListContext must be used within a DeliveryTripListProvider"
    );
  }
  return context;
};
