import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  InventorySettingsByTypeQuery,
  InventorySettingType,
  useInventorySettingsByTypeQuery,
  useWarehousesFindAllQuery,
  WarehousesFindAllQuery,
} from "generated/general";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { dateFilterParams } from "utils/AgGridFilter";
import { formatDate } from "utils/Date";
import CustomizedStatus from "components/Custom/CustomizedStatus";

export const useNoEntryReportColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } = useWarehousesFindAllQuery<WarehousesFindAllQuery>(
    graphQLClient,
    {},
    {
      enabled: false,
    }
  );

  const { refetch: refetchCategory } =
    useInventorySettingsByTypeQuery<InventorySettingsByTypeQuery>(
      graphQLClient,
      {
        type: InventorySettingType.Cate_1,
      },
      {
        enabled: false,
      }
    );

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "created_date",
        headerName: t("inventory.stockEntry.createdDate"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "unique_id",
        headerName: t("inventory.item.unique_id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "seller_sku",
        headerName: t("inventory.item.sku"),
        filter: "agTextColumnFilter",
        hide: true,
        valueFormatter: (params) => (params.value ? params.value : "-"),
      },
      {
        field: "name",
        headerName: t("inventory.item.name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "type",
        headerName: t("inventory.item.type"),
        sortable: false,
        filter: false,
        valueFormatter: ({ value }: { value: string }) => {
          switch (value) {
            case "normal":
              return "สินค้าธรรมดา";
            case "service":
              return "บริการ";
            default:
              return "";
          }
        },
      },
      {
        field: "is_saleable",
        headerName: t("inventory.item.is_saleable"),
        sortable: false,
        filter: false,
        valueFormatter: ({ value }: { value: boolean }) => {
          switch (value) {
            case true:
              return "ใช่";
            case false:
              return "ไม่ใช่";
            default:
              return "-";
          }
        },
      },
      {
        field: "is_purchasable",
        headerName: t("inventory.item.is_purchasable"),
        sortable: false,
        filter: false,
        valueFormatter: ({ value }: { value: boolean }) => {
          switch (value) {
            case true:
              return "ใช่";
            case false:
              return "ไม่ใช่";
            default:
              return "-";
          }
        },
      },
      {
        field: "is_stockable",
        headerName: t("inventory.item.is_stockable"),
        sortable: false,
        filter: false,
        valueFormatter: ({ value }: { value: boolean }) => {
          switch (value) {
            case true:
              return "ใช่";
            case false:
              return "ไม่ใช่";
            default:
              return "-";
          }
        },
      },
      {
        field: "description",
        headerName: t("inventory.item.description"),
        filter: "agTextColumnFilter",
      },
      {
        field: "stock_qty",
        headerName: t("inventory.item.stock_qty"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params) =>
          params.value ? params.value.toLocaleString() : 0,
        cellStyle: { display: "flex", justifyContent: "flex-end" },
      },
      {
        field: "available_qty",
        headerName: t("inventory.item.available_qty"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params) =>
          params.value ? params.value.toLocaleString() : 0,
        cellStyle: { display: "flex", justifyContent: "flex-end" },
      },
      {
        field: "cate_1",
        headerName: t("inventory.item.category_one"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: categoriesResult } = await refetchCategory();
            const values = categoriesResult?.InventorySettingsByType;
            const formatValues = values?.map((category) => category.name);
            params.success(formatValues);
          },
        },
      },
      {
        field: "is_active",
        headerName: t("inventory.item.status"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [1, 0],
          valueFormatter: ({ value }: { value: number }) =>
            value ? "ใช้งาน" : "หยุดใช้งาน",
        },
        cellRenderer: ({ value }: { value: number }) => {
          return <CustomizedStatus status={value ? "active" : "inactive"} />;
        },
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
      },
    ]);
  }, [t, refetch, refetchCategory]);
  return columnDefs;
};
