import { expensesListData } from "components/Form/Logistic/DeliveryTrip/schema";
import dayjs from "dayjs";
import { DeliveryTripFindUniqueQuery } from "generated/sales";
import { LOCATION_VIEW } from "services/AgGrid/LocationAgGrid";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { IUser } from "types/Auth/user";
import { IAttachment, IShippingCost } from "types/global";
import { IWorkList } from "types/Logistic";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import { uploadFileToS3 } from "utils/s3";
import { v4 as uuidv4 } from "uuid";
import { maintenanceSchema } from "components/Form/Logistic/Maintenance/schema";

export const deliveryTripCreatePayload = (
  data: IDeliveryTrip,
  status: string
) => {
  const {
    customer_details,
    work_list,
    income_list,
    expenses_list,
    created_date,
    ...otherData
  } = data;
  delete (otherData as any).credit_day;
  const formatWorkList = work_list.map(({ ...otherItem }, index) => ({
    ...otherItem,
    index: index + 1,
    reference_unique_id: data.unique_id,
  }));

  const formatIncomeList = income_list.map(({ ...otherItem }, index) => ({
    ...otherItem,
    index: index + 1,
    reference_unique_id: data.unique_id,
  }));

  const formatExpensesList = expenses_list.map(({ ...otherItem }, index) => ({
    ...otherItem,
    index: index + 1,
    reference_unique_id: data.unique_id,
  }));

  const { unique_id_name, ...customer } = customer_details;

  return {
    ...otherData,
    customer_details: customer,
    work_list: formatWorkList,
    income_list: formatIncomeList,
    expenses_list: formatExpensesList,
    main_status: status,
    sub_status: status,
  };
};

export const deliveryTripUpdatePayload = async (
  data: IDeliveryTrip,
  status: string
) => {
  const {
    id,
    unique_id,
    main_status,
    flag_status,
    last_updated_date,
    created_by,
    created_date,
    customer_details,
    work_list,
    income_list,
    expenses_list,
    attachment_list,
    ...otherData
  } = data;

  delete (otherData as any).credit_day;

  if (status === "delivering" || status === "finished") {
    const isLate = dayjs().isAfter(data.plan_end_date);
    if (isLate) {
      if (flag_status && !flag_status.includes("late")) {
        flag_status.push("late");
      }
    }
  }

  const formatWorkList = work_list.map(({ ...otherItem }, index) => ({
    ...otherItem,
    index: index + 1,
    reference_unique_id: data.unique_id,
  }));

  const formatIncomeList = income_list.map(({ ...otherItem }, index) => ({
    ...otherItem,
    index: index + 1,
    reference_unique_id: data.unique_id,
  }));

  const formatExpensesList = expenses_list.map(({ ...otherItem }, index) => ({
    ...otherItem,
    index: index + 1,
    reference_unique_id: data.unique_id,
  }));

  const { unique_id_name, ...customer } = customer_details;

  let uploadedAttachment: IAttachment[] = [];
  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "delivery_trip",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        uploadedAttachment.push(formatAttachment);
      } else {
        uploadedAttachment.push(file);
      }
    }
  }

  return {
    ...otherData,
    attachment_list: uploadedAttachment,
    customer_details: customer,
    work_list: formatWorkList,
    income_list: formatIncomeList,
    expenses_list: formatExpensesList,
    main_status: status,
    sub_status: status,
    flag_status,
  };
};

export const deliveryTripDataFormatter = (
  data: DeliveryTripFindUniqueQuery["DeliveryTripFindUnique"]
) => {
  if (data) {
    const {
      created_date,
      issue_date,
      plan_start_date,
      plan_end_date,
      actual_start_date,
      actual_end_date,
      last_updated_date,
      customer_details,
      work_list,
      ...otherData
    } = data;

    const formatWorkList = work_list?.map(
      ({ start_date, end_date, ...work }) => ({
        ...work,
        start_date: start_date ? dayjs(start_date) : undefined,
        end_date: end_date ? dayjs(end_date) : undefined,
      })
    );

    const formatCustomerDetails = {
      unique_id_name: `${otherData.customer_unique_id} - ${customer_details.name}`,
      ...customer_details,
    };

    const formatPayload = {
      ...otherData,
      created_date: created_date ? dayjs(created_date) : undefined,
      issue_date: issue_date ? dayjs(issue_date) : undefined,
      plan_start_date: plan_start_date ? dayjs(plan_start_date) : undefined,
      plan_end_date: plan_end_date ? dayjs(plan_end_date) : undefined,
      actual_start_date: actual_start_date
        ? dayjs(actual_start_date)
        : undefined,
      actual_end_date: actual_end_date ? dayjs(actual_end_date) : undefined,
      last_updated_date: last_updated_date
        ? dayjs(last_updated_date)
        : undefined,
      work_list: formatWorkList,
      customer_details: formatCustomerDetails,
    };
    return formatPayload;
  }
};

export const workListQueryFormatter = async (
  workList: IWorkList[],
  vehicleType: string,
  isFrozen: boolean
): Promise<IWorkList[]> => {
  if (workList.length === 0) return [];

  const workListIds = workList.map((work) => work.location_unique_id);

  // Fetch location data from GraphQL
  const graphQLClientWithHeader = createGraphQLClientWithMiddleware("crm");
  const { LocationViewAggrid } = await graphQLClientWithHeader.request(
    LOCATION_VIEW,
    {
      aggridInput: {
        startRow: 0,
        endRow: workListIds.length,
        filterModel: {
          unique_id: { values: workListIds, filterType: "set" },
        },
        sortModel: [],
      },
    }
  );

  const allLocations: any = LocationViewAggrid.results;

  // Map location ids to their respective location data for quick lookup
  const locationMap: any = new Map(
    allLocations.map((location: any) => [location.unique_id, location])
  );

  let maxShippingCost = 0;
  let firstDeliverIndex = -1;

  // Single pass through workList to compute results
  const newWorkList = workList.map((work, index) => {
    const location = locationMap.get(work.location_unique_id);

    if (work.type === "deliver") {
      const shipping_cost = location
        ? location.shipping_cost.find(
            (cost: IShippingCost) =>
              cost.type === vehicleType && cost.is_frozen === isFrozen
          )?.cost || 0
        : null;

      const drop_cost = location?.drop_cost ? location.drop_cost : null;

      if (firstDeliverIndex === -1) {
        firstDeliverIndex = index; // Track the first "deliver" work index
      }

      // Track the maximum shipping cost
      maxShippingCost = Math.max(maxShippingCost, shipping_cost || 0);

      return { ...work, shipping_cost, drop_cost, max_shipping_cost: 0 };
    }

    return {
      ...work,
      shipping_cost: null,
      drop_cost: null,
      max_shipping_cost: 0,
    };
  });

  // Assign the max_shipping_cost to the first item
  if (newWorkList.length > 0) {
    newWorkList[0].max_shipping_cost = maxShippingCost;
  }

  // Clear drop_cost for the first deliver item
  if (firstDeliverIndex !== -1) {
    newWorkList[firstDeliverIndex].drop_cost = null;
  }

  return newWorkList;
};

export const copyDeliveryTripHandler = (
  data: IDeliveryTrip,
  currentUser: IUser | null
) => {
  const {
    id,
    unique_id,
    main_status,
    sub_status,
    flag_status,
    aggrid_status,
    attachment_list,
    created_by,
    created_date,
    last_updated_by,
    last_updated_date,
    issue_date,
    plan_start_date,
    plan_end_date,
    actual_start_date,
    actual_end_date,
    temperature_start_hours,
    temperature_end_hours,
    total_temperature_hours,
    end_kilometer,
    total_kilometer_distance,
    fuel,
    expenses_list,
    work_list,
    income_list,
    related_user_list,
    job_no,
    reference_document_list,
    start_kilometer,
    ...otherData
  } = data;

  const { actual_fleet, appa, other, total_usage, ...otherFuel } = fuel;

  const formatWorkList = work_list.map(
    ({
      id,
      unique_id,
      start_date,
      end_date,
      reference_unique_id,
      ...work
    }) => ({
      ...work,
      unique_id: uuidv4(),
      start_date: undefined,
      end_date: undefined,
      arrived_kilometer: undefined,
    })
  );

  const formatIncomeList = income_list.map(
    ({ id, unique_id, reference_unique_id, ...income }) => ({
      ...income,
      unique_id: uuidv4(),
    })
  );

  let formatRelatedUserList;

  if (currentUser && currentUser.unique_id !== created_by?.user_unique_id) {
    formatRelatedUserList = [
      created_by,
      ...related_user_list.filter(
        (user) => user.user_unique_id !== created_by?.user_unique_id
      ),
    ];
  } else {
    formatRelatedUserList = related_user_list;
  }

  const formatData = {
    ...otherData,
    copied_id: id,
    copied_unique_id: unique_id,
    unique_id: "",
    main_status: "",
    sub_status: "",
    flag_status: [],
    aggrid_status: "",
    attachment_list: [],
    fuel: otherFuel,
    created_date: dayjs().toISOString(),
    issue_date: dayjs().toISOString(),
    plan_start_date: dayjs().toISOString(),
    plan_end_date: dayjs().toISOString(),
    work_list: formatWorkList,
    income_list: formatIncomeList,
    expenses_list: expensesListData,
    total_kilometer_distance: 0,
    related_user_list: formatRelatedUserList,
    expenses_net_amount: 0,
    expenses_post_discount_amount: 0,
    expenses_total_amount: 0,
    expenses_vat_0_percent_amount: 0,
    expenses_vat_7_percent_amount: 0,
    expenses_vat_amount: 0,
    expenses_vat_exempted_amount: 0,
    expenses_withholding_tax_amount: 0,
  };

  return formatData;
};

export const createMaintenanceFromDeliveryTrip = (data: IDeliveryTrip) => {
  const {
    id,
    unique_id,
    vehicle_id,
    vehicle,
    trailer_id,
    trailer,
    primary_driver,
  } = data;

  const {
    unique_id: vehicle_no,
    register_id,
    ...vehicle_details
  } = vehicle as any;

  const {
    unique_id: trailer_no,
    register_id: trailer_register_id,
    ...trailer_details
  } = trailer as any;

  const formatted = {
    ...maintenanceSchema,
    created_date: maintenanceSchema.created_date
      ? maintenanceSchema.created_date.toISOString()
      : undefined,
    issue_date: maintenanceSchema.issue_date
      ? maintenanceSchema.issue_date.toISOString()
      : undefined,
    ma_start_date: maintenanceSchema.ma_start_date
      ? maintenanceSchema.ma_start_date.toISOString()
      : undefined,
    ma_end_date: maintenanceSchema.ma_end_date
      ? maintenanceSchema.ma_end_date.toISOString()
      : undefined,
    reference_document_list: [
      {
        document_id: id,
        document_unique_id: unique_id,
        document_type: "delivery_trip",
      },
    ],
    vehicle_id: vehicle_id,
    vehicle_details: {
      ...vehicle_details,
      vehicle_no,
      license_plate: register_id,
      current_kilometer: "",
    },
    trailer_id,
    trailer_details: {
      ...trailer_details,
      vehicle_no: trailer_no,
      license_plate: trailer_register_id,
      current_kilometer: "",
    },
    driver_list: primary_driver ? [{ ...primary_driver }] : [],
  };

  return formatted;
};
