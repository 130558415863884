import { AgGridReact } from "ag-grid-react";
import { useStateContext } from "contexts/auth-context";
import { DeliveryReportType } from "generated/sales";
import { GraphQLClient } from "graphql-request";
import { useSnackbar } from "notistack";
import { RefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { DELIVERY_REPORT } from "services/Logistic/Report/DeliveryTripReportAgGrid";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { dateFilterModel } from "utils/AgGridFilter";

export const useDeliveryTripReport = (
  gridRef: RefObject<AgGridReact<any>>,
  reportType: DeliveryReportType,
  documentUniqueId?: string
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const graphQLClientWithHeaderDelivery: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const {
    state: { authUser },
  } = useStateContext();

  const onBtnExport = async () => {
    setIsLoading(true);
    try {
      const filterModel = gridRef?.current?.api.getFilterModel();
      const sortModel = gridRef?.current?.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));
      const formattedFilterModel = filterModel
        ? {
            ...filterModel,
            flag_status: filterModel.flag_status && {
              ...filterModel.flag_status,
              values: filterModel.flag_status.values.map((v: string) =>
                parseInt(v, 10)
              ),
            },
            is_frozen: filterModel.is_frozen && {
              filterType: "setBoolean",
              values: filterModel.is_frozen.values.map(
                (v: string) => v === "true"
              ),
            },
            issue_date: dateFilterModel(filterModel.issue_date),
            plan_start_date: dateFilterModel(filterModel.plan_start_date),
            plan_end_date: dateFilterModel(filterModel.plan_end_date),
            created_date: dateFilterModel(filterModel.created_date),
            actual_start_date: dateFilterModel(filterModel.actual_start_date),
            actual_end_date: dateFilterModel(filterModel.actual_end_date),
            reference_document: filterModel.reference_document
              ? {
                  filterType: "array",
                  type: "hasSome",
                  values: filterModel.reference_document.values,
                }
              : undefined,
          }
        : {};
      const params: any = {
        aggridInput: {
          filterModel: formattedFilterModel,
          sortModel,
        },
      };

      if (documentUniqueId) {
        params.document_unique_id = documentUniqueId;
      }

      const reportInput = {
        report_type: reportType,
        user_unique_id: authUser?.unique_id || "",
        params: params,
      };

      try {
        const { GenerateDeliveryReport } =
          await graphQLClientWithHeaderDelivery.request(DELIVERY_REPORT, {
            input: reportInput,
          });
        if (GenerateDeliveryReport) {
          window.open(GenerateDeliveryReport || "", "_blank");
          enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
            variant: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  return {
    onBtnExport,
    isLoading: isLoading,
  };
};
