import { GraphQLClient } from "graphql-request";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import {
  ActivityLog as ActivityLogType,
  ActivityLogDocumentType,
  ActivityLogFindManyByReferenceIdQuery,
  ActivityType,
  useActivityLogFindManyByReferenceIdQuery,
} from "../../generated/general";
import { useTranslation } from "react-i18next";
import CustomizedSelect from "../Custom/CustomizedSelect";
import { CustomizedBox } from "../Custom/CustomizedBox";
import CustomizedScrollbar from "../Custom/CustomizedScrollbar";
import {
  Box,
  Drawer,
  IconButton,
  Link,
  TablePagination,
  Typography,
} from "@mui/material";
import { formatDate, formatTime } from "../../utils/Date";
import CustomizedStatus from "../Custom/CustomizedStatus";
import { numberFormatter } from "../../utils/Formatter/Global";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const ICON_MAP: Record<ActivityType, typeof DeleteOutlineIcon> = {
  [ActivityType.AddRelatedEmployee]: PersonAddAltOutlinedIcon,
  [ActivityType.Approve]: DoneOutlinedIcon,
  [ActivityType.Cancel]: ClearOutlinedIcon,
  [ActivityType.CancelPendingScan]: ClearOutlinedIcon,
  [ActivityType.Comment]: CommentOutlinedIcon,
  [ActivityType.Copy]: ContentCopyOutlinedIcon,
  [ActivityType.Create]: AddOutlinedIcon,
  [ActivityType.Delete]: DeleteOutlineIcon,
  [ActivityType.DeleteRelatedEmployee]: PersonRemoveOutlinedIcon,
  [ActivityType.Document]: DescriptionOutlinedIcon,
  [ActivityType.Edit]: EditIcon,
  [ActivityType.Image]: ImageOutlinedIcon,
  [ActivityType.StatusChange]: ArrowForwardOutlinedIcon,
  [ActivityType.Active]: CheckOutlinedIcon,
  [ActivityType.InActive]: CloseOutlinedIcon,
  [ActivityType.ChangePassword]: EditIcon,
  [ActivityType.Importer]: AddOutlinedIcon,
  [ActivityType.EditPermission]: EditIcon,
};

const formatActivityType = (activityType: ActivityType | undefined) => {
  switch (activityType) {
    case ActivityType.AddRelatedEmployee:
      return "เพิ่มพนักงานที่เกี่ยวข้อง";
    case ActivityType.Approve:
      return "อนุมัติ";
    case ActivityType.Cancel:
      return "ยกเลิก";
    case ActivityType.CancelPendingScan:
      return "ยกเลิกจำนวนที่ค้าง";
    case ActivityType.Comment:
      return "แสดงความคิดเห็น";
    case ActivityType.Copy:
      return "คัดลอก";
    case ActivityType.Create:
      return "สร้าง";
    case ActivityType.Delete:
      return "ลบ";
    case ActivityType.DeleteRelatedEmployee:
      return "ลบพนักงานที่เกี่ยวข้อง";
    case ActivityType.Document:
      return "เอกสาร";
    case ActivityType.Edit:
      return "แก้ไข";
    case ActivityType.Image:
      return "รูปภาพ";
    case ActivityType.StatusChange:
      return "เปลี่ยนสถานะ";
    case ActivityType.EditPermission:
      return "แก้ไขสิทธิ์";
    case ActivityType.ChangePassword:
      return "เปลี่ยนรหัสผ่าน";
    case ActivityType.Importer:
      return "สร้างจากการนำเข้าไฟล์";
    case ActivityType.Active:
      return "เปิดใช้งาน";
    case ActivityType.InActive:
      return "หยุดใช้งาน";
    default:
      return "";
  }
};

type Props = {
  documentType: string;
  documentId: string;
  open: boolean;
  onClose: () => void;
};

export const ActivityLog = ({
  documentType,
  documentId,
  onClose,
  open,
}: Props) => {
  const [page, setPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState("desc");
  // const [disabled] = useDisable();
  const sortOptions = [
    { id: 1, value: "desc", label: "ใหม่ไปเก่า" },
    { id: 2, value: "asc", label: "เก่าไปใหม่" },
  ];
  const { pathname } = useLocation();

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, refetch } = useActivityLogFindManyByReferenceIdQuery<
    Array<ActivityLogFindManyByReferenceIdQuery>
  >(
    graphQLClientGeneral,
    {
      findManyInput: {
        reference_id:
          typeof documentId === "string" ? parseInt(documentId) : documentId,
        document_type: documentType as ActivityLogDocumentType,
      },
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  const sortedData = useMemo(() => {
    if ((data as any)?.ActivityLogFindManyByReferenceId?.length > 0) {
      return [...(data as any)?.ActivityLogFindManyByReferenceId].sort(
        (a: any, b: any) => {
          const dateComparison =
            new Date(a.created_date).getTime() -
            new Date(b.created_date).getTime();

          // in case of same date, sort by id (happens through status change)
          if (dateComparison === 0) {
            return sortBy === "asc" ? a.id - b.id : b.id - a.id;
          } else {
            return sortBy === "asc" ? dateComparison : -dateComparison;
          }
        }
      );
    } else {
      return [];
    }
  }, [data, sortBy]);

  const paginatedData = useMemo(() => {
    return sortedData.slice(page * 5, page * 5 + 5);
  }, [sortedData, page]);

  const handleSelectSortOption = (value: string) => {
    setSortBy(value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const splitPath = pathname.split("/");
  const copyPath = splitPath.splice(0, splitPath.length - 1).join("/");

  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 560,
          borderRadius: "10px 0px 0px 10px",
          p: "1.25rem",
        },
      }}
      ModalProps={{ sx: { zIndex: "rightDrawer" } }}
    >
      <CustomizedScrollbar>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h5">การเคลื่อนไหว</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseOutlinedIcon />
          </IconButton>
        </Box>

        <CustomizedBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minHeight: "400px",
            height: "calc(100vh - 7rem)",
          }}
        >
          <CustomizedSelect
            options={sortOptions}
            label="เรียงลำดับ"
            onChange={(e: any) => handleSelectSortOption(e.target.value)}
            value={sortBy}
            sx={{
              mb: 0.5,
              width: "115px",
            }}
            variant="outlined"
          />
          <Box
            sx={{
              flex: 1,
              overflow: "auto",
              minHeight: "100px",
            }}
          >
            {paginatedData?.map((row: ActivityLogType, index: number) => {
              const Icon = ICON_MAP[row.activity_type || ActivityType.Approve];
              return (
                <Fragment key={row.id}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 24px 2fr",
                      gap: 1,
                    }}
                    my={3}
                  >
                    <Box>
                      <Box display={"flex"} gap={2} mb={0.5}>
                        <Typography>วันที่</Typography>
                        <Typography
                          sx={{
                            color: "#737373",
                          }}
                        >
                          {formatDate(row?.created_date)}
                        </Typography>
                      </Box>
                      <Box display={"flex"} gap={2}>
                        <Typography>เวลา</Typography>
                        <Typography
                          sx={{
                            color: "#737373",
                          }}
                        >
                          {formatTime(row?.created_date)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          width: "24px",
                          height: "24px",
                          bgcolor: "#F5F5F5",
                        }}
                      >
                        <Icon fontSize="small" />
                      </Box>
                      {index < paginatedData.length - 1 && (
                        <Box
                          sx={{
                            marginTop: "5px",
                            borderRight: "1px solid #c8c8c8",
                            height: "calc(70%)",
                            width: "calc(50% - 0.5px)",
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                        }}
                        mb={0.5}
                      >
                        <Typography fontWeight={600}>
                          {formatActivityType(
                            row.activity_type as ActivityType
                          )}
                          {row.activity_type === ActivityType.Approve &&
                            ` ${row.activity_detail?.message?.[0]}`}
                        </Typography>
                        {row.activity_type === ActivityType.StatusChange && (
                          <Box sx={{ maxWidth: "fit-content" }}>
                            <CustomizedStatus
                              status={row.activity_detail?.curr_status}
                              fontSize="0.75rem"
                            />
                          </Box>
                        )}
                      </Box>
                      {row.activity_type === ActivityType.Copy &&
                        row.activity_detail?.copied_to && (
                          <Box display={"flex"} gap={0.5} mb={0.5}>
                            <Typography>คัดลอกไป</Typography>
                            <Link
                              href={`${copyPath}/${row.activity_detail.copied_to.id}`}
                              underline="none"
                            >
                              {row.activity_detail.copied_to.unique_id}
                            </Link>
                          </Box>
                        )}
                      {row.activity_type === ActivityType.Copy &&
                        row.activity_detail?.copied_from && (
                          <Box display={"flex"} gap={0.5} mb={0.5}>
                            <Typography>คัดลอกมาจาก</Typography>
                            <Link
                              href={`${copyPath}/${row.activity_detail.copied_from.id}`}
                              underline="none"
                            >
                              {row.activity_detail.copied_from.unique_id}
                            </Link>
                          </Box>
                        )}
                      {row.activity_detail?.updated_fields
                        ? Object.keys(
                            row.activity_detail?.updated_fields
                          ).includes("net_amount") && (
                            <Box mb={0.5}>
                              <Typography>
                                {`${t("sales.net_amount")}: ${numberFormatter(
                                  row.activity_detail?.updated_fields.net_amount
                                )} บาท`}
                              </Typography>
                            </Box>
                          )
                        : null}

                      <Box>
                        <Typography sx={{ display: "inline-block" }}>
                          โดย:
                        </Typography>
                        <Link
                          component={RouterLink}
                          key={index}
                          style={{ marginLeft: "0.25rem" }}
                          underline="none"
                          onClick={onClose}
                          to={`/user/account/${row.created_by?.user_id}`}
                        >
                          {`${row.created_by?.first_name} ${row.created_by?.last_name}`}
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Fragment>
              );
            })}
          </Box>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={sortedData.length}
            rowsPerPage={5}
            page={page}
            onPageChange={handleChangePage}
          />
        </CustomizedBox>
      </CustomizedScrollbar>
    </Drawer>
  );
};
