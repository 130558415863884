import { useDisable } from "hooks/use-disable";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IHeaderTable } from "types/global";

export const useIncomeListHeader = (disabledStatus: boolean) => {
  const { t } = useTranslation();
  const [headers, setHeader] = useState<IHeaderTable[]>([]);
  const [disabled] = useDisable();

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: t("logistic.income_list.no"),
        width: 54,
        align: "left",
      },
      {
        thaiLabel: t("logistic.income_list.billing"),
        width: 334,
        align: "left",
        required: true,
      },
      {
        thaiLabel: t("logistic.income_list.qty"),
        width: 134,
        align: "right",
        required: true,
      },
      {
        thaiLabel: t("logistic.income_list.uom"),
        width: 134,
        align: "left",
        required: true,
      },
      {
        thaiLabel: t("logistic.income_list.price_per_unit"),
        width: 134,
        align: "right",
        required: true,
      },
      {
        thaiLabel: t("logistic.income_list.discount"),
        width: 134,
        align: "right",
      },
      {
        thaiLabel: t("logistic.income_list.vat_percentage"),
        width: 134,
        align: "left",
        required: true,
      },
      {
        thaiLabel: t("logistic.income_list.pre_vat_amount"),
        width: 134,
        align: "right",
        required: true,
      },
      {
        thaiLabel: t("logistic.income_list.withholding_tax"),
        width: 134,
        align: "left",
      },
    ];

    if (!disabled && !disabledStatus) {
      setHeader([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 35,
        },
      ]);
    } else {
      setHeader(defaultHeaders);
    }
  }, [disabled, disabledStatus, t]);

  return headers;
};
