import React, { ElementType, forwardRef } from "react";
import Chip, { ChipProps } from "@mui/material/Chip";

type ExtendedProps = ChipProps & {
  handleDelete?: any;
  component?: ElementType;
  noneColor?: boolean;
  isRounded?: boolean;
  value?: string;
};

const CustomizedChips = forwardRef(
  (
    {
      handleDelete,
      variant,
      color,
      onClick,
      component,
      isRounded,
      noneColor,
      value,
      ...props
    }: ExtendedProps,
    ref
  ) => {
    return (
      <Chip
        ref={ref}
        label={value}
        variant={variant || "outlined"}
        onDelete={handleDelete}
        size="medium"
        color={color || "primary"}
        onClick={onClick}
        component={component || "div"}
        {...props}
        sx={{
          ...props.sx,
          bgcolor: noneColor ? "gray.main" : undefined,
          borderRadius: isRounded ? undefined : "8px !important",
        }}
      />
    );
  }
);
export default CustomizedChips;
